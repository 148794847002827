import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { AppsService } from 'src/app/services/apps/apps-service';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { EventService } from 'src/app/services/events/event-services';
import { MediaService } from 'src/app/services/media/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { LibraryServiceService } from './../../../../services/library/library-service.service'
import { AppAnimation } from './../../../../utilities/animations/animations';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { EbookreaderService } from 'src/app/services/collection/ebook/ebookreader.service';

@Component({
  selector: 'app-add-to-folder-side-pop-up',
  templateUrl: './add-to-folder-side-pop-up.component.html',
  styleUrls: ['./add-to-folder-side-pop-up.component.css'],
  animations: AppAnimation,
})
export class AddToFolderSidePopUpComponent implements OnInit {

  overlay = false;
  sidePanel = false;
  subsciptionData = new Subscription();
  trendingList : any [] = []
  totalMediaItems = 0
  selectedItems: any[] = [];
  linkToList : any[] = []
  rssFeedToList : any[] = []
  selectedItemId : any;
  isChecked;
  isCheckedName;
  listId : number
  linkId : number
  rssFeedId : number
  clickFrom: string;
  dataId : any;
  showall = false;
  tabId: number;
  listIdArray : any[]=[];
  eventIds: number;
  mediaItemIds: any;
  mediaseriesId: any;
  ebookIds:any;
  ebookReaderId:any
  calendarIds: number;
  albumId : any;
  musicId : any;
  @ViewChild ('searchInput') searchInput : ElementRef
  constructor(
    public libraryService: LibraryServiceService,
    public messageService: MessageService,
    public artWorkService: ArtWorkService,
    public SharedService : SharedService,
    public eventService : EventService,
    public appsService: AppsService,
    public ebook:EbookreaderService,
    public mediaService: MediaService
  ) { }

  ngOnInit(): void {

    this.getSubscriptionData()

    // this.libraryService.panelState$.
    //   subscribe(
    //     (state: any) => {
    //       this.sidePanel = state.showPanel;
    //       this.overlay = state.showPanel
    //       this.clickFrom = state.clickFrom
    //     }
    //   )

    //   this.libraryService.panelState$.subscribe(
    //     (state:any) => {
    //       this.sidePanel = state.showPanel;
    //       this.overlay = state.showPanel
    //       this.dataId = state.Id
    //       this.clickFrom = state.clickFrom
    //     }
    //   )

    //   this.getListItem(1,5)

    //   this.libraryService.addLink$.subscribe((select:any) => {
    //     this.linkToList = select
    //     console.log(this.linkToList)
    //   } )

    //   this.libraryService.addRssFeed$.subscribe((response:any) =>{
    //     this.rssFeedToList = response
    //     console.log(this.linkToList)
    //   } )

  }

  ngAfterViewInit() {
   
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      this.getListItem(1,10);
    })).subscribe();
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }


  getSubscriptionData(){
    // this.subsciptionData.add(
    // this.libraryService.panelState$.
    // subscribe(
    //   (state: any) => {
    //     this.sidePanel = state.showPanel;
    //     this.overlay = state.showPanel
    //     this.clickFrom = state.clickFrom
    //     this.getListItem(1,5)
    //   }

    // )

    // )


    this.subsciptionData.add(
    this.libraryService.panelState$.subscribe(
      (state:any) => {
        this.sidePanel = state.showPanel;
        this.overlay = state.showPanel
        this.dataId = state.Id
        this.clickFrom = state.clickFrom
        this.listIdArray = []
        this.listId = 0
        this.getListItem(1,10)
      }
    )
    )
    // this.getListItem(1,5)
    this.subsciptionData.add(
    this.libraryService.addLink$.subscribe((select:any) => {
      this.linkToList = select
    } )
    )

    this.subsciptionData.add(
    this.libraryService.addRssFeed$.subscribe((response:any) =>{
      this.rssFeedToList = response
      console.log(this.linkToList)
    } )
    )

  }

  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.showall = false;
    this.searchInput.nativeElement.value = null
    this.libraryService.updatePanelState({ showPanel: false })
  }

  showmore(){
    this.showall = true
    this.getListItem(1,this.totalMediaItems);
  }

  getListItem(page,size){
    this.libraryService.getListItems(page,size,this.searchInput ? this.searchInput.nativeElement.value : '').subscribe((response:any)=>
    {
      this.trendingList = response.data.content ? response.data.content : [];
      this.totalMediaItems = response.data.totalElements;
      if(response.data.totalElements < 5){
        this.showall = true
      }
      this.trendingList.forEach(data =>{
        if(data.squareArtwork){
          // if(data.squareArtwork.document.bdImage === true){
            if(data.squareArtwork.artworkType === "SQUARE"){var width = 150;var height = 150;}
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.SharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
          // }
        }
        else if(data.bannerArtwork){
          // if(data.bannerArtwork.document.bdImage === true){
            if(data.bannerArtwork.artworkType === "BANNER"){var width = 150;var height = 56;}
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.SharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
          // }
        }
        else if(data.wideArtwork){
          // if(data.wideArtwork.document.bdImage === true){
            if(data.wideArtwork.artworkType === "WIDE"){var width = 150;var height = 70;}
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.SharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
          // }
        }
      })
    });
  }


  selectItems(event,item) {
  if(this.clickFrom === 'subtabs'){
    this.listIdArray.push(item.id)
  }else{
    this.listId = event.target.value
  }
  console.log(this.listId)



    // if (item === 'all') {
    //   this.selectAll = event.target.checked;
    //   this.trendingList.forEach((el) => el.itemChecked = event.target.checked);
    //   this.selectedItems = event.target.checked ? this.trendingList : [];
    // } else {

      // this.trendingList.forEach((el) => {
      //   if (el.id === item.id) {
      //     el.itemChecked = event.target.checked;
      //   }
      // });
      // this.selectedItems = this.trendingList.filter((el) => el.itemChecked);
      // console.log(this.selectItems)
      // console.log(this.selectedItems[0].id)
      // this.listId = this.selectedItems[0].id
      // console.log(this.listId)

      // this.selectAll = (this.selectedItems.length === this.trendingList.length);
    // }

    // this.isChecked = !this.isChecked;
    // this.isCheckedName = event.target.name;

  }

  apply(){
    if(this.clickFrom  === 'link'){
      this.addLinkToList()
    }
    else if(this.clickFrom  === 'rss'){
      this.addRssFeedToList()
    }
    else if (this.clickFrom === 'buildOwnView'){
      this.changeFolder();
    }
    else if (this.clickFrom === 'subtabs'){
      this.createSubtab();
    }
    else if(this.clickFrom === 'eventList'){
      this.addToFolderFromEvent();
    }
    else if(this.clickFrom === 'mediaList' || this.clickFrom === 'bulkMediaItem' || this.clickFrom === 'liveList' || this.clickFrom === 'bulkLiveItem' ){
      this.addToFolderFromMediaItem();
    }
    else if(this.clickFrom === 'mediaSeries' || this.clickFrom === 'bulkMediaSeries'){
      this.addToFolderFromMediaSeries();
    }

    else if(this.clickFrom === 'ebook' || this.clickFrom === 'bulkEbook'){
      this.addToFolderFromEbook();
    }

    else if(this.clickFrom === 'ebookreader' || this.clickFrom === 'bulkEbookreader'){
      this.addToFolderFromEbookReader();
    }

    else if (this.clickFrom === 'calenderList'){
      this.addToFolderFromCalender();
    }
    else if (this.clickFrom === 'album' || this.clickFrom === 'bulkAlbum' ){
      this.addToFolderFromAlbum();
    }
    else if (this.clickFrom === 'song' || this.clickFrom === 'bulkSong' ){
      this.addToFolderFromSong();
    }
  }

  addToFolderFromCalender(){
    this.calendarIds = this.dataId
  this.eventService.calenderAddToFolder(this.listId,this.calendarIds).subscribe(() => {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: `Calender added to Folder successfully` });
    // this.router.navigate(['/media-module/media/on-demand']);
    // this.libraryService.sendUpdateToCreate({ update: true })
    this.libraryService.updatePanelState({ showPanel: false })
  }, (error) => {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
  });
  }

  addToFolderFromMediaItem(){
    // this.mediaItemIds = this.dataId
    this.mediaItemIds = ''
    if(this.clickFrom === 'bulkMediaItem' || this.clickFrom === 'bulkLiveItem'){
      this.dataId.forEach(el => {
      this.mediaItemIds += `mediaItemIds=${el.id}&`;
    });
  }
  else{
    this.mediaItemIds += `mediaItemIds=${this.dataId}&`
  }
    this.mediaService.mediaitemAddToFolder(this.listId,this.mediaItemIds.slice(0, -1)).subscribe(() => {
      if(this.clickFrom === 'liveList' || this.clickFrom === 'bulkLiveItem'){
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Livestream added to Folder successfully` });
      }else{
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Video item added to Folder successfully` });
      }
      // this.router.navigate(['/media-module/media/on-demand']);
      this.libraryService.sendUpdateToCreate({ update: true })
      this.libraryService.updatePanelState({ showPanel: false })
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  addToFolderFromMediaSeries(){

    this.mediaseriesId = ''
    if(this.clickFrom === 'bulkMediaSeries'){
      this.dataId.forEach(el => {
      this.mediaseriesId += `mediaseriesId=${el.id}&`;
    });
  }
  else{
    this.mediaseriesId += `mediaseriesId=${this.dataId}&`
  }
    this.mediaService.mediaSeriesAddToFolder(this.listId,this.mediaseriesId.slice(0, -1)).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Video series added to Folder successfully` });
      // this.router.navigate(['/media-module/media/on-demand']);
      this.libraryService.sendUpdateToCreate({ update: true })
      this.libraryService.updatePanelState({ showPanel: false })
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }





  addToFolderFromEbook(){
    this.ebookIds = []
    if(this.clickFrom === 'bulkEbook'){
      this.dataId.forEach(el => {
      this.ebookIds += `ebookIds=${el.id}&`;
    });
  }
  else{
    this.ebookIds += `ebookIds=${this.dataId}`
  }
    this.ebook.EbookAddtoFolder(this.listId,this.ebookIds).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Ebook added to Folder successfully` });
      // this.router.navigate(['/media-module/media/on-demand']);
      this.libraryService.sendUpdateToCreate({ update: true })
      this.libraryService.updatePanelState({ showPanel: false })
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }


  addToFolderFromEbookReader(){

        this.ebookReaderId = []
        if(this.clickFrom === 'bulkEbookreader'){
          this.dataId.forEach(el => {
          this.ebookReaderId += `mediaseriesId=${el.id}`;
        });
      }
      else{
        this.ebookReaderId += `mediaseriesId=${this.dataId}`
      }
      this.mediaService.mediaSeriesAddToFolder(this.listId,this.ebookReaderId).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Ebook Library added to Folder successfully` });
          // this.router.navigate(['/media-module/media/on-demand']);
          this.libraryService.sendUpdateToCreate({ update: true })
          this.libraryService.updatePanelState({ showPanel: false })
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }







  addToFolderFromAlbum(){
    this.albumId = ''
    if(this.clickFrom === 'bulkAlbum'){
      this.dataId.forEach(el => {
      this.albumId += `albumId=${el.id}&`;
    });
  }
  else{
    this.albumId += `albumId=${this.dataId}&`
  }
    this.mediaService.albumAddToFolder(this.listId,this.albumId.slice(0, -1)).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Album added to Folder successfully` });
      // this.router.navigate(['/media-module/media/on-demand']);
      this.libraryService.sendUpdateToCreate({ update: true })
      this.libraryService.updatePanelState({ showPanel: false })
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  addToFolderFromSong(){
    this.musicId = ''
    if(this.clickFrom === 'bulkSong'){
      this.dataId.forEach(el => {
      this.musicId += `musicId=${el.id}&`;
    });
  }
  else{
    this.musicId += `musicId=${this.dataId}&`
  }
    this.mediaService.songAddToFolder(this.listId,this.musicId.slice(0, -1)).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Song added to Folder successfully` });
      // this.router.navigate(['/media-module/media/on-demand']);
      this.libraryService.sendUpdateToCreate({ update: true })
      this.libraryService.updatePanelState({ showPanel: false })
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }



  addToFolderFromEvent(){
    this.eventIds = this.dataId
  this.eventService.eventAddToFolder(this.listId,this.eventIds).subscribe(() => {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: `Event added to Folder successfully` });
    // this.router.navigate(['/media-module/media/on-demand']);
    // this.libraryService.sendUpdateToCreate({ update: true })
    this.libraryService.updatePanelState({ showPanel: false })
  }, (error) => {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
  });
  }

  createSubtab(){
    let dataToSend = {};
    dataToSend['catalogIds'] = this.listIdArray;
    this.tabId = this.dataId
  this.appsService.createSubtab(dataToSend,this.tabId).subscribe(() => {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: `Subtab created successfully` });
    // this.router.navigate(['/media-module/media/on-demand']);
    this.libraryService.sendUpdateToCreate({ update: true })
    this.libraryService.updatePanelState({ showPanel: false })
  }, (error) => {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
  });
  }

  changeFolder(){
    if(this.dataId ){
      this.tabId = this.dataId
      this.libraryService.changetabFolderdata(this.listId,this.tabId,'folder').subscribe((response:any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Folder is changed successfully.' });
        console.log(response)
        this.showall = false;
        this.libraryService.sendUpdateToCreate({ update: true })
        this.libraryService.updatePanelState({ showPanel: false })
      }
      ,(error) =>{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });

    }
  }

  addLinkToList(){
    if(this.dataId ){
      this.linkId = this.dataId
      this.libraryService.addLinkList(this.listId,this.linkId).subscribe((response:any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Link added to Folder Successfully.' });
        console.log(response)
        this.libraryService.updatePanelState({ showPanel: false })
      }
      ,(error) =>{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });

    }

    else{
      let filter = '';
    this.linkToList.forEach(el => {
      filter += `${el.id}&`;
      console.log(filter)
    });
    this.libraryService.addLinkList(this.listId,filter).subscribe((response:any) => {
      console.log(response)
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Link added to Folder Successfully.' });
      this.libraryService.updatePanelState({ showPanel: false })
    }
    ,(error) =>{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    }
  }

  addRssFeedToList(){
    if(this.dataId ){
      this.rssFeedId = this.dataId
      this.libraryService.addRssFeedList(this.listId,this.rssFeedId).subscribe((response:any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'RSS Feed added to Folder Successfully.' });
        console.log(response)
        this.libraryService.updatePanelState({ showPanel: false })
      }
      ,(error) =>{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });

    }

    else{
      let filter = '';
    this.rssFeedToList.forEach(el => {
      filter += `${el.id}&`;
      console.log(filter)
    });
    this.libraryService.addRssFeedList(this.listId,filter).subscribe((response:any) => {
      console.log(response)
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'RSS Feed added to Folder Successfully.' });
      this.libraryService.updatePanelState({ showPanel: false })
    }
    ,(error) =>{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    }
  }


}
