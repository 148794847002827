import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../../../services/shared/shared.service'
import { AppAnimation } from './../../../../utilities/animations/animations';
import { LibraryServiceService } from './../../../../services/library/library-service.service'
import { MobileListDesign } from './../../../../models/library.model'
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { Subscription } from 'rxjs'
import { appConfig } from 'src/app/app.config';
import { stat } from 'fs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-mobile-app-list',
  templateUrl: './mobile-app-list.component.html',
  styleUrls: ['./mobile-app-list.component.css'],
  animations: AppAnimation
})
export class MobileAppListComponent implements OnInit {
  sidePanel = false;
  overlay = false;
  offActive = true;
  rowActive = true;
  carouselActive = false;
  gridActive = false;
  stackActive = false;
  listDesign: MobileListDesign
  headerType: string
  itemLayout: string
  catalogId: number
  staticImageId: number
  mobileListDesignData: any[] = []
  itemId: number;
  Id: number
  imagePath: string
  artworkList: any = {};
  staticActive = false
  mainTab = false
  subTab = true
  trendingList = Array
  mediaSerieId: any[] = []
  mediaItemId: any[] = []
  combined: any[] = []
  dataList: any[] = []
  updatedArray: any[] = []
  id: number
  staticImageData: any[] = []
  staticImageName: string
  newDataArray: any[] = []
  newDataArray1: any[] = []
  sendDataArray: any[] = []
  carousalData: any[] = []
  carouselContent: any[] = []
  subsciptionData = new Subscription();
  subsciptionData2 = new Subscription();
  eventPanel = false
  calenderPanel = false
  listDesignImages: any;
  infoMsg = false;
  infoCount = 0
  staticArtworkId: number;
  imagePathWide: any
  withWideArtwork = true;
  clickedFrom: any
  carouselArtworkType: any

  drop(event: CdkDragDrop<string[]>, type) {
    moveItemInArray(this.dataList, event.previousIndex, event.currentIndex);
    if (type === 'content') {
      let id = this.dataList[event.currentIndex]['id'];
      var tabIndex = event.currentIndex;
      this.dataList[event.currentIndex].sortOrder = tabIndex
      this.combined[event.currentIndex].sortOrder = tabIndex
    }
  }
  constructor(
    public sharedService: SharedService,
    public libraryService: LibraryServiceService,
    public messageService: MessageService,
    public router: Router,
    public artWorkService: ArtWorkService
  ) {
    this.listDesign = new MobileListDesign()
  }

  ngOnInit(): void {
    this.changeImages()
    this.getSubscription2()
    this.libraryService.panel1$.subscribe((state: any) => {
      this.sidePanel = state.showPanel
      this.clickedFrom = state.clickedFrom
      if (this.clickedFrom !== "mobileApp") {
        this.mainTab = true
        this.subTab = false
      }
      else {
        this.mainTab = false
        this.subTab = true
      }
      // this.listDesignImages = null
      this.overlay = state.showPanel
      if (state.id) {
        this.id = state.id
      }
      if (state.eventPanel) {
        this.eventPanel = state.eventPanel
        if (this.eventPanel) {
          this.offActive = true
          this.rowActive = true
          // this.offActive = false
        }
      }
      if (state.calenderPanel) {
        this.calenderPanel = state.calenderPanel
        if (this.calenderPanel) {
          this.offActive = true
          this.rowActive = true
          // this.offActive = false
        }
      }
    })

    this.libraryService.sendCatalog$.subscribe((id: any) => {
      this.catalogId = id
    })

    this.headerType = 'OFF'
    this.itemLayout = 'ROWS'


    // this.libraryService.sendMobileId$.subscribe((response: any) => {
    //   this.itemId = response
    //   this.getListDesign(this.itemId)

    // })

    this.libraryService.sendCaraousalImage$.subscribe((response: any) => {
      this.carousalData = response.map((el, i) => ({
        id: el.document.id,
      }))
      this.carousalData = []
      response.forEach(el => {
        this.carousalData.push(el.id)

      })
    })

    this.libraryService.sendStaticImage$.subscribe((response: any) => {

      this.staticImageId = response?.document?.id
      this.staticArtworkId = response?.id
      this.staticImageData = (response)



      if (this.staticImageData) {
        if (this.staticImageData['artworkType'] === 'WIDE') {
          // var width = 150; var height = 150;
          // let id = this.staticImageData['document'].id
          // this.staticImageName = this.staticImageData['document'].name
          // this.staticImageData['document']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          var width = 80; var height = 45;
          let id = this.staticImageData['document'].id
          this.staticImageName = this.staticImageData['document'].name
          this.staticImageData['document']['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePathWide = this.staticImageData['document'].newWideimg
          this.imagePath = null
        }
        else {
          var width = 120; var height = 40;
          let id = this.staticImageData['document'].id
          this.staticImageName = this.staticImageData['document'].name
          this.staticImageData['document']['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePath = this.staticImageData['document'].newBannerimg
          this.imagePathWide = null
        }
        // var width = 150; var height = 150;
        // let id = this.staticImageData['document'].id
        // this.staticImageName = this.staticImageData['document'].name
        // this.staticImageData['document']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width

      }
      // this.imagePath = this.staticImageData['document'].newimg

    })



    this.libraryService.sendMobileData1$.subscribe((response: any) => {
      this.mediaSerieId = response
    })

    this.libraryService.mediaItem$.subscribe((response: any) => {
      this.mediaItemId = response

    })

    this.libraryService.sendMobileData2$.subscribe((response: any) => {
      this.combined = response
      // if(this.combined.length === 0){
      //   this.dataList = []
      // }
      this.combined.forEach((element) => {
        this.dataList.forEach((el, i) => {
          if (element.id === el.id && element.type === el.type) {
            this.dataList.splice(i, 1)
          }
        })
      })
      // this.dataList = this.dataList.filter(val => !this.combined.includes(val));

      this.combined = [...this.combined.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];

      this.combined.forEach(data => {
        if (data.squareArtworkId) {
          var width = 150; var height = 150;
          let id = data.squareArtworkId;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

        }
        if (data.bannerArtworkId) {
          var width = 300; var height = 100;
          let id = data.bannerArtworkId;
          data['newbannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

        }
        if (data.wideArtworkId) {
          var width = 320; var height = 180;
          let id = data.wideArtworkId;
          data['newwideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

        }
      })
      this.dataList.forEach(data => {
        if (data.squareArtwork.document.id) {
          var width = 150; var height = 150;
          let id = data.squareArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

        }
        if (data.bannerArtwork.document.id) {
          var width = 300; var height = 100;
          let id = data.bannerArtwork.document.id;
          data['newbannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

        }
        if (data.wideArtwork.document.id) {
          var width = 320; var height = 180;
          let id = data.wideArtwork.document.id;
          data['newwideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

        }
      })


    })

    this.getSubscription()

    this.listDesign.itemImages = 'SQUARE'


  }

  marginSlider(event) {
    if (!event.target.checked) {
      this.listDesign.shadow = false
    }
  }

  changeStaticType(type) {
    if (type === 'WIDE') {
      this.withWideArtwork = true
      this.imagePathWide = this.staticImageData ? this.staticImageData['staticImage'].newWideimg : this.staticImageData ? this.staticImageData['document'].newWideimg : ''
    }
    else {
      this.withWideArtwork = false
      this.imagePath = this.staticImageData ? this.staticImageData['staticImage'].newBannerimg : this.staticImageData ? this.staticImageData['document'].newBannerimg : ''
    }
  }

  getSubscription2() {
    this.subsciptionData2.add(
      this.libraryService.sendartworktype$.subscribe((response: any) => {
        this.carouselArtworkType = response
        // //  this.clickedFrom = response.clickedFrom
        //  console.log(response,"type,,,,,");

      }),
    )
  }

  getSubscription() {
    this.subsciptionData.add(
      this.libraryService.sendMobileId$.subscribe((response: any) => {
        this.itemId = response
        if (this.clickedFrom !== 'mobileApp') {
          this.listDesignImages = null
          this.combined = []
          this.getListDesign(this.itemId)
        }
      }),
    )

    if (this.clickedFrom !== 'mobileApp') {

      this.subsciptionData.add(
        this.libraryService.sendartworktype$.subscribe((response: any) => {
          this.carouselArtworkType = response
        }),
      )

      this.subsciptionData.add(
        this.libraryService.senddesignddata$.subscribe((response: any) => {
          setTimeout(() => {
            this.listDesign = new MobileListDesign(response)
            this.headerType = response.headerType
            this.itemLayout = response.itemLayout
            // this.staticImageId = response.staticImageId
            // this.imagePath = response.staticImage.path

            this.dataList = response.carouselContentsList ? response.carouselContentsList : [];
            console.log(this.dataList);

            if (this.dataList) {
              this.dataList.forEach(data => {
                if (data.squareArtwork) {
                  if (data.squareArtwork.artworkType === "SQUARE") { var width = 150; var height = 150; }
                  let id = data.squareArtwork.document.id;
                  data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
                }
                if (data.wideArtwork) {
                  if (data.wideArtwork.artworkType === "WIDE") { var width = 320; var height = 180; }
                  let id = data.wideArtwork.document.id;
                  data.wideArtwork.document['newwideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
                }
                if (data.bannerArtwork) {
                  if (data.bannerArtwork.artworkType === "BANNER") { var width = 300; var height = 100; }
                  let id = data.bannerArtwork.document.id;
                  data.bannerArtwork.document['newbannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
                }
              })
            }
            // this.combined = this.dataListsss
            this.mediaSerieId = this.dataList.map((el) => ({
              id: el.id,
              type: el.type,
            }))
            // this.dataList = [...this.newDataArray1,...this.dataList]
            if (this.headerType === 'OFF') {
              this.offActive = true;
              this.carouselActive = false;
              this.staticActive = false
            }
            else if (this.headerType === 'CAROUSEL') {
              this.carouselActive = true;
              this.offActive = false;
              this.staticActive = false
            }
            else {
              this.staticActive = true
              this.carouselActive = false
              this.offActive = false
            }
            if (this.itemLayout === 'ROWS') {
              this.rowActive = true;
              this.gridActive = false;
              this.stackActive = false;
            }
            else if (this.itemLayout === 'GRID') {
              this.gridActive = true;
              this.rowActive = false;
              this.stackActive = false;

            } else {
              this.stackActive = true;
              this.rowActive = false;
              this.gridActive = false
            }
            if (response.staticImage) {
              this.staticImageId = response.staticImage.id
            }
            else {
              this.staticImageData = null
              this.imagePath = null
              this.staticImageId = null
            }
            if (response.staticArtworkId) {
              this.staticArtworkId = response.staticArtworkId
            }

            if (response.bannerArtworkIds) {
              this.carousalData = response.bannerArtworkIds
            }

            // this.imagePath = response.staticImage.path
            this.staticImageData = response

            if (this.staticImageData['staticImage']) {
              // if (this.staticImageData['staticImage'].bdImage === true) {
              if (response.staticType === 'WIDE') {
                this.withWideArtwork = true
                var width = 80; var height = 45;
                let id = this.staticImageData['staticImage'].id
                this.staticImageName = this.staticImageData['staticImage'].name
                this.staticImageData['staticImage']['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
                this.imagePathWide = this.staticImageData['staticImage'].newWideimg
              }
              else if (response.staticType === 'Banner') {
                this.withWideArtwork = false
                var width = 120; var height = 40;
                let id = this.staticImageData['staticImage'].id
                this.staticImageName = this.staticImageData['staticImage'].name
                this.staticImageData['staticImage']['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
                this.imagePath = this.staticImageData['staticImage'].newBannerimg
              }
            }
          }, 500);
        }),
      )
    }
  }

  back() {
    this.mainTab = true
    this.subTab = false
  }

  openCarouselPanel(mobile) {
    this.libraryService.sendArtworkType({ carouselArtworkType: this.carouselArtworkType, clickedFrom: 'mobileApp' })
    if (this.dataList.length > 0) {
      this.libraryService.sendDataList(this.dataList.length)
      this.libraryService.sendSaveCarouselData(this.dataList)
    }
    else {
      this.libraryService.sendDataList(this.combined.length)
      this.libraryService.sendSaveCarouselData(this.combined)
    }
    this.libraryService.updatePanel({ showPanel: true, opacity: true, clickedFrom: mobile })
    this.libraryService.sendCarouselMobileLengthData(this.mediaSerieId.length)


  }

  cancelPanel(){
    this.libraryService.cancelPanel(true)
  }

  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.infoCount = 0
    this.infoMsg = false
    this.combined = []
    this.libraryService.updatePanel1({ showPanel: false })
    if (!this.itemId) {
      this.offActive = true;
      this.rowActive = true;
      this.carouselActive = false;
      this.gridActive = false;
      this.stackActive = false;
      this.staticActive = false
      this.listDesign = new MobileListDesign()
      this.headerType = 'OFF'
      this.itemLayout = 'ROWS'
      this.staticImageData = null
      this.imagePath = null
      this.staticImageId = null
      this.staticArtworkId = null
      this.eventPanel = false
      this.calenderPanel = false
    }
  }

  closeInfoMsg() {
    this.infoMsg = false
  }

  openPanel(clickedFrom) {
    if (clickedFrom === 'static') {
      if (this.listDesign.staticType === 'WIDE') {
        this.sharedService.updatePanelState({ showPanel: true, type: 'wide', opacity: true, static: true, clickedFrom: clickedFrom, artworkId: this.staticArtworkId, id: this.staticImageId })
      }
      else {
        this.sharedService.updatePanelState({ showPanel: true, type: 'banner', opacity: true, static: true, clickedFrom: clickedFrom, artworkId: this.staticArtworkId, id: this.staticImageId })
      }
    }
    else {
      if (this.carousalData.length > 0 && clickedFrom === 'carousel') {
        this.libraryService.sendBannerCarousel(this.carousalData)
      }
      else if (this.carousalData.length === 0 && clickedFrom === 'carousel') {
        this.libraryService.sendBannerCarousel(this.carousalData)
      }
      this.sharedService.updatePanelState({ showPanel: true, type: 'banner', opacity: true, static: true, clickedFrom: clickedFrom })

    }

  }

  staticActivate(STATIC) {
    this.staticActive = true
    this.carouselActive = false
    this.offActive = false
    this.headerType = 'STATIC'

    if (this.staticActive && this.stackActive && (this.infoCount === 0)) {
      this.infoMsg = true
      this.infoCount = this.infoCount + 1
    }
    this.changeImages();
  }

  offActivate(OFF) {
    this.offActive = true;
    this.carouselActive = false;
    this.staticActive = false
    this.headerType = 'OFF'
    this.changeImages();
    this.infoMsg = false
  }

  carouselActivate(CAROUSEL) {
    this.carouselActive = true;
    this.offActive = false;
    this.staticActive = false
    this.headerType = 'CAROUSEL'
    if (this.rowActive && this.carouselActive) {
      this.listDesign.marginEdges = 'SHARP'
    }
    this.changeImages();
  }

  getListDesignChange(data) {
    this.changeImages();
  }

  rowActivate(ROWS) {
    this.rowActive = true;
    this.gridActive = false;
    this.stackActive = false;
    this.itemLayout = 'ROWS'

    this.listDesign.itemTitles = 'BELOW'
    this.listDesign.itemDisplay = 'IMAGE'
    this.listDesign.itemImages = 'SQUARE'
    this.listDesign.margins = false
    this.listDesign.shadow = false
    this.listDesign.marginEdges = null
    this.listDesign.marginType = null
    if (this.rowActive && this.carouselActive) {
      this.listDesign.marginEdges = 'SHARP'
    }
    this.changeImages();
  }

  gridActivate(GRID) {
    this.gridActive = true;
    this.rowActive = false;
    this.stackActive = false;
    this.itemLayout = 'GRID'

    this.listDesign.itemTitles = 'BELOW'
    this.listDesign.itemImages = 'SQUARE'
    this.listDesign.margins = false
    this.listDesign.shadow = false
    this.listDesign.marginEdges = 'SHARP'
    this.listDesign.marginType = 'VERYTHIN'
    this.listDesign.itemDisplay = null
    this.changeImages();
  }

  stackActivate(STACKED) {
    this.stackActive = true;
    this.rowActive = false;
    this.gridActive = false
    this.itemLayout = 'STACKED'

    this.listDesign.itemTitles = 'BELOW'
    this.listDesign.itemImages = 'WIDE'
    this.listDesign.margins = false
    this.listDesign.shadow = false
    this.listDesign.marginEdges = 'SHARP'
    this.listDesign.marginType = 'VERYTHIN'
    this.listDesign.itemDisplay = null
    this.changeImages();

    if (this.staticActive && this.stackActive && (this.infoCount === 0)) {
      this.infoMsg = true
      this.infoCount = this.infoCount + 1
    }

  }

  apply() {
    if (this.clickedFrom !== 'mobileApp') {
      this.back()
    }
    else {
      this.createListDesign();
    }
  }

  cancel() {
    if (this.clickedFrom !== 'mobileApp') {
      this.back()
    }
    else {
      this.closePanel();
    }
  }

  createListDesign() {

    const data = (JSON.parse(JSON.stringify(this.listDesign)))
    if (this.eventPanel) {
      data.eventId = this.catalogId
    }
    if (this.calenderPanel) {
      data.calendarId = this.catalogId
    }
    if (this.eventPanel || this.calenderPanel) {
      data.itemTitles = null
    }
    if (!this.eventPanel && !this.calenderPanel) {
      data.catalogId = this.catalogId
    }

    if (this.dataList) {
      this.newDataArray = this.dataList.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: el.type
      }))
      this.mediaSerieId = [...this.mediaSerieId, ...this.newDataArray]
    }
    this.mediaSerieId.forEach((element) => {
      var medId = element.id
      this.newDataArray.forEach((el) => {
        if (medId !== el.id) {
          this.mediaSerieId = [...this.mediaSerieId, ...this.newDataArray]
          this.updatedArray = [element, el]
        }
      })
    })

    this.mediaSerieId = this.mediaSerieId.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }))

    this.sendDataArray = [...this.carousalData.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];

    if (!this.listDesign.margins) {
      data.marginEdges = null
      data.marginType = null
    }
    data.headerType = this.headerType
    if (data.headerType === "STATIC") {
      if (this.staticImageData['document'] ? this.staticImageData['document'].newWideimg : this.staticImageData['staticImage'] ? this.staticImageData['staticImage'].newWideimg : null && data.staticType === 'WIDE') {
        data.staticImageId = this.staticImageId
        data.staticArtworkId = this.staticArtworkId
      }
      else if (this.staticImageData['document'] ? this.staticImageData['document'].newBannerimg : this.staticImageData['staticImage'] ? this.staticImageData['staticImage'].newBannerimg : null && data.staticType === 'BANNER') {
        data.staticImageId = this.staticImageId
        data.staticArtworkId = this.staticArtworkId
      }
      else {
        data.staticImageId = null
        data.staticArtworkId = null
      }
    }

    data.designType = "MOBILE_APP"

    data.itemLayout = this.itemLayout
    // data.staticImageId = this.staticImageId
    data.carouselContentIds = this.carousalData
    data.carouselContent = this.mediaSerieId


    if (this.itemId) {
      this.libraryService.updateListDesign(this.itemId, data).subscribe((response: any) => {

        // this.router.navigate([`/library-module/library/folder/create-list/`+this.id]);
        this.combined = []
        this.mediaSerieId = []


        this.libraryService.sendUpdateToCreate({ update: true })

        this.closePanel()

        // this.getListDesign(this.itemId)

        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item updated successfully` });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      }
      )
    }
    else {
      this.libraryService.createListDesign(data).subscribe((response) => {

        this.itemId = response.body.data.id
        this.combined = []
        this.mediaSerieId = []

        this.libraryService.sendUpdateToCreate({ update: true })

        this.closePanel()

        // this.getListDesign(this.itemId)

        // this.getListItemData(this.id)



        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item created successfully` });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      }
      )
    }
  }



  getListDesign(id) {
    this.libraryService.getListDesign(id).subscribe((response: any) => {
      this.listDesign = new MobileListDesign(response.data)
      this.headerType = response.data.headerType
      this.itemLayout = response.data.itemLayout
      // this.staticImageId = response.data.staticImageId
      // this.imagePath = response.data.staticImage.path

      this.dataList = response.data.carouselContents ? response.data.carouselContents : [];
      console.log(this.dataList);

      if (this.dataList) {
        this.dataList.forEach(data => {
          if (data.squareArtwork) {
            if (data.squareArtwork.artworkType === "SQUARE") { var width = 150; var height = 150; }
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

          }
          if (data.wideArtwork) {
            if (data.wideArtwork.artworkType === "WIDE") { var width = 320; var height = 180; }
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document['newwideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

          }
          if (data.bannerArtwork) {
            if (data.bannerArtwork.artworkType === "BANNER") { var width = 300; var height = 100; }
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document['newbannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;

          }
        })
      }
      // this.combined = this.dataListsss
      this.mediaSerieId = this.dataList.map((el) => ({
        id: el.id,
        type: el.type,
      }))
      // this.dataList = [...this.newDataArray1,...this.dataList]
      if (this.headerType === 'OFF') {
        this.offActive = true;
        this.carouselActive = false;
        this.staticActive = false
      }
      else if (this.headerType === 'CAROUSEL') {
        this.carouselActive = true;
        this.offActive = false;
        this.staticActive = false
      }
      else {
        this.staticActive = true
        this.carouselActive = false
        this.offActive = false
      }
      if (this.itemLayout === 'ROWS') {
        this.rowActive = true;
        this.gridActive = false;
        this.stackActive = false;
      }
      else if (this.itemLayout === 'GRID') {
        this.gridActive = true;
        this.rowActive = false;
        this.stackActive = false;

      } else {
        this.stackActive = true;
        this.rowActive = false;
        this.gridActive = false
      }
      if (response.data.staticImage) {
        this.staticImageId = response.data.staticImage.id
      }
      else {
        this.staticImageData = null
        this.imagePath = null
        this.staticImageId = null
      }
      if (response.data.staticArtworkId) {
        this.staticArtworkId = response.data.staticArtworkId
      }

      if (response.data.bannerArtworkIds) {
        this.carousalData = response.data.bannerArtworkIds
      }

      // this.imagePath = response.data.staticImage.path
      this.staticImageData = response.data

      if (this.staticImageData['staticImage']) {
        // if (this.staticImageData['staticImage'].bdImage === true) {
        if (response.data.staticType === 'WIDE') {
          this.withWideArtwork = true
          var width = 80; var height = 45;
          let id = this.staticImageData['staticImage'].id
          this.staticImageName = this.staticImageData['staticImage'].name
          this.staticImageData['staticImage']['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePathWide = this.staticImageData['staticImage'].newWideimg
        }
        else if (response.data.staticType === 'Banner') {
          this.withWideArtwork = false
          var width = 120; var height = 40;
          let id = this.staticImageData['staticImage'].id
          this.staticImageName = this.staticImageData['staticImage'].name
          this.staticImageData['staticImage']['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePath = this.staticImageData['staticImage'].newBannerimg
        }
        // var width = 150; var height = 150;
        // let id = this.staticImageData['staticImage'].id
        // this.staticImageName = this.staticImageData['staticImage'].name
        // this.staticImageData['staticImage']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
      }
      // if (this.staticImageData['staticImage']) {

      //   this.imagePath = this.staticImageData['staticImage'].newimg
      // }
      this.changeImages()
    })
  }

  deleteItem(item) {

    let data = this.combined[item].id
    let arrData = this.combined[item]
    this.mediaSerieId.forEach((el) => {
      if (el.id === data) {

        var i = this.mediaSerieId.indexOf(el)

        this.mediaSerieId.splice(i, 1)
        // el.itemChecked = false
        let arrData = []
        arrData.push(el)

        this.libraryService.sendRemovedDataFromMobile(arrData)

      }
    })
    let arr = []
    arr.push(arrData)
    this.combined.splice(item, 1)
    this.libraryService.sendRemovedDataFromMobile(arr)
  }

  delete(item, it) {

    this.mediaSerieId.forEach((el, i) => {
      if (el.id === it.id) {
        this.mediaSerieId.splice(i, 1)

      }
    })
    this.dataList.splice(item, 1)
    this.updatedArray = (this.dataList.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }
    )))
  }

  // getListItemData(id) {
  //   this.libraryService.getListItemById(id).subscribe((response: any) => {
  //   })
  // }

  changeImages() {
    if (this.offActive) {
      // off active ********************************************************
      if (this.rowActive) {
        if (this.listDesign.itemDisplay === 'IMAGE') {
          if (this.offActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.shadow) {
            // cha cor Header- off+ layout- rows + item display- images + item images- square + shadows - off
            this.listDesignImages = "assets/images/apps/of-row-image-square-off.png"
          }
          else if (this.offActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.shadow) {
            // cha cor Header- off+ layout- rows + item display- images + item images- square + shadows - on
            this.listDesignImages = "assets/images/apps/of-row-image-square-on.png"
          }
          else if (this.offActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'WIDE' && this.listDesign.shadow) {
            // cha wro Header- off+ layout- rows + item display- images + item images- wide + shadows - on
            this.listDesignImages = "assets/images/apps/of-row-image-wide-on.png"
          }
          else if (this.offActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.shadow) {
            // cha wro Header- off+ layout- rows + item display- images + item images- wide + shadows - off
            this.listDesignImages = "assets/images/apps/of-row-image-wide-off.png"
          }
        }
        else {
          if (this.offActive && this.rowActive && this.listDesign.itemDisplay === 'DATE') {
            // cha Header- off+ layout- rows + item display- date
            this.listDesignImages = 'assets/images/apps/of-row-date.png'
          }
          else if (this.offActive && this.rowActive && this.listDesign.itemDisplay === 'TITLE_ONLY') {
            // cha Header- off+ layout- rows + item display- title only
            this.listDesignImages = 'assets/images/apps/of-row-title.png'
          }
        }
      }
      else if (this.gridActive) {
        if (this.listDesign.itemTitles === 'BELOW') {
          if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha cor Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-grid-below-square-off-off.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-below-square-on-off.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-off-verythin-sharp.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-off-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-off-verythin-curve.png'
          }
          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-off-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-off-thick-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-below-square-on-on.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-on-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-on-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-on-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-square-on-on-thick-curve.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-grid-below-wide-off-off.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-below-wide-on-off.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-off-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-off-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-off-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-off-thick-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            //  cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-below-wide-on-on.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            //  cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-on-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            //  cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-on-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            //  cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            //  cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-on-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            //  cha imp Header- off+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-below-wide-on-on-thick-curve.png'
          }



        }
        else if (this.listDesign.itemTitles === 'OFF') {
          if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha correct Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-grid-off-square-off-off.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-off-square-on-off.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-off-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-off-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-off-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-off-thick-curve.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-on.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-on-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-on-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-on-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- off+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-square-on-on-thick-curve.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-grid-off-wide-off-off.png'
          }


          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-off-wide-on-off.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-off-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-off-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-off-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-off-thick-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/of-grid-off-wide-on-on.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-on-verythin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-on-thin-sharp.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-on-thin-curve.png'
          }

          else if (this.offActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/off-grid-off-wide-on-on-thick-curve.png'
          }



        }
      }
      else if (this.stackActive) {
        if (this.listDesign.itemTitles === 'BELOW') {
          if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-off-off.png'
          }


          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on + margin -thick
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-on-off-margin-thick.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on + margin -very thin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-on-off-margin-verythin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on + margin -thin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-on-off-margin-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item imaxges- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-off-thick-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-off-verythin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-off-thin-curve.png'
          }

          // else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' ) {
          //   // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on
          //   this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-off-verythin-sharp.png'
          // }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THICK' && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-on-on-thick.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THIN' && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-on-on-thin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-wide-on-on-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THICK' && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-on-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THIN' && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-on-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha imp Header- off+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-below-wide-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-off-off.png'
          }


          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-on-off.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-on-off-margin-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-on-off-margin-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/off-stacked-below-banner-on-off-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/off-stacked-below-banner-on-off-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/off-stacked-below-banner-on-off-verythin-curve.png'
          }


          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-on-on-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-on-on-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-below-banner-on-on-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-below-banner-on-on-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-below-banner-on-on-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-below-banner-on-on-verythin-curve.png'
          }

        }
        else if (this.listDesign.itemTitles === 'OVERLAY') {
          if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-off-off.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-on-off-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-on-off-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-on-off-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-wide-on-off-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-wide-on-off-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-wide-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-on-on-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-on-on-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-wide-on-on-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-wide-on-on-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-wide-on-on-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha cor Header- off+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-wide-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            //  ch wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-off-off.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-on-off.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-on-off-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-on-off-verthin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-banner-on-off-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-banner-on-off-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-banner-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-on-on-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-on-on-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-overlay-banner-on-on-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-banner-on-on-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-banner-on-on-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-overlay-banner-on-on-verythin-curve.png'
          }

        }
        else if (this.listDesign.itemTitles === 'OFF') {
          if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            //  cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-off-off.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THICK' && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thick sharp
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-on-off-margin-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-on-off-margin-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'VERYTHIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-on-off-margin-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-wide-on-off-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-wide-on-off-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-wide-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-on-on-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-on-on-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-wide-on-on-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-wide-on-on-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-wide-on-on-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-wide-on-on-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-off-off.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-on-off.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-on-off-margin-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-on-off-margin-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.margins && this.listDesign.marginType === 'THICK' && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/off-stacked-off-banner-on-off-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.margins && this.listDesign.marginType === 'THIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/off-stacked-off-banner-on-off-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.margins && this.listDesign.marginType === 'VERYTHIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/off-stacked-off-banner-on-off-verythin-curve.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-on-on-thick.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-on-on-thin.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/of-stacked-off-banner-on-on-verythin.png'
          }

          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-banner-on-on-thick-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-banner-on-on-thin-curve.png'
          }
          else if (this.offActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- off+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/off-stacked-off-banner-on-on-verythin-curve.png'
          }

        }
      }
    }
    else if (this.staticActive) {
      // static active ********************************************************
      if (this.rowActive) {
        if (this.listDesign.itemDisplay === 'IMAGE') {
          if (this.staticActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- rows + item display- image + item images- square + shadows - on
            this.listDesignImages = 'assets/images/apps/stat-rows-image-square-on.png'
          }
          else if (this.staticActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.shadow) {
            // cha Header- Static+ layout- rows + item display- image + item images- square + shadows - off
            this.listDesignImages = 'assets/images/apps/stat-rows-image-square-off.png'
          }
          else if (this.staticActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'WIDE' && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- rows + item display- image + item images- wide + shadows - on
            this.listDesignImages = 'assets/images/apps/stat-rows-image-wide-on.png'
          }
          else if (this.staticActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- rows + item display- image + item images- wide + shadows - off
            this.listDesignImages = 'assets/images/apps/stat-rows-image-wide-off.png'
          }
        }
        else {
          if (this.staticActive && this.rowActive && this.listDesign.itemDisplay === 'DATE') {
            // cha wro Header- Static+ layout- rows + item display- date
            this.listDesignImages = 'assets/images/apps/stat-rows-date.png'
          }
          else if (this.staticActive && this.rowActive && this.listDesign.itemDisplay === 'TITLE_ONLY') {
            // cha wro Header- Static+ layout- rows + item display- title only
            this.listDesignImages = 'assets/images/apps/stat-rows-titleonly.png'
          }
        }
      }
      else if (this.gridActive) {
        if (this.listDesign.itemTitles === 'BELOW') {
          if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-off-off.png'
          }

          // else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
          //   // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
          //   this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off-verythin-sharp.png'
          // }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-off-thick-curve.png'
          }



          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-on.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-on-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-on-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-on-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-on-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-square-on-on-thick-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-off-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-off-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-off-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-off-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-off-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-off-thick-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-on.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-on-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-on-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-on-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-on-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-below-wide-on-on-thick-curve.png'
          }


        }
        else if (this.listDesign.itemTitles === 'OFF') {
          if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-off-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-off-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-off-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-off-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-off-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-off-thick-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-on.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-on-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-on-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-on-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-on-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-square-on-on-thick-curve.png'
          }


          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-off-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-off.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-off-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-off-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-off-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-off-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-off-thick-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-on.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-on-verythin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-on-thin-sharp.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-on-verythin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-on-thin-curve.png'
          }

          else if (this.staticActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // wro Header- Static+ layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/stat-grid-off-wide-on-on-thick-curve.png'
          }

        }
      }
      else if (this.stackActive) {
        if (this.listDesign.itemTitles === 'BELOW') {
          if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-off-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THICK' && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'Thick'
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-off-margin-thick.png'
          }

          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'VERYTHIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'VERYTHIN'
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-off-margin-very-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-off-margin-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THICK' && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-off-margin-Thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'THIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-off-margin-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.marginType === 'VERYTHIN' && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-off-margin-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-on-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-on-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-on-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-on-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-on-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-wide-on-on-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-off-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-on-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-on-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-on-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-on-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-on-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-below-banner-on-on-verythin-curve.png'
          }
        }
        else if (this.listDesign.itemTitles === 'OVERLAY') {
          if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-off-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-off-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-off-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin very thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-off-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-off-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-off-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin very thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-off-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-on-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-on-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-on-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-on-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-on-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-wide-on-on-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-off-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-off-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-off-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-off-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-off-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-off-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-off-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-on-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-on-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-on-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-on-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-on-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-overlay-banner-on-on-verythin-curve.png'
          }
        }
        else if (this.listDesign.itemTitles === 'OFF') {
          if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-off-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-off-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-off-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-off-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-off-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-off-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-off-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-on-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-on-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-on-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-on-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-on-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-wide-on-on-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-off-off.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-off-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-off-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-off-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-off-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-off-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-off-verythin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-on-thick.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-on-thin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-on-verythin.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-on-thick-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-on-thin-curve.png'
          }
          else if (this.staticActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Static+ layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/stat-stacked-off-banner-on-on-verythin-curve.png'
          }
        }
      }
    }
    else if (this.carouselActive) {
      // carousel Activate ********************************************************
      if (this.rowActive) {
        if (this.listDesign.itemDisplay === 'IMAGE') {
          if (this.carouselActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.shadow) {
            // ch Header- Carousel + layout- rows + item display- image + item images- square + shadows - on
            this.listDesignImages = 'assets/images/apps/carous-rows-image-square-on.png'
          }
          else if (this.carouselActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.shadow) {
            // cha Header- Carousel + layout- rows + item display- image + item images- square + shadows - off
            this.listDesignImages = 'assets/images/apps/carous-rows-image-square-off.png'
          }
          else if (this.carouselActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'WIDE' && this.listDesign.shadow) {
            // cha Header- Carousel + layout- rows + item display- image + item images- wide + shadows - on
            this.listDesignImages = 'assets/images/apps/carous-rows-image-wide-on.png'
          }
          else if (this.carouselActive && this.rowActive && this.listDesign.itemDisplay === 'IMAGE' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.shadow) {
            // cha Header- Carousel + layout- rows + item display- image + item images- wide + shadows - off
            this.listDesignImages = 'assets/images/apps/carous-rows-image-wide-off.png'
          }
        } else {
          if (this.carouselActive && this.rowActive && this.listDesign.itemDisplay === 'DATE') {
            // cha Header- Carousel + layout- rows + item display- date
            this.listDesignImages = 'assets/images/apps/carous-rows-date.png'
          }
          else if (this.carouselActive && this.rowActive && this.listDesign.itemDisplay === 'TITLE_ONLY') {
            // cha Header- Carousel + layout- rows + item display- title only
            this.listDesignImages = 'assets/images/apps/carous-rows-title-only.png'
          }
        }
      }
      else if (this.gridActive) {
        if (this.listDesign.itemTitles === 'BELOW') {
          if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-off-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-off-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-off-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-off-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-off-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-off-thick-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-on.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-on-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-on-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-on-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-on-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha  wro Header- Carousel + layout- Grid + item Title-  below + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-square-on-on-thick-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-off-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-off-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-off-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-off-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-off-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-off-thick-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel +  layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-on.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel +  layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-on-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel +  layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-on-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel +  layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-on-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel +  layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-on-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel +  layout- Grid + item Title-  below + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-below-wide-on-on-thick-curve.png'
          }

        }
        else if (this.listDesign.itemTitles === 'OFF') {
          if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-off-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-off-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-off-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-off-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-off-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-off-thick-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-on.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-on-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-on-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-on-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-on-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'SQUARE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- square + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-square-on-on-thick-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-off-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-off.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-off-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-off-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-off-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-off-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-off-thick-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-on.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-on-verythin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-on-thin-sharp.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-on-verythin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-on-thin-curve.png'
          }

          else if (this.carouselActive && this.gridActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.margins && this.listDesign.shadow && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Carousel + layout- Grid + item Title-  off + item images- wide + shadows - on + margin - on
            this.listDesignImages = 'assets/images/apps/carous-grid-off-wide-on-on-thick-curve.png'
          }

        }
      }
      else if (this.stackActive) {
        if (this.listDesign.itemTitles === 'BELOW') {
          if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha  wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-off-off.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-off-margin-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-off-margin-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'SHARP') {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - on
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-off-margin-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-off-margin-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-off-margin-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.margins && !this.listDesign.shadow && this.listDesign.marginEdges === 'CURVE') {
            // cha wro Header- Static+ layout- stacked + item Titles-  Below + item images- wide + shadows - off + margin - listDesign.marginType === 'THIN'
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-off-margin-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-wide-on-on-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-off-off.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-off-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-off-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-off-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-off-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-off-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-off-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'BELOW' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // wro Header- Carousel + layout- stacked + item Titles-  Below + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-below-banner-on-on-verythin-curve.png'
          }
        }
        else if (this.listDesign.itemTitles === 'OVERLAY') {
          if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-off-off.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-off-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-off-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-off-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-off-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-off-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-off-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-wide-on-on-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-off-off.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-off-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-off-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-off-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-off-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-off-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-off-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            //  cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            //  cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            //  cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            //  cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            //  cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OVERLAY' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            //  cha wro Header- Carousel + layout- stacked + item Titles-  overlay + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-overlay-banner-on-on-verythin-curve.png'
          }
        }
        else if (this.listDesign.itemTitles === 'OFF') {
          if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-off-off.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-off-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-off-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-off-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-off-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-off-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-off-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'WIDE' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- wide + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-wide-on-on-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && !this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - off
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-off-off.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thick
            this.listDesignImages = 'assets/images/apps/carousel-stacked-off-banner-off-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thin
            this.listDesignImages = 'assets/images/apps/carousel-stacked-off-banner-off-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin verythin
            this.listDesignImages = 'assets/images/apps/carousel-stacked-off-banner-off-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thick curve
            this.listDesignImages = 'assets/images/apps/carousel-stacked-off-banner-off-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin thin curve
            this.listDesignImages = 'assets/images/apps/carousel-stacked-off-banner-off-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && !this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - off + margin - on margin verythin curve
            this.listDesignImages = 'assets/images/apps/carousel-stacked-off-banner-off-on-verythin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-on-on-thick.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thin
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-on-on-thin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'SHARP' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on verythin
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-on-on-verythin.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THICK' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thick curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-on-on-thick-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'THIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on thin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-on-on-thin-curve.png'
          }
          else if (this.carouselActive && this.stackActive && this.listDesign.itemTitles === 'OFF' && this.listDesign.itemImages === 'BANNER' && this.listDesign.marginType === 'VERYTHIN' && this.listDesign.marginEdges === 'CURVE' && this.listDesign.margins && this.listDesign.shadow) {
            // cha wro Header- Carousel + layout- stacked + item Titles-  off + item images- Banner + shadows - on + margin - on verythin curve
            this.listDesignImages = 'assets/images/apps/carous-stacked-off-banner-on-on-verythin-curve.png'
          }
        }
      }
    }
  }
  openAddlistPannel() {
    this.mainTab = false;
    this.subTab = true;
  }

}
