import { LoginModel } from './../../../models/authenticate.model';
import { SharedService } from './../../../services/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from './../../../services/authenticateService/authenticate.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  login: LoginModel;
  passwordType = false;
  subscriptionPlanName: any;
  profitable: any;
  featureList: any[]=[]
  showGiving: boolean
  formAnimate = false
  setLogoPosition=false
  constructor(
    public authenticateService: AuthenticateService,
    public router: Router,
    public messageService: MessageService,
    public sharedService: SharedService
  )
  {
    this.login = new LoginModel();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.formAnimate = true
    }, 2650);
    setTimeout(() => {
      this.setLogoPosition = true
    }, 2700);
   }

  /**
   * To login user
   */
  loginUser() {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var courseCode = this.login.username;
    if (this.login.username !== undefined && this.login.username) {
      if (courseCode.match(regex)) {
        this.authenticateService.loginUser(this.login).subscribe((response: any) => {
          localStorage.setItem('loggedInUserName', response.body.data.userDetails.username);
          localStorage.setItem('loggedInUser', JSON.stringify(response.body.data));
          if (response.body.data.userDetails.isPaymentDone === true && response.body.data.userDetails.activeCard === true || response.body.data.userDetails.organization.id === 1) {
            if (response.body.data.userDetails.roles[0].name === 'ROLE_ON_BOARDING') {
              this.router.navigate(['/customer-module/customer'])
            }
            else{
              this.router.navigate(['/dashboard']);
            }
          }
          else  {
            this.router.navigate(['/payment-module/payment']);
          }

          this.subscriptionPlanName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.subscriptionPlanName : '';
          this.profitable = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.profit : '';
          this.featureList = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.featureList : null;
          if(this.featureList){
            this.featureList.forEach(el=>{
              if(el.name === 'Giving'){
                this.showGiving = el.isView
              }
            })
          }
          if(this.showGiving && this.profitable === false){
            this.sharedService.updateSideBarControlData(true);
          }
          else{this.sharedService.updateSideBarControlData(false);}

        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter a Valid Email' });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter an Email' });
    }
  }
}
