<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">
    <div  *ngIf="this.clickFrom === 'movecll'" class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">
            <h4>Ebook Libraries</h4>
            <img (click)="closePanel()" src="./../../../../assets/images/library/close.svg" alt="" style="cursor: pointer;">
        </div>
    </div>

    <div  *ngIf="this.clickFrom === 'changecollection'" class="panels-header">
      <div class="d-flex align-items-center justify-content-between p-4">
          <h4>Ebook Libraries</h4>
          <img (click)="closePanel()" src="./../../../../assets/images/library/close.svg" alt="" style="cursor: pointer;">
      </div>
  </div>
  <div  *ngIf="this.clickFrom === 'move' || this.clickFrom === 'change'" class="panels-header">
    <div class="d-flex align-items-center justify-content-between p-4">
        <h4>Video Series</h4>
    </div>
</div>
<div  *ngIf="((this.clickFrom === 'changeAlbum') || (this.clickFrom === 'movealbm'))" class="panels-header">
    <div class="d-flex align-items-center justify-content-between p-4">
        <h4>Album</h4>
    </div>
</div>



    <div  [hidden]="!((this.clickFrom === 'change')||(this.clickFrom === 'move'))" class="app-block">
        <!-- <div class="app-block-title">
            <p class="info-title">Recent Media Series</p>
        </div> -->
        <div class="app-filters d-flex justify-content-center">
            <button [disabled]="!radCheck" (click)="changeMediaSeries()" class="btn btn-white mr-10">Move</button>
            <button class="btn btn-white" (click)="cancel()">Cancel</button>
        </div>

        <div class="app-search app-search-big search-box">
            <input  type="text" placeholder="Search" class="form-control w-100 searchbar-size" #searchInput >
        </div>
    </div>
    <ng-container *ngIf="((this.clickFrom === 'change')||(this.clickFrom === 'move'))">
    <div class="app-block border-color">
        <!-- <div class="app-block-title">
            <p class="info-title" style="margin-bottom: 10px;">Recent Video Series</p>
        </div> -->
        <ul class="recent-item-list m-0">
            <div class="media-series-container">
                <li *ngFor="let series of recentSeries">
                    <label for={{series.id}} style="width: 100%;">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="user-img" [ngStyle]="{'background-color': series.squareArtworkImageColour ? series.squareArtworkImageColour : '#e6e6e6'}">
                                    <img [src]="series.squareArtwork ? series.newimg :  '' "
                                        alt="" onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" >
                                </span>
                                <div class="recent-info">
                                    <p class="pointer ellipsis-text" (change)="medSeries(series)">
                                        {{series.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center" >
                            <input type="radio" name="medSer" id={{series.id}} (change)="medSeries(series)" [checked]="series.itemChecked && radCheck" >
                        </div>
                    </div>
                </label>

                </li>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showall">
                    <button class="btn btn-white" *ngIf="recentSeries.length>4" (click)= "showmore()">Show more</button>
                </div>
            </div>
        </ul>
    </div>
    </ng-container>
   


    <div  [hidden]="!((this.clickFrom === 'changecollection') ||(this.clickFrom === 'movecll'))" class="app-block">
      <!-- <div class="app-block-title">
          <p class="info-title">Recent Media Series</p>
      </div> -->
      <div class="app-filters d-flex justify-content-center">
          <button [disabled]="!radCheck" (click)="changeCollection()" class="btn btn-white mr-10">Move</button>
          <button class="btn btn-white" (click)="cancel()">Cancel</button>
      </div>
    <div class="app-search app-search-big search-box">
        <input type="text" placeholder="Search" class="form-control w-100 searchbar-size" #searchEbook >
    </div>
</div>
    <ng-container *ngIf="((this.clickFrom === 'changecollection') ||(this.clickFrom === 'movecll'))">
    <div class="app-block border-color">
    <!-- <div class="app-block-title">
        <p class="info-title" style="margin-bottom: 10px;">Recent Ebook Libraries</p>
    </div> -->
      <ul class="recent-item-list m-0">
          <div class="media-series-container">
              <li *ngFor="let collection of recentCollections">
                  <label for={{collection.id}} style="width: 100%;">
                  <div class="d-flex justify-content-between">
                      <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                              <span class="user-img" [ngStyle]="{'background-color': collection.squareArtworkImageColour ? collection.squareArtworkImageColour : '#e6e6e6'}">
                                  <img [src]="collection.squareArtwork ? collection.newimg :  '' "
                                      alt="" onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" >
                              </span>
                              <div class="recent-info">
                                  <p class="pointer ellipsis-text" (change)="medCollection(collection)">
                                      {{collection.title}}</p>
                                  <!-- <span>Click here to watch 24/7 Livestream</span> -->
                              </div>
                          </div>
                      </div>
                      <div  style="display: flex;align-items: center" >
                          <input type="radio" name="mdcll" id={{collection.id}} (change)="medCollection(collection)" [checked]="collection.itemChecked && radCheck" >
                      </div>
                  </div>
              </label>

              </li>

              <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showall">
                  <button class="btn btn-white" *ngIf="recentCollections.length>4" (click)= "showmore1()">Show more</button>
              </div>
          </div>
      </ul>
    </div>
   </ng-container>
  


 <!-- Move to album -->


 <div  [hidden]="!((this.clickFrom === 'changeAlbum') || (this.clickFrom === 'movealbm'))"  class="app-block">
    <!-- <div class="app-block-title">
        <p class="info-title">Recent Media Series</p>
    </div> -->
    <div class="app-filters d-flex justify-content-center">
        <button [disabled]="!radCheck" (click)="changeAlbum()" class="btn btn-white mr-10">Move</button>
        <button class="btn btn-white" (click)="cancel()">Cancel</button>
    </div>
    <!-- <div class=" d-flex justify-content-center pt-2">
        
    </div> -->
    <div class="app-search app-search-big search-box">
        <input type="text" placeholder="Search" class="form-control w-100 searchbar-size" #searchAlbum >
    </div>
</div>

<ng-container *ngIf="((this.clickFrom === 'changeAlbum') || (this.clickFrom === 'movealbm'))">
    <div class="app-block border-color">
    <!-- <div class="app-block-title">
        <p class="info-title" style="margin-bottom: 10px;">Recent Album</p>
    </div> -->
    <ul class="recent-item-list m-0">
        <div class="media-series-container">
            <li *ngFor="let album of recentAlbum">
                <label for={{album.id}} style="width: 100%;">
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <span class="user-img" [ngStyle]="{'background-color': album.squareArtworkImageColour ? album.squareArtworkImageColour : '#e6e6e6'}">
                                <img [src]="album.squareArtwork ? album.newimg :  '' "
                                    alt="" onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" >
                            </span>
                            <div class="recent-info">
                                <p class="pointer ellipsis-text" (change)="medAlbum(album)">
                                    {{album.title}}</p>
                                <!-- <span>Click here to watch 24/7 Livestream</span> -->
                            </div>
                        </div>
                    </div>
                    <div  style="display: flex;align-items: center" >
                        <input type="radio" name="mdcll" id={{album.id}} (change)="medAlbum(album)" [checked]="album.itemChecked && radCheck" >
                    </div>
                </div>
            </label>

            </li>

            <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showall">
                <button class="btn btn-white" *ngIf="recentAlbum.length>4" (click)= "showmore2()">Show more</button>
            </div>
        </div>
    </ul>
</div>
</ng-container>

</div>

