import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { UpgradeStoragePlanModel } from "src/app/models/authenticate.model";
import { AuthenticateService } from "src/app/services/authenticateService/authenticate.service";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { StripeService, StripeCardComponent } from "ngx-stripe";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";
import { SharedService } from "src/app/services/shared/shared.service";

@Component({
  selector: 'app-upgrade-subscription-plan',
  templateUrl: './upgrade-subscription-plan.component.html',
  styleUrls: ['./upgrade-subscription-plan.component.css']
})
export class UpgradeSubscriptionPlanComponent implements OnInit {
  basicFeatures = false;
  standardFeatures = false;
  premiumFeatures = false;
  activePlan: any;
  signUp: UpgradeStoragePlanModel;
  showPlans = true;
  showSignupForm = false;
  showCard = false;
  stripeTest: any;
  stripeTokenId: string;
  subDomainName: any;
  subscriptionPlanName: any;
  invoiceId: any;
  disableFields = true;
  ListPlanDetails: Array<any>;
  basicFeature: Array<any>;
  standardFeature: Array<any>;
  premiumFeature: Array<any>;
  planAmountBasic: any;
  planAmountStandard: any;
  planAmountPremium: any;
  premiumTrialDays: any;
  standardTrialDays: any;
  basicTrialDays: any;
  isView: boolean;
  passwordType = false;
  profitOption: any[] = [];

  cardFound = false
  cardSource = ""
  isCCNumberValid = false
  isCCExpValid = false
  isCVVValid = false
  NMIObject = null
  NMIApiRequested = false

  isSubmitting = false;
  alertMessage = '';


  submitted = false;
  cardHolderName: any;
  cardHolderEmail: any;
  cardHolderAddressLine1: any;
  cardHolderAddressLine2: any;
  cardHolderCity = "";
  cardHolderCountry = "US";
  cardHolderState = "";
  cardHolderZipcode: any;

  countryList: any[] = [];
  stateList: any[] = [];
  cityDropdown = false;
  cityList: any[] = [];
  disableBtn = false;
  orderObj: any;
  cardError: any = {
    error: false,
    message: "",
  };
  subscriptionPlanId: any;
  page = 1;
  rows = 10;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#212529",
        color: "#212529",
        backgroundColor: "#fff",
        fontWeight: "500",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#212529",
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: "en",
  };
  totalDiskSpaceBasic: any;
  totalDiskSpaceStandard: any;
  totalDiskSpacePremium: any;
  totalDiskSpaceCustom: any;
  totalDiskSpace: any;
  totalDiskSpaceGB: any;
  totalDiskSpaceTB: any;
  totalDiskSpaceBasicTB: any;
  totalDiskSpaceStandardTB: any;
  totalDiskSpacePremiumTB: any;
  totalDiskSpaceBasicGB: any;
  totalDiskSpaceStandardGB: any;
  totalDiskSpacePremiumGB: any;
  cardBrand = '';
  cardPersonName = ''
  cardexpYear= 0
  cardexpMonth = 0
  cardLast4Digit = 0
  anotherCard = false;
  subscriptionData : any;
  selectedPlanUsage : any = 'select';
  selectedPlanPrice : string = '$ 0';
  dataStorageType: string = '';
  orgId: any;
  selectedPlanData: any;
  basicInfo : any;
  paymentModal = false;


  constructor(
    public authenticateService: AuthenticateService,
    public messageService: MessageService,
    public router: Router,
    public route: ActivatedRoute,
    private renderer: Renderer2,
    public settingsService: SettingsServiceService,
    public stripeService: StripeService,
    public subscrptionService: SubscriptionServiceService,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.signUp = new UpgradeStoragePlanModel();
    // this.profitOption = [
    //   {name: 'Non-profit', value: false},
    //   {name: 'Profit', value: true},
    // ]
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.orderObj = params["params"];
      this.dataStorageType = params["params"].type;
      this.orgId = params["params"].orgId;
      if (this.orderObj.plan) {
        this.activePlan = this.orderObj.plan;
        this.showSignupForm = true;
        this.showPlans = false;
      }
    });
    this.subscriptionPlanId = this.route.snapshot.paramMap.get("id");
    // this.dataStorageType = this.route.snapshot.paramMap.get("id");
    if (this.subscriptionPlanId) {
      this.showPlans = false;
      this.showSignupForm = true;
    } else {
      // this.getSubscriptionPlanList();
    }
    // this.getSecretApiKey();
    // this.getSubscriptionPlanList();
    this.getAllCountryList();
    this.getOrganisationInfoForStorage();
  }




  showBasic() {
    this.basicFeatures = !this.basicFeatures;
  }

  showStandard() {
    this.standardFeatures = !this.standardFeatures;
  }

  showPremium() {
    this.premiumFeatures = !this.premiumFeatures;
  }

  // selectOrgType(e){
  //   this.signUp.profit = e.value.value
  // }


  configureCollectJS(): void {
    const CollectJS = (window as any).CollectJS;
    if (CollectJS && typeof CollectJS.configure === 'function') {
      CollectJS.configure({
        variant: 'inline',
        googleFont: "Montserrat:400",
        customCss: {
          color: "#212529",
          "background-color": "#fff",
          "border-color": "#fff",
          "border-width": "0px",
          "font-weight": "500",
          "font-size": "18px",
          // "text-align" : "right"
        },
        invalidCss: {
          color: "red",
          "border-width": "0px"
        },
        validCss: {
          color: "black",
          "border-color": "#fff",
          "background-color": "#fff",
        },
        placeholderCss: {
          color: "black",
          "background-color": "#fff",
        },
        focusCss: {
          color: "black",
        },
        callback: (token: any) => {
          this.finishSubmit(token);
        },
        validationCallback: (field, status, message) => {
          switch (field) {
            case "ccnumber":
              this.isCCNumberValid = status
              this.cdr.detectChanges()
              break
            case "ccexp":
              this.isCCExpValid = status
              this.cdr.detectChanges()
              break
            case "cvv":
              this.isCVVValid = status
              this.cdr.detectChanges()
              break
          }
          if (this.isCCNumberValid && this.isCCExpValid && this.isCVVValid) {
            CollectJS.startPaymentRequest();
          }
          console.log(field, status, message, "Stusus");
        },
        timeoutDuration: 10000,
        timeoutCallback: () => {
          console.log(
            "The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity"
          );
        },
        fieldsAvailableCallback: () =>{
          console.log("Collect.js loaded the fields onto the form");
        },
        fields: {
          ccnumber: {
            placeholder: 'Card number',
            selector: '#ccnumber'
          },
          ccexp: {
            placeholder: 'MM / YY',
            selector: '#ccexp'
          },
          cvv: {
            placeholder: 'CVV',
            selector: '#cvv'
          }
        }
      });
    } else {
      console.error("CollectJS is not available.");
    }
  }

  loadCollectJSScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = 'https://nowcast.transactiongateway.com/token/Collect.js';
      script.setAttribute('data-tokenization-key', 'GV2byu-5C3KW8-YZtHsQ-YuWt45');
      script.onload = () => {
        console.log('CollectJS script loaded.');
        resolve();
        // const payment = this.renderer.createElement('script');
        // payment.src = 'https://nowcast.transactiongateway.com/contrib/js/payment.js';
        // // payment.setAttribute('data-tokenization-key', 'GV2byu-5C3KW8-YZtHsQ-YuWt45');
        // payment.onload = () => {
        //   console.log('Payment Desing script loaded.');
        //   resolve();
        // };
        // payment.onerror = (error: any) => reject(error);
        // this.renderer.appendChild(document.body, payment);
      };
      script.onerror = (error: any) => reject(error);
      this.renderer.appendChild(document.body, script);
    });
  }

  finishSubmit(response) {
    console.log("Hello", response);
    if (this.NMIApiRequested) {
      console.log("3")
      this.showcardIcon(response)
      this.sendNmiApiCall(response)
    } else {
      console.log("4")
      this.NMIObject = response
      this.showcardIcon(response)
    }
  }
  showcardIcon(response) {
    console.log("5")
    switch (response.card.type) {
      case "visa":
        this.cardSource = "https://nowcast.transactiongateway.com//shared/images/brand-visa.png"
        this.cardFound = true
        break;
      case "mastercard":
        this.cardSource = "https://nowcast.transactiongateway.com/shared/images/brand-mastercard.png"
        this.cardFound = true
        break;
      case "discover":
        this.cardSource = "https://nowcast.transactiongateway.com/shared/images/brand-discover.png"
        this.cardFound = true
        break;
      case "amex":
        this.cardSource = "https://nowcast.transactiongateway.com/shared/images/brand-amex.png"
        this.cardFound = true
        break;
      case "jcb":
        this.cardSource = "https://nowcast.transactiongateway.com/shared/images/brand-jcb.png"
        this.cardFound = true
        break;
      case "maestro":
        this.cardSource = "https://nowcast.transactiongateway.com/shared/images/brand-maestro.png"
        this.cardFound = true
        break;
      case "hipercard":
        this.cardSource = "https://nowcast.transactiongateway.com/shared/images/brand-hipercard.png"
        this.cardFound = true
        break;
      default:
        this.cardSource = ""
        this.cardFound = false
        break;
    }
  }

  convertToYearMonth(inputString) {
    console.log(inputString, "INput")
    if (inputString.length !== 4) {
      return "Invalid input: Must be 4 digits (YYMM)";
    }
    // Parse year and month
    const monthPart = parseInt(inputString.slice(0, 2), 10);
    const yearPart = parseInt(inputString.slice(2), 10);
    // Calculate the full year (assuming base year 2000)
    const year = 2000 + yearPart;
    console.log(`${year}-${String(monthPart).padStart(2, '0')}`, monthPart < 1 || monthPart > 12)
    // Check if month is valid
    if (monthPart < 1 || monthPart > 12) {
      return "Invalid month";
    }
    // Format the result as "Year-Month"
    return `${year}-${String(monthPart).padStart(2, '0')}`;  // E.g., "2025-10"
  }


  addCardDetailsNMI(data) {
    // console.log(data, "Data")
    if (!this.NMIObject) {
      console.log("1")
      this.NMIApiRequested = true
      const CollectJS = (window as any).CollectJS;
      CollectJS.startPaymentRequest();
    } else {
      console.log("2")
      this.sendNmiApiCall(this.NMIObject)
    }
  }


  generatePassword() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

sendNmiApiCall(data) {
  console.log("6")
  let obj = {}
  // const data = JSON.parse(JSON.stringify(result));
  // let tokenID = data.token.id;
  // data["card"] = null;
  obj["addressCity"] = this.cardHolderCity
    ? this.cardHolderCity
    : null;
  obj["addressCountry"] = this.cardHolderCountry
    ? this.cardHolderCountry
    : null;
  obj["addressLine1"] = this.cardHolderAddressLine1
    ? this.cardHolderAddressLine1
    : null;
  obj["addressLine1Check"] = null
  obj["addressLine2"] = this.cardHolderAddressLine2
    ? this.cardHolderAddressLine2
    : null;
  obj["addressState"] = this.cardHolderState
    ? this.cardHolderState
    : null;
  obj["addressZip"] = this.cardHolderZipcode
    ? this.cardHolderZipcode
    : null;
  obj["addressZipCheck"] = null
  obj["autoPayment"] = true;
  obj["brand"] = data ?
    data.card.type
    :
    null
  obj["country"] = data ?
    null
    :
    null
  obj["cvcCheck"] = "unchecked"

  obj["dynamicLast4"] = data ?
    data.card.number.slice(-4)
    :
    null;
  obj["expMonth"] = data ?
    Number(this.convertToYearMonth(data.card.exp).split("-")[1])
    :
    null;
  obj["expYear"] = data ?
    Number(this.convertToYearMonth(data.card.exp).split("-")[0])
    :
    null;
  obj["funding"] = null
  obj["id"] = this.generatePassword();
  obj["last4"] = data ?
  data.card.number.slice(-4)
    : null;
  obj["tokenizationMethod"] = null;
  obj["name"] = this.cardHolderName ? this.cardHolderName : null;
  obj["object"] = null;
  obj["selectForPayment"] = true;
  obj["tokenId"] = null;

  obj["nmiPaymentToken"] = data.token
  // delete data["token"];
  // delete data["card"]["card"];
  // delete data["card"]["client_ip"];
  // delete data["card"]["created"];
  // delete data["card"]["type"];
  // delete data["card"]["used"];
  // delete data["card"]["livemode"];

  console.log({ card: { ...obj } }, "Card detail DTO")
  this.settingsService.addcardDetails({ card: { ...obj } }).subscribe(
    (response: any) => {
      console.log(response);
      // this.messageService.add({ severity: 'success', summary: 'Success', detail: `Card added successfully` });
      // this.getCardDetailsList();
      this.NMIApiRequested = false
      this.makePayment();
      // this.showAddCard = false
    },
    (error) => {
      this.disableBtn = false;
      this.NMIApiRequested = false
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: error.error ? error.error.message : "Something went wrong.",
      });
    }
  );
}

  signUpUser() {
    if(this.selectedPlanData !== undefined) {
      this.showCard = true;
      this.loadCollectJSScript().then(() => {
        this.configureCollectJS();
      }).catch(error => {
        console.error("Failed to load CollectJS script:", error);
      });
    }
    else{
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Plan list should not be empty",
      });
    }
    return;
    const dataToSend = JSON.parse(JSON.stringify(this.signUp));
    if (this.activePlan === "Basic") {
      dataToSend.subscriptionPlanId = 1;
    } else if (this.activePlan === "Standard") {
      dataToSend.subscriptionPlanId = 2;
    } else if (this.activePlan === "Premium") {
      dataToSend.subscriptionPlanId = 3;
    } else {
      dataToSend.subscriptionPlanId = this.subscriptionPlanId;
    }
    this.authenticateService.signUpOrg(dataToSend).subscribe(
      (response) => {
        console.log(response);
        localStorage.setItem(
          "loggedInUserName",
          response.body.data.userDetails.username
        );
        localStorage.setItem(
          "loggedInUser",
          JSON.stringify(response.body.data)
        );
        this.showCard = true;
        this.loadCollectJSScript().then(() => {
          this.configureCollectJS();
        }).catch(error => {
          console.error("Failed to load CollectJS script:", error);
        });
        this.disableFields = false;
        this.subDomainName = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .organization.subdomain
          : null;
        this.subDomainName = this.subDomainName
          ? this.subDomainName.substr(0, this.subDomainName.indexOf("."))
          : null;
        this.subscriptionPlanName = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .organization.subscription[0].subscriptionPlanName
          : null;
        this.invoiceId = localStorage.getItem("loggedInUser")
          ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
              .invoiceId
          : "";
        // this.router.navigate([`/signup/${this.uuid}`], { queryParams: { signup: true } });

        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Signup Form filled successfully' });
        // this.router.navigate(['/contacts-module/contacts']);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Something went wrong.",
        });
      }
    );
  }

  getSecretApiKey() {
    this.settingsService
      .getSecretKeyWithoutAuth()
      .subscribe((response: any) => {
        this.stripeService.setKey(response.data.stripeApiKey);
        // this.cardView = true
      });
  }

  createToken(): void {
    if (
      !this.cardHolderName ||
      !this.cardHolderEmail ||
      !this.cardHolderAddressLine1 ||
      !this.cardHolderCity ||
      !this.cardHolderCountry ||
      !this.cardHolderState ||
      !this.cardHolderZipcode ||
      !this.isCCNumberValid ||
       !this.isCCExpValid ||
        !this.isCVVValid
    ) {
      this.submitted = true;
    } else {
      if (this.cardHolderCity.trim() === "") {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "City should be blank",
        });
        return;
      }

      const name = this.cardHolderName ? this.cardHolderName.trim() : "";
      const address_city = this.cardHolderCity
        ? this.cardHolderCity.trim()
        : "";
      const address_line1 = this.cardHolderAddressLine1
        ? this.cardHolderAddressLine1.trim()
        : "";
      const address_line2 = this.cardHolderAddressLine2
        ? this.cardHolderAddressLine2.trim()
        : "";
      const address_state = this.cardHolderState
        ? this.cardHolderState.trim()
        : "";
      const address_zip = this.cardHolderZipcode
        ? this.cardHolderZipcode.trim()
        : "";
      const address_country = this.cardHolderCountry
        ? this.cardHolderCountry.trim()
        : "";
      const currency = "USD";
      this.disableBtn = true;
      this.addCardDetailsNMI({
        name,
        address_city,
        address_line1,
        address_line2,
        address_state,
        address_zip,
        address_country,
        currency,
        card: {}
      })
      // this.stripeService
      //   .createToken(this.card.element, {
      //     name,
      //     address_city,
      //     address_line1,
      //     address_line2,
      //     address_state,
      //     address_zip,
      //     address_country,
      //     currency,
      //   })
      //   .subscribe(
      //     (result) => {
      //       if (result.token) {
      //         // Use the token
      //         console.log(result.token.id);
      //         this.stripeTokenId = result.token.id;
      //         this.addCardDetails(result);
      //         this.cardError.error = false;
      //         this.cardError.message = "";

      //         setTimeout(() => {
      //           this.cardError.error = false;
      //           this.cardError.message = "";
      //         }, 3500);
      //         // const customer = result.token.
      //         // this.stripeService.customers.create({
      //         //   description: 'My First Test Customer (created for API docs)',
      //         // });
      //       } else if (result.error) {
      //         this.disableBtn = false;
      //         this.sharedService.updateLoader(false);
      //         this.cardError.error = true;
      //         this.cardError.message = result.error.message;
      //         this.messageService.add({
      //           severity: "error",
      //           summary: "Error",
      //           detail: result.error.message
      //             ? result.error.message
      //             : "Something went wrong.",
      //         });
      //         // Error creating the token
      //       }
      //     },
      //     (error) => {
      //       this.disableBtn = false;
      //     }
      //   );
    }
  }


  isNumberOnly(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

 

  // addCardDetails(result) {
  //   const data = JSON.parse(JSON.stringify(result));
  //   let tokenID = data.token.id;
  //   data["card"] = data.token;
  //   data["card"]["addressCity"] = this.cardHolderCity
  //     ? this.cardHolderCity
  //     : null;
  //   data["card"]["addressCountry"] = this.cardHolderCountry
  //     ? this.cardHolderCountry
  //     : null;
  //   data["card"]["addressLine1"] = this.cardHolderAddressLine1
  //     ? this.cardHolderAddressLine1
  //     : null;
  //   data["card"]["addressLine1Check"] = data.token.card.address_line1_check
  //     ? data.token.card.address_line1_check
  //     : null;
  //   data["card"]["addressLine2"] = this.cardHolderAddressLine2
  //     ? this.cardHolderAddressLine2
  //     : null;
  //   data["card"]["addressState"] = this.cardHolderState
  //     ? this.cardHolderState
  //     : null;
  //   data["card"]["addressZip"] = this.cardHolderZipcode
  //     ? this.cardHolderZipcode
  //     : null;
  //   data["card"]["addressZipCheck"] = data.token.card.address_zip_check
  //     ? data.token.card.address_zip_check
  //     : null;
  //   data["card"]["autoPayment"] = true;
  //   data["card"]["brand"] = data.token.card.brand
  //     ? data.token.card.brand
  //     : null;
  //   data["card"]["country"] = data.token.card.country
  //     ? data.token.card.country
  //     : null;
  //   data["card"]["cvcCheck"] = data.token.card.cvc_check
  //     ? data.token.card.cvc_check
  //     : null;
  //   data["card"]["dynamicLast4"] = data.token.card.dynamic_last4
  //     ? data.token.card.dynamic_last4
  //     : null;
  //   data["card"]["expMonth"] = data.token.card.exp_month
  //     ? data.token.card.exp_month
  //     : null;
  //   data["card"]["expYear"] = data.token.card.exp_year
  //     ? data.token.card.exp_year
  //     : null;
  //   data["card"]["funding"] = data.token.card.funding
  //     ? data.token.card.funding
  //     : null;
  //   data["card"]["id"] = data.token.card.id ? data.token.card.id : null;
  //   data["card"]["last4"] = data.token.card.last4
  //     ? data.token.card.last4
  //     : null;
  //   data["card"]["name"] = this.cardHolderName ? this.cardHolderName : null;
  //   data["card"]["object"] = data.token.card.object
  //     ? data.token.card.object
  //     : null;
  //   data["card"]["selectForPayment"] = true;
  //   data["card"]["tokenId"] = tokenID ? tokenID : null;
  //   data["card"]["tokenizationMethod"] = data.token.card.tokenization_method
  //     ? data.token.card.tokenization_method
  //     : null;
  //   delete data["token"];
  //   delete data["card"]["card"];
  //   delete data["card"]["client_ip"];
  //   delete data["card"]["created"];
  //   delete data["card"]["type"];
  //   delete data["card"]["used"];
  //   delete data["card"]["livemode"];

  //   data["organizationId"] = this.orgId
  //   data["email"] = this.subscriptionData.basicInfoDTO.email
  //   data["firstName"] = this.subscriptionData.basicInfoDTO.firstName
  //   data["lastName"] = this.subscriptionData.basicInfoDTO.lastName
  //   data["mobileNumber"] = this.subscriptionData.basicInfoDTO.mobileNumber
  //   this.authenticateService.addcardDetailsWithoutAuth(data).subscribe(
  //     (response: any) => {
  //       console.log(response);
  //       // this.messageService.add({ severity: 'success', summary: 'Success', detail: `Card added successfully` });
  //       // this.getCardDetailsList();
  //       this.makePayment();
  //       // this.showAddCard = false
  //     },
  //     (error) => {
  //       this.disableBtn = false;
  //       this.messageService.add({
  //         severity: "error",
  //         summary: "Error",
  //         detail: error.error ? error.error.message : "Something went wrong.",
  //       });
  //     }
  //   );
  // }

  makePaymentNow() {
    // this.stripeTokenId = this.cardDetails[0].tokenId;
    // this.cardHolderEmail = this.cardDetails[0].updatedBy;
    this.anotherCard = false;
    this.makePayment();
  }

  convertDataToByte(unit, val){
    let data = val;
    if (unit === "GB") {
      data *= 1024 * 1024 * 1024; // Convert GB to bytes
    } else if (unit === "TB") {
      data *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
    }
    return data;
  }

  makePayment() {
    let newdata = {};
    const storageValue = this.convertDataToByte(this.selectedPlanData.unit,this.selectedPlanData.quantity);
    newdata = {
      organizationId: Number(this.orgId),
      storageValue: storageValue,
      currentPlan : this.selectedPlanData,
    };
    this.authenticateService.makeStoragePayment(newdata,this.dataStorageType).subscribe(
      (response: any) => {
        if (response.body) {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: `Your Plan Upgraded Successfully`,
          });
          // this.getCardDetailsList();
          // this.showAddCard = false
          // this.reSubscribe = false

          // var local = localStorage.getItem("loggedInUser")
          //   ? JSON.parse(localStorage.getItem("loggedInUser"))
          //   : "";
          // local.userDetails.isPaymentDone = true;
          // local.userDetails.activeCard = true;
          // localStorage.setItem("loggedInUser", JSON.stringify(local));
          this.router.navigate(["/login"]);
        } 
        // else if (response.body.data.paymentStatus === "Payment Failed") {
        //   this.disableBtn = false;
        //   this.messageService.add({
        //     severity: "error",
        //     summary: "Error",
        //     detail: response.body.data.reason,
        //   });
        // }
      },
      (error) => {
        this.disableBtn = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Something went wrong.",
        });
      }
    );
  }

  getSubscriptionPlanList() {
    this.subscrptionService
      .getSubscriptionPlanbylist(this.page, this.rows, "")
      .subscribe((response: any) => {
        // this.planAmountBasic = response.data[0].planAmount
        // console.log(response.data[0].planAmount);
        // console.log(this.planAmountBasic);
        this.ListPlanDetails = response.data.content;
        console.log(this.ListPlanDetails);
        this.ListPlanDetails.forEach((el) => {
          if (el.subscriptionPlanName === "Basic") {
            this.basicFeature = el.permission;
            this.planAmountBasic = el.planAmount;
            this.basicTrialDays = el.trialDays;
            this.totalDiskSpaceBasic = el.totalDiskSpace;
            if (this.totalDiskSpaceBasic === 500000000000) {
              this.convertToGb(this.totalDiskSpaceBasic, "Basic");
            } else {
              this.convertToTb(this.totalDiskSpaceBasic, "Basic");
            }
            console.log(this.planAmountBasic);
          }
          if (el.subscriptionPlanName === "Standard") {
            this.standardFeature = el.permission;
            this.planAmountStandard = el.planAmount;
            this.standardTrialDays = el.trialDays;
            this.totalDiskSpaceStandard = el.totalDiskSpace;
            if (this.totalDiskSpaceStandard === 500000000000) {
              this.convertToGb(this.totalDiskSpaceStandard, "Standard");
            } else {
              this.convertToTb(this.totalDiskSpaceStandard, "Standard");
            }
            console.log(this.planAmountStandard);
          }
          if (el.subscriptionPlanName === "Premium") {
            this.premiumFeature = el.permission;
            this.planAmountPremium = el.planAmount;
            this.premiumTrialDays = el.trialDays;
            this.totalDiskSpacePremium = el.totalDiskSpace;
            if (this.totalDiskSpacePremium === 500000000000) {
              this.convertToGb(this.totalDiskSpacePremium, "Premium");
            } else {
              this.convertToTb(this.totalDiskSpacePremium, "Premium");
            }
            console.log(this.planAmountPremium);
          }
          console.log(this.basicFeature);
        });
      });
  }

  getAllCountryList() {
    this.authenticateService.getCountryList().subscribe(
      (response: any) => {
        this.countryList = response.data.countryList;
        this.cardHolderCountry = response.data.countryList[0].code2;
        this.stateList = response.data.countryList[0]
          ? response.data.countryList[0].states
          : [];
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }
  updateStateList(event: any) {
    const selectedCountryList = this.countryList.filter(
      (value) => value.code2 === event.target.value
    );
    this.stateList = [];
    this.cardHolderState = "";
    if (selectedCountryList.length && selectedCountryList[0].states.length) {
      this.stateList = selectedCountryList[0].states;
    }
  }

  convertToTb(diskSpace, plan) {
    // Convert to gigabytes
    let totalDiskSpaceInGB = diskSpace / 1024 ** 3;
    // Round off to the nearest whole number
    let totalDiskSpaceInGBRounded = Math.round(totalDiskSpaceInGB);
    this.totalDiskSpaceGB = totalDiskSpaceInGBRounded;
    console.log(`Total Disk SPace in GB:  ${totalDiskSpaceInGBRounded}`);
    // Convert to terabytes
    let totalDiskSpaceInTB = diskSpace / 1024 ** 4;
    // Round off to the nearest whole number
    let totalDiskSpaceInTBRounded = Math.round(totalDiskSpaceInTB);
    if (plan === "Basic") {
      this.totalDiskSpaceBasicTB = totalDiskSpaceInTBRounded;
    } else if (plan === "Standard") {
      this.totalDiskSpaceStandardTB = totalDiskSpaceInTBRounded;
    } else if (plan === "Premium") {
      this.totalDiskSpacePremiumTB = totalDiskSpaceInTBRounded;
    } else {
      this.totalDiskSpaceTB = totalDiskSpaceInTBRounded;
    }
  }

  convertToGb(diskSpace, plan) {
    this.totalDiskSpaceGB = (diskSpace / 1024 ** 3).toFixed(2);
    // Round off to the nearest multiple of 500 GB
    this.totalDiskSpaceGB = Math.round(this.totalDiskSpaceGB / 500) * 500;
    if (plan === "Basic") {
      this.totalDiskSpaceBasicGB = this.totalDiskSpaceGB;
    } else if (plan === "Standard") {
      this.totalDiskSpaceStandardGB = this.totalDiskSpaceGB;
    } else if (plan === "Premium") {
      this.totalDiskSpacePremiumGB = this.totalDiskSpaceGB;
    } else {
      this.totalDiskSpaceGB = this.totalDiskSpaceGB;
    }
  }

  updateCityList(event: any) {
    // const selStateObj = this.stateList.filter(
    //   (el) => el.name === event.target.value
    // );
    // if (selStateObj.length && selStateObj[0].cities.length) {
    //   this.cityDropdown = true;
    //   this.cityList = selStateObj[0].cities;
    //   this.cardHolderCity = this.cityList[0].cityName;
    // } else {
    //   this.cityDropdown = false;
    //   this.cardHolderCity = '';
    // }
  }

  getOrganisationInfoForStorage() {
    const type = this.dataStorageType;
    const orgId = this.orgId
    this.authenticateService.getOrganisationInfoForStorage(type,orgId).subscribe(
      (response: any) => {
        let data = response.data;
        data.currentPlan['usage'] = `${data.currentPlan.quantity} ${data.currentPlan.unit}`;
        data.dataUsagePricingInfoDTOS.forEach(el => {
          el.dataUsageName = `${el.name} ${el.quantity}${el.unit}`;
        })
        if(data.cards.length > 0) {
          data.cards.forEach(element => {
            if(element.selectForPayment && !element.forGiving){
              this.cardBrand = element.brand;
              this.cardPersonName = element.name;
              this.cardexpYear= element.expYear;
              this.cardexpMonth = element.expMonth;
              this.cardLast4Digit = element.last4;
              // this.stripeTokenId = element.tokenId;
              // this.cardHolderEmail = element.updatedBy;
            }
          });
        }
        this.subscriptionData = data;
       
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  onChange(event: any){
    const id = Number(event.target.value);
    this.subscriptionData.dataUsagePricingInfoDTOS.forEach(el => {
      if(el.id === id){
        this.selectedPlanPrice = `$ ${el.setPrice}`;
        this.selectedPlanData = el;
      }
    });
  }

  processNow(){
    this.paymentModal = true;
  }
}
