<div class="main" [class.signup-info]="signedUp" [ngStyle]="{'background-color': brandColor ? brandColor : ''}">
    <div class="sub-main" *ngIf="!signedUp" [ngStyle]="{'background-color': brandColor ? brandColor : ''}">
        <div class="content-wrap">
            <div class="content">
                <form #signUpForm="ngForm" (ngSubmit)="signUpUser(signUpForm)">
                    <div class="logo-container" *ngIf="signUpLogo">
                        <img [src]="signUpLogo" alt="logo">
                    </div>
                    <div class="app-content-inner">
                        <div class="app-block app-block-padding">
                            <div class="row">
                                <p class="info-title">Basic Info</p>
                                <!-- <div class="col-sm-6">
                                        <div class="form-group required">
                                            <label>Organization Name</label>
                                            <input type="text" class="form-control" [(ngModel)]="signUp.name" name="name"
                                                required #name="ngModel" appWhiteSpaceValidator>
                                            <ng-container *ngIf="name.errors && (name.touched || name.dirty)">
                                                <p class="error"
                                                    [hidden]="!name.errors.required && !name.errors.whiteSpaceValidator">
                                                    Organization Name is required
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div> -->
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>First Name</label>
                                        <input type="text" class="form-control" [(ngModel)]="signUp.basicInfo.firstName"
                                            name="firstName" required #firstName="ngModel" appWhiteSpaceValidator>
                                        <ng-container *ngIf="firstName.errors && submitted ">
                                            <p class="error"
                                                [hidden]="!firstName.errors.required && !firstName.errors.whiteSpaceValidator">
                                                First Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input type="text" class="form-control" [(ngModel)]="signUp.basicInfo.lastName"
                                            name="lastName" #lastName="ngModel" appWhiteSpaceValidator>
                                        <!-- <ng-container *ngIf="lastName.errors && submitted">
                                            <p class="error"
                                                [hidden]="!lastName.errors.required && !lastName.errors.whiteSpaceValidator">
                                                Last Name is required
                                            </p>
                                        </ng-container> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Email</label>
                                        <input type="text" class="form-control"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="
                                            signUp.basicInfo.email" name="email" required #email="ngModel">
                                        <ng-container *ngIf="email.errors && submitted">
                                            <p class="error" [hidden]="!email.errors.pattern">Please enter a valid email
                                            </p>
                                            <p class="error" [hidden]="!email.errors.required">Email is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Mobile</label>
                                        <input type="number" class="form-control" pattern="^[0-9]*$"
                                            [(ngModel)]="signUp.basicInfo.mobileNumber" name="mobileNumber" required
                                            #mobileNumber="ngModel">
                                        <ng-container *ngIf="mobileNumber.errors && submitted && submitted">
                                            <!-- <p class="error" [hidden]="!mobileNumber.errors.pattern">Please enter a
                                                valid
                                                number</p> -->
                                            <p class="error" [hidden]="!mobileNumber.errors.required">Mobile is required
                                            </p>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="((signUp.basicInfo.mobileNumber && signUp.basicInfo.mobileNumber.toString().trim() !== '' && (signUp.basicInfo.mobileNumber.toString().length < 5 || signUp.basicInfo.mobileNumber.toString().length > 15 ))) && submitted ">
                                            <p class="error">
                                                Phone number should be between 5 to 15 digits</p>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group required">
                                            <label>Subscription</label>
                                            <span class="app-dropdown d-block m-0">
                                                <select class="form-control" [(ngModel)]="signUp.subscriptionPlanId"
                                                    name="subscriptionPlanId" #subscriptionPlanId="ngModel" required>
                                                    <option value="">Select</option>
                                                    <option value="1">Basic</option>
                                                    <option value="2">Standard</option>
                                                    <option value="3">Premium</option>
                                                </select>
                                            </span>
                                            <ng-container
                                                *ngIf="subscriptionPlanId.errors && (subscriptionPlanId.touched || subscriptionPlanId.dirty)">
                                                <p class="error" [hidden]="!subscriptionPlanId.errors.required">Subscription
                                                    is
                                                    required</p>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div> -->
                            <!-- <p class="info-title mt-2">Social Media Account</p>
                            <div class="row">
                                <div class="col-7">
                                    <div class="d-flex align-items-end mb-2">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="social-icon">
                                                <img src="assets/images/common/facebook.svg" alt="facebook">
                                            </span>
                                        </div>
                                        <div class="form-group col-6 w-100 mb-0">
                                            <label>Profile</label>
                                            <input type="text" class="form-control" [(ngModel)]="signUp.socialMediaDTO.fb"
                                                name="fb">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-end mb-2">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="social-icon">
                                                <img src="assets/images/common/twitter.svg" alt="twitter">
                                            </span>
                                        </div>
                                        <div class="form-group col-6 w-100 mb-0">
                                            <label>Profile</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="signUp.socialMediaDTO.insta" name="insta">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-end mb-2">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="social-icon">
                                                <img src="assets/images/common/instagram.svg" alt="instagram">
                                            </span>
                                        </div>
                                        <div class="form-group col-6 w-100 mb-0">
                                            <label>Profile</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="signUp.socialMediaDTO.tiktok" name="tiktok">
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-end mb-2">
                                        <div class="d-inline-flex align-items-center">
                                            <span class="social-icon">
                                                <img src="assets/images/common/tiktok.svg" alt="tiktok">
                                            </span>
                                        </div>
                                        <div class="form-group col-6 w-100 mb-0">
                                            <label>Profile</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="signUp.socialMediaDTO.twitter" name="twitter">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <p class="info-title mt-2 mb-0 required">Mailing Address<sup>*</sup></p>
                                <div class="form-group " style="margin-top:5px">
                                    <input type="text" class="form-control"
                                        [(ngModel)]="signUp.mailingAddress.addressLine1" name="addressLine1"
                                        appWhiteSpaceValidator #addressLine1="ngModel" required>
                                    <ng-container *ngIf="addressLine1.errors && submitted">
                                        <p class="error"
                                            [hidden]="!addressLine1.errors.required && !addressLine1.errors.whiteSpaceValidator">
                                            Mailing
                                            Address is required</p>
                                    </ng-container>
                                </div>
                                <div class="form-group">
                                    <label>Apt/Suite</label>
                                    <input type="text" class="form-control"
                                        [(ngModel)]="signUp.mailingAddress.apartment" name="apartment">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group required">
                                        <label>State</label>
                                        <span class="app-dropdown d-block m-0">
                                            <select class="form-control" [(ngModel)]="signUp.mailingAddress.state"
                                                name="state" required #state="ngModel"
                                                (change)="updateCityList($event)">
                                                <option value="null">Select</option>
                                                <option *ngFor="let st of stateList" value="{{st.code}}">
                                                    {{st.name}}
                                                </option>
                                            </select>
                                        </span>
                                        <ng-container *ngIf="state.errors && submitted">
                                            <p class="error" [hidden]="!state.errors.required">State is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group required">
                                        <label>City</label>
                                        <ng-container *ngIf="cityDropdown else cityInput">
                                            <span class="app-dropdown d-block m-0">
                                                <select class="form-control" [(ngModel)]="signUp.mailingAddress.city"
                                                    name="city">
                                                    <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                                        {{ct.cityName}}
                                                    </option>
                                                </select>
                                            </span>
                                        </ng-container>
                                        <ng-template #cityInput>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="signUp.mailingAddress.city" name="city" required
                                                #city="ngModel" pattern="^[a-zA-Z ]*$">
                                            <ng-container *ngIf="city.errors && submitted">
                                                <p class="error" [hidden]="!city.errors.pattern">Please enter a valid
                                                    city
                                                </p>
                                                <p class="error" [hidden]="!city.errors.required">City
                                                    is required</p>
                                            </ng-container>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group required">
                                        <label>Zip Code</label>
                                        <input type="text" class="form-control"
                                            [(ngModel)]="signUp.mailingAddress.postalCode" name="postalCode" required maxlength="12" minlength="3"
                                            #postalCode="ngModel" pattern="^[0-9]*$">
                                        <ng-container *ngIf="postalCode.errors && submitted ">
                                            <p class="error" [hidden]="!postalCode.errors.pattern">Please enter a valid
                                                Zip
                                                Code</p>
                                            <p class="error" [hidden]="!postalCode.errors.required">Zip Code is
                                                required</p>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-check mt-3">
                                    <input class="form-check-input" type="checkbox" value=""  [checked]="signUp.isTermAndCondition" [(ngModel)]="signUp.isTermAndCondition" name="isTermAndCondition" #isTermAndCondition="ngModel" >
                                    <label style="color: #656565;"  class="form-check-label" >
                                        By clicking, you agree to our 
                                    </label>
                                    <span *ngIf="!isWhiteLabel">
                                        <a style="margin-left: 6px;"  href="https://www.nowcast.cc/terms-of-conditions" target="_blank">Terms of Service</a><span style="color: #656565;">&nbsp;and&nbsp;</span> <a href="https://www.nowcast.cc/privacy-policy" target="_blank">Privacy Policy</a>
                                    </span>
                                    <span *ngIf="isWhiteLabel">
                                        <a style="margin-left: 6px;" [href]="termsOfUse" target="_blank">Terms of Service</a><span style="color: #656565;">&nbsp;and&nbsp;</span> <a [href]="privacyPolicy" target="_blank">Privacy Policy</a>
                                    </span>
                    
                                </div>
                            </div>
                        </div>
                        <div class="container text-center mt-20">
                            <button type="submit" [disabled]="!signUp.isTermAndCondition" class="btn btn-white mr-10">Sign
                                Up</button>
                            <!-- [disabled]="!signUpForm.valid" -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ng-container *ngIf="signedUp">
        <div class="logo-container">
            <img [src]="signUpLogo ? signUpLogo : '/assets/images/authenticate/logo.png'" alt="logo">
        </div>
        <div class="app-block">
            <p class="m-0">You've successfully signed up for this organization. In order to get started, please check
                your mail.</p>
        </div>
    </ng-container>
</div>