import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { appConfig } from 'src/app/app.config';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';
import { TabSvgIcons } from 'src/app/tab.icons';
import * as moment from 'moment';
import "moment-timezone";
import { HlsjsPlyrDriver } from 'src/app/hlsjs-plyr-driver/hlsjs-plyr-driver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaService } from 'src/app/services/media/media.service';
import { environment } from 'src/environments/environment';
const { parse } = require('rss-to-json');

@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.css']
})
export class PlatformsComponent implements OnInit {

  showMobile = true;
  showtablet = false;
  tabs: any[] = [];
  contents: any[] = [];
  products: any[] = [];
  wideImage: any[] = [];
  squareView: any[] = [];
  tabIcons = TabSvgIcons
  tabName = "welcome"
  showThinMargin = false;
  showstacked = false;
  channelId: number
  networkId: number
  channelType: any
  networkType: any
  m3uData: any[]=[]
  customNetworkData: any[]=[]
  showCarousal: boolean;
  showStatic: boolean
  tabId: any;
  orgId: any;
  allActiveTabs: any[] = [];
  AllTabData: any[] = [];
  index: number;
  tabType: any;
  iconId: any;
  imageType: any;
  carousalData: any[] = [];
  topHeader: any;
  staticArtworkId: any
  landscapeArtworkId: any
  staticHeaderImgid: any
  landscapeImageId: any
  staticWideHeaderImg: any
  staticBannerHeaderImg: any
  imagePathWide: any
  imagePath: any
  withWideArtwork = true;
  staticHeaderImg: any;
  staticHeaderColor: any;
  marginType: any;
  brandColor: any;
  marginEdges: any;
  showShadow: boolean;
  showTitle: boolean;
  itemTitle: any;
  itemLayout: any;
  overlayText: boolean;
  itemDisplay: any;
  showDate: boolean
  bannerColor: any;
  showImage = true;
  eventBanner: any;
  eventBannerId: any;
  backgroundEventBanner: any
  theme: any;
  tabColor: '#000'
  showBannerCarousal = false;
  carousalType: any;
  staticType: any;
  carousalRotate = true
  ebookData: any[] = []
  blogLayout: any
  givingTitle: any
  showAllData = false
  moment: any = moment
  blogData: any[] = []
  timeZone: any
  image = true
  showContent: boolean
  video = false
  currentVedio: Plyr.Source[] = [
    { src: '' }
  ]
  hlsjsDriver1 = new HlsjsPlyrDriver(true)

  hlsjsDriver2 = new HlsjsPlyrDriver(false);

  players: Plyr;

  options: Plyr.Options = {
    // autoplay: true,
    volume: 0,
    autoplay: true,
    muted: false,
    // invertTime: false,
    disableContextMenu: true,
    displayDuration: true,
    settings: [],
    controls: [],
    captions: {},
    loop: { active: true }
  }
  autoPlayChange = true;
  videoJsPlayerType: string;
  videoJsPlayerUrl: string;
  poster: string
  m3u8 = false
  // videoPath = 'https://d22trojezeokn1.cloudfront.net/1/public/1651689838182_1651689837610_nowcast_Glitch_Promo_%28Final%29.mp4'
  // videoPath: string = 'https://d22trojezeokn1.cloudfront.net/630/public/1669807102630_15minLowTrim.mp4';
  videoPath: string = " "

  videoPathClone = this.videoPath
  rssData: any
  allRssData: any[] = []
  showdummyData = true
  showTransparency: any;
  showBannerTransparency: any;
  transparencyCount: any;
  transparencyColor: any;
  showData = false
  currentSelectedChannelLogo
  logoOnRight: any
  logoOnPlayer: any
  playlistType: any
  videoOnLoop: any
  currentSelectedChannelName: any
  currentSelectedChannelDesc: any
  categoryData: any[]=[]
  currentDate = new Date()
  tillCurrectTimeSec: any;
  currentDay: any
  sendDate: any
  allDayDefault: any[]=[]
  checkCurrentTimeInterval: any
  currentPlayingItemName: any;
  currentStartItemTime: any;
  currentEndItemTime: any;
  currentItemRemainingTime: any;
  currentItemCurrentTime: any;
  videoUrl: any;
  streamId: any
  selectedChannel: any
  itemDescription: any;
  isManual = false
  sendDateData = {
    slotInterval: 30,
    openTime: '00:00:00',
    closeTime: '23:59:59',
    date: moment(this.currentDate).format('YYYY-MM-DD')
  };
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;
  @ViewChild('scrollthree') scrollthree : ElementRef;
  constructor(
    public authService: AuthenticateService,
    private sanitizer: DomSanitizer,
    private _mediaService: MediaService,
    private http: HttpClient,
    private authenticateService: AuthenticateService
  ) {
    this.showContent = false
    this.generateCalendar(this.sendDateData)
    this.blogData = [
      { rowImg: 'assets/images/tabs/blog1.jpeg', imageURL: 'assets/images/tabs/ebook1.jpeg', title: 'The Man Who Tried to Kidnap Nancy Pelosi', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog2.jpeg', imageURL: 'assets/images/tabs/ebook2.jpeg', title: 'Twitter in the Time of Elon Musk', dateTime: 'Wed, 1 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog3.jpeg', imageURL: 'assets/images/tabs/ebook3.jpeg', title: 'Xi Jinping Opens a New Chapter for China', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog1.jpeg', imageURL: 'assets/images/tabs/ebook1.jpeg', title: 'The Man Who Tried to Kidnap Nancy Pelosi', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog2.jpeg', imageURL: 'assets/images/tabs/ebook2.jpeg', title: 'Twitter in the Time of Elon Musk', dateTime: 'Wed, 1 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog3.jpeg', imageURL: 'assets/images/tabs/ebook3.jpeg', title: 'Xi Jinping Opens a New Chapter for China', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog1.jpeg', imageURL: 'assets/images/tabs/ebook1.jpeg', title: 'The Man Who Tried to Kidnap Nancy Pelosi', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog2.jpeg', imageURL: 'assets/images/tabs/ebook2.jpeg', title: 'Twitter in the Time of Elon Musk', dateTime: 'Wed, 1 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog3.jpeg', imageURL: 'assets/images/tabs/ebook3.jpeg', title: 'Xi Jinping Opens a New Chapter for China', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog1.jpeg', imageURL: 'assets/images/tabs/ebook1.jpeg', title: 'The Man Who Tried to Kidnap Nancy Pelosi', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog2.jpeg', imageURL: 'assets/images/tabs/ebook2.jpeg', title: 'Twitter in the Time of Elon Musk', dateTime: 'Wed, 1 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog3.jpeg', imageURL: 'assets/images/tabs/ebook3.jpeg', title: 'Xi Jinping Opens a New Chapter for China', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog1.jpeg', imageURL: 'assets/images/tabs/ebook1.jpeg', title: 'The Man Who Tried to Kidnap Nancy Pelosi', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog2.jpeg', imageURL: 'assets/images/tabs/ebook2.jpeg', title: 'Twitter in the Time of Elon Musk', dateTime: 'Wed, 1 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
      { rowImg: 'assets/images/tabs/blog3.jpeg', imageURL: 'assets/images/tabs/ebook3.jpeg', title: 'Xi Jinping Opens a New Chapter for China', dateTime: 'Wed, 2 Nov 2022 09:45:00 + 0000', description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis excepturi distinctio reprehenderit aperiam officiis, ipsa illum eius. Cum libero quos explicabo saepe beatae sit repudiandae vel impedit excepturi. Iste, adipisci.' },
    ]
  }

  ngOnInit(): void {
    this.orgId = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
        .organization.id
      : "";
    this.timeZone = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.timeZone
      : "";
    this.getActiveTabs(this.orgId);
    this.getBrandingData(this.orgId)
  }

  updateScroll(){
    const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;
    const scrollthree = this.scrollthree.nativeElement as HTMLElement;
    // do logic and set
    scrollTwo.scrollLeft = scrollOne.scrollLeft;
    scrollthree.scrollLeft = scrollOne.scrollLeft;
  }

  getBrandingData(orgId) {
    this.authService.getBrandingDataWithoutAuth(orgId).subscribe((response: any) => {
      this.brandColor = response.data.brandColor ? response.data.brandColor : '#010433';
      this.theme = response.data.mobileTheme ? response.data.mobileTheme : 'light'
      this.givingTitle = response.data.shortAppTitle
    })
  }

  changePlatform(type) {
    if (type === 'tablet') {
      this.showMobile = false;
      this.showtablet = true;
    }
    else if (type === 'mobile') {
      this.showMobile = true;
      this.showtablet = false;
    }
  }

  openSidebar() { }


  test: any;
  getActiveTabs(id) {
    this.authService.getTabsWithoutAuth(id).subscribe((response: any) => {
      this.allActiveTabs = response.data;
      if (!this.tabId) {
        this.allActiveTabs[0]['active'] = true;
        this.gettabDataByID(this.allActiveTabs[0].id, this.orgId)
      }
      this.allActiveTabs.forEach(data => {
        if (data) {
          this.tabIcons.forEach((el: any) => {
            if (data.iconId === el.id) {
              data['newimg'] = el.svgTag;
              // this.sanitizer.bypassSecurityTrustHtml(data.newimg);
              // data['color'] = "#ff0000";
            }
          })
        }
        if (data.id === this.tabId) {
          data['active'] = true
          // data['color'] = '#121baf'
        }
      })

    })
  }

  getColor(item) {

    if (item.active === true) {
      if (this.theme === 'light') {
        return this.brandColor
      }
      else {
        return '#ffffff'
      }
    }
    else {
      return '#a3a2a2'
    }

  }

  gettabDataByID(tabId, orgId) {
    this.authService.getTabDataByIdWithoutAuth(tabId, orgId).subscribe((response: any) => {
      this.AllTabData = response.data;
      this.showContent = false
      if (response.data.length > 1) {
        this.index = response.data.length - 1
      } else { this.index = 0 }
      this.tabType = response.data[this.index].tabBasicInfo.tabType
      this.tabId = response.data[this.index].tabBasicInfo.id
      this.tabName = response.data[this.index].tabBasicInfo.title
      this.iconId = response.data[this.index].tabBasicInfo.iconId
      this.currentVedio[0].src = ''
      if (this.tabType === 'BUILD_YOUR_OWN') {
        this.showTitle = false;
        this.imageType = response.data[this.index].buildYourOwnDTO.design.itemImages;
        this.topHeader = response.data[this.index].buildYourOwnDTO.design.headerType;
        this.marginType = response.data[this.index].buildYourOwnDTO.design.marginType;
        this.marginEdges = response.data[this.index].buildYourOwnDTO.design.marginEdges;
        this.showShadow = response.data[this.index].buildYourOwnDTO.design.shadow;
        this.itemTitle = response.data[this.index].buildYourOwnDTO.design.itemTitles;
        this.itemLayout = response.data[this.index].buildYourOwnDTO.design.itemLayout;
        this.itemDisplay = response.data[this.index].buildYourOwnDTO.design.itemDisplay;
        this.carousalType = response.data[this.index].buildYourOwnDTO.design.carouselType;
        this.staticType = response.data[this.index].buildYourOwnDTO.design.staticType;
        this.showTransparency = response.data[this.index].buildYourOwnDTO.design.showTransparency
        this.showBannerTransparency = response.data[this.index].buildYourOwnDTO.design.showBannerTransparency

        this.transparencyCount = response.data[this.index].buildYourOwnDTO.design.transparencyCount
        this.transparencyColor = response.data[this.index].buildYourOwnDTO.design.transparencyColor
        if (this.carousalType === 'WIDE') {
          this.showBannerCarousal = false
        }
        else if (this.carousalType === 'BANNER') {
          this.showBannerCarousal = true;
        }
        this.showImage = true;
        this.showDate = false;
        this.showTitle = true;
        if (this.itemDisplay === 'DATE' && this.itemLayout === 'ROWS') {
          this.showDate = true;
          this.showImage = false;
          this.imageType = "SQUARE"
        }
        else if (this.itemDisplay === 'IMAGE' && this.itemLayout === 'ROWS') {
          this.showImage = true;
          this.showDate = false;
        }
        else if (this.itemDisplay === 'TITLE_ONLY' && this.itemLayout === 'ROWS') {
          this.showDate = false;
          this.showImage = false;
        }
        if (this.itemTitle === "BELOW") {
          this.showTitle = true;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OFF") {
          this.showTitle = false;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OVERLAY") {
          this.showTitle = true;
          this.overlayText = true;
        }
        if (this.topHeader === "CAROUSEL") {
          this.showCarousal = true;
          this.showStatic = false;
        }
        else if (this.topHeader === "OFF") {
          this.showCarousal = false;
          this.showStatic = false;
        }
        else if (this.topHeader === "STATIC") {
          this.showCarousal = false;
          this.showStatic = true;
        }
        this.staticHeaderImgid = response.data[this.index].buildYourOwnDTO.design.staticImageId;
        this.staticArtworkId = ''
        this.landscapeArtworkId = ''
        this.staticHeaderImgid = ''
        this.landscapeImageId = ''
        this.staticWideHeaderImg = ''
        this.staticBannerHeaderImg = ''
        this.staticArtworkId = response.data[this.index].buildYourOwnDTO.design.staticArtworkId
        this.landscapeArtworkId = response.data[this.index].buildYourOwnDTO.design.landscapeArtworkId
        this.staticHeaderImgid = response.data[this.index].buildYourOwnDTO.design.staticImageId;
        this.landscapeImageId = response.data[this.index].buildYourOwnDTO.design.landscapeImageId
        if (this.staticHeaderImgid) {
          // this.staticHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=270&width=480';
          this.staticWideHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=270&width=480';
        }
        if (this.landscapeImageId) {
          // this.staticHeaderImg = `${appConfig.imageURL}` + this.landscapeImageId + '?height=100&width=300';
          this.staticBannerHeaderImg = `${appConfig.imageURL}` + this.landscapeImageId + '?height=150&width=450';
        }

        if (this.staticHeaderImgid) {
          // if (this.staticImageData['staticImage'].bdImage === true) {
          // if(this.staticType === 'WIDE'){
          // this.withWideArtwork = true
          var width = 480; var height = 270;
          let id = this.staticHeaderImgid
          // this.staticImageName = this.staticImageData['staticImage'].name
          // this.staticImageData['staticImage']['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePathWide = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          // this.imagePath = ''
          // }
          // var width = 150; var height = 150;
          // let id = this.staticImageData['staticImage'].id
          // this.staticImageName = this.staticImageData['staticImage'].name
          // this.staticImageData['staticImage']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          // this.wideArtworkId = this.staticHeaderImgid
        }
        else {
          this.imagePathWide = ''
          // this.imagePath = ''
        }

        if (this.landscapeImageId) {
          // if(this.staticType === 'BANNER'){
          // this.withWideArtwork = false
          var width = 300; var height = 100;
          let id = this.landscapeImageId
          // this.staticImageName = this.staticImageData['staticImage'].name
          // this.staticImageData['staticImage']['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePath = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          // this.imagePathWide = ''
          // }
        }
        else {
          // this.imagePathWide = ''
          this.imagePath = ''
        }
        if (this.staticType === 'WIDE') {
          this.staticHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=270&width=480';
        }
        else {
          this.staticHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=100&width=277';
        }
        if (this.staticType === 'WIDE' || this.staticType === 'VIDEO') {
          this.withWideArtwork = true
        }
        else {
          this.withWideArtwork = false
        }
        this.staticHeaderColor = response.data[this.index].buildYourOwnDTO.design.staticImageColor
        this.contents = response.data[this.index].buildYourOwnDTO.contents;
        this.contents.forEach(data => {
          if (this.imageType === "SQUARE") {
            if (data.squareArtworkId) {
              var width = 200; var height = 200;
              let id = data.squareArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.squareArtworkImageColor ? data.squareArtworkImageColor : '#efefef';
            }
          }
          else if (this.imageType === "BANNER") {

            if (data.bannerArtworkId) {
              var width = 600; var height = 200;
              let id = data.bannerArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.bannerArtworkImageColor ? data.bannerArtworkImageColor : '#efefef';
            }
          }
          else if (this.imageType === "WIDE") {
            if (data.wideArtWorkId) {
              var width = 480; var height = 270;
              let id = data.wideArtWorkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.wideArtworkImageColor ? data.wideArtworkImageColor : '#efefef';
            }

          }
        })

        this.carousalData = response.data[this.index].buildYourOwnDTO.design.carouselContentsList
        this.products = this.carousalData.map((el) => ({
          id: el,
          title: el.title ? el.title : '',
          subtitle: el.subTitle ? el.subTitle : '',
          wideImgId: el.wideArtwork ? el.wideArtwork.document.id : '',
          wideImg: '',
          bannerImgId: el.bannerArtwork ? el.bannerArtwork.document.id : '',
          bannerImg: ''
        }))
        console.log(this.products);

        this.products.forEach((data, i) => {
          if (data.bannerImgId) {
            var width = 600; var height = 200;
            let id = data.bannerImgId;
            data.bannerImg = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          }
          if (data.wideImgId) {
            var width = 480; var height = 270;
            let id = data.wideImgId;
            data.wideImg = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          }
        })
        console.log(this.products);
        if (this.products.length == 1) {
          this.carousalRotate = false
        }
        else {
          this.carousalRotate = true
        }
        this.contents = this.contents.filter((el, i) => {
          if (el.status === "PUBLISHED" || el.status === "SCHEDULED") {
            return el
          }
        })

        this.m3u8 = false;
        this.videoPath = this.videoPathClone

        if (response.data[this.index].buildYourOwnDTO.design.staticType === 'VIDEO') {
          response.data[this.index].buildYourOwnDTO.design.staticType = 'WIDE'
          this.image = false
          this.video = true
          this.staticArtworkId = null
          if (
            response.data[this.index].buildYourOwnDTO.design.videoUrl &&
            response.data[this.index].buildYourOwnDTO.design.videoUrl.trim() != ""
          ) {
            var size = response.data[this.index].buildYourOwnDTO.design.videoUrl.split(".").length;
            let ext = response.data[this.index].buildYourOwnDTO.design.videoUrl.split(".")[size - 1];
            if (ext === "m3u8") {
              this.m3u8 = true;
              this.videoJsPlayerType = 'application/x-mpegURL';
              this.videoJsPlayerUrl = response.data[this.index].buildYourOwnDTO.design.videoUrl;
              // this.currentVedio[0].src = response.data[this.index].buildYourOwnDTO.design.videoUrl;
              // this.hlsjsDriver2.updateSource()
              this.hlsjsDriver2.load(this.currentVedio[0].src);
            } else {
              this.m3u8 = false;
              this.videoJsPlayerUrl = response.data[this.index].buildYourOwnDTO.design.videoUrl;
              this.videoJsPlayerType = `video/${ext}`;
              // this.videoPath = response.data[this.index].buildYourOwnDTO.design.videoUrl
            }
            this.autoPlayChange = true
          } else {
            this.m3u8 = false;
            this.videoPath = this.videoPathClone
          }
        } else if (response.data[this.index].buildYourOwnDTO.design.staticType === 'WIDE') {
          this.image = true
          this.video = false
          if (
            response.data[this.index].buildYourOwnDTO.design.videoUrl &&
            response.data[this.index].buildYourOwnDTO.design.videoUrl.trim() != ""
          ) {
            var size = response.data[this.index].buildYourOwnDTO.design.videoUrl.split(".").length;
            let ext = response.data[this.index].buildYourOwnDTO.design.videoUrl.split(".")[size - 1];
            if (ext === "m3u8") {
              this.m3u8 = true;
              this.videoJsPlayerType = 'application/x-mpegURL';
              this.videoJsPlayerUrl = response.data[this.index].buildYourOwnDTO.design.videoUrl;
              // this.currentVedio[0].src = response.data[this.index].buildYourOwnDTO.design.videoUrl;
              // this.hlsjsDriver2.load(this.currentVedio[0].src);
            } else {
              this.m3u8 = false;
              this.videoJsPlayerUrl = response.data[this.index].buildYourOwnDTO.design.videoUrl;
              this.videoJsPlayerType = `video/${ext}`;
              // this.videoPath = response.data[this.index].buildYourOwnDTO.design.videoUrl
            }
            this.autoPlayChange = true
          } else {
            this.m3u8 = false;
            this.videoPath = this.videoPathClone
          }
        }
        setTimeout(() => { 
          this.showData = true
        }, 100);
      }

      else if(this.tabType === 'CHANNEL'){
        this.channelId = response.data[this.index].channel.channelId
        this.getMediaItemData(this.channelId, 'channelData');
      }
      else if(this.tabType === 'NETWORK'){
        setInterval(() => {
          this.currentDate = new Date()
          this.currentDay = moment(this.currentDate).format('dddd').substring(0,3)
    
          let sendDateTime = new Date() 
          let todayDateTime = moment(sendDateTime).format('YYYY-MM-DD') + ' ' + '00:00:00'
          let currentDateTime = moment(sendDateTime).format('YYYY-MM-DD HH:mm:ss')
          var getDiff = moment(currentDateTime).diff(moment(todayDateTime))
          // let secondsDiff =  currentDateTime.diff(todayDateTime)
          let getDiffinSec = getDiff / 1000;
          // console.log(secondsDiff,'<=============secondsDiff');
          
          this.tillCurrectTimeSec = getDiffinSec
        }, 1000);
        this.networkId = response.data[this.index].network.networkId
        this.sendDate = moment(this.currentDate).format('YYYY-MM-DD')
        this.getMediaSeriesData(this.sendDate,this.networkId,5)
      }
      else if (this.tabType === 'EVENTS') {
        this.showTitle = false;
        this.bannerColor = response.data[this.index].calendarDTO.bannerArtwork ? response.data[this.index].calendarDTO.bannerArtwork.document.imageColur : '';
        this.contents = response.data[this.index].calendarDTO.eventDTOList;
        this.eventBannerId = response.data[this.index].calendarDTO.bannerArtwork ? response.data[this.index].calendarDTO.bannerArtwork.document.id : '';
        this.imageType = response.data[this.index].calendarDTO.calendarDesign.itemImages;
        this.topHeader = response.data[this.index].calendarDTO.calendarDesign.headerType;
        this.marginType = response.data[this.index].calendarDTO.calendarDesign.marginType;
        this.marginEdges = response.data[this.index].calendarDTO.calendarDesign.marginEdges;
        this.showShadow = response.data[this.index].calendarDTO.calendarDesign.shadow;
        this.itemTitle = response.data[this.index].calendarDTO.calendarDesign.itemTitles;
        this.itemLayout = response.data[this.index].calendarDTO.calendarDesign.itemLayout;
        this.itemDisplay = response.data[this.index].calendarDTO.calendarDesign.itemDisplay;
        this.backgroundEventBanner = response.data[this.index].calendarDTO.bannerArtworkId ? `${appConfig.imageURL}` + response.data[this.index].calendarDTO.bannerArtworkId + "?height=150&width=450" : null
        // this.showTitle = true;
        if (this.eventBannerId) {
          var width = 600; var height = 200;
          let id = this.eventBannerId;
          this.eventBanner = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
        }

        if (this.itemDisplay === 'DATE' && this.itemLayout === 'ROWS') {
          this.showDate = true;
          this.showImage = false;
          this.imageType = "SQUARE"
        }
        else if (this.itemDisplay === 'IMAGE' && this.itemLayout === 'ROWS') {
          this.showImage = true;
          this.showDate = false;
        }
        else if (this.itemDisplay === 'TITLE_ONLY' && this.itemLayout === 'ROWS') {
          this.showDate = false;
          this.showImage = false;
        }
        if (this.itemTitle === "BELOW") {
          this.showTitle = true;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OFF") {
          this.showTitle = true;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OVERLAY") {
          this.showTitle = true;
          this.overlayText = true;
        } else {
          this.showTitle = true;
          this.overlayText = false;
        }
        if (this.topHeader === "CAROUSEL") {
          this.showCarousal = true;
          this.showStatic = false;
        }
        else if (this.topHeader === "OFF") {
          this.showCarousal = false;
          this.showStatic = false;
        }
        else if (this.topHeader === "STATIC") {
          this.showCarousal = false;
          this.showStatic = true;
        }

        this.contents = this.contents.filter((el, i) => {
          if (el.status === "PUBLISHED" || el.status === "SCHEDULED") {
            return el
          }
        })

        this.contents.forEach(data => {
          if (this.imageType === "SQUARE") {
            if (data.squareArtworkId) {
              var width = 200; var height = 200;
              let id = data.squareArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.squareArtwork.document.imageColur ? data.squareArtwork.document.imageColur : '#efefef';
            }
          }
          else if (this.imageType === "BANNER") {

            if (data.bannerArtworkId) {
              var width = 600; var height = 200;
              let id = data.bannerArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.bannerArtwork.document.imageColur ? data.bannerArtwork.document.imageColur : '#efefef';
            }
          }
          else if (this.imageType === "WIDE") {
            if (data.wideArtworkId) {
              var width = 320; var height = 180;
              let id = data.wideArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.wideArtwork.document.imageColur ? data.wideArtwork.document.imageColur : '#efefef';
            }

          }
        })
        this.contents.forEach(data => {
          if (data.startedDate && data.startTime) {
            data.startedDate = this.getDateTime(data.startedDate, data.startTime, 'date')
            data.startTime = this.getDateTime(data.startedDate, data.startTime, 'time')
          }
          if (data.endedDate && data.endTime) {
            data.endedDate = this.getDateTime(data.endedDate, data.endTime, 'date')
            data.endTime = this.getDateTime(data.endedDate, data.endTime, 'time')
          }
          if (
            moment(data.startedDate).format("hh-mm-dd") !==
            moment(data.endedDate).format("hh-mm-dd")
          ) {
            data.isEndVisible = true;
          } else {
            data.isEndVisible = false;
          }
        })
        // setTimeout(() => {
        //   this.showContent = true
        // }, 500);
      }

      else if (this.tabType === 'MUSIC') {

        this.contents = response.data[this.index].smartListDTO.contents;
        this.imageType = response.data[this.index].smartListDTO.design.itemImages;
        this.topHeader = response.data[this.index].smartListDTO.design.headerType;
        this.marginType = response.data[this.index].smartListDTO.design.marginType;
        this.marginEdges = response.data[this.index].smartListDTO.design.marginEdges;
        this.showShadow = response.data[this.index].smartListDTO.design.shadow;
        this.itemTitle = response.data[this.index].smartListDTO.design.itemTitles;
        this.itemLayout = response.data[this.index].smartListDTO.design.itemLayout;
        this.itemDisplay = response.data[this.index].smartListDTO.design.itemDisplay;
        this.carousalType = response.data[this.index].smartListDTO.design.carouselType;
        this.staticType = response.data[this.index].smartListDTO.design.staticType;
        this.showTitle = true;
        if (this.carousalType === 'WIDE') {
          this.showBannerCarousal = false
        }
        else if (this.carousalType === 'BANNER') {
          this.showBannerCarousal = true;
        }
        this.showImage = true;
        this.showDate = false;
        this.showTitle = true;
        if (this.itemDisplay === 'DATE' && this.itemLayout === 'ROWS') {
          this.showDate = true;
          this.showImage = false;
          this.imageType = "SQUARE"
        }
        else if (this.itemDisplay === 'IMAGE' && this.itemLayout === 'ROWS') {
          this.showImage = true;
          this.showDate = false;
        }
        else if (this.itemDisplay === 'TITLE_ONLY' && this.itemLayout === 'ROWS') {
          this.showDate = false;
          this.showImage = false;
        }
        if (this.itemTitle === "BELOW") {
          this.showTitle = true;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OFF") {
          this.showTitle = false;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OVERLAY") {
          this.showTitle = true;
          this.overlayText = true;
        }
        if (this.topHeader === "CAROUSEL") {
          this.showCarousal = true;
          this.showStatic = false;
        }
        else if (this.topHeader === "OFF") {
          this.showCarousal = false;
          this.showStatic = false;
        }
        else if (this.topHeader === "STATIC") {
          this.showCarousal = false;
          this.showStatic = true;
        }
        this.staticHeaderImgid = response.data[this.index].smartListDTO.design.staticImageId;
        if (this.staticType === 'WIDE') {
          this.staticHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=270&width=480';
        }
        else {
          this.staticHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=100&width=277';
        }
        this.staticHeaderColor = response.data[this.index].smartListDTO.design.staticImageColor

        this.contents.forEach(data => {
          if (this.imageType === "SQUARE") {
            if (data.squareArtworkId) {
              var width = 200; var height = 200;
              let id = data.squareArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.squareArtworkImageColor ? data.squareArtworkImageColor : '#efefef';
            }
          }
          else if (this.imageType === "BANNER") {

            if (data.bannerArtworkId) {
              var width = 600; var height = 200;
              let id = data.bannerArtworkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.bannerArtworkImageColor ? data.bannerArtworkImageColor : '#efefef';

            }
          }
          else if (this.imageType === "WIDE") {
            if (data.wideArtWorkId) {
              var width = 320; var height = 180;
              let id = data.wideArtWorkId;
              data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              data['Imgcolor'] = data.wideArtworkImageColor ? data.wideArtworkImageColor : '#efefef';
            }

          }
        })

        if (this.itemDisplay === 'DATE' && this.itemLayout === 'ROWS') {
          this.showDate = true;
          this.showImage = false;
          this.imageType = "SQUARE"
        }
        else if (this.itemDisplay === 'IMAGE' && this.itemLayout === 'ROWS') {
          this.showImage = true;
          this.showDate = false;
        }
        else if (this.itemDisplay === 'TITLE_ONLY' && this.itemLayout === 'ROWS') {
          this.showDate = false;
          this.showImage = false;
        }
        if (this.itemTitle === "BELOW") {
          this.showTitle = true;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OFF") {
          this.showTitle = false;
          this.overlayText = false;
        }
        else if (this.itemTitle === "OVERLAY") {
          this.showTitle = true;
          this.overlayText = true;
        }
        if (this.topHeader === "CAROUSEL") {
          this.showCarousal = true;
          this.showStatic = false;
        }
        else if (this.topHeader === "OFF") {
          this.showCarousal = false;
          this.showStatic = false;
        }
        else if (this.topHeader === "STATIC") {
          this.showCarousal = false;
          this.showStatic = true;
        }
        this.carousalData = response.data[this.index].smartListDTO.design.carouselContentsList
        this.products = this.carousalData.map((el) => ({
          id: el,
          wideImgId: el.wideArtwork ? el.wideArtwork.document.id : '',
          wideImg: '',
          bannerImgId: el.bannerArtwork ? el.bannerArtwork.document.id : '',
          bannerImg: ''
        }))
        console.log(this.products);

        this.products.forEach((data, i) => {
          if (data.bannerImgId) {
            var width = 600; var height = 200;
            let id = data.bannerImgId;
            data.bannerImg = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          }
          if (data.wideImgId) {
            var width = 480; var height = 270;
            let id = data.wideImgId;
            data.wideImg = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          }
        })
        console.log(this.products);
        if (this.products.length == 1) {
          this.carousalRotate = false
        }
        else {
          this.carousalRotate = true
        }
      }

      else if (this.tabType === 'EBOOK') {
        this.ebookData = response.data[this.index].mediaSeriesDTO.mediaItemDTO
        this.ebookData.forEach(el=>{
          if(el.ebookArtwork){
            var width = 140; var height = 220;
              let id = el.ebookArtwork.document.id;
              el['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              el['Imgcolor'] = el.ebookArtwork.document.imageColur ? el.ebookArtwork.document.imageColur : '#efefef';
          }
        })
      }
      

      else if (this.tabType === 'BLOG') {
        // BLOG Starts----------------------
        this.blogLayout = response.data[this.index].blogDTO.layout
        var url = response.data[this.index].blogDTO.url
        this.showdummyData = true
        parse(url).then(rss => {
          this.showdummyData = false
          this.blogData = []
          this.rssData = rss.items
          this.rssData.forEach((item) => {
            let objectToPush = {};
            let title = '';
            let author = '';
            let imageURL = '';
            let description = '';
            let date = '';
            let url = '';
            let detailedDescription = '';
            //for title------------------------
            if (title !== null) {
              if (item.hasOwnProperty('title')) {
                title = `${item.title}`;
              } else if (
                item.hasOwnProperty('itunes:title')
              ) {
                title = `${item['itunes:title']}`;
              }
            }
            //for description--------------------
            if (description !== null) {
              if (
                item.hasOwnProperty('description')
              ) {
                let html = `${item.description}`;
                if ((html === null) || (html === '')) {
                  description = ''
                }
                else {
                  description = html.replace(/(<([^>]+)>)/ig, '');
                }
              } else if (
                item.hasOwnProperty(
                  'itunes:description'
                )
              ) {
                description = `${item['itunes:description']}`;
              }
            }
            // for author------------------------
            if (author !== null) {
              if (
                item.hasOwnProperty(['dc:creator'][0])
              ) {
                author = `${item['dc:creator'][0]}`;
              } else if (
                item.hasOwnProperty('itunes:author')
              ) {
                author = `${item['itunes:author']}`;
              } else if (
                item.hasOwnProperty('author')
              ) {
                author = `${item.author}`;
              }
            }
            //for published date---------------------
            if (date !== null) {
              if (item.hasOwnProperty('published')) {
                date = `${item.published}`;
              } else if (
                item.hasOwnProperty('itunes:pubDate')
              ) {
                date = `${item['itunes:pubDate']}`;
              }
            }
            //for images -------------------------
            if (imageURL !== null) {
              if (item.hasOwnProperty('thumbnail')) {
                imageURL = `${item.thumbnail}`;
              } else if (
                item.hasOwnProperty('imageURL')
              ) {
                imageURL = `${item.imageURL}`;
              } else if (
                item.hasOwnProperty(
                  'itunes:thumbnail'
                )
              ) {
                imageURL = `${item['itunes:thumbnail']}`;
              }
            }
            // for url---------------------
            if (url !== null) {
              if (item.hasOwnProperty('url')) {
                url = `${item.url}`;
              } else if (
                item.hasOwnProperty('link')
              ) {
                url = `${item.link}`;
              } else if (
                item.hasOwnProperty('itunes:link')
              ) {
                url = `${item['itunes:link']}`;
              }
            }
            objectToPush = {
              title: title,
              description: description,
              author: author,
              date: date,
              imageURL: imageURL,
              url: url,
              detailedDescription: detailedDescription,
            };
            // console.log('detailed description is :', detailedDescription)
            this.blogData.push(objectToPush);
          })
          //     console.log(this.blogData);
        });
         // BLOG Ends ----------------------
      }

      else if (this.tabType === 'GIVING') {
        // this.givingTitle = response.data[this.index].tabBasicInfo.title
      }

      // if(!this.tabId){
      //   this.allActiveTabs[0]['active'] = true;
      //   this.gettabDataByID(this.allActiveTabs[0].id)
      // }
      // this.allActiveTabs.forEach(data =>{
      //   if(data){
      //     this.tabIcons.forEach((el:any)=>{
      //       if(data.iconId === el.id){
      //         data['newimg'] = el.svgImg
      //       }
      //     })
      //   }
      //   if(data.id === this.tabId){
      //     data['active'] = true
      //   }
      // })
      this.showAllData = true
    })
  }

  getRssFeedByURL(url: any) {
    this.authenticateService.getBrandingColor(url).subscribe((res: any) => {

      console.log(res);
    }, (err: any) => {
      console.log(err);
    }
    )
  }

  getDateTime(date, time, format) {
    const dateFormat = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
    const timeFormat = moment(time, 'HH:mm:ss').format('HH:mm:ss')
    const dateTimeFormat = dateFormat + " " + timeFormat;
    let newscheduleDate = moment.utc(dateTimeFormat);
    var displayCutoff = moment(newscheduleDate).tz(this.timeZone);
    if (format === 'date') {
      let formatedDateTime = moment(displayCutoff).format('YYYY-MM-DD');
      return formatedDateTime;
    } else {
      let formatedDateTime = moment(displayCutoff).format('YYYY-MM-DD, h:mm a');
      return formatedDateTime;
    }
  }

  getTab(item) {
    this.allActiveTabs.forEach(el => {
      if (el.id === item.id) {
        el.active = true;
      }
      else {
        el.active = false;
      }
    })
    this.gettabDataByID(item.id, this.orgId);
  }

  /**
   * To get Media Item data
   * @param id Media Item Id
   */
   getMediaItemData(id, type) {
    this._mediaService.getMediaById(id).subscribe((response: any) => {
      this.channelType = response.data.channelInfoDTO.channelType
      this.videoJsPlayerUrl = ''
      this.videoJsPlayerType = ''
      // if(this.channelType === 'M3U8'){
        this.videoJsPlayerUrl = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
        this.videoJsPlayerType = 'application/x-mpegURL'
    });
  }

  getMediaSeriesData(date,id,noOfItems) {
    // this.m3uData = []
    this._mediaService.getNetworkData(date,id,noOfItems).subscribe((response: any) => {
      this.networkType = response.data.type
      if(this.networkType === 'M3U'){
        this.m3uData = response.data.m3uChannelList ? response.data.m3uChannelList : []
        this.isManual = false
      }
      else{
        this.customNetworkData = response.data.manualChannelList ? response.data.manualChannelList : []
        this.isManual = true
        this.videoJsPlayerUrl = ''
        this.videoJsPlayerType = ''
        // this.videoJsPlayerType = 'application/x-mpegURL'
        if(this.customNetworkData.length>0){
          this.customNetworkData.forEach((el: any,i: any)=>{
            if(el.Playlist.length>0){
              el.Playlist.forEach((element:any,index: any) => {
                element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                element['isSelected'] = false
              });
              this.customNetworkData[0].Playlist[0]['isSelected'] = true
              setTimeout(() => {
                if(i === 0){
                  if(this.customNetworkData[i].Playlist[i]){
                    this.selectChannel(this.customNetworkData[0].Playlist[0])
                  }
                }
                // this.streamId = this.customNetworkData[0].Playlist[0].streamId
              }, 1000);
              // this.videoJsPlayerType = ''
              // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
              this.videoJsPlayerUrl = this.customNetworkData[0].Playlist[0].playlistUrl
              this.currentSelectedChannelLogo = this.customNetworkData[0].Playlist[0].logoPath
              this.logoOnRight = this.customNetworkData[0].Playlist[0].logoOnRight
              this.logoOnPlayer = this.customNetworkData[0].Playlist[0].logoOnPlayer
              this.playlistType = this.customNetworkData[0].Playlist[0].playlistType
              this.videoOnLoop = this.customNetworkData[0].Playlist[0].videoOnLoop
              this.currentSelectedChannelName = this.customNetworkData[0].Playlist[0].title
              this.currentSelectedChannelDesc = this.customNetworkData[0].Playlist[0].description
            }
          })
          this.categoryData = this.customNetworkData.map((el)=>({
            categoryName : el.Category
          }))
          // let newCategoryData = [...new Set(this.categoryData)];
          // console.log(newCategoryData);
          
        }
      }
    });
  }

  handleMissingImage(event:any){
    event.target.src = 'assets/images/common/no-image-icon-23494.png';
    (event.target as HTMLImageElement).style.width = "55px";
  }

  generateCalendar(x: any) {
    //Format the time
    let start = x.date + ' ' + x.openTime
    let end = x.date + ' ' + x.closeTime
    let startTime = moment(start, "YYYY-MM-DD HH:mm");

    //Format the end time and the next day to it 
    let endTime = moment(end, "YYYY-MM-DD HH:mm").add(0, 'days');

    //Times
    let allTimes: any = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    this.allDayDefault = []
    this.allDayDefault = allTimes.map((el: any, i: any) => ({
      date: x.date,
      end: allTimes[i + 1],
      start: el,
    })
    )
  }

  generateVideoUrl(videoId: string): void {
    // const url = `https://media.nowcast.cc:5443/LiveApp/play.html?name=${videoId}`;
    const url = `${environment.antMediaServerUrl}/LiveApp/play.html?name=${videoId}`;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  selectChannel(data: any){
    this.videoJsPlayerUrl = ''
    this.videoJsPlayerType = ''
    this.currentSelectedChannelLogo = ''
    this.currentPlayingItemName = ''
    this.currentStartItemTime = ''
    this.currentEndItemTime = ''
    this.itemDescription = ''
    // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
    this.videoJsPlayerUrl = data.playlistUrl
    this.videoJsPlayerType = 'application/x-mpegURL'
    this.currentSelectedChannelLogo = data.logoId ? `${appConfig.imageURL}` + data.logoId : null
    this.currentSelectedChannelName = data.title
    this.currentSelectedChannelDesc = data.description
    this.logoOnRight = data.logoOnRight
    this.streamId = data.streamId
    this.generateVideoUrl(this.streamId)
    this.logoOnPlayer = data.logoOnPlayer
    this.playlistType = data.playlistType
    this.videoOnLoop = data.videoOnLoop
    if(this.customNetworkData.length>0){
      this.customNetworkData.forEach((el: any)=>{
        if(el.Playlist.length>0){
          el.Playlist.forEach((element:any) => {
            if(element.id === data.id){
              element.isSelected = true
              this.selectedChannel = element
              let sendDate = new Date()
              this.getCurrentPlayItem(sendDate,element)
            }
            else{
              element.isSelected = false
            }
          });
        }
      })
    }
  }

  getCurrentPlayItem(currentDateTime: any,getData: any){
    let currectDateTime = moment(currentDateTime).format('YYYY-MM-DD HH:mm:ss')
    if(this.checkCurrentTimeInterval){
      clearInterval(this.checkCurrentTimeInterval)
    }
    if(this.customNetworkData.length>0){
      this.customNetworkData.forEach((el: any)=>{
        if(el.Playlist.length>0){
          el.Playlist.forEach((element:any) => {
            if(element.id === getData.id){
              if(element.schedulingChannelInfoDTO){ 
              if(element.schedulingChannelInfoDTO.length>0){
                element.schedulingChannelInfoDTO.forEach((data: any)=>{
                  data.start = data.date + ' ' + data.startTime
                  data.end = data.date + ' ' + data.endTime
                  let time : any
                  if(this.playlistType === 'LOOP' && this.videoOnLoop){
                    let selectedTime =  moment(currentDateTime).format('HH:mm:ss')
                    let selectedDateTime = data.date + ' ' + selectedTime
                    time = moment(selectedDateTime,'YYYY-MM-DD HH:mm:ss')
                  }
                  else{
                    time = moment(currectDateTime,'YYYY-MM-DD HH:mm:ss')
                  }
                  let beforeTime = moment(data.start, 'YYYY-MM-DD HH:mm:ss')
                  let afterTime = moment(data.end, 'YYYY-MM-DD HH:mm:ss');
                  if (time.isBetween(beforeTime, afterTime)) {
                    this.currentPlayingItemName = data.name
                    this.currentStartItemTime = data.start
                    this.currentEndItemTime = data.end
                    this.itemDescription = data.itemDescription
                    this.checkCurrentTimeInterval = setInterval(() => {
                      let sendCurrentDateTime = new Date()
                      let sendDateData = moment(sendCurrentDateTime).format('YYYY-MM-DD HH:mm:ss')
                      let sendStartTime = data.start
                      var getDiff = moment(sendCurrentDateTime).diff(moment(sendStartTime))
                      let currDiff = moment.utc(getDiff).format('HH:mm:ss')
                      
                      // var getDiff = moment.utc(moment(sendDateData, "HH:mm:ss").diff(moment(sendStartTime, "HH:mm:ss"))).format("HH:mm:ss")
                      this.currentItemCurrentTime = currDiff;
        
                      let sendEndTime =  data.end
                      var getRemDiff = moment(sendEndTime).diff(moment(sendDateData))
                      let remainDiff = moment.utc(getRemDiff).format('HH:mm:ss')
                      // var getRemDiff = moment.utc(moment(sendEndTime, "HH:mm:ss").diff(moment(sendDateData, "HH:mm:ss"))).format("HH:mm:ss")
                      this.currentItemRemainingTime = remainDiff;
                      if(this.currentItemRemainingTime == '00:00:00'){
                        if (this.selectedChannel) {
                          if(this.checkCurrentTimeInterval){
                            clearInterval(this.checkCurrentTimeInterval)
                          }
                          this.currentPlayingItemName = ''
                          this.currentStartItemTime = ''
                          this.currentEndItemTime = ''
                          this.currentItemRemainingTime = ''
                          this.currentItemCurrentTime = ''
                          this.itemDescription = ''
                          setTimeout(() => {
                            this.selectChannel(this.selectedChannel)  
                          }, 2000);
                          // this.getMediaItemData(this.mediaItemId, 'channelData');
                          // let sendDate = moment(this.currentDate).format('YYYY-MM-DD')
                          // this.getSchedulingChannelInfoWithDate(sendDate,this.mediaItemId)
                        }
                      }
                    }, 1000);
                  }
                })
              }
            }
            }
          });
        }
      })
    }
    }

  selectM3UItem(data){
  //   if(this.m3uData){
  //     this.m3uData.forEach(el=>{
  //       if(el.Playlist){
  //         el.Playlist.forEach(element=>{
  //           if(element.channelName == data.channelName){
  //             element.isSelected = true
  //             this.showPlayer = true
  //           }
  //           else{
  //             element.isSelected = false
  //           }
  //         })
  //       }
  //     })
  //   }
  //   this.videoJsPlayerUrl = ''
  //   this.videoJsPlayerType = ''
  //   if (data.channelUri) {
  //     let size = data.channelUri?.split(".").length;
  //     let ext = data.channelUri?.split(".")[size - 1];
  //     if (ext === "m3u8") {
  //       this.videoJsPlayerType = 'application/x-mpegURL';
  //       this.videoJsPlayerUrl = data.channelUri;
  //     } 
  //     else {
  //       this.videoJsPlayerUrl = data.channelUri;
  //       this.videoJsPlayerType = `video/mp4`;
  //     }
  // }
}

}
