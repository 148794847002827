import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../../../services/shared/shared.service'
import { AppAnimation } from './../../../../utilities/animations/animations';
import { LibraryServiceService } from './../../../../services/library/library-service.service'
import { TvListDesign } from './../../../../models/library.model'
import { MessageService } from 'primeng/api';
import {Subscription} from 'rxjs'

@Component({
  selector: 'app-tv-list',
  templateUrl: './tv-list.component.html',
  styleUrls: ['./tv-list.component.css'],
  animations: AppAnimation
})
export class TVListComponent implements OnInit {
  sidePanel = false;
  overlay = false;
  off = true;
  featured = false;
  tv = true;
  featureItems = false;
  carousel = false;
  trendingList : any [] = [];
  recentSeries = Array;
  listDesign: TvListDesign
  headerType = 'OFF'
  catalogId: number
  combined : any [] = []
  tvListDesignData: any[] = []
  itemId: number
  carouselList : any [] = []
  tvData : any [] = []
  anyArray : any [] = []
  updatedArray : any [] = []
  newDataArray : any [] = []
  sendDataArray : any [] = []
  subsciptionData = new Subscription();

  constructor(
    public sharedService: SharedService,
    public libraryService: LibraryServiceService,
    public messageService: MessageService
  ) {
    this.listDesign = new TvListDesign()
  }

  ngOnInit(): void {
    this.libraryService.panel3$.subscribe((state: any) => {
      this.sidePanel = state.showPanel
      this.overlay = state.showPanel
    })
    this.libraryService.sendCatalog$.subscribe((id: any) => {
      this.catalogId = id      
    })

    // this.libraryService.sendTvId$.subscribe((response: any) => {
    //   this.itemId = response
    //   this.getListDesign(this.itemId)
    // })

    this.libraryService.sendDataItems1$.subscribe((response:any) => {
       this.tvData = response
    } )

    this.libraryService.sendDataItems2$.subscribe((response:any) => {
      this.combined = response

    this.combined.forEach((el) =>{
      let data = el.id
      let dataIndex = this.combined.indexOf(el)
      this.carouselList.forEach((element) => {
        if(element.id === data){
          this.combined.splice(dataIndex,1)
        }
      } )
    } )

      this.combined =[ ...this.combined.reduce((map,obj) => map.set(obj.id,obj),new Map()).values()];
      
    } )
    
   this.getSubscription()
    
  }

   getSubscription(){

   this.subsciptionData.add(
    this.libraryService.sendTvId$.subscribe((response: any) => {
      this.itemId = response
      this.getListDesign(this.itemId)
    })

   )

   }

  openCarouselPanel() {
    this.libraryService.updatePanel5({ showPanel: true })
    this.libraryService.sendCarouselLengthData(this.carouselList.length)
    if(this.carouselList){
      this.libraryService.sendTvData(this.carouselList)
    }
  }

  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.libraryService.updatePanel3({ showPanel: false })
    if(!this.itemId){
      this.headerType = 'OFF'
      this.off = true;
      this.featured = false;
    }
  }
  offOption(OFF) {
    this.off = true;
    this.featured = false;
    this.headerType = 'OFF'
  }
  featuredOption(CAROUSEL) {
    this.featured = true;
    this.off = false;
    this.headerType = 'CAROUSEL'
  }
  featureItemTab() {
    this.tv = false;
    this.featureItems = true
    // this.carousel = false
  }
  tvTab() {
    this.tv = true;
    this.featureItems = false;
    // this.carousel = false
  }
  // carouselTab() {
  //   this.carousel = true;
  //   this.tv = false;
  //   this.featureItems = false;
  // }
  // backFeaturedTab() {
  //   this.tv = false;
  //   this.featureItems = true
  //   // this.carousel = false
  // }

  createListDesign() {
   if(this.carouselList){
   this.newDataArray = this.carouselList.map((el) => ({
     id : el.id,
    sortOrder : 1,
    type : el.type
   }) )
   this.tvData = [...this.tvData,...this.newDataArray]
  }
  //  this.tvData.forEach((el) => {
  //   var medId = el.id
  //   this.newDataArray.forEach((el) => {
  //     if(medId !== el.id){
  //      this.tvData = [...this.tvData,...this.newDataArray]
  //     }
  //   } )
  // } )


  this.tvData = this.tvData.map((el,i) => ({
    id : el.id,
    sortOrder : i,
    type : el.type
  }) )

  this.sendDataArray =[ ...this.tvData.reduce((map,obj) => map.set(obj.id,obj),new Map()).values()];

    const data = (JSON.parse(JSON.stringify(this.listDesign)))
    data.catalogId = this.catalogId
    
    data.designType = "TV"
    data.headerType = this.headerType

    data.carouselContent = this.sendDataArray
    

    
    if (this.itemId) {
      this.libraryService.updateListDesign(this.itemId, data).subscribe((response: any) => {
        
        this.combined = []
        this.tvData = []

        this.libraryService.sendUpdateToCreate({ update : true })
        this.closePanel()
        // this.getListDesign(this.itemId)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item updated successfully` });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
    else {
      this.libraryService.createListDesign(data).subscribe((response) => {
        
        this.combined = []
        this.tvData = []

        this.itemId = response.body.data.id
        
        this.libraryService.sendUpdateToCreate({ update : true })
        this.closePanel()
        // this.getListDesign(this.itemId)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item created successfully` });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
  }


  getListDesign(id) {
    this.libraryService.getListDesign(id).subscribe((response: any) => {
      console.log(response)
      console.log('nowcast')
      this.listDesign = new TvListDesign(response.data)
      this.headerType = response.data.headerType
      this.carouselList = response.data.carouselContents
      if(response.data.headerType === 'CAROUSEL' ){
        this.featured = true;
        this.off = false;
      }
      else{
        this.off = true;
        this.featured = false;        
      }
    })
  }
  deleteItem(item){
    let data =this.combined[item].id
    let arrData = this.combined[item]
    this.tvData.forEach((el)=>{
      if(el.id === data){
      var i = this.tvData.indexOf(el)
      this.tvData.splice(i,1)
     
      let arrData = []
      arrData.push(el)
      this.libraryService.sendDeletedData(arrData)

      }
    })

    this.combined.splice(item,1)

  }

  delete(item) {
    console.log(this.carouselList)       
    this.carouselList.splice(item, 1)
    console.log(this.carouselList)
    // this.updatedArray = this.carouselList.map((el, i) => ({
    //   id: el.id,
    //   sortOrder: i,
    //   type: el.type
    // }))
   
    
  }

}
