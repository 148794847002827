<div class="social-lead-edit">
    <div class="panel-overlay" [@overlay1]="overlay"></div>
    <div class="slide-panel social-panel-details" [@sidePanel1]="sidePanel">
        <div class="panel-header">
            <div class="close-icon">
                <img src="../../../../../assets/images/library/close.svg" (click)="closePanel()"
                    style="cursor:pointer;" />
            </div>
            <ul class="custom-tabs mt-3">
                <li [class.active]="activeTab === 'details'" (click)="activeTab = 'details'">
                    <a>Form Details</a>
                </li>
                <li [class.active]="activeTab === 'leads'" (click)="activeTab = 'leads'">
                    <a>Leads</a>
                </li>
            </ul>
        </div>

        <div class="panel-content" style="padding-top: 5px; padding-bottom: 50px;">
            <ng-container *ngIf="activeTab === 'details' else leads">
                <div class="row">
                    <div class="container-fluid ">
                        <div class=" row d-flex justify-content-between align-items-end">
                            <h4 class="col-sm-6">{{updateSocialLeadForm.formName}} <br> <span
                                    class="detail-date">{{updateSocialLeadForm.updatedDate}}</span></h4>

                            <!-- <p>Active</p> -->

                            <div style="margin-bottom: 16px;" class="col-sm-6">
                                <div class="row">

                                    <div class="d-flex justify-content-end col-sm-8">
                                        <p class="mb-0">Auto Sync</p>
                                        <label class="switch-input" style="margin-left:10px; margin-top:3px;"
                                            container="body">
                                            <input type="checkbox" [checked]="updateSocialLeadForm.autoSync"
                                                [(ngModel)]="updateSocialLeadForm.autoSync">
                                            <span class="slider"></span>
                                        </label>
                                    </div>

                                    <div class="d-flex justify-content-end col-sm-4">
                                        <p class="mb-0">Status</p>
                                        <label class="switch-input" style="margin-left:10px; margin-top:3px;"
                                            container="body">
                                            <input type="checkbox" [checked]="status" [(ngModel)]="status">
                                            <span class="slider"></span>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <!-- <div  class="d-flex col-sm-4">
                                <p>Auto Sync</p>
                                <label class="switch-input" style="margin-left:10px; margin-top:3px;" container="body">
                                    <input type="checkbox" [checked]="updateSocialLeadForm.autoSync"
                                        [(ngModel)]="updateSocialLeadForm.autoSync">
                                    <span class="slider"></span>
                                </label>
                            </div> -->
                        </div>
                    </div>
                </div>


                <!-- <div class="row heading-background">
                    <div class="col-sm-5">
                        Fields mapped in NowCAST Contacts
                    </div>
                    <div class="col-sm-5">
                        Field in the Lead Form
                    </div>
                </div> -->

                <div class="row heading-background">
                    <div class="col-sm-5">
                        Field in the Lead Form
                    </div>
                    <div class="col-sm-5">
                        Fields mapped in NOWCAST Contacts
                    </div>
                </div>

                <form>

                    <div class="mapping-block">
                        <div class="row content-row" *ngFor="let obj of mappingArray;let i=index">
                            <div class="col-sm-5">
                                <div class="form-group">


                                    <!-- <div class="app-dropdown w-100">
                                            <select class="form-control " name="nowcastColumn{{i}}" [(ngModel)]="obj.nowcastColumn" >
                                                <option value="" selected="selected" disabled >Select</option>
                                                <option value="First Name">First Name</option>
                                                <option value="Last Name">Last Name</option>
                                                <option value="Full Name">Full Name</option>
                                                <option value="Email">Email</option>
                                                <option value="Mobile">Mobile</option>
                                                <option value="City">City</option>
                                                <option value="State">State</option>
                                                <option value="Zip Code">Zip Code</option>
                                            </select>
                                        </div> -->

                                    <input type="text" name="socialMediaColumn{{i}}" [(ngModel)]="obj.socialMediaColumn"
                                        class="form-control">

                                </div>
                            </div>

                            <div class="col-sm-5">
                                <div class="form-group">
                                    <!-- <input type="text" name="socialMediaColumn{{i}}" [(ngModel)]="obj.socialMediaColumn"
                                        class="form-control"> -->


                                    <div class="app-dropdown w-100">
                                        <select class="form-control " name="nowcastColumn{{i}}"
                                            [(ngModel)]="obj.nowcastColumn">
                                            <option value="" selected="selected" disabled>Select</option>
                                            <option value="First Name">First Name</option>
                                            <option value="Last Name">Last Name</option>
                                            <option value="Full Name">Full Name</option>
                                            <option value="Email">Email</option>
                                            <option value="Mobile">Mobile</option>
                                            <option value="City">City</option>
                                            <option value="State">State</option>
                                            <option value="Zip Code">Zip Code</option>
                                        </select>
                                    </div>

                                    <!-- <div class="app-dropdown w-100">
                                            <select class="form-control " name="nowcastColumn{{i}}" [(ngModel)]="obj.nowcastColumn" >
                                                <option value="" selected="selected" disabled >Select</option>
                                                <option value="Email">Email</option>
                                                <option value="First Name">Full Name</option>
                                                <option value="Mobile">Phone No</option>
                                                <option value="State">City</option>
                                            </select>
                                        </div> -->

                                </div>
                            </div>

                            <div class="col-sm-1 " style="margin-top: 8px;" >

                                <!-- <img  class="mx-0 px-1 mx-2" style="cursor: pointer;"
                                (click)="addRow(i)"  tooltip="Add Row"  src="./../../../../assets/images/library/add.svg" alt="">
                                 -->
                                <em [appSvgIcon]="'delete'" tooltip="Remove Row" (click)="removeRow(i)" ></em>

                            </div>

                        </div>
                        <div class="d-flex align-items-end justify-content-end" *ngIf="this.mappingArray.length < 8">
                            <button class="btn btn-white" style="margin: 11px;" (click)="addRow()">Add Row</button>
                        </div>
                    </div>
                </form>

                <div class="row">
                    <div class="app-filters d-flex justify-content-center align-items-center p-4">
                        <button class="btn btn-white" [disabled]="this.mappingArray.length === 0" (click)="updateLeadForm()">Update</button>
                        <button style="margin-right: 0;" class="btn btn-white" (click)="closePanel()"> Cancel</button>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <table class="table">
                                <thead class="thead-light">
                                    <tr>
                                        <th width="50%">Field in the Lead Form</th>
                                        <th width="50%">Fields mapped in NowCAST Contacts
                                            <em [appSvgIcon]="'edit'"></em>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="tbody">
                                    <tr>


                                        <!-- <tr>
                                        <td>
                                            <div class="form-group">
                                                <div class="app-dropdown w-100">
                                                    <select class="form-control " [(ngModel)]="mapping.socialMedaColumn" >
                                                        <option value="" selected="selected" disabled>Select</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Full Name">Full Name</option>
                                                        <option value="Phone No">Phone No</option>
                                                        <option value="City">City</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                            
                                                    <input type="text" [(ngModel)]="mapping.nowcastColumn" class="form-control" >
                                    

                                                


                                            </div>
                                        </td>

                                    </tr> -->




                <!-- <!-- <td>
                                            <div class="form-group">
                                                <div class="app-dropdown w-100">
                                                    <select class="form-control " [(ngModel)]="leadEmail">
                                                        <option value="" selected="selected" disabled>Select</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Full Name">Full Name</option>
                                                        <option value="Phone No">Phone No</option>
                                                        <option value="City">City</option>
                                                    </select>
                                                    <!-- <input type="text" [(ngModel)]="leadEmail" class="form-control" placeholder="Email" > -->
                <!-- </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100"> -->
                <!-- <select class="form-control ">
                                                        <option value="">Email</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="nowcastEmail" class="form-control"> -->
                <!-- </div> -->




                <!-- </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100">  -->
                <!-- <select class="form-control ">
                                                        <option value="">Full Name</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="leadFullName" class="form-control" placeholder="Full Name" > -->
                <!-- </div> -->

                <!-- <div class="app-dropdown w-100">
                                                    <select class="form-control " [(ngModel)]="leadFullName">
                                                        <option value="" selected="selected" disabled>Select</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Full Name">Full Name</option>
                                                        <option value="Phone No">Phone No</option>
                                                        <option value="City">City</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="leadEmail" class="form-control" placeholder="Email" > -->
                <!-- </div> -->


                <!-- </div>
                                        </td>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100"> -->
                <!-- <select class="form-control ">
                                                        <option value="">Full Name</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="nowcastFullName" class="form-control"> -->
                <!-- </div> -->
                <!-- </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100"> -->
                <!-- <select class="form-control ">
                                                        <option value="">Phone</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="leadPhone" class="form-control" placeholder="Phone" > -->
                <!-- </div> -->


                <!-- <div class="app-dropdown w-100">
                                                    <select class="form-control " [(ngModel)]="leadPhone">
                                                        <option value="" selected="selected" disabled>Select</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Full Name">Full Name</option>
                                                        <option value="Phone No">Phone No</option>
                                                        <option value="City">City</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="leadEmail" class="form-control" placeholder="Email" > -->
                <!-- </div>

                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100"> -->
                <!-- <select class="form-control ">
                                                        <option value="">Phone</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="nowcastPhone" class="form-control"> -->
                <!-- </div> -->
                <!-- </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100"> -->
                <!-- <select class="form-control ">
                                                        <option value="">City</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="leadCity" class="form-control" placeholder="City"  > -->
                <!-- </div> -->


                <!-- <div class="app-dropdown w-100">
                                                    <select class="form-control " [(ngModel)]="leadCity">
                                                        <option value="" selected="selected" disabled>Select</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Full Name">Full Name</option>
                                                        <option value="Phone No">Phone No</option>
                                                        <option value="City">City</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="leadEmail" class="form-control" placeholder="Email" > -->
                <!-- </div>


                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group"> -->
                <!-- <div class="app-dropdown w-100"> -->
                <!-- <select class="form-control ">
                                                        <option value="">City</option>
                                                    </select> -->
                <!-- <input type="text" [(ngModel)]="nowcastCity" class="form-control"> -->
                <!-- </div> -->
                <!-- </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div> -->

                <!-- <div class="app-filters d-flex justify-content-center align-items-center p-4">
                            <button class="btn btn-white" (click)="updateLeadForm()"> Save </button>
                        </div>

                    </div>
                </div>  -->

            </ng-container>
            <ng-template #leads>

                <div class="app-block">
                    <!-- <div class="app-filters d-flex justify-content-end align-items-end">
                        <span class="mr-10" style="margin-bottom: 10px;" >{{updatedDate}}</span>
                        <button style="margin-right: 0;" (click)="updateLeadsData()" type="button mobileRes"><em [appSvgIcon]="'sync'"></em> Refresh</button>
                    </div> -->


                    <p-table [columns]="cols" [value]="pages" [rows]="rowCount" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50, 100]" [autoLayout]="true" (onLazyLoad)="loadLeads($event)"
                        [lazy]="true" [totalRecords]="totalRecords" class="customertable"
                        styleClass="customertable custom-dataTable table-striped">
                        <!-- <p-table [columns]="cols" [value]="pages" [rows]="rowCount" [autoLayout]="true" [totalRecords]="totalRecords"> -->
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <span [tooltip]="col.header">{{col.header}}</span>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" class="pointer">

                                <!-- <td>2 May 2021</td>
                            <td>Video On Demand</td>
                            <td>Steven@ar.com</td>
                            <td>Steven Smith</td>
                            <td>+8803868495</td> -->

                                <td *ngFor="let col of columns">
                                    <span *ngIf="col.field === 'action' else contentCols"
                                        class="d-flex align-items-center">
                                        <!-- <em [appSvgIcon]="'edit'" routerLink="/contacts-module/contacts/add-contacts/{{rowData.id}}"  ></em> -->
                                        <!-- <em [appSvgIcon]="'delete'" (click)="openDeleteModal(rowData)" ></em> -->

                                        <div *ngIf="!rowData['autoSync'] && (!rowData['addedAsContact']  || rowData['addedAsContact'] === null)"
                                            class="btn-group align-items-center mx-1" dropdown>
                                            <span class="pointer toggle-dropdown" dropdownToggle><em
                                                    [appSvgIcon]="'toggle'"></em></span>
                                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">

                                                <li (click)="addLeadAsContact(rowData)"><a>Add as Contact</a></li>

                                            </ul>
                                        </div>

                                        <!-- <img tooltip="Add as Contact" *ngIf="!rowData['autoSync'] && (!rowData['addedAsContact']  || rowData['addedAsContact'] === null)"  class="mx-0 px-1 mx-2" style="cursor: pointer;"
                                (click)="addLeadAsContact(rowData)"  tooltip=""  src="./../../../../assets/images/library/add.svg" alt=""> -->

                                        <span *ngIf="rowData['addedAsContact']">
                                            Added as Contact
                                        </span>

                                    </span>
                                    <ng-template #contentCols>
                                        <span [tooltip]="rowData[col.field]">{{rowData[col.field]}}</span>
                                    </ng-template>
                                </td>

                                <!-- <td *ngFor="let col of columns">
                                <span>
                                    {{rowData[col.field]}}
                                </span>
                            </td> -->

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="9">
                                    No Leads found.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>

            </ng-template>
        </div>


    </div>

</div>