import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/media/media.service';
import { AppAnimation } from './../../../../utilities/animations/animations';

@Component({
  selector: 'app-mobile-embed-side-panel',
  templateUrl: './mobile-embed-side-panel.component.html',
  styleUrls: ['./mobile-embed-side-panel.component.css'],
  animations : AppAnimation
})
export class MobileEmbedSidePanelComponent implements OnInit {
  overlay = false
  sidePanel = false
  code = '<script id="subsplash-embed-hjcm" type="text/javascript">var target = document.getElementById("subsplash-embed-hjcm");var script = document.createElement("script");script.type = "text/javascript";script.onload = function() {subsplashEmbed("+hjcm/ap","https://subsplash.com/","subsplash-embed-hjcm");};script.src = "https://dashboard.static.subsplash.com/production/web-client/external/embed-1.1.0.js";target.parentElement.insertBefore(script, target);</script>'

  constructor(
    public mediaService : MediaService
  ) { }

  ngOnInit(): void {
    this.mediaService.mobileEmbed$.subscribe((response:any) => {
      this.sidePanel = response
      this.overlay = response
    } )
  }
  
  closePanel(){
    this.sidePanel = false
    this.overlay = false
    this.mediaService.openMobileEmbed(false)
  }

}
