<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    <div class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">

            <h4>Select Header</h4>
            <img class="cross-icon-img" style="cursor: pointer;" (click)="closePanel();cancelPanel()"
                src="./../../../../assets/images/library/close.svg" alt="">
        </div>
    </div>

    <div *ngIf="showOptions" class="app-filters d-flex justify-content-center pt-1">
        <button (click)="apply()" [disabled]="selectedMediaItems.length === 0 "
            class="btn btn-white mr-10">Apply</button>
        <!-- (click)="sendData(selectedItems)" -->
        <!-- (click)="closePanel('cancel')" -->
        <button (click)="closePanel();cancelPanel()" class="btn btn-white">Cancel</button>
    </div>

    <div *ngIf="showOptions" class="table addtolistTable">
        <form>

            <div class="py-3">
                <table>

                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio"
                                    (change)="type = 'MEDIA_ITEM';getMediaItems(1,10);emptySearch();showAllMediaItem = false"
                                    id="mediaId" name="optradio" [checked]="mediaChecked">
                                <label style="cursor: pointer;" for="mediaId" [ngClass]="{activebtn: mediaChecked}"
                                    name="optradio">Video
                                </label>
                            </div>
                        </td>
                        <td>
                            <div style="margin-left:7px;margin-right:17.5px;" class="d-flex align-items-center">
                                <input type="radio"
                                    (change)="type = 'LIVE_STREAMING';getMediaItems(1,10);emptySearch();showAllLivestream = false"
                                    id="liveId" name="optradio" [checked]="livestreamChecked" checked>
                                <label style="cursor: pointer;" for="liveId" [ngClass]="{activebtn: livestreamChecked}"
                                    name="optradio">Livestream</label>
                            </div>
                        </td>

                    </tr>


                </table>

            </div>
        </form>

    </div>

    <div [hidden]="!mediaItems || createMedIt">
        <div class="app-filters d-flex justify-content-center">
            <div class="app-search app-search-big search-box mr-10">
                <input type="text" placeholder="Search" class="form-control w-100 " #searchMediaItems>
            </div>
            <button (click)="createMedItem('MEDIA_ITEM')" class="btn btn-white btnSeries">Add Video</button>

        </div>
    </div>

    <div [hidden]="!livestreamItem || createMedIt">
        <div class="app-filters d-flex justify-content-center">
            <div class="app-search app-search-big search-box mr-10">
                <!-- style="width: 162.5px !important;" -->
                <input type="text" placeholder="Search" class="form-control w-100 " #searchInputLivestream>
            </div>
            <!-- style="width: 162.5px !important;" -->
            <button (click)="createMedItem('LIVE_STREAMING')" class="btn btn-white btnSeries">Add Livestream</button>

        </div>
    </div>

    <ng-container>


        <div *ngIf="createMedIt">
            <div *ngIf="type === 'MEDIA_ITEM'" class="app-block">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="info-title">Basic Info</p>
                    <em [appSvgIcon]="'back'" (click)="closeMediaItem(type)"></em>
                </div>

                <form #createMediaItemForm="ngForm">
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" [(ngModel)]="mediaItem.title" name="title" required
                            appWhiteSpaceValidator #title="ngModel">
                        <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                            <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                Title is required</p>
                        </ng-container>
                    </div>

                    <div class="app-filters d-flex justify-content-center my-4">
                        <button [disabled]="!createMediaItemForm.valid" (click)="createMediaItem(mediaItem)"
                            type="button" class="btn btn-white mr-10">Add</button>
                    </div>
                </form>
            </div>

            <div *ngIf="type !== 'MEDIA_ITEM'" class="app-block">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="info-title">Basic Info</p>
                    <em [appSvgIcon]="'back'" (click)="closeMediaItem(type)"></em>
                </div>

                <form #createLiveStreamForm="ngForm">
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" [(ngModel)]="livestreamItemModel.title" name="title"
                            required appWhiteSpaceValidator #title="ngModel">
                        <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                            <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                Title is required</p>
                        </ng-container>
                    </div>

                    <div class="app-filters d-flex justify-content-center my-4">
                        <button [disabled]="!createLiveStreamForm.valid" (click)="createMediaItem(livestreamItemModel)"
                            type="button" class="btn btn-white mr-10">Add</button>
                    </div>
                </form>
            </div>

        </div>

    </ng-container>


    <div *ngIf="!createMedIt">

        <span class="draft-info">To
            enable item selection, update its Draft status to Publish or Schedule</span>

        <div *ngIf="mediaItems">
            <div class="app-block">
                <div class="app-block-title">
                    <p class="info-title">Video</p>
                </div>
                <ul *ngIf="showAllMediaItem" class="recent-item-list m-0">
                    <li *ngFor="let recent of recentMedia ; let i = index">
                        <div class="d-flex align-items-center"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <!-- <span class="user-img"
                                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                            </span> -->
                            <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-wide"
                                [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType === 'WIDE'">
                                <!-- && carouselArtworkType !== 'BANNER'" -->
                                <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-banner"
                                [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-MediaItem-{{i}}">
                                    <p class="pointer ellipsis-text" (click)="goTo(recent, 'vidItem')"
                                        (dblclick)="saveData()">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </label>
                                <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                    <span class="red-bg">Draft</span>
                                </div>
                            </div>

                        </div>

                        <!-- <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name="" id="selected-MediaItem-{{i}}"
                                        (change)="selectItems($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div> -->
                        <div>
                            <input type="radio" name="recent" [checked]="recent.itemChecked"
                                [disabled]="recent.status === 'DRAFT'" id="selected-MediaItem-{{i}}"
                                value="{{recent.id}}" (change)="selectItems($event, recent)">
                        </div>
                    </li>
                    <!-- <li *ngIf="!recentMedia.length" class="no-media">
                                        <div class="d-flex align-items-center">
                                            <div class="recent-info">
                                                <span>No Recent Media Exists</span>
                                            </div>
                                        </div>
                                    </li> -->
                </ul>
                <ul *ngIf="!showAllMediaItem" class="recent-item-list m-0">
                    <li *ngFor="let recent of totalMediaItem ; let i = index">
                        <div class="d-flex align-items-center"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <!-- <span class="user-img"
                                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                            </span> -->
                            <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-wide"
                                [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType === 'WIDE' ">
                                <!-- && carouselArtworkType !== 'BANNER' -->
                                <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-banner"
                                [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-allMediaItem-{{i}}">

                                    <p class="pointer ellipsis-text" (click)="goTo(recent, 'vidItem')"
                                        (dblclick)="saveData()">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </label>
                                <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                    <span class="red-bg">Draft</span>
                                </div>
                            </div>

                        </div>

                        <!-- <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name="" id="selected-allMediaItem-{{i}}"
                                        (change)="selectItems($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div> -->
                        <div>
                            <input type="radio" name="recent" [checked]="recent.itemChecked"
                                [disabled]="recent.status === 'DRAFT'" id="selected-allMediaItem-{{i}}"
                                value="{{recent.id}}" (change)="selectItems($event, recent)">
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="!showAllMediaItem" class="app-filters mt-20 d-flex justify-content-center">
                <button class="btn btn-white" *ngIf="totalMediaItems>4" (click)="showmoreMediaItem()">Show
                    more</button>
            </div>
        </div>

        <div *ngIf="livestreamItem">
            <div class="app-block">
                <div class="app-block-title">
                    <p class="info-title">Livestream</p>
                </div>
                <ul *ngIf="showAllLivestream" class="recent-item-list m-0">
                    <li *ngFor="let recent of recentLivestream ; let i = index">
                        <div class="d-flex align-items-center"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <!-- <span class="user-img"
                                                        [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                                        <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                                            onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                                    </span> -->
                            <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-wide"
                                [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType === 'WIDE'">
                                <!-- && carouselArtworkType !== 'BANNER'" -->
                                <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-banner"
                                [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-MediaItem-{{i}}">
                                    <p class="pointer ellipsis-text" (click)="goTo(recent, 'liveItem')"
                                        (dblclick)="saveData()">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </label>
                                <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                    <span class="red-bg">Draft</span>
                                </div>
                            </div>

                        </div>

                        <!-- <div *ngIf="clickedFrom !== 'notification'">
                                        <span class="form-check">
                                            <input type="checkbox" class="form-check-input" name="" id="selected-MediaItem-{{i}}"
                                                (change)="selectItems($event, recent)"
                                                [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                                [(ngModel)]="recent.itemChecked">
                                        </span>
                                    </div> -->
                        <div>
                            <input type="radio" name="recent" [checked]="recent.itemChecked"
                                [disabled]="recent.status === 'DRAFT'" id="selected-MediaItem-{{i}}"
                                value="{{recent.id}}" (change)="selectItems($event, recent)">
                        </div>
                    </li>
                    <!-- <li *ngIf="!recentMedia.length" class="no-media">
                                                <div class="d-flex align-items-center">
                                                    <div class="recent-info">
                                                        <span>No Recent Media Exists</span>
                                                    </div>
                                                </div>
                                            </li> -->
                </ul>
                <ul *ngIf="!showAllLivestream" class="recent-item-list m-0">
                    <li *ngFor="let recent of totalLivestream ; let i = index">
                        <div class="d-flex align-items-center"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <!-- <span class="user-img"
                                                        [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                                        <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                                            onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                                    </span> -->
                            <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-wide"
                                [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType === 'WIDE' ">
                                <!-- && carouselArtworkType !== 'BANNER' -->
                                <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-banner"
                                [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-allMediaItem-{{i}}">

                                    <p class="pointer ellipsis-text" (click)="goTo(recent, 'liveItem')"
                                        (dblclick)="saveData()">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </label>
                                <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                    <span class="red-bg">Draft</span>
                                </div>
                            </div>

                        </div>

                        <!-- <div *ngIf="clickedFrom !== 'notification'">
                                        <span class="form-check">
                                            <input type="checkbox" class="form-check-input" name="" id="selected-allMediaItem-{{i}}"
                                                (change)="selectItems($event, recent)"
                                                [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                                [(ngModel)]="recent.itemChecked">
                                        </span>
                                    </div> -->
                        <div>
                            <input type="radio" name="recent" [checked]="recent.itemChecked"
                                [disabled]="recent.status === 'DRAFT'" id="selected-allMediaItem-{{i}}"
                                value="{{recent.id}}" (change)="selectItems($event, recent)">
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="!showAllLivestream" class="app-filters mt-20 d-flex justify-content-center">
                <button class="btn btn-white" *ngIf="totalLivestreams>4" (click)="showmoreMediaItem()">Show
                    more</button>
            </div>
        </div>


    </div>


</div>