import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ResourceService {
    constructor(
        public httpclient: HttpClient,
    ) { }

    /**
     * To get data
     * @param url API Endpoint
     */
    getData(url) {
        return this.httpclient.get<any>(url).pipe(catchError((error) => {
            return this.errorHandler(error);
        }));
    }

    /**
     * To save data
     * @param data Data to be send
     * @param url API Endpoint
     */
    postData(url, data?) {
        return this.httpclient.post<any>(url, data, { observe: 'response' }).pipe(
            catchError((error) => {
                return this.errorHandler(error);
            }));
    }


    /**
     * To save data
     * @param formData Data to be send
     * @param url API Endpoint
     */
     postNewData(url, formData?) {
        return this.httpclient.post<any>(url, formData, {reportProgress: true, observe: 'events' }).pipe(
            catchError((error) => {
                return this.errorHandler(error);
            }));
    }

    /**
     * To update data
     * @param data Data to be send
     * @param url API Endpoint
     */
    putData(url, data?) {
        return this.httpclient.put<any>(url, data, { observe: 'response' }).pipe(
            catchError((error) => {
                return this.errorHandler(error);
            }));
    }

    /**
     * To delete data 
     * @param url API Endpoint
     */
    deleteData(url) {
        return this.httpclient.delete<any>(url, { observe: 'response' }).pipe(
            catchError((error) => {
                return this.errorHandler(error);
            }));
    } 
    /**
     * To update data 
     * @param data Data to be send
     * @param url API Endpoint
     */
    patchData(url,data) { 
        return this.httpclient.patch<any>(url,data, {observe: 'response' }).pipe(
            catchError((error) => {
                return this.errorHandler(error);
            }));
    }

    /**
     * Error Handler
     * @param error Error
     */
    errorHandler(error: HttpErrorResponse) {
        return throwError(error || 'Error');
    }

}
