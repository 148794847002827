<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">
    <div *ngIf="mainTab" style="display: none;">
        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between p-4">
                <h4>Mobile App Designer</h4>
                <img class="cross-icon-img" (click)="closePanel()" src="./../../../../assets/images/library/close.svg"
                    alt="">
            </div>

        </div>
        <!-- <div class="img-div d-flex justify-content-center py-3">
        <img *ngIf="rowActive" class="main-img" src="./../../../../assets/images/library/MObile-list.svg" alt="mobile-list">
        <img *ngIf="gridActive && stackActive " class="main-img" src="./../../../../assets/images/library/Mobile-grid.svg">
    </div> -->
        <div class="app-filters d-flex pt-1 justify-content-center">
            <button (click)="createListDesign()" class="btn btn-white">Apply</button>
            <button (click)="closePanel()" class="btn btn-white">Cancel</button>
        </div>

        <div class="img-div d-flex justify-content-center align-items-center py-2">

            <img [ngClass]="{'transition':offActive && rowActive}" *ngIf="listDesignImages" class="main-img"
                [src]="listDesignImages" alt="mobile-list">
            <div *ngIf="!listDesignImages">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div class="panel-content">

            <ng-container>

                <div *ngIf="!eventPanel && !calenderPanel">
                    <!-- <h5>Header</h5> -->

                    <p class="info-title" style="text-transform: uppercase;font-weight: bold">Header</p>

                    <div class="d-flex justify-content-between ">
                        <span
                            class="active p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                            *ngIf="offActive" (click)="offActivate('OFF')">
                            <img src="./../../../../assets/images/library/on-off-button.svg" alt="off">
                            <p class="sub-head-p my-2">Off</p>
                        </span>
                        <span class="web-options p-4 d-flex flex-column align-items-center justify-content-center "
                            *ngIf="!offActive" (click)="offActivate('OFF')">
                            <img src="./../../../../assets/images/library/on-off-button-light.svg" alt="off">
                            <p class="sub-head-p my-2">Off</p>
                        </span>

                        <span *ngIf="staticActive"
                            class="active p-4 d-flex flex-column align-items-center justify-content-center web-options">
                            <img src="./../../../../assets/images/library/staticdark.svg" alt="">
                            <p class="sub-head-p my-2">Static</p>
                        </span>

                        <span *ngIf="!staticActive"
                            class="web-options p-4 d-flex flex-column align-items-center justify-content-center "
                            (click)="staticActivate('STATIC')">
                            <img src="./../../../../assets/images/library/static.svg" alt="static">
                            <p class="sub-head-p my-2">Static</p>
                        </span>
                        <span *ngIf="!carouselActive"
                            class="d-flex p-4 flex-column align-items-center justify-content-center web-options"
                            (click)="carouselActivate('CAROUSEL')">
                            <img src="./../../../../assets/images/library/carousel-light.svg" alt="carousel">
                            <p class="sub-head-p my-2">Carousel</p>
                        </span>
                        <span
                            class="active p-4 d-flex flex-column align-items-center justify-content-center web-options"
                            *ngIf="carouselActive">
                            <img src="./../../../../assets/images/library/carousel-dark.svg" alt="carousel">
                            <p class="sub-head-p my-2">Carousel</p>
                        </span>

                    </div>
                </div>

                <!-- <div (click)="openPanel('carousel')" -->

                <div (click)="openAddlistPannel()"
                    class=" carousel-box d-flex justify-content-between align-items-center m-2 py-2 px-4"
                    *ngIf="carouselActive && (!eventPanel || !calenderPanel )">
                    <div class="d-flex align-items-center">
                        <span>
                            <img src="./../../../../assets/images/library/carousel-dark.svg" alt="">
                        </span>
                        <span class="px-4 d-flex flex-column">
                            <span>Carousel item</span>
                            <span>{{combined.length + dataList.length}}/5 added</span>
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                        </span>
                    </div>
                </div>


                <div *ngIf="carouselActive && (!eventPanel || !calenderPanel )">


                    <div class="p-2 ">
                        <!-- <h6>Item Display</h6> -->
                        <p class="info-title pt-1" style="text-transform: uppercase;font-weight: bold">Carousel Design
                        </p>
                        <div>
                            <form class="d-flex justify-content-between py-1" action="">
                                <!-- class="py-1 d-flex align-items-center" -->
                                <div>
                                    <div>
                                        <input type="radio" [(ngModel)]="listDesign.carouselType" name="carouselType"
                                            value="WIDE" checked>
                                        <label class="px-1" for="">Wide</label>
                                    </div>
                                    <img class="carousel-img" src="./../../../../../assets/images/apps/carousel-A.png"
                                        alt="">
                                </div>

                                <div>
                                    <div>
                                        <input type="radio" [(ngModel)]="listDesign.carouselType" name="carouselType"
                                            value="BANNER">
                                        <label class="px-1" for="">Banner</label>
                                    </div>
                                    <img class="carousel-img" src="./../../../../../assets/images/apps/carousel-B.png"
                                        alt="">
                                </div>

                            </form>
                        </div>
                    </div>


                </div>

                <div (click)="openPanel('static')"
                    class=" carousel-box d-flex justify-content-between align-items-center m-2 py-2 px-4"
                    *ngIf="staticActive && (!eventPanel && !calenderPanel ) && withWideArtwork">
                    <div class="d-flex align-items-center">
                        <span>
                            <img class="static-imgs"
                                [src]=" imagePathWide ? imagePathWide :'./../../../../assets/images/library/static.svg'"
                                alt="img">
                        </span>
                        <span class="px-3 d-flex flex-column">
                            <span>Static Image</span>
                            <span *ngIf="!imagePathWide">Add or select an image</span>
                            <span class="ellipsis-text" *ngIf="imagePathWide"> {{staticImageName}}</span>
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                        </span>
                    </div>
                </div>

                <div (click)="openPanel('static')"
                    class=" carousel-box d-flex justify-content-between align-items-center m-2 py-2 px-4"
                    *ngIf="staticActive && (!eventPanel && !calenderPanel ) && !withWideArtwork">
                    <div class="d-flex align-items-center">
                        <span>
                            <img class="static-imgs"
                                [src]=" imagePath ? imagePath :'./../../../../assets/images/library/static.svg'"
                                alt="img">
                        </span>
                        <span class="px-3 d-flex flex-column">
                            <span>Static Image</span>
                            <span *ngIf="!imagePath">Add or select an image</span>
                            <span class="ellipsis-text" *ngIf="imagePath"> {{staticImageName}}</span>
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                        </span>
                    </div>
                </div>

                <div *ngIf="staticActivate && !carouselActive && !offActive">


                    <div class="p-2 ">
                        <!-- <h6>Item Display</h6> -->
                        <p class="info-title pt-1" style="text-transform: uppercase;font-weight: bold">Static Design</p>
                        <div>
                            <form class="d-flex justify-content-between py-1" action="">
                                <!-- class="py-1 d-flex align-items-center" -->
                                <div>
                                    <div>
                                        <input type="radio" [(ngModel)]="listDesign.staticType" name="staticType"
                                            (change)="changeStaticType('WIDE')" value="WIDE" checked>
                                        <label class="px-1" for="">Wide</label>
                                    </div>
                                    <img class="carousel-img" src="./../../../../../assets/images/apps/carousel-A.png"
                                        alt="">
                                </div>

                                <div>
                                    <div>
                                        <input type="radio" [(ngModel)]="listDesign.staticType" name="staticType"
                                            (change)="changeStaticType('BANNER')" value="BANNER">
                                        <label class="px-1" for="">Banner</label>
                                    </div>
                                    <img class="carousel-img" src="./../../../../../assets/images/apps/carousel-B.png"
                                        alt="">
                                </div>

                            </form>
                        </div>
                    </div>


                </div>




                <div *ngIf="!eventPanel && !calenderPanel" class="py-4">
                    <!-- <h6>Item Layout</h6> -->
                    <p class="info-title" style="text-transform: uppercase;font-weight: bold">Item Layout</p>
                    <div class="d-flex justify-content-between ">
                        <span
                            class="p-4 active d-flex flex-column align-items-center justify-content-center  web-options"
                            *ngIf="rowActive" (click)="rowActivate('ROWS')">
                            <img src="./../../../../assets/images/library/row-dark.svg" alt="row">
                            <p class="sub-head-p my-2">Rows</p>
                        </span>
                        <span *ngIf="!rowActive"
                            class="p-4 d-flex flex-column align-items-center justify-content-center web-options"
                            (click)="rowActivate('ROWS')">
                            <img src="./../../../../assets/images/library/row-light.svg" alt="row">
                            <p class="sub-head-p my-2">Rows</p>
                        </span>
                        <span *ngIf="!gridActive"
                            class="p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                            (click)="gridActivate('GRID')">
                            <img src="./../../../../assets/images/library/grid.svg" alt="grid">
                            <p class="sub-head-p my-2">Grid</p>
                        </span>
                        <span *ngIf="gridActive"
                            class="p-4 d-flex flex-column align-items-center justify-content-center  active web-options">
                            <img src="./../../../../assets/images/library/grid-dark.svg" alt="grid">
                            <p class="sub-head-p my-2">Grid</p>
                        </span>
                        <span *ngIf="!stackActive"
                            class="p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                            (click)="stackActivate('STACKED')">
                            <img src="./../../../../assets/images/library/stack-light.svg" alt="stacked">
                            <p class="sub-head-p my-2">Stacked</p>
                        </span>
                        <span *ngIf="stackActive"
                            class="p-4 d-flex flex-column align-items-center justify-content-center  active web-options">
                            <img src="./../../../../assets/images/library/stack-dark.svg" alt="stacked">
                            <p class="sub-head-p my-2">Stacked</p>
                        </span>
                    </div>
                </div>

                <div *ngIf="eventPanel || calenderPanel" class="py-4">
                    <p class="info-title">Item Layout</p>
                    <span class="p-4 active d-flex flex-column align-items-center justify-content-center  web-options"
                        *ngIf="rowActive" (click)="rowActivate('ROWS')">
                        <img src="./../../../../assets/images/library/row-dark.svg" alt="row">
                        <p class="sub-head-p my-2">Rows</p>
                    </span>
                </div>

                <div class="py-4 d-flex"
                    *ngIf="(offActive && rowActive) || (carouselActive && rowActive) || (staticActive && rowActive) ">
                    <div>
                        <!-- <h6>Item Display</h6> -->
                        <p class="info-title">Item Display</p>
                        <form action="">
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" [(ngModel)]="listDesign.itemDisplay" name="itemDisplay"
                                    (ngModelChange)="getListDesignChange(listDesign.itemDisplay)" #itemDisplay="ngModel"
                                    value="IMAGE">
                                <label class="px-1" for="">Image</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" [(ngModel)]="listDesign.itemDisplay" name="itemDisplay"
                                    (ngModelChange)="getListDesignChange(listDesign.itemDisplay)" #itemDisplay="ngModel"
                                    value="DATE">
                                <label class="px-1" for="">Date</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" [(ngModel)]="listDesign.itemDisplay" name="itemDisplay"
                                    (ngModelChange)="getListDesignChange(listDesign.itemDisplay)" #itemDisplay="ngModel"
                                    value="TITLE_ONLY">
                                <label class="px-1" for="">Title Only</label>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="listDesign.itemDisplay==='IMAGE'" class="px-4 mx-4">
                        <div>
                            <!-- <h6>Item Images</h6> -->
                            <p class="info-title">Item Images</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" checked [(ngModel)]="listDesign.itemImages" name="itemImages"
                                        (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                        #itemImages="ngModel" value="SQUARE">
                                    <label class="px-1" for="">Square</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.itemImages" name="itemImages"
                                        (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                        #itemImages="ngModel" value="WIDE">
                                    <label class="px-1" for="">Wide</label>
                                </div>
                                <!-- <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.itemImages" name="itemImages" #itemImages="ngModel" value="BANNER">
                                    <label class="px-1" for="">Banner</label>
                                </div> -->
                                <div class="py-1 d-flex align-items-center">
                                    <label class="switch-input " tooltip="" container="body">
                                        <input type="checkbox" [checked]="listDesign.shadow"
                                            [(ngModel)]="listDesign.shadow" name="shadow" #shadow="ngModel"
                                            (ngModelChange)="getListDesignChange(listDesign.shadow)">
                                        <span class="slider "></span>
                                    </label>
                                    <label class="px-1" for="">Shadows</label>
                                </div>





                            </form>
                        </div>
                        <!-- <div class="py-3" *ngIf="carouselActive">
                            <p class="info-title">Margin Edges</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" value="SHARP" name="marginEdges" checked (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Sharp</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" value="CURVE" name="marginEdges" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Curve</label>
                   carousalData             </div>
                            </form>
                        </div> -->
                        <!-- <div class="py-3">
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div>
                        </div>  -->
                    </div>
                </div>


                <div class="py-4 d-flex"
                    *ngIf="(offActive && gridActive) || (carouselActive && gridActive ) || (staticActive && gridActive) ">
                    <div>
                        <div>
                            <!-- <h6>Item Titles</h6> -->
                            <p class="info-title">Item Titles</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" checked name="itemTitles" [(ngModel)]="listDesign.itemTitles"
                                        (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                        #itemTitles="ngModel" value="BELOW">
                                    <label class="px-1" for="">Below</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" name="itemTitles" [(ngModel)]="listDesign.itemTitles"
                                        (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                        #itemTitles="ngModel" value="OFF">
                                    <label class="px-1" for="">Off</label>
                                </div>
                            </form>
                        </div>

                    </div>
                    <div class="px-4 mx-4">
                        <div>
                            <!-- <h6>Item Images</h6> -->
                            <p class="info-title">Item Images</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" checked name="itemImages" [(ngModel)]="listDesign.itemImages"
                                        (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                        #itemImages="ngModel" value="SQUARE">
                                    <label class="px-1" for="">Square</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" name="itemImages" [(ngModel)]="listDesign.itemImages"
                                        (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                        #itemImages="ngModel" value="WIDE">
                                    <label class="px-1" for="">Wide</label>
                                </div>
                                <div *ngIf="!listDesign.margins" class="py-1 d-flex align-items-center">
                                    <label class="switch-input" tooltip="" container="body">
                                        <input type="checkbox" [checked]="listDesign.margins"
                                            [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                            (change)="marginSlider($event);getListDesignChange(listDesign.margins)">
                                        <span class="slider"></span>
                                    </label>
                                    <label class="px-1" for="">Margins</label>
                                </div>
                                <div *ngIf="!listDesign.margins" class="py-1 d-flex align-items-center">
                                    <label class="switch-input"
                                        [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                        container="body">
                                        <input type="checkbox" [checked]="listDesign.shadow"
                                            [(ngModel)]="listDesign.shadow" name="shadow" #shadow="ngModel"
                                            [disabled]="!listDesign.margins">
                                        <span class="slider"></span>
                                    </label>
                                    <label class="px-1" for="">Shadows</label>
                                </div>




                            </form>
                        </div>

                    </div>


                </div>
                <div class="d-flex"
                    *ngIf="((offActive && gridActive) || (carouselActive && gridActive ) || (staticActive && gridActive)  ) && listDesign.margins ">
                    <div class="py-3 ">
                        <!-- <h6>Margins</h6> -->
                        <p class="info-title">Margins</p>
                        <form action="">
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" name="marginType" [(ngModel)]="listDesign.marginType"
                                    value="VERYTHIN" (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                <label class="px-1" for="">Very Thin</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" name="marginType" [(ngModel)]="listDesign.marginType" value="THIN"
                                    (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                <label class="px-1" for="">Thin</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" name="marginType" [(ngModel)]="listDesign.marginType" value="THICK"
                                    (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                <label class="px-1" for="">Thick</label>
                            </div>
                        </form>
                    </div>



                    <div>
                        <div class="py-3 px-4 mx-4">
                            <!-- <h6>Margin Edges</h6> -->
                            <p class="info-title">Margin Edges</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" name="marginEdges"
                                        value="SHARP" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Sharp</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" name="marginEdges"
                                        value="CURVE" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Curve</label>
                                </div>
                            </form>
                        </div>
                        <div class="py-3 px-4 mx-4">
                            <!-- <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div> -->


                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="listDesign.margins"
                                        [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)"
                                        (change)="marginSlider($event);getListDesignChange(listDesign.margins)"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input"
                                    [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                    container="body">
                                    <input type="checkbox" [checked]="listDesign.shadow" [(ngModel)]="listDesign.shadow"
                                        (ngModelChange)="getListDesignChange(listDesign.shadow)" name="shadow"
                                        #shadow="ngModel" [disabled]="!listDesign.margins"
                                        (ngModelChange)="getListDesignChange(listDesign.shadow)">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div>

                        </div>
                    </div>
                </div>



                <div class="py-4 d-flex"
                    *ngIf="(offActive && stackActive) || (carouselActive && stackActive ) || (staticActive && stackActive)">
                    <div>

                        <p class="info-title">Item Titles</p>
                        <form action="">
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" checked [(ngModel)]="listDesign.itemTitles" name="itemTitles"
                                    (ngModelChange)="getListDesignChange(listDesign.itemTitles)" #itemTitles="ngModel"
                                    value="BELOW">
                                <label class="px-1" for="">Below</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" [(ngModel)]="listDesign.itemTitles" name="itemTitles"
                                    (ngModelChange)="getListDesignChange(listDesign.itemTitles)" #itemTitles="ngModel"
                                    value="OVERLAY">
                                <label class="px-1" for="">Overlay</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" [(ngModel)]="listDesign.itemTitles" name="itemTitles"
                                    (ngModelChange)="getListDesignChange(listDesign.itemTitles)" #itemTitles="ngModel"
                                    value="OFF">
                                <label class="px-1" for="">Off</label>
                            </div>
                        </form>
                    </div>




                    <div class="px-4 mx-4">
                        <div>


                            <p class="info-title">Item Images</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" checked name="itemImages" [(ngModel)]="listDesign.itemImages"
                                        (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                        #itemImages="ngModel" value="WIDE" checked>
                                    <label class="px-1" for="">Wide</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" name="itemImages" [(ngModel)]="listDesign.itemImages"
                                        (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                        #itemImages="ngModel" value="BANNER">
                                    <label class="px-1" for="">Banner</label>
                                </div>

                            </form>
                        </div>



                        <!-- <div *ngIf="listDesign.margins" class="py-3 mt-2">
                                            <p class="info-title py-1">Margin Edges</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" (ngModelChange)="getListDesignChange(listDesign.marginEdges)" name="marginEdges" value="SHARP"  (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Sharp</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" (ngModelChange)="getListDesignChange(listDesign.marginEdges)" name="marginEdges" value="CURVE" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Curve</label>
                                </div>
                            </form>
                        </div> -->
                        <div *ngIf="!listDesign.margins" class="py-3">
                            <form action="">


                                <div class="py-1 d-flex align-items-center">



                                    <label class="switch-input" tooltip="" container="body">
                                        <input type="checkbox" [checked]="listDesign.margins"
                                            [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                            (ngModelChange)="getListDesignChange(listDesign.margins)"
                                            (change)="marginSlider($event);getListDesignChange(listDesign.margins)">
                                        <span class="slider"></span>
                                    </label>
                                    <label class="px-1" for="">Margins</label>
                                </div>

                                <div class="py-1 d-flex align-items-center">
                                    <label class="switch-input"
                                        [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': '' "
                                        container="body">
                                        <input type="checkbox" [checked]="listDesign.shadow"
                                            [(ngModel)]="listDesign.shadow" name="shadow" #shadow="ngModel"
                                            (ngModelChange)="getListDesignChange(listDesign.shadow)"
                                            [disabled]="!listDesign.margins">
                                        <span class="slider"></span>
                                    </label>
                                    <label class="px-1" for="">Shadows</label>
                                </div>


                            </form>
                        </div>

                    </div>
                </div>


                <div class="d-flex"
                    *ngIf="((offActive && stackActive) || (carouselActive && stackActive ) || (staticActive && stackActive)  ) && listDesign.margins">
                    <div class="py-3 ">
                        <!-- <h6>Margins</h6> -->



                        <p class="info-title">Margins</p>
                        <form action="">
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" name="marginType" [(ngModel)]="listDesign.marginType"
                                    value="VERYTHIN" (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                <label class="px-1" for="">Very Thin</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" name="marginType" [(ngModel)]="listDesign.marginType" value="THIN"
                                    (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                <label class="px-1" for="">Thin</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <input type="radio" name="marginType" [(ngModel)]="listDesign.marginType" value="THICK"
                                    (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                <label class="px-1" for="">Thick</label>
                            </div>
                        </form>
                    </div>



                    <div>
                        <div class="py-3 px-4 mx-4">
                            <!-- <h6>Margin Edges</h6> -->
                            <p class="info-title">Margin Edges</p>
                            <form action="">
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" name="marginEdges"
                                        value="SHARP" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Sharp</label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <input type="radio" [(ngModel)]="listDesign.marginEdges" name="marginEdges"
                                        value="CURVE" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                    <label class="px-1" for="">Curve</label>
                                </div>
                            </form>
                        </div>
                        <div class="py-3 px-4 mx-4">
                            <!-- <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div> -->


                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="listDesign.margins"
                                        [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)"
                                        (change)="marginSlider($event);getListDesignChange(listDesign.margins)"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input"
                                    [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                    container="body">
                                    <input type="checkbox" [checked]="listDesign.shadow" [(ngModel)]="listDesign.shadow"
                                        (ngModelChange)="getListDesignChange(listDesign.shadow)" name="shadow"
                                        #shadow="ngModel" [disabled]="!listDesign.margins"
                                        (ngModelChange)="getListDesignChange(listDesign.shadow)">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- <div class="info-msg" >
                    <p>This design may be tricky for users</p>
                    <p>A Static Header can look similar to Stacked Item Layouts without Margins. Users may have a tough time telling the difference between your content and your Header, so we recommend changing your Header.</p>
                </div> -->



            </ng-container>
            <!-- </div>  -->
            <!-- <div class="app-filters d-flex p-4 justify-content-center">
                <button (click)="createListDesign()" class="btn btn-white">Apply</button>
                <button (click)="closePanel()" class="btn btn-white">Cancel</button>
            </div> -->
        </div>
    </div>

    <div *ngIf="subTab">
        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between p-4">
                <h4>Carousel Items</h4>
                <!-- <img class="cross-icon-img" (click)="back()" src="./../../../../assets/images/library/back-button.svg"
                    alt=""> -->
            </div>
        </div>
        <div class="panels-header">
            <div class="app-filters d-flex pt-1 justify-content-center">
                <button *ngIf="this.clickedFrom !== 'mobileApp'" (click)="back()" class="btn btn-white">Apply</button>
                <button *ngIf="this.clickedFrom !== 'mobileApp'" (click)="back()" class="btn btn-white">Cancel</button>
                <button *ngIf="this.clickedFrom === 'mobileApp'" (click)="createListDesign()"
                    class="btn btn-white">Apply</button>
                <button *ngIf="this.clickedFrom === 'mobileApp'" (click)="closePanel();cancelPanel()"
                    class="btn btn-white">Cancel</button>
            </div>


        </div>
        <div class="d-flex my-2 align-items-center justify-content-center">
            <div class="app-filters " style="padding:10px">
                <button class="btn-white-rounded" (click)="openCarouselPanel('mobile')">
                    <em [appSvgIcon]="'plus'"></em> Add item
                </button>
                <span class="span-text px-3" style="font-weight: 600;">Add up to 5 carousel items</span>
            </div>

        </div>
        <div class="panel-content" style="padding:0">
            <ng-container>
                <!-- <div class="d-flex align-items-center">
                    <div class="app-filters d-flex align-items-center justify-content-between">
                        <button class="btn-white-rounded" (click)="openCarouselPanel('mobile')">
                            <em [appSvgIcon]="'plus'"></em> Add item
                        </button>
                        <span class="span-text px-3">Add up to 5 carousel items</span>
                    </div>
                </div> -->
                <!-- <div class="folder-items">
                    <ul class="trending-list">
                        <li *ngFor="let item of combined">
                            <div class="main-content-div py-2">
                                <div class="d-flex align-items-center">
                                    <span class="user-img">
                                        <img src="./../../../../assets/images/library/square-img.png" alt="">
                                    </span>
                                    <span class="text-content ">
                                        <!-- <h6 class="my-0 px-1">Watch Live </h6> -->
                <!-- <p class="my-0 px-1">{{item.title}} </p>
                                    </span>
                                </div>
                            </div>
                            <div class="side-icons d-flex justify-content-between align-items-center pb-3 ">


                                <img src="./../../../../assets/images/library/trash.svg" alt="delete-icon">

                            </div>
                        </li>


                        <li *ngFor="let item of dataList">
                            <div class="main-content-div py-2">
                                <div class="d-flex align-items-center">
                                    <span class="user-img">
                                        <img src="./../../../../assets/images/library/square-img.png" alt="">
                                    </span>
                                    <span class="text-content ">
                                        <h6 class="my-0 px-1">{{item.id}} </h6>
                                        <p class="my-0 px-1">{{item.title}} </p>
                                    </span>
                                </div>
                            </div>
                            <div class="side-icons d-flex justify-content-between align-items-center pb-3 ">


                                <img src="./../../../../assets/images/library/trash.svg" alt="delete-icon">

                            </div>
                        </li>

                    </ul>
                </div> -->


                <div class="app-block" style="box-shadow: none !important;">
                    <!-- <div class="app-block-title">
                        <p class="info-title">Folder Items</p>
                    </div> -->
                    <!-- <ul  class="recent-item-list m-0">
                        <li *ngFor="let item of combined">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.path : (item.wideArtwork ? item.wideArtwork.document.path : (item.bannerArtwork ? item.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{item.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                    <!-- </div>
                            </div>
                            <div> -->
                    <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                    [(ngModel)]="item.itemChecked" id=""> -->
                    <!-- <img src="./../../../../assets/images/library/trash.svg" alt="delete-icon">
                            </div>

                        </li>
                    </ul> -->

                    <ul class="recent-item-list m-0" cdkDropList (cdkDropListDropped)="drop($event,'content')">
                        <li *ngFor="let item of combined;index as i" cdkDrag>
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <span class="user-img"  [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.squareArtworkId ? item.newimg :  ''" onload="style.opacity = 1"
                                        *ngIf="item.squareArtworkId" style="background-color: #e6e6e6;opacity: 0;"
                                        alt="">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''"
                                        *ngIf="!item.squareArtworkId" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide" [ngStyle]="{'background-color': item.wideArtwork ? item.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.wideArtworkId ? item.newwideimg :  ''" onload="style.opacity = 1"
                                        *ngIf="item.wideArtworkId" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                    <img [src]="item.wideArtwork ? item.wideArtwork.document.newwideimg :  ''"
                                        *ngIf="!item.wideArtworkId" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner" [ngStyle]="{'background-color': item.bannerArtwork ? item.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="item.bannerArtworkId ? item.newbannerimg :  ''"
                                        onload="style.opacity = 1" *ngIf="item.bannerArtworkId"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                    <img [src]="item.bannerArtwork ? item.bannerArtwork.document.newbannerimg :  ''"
                                        *ngIf="!item.bannerArtworkId" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer ellipsis-text">
                                        {{item.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                            <div style="
                            display: flex;
                            width: auto;
                            height: 17px;
                            margin-top: 8%;
                        ">
                                <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                    [(ngModel)]="item.itemChecked" id=""> -->
                                <img (click)="deleteItem(i)" src="./../../../../assets/images/library/trash.svg"
                                    class="delete_icon" alt="delete-icon">

                            </div>

                        </li>

                        <li *ngFor="let item of dataList;index as i" cdkDrag>
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <span class="user-img" [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.squareArtworkId ? item.newimg :  ''" onload="style.opacity = 1"
                                        *ngIf="item.squareArtworkId" style="background-color: #e6e6e6;opacity: 0;"
                                        alt="">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''"
                                        *ngIf="!item.squareArtworkId" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide" [ngStyle]="{'background-color': item.wideArtwork ? item.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.wideArtworkId ? item.newwideimg :  ''" onload="style.opacity = 1"
                                        *ngIf="item.wideArtworkId" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                    <img [src]="item.wideArtwork ? item.wideArtwork.document.newwideimg :  ''"
                                        *ngIf="!item.wideArtworkId" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner" [ngStyle]="{'background-color': item.bannerArtwork ? item.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'BANNER' && carouselArtworkType !== 'WIDE'">
                                    <img [src]="item.bannerArtworkId ? item.newbannerimg :  ''"
                                        onload="style.opacity = 1" *ngIf="item.bannerArtworkId"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                    <img [src]="item.bannerArtwork ? item.bannerArtwork.document.newbannerimg :  ''"
                                        *ngIf="!item.bannerArtworkId" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer ellipsis-text">
                                        {{item.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                            <div style="
                            display: flex;
                            width: auto;
                            height: 17px;
                            margin-top: 8%;
                        ">
                                <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                    [(ngModel)]="item.itemChecked" id=""> -->
                                <img (click)="delete(i,item)" src="./../../../../assets/images/library/trash.svg"
                                    class="delete_icon" alt="delete-icon">

                            </div>

                        </li>

                        <!-- <li *ngIf="!combined.length && dataList.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->

                    </ul>
                </div>





            </ng-container>
        </div>


    </div>

    <div *ngIf="infoMsg" class="info-msg">
        <p class="info-msg-heading">This design may be tricky for users</p>
        <p class="info-msg-content">A Static Header can look similar to Stacked Item Layouts without Margins. Users may
            have a tough time telling the difference between your content and your Header, so we recommend changing your
            Header.</p>

        <div class="d-flex justify-content-between align-items-center">

            <span (click)="closeInfoMsg()" class="info-msg-close">
                NO THANKS
            </span>

            <span (click)="offActivate('OFF')" class="info-msg-off">
                TURN HEADER OFF
            </span>

        </div>

    </div>


</div>

<!-- <div class="info-msg" >
    <p>This design may be tricky for users</p>
    <p>A Static Header can look similar to Stacked Item Layouts without Margins. Users may have a tough time telling the difference between your content and your Header, so we recommend changing your Header.</p>
</div> -->

<!-- <app-artwork (updateArtwork)="getArtwork($event)"></app-artwork> -->

<app-artwork></app-artwork>

<app-add-to-list></app-add-to-list>