<div class="main">
    <div class="sub-main">
        <div class="content-wrap">
            <div class="content">
                <!-- <div class="selection d-flex">
                    <div class="mr-2" style="margin-right: 10px;">
                        <label>Mobile</label>
                        <input type="radio" checked name="platform" id="select-platform" (click)="changePlatform('mobile')">
                    </div>
                    <div>
                        <label>Tablet</label>
                        <input type="radio" name="platform" id="select-platform" (click)="changePlatform('tablet')">
                    </div>
                </div> -->

                <div class="main-mobile" *ngIf="showMobile">
                    <img class="platform-image" src="assets/images/web/Mobile 3.svg" alt="">

                    <div class="mobile-background" [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                        <div class="main-container" *ngIf="showAllData">
                            <section class="header" [ngStyle]="{'background-color': brandColor}"
                                [ngClass]="[tabType === 'BIBLE' && theme === 'dark' ? 'darkHeader' : '']">
                                <div class="menu-icon">
                                    <span class="hamburger-icon" (click)="openSidebar()">
                                        <em [appSvgIcon]="'hamburger'"></em>
                                    </span>
                                </div>
                                <div class="tab-name">{{tabName}}</div>
                                <div class="tab-name" *ngIf="tabType === 'BLOG' && this.showdummyData">Dummy Data</div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'BUILD_YOUR_OWN' && showData"
                                [ngClass]="[marginType === 'VERYTHIN' ? 'p1-body' : '' || marginType === 'THIN' ? 'p2-body' : '' || marginType === 'THICK' ? 'p4-body' : '', theme === 'light' ? 'light' : 'dark']">
                                <div *ngIf="showCarousal" class="platform-carousal-wrapper" style="width: 100%;">
                                    <p-carousel [value]="products" [numVisible]="1" [circular]="carousalRotate"
                                        [autoplayInterval]="3000" [numScroll]="1"
                                        [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius2' : '']">
                                        <!-- <ng-template pTemplate="header">
                                        <h5>Basic</h5>
                                    </ng-template> -->
                                        <ng-template let-product pTemplate="item">
                                            <div class="product-item">
                                                <div class="product-item-content text-ellipsis"
                                                    style="position: relative;">
                                                    <!-- <div class="mb-3"> -->
                                                    <img [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius2' : '']"
                                                        [src]="showBannerCarousal ? product.bannerImg : product.wideImg"
                                                        class="product-image" />
                                                    <!-- </div> -->
                                                    <div class="text-wrapper"
                                                        [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && imageType === 'SQUARE' ? 'w70' : '' , imageType === 'WIDE' && itemLayout === 'ROWS'  ? 'w60' : '' , !showImage ? 'onlyText' : '' ]"
                                                        *ngIf="showTitle && overlayText">
                                                        <div class="item-title"
                                                            [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                                            {{product.title}}
                                                        </div>
                                                        <div class="item-subtitle">{{product.subtitle}}</div>
                                                    </div>
                                                    <div *ngIf="overlayText === true && showBannerTransparency"
                                                        [style.opacity]="(transparencyCount / 10)"
                                                        [style.background]="transparencyColor"
                                                        [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius2' : '', carousalType === 'BANNER' ? 'carousalTypeBanner' : 'carousalTypeWide']"
                                                        class="transparentBlock"></div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-carousel>
                                </div>
                                <div [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p1-Bottom' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p1-Bottom' : '']"
                                    *ngIf="showStatic" class="platform-carousal-wrapper" style="width: 100%;">
                                    <!-- <div class="static-header" *ngIf="withWideArtwork">
                                        <span
                                            [ngClass]="[showStatic ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [ngStyle]="{'background-color': staticHeaderColor}">
                                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                                [src]="staticWideHeaderImg" alt="" onload="style.opacity = 1"
                                                style="opacity: 0;">
                                        </span>
                                    </div> -->
                                    <div class="static-header " *ngIf="withWideArtwork" style="position: relative; "
                                        [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', withWideArtwork && staticWideHeaderImg ? 'wideHeight' : '']"
                                        [ngStyle]="{'background-color': staticHeaderColor && !video ? staticHeaderColor : '',
                                         'height': marginType === 'THICK' && '128px' || marginType === 'THIN' && '132px' ||  marginType === 'VERYTHIN' && '137px'}"
                                        [class.no-background]="video">

                                        <!-- <span [ngClass]="[staticActive ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'staticradius1' : '', staticActive && withWideArtwork && staticWideHeaderImg ? 'wideHeight' : '']" [ngStyle]="{'background-color': staticHeaderColor}"> -->
                                        <img *ngIf="!video"
                                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [src]="staticWideHeaderImg" alt="" onload="style.opacity = 1"
                                            style="opacity: 0;">

                                        <div *ngIf="overlayText === true && showBannerTransparency && !video"
                                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [style.opacity]="(transparencyCount / 10)"
                                            [style.background]="transparencyColor" class="transparentBlock"></div>
                                        <!-- <div [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            *ngIf="m3u8 && video" plyr plyrType="video" style="height: 100%;width:100%;"
                                            [plyrOptions]="options" plyrTitle="Video 1" [plyrPlaysInline]="true"
                                            [plyrDriver]="hlsjsDriver2" [plyrSources]="currentVedio" (plyrInit)="players = $event"
                                            [plyrPoster]="poster"></div> -->

                                        <!-- (plyrEnded)="completeLiveVideo($event)" (plyrStateChange)="stateChange($event)"
                                            (plyrEnterFullScreen)="playedFullScreen($event)" (plyrPlay)="liveVideoPlayed($event)" -->
                                        <div [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            *ngIf="video">
                                            <app-video-player
                                                [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                                [options]="{radiusClass:marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '',muted: true,autoplay: autoPlayChange ? 'play':false, controls: false, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                            </app-video-player>
                                        </div>

                                        <!-- <div [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [plyrOptions]="options" *ngIf="!m3u8 && video" id="vidPlay" plyr plyrType="video"
                                            style="height: 100%;width:100%;" plyrTitle="Video 1" [plyrPlaysInline]="true"
                                            [plyrSources]=videoPath (plyrInit)="players = $event" [plyrPoster]="poster"></div> -->

                                        <!-- (plyrEnded)="completeLiveVideo($event)"
                                            (plyrStateChange)="stateChange($event)" (plyrEnterFullScreen)="playedFullScreen($event)"
                                            (plyrPlay)="liveVideoplayed($event)" -->


                                        <!-- <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                                            style="background-color: #e6e6e6;opacity: 0;" alt=""> -->
                                        <!-- </span> -->
                                    </div>
                                    <div class="static-header" *ngIf="!withWideArtwork" style="position: relative;">
                                        <div [ngClass]="[showStatic ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [ngStyle]="marginType === 'THICK' && {'height':'74px', 'background-color': staticHeaderColor} ||
                                             marginType === 'THIN' && {'height':'78px','background-color': staticHeaderColor} ||
                                             marginType === 'VERYTHIN' && {'height':'80px','background-color': staticHeaderColor} ">
                                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                                [src]="staticBannerHeaderImg" alt="" onload="style.opacity = 1"
                                                style="opacity: 0;">
                                            <div *ngIf="overlayText === true && showBannerTransparency && !video"
                                                [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                                [style.opacity]="(transparencyCount / 10)"
                                                [style.background]="transparencyColor" class="transparentBlock"></div>
                                            <!-- <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt=""> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="item-wrapper"
                                    [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '', itemLayout === 'STACKED' ? 'w100' : '' || itemLayout === 'ROWS' ? 'rowLayout' : '', itemLayout === 'STACKED' && imageType === 'BANNER' ? 'stackBannerImg' : '', overlayText === true ? 'overlayWrapper' : '', itemLayout === 'STACKED' && itemTitle === 'BELOW' && imageType === 'WIDE' ? 'stackWideBelowTitle' : '' , theme === 'light' ? 'light' : 'dark' ]"
                                    *ngFor="let item of contents">
                                    <!-- <div class="item-container"> -->
                                    <div class="item-image"
                                        [ngClass]="[itemLayout === 'ROWS' && imageType === 'SQUARE' ? 'wrow' : '', imageType === 'WIDE' && itemLayout === 'ROWS' ? 'rowWide' : '', !showImage && !showDate ? 'hideContainer' : '']">
                                        <span *ngIf="showImage"
                                            [ngClass]="[itemLayout === 'GRID' && imageType === 'SQUARE' ? 'gridSquareImg user-img' : '', itemLayout === 'GRID' && imageType === 'WIDE' ? 'gridWideImg user-img-wide' : '', itemLayout === 'STACKED' && imageType === 'WIDE' ? 'stackWide user-img-wide' : '', itemLayout === 'STACKED' && imageType === 'BANNER' ? 'stackBannerImg user-banner' : '', itemLayout === 'ROWS' && imageType === 'SQUARE' ? 'rowSquareImg user-img' : '' , itemLayout === 'ROWS' && imageType === 'WIDE' ? 'rowWideImg user-img-wide' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                            [ngStyle]="{'background-color': item.Imgcolor}">
                                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                                [src]="item.newimg" onload="style.opacity = 1"
                                                style="background-color: #e6e6e6;opacity: 0;" alt="">
                                        </span>
                                        <div class="date-wrapper" *ngIf="showDate">
                                            <div class="date dateMonth">{{item.createdDate | date: 'MMM'}}</div>
                                            <div class="date dateDate">{{item.createdDate | date: 'dd'}}</div>
                                            <div class="date dateYear">{{item.createdDate | date: 'YYYY'}}</div>
                                        </div>
                                        <div *ngIf="overlayText === true && showTransparency"
                                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [style.opacity]="(transparencyCount / 10)"
                                            [style.background]="transparencyColor" class="transparentBlock"></div>
                                    </div>
                                    <div class="text-wrapper"
                                        [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && imageType === 'SQUARE' ? 'w70' : '' , imageType === 'WIDE' && itemLayout === 'ROWS'  ? 'w60' : '' , !showImage ? 'onlyText' : '' ]"
                                        *ngIf="showTitle">
                                        <div class="item-title"
                                            [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">{{item.title}}
                                        </div>
                                        <div class="item-subtitle">{{item.subtitle}}</div>
                                    </div>
                                    <!-- <div *ngIf="overlayText === true && showTransparency"
                                        [style.opacity]="(transparencyCount / 10)"
                                        [style.background]="transparencyColor"
                                        [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius2' : '']"
                                        class="transparentBlock"></div> -->
                                    <!-- </div> -->
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'GIVING'"
                                [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                                <div class="main-donation">
                                    <div class="upper">
                                        <div class="donation-title"
                                            [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                            {{givingTitle}}
                                        </div>
                                        <div class="donation-input">
                                            <div class="sign"
                                                [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">$</div>
                                            <div class="input-field">
                                                <input type="text" [ngClass]="[theme === 'light' ? 'light' : 'dark']"
                                                    class="input" placeholder="0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom">
                                        <button class="next-btn"
                                            [ngStyle]="{'background-color': brandColor}">Next</button>
                                    </div>
                                </div>
                            </section>

                            <section class="main-body blog" *ngIf="tabType === 'BLOG'"
                                [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                                <div class="main-blog">
                                    <div class="reader-Blog" *ngIf="blogLayout === 'READER'">
                                        <div class="blog-cards" *ngFor="let item of blogData">
                                            <div class="info-wrapper" [ngStyle]="{width: item.imageURL ? '' : '100%'}">
                                                <h1 class="blogTitle"
                                                    [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                                    {{item.title}}</h1>
                                                <div class="blogDate">{{item.date | date : 'MMM dd yyyy hh:mm:ss'}}
                                                </div>
                                                <p class="blogDesc"
                                                    [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                                    {{item.description}}</p>
                                            </div>
                                            <div class="img-wrapper" [ngStyle]="{width: item.imageURL ? '' : '0%'}">
                                                <img [src]="item.imageURL" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="blogLayout === 'ROWS'">
                                        <div class="row-blog" *ngFor="let item of blogData">
                                            <div class="row-wrapper" [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                                                <div class="rowImg" *ngIf="item.imageURL ? item.imageURL : item.rowImg">
                                                    <img [src]="item.imageURL ? item.imageURL : item.rowImg" alt="">
                                                </div>
                                                <div class="rowBlogTitle"
                                                    [ngStyle]="{width: item.imageURL ? '' : '100%'}"
                                                    [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                                    {{item.title}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'MUSIC'"
                                [ngClass]="[marginType === 'VERYTHIN' ? 'p1-body' : '' || marginType === 'THIN' ? 'p2-body' : '' || marginType === 'THICK' ? 'p4-body' : '', theme === 'light' ? 'light' : 'dark']">
                                <div *ngIf="showCarousal" class="platform-carousal-wrapper" style="width: 100%;">
                                    <p-carousel [value]="products" [numVisible]="1" [circular]="carousalRotate"
                                        [autoplayInterval]="3000" [numScroll]="1"
                                        [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius2' : '']">
                                        <!-- <ng-template pTemplate="header">
                                        <h5>Basic</h5>
                                    </ng-template> -->
                                        <ng-template let-product pTemplate="item">
                                            <div class="product-item">
                                                <div class="product-item-content">
                                                    <!-- <div class="mb-3"> -->
                                                    <img [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius2' : '']"
                                                        [src]="showBannerCarousal ? product.bannerImg : product.wideImg"
                                                        class="product-image" />
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-carousel>
                                </div>
                                <div [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p1-Bottom' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p1-Bottom' : '']"
                                    *ngIf="showStatic" class="platform-carousal-wrapper" style="width: 100%;">
                                    <div class="static-header">
                                        <span
                                            [ngClass]="[showStatic ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                            [ngStyle]="{'background-color': staticHeaderColor}">
                                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                                                [src]="staticHeaderImg" alt="" onload="style.opacity = 1"
                                                style="opacity: 0;">
                                            <!-- <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt=""> -->
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrapper"
                                    [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '', itemLayout === 'STACKED' ? 'w100' : '' || itemLayout === 'ROWS' ? 'rowLayout' : '', itemLayout === 'STACKED' && imageType === 'BANNER' ? 'stackBannerImg' : '', overlayText === true ? 'overlayWrapper' : '', itemLayout === 'STACKED' && itemTitle === 'BELOW' && imageType === 'WIDE' ? 'stackWideBelowTitle' : '' , theme === 'light' ? 'light' : 'dark' ]"
                                    *ngFor="let item of contents">
                                    <!-- <div class="item-container"> -->
                                    <div class="item-image"
                                        [ngClass]="[itemLayout === 'ROWS' && imageType === 'SQUARE' ? 'wrow' : '', imageType === 'WIDE' && itemLayout === 'ROWS' ? 'rowWide' : '', !showImage && !showDate ? 'hideContainer' : '']">
                                        <span *ngIf="showImage"
                                            [ngClass]="[itemLayout === 'GRID' && imageType === 'SQUARE' ? 'gridSquareImg user-img' : '', itemLayout === 'GRID' && imageType === 'WIDE' ? 'gridWideImg user-img-wide' : '', itemLayout === 'STACKED' && imageType === 'WIDE' ? 'stackWide user-img-wide' : '', itemLayout === 'STACKED' && imageType === 'BANNER' ? 'stackBannerImg user-banner' : '', itemLayout === 'ROWS' && imageType === 'SQUARE' ? 'rowSquareImg user-img' : '' , itemLayout === 'ROWS' && imageType === 'WIDE' ? 'rowWideImg user-img-wide' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                            [ngStyle]="{'background-color': item.Imgcolor}">
                                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                                [src]="item.newimg" onload="style.opacity = 1"
                                                style="background-color: #e6e6e6;opacity: 0;" alt="">
                                        </span>
                                        <div class="date-wrapper" *ngIf="showDate">
                                            <div class="date dateMonth">{{item.createdDate | date: 'MMM'}}</div>
                                            <div class="date dateDate">{{item.createdDate | date: 'dd'}}</div>
                                            <div class="date dateYear">{{item.createdDate | date: 'YYYY'}}</div>
                                        </div>
                                    </div>
                                    <div class="text-wrapper"
                                        [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && imageType === 'SQUARE' ? 'w70' : '' , imageType === 'WIDE' && itemLayout === 'ROWS'  ? 'w60' : '' , !showImage ? 'onlyText' : '' ]"
                                        *ngIf="showTitle">
                                        <div class="item-title"
                                            [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">{{item.title}}
                                        </div>
                                        <div class="item-subtitle">{{item.subtitle}}</div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'BIBLE'"
                                [ngClass]="{bible: tabType === 'BIBLE'}">
                                <div class="main-bible" [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                                    <p [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                        1 In the beginning God created the heavens
                                        and the earth. 2And the earth was waste
                                        and void; and darkness was upon the face
                                        of the deep: and the Spirit of God moved
                                        upon the face of the waters. 3And God
                                        said, Let there be light: and there was
                                        light. And God saw the light, that it was
                                        good: and God divided the light from the
                                        darkness. sAnd God called the light Day,
                                        and the darkness he called Night. And
                                        there was evening and there was morning,
                                        one day.
                                        6And God said, Let there be a firmament
                                        in the midst of the waters, and let it
                                        divide the waters from the waters. 7And
                                        God made the firmament, and divided the
                                        waters which were under the firmament
                                        from the waters which were above the
                                        firmament: and it was so. &And God called
                                        the firmament Heaven. evening and there was morning, a second
                                        day.
                                        And God said, Let the waters under the
                                        heavens be gathered together unto one
                                        place, and let the dry land appear: and it
                                        was so. 10 And God called the dry land
                                        Earth; and the gathering together of the
                                        waters called he Seas: and God saw that
                                        it was good. 11 And God said, Let the
                                        earth put forth grass, herbs yielding seed,
                                        and fruit-trees bearing fruit after their
                                        kind, wherein is the seed thereof, upon
                                        the earth: and it was so. 12 And the earth
                                        brought forth grass, herbs yielding seed
                                        after their kind, and trees bearing fruit,
                                        wherein is the seed thereof, after their
                                        kind: and God saw that it was good. 13 And
                                        there was evening and there was morning,
                                        a third day.

                                        14 And God said, Let there be lights in the
                                        firmament of heaven to divide the day from
                                        the night; and let them be for signs, and
                                        for seasons, and for days and years: 15 and
                                        let them be for lights in the firmament of
                                        heaven to give light upon the earth: and
                                        it was so. 16 And God made the two great
                                        lights; the greater light to rule the day, and
                                        the lesser light to rule the night: he made
                                        the stars also. 17 And God set them in the
                                        firmament of heaven to give light upon the
                                        earth, 18 and to rule over the day and over
                                        the night, and to divide the light from the
                                        darkness: and God saw that it was good.
                                        19 And there was evening and there was
                                        morning, a fourth day.
                                        20 And God said, Let the waters swarm
                                        with swarms of living creatures, and
                                        let birds fly above the earth in the open
                                        firmament of heaven.

                                        21 And God created
                                        the great sea-monsters, and every living
                                        creature that
                                        moveth, wherewith the
                                        waters swarmed, after their kind, and every
                                        winged bird after its kind: and God saw
                                        that it was good. 22 And God blessed them,
                                        saying, Be fruitful, and multiply, and fill the
                                        waters in the seas, and let birds multiply
                                        on the earth. 23 And there was evening and
                                        there was morning, a fifth day.
                                        24 And God said, Let the earth bring forth
                                        living creatures after their kind, cattle, and
                                        creeping things, and beasts of the earth
                                        after their kind: and it was SO. 25 And God
                                        made the beasts of the earth after their
                                        kind, and the cattle after their kind, and
                                        everything that creepeth upon the ground
                                        after its kind: and God saw that it was
                                        good, 26 And God said, Let us make man in
                                        our image, after our likeness: and let them
                                        have dominion over the fish of the sea,

                                        have dominion over the fish of the sea,
                                        and over the birds of the heavens, and over
                                        the cattle, and over all the earth, and over
                                        every creeping thing that creepeth upon
                                        the earth. 27 And God created man in his
                                        own image, in the image of God created
                                        he him; male and female created he them.
                                        28 And God blessed them: and God said
                                        unto them, Be fruitful, and multiply, and
                                        replenish the earth, and subdue it; and
                                        have dominion over the fish of the sea,
                                        and over the birds of the heavens, and over
                                        every living thing that moveth upon the
                                        earth. 29 And God said, Behold, I have given
                                        you every herb yielding seed, which is upon
                                        the face of all the earth, and every tree, in
                                        which is the fruit of a tree yielding seed;
                                        to you it shall be for food: 30 and to every
                                        beast of the earth, and to every bird of the
                                        heavens, and to everything that creepeth
                                        upon the earth, wherein there is life, I have

                                        I have
                                        given every green herb for food: and it was
                                        SO. 31 And God saw everything that he had
                                        made, and, behold, it was very good. And
                                        there was evening and there was morning,
                                        the sixth day.
                                    </p>
                                </div>

                                <div class="bible-btn-wrapper"
                                    [ngClass]="[theme === 'light' ? 'light-wrapper' : 'dark-wrapper']">
                                    <div class="bible-btn">
                                        <div class="btn-actions">Gensis 1 <span><img
                                                    src="assets/images/common/down-arrow-small.svg" alt=""></span></div>
                                        <div class="btn-actions"><span style="margin: 0 3px;"><i class="fa fa-calendar"
                                                    aria-hidden="true"></i></span> Plan</div>
                                        <div class="btn-actions">ASV <span><img
                                                    src="assets/images/common/down-arrow-small.svg" alt=""></span></div>
                                    </div>
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'EVENTS'"
                                [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                                <div class="main-event">
                                    <div class="event-header">
                                        <div class="headerColor" [ngClass]="[theme === 'light' ? '' : 'darkBorder']"
                                            [ngStyle]="{'background-color': bannerColor ? bannerColor : '#e6e6e6'}">
                                            <img class="bg-img" style="width: 100%;height: 100%;filter: blur(3px);"
                                                [src]="backgroundEventBanner" alt="">
                                            <div class="showDarkDiv"
                                                style="width: 100%;height: 100%;opacity: 0.3;background-color: #000000;position: absolute;top: 0;">
                                            </div>
                                        </div>
                                        <!-- <div class="headerColor" [ngClass]="[theme === 'light' ? '' : 'darkBorder']"
                                            [ngStyle]="{'background-color': bannerColor ? bannerColor : ''}"></div> -->
                                        <div class="headerImg">
                                            <img [src]="eventBanner" alt="">
                                        </div>
                                    </div>
                                    <div class="event-list-wrapper">
                                        <div class="item-wrapper"
                                            [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '', itemLayout === 'STACKED' ? 'w100' : '' || itemLayout === 'ROWS' ? 'rowLayout' : '', overlayText === true ? 'overlayWrapper' : '', theme === 'light' ? 'light' : 'dark' ]"
                                            *ngFor="let item of contents">
                                            <!-- <div class="item-container"> -->
                                            <div class="item-image"
                                                [ngClass]="[itemLayout === 'ROWS' && imageType === 'SQUARE' ? 'wrow' : '', imageType === 'WIDE' && itemLayout === 'ROWS' ? 'rowWide' : '', !showImage && !showDate ? 'hideContainer' : '']">
                                                <span *ngIf="showImage" class="user-img"
                                                    [ngClass]="[itemLayout === 'GRID' && imageType === 'SQUARE' ? 'gridSquareImg' : '', itemLayout === 'GRID' && imageType === 'WIDE' ? 'gridWideImg' : '', itemLayout === 'STACKED' && imageType === 'WIDE' ? 'stackWide' : '', itemLayout === 'STACKED' && imageType === 'BANNER' ? 'stackBannerImg' : '', itemLayout === 'ROWS' && imageType === 'SQUARE' ? 'rowSquareImg' : '' , itemLayout === 'ROWS' && imageType === 'WIDE' ? 'rowWideImg' : '']"
                                                    [ngStyle]="{'background-color': item.Imgcolor}">
                                                    <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                                        [src]="item.newimg" onload="style.opacity = 1"
                                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                                    <div class="dateOverImg" *ngIf="showImage">
                                                        <div class="date dateMonth">{{item.startedDate ?
                                                            (item.startedDate | date: 'MMM') : ''}}
                                                        </div>
                                                        <div class="date dateDate">{{item.startedDate ?
                                                            (item.startedDate | date: 'dd') : ''}}
                                                        </div>
                                                    </div>
                                                </span>
                                                <div class="date-wrapper" *ngIf="showDate">
                                                    <div class="date dateMonth">{{item.startedDate ? (item.startedDate |
                                                        date: 'MMM') : ''}}</div>
                                                    <div class="date dateDate">{{item.startedDate ? (item.startedDate |
                                                        date: 'dd') : ''}}</div>
                                                    <div class="date dateYear">{{item.startedDate ? (item.startedDate |
                                                        date: 'YYYY') : ''}}</div>
                                                </div>
                                            </div>
                                            <div class="text-wrapper"
                                                [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && imageType === 'SQUARE' ? 'w70' : '' , imageType === 'WIDE' && itemLayout === 'ROWS'  ? 'w60' : '' , !showImage ? 'onlyText' : '' ]"
                                                *ngIf="showTitle">
                                                <div class="item-title" style="line-height: inherit !important;"
                                                    [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">
                                                    {{item.title}}</div>
                                                <div class="item-subtitle">{{item.subTitle ? item.subTitle : ''}}</div>
                                                <div class="date-row">
                                                    <span class="date dateMonth">{{item.startedDate ? (item.startedDate
                                                        | date: 'MMM dd') : ''}},
                                                    </span>
                                                    <span class="date dateDate"
                                                        *ngIf="item.startTime.includes('-')">{{item.startTime ?
                                                        (item.startTime |
                                                        date: 'h:mm a') : ''}} -
                                                    </span>
                                                    <span *ngIf="item.isEndVisible"
                                                        class="date dateMonth">{{item.endedDate ? (item.endedDate |
                                                        date: 'MMM dd') : ''}},
                                                    </span>
                                                    <span class="date dateYear"
                                                        *ngIf="item.startTime.includes('-')">{{item.endTime ?
                                                        (item.endTime | date:
                                                        'h:mm a') : ''}}</span>
                                                </div>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'EBOOK'"
                                [ngClass]="[theme === 'light' ? 'light' : 'dark']">
                                <div class="main-ebook">
                                    <div class="ebook-list-wrapper">
                                        <div class="item-wrapper" *ngFor="let item of ebookData">
                                            <div class="item-image">
                                                <span class="ebook-img" [ngStyle]="{'background-color': item.Imgcolor}">
                                                    <img [src]="item.newimg" onload="style.opacity = 1"
                                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'CHANNEL'"
                                [ngClass]="[theme === 'light' ? 'dark' : 'dark']">
                                <div class="single-player">
                                    <div class="video-player">
                                        <app-video-player
                                                [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: false, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                        </app-video-player>
                                    </div>
                                </div>
                            </section>

                            <section class="main-body" *ngIf="tabType === 'NETWORK'"
                                [ngClass]="[networkType === 'M3U' ? 'light' : 'dark']">
                                <div class="network-wrapper" *ngIf="networkType === 'M3U'">
                                    <div class="search">
                                        <input type="text" placeholder="Search">
                                    </div>
                                    <div class="m3u-network-wrapper position-relative">
                                        <div class="main-description-player-wrapper overflow-x-wrapper">
                                            <!-- <div class="container"> -->
                                                <div class="col-12">
                                                    <div class="m3u-data" *ngFor="let item of m3uData">
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <h1 class="cagtagory-name">{{item.Category}}</h1>
                                                            <p class="viewAll" *ngIf="item.Playlist.length>4">View all</p>
                                                            <!-- <p class="viewAll" *ngIf="showViewAll" (click)="getMediaSeriesData(mediaSeriesId);showViewAll=false;showSpinner=true;selectedM3uCategory=null">Go Back</p> -->
                                                        </div>
                                                        <div class="playlistdata-wrapper">
                                                            <div class="playlistdata" *ngFor="let data of item.Playlist">
                                                                <div class="d-flex flex-column">
                                                                    <div class="card" [ngClass]="{'selected-item': data.isSelected}" (click)="selectM3UItem(data)">
                                                                        <img class="item-logo" style="opacity:0" onload="style.opacity=1" [src]="data.tvgLogo" alt="" (error)="handleMissingImage($event)" >
                                                                    </div>
                                                                    <p class="data-name" [title]="data.tvgName">{{data.tvgName}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="no-data" *ngIf="showNotFound">
                                                        <p>No result found</p>
                                                    </div> -->
                                                </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-network" *ngIf="networkType === 'CUSTOM'">
                                    <div class="video-player">
                                        <app-video-player
                                                [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: false, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                        </app-video-player>
                                    </div>
                                    <div class="logo" style="width: 55px;padding-left: 10px;padding-bottom: 5px;padding-top: 10px;">
                                        <img style="opacity: 0;width: 100%;" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                    </div>
                                    <p class="channel-name" *ngIf="currentPlayingItemName">{{currentPlayingItemName}}</p>
                                    <section class="main-catagory">
                                        <div class="catagory-wrapper">
                                            <ul>
                                                <li  *ngFor="let item of categoryData">{{item.categoryName}}</li>
                                            </ul>
                                        </div>
                                    </section>
                                    <section class="main-playlist-timeline">
                                        <div>
                                            <div class="d-flex flex-column">
                                                <div class=" d-flex time-wrapper" #scrollTwo>
                                                    <p class="day-time">{{currentDay}}, {{currentDate | date:'hh:mm a'}}</p>
                                                    <div class="d-flex all-time" *ngFor="let item of allDayDefault">
                                                        <p>{{item.start | date: 'hh:mm a'}}</p>
                                                        
                                                    </div>
                                                    <div class="ruler-wrapper" #scrollthree>
                                                        <span class="ruler-horizontal" [style.backgroundColor]="brandColor ? brandColor : '#fff'" [ngStyle]="{'width': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.16666667) + 125) + 'px') : '0'}"></span>
                                                        <span class="triangle-down" [style.borderTop]="brandColor ? ('19px solid ' + brandColor) : '#fff'" [title]="currentDate | date:'hh:mm:ss a'" [ngStyle]="{'left': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.16666667) + 105) + 'px') : '0'}"></span>
                                                        
                                                    </div>
                                                </div>
                                                <div class="playlist-wrapper" (scroll)="updateScroll()" #scrollOne>
                                                    <div class="d-flex">
                                                        <div>
                                                            <div *ngFor="let item of customNetworkData" >
                                                                <div class="channel-wrapper d-flex" *ngFor="let data of item.Playlist" (click)="selectChannel(data)" [ngClass]="{'activeChannel': data.isSelected}" >
                                                                    <p class="d-flex justify-content-between">
                                                                        <span style="width: 40%;padding-left: 10px;" class="d-flex">
                                                                            {{data.channelNumber}}
                                                                        </span>
                                                                        <span class="d-flex justify-content-center" style="width: 60%;">
                                                                            <img [src]="data.logoPath"
                                                                                style="width:auto;height:40px;margin-left:15px;object-fit: contain;max-width: 50px;" alt="">
                                                                        </span>
                                                                    </p>
                                                                    <div class="all-playlist-wrapper d-flex" *ngFor="let item of data.schedulingChannelInfoDTO">
                                                                        <div class="playlist-data" [ngStyle]="{'width': item.duration ? ((item.duration * 0.16666667) + 'px') : '0'}" [title]="item.name"><span>{{item.name}}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="no-data" *ngIf="customNetworkData.length === 0">No data exist</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </section>
                                </div>
                            </section>

                            <section class="footer-tabs">
                                <ul class="tabs" [ngClass]="[theme === 'light' ? '' : 'darkTabs']">
                                    <li *ngFor="let item of allActiveTabs" (click)="getTab(item)">
                                        <!-- <div class="icons">{{item.icon}}</div>\ -->
                                        <!-- <img [src]="item.newimg" alt=""> -->
                                        <!-- <em [appSvgIcon]="item.newimg" ></em> -->
                                        <div class="icons" [attr.stroke]="getColor(item)"
                                            [style.stroke]="getColor(item)" [attr.fill]="getColor(item)"
                                            [style.fill]="getColor(item)" [ngClass]="{activeTab: item.active}"
                                            [innerHTML]="item.newimg | safe"></div>
                                        <div class="tab-title"
                                            [ngStyle]="{color: item.active && theme === 'light' ? '#000': item.active && theme === 'dark' ? '#fff' : '#727070'}">
                                            {{item.title}}</div>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>

                <div class="main-tablet" *ngIf="showtablet">
                    <img class="platform-image" src="assets/images/web/Tab 3.svg" alt="">

                    <div class="mobile-background">
                        <div class="main-container">
                            <!-- <div class="header">
                             <div class="menu-icon">
                                 <span class="hamburger-icon" (click)="openSidebar()">
                                     <em [appSvgIcon]="'hamburger'"></em>
                                 </span>
                             </div>
                             <div class="tab-name">Welcome</div>
                         </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>