import { MessageService } from 'primeng/api';
import { AuthenticateService } from './../../../services/authenticateService/authenticate.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  username: string;
  @ViewChild('forgotForm') forgotForm: any;
  constructor(
    public authenticateService: AuthenticateService,
    public messageService: MessageService,
    public router : Router
  ) { }

  ngOnInit(): void {
  }

  /**
   * To send forget password link
   */
  forgetPassword() {
    const dataToSend = {};
    dataToSend['username'] = this.username
    this.authenticateService.forgetPassword(dataToSend).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password reset link successfully send to your mail' });
      this.forgotForm.reset();
      localStorage.clear();
      this.router.navigate(['/login']);
    }, (error) => {
      let expiredTokenExist;
      if (error.error && Array.isArray(error.error.errors)) {
        expiredTokenExist = error.error.errors.some((el) => el.fieldCode === 'Expired');
      }
      this.messageService.add({ severity: 'error', summary: 'Error', detail: expiredTokenExist ? 'Token Expired' : error.error.message });
    });
  }

}
