import { Injectable } from "@angular/core";
import { appConfig } from "src/app/app.config";
import { ResourceService } from "../resource/resource.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionServiceService {
  constructor(public resourceService: ResourceService) { }

  createSubscription(data) {
    const createSubscriptionURL = `${appConfig.subscription}`;
    return this.resourceService.postData(createSubscriptionURL, data);
  }

  getSubscriptionData(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";

    const getSubscriptionDataURL = `${appConfig.subscription}?page=${page}&size=${size}${filter}`;
    return this.resourceService.getData(getSubscriptionDataURL);
  }

  getSubscriptionDataList(
    page,
    size,
    search,
    endDate,
    startDate,
    isDefault,
    isSelected
  ) {
    // const item = (isSelected ? `&itemType=${itemType}` : `?itemType=${itemType}`)

    const selected = isSelected ? `&isSelected=${isSelected}` : "";
    const end = endDate ? `?endDate=${endDate}` : "";
    const filter = search ? `&searchkey=${search}` : "";
    const start = startDate ? `&startDate=${startDate}` : "";
    const DefaultData = endDate
      ? `&isDefault=${isDefault}`
      : `?isDefault=${isDefault}`;
    // isDefault ? `isDefault=${isDefault}` : ''
    // ${ DefaultData }
    const getSubsListURL = `${appConfig.subscription}${end}${selected}&page=${page}${filter}&size=${size}${start}`;
    return this.resourceService.getData(getSubsListURL);
  }

  getSubscriptionList(id) {
    const getSubscriptionListURL = `${appConfig.subscription}/subscriptionPlanList?organizationId=${id}`;
    return this.resourceService.getData(getSubscriptionListURL);
  }

  getSubscriptionById(id) {
    const getSubscriptionByIdURL = `${appConfig.subscription}/${id}`;
    return this.resourceService.getData(getSubscriptionByIdURL);
  }

  updateSubscription(id, data) {
    const updateSubscriptionURL = `${appConfig.subscription}/${id}`;
    return this.resourceService.putData(updateSubscriptionURL, data);
  }

  deleteSubscription(id) {
    const deleteSubscriptionURL = `${appConfig.subscription}/${id}`;
    return this.resourceService.deleteData(deleteSubscriptionURL);
  }

  getAllAssociatedOrganizations(id, page, size) {
    const getOneTimeSubscribedUserDataURL = `${appConfig.subscription}/${id}/associatedOrganizations?page=${page}&size=${size}`;
    return this.resourceService.getData(getOneTimeSubscribedUserDataURL);
  }

  duplicateSubscription(id) {
    const duplicateSubscriptionURL = `${appConfig.subscription}/${id}`;
    return this.resourceService.postData(duplicateSubscriptionURL);
  }

  getSubscribedUserList(page, size, search) {
    const filter = search ? `&searchKey=${search}` : "";
    const getSubscribedUserDataURL = `${appConfig.subscription}/getSubscribedUsers?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getSubscribedUserDataURL);
  }
  // one time paid subscriber
  getOneTimeSubscribedUserList(page, size, search) {
    const filter = search ? `&searchKey=${search}` : "";
    const getOneTimeSubscribedUserDataURL = `${appConfig.subscription}/getAllOneTimeSubscriber?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getOneTimeSubscribedUserDataURL);
  }

  getBothSubscribersList(page, size, search, subscriberType, selectedPlan, startDate, endDate) {
    // selectedPlan ? search = selectedPlan : ''
    const subscriptionId = selectedPlan
      ? `&subscriptionId=${selectedPlan}`
      : "";
    const filter = search ? `&searchKey=${search}` : "";
    if (startDate) {
      var getSubscribedUserDataURL = `${appConfig.subscription}/subscribers?endDate=${endDate}&page=${page}${filter}&size=${size}&startDate=${startDate}&subscriberType=${subscriberType}${subscriptionId}`;
    }
    else {
      var getSubscribedUserDataURL = `${appConfig.subscription}/subscribers?page=${page}${filter}&size=${size}&subscriberType=${subscriberType}${subscriptionId}`;
    }
    return this.resourceService.getData(getSubscribedUserDataURL);
  }

  getSubscriptionPlanbyid(subscriptionPlanId) {
    const getSubscriptionPlanbyid = `${appConfig.subscriptionPlans}/${subscriptionPlanId}`;
    return this.resourceService.getData(getSubscriptionPlanbyid);
  }

  updateSubscriptionPlan(data) {
    // const planName = (plan === null || plan === '') ? "" : `?subscriptionPlanName=${plan}`
    // const amo = (amount === 0 || amount === null) ? "" : `&price=${amount}`
    // const trialDay = ( trialDays === null || trialDays === '') ? "" : `&trialDays=${trialDays}`
    const updateSubscriptionPlan = `${appConfig.subscriptionPlans}`;
    return this.resourceService.putData(updateSubscriptionPlan, data);
  }

  getSubscriptionPlanbylist(page, size, search) {
    const filter = search ? `&searchkey=${search}` : "";
    const Sendpage = page ? `?page=${page}` : ''
    const Sendsize = size ? `&size=${size}` : ''
    const getSubscriptionPlanbylist = `${appConfig.subscriptionPlans}${Sendpage}${filter}${Sendsize}`;
    return this.resourceService.getData(getSubscriptionPlanbylist);
  }

  // get all subscription plan list
  getAllSubscriptionPlanList() {
    const getSubscriptionPlanbylist = `${appConfig.subscriptionPlans}/allFeaturesList`;
    return this.resourceService.getData(getSubscriptionPlanbylist);
  }

  getTotalNumberOfPlans() {
    const getTotalPlansUrl = `${appConfig.subscriptionPlans}`;
    return this.resourceService.getData(getTotalPlansUrl);
  }

  // get All Plans list
  getAllPlansList() {
    const getAllSubscriptionPlanbylist = `${appConfig.subscription}/superAdminSubscriptionPlan`;
    return this.resourceService.getData(getAllSubscriptionPlanbylist);
  }

  updateSubscriptionTemplate(id, templateId) {
    const updateSubscriptionURL = `${appConfig.subscription}/updateTemplateInSubscriptionPlan?id=${id}&templateId=${templateId}`;
    return this.resourceService.putData(updateSubscriptionURL);
  }

  getVastDetail(id) {
    const getSubscriptionListURL = `${appConfig.subscription}/vastTagEnabled/${id}`;
    return this.resourceService.getData(getSubscriptionListURL);
  }

}
