import { LoginAuthGuard } from './../../auth-guards/login-auth.guard';
import { ForgetPasswordComponent } from './../../components/authenticate/forget-password/forget-password.component';
import { SignupComponent } from './../../components/authenticate/signup/signup.component';
import { LoginComponent } from './../../components/authenticate/login/login.component';
import { ResetPasswordComponent } from './../../components/authenticate/reset-password/reset-password.component';
import { Routes } from '@angular/router';
import { PlatformsComponent } from 'src/app/components/authenticate/platforms/platforms.component';
import { SignupOrgComponent } from 'src/app/components/authenticate/signup-org/signup-org.component';
import { TvPreviewComponent } from 'src/app/components/authenticate/tv-preview/tv-preview.component';
import { EmailVerificationComponent } from 'src/app/components/authenticate/email-verification/email-verification.component';
import { UpgradeSubscriptionPlanComponent } from 'src/app/components/authenticate/upgrade-subscription-plan/upgrade-subscription-plan.component';

export const AuthenticateRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginAuthGuard]
    },
    {
        path: 'signup/:uuid',
        component: SignupComponent,
    },
    {
        path: 'platforms',
        component: PlatformsComponent,
    },
    {
        path: 'tv-app-preview',
        component: TvPreviewComponent,
    },
    {
        path: 'forget-password',
        component: ForgetPasswordComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'set-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'organizationSignup',
        component: SignupOrgComponent,
    },
    {
        path: 'organizationSignup/:id',
        component: SignupOrgComponent,
    },
    {
        path: 'email-verification',
        component: EmailVerificationComponent,
     },
    {
        path: 'data-usage-email',
        component: UpgradeSubscriptionPlanComponent,
    }
];
