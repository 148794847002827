import { MessageService } from "primeng/api";
import { DashboardService } from "./../../services/dashboard/dashboard.service";
import { SharedService } from "./../../services/shared/shared.service";
import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import * as moment from "moment";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import am4themes_moonrisekingdom from "@amcharts/amcharts4/themes/moonrisekingdom";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { appConfig } from "src/app/app.config";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "src/app/services/security/security.service";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";
// import { ChartConfiguration, ChartOptions, ChartType } from "chart.js";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, Label } from "ng2-charts";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_usaLow from "@amcharts/amcharts5-geodata/usaLow";
import * as am4maps from "@amcharts/amcharts4/maps";
import am5geodata_region_usa_congressional2022_worldLow from "@amcharts/amcharts5-geodata/region/usa/congressional2022/flLow";
import { FeatureControlService } from "src/app/services/feature-control.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  name = "Angular";
  private chart: am4charts.XYChart;

  private chart1: am4charts.XYChart;

  private chart2: am4charts.XYChart;

  all_data: any;
  allRevenue: any;
  transactionalSummary_data: any[] = [];
  transactionalSummaryType = "ALL";
  showAllTransaction = true;
  showUserTransaction = false;
  showGivingTransaction = false;
  showOneTimeTransaction = false;
  showAllbarGraph = false;
  showTvGraph = false;
  showMobileGraph = false;
  summaryData: any;
  playCountData: any;
  unsubscribeCountnumber: number;
  subscribedData: any;
  appLaunchesData: any;
  appImpressionsData: any;
  userRoles: any[] = [];
  trendinglist: any[] = [];
  playCountList: any[] = [];
  expenseList: any[] = [];
  revenueList: any[] = [];
  securityGroupPermission: any[] = [];
  customers = false;
  emailTemp = false;
  subscription = false;
  onBoardinguser = false;
  securityGroup = false;
  uploadStock = false;
  totalExpense = 0;
  totalRevenue = 0;
  combined: any[] = [];
  promotionImage: any;
  mediaPlayCount: string;
  pastDaysCount: string;
  showPromotion: boolean;
  documentId: any;
  summaryChartOptions = {
    legend: { display: false },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 3000,
      easing: "easeOutSine",
    },
  };
  year = "";
  month = "";
  subscriptonData = new Subscription();
  speaker: string;
  title: string;
  date: string;
  imagePath: string;
  mostRecentItemId: number;
  appDownloadsData: any;
  AllappDownloadsData: any;
  onlytvappDownloadsData: any;
  onlymobileappDownloadsData: any;
  tv = 0;
  web = 0;
  ios = 0;
  android = 0;

  featureList: any[] = [];
  profitable: any;
  showGiving: boolean;
  showGivingCard = false;
  // new
  mobileios = 0;
  mobileandroid = 0;
  tvandroid = 0;
  roku = 0;
  website = 0;
  tvios = 0;
  //
  lineStylesData: any;
  basicData: any;
  appDownloadsArray: any[] = [];
  androidDownload: number;
  iosDownload: number;
  tvDownload: number;
  currentDate = Date.now();
  promotionalArtworkId: any;
  promotionalLink: any;
  firstDateMonth: any;
  firstMonthArray: any[] = [];
  firstMonthDataArray: any[] = [];
  androidFirstMonth = 0;
  iosFirstMonth = 0;
  tvFirstMonth = 0;
  rokuFirstMonth = 0;
  webFirstMonth = 0;
  tvIosFirstMonth = 0;
  tvAmazonFirstMonth = 0;
  googleFirstMonth = 0;
  secondDateMonth: any;
  secondMonthArray: any[] = [];
  secondMonthDataArray: any[] = [];
  androidSecondMonth = 0;
  iosSecondMonth = 0;
  tvSecondMonth = 0;
  tvIosSecondMonth = 0;
  tvAmazonSecondMonth = 0;
  googleSecondMonth = 0;
  rokuSecondMonth = 0;
  webSecondMonth = 0;
  thirdDateMonth: any;
  thirdMonthArray: any[] = [];
  thirdMonthDataArray: any[] = [];
  androidThirdMonth = 0;
  iosThirdMonth = 0;
  rokuThirdMonth = 0;
  tvThirdMonth = 0;
  tvIosThirdMonth = 0;
  tvAmazonThirdMonth = 0;
  googleThirdMonth = 0;
  webThirdMonth = 0;
  fourthDateMonth: any;
  fourthMonthArray: any[] = [];
  fourthMonthDataArray: any[] = [];
  androidFourthMonth = 0;
  currentUrl: any;
  freeCountsCount: any;
  iosFourthMonth = 0;
  tvFourthMonth = 0;
  tvIosFourthMonth = 0;
  tvAmazonFourthMonth = 0;
  googleFourthMonth = 0;
  webFourthMonth = 0;
  rokuFourthMonth = 0;
  googleFifthMonth = 0;
  fifthDateMonth: any;
  fifthMonthArray: any[] = [];
  fifthMonthDataArray: any[] = [];
  androidFifthMonth = 0;
  iosFifthMonth = 0;
  tvFifthMonth = 0;
  tvIosFifthMonth = 0;
  tvAmazonFifthMonth = 0;
  rokuFifthMonth = 0;
  webFifthMonth = 0;
  sixthDateMonth: any;
  sixthMonthArray: any[] = [];
  sixthMonthDataArray: any[] = [];
  seventhMonthDataArray: any[] = [];
  eighthMonthDataArray: any[] = [];
  androidSixthMonth = 0;
  iosSixthMonth = 0;
  tvIosSixthMonth = 0;
  tvAmazonSixthMonth = 0;
  tvSixthMonth = 0;
  webSixthMonth = 0;
  rokuSixthMonth = 0;
  googleSixthMonth = 0;
  appLaunchesArray: any[] = [];
  appLaunchesFirstDateMonth: any;
  appLaunchesFirstMonthArray: any[] = [];
  appLaunchesFirstMonthDataArray: any[] = [];
  appLaunchesAndroidFirstMonth = 0;
  appLaunchesIosFirstMonth = 0;
  appLaunchesTvFirstMonth = 0;
  appLaunchestvIosFirstMonth = 0;
  appLaunchestvAmazonFirstMonth = 0;
  appLaunchestvIosSecondMonth = 0;
  appLaunchestvAmazonSecondMonth = 0;
  appLaunchestvIosThirdMonth = 0;
  appLaunchestvAmazonThirdMonth = 0;
  appLaunchestvIosFourthMonth = 0;
  appLaunchestvAmazonFourthMonth = 0;
  appLaunchestvIosFifthMonth = 0;
  appLaunchestvAmazonFifthMonth = 0;
  appLaunchestvIosSixthMonth = 0;
  appLaunchestvAmazonSixthMonth = 0;
  appLaunchesWebFirstMonth = 0;
  appLaunchesRokuFirstMonth = 0;
  appLaunchesGoogleFirstMonth = 0;
  appLaunchesGoogleSecondMonth = 0;
  appLaunchesGoogleThirdMonth = 0;
  appLaunchesGoogleFourthMonth = 0;
  appLaunchesGoogleFifthMonth = 0;
  appLaunchesGoogleSixthMonth = 0;
  appLaunchesSecondDateMonth: any;
  appLaunchesRokuSecondMonth = 0;
  appLaunchesSecondMonthArray: any[] = [];
  appLaunchesSecondMonthDataArray: any[] = [];
  appLaunchesAndroidSecondMonth = 0;
  appLaunchesIosSecondMonth = 0;
  appLaunchesTvSecondMonth = 0;
  appLaunchesWebSecondMonth = 0;
  appLaunchesThirdDateMonth: any;
  appLaunchesThirdMonthArray: any[] = [];
  appLaunchesThirdMonthDataArray: any[] = [];
  appLaunchesAndroidThirdMonth = 0;
  appLaunchesIosThirdMonth = 0;
  appLaunchesRokuThirdMonth = 0;
  appLaunchesTvThirdMonth = 0;
  appLaunchesWebThirdMonth = 0;
  appLaunchesFourthDateMonth: any;
  appLaunchesFourthMonthArray: any[] = [];
  appLaunchesFourthMonthDataArray: any[] = [];
  appLaunchesAndroidFourthMonth = 0;
  appLaunchesIosFourthMonth = 0;
  appLaunchesRokuFourthMonth = 0;
  appLaunchesTvFourthMonth = 0;
  appLaunchesWebFourthMonth = 0;
  appLaunchesFifthDateMonth: any;
  appLaunchesFifthMonthArray: any[] = [];
  appLaunchesFifthMonthDataArray: any[] = [];
  appLaunchesAndroidFifthMonth = 0;
  appLaunchesIosFifthMonth = 0;
  appLaunchesTvFifthMonth = 0;
  appLaunchesRokuFifthMonth = 0;
  appLaunchesWebFifthMonth = 0;
  appLaunchesSixthDateMonth: any;
  appLaunchesSixthMonthArray: any[] = [];
  appLaunchesSixthMonthDataArray: any[] = [];
  appLaunchesSeventhMonthDataArray: any[] = [];
  appLaunchesEightMonthDataArray: any[] = [];

  appLaunchesAndroidSixthMonth = 0;
  appLaunchesIosSixthMonth = 0;
  appLaunchesTvSixthMonth = 0;
  appLaunchesRokuSixthMonth = 0;
  appLaunchesWebSixthMonth = 0;
  isViewedDate: any;
  appLaunches = 0;
  appImpressions = 0;
  appDownloads = 0;

  isPaymentDone = true;
  activeCard = true;
  role: string;
  appImpArr: any[] = [];
  appDownArr: any[] = [];
  allAppDownloadData: any[] = [];
  appLaunchArr: any[] = [];
  promotionaImageBgColor: any;

  totalOthers = 0;
  subscribeCount = 0;
  unSubscribeCount = 0;

  permissionArray: any[] = [];
  expense = true;
  sumarry = true;
  revenue = true;
  freeSubscriberCount: any;
  paidsubscriberCounts: any;
  trialSubscriberCount: any;
  mediaPlayTotalCount: any;

  // static dashboard data
  multiAxisData: any;
  multiAxisOptions: any;
  basicData2: any;
  basicOptions2: any;
  showLoader = false;

  numberCounter: number = 0;
  numberPercent: number = 0;
  amountCounter: number = 0;
  counterStops: any;
  counterStops2: any;
  counterStops3: any;
  options: any;
  startDate = "";
  endDate = "";
  isDefault: boolean;
  page: number = 1;
  searchInput = "";
  activePaidSubscribers: number = 0;
  currentMRR: number = 0;
  totalTrial: number = 0;
  activePaidSubscribersIntervels: any;
  Total_ContactsIntervels: any;
  Total_Contacts: number = 0;
  Monthly_Lead_CountIntervels: any;
  Monthly_Lead_Count: number = 0;
  Monthly_Giving_AmountIntervels: any;
  Monthly_Giving_Amount: number = 0;
  currentMRRIntervels: any;
  totalTrialIntervels: any;
  stackedData: any;
  stackedOptions: any;
  showworldMap = true;
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  lineChartOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 8000,
      easing: "easeInOutBounce",
    },
    // animation: {
    //     duration: 8000,
    //     animateScale: true,
    //   animateRotate: true,
    //     easing: 'easeInOutBounce',

    //     from: 1,
    //     to: 100,
    //     loop: true

    // },
  };
  lineChartColors: Color[] = [];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = "line";
  tvAppGoogleTV: boolean;
  @ViewChild("myBarCanvas") canvas: ElementRef;
  @ViewChild("myBarCanvas2") canvas2: ElementRef;
  @ViewChild("myBarCanvas3") canvas3: ElementRef;
  @ViewChild("doughnutChart") doughnutChart: ElementRef;
  barChartData: ChartDataSets[] = [];
  barChartLabels: Label[] = [];
  barChartOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 2000,
      easing: "easeInOutBounce",
    },
  };
  barChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: "#a7e0f9",
    },
  ];
  barChartLegend = true;
  barChartPlugins = [];
  barChartType = "bar";
  emptyData = false;
  tvAppAndroidTV: boolean;
  tvAppIOSTV: boolean;
  tvAppRoku: boolean;
  tvAppAmazonTV: boolean;
  mobileAppAndroid: boolean;
  mobileAppIos: boolean;
  constructor(
    public sharedService: SharedService,
    public dashboardService: DashboardService,
    public messageService: MessageService,
    private zone: NgZone,
    public router: Router,
    public securityService: SecurityService,
    private subscriptionService: SubscriptionServiceService,
    private _ActivatedRoute: ActivatedRoute,
    public featureControlService: FeatureControlService
  ) {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDays = new Date(y, m, 1);
    var lastDays = new Date(y, m + 1, 0);
    var firsts = moment(firstDays).format("YYYY-MM-DD");
    var lasts = moment(lastDays).format("YYYY-MM-DD");
    this.startDate = firsts;
    this.endDate = lasts;
    this.isDefault = true;
    this.page = 1;
    this.featureControlService.getFeatureList();
  }

  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe((data) => {
      const routeVal = data;
      console.log(routeVal);
    });

    this.basicData2 = {
      labels: [],
      datasets: [
        {
          label: "First Dataset",
          data: [65, 59, 80, 81, 96, 90],
          fill: false,
          borderColor: "#42A5F5",
          backgroundColor: [
            "#a7e0f9",
            "#930101",
            "#6cbe00",
            "#ffb600",
            "#54047c",
            "#2c7be5",
          ],
          tension: 0.4,
        },
      ],
    };

    this.basicOptions2 = {
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      // scales: {
      //     x: {
      //         ticks: {
      //             color: '#495057'
      //         },
      //         grid: {
      //             color: '#ebedef'
      //         }
      //     },
      //     y: {
      //         ticks: {
      //             color: '#495057'
      //         },
      //         grid: {
      //             color: '#ebedef'
      //         }
      //     }
      // }
    };

    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 12,
    };

    // this.getSubscriptionData(1, 10)

    this.isPaymentDone = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
          .isPaymentDone
      : null;
    this.activeCard = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.activeCard
      : null;
    this.role = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.roles[0]
          .name
      : null;
    let orgId = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
          .organization.id
      : null;
    if (
      (this.activeCard === true &&
        this.isPaymentDone === true &&
        this.role !== "ROLE_SUPER_ADMIN") ||
      orgId === 1
    ) {
      this.getSubsciptionData();
    } else {
      this.router.navigate(["/payment-module/payment"]);
    }

    this.year = moment().format("YYYY");
    this.month = moment().format("M");

    if (this.role === "ROLE_SUPER_ADMIN") {
      this.getDashboardData();
    }
    this.profitable = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
          .organization.profit
      : "";
    this.featureList = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.featureList
      : null;

    if (this.featureList) {
      this.featureList.forEach((el) => {
        if (el.name === "Giving") {
          this.showGiving = el.isView;
        }
      });
    }

    if (!this.showGiving || this.profitable === true) {
      this.showGivingCard = false;
    } else {
      this.showGivingCard = true;
    }

    this.stackedData = {
      labels: [
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          type: "bar",
          label: "TV",
          backgroundColor: "#6cbe00",
          data: [50, 25, 12, 48, 90, 76, 20],
        },
        {
          type: "bar",
          label: "Mobile",
          backgroundColor: "#930101",
          data: [21, 84, 24, 75, 37, 65, 40],
        },
      ],
    };

    this.stackedOptions = {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      responsive: true,
      animation: {
        animateScale: true,
        animateRotate: true,
        duration: 2000,
        easing: "easeOutSine",
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    this.basicData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "First Dataset",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "#42A5F5",
          tension: 0.4,
        },
        {
          label: "Second Dataset",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: "#FFA726",
          tension: 0.4,
        },
      ],
    };

    if (this.featureList) {
      this.featureList.forEach((el) => {
        if (el.name === "TV App") {
          console.log("test", el.name);
          // this.showTvApp = el.isView
          const tvAppSubfeatures = el.subFeature;
          if (tvAppSubfeatures && tvAppSubfeatures.length > 0) {
            tvAppSubfeatures.forEach((subfeature) => {
              if (subfeature.name === "Android TV") {
                this.tvAppAndroidTV = subfeature.isView;
                console.log(
                  "check tv app android subfeature---",
                  this.tvAppAndroidTV
                );
              }
              if (subfeature.name === "IOS TV") {
                this.tvAppIOSTV = subfeature.isView;
                console.log("check tv app ios subfeature---", this.tvAppIOSTV);
              }
              if (subfeature.name === "ROKU TV") {
                this.tvAppRoku = subfeature.isView;
                console.log("check tv app roku subfeature---", this.tvAppRoku);
              }
              if (subfeature.name === "Amazon Fire TV") {
                this.tvAppAmazonTV = subfeature.isView;
                console.log(
                  "check tv amazon subfeature---",
                  this.tvAppAmazonTV
                );
              }
              if (subfeature.name === "Google TV") {
                this.tvAppGoogleTV = subfeature.isView;
                console.log(
                  "check tv amazon subfeature---",
                  this.tvAppGoogleTV
                );
              }
            });
          }
        }
        if (el.name === "Mobile App") {
          console.log("test", el.name);
          // this.showTvApp = el.isView
          const mobileAppSubfeatures = el.subFeature;
          if (mobileAppSubfeatures && mobileAppSubfeatures.length > 0) {
            mobileAppSubfeatures.forEach((subfeature) => {
              if (subfeature.name === "Android Mobile App") {
                this.mobileAppAndroid = subfeature.isView;
                console.log(
                  "check tv app android subfeature---",
                  this.mobileAppAndroid
                );
              }
              if (subfeature.name === "IOS Mobile App") {
                this.mobileAppIos = subfeature.isView;
                console.log(
                  "check mobile app ios subfeature---",
                  this.mobileAppIos
                );
              }
            });
          }
        }
      });
    }

    if (
      (this.mobileAppAndroid || this.mobileAppIos) &&
      (this.tvAppAndroidTV || this.tvAppIOSTV || this.tvAppRoku)
    ) {
      this.showAllbarGraph = true;
    } else if (
      !this.mobileAppAndroid &&
      !this.mobileAppIos &&
      (this.tvAppAndroidTV || this.tvAppIOSTV || this.tvAppRoku)
    ) {
      this.showAllbarGraph = false;
      this.showTvGraph = true;
    } else if (
      this.mobileAppAndroid &&
      this.mobileAppIos &&
      !this.tvAppAndroidTV &&
      !this.tvAppIOSTV &&
      !this.tvAppRoku
    ) {
      this.showAllbarGraph = false;
      this.showTvGraph = false;
      this.showMobileGraph = true;
    } else {
      this.showAllbarGraph = false;
    }
    // this.getSubsciptionData();
  }

  getStateMapChart() {
    var root = am5.Root.new("StateMapId");

    root._logo.dispose();
    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoAlbersUsa(),
      })
    );

    // Create polygon series
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_usaLow,
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
    });

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x297373),
    });

    let zoomOut = root.tooltipContainer.children.push(
      am5.Button.new(root, {
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        dx: -20,
        dy: 20,
        themeTags: ["zoom"],
        icon: am5.Graphics.new(root, {
          themeTags: ["button", "icon"],
          strokeOpacity: 0.7,
          draw: function (display) {
            display.moveTo(0, 0);
            display.lineTo(12, 0);
          },
        }),
      })
    );

    // zoomOut.get("background").setAll({
    //   cornerRadiusBL: 40,
    //   cornerRadiusBR: 40,
    //   cornerRadiusTL: 40,
    //   cornerRadiusTR: 40
    // });
    // zoomOut.events.on("click", function() {
    //   if (currentSeries) {
    //     currentSeries.hide();
    //   }
    //   chart.goHome();
    //   zoomOut.hide();
    //   currentSeries = regionalSeries.US.series;
    //   currentSeries.show();
    // });
    // zoomOut.hide();

    // =================================
    // Set up point series
    // =================================

    // Load store data
    // am5.net.load("https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/TargetStores.json").then(function(result) {
    //   let stores = am5.JSONParser.parse(result.response);
    //   setupStores(stores);
    // });

    // let regionalSeries = {};
    // let currentSeries;

    // Parses data and creats map point series for domestic and state-level
    // function setupStores(data) {
    //   console.log(data);

    //   // Init country-level series
    //   regionalSeries.US = {
    //     markerData: [],
    //     series: createSeries("stores")
    //   };

    //   // Set current series
    //   currentSeries = regionalSeries.US.series;

    //   // Process data
    //   am5.array.each(data.query_results, function(store) {

    //     // Get store data
    //     let store = {
    //       state: store.MAIL_ST_PROV_C,
    //       long: am5.type.toNumber(store.LNGTD_I),
    //       lat: am5.type.toNumber(store.LATTD_I),
    //       location: store.co_loc_n,
    //       city: store.mail_city_n,
    //       count: am5.type.toNumber(store.count)
    //     };

    //     // Process state-level data
    //     if (regionalSeries[store.state] == undefined) {
    //       let statePolygon = getPolygon("US-" + store.state);
    //       if (statePolygon) {

    //         let centroid = statePolygon.visualCentroid();

    //         // Add state data
    //         regionalSeries[store.state] = {
    //           target: store.state,
    //           type: "state",
    //           name: statePolygon.dataItem.dataContext.name,
    //           count: store.count,
    //           stores: 1,
    //           state: store.state,
    //           markerData: [],
    //           geometry: {
    //             type: "Point",
    //             coordinates: [centroid.longitude, centroid.latitude]
    //           }
    //         };
    //         regionalSeries.US.markerData.push(regionalSeries[store.state]);

    //       }
    //       else {
    //         // State not found
    //         return;
    //       }
    //     }
    //     else {
    //       regionalSeries[store.state].stores++;
    //       regionalSeries[store.state].count += store.count;
    //     }

    //     // Process city-level data
    //     if (regionalSeries[store.city] == undefined) {
    //       regionalSeries[store.city] = {
    //         target: store.city,
    //         type: "city",
    //         name: store.city,
    //         count: store.count,
    //         stores: 1,
    //         state: store.state,
    //         markerData: [],
    //         geometry: {
    //           type: "Point",
    //           coordinates: [store.long, store.lat]
    //         }
    //       };
    //       regionalSeries[store.state].markerData.push(regionalSeries[store.city]);
    //     }
    //     else {
    //       regionalSeries[store.city].stores++;
    //       regionalSeries[store.city].count += store.count;
    //     }

    //     // Process individual store
    //     regionalSeries[store.city].markerData.push({
    //       name: store.location,
    //       count: store.count,
    //       stores: 1,
    //       state: store.state,
    //       geometry: {
    //         type: "Point",
    //         coordinates: [store.long, store.lat]
    //       }
    //     });

    //   });
    //   console.log(regionalSeries.US.markerData)
    //   regionalSeries.US.series.data.setAll(regionalSeries.US.markerData);
    // }

    // Finds polygon in series by its id
    function getPolygon(id) {
      let found;
      polygonSeries.mapPolygons.each(function (polygon) {
        if (polygon.dataItem.get(id) == id) {
          found = polygon;
        }
      });
      return found;
    }

    // Creates series with heat rules
    function createSeries(heatfield) {
      // Create point series
      let pointSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {
          valueField: heatfield,
          calculateAggregates: true,
        })
      );

      // Add store bullet
      let circleTemplate: am5.Template<am5.Circle> = am5.Template.new({});
      // let circleTemplate = am5.Template.new(root);
      pointSeries.bullets.push(function () {
        let container = am5.Container.new(root, {});

        let circle = container.children.push(
          am5.Circle.new(
            root,
            {
              radius: 10,
              fill: am5.color(0x000000),
              fillOpacity: 0.7,
              cursorOverStyle: "pointer",
              tooltipText: "{name}:\n[bold]{stores} stores[/]",
            },
            circleTemplate
          )
        );

        let label = container.children.push(
          am5.Label.new(root, {
            text: "{stores}",
            fill: am5.color(0xffffff),
            populateText: true,
            centerX: am5.p50,
            centerY: am5.p50,
            textAlign: "center",
          })
        );

        // Set up drill-down
        circle.events.on("click", function (ev) {
          // Determine what we've clicked on
          let data = ev.target.dataItem.dataContext;

          // No id? Individual store - nothing to drill down to further
          // if (!data.target) {
          //   return;
          // }

          // // Create actual series if it hasn't been yet created
          // if (!regionalSeries[data.target].series) {
          //   regionalSeries[data.target].series = createSeries("count");
          //   regionalSeries[data.target].series.data.setAll(data.markerData);
          // }

          // // Hide current series
          // if (currentSeries) {
          //   currentSeries.hide();
          // }

          // Control zoom
          // if (data.type == "state") {
          //   let statePolygon = getPolygon("US-" + data.state);
          //   polygonSeries.zoomToDataItem(statePolygon.dataItem);
          // }
          // else if (data.type == "city") {
          //   chart.zoomToGeoPoint({
          //     latitude: data.geometry.coordinates[1],
          //     longitude: data.geometry.coordinates[0]
          //   }, 64, true);
          // }
          zoomOut.show();

          // Show new targert series
          // currentSeries = regionalSeries[data.target].series;
          // currentSeries.show();
        });

        return am5.Bullet.new(root, {
          sprite: container,
        });
      });

      // Add heat rule for circles
      pointSeries.set("heatRules", [
        {
          target: circleTemplate,
          dataField: "value",
          min: 10,
          max: 30,
          key: "radius",
        },
      ]);

      // Set up drill-down
      // TODO

      return pointSeries;
    }
  }

  getMapApmChart() {
    let data = [
      {
        id: "US",
        name: "United States",
        value: 100,
      },
      {
        id: "GB",
        name: "United Kingdom",
        value: 100,
      },
      {
        id: "CN",
        name: "China",
        value: 100,
      },
      {
        id: "IN",
        name: "India",
        value: 100,
      },
      {
        id: "AU",
        name: "Australia",
        value: 100,
      },
      {
        id: "CA",
        name: "Canada",
        value: 100,
      },
      {
        id: "BR",
        name: "Brasil",
        value: 100,
      },
      {
        id: "ZA",
        name: "South Africa",
        value: 100,
      },
    ];

    let root = am5.Root.new("worldMapId");
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(am5map.MapChart.new(root, {}));

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am4geodata_worldLow,
        exclude: ["AQ"],
      })
    );

    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: "value",
        calculateAggregates: true,
        polygonIdField: "id",
      })
    );

    // let circleTemplate = am5.Template.new({});
    let circleTemplate: am5.Template<am5.Circle> = am5.Template.new({});
    bubbleSeries.bullets.push(function (root, series, dataItem) {
      let container = am5.Container.new(root, {});

      let circle = container.children.push(
        am5.Circle.new(
          root,
          {
            radius: 20,
            fillOpacity: 0.7,
            fill: am5.color(0xff0000),
            cursorOverStyle: "pointer",
            tooltipText: `{name}: [bold]{value}[/]`,
          },
          circleTemplate
        )
      );

      let countryLabel = container.children.push(
        am5.Label.new(root, {
          text: "{name}",
          paddingLeft: 5,
          populateText: true,
          fontWeight: "bold",
          fontSize: 13,
          centerY: am5.p50,
        })
      );

      circle.on("radius", function (radius) {
        countryLabel.set("x", radius);
      });

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true,
      });
    });

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{value.formatNumber('#.')}",
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: "center",
        }),
        dynamic: true,
      });
    });

    // minValue and maxValue must be set for the animations to work
    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        dataField: "value",
        min: 10,
        max: 50,
        minValue: 0,
        maxValue: 100,
        key: "radius",
      },
    ]);

    bubbleSeries.data.setAll(data);

    updateData();
    // setInterval(function() {
    //   updateData();
    // }, 2000)

    function updateData() {
      for (var i = 0; i < bubbleSeries.dataItems.length; i++) {
        bubbleSeries.data.setIndex(i, {
          value: Math.round(Math.random() * 100),
          id: data[i].id,
          name: data[i].name,
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptonData) {
      this.subscriptonData.unsubscribe();
    }

    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });

    this.zone.runOutsideAngular(() => {
      if (this.chart1) {
        this.chart1.dispose();
      }
    });

    this.zone.runOutsideAngular(() => {
      if (this.chart2) {
        this.chart2.dispose();
      }
    });
  }

  /**
   * To get Dashbaord Data
   */
  getDashboardData() {
    this.sharedService.updateLoader(false);
    this.totalExpense = 0;
    // this.dashboardService.getBaseData(this.year, this.month).subscribe((response: any) => {
    this.dashboardService
      .getDashBaseData(this.year, this.month)
      .subscribe((response: any) => {
        // this.expenseList = response[0].data.accountDTOS ? response[0].data.accountDTOS.content : [];
        this.expenseList = response[0].data.accountDTOS
          ? response[0].data.accountDTOS.content
          : [];
        // this.revenueList = response[1].data.accountDTOS ? response[1].data.accountDTOS.content : [];

        // this.revenueList = response[1].data.revenueList ? response[1].data.revenueListObj.content : [];
        this.revenueList = response[1].data.revenueListObj
          ? response[1].data.revenueListObj
          : [];

        // this.revenueList = response.data.revenueList.content ? response.data.revenueList.content : [];
        this.totalRevenue = response[1].data
          ? response[1].data.totalRevenue
          : 0;
        this.totalOthers = response[1].data ? response[1].data.totalOthers : 0;
        // response[1].data.accountDTOS && response[1].data.accountDTOS.content && response[1].data.accountDTOS.content.length > 0 ?
        //   response[1].data.totalRevenue : 0;
        // response[1].data.totalRevenue : 0;
        this.expenseList.forEach((el) => (this.totalExpense += +el.amount));
        this.summaryData = this.createSummaryChartData(
          this.totalRevenue,
          this.totalExpense
        );
        this.sharedService.updateLoader(false);
      });
    this.sharedService.updateLoader(false);
  }

  /**
   * To get subscription data
   */
  getSubsciptionData() {
    this.sharedService.updateLoader(false);
    this.subscriptonData.add(
      this.sharedService.updateUserRole$.subscribe((role: any) => {
        this.userRoles = role;

        // if (role[0] === 'ROLE_ON_BOARDING') {
        //   var id = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.securityGroup.id : null;
        //   this.getGroupData(id)

        // }

        if (
          role[0] === "ROLE_ORGANIZATION_ADMIN" ||
          role[0] === "ROLE_USER" ||
          role[0] === "ROLE_ADMIN" ||
          role[0] === "ROLE_BILLING_MANAGER" ||
          role[0] === "ROLE_CONTENT_MANAGER"
        ) {
          this.getRecentItem();
          this.getPromotionalImageData();
          this.getTrendingListData();
          // this.getMediaPlayCount()
          this.getAppDownloads();
          this.getAppLaunches();
          this.getFreeCount();
          this.getAllDaashboardData();
          this.getTransactionalSummary(this.transactionalSummaryType);
          // this.getUnsubscribedCount()
          // this.getSubscribedCount()
          // console.log( this.getSubscribedCount(),this.getUnsubscribedCount());

          this.sharedService.updateLoader(false);
        }
        this.sharedService.updateLoader(false);
      })
    );

    // this.subscriptonData.add(
    //   this.sharedService.updateGroup$.subscribe((response: any) => {
    //     this.getGroupData(response)
    //   })
    // )
    this.sharedService.updateLoader(false);
    this.subscriptonData.add(
      this.sharedService.updateUserRole$.subscribe((role: any) => {
        this.sharedService.updateLoader(false);
        this.userRoles = role;
        if (role[0] === "ROLE_ON_BOARDING") {
          var id = localStorage.getItem("loggedInUser")
            ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
                .securityGroup.id
            : null;
          this.getGroupData(id);
          this.securityGroupPermission = localStorage.getItem("loggedInUser")
            ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
                .securityGroup.permission
            : null;

          this.securityGroupPermission.forEach((el) => {
            if (el.pernissionName === "Customers" && el.view) {
              this.customers = true;
            }
            if (el.pernissionName === "Email Templates" && el.view) {
              this.emailTemp = true;
            }
            if (el.pernissionName === "Subscription" && el.view) {
              this.subscription = true;
            }
            if (el.pernissionName === "Onboarding Users" && el.view) {
              this.onBoardinguser = true;
            }
            if (el.pernissionName === "Security Group" && el.view) {
              this.securityGroup = true;
            }
            if (el.pernissionName === "Upload Stock" && el.view) {
              this.uploadStock = true;
            }
          });
          this.securityGroupPermission.forEach((el) => {
            if (this.customers) {
              this.router.navigate(["customer-module/customer"]);
            } else if (!this.customers && this.emailTemp) {
              this.router.navigate(["email-templates-module/template-type"]);
            } else if (
              !this.customers &&
              !this.emailTemp &&
              this.subscription
            ) {
              this.router.navigate(["subscription"]);
            } else if (
              !this.customers &&
              !this.emailTemp &&
              !this.subscription &&
              this.onBoardinguser
            ) {
              this.router.navigate(["onboarding-module/onboarding"]);
            } else if (
              !this.customers &&
              !this.emailTemp &&
              !this.subscription &&
              !this.onBoardinguser &&
              this.securityGroup
            ) {
              this.router.navigate(["security-module/security"]);
            } else if (
              !this.customers &&
              !this.emailTemp &&
              !this.subscription &&
              !this.onBoardinguser &&
              !this.securityGroup
            ) {
              this.router.navigate(["stock-module/upload-stock"]);
            }
          });
        }
        this.currentUrl = document.URL;
        if (this.currentUrl.includes("dashboard")) {
          window.onpopstate = function (event) {
            history.go(1);
          };
        }
      })
    );
    this.sharedService.updateLoader(false);

    this.subscriptonData.add(
      this.sharedService.updateGroupData$.subscribe((response: any) => {
        this.sharedService.updateLoader(false);
        if (this.userRoles[0] === "ROLE_ON_BOARDING") {
          this.getGroupData(response);
          console.log(this.getGroupData(response));
        }
      })
    );
    this.sharedService.updateLoader(false);
  }

  getGroupData(groupId) {
    this.sharedService.updateLoader(false);
    this.securityService.getGroupData(groupId).subscribe((response) => {
      this.sharedService.updateLoader(false);
      this.permissionArray = response.data ? response.data.permission : [];
      console.log(response, `res123`);
      if (this.permissionArray.length > 0) {
        this.permissionArray.forEach((el) => {
          if (el.pernissionName === "Expense" && !el.view) {
            this.expense = false;
            console.log(this.expense, `ex`);
          }
          if (el.pernissionName === "Revenue" && !el.view) {
            this.revenue = false;
            console.log(this.revenue, `rev`);
          }

          if (el.pernissionName === "Summary" && !el.view) {
            this.sumarry = false;
            console.log(this.sumarry, `summ`);
          }
        });
      }
    });
    this.sharedService.updateLoader(false);
  }

  // getUnsubscribedCount() {
  //   this.sharedService.updateLoader(false);
  //   this.dashboardService.getUnsubscribedCount().subscribe((response: any) => {
  //     this.sharedService.updateLoader(false);
  //     if (response.data) {
  //       this.unSubscribeCount = response.data
  //       console.log(this.unSubscribeCount);

  //     }
  //   })
  //   this.sharedService.updateLoader(false);
  // }

  getFreeCount() {
    this.dashboardService
      .getFreeSubscriberCount()
      .subscribe((response: any) => {
        this.sharedService.updateLoader(false);
        if (response.data) {
          this.freeSubscriberCount = response.data.freeSubscriberCount;
          this.paidsubscriberCounts = response.data.paidSubscriberCount;
          this.activePaidSubscribers = response.data.paidSubscriberCount;
          this.trialSubscriberCount = response.data.inTrial;
          this.totalTrial = response.data.inTrial;
          this.currentMRR = response.data.currentMRR;

          // if(response.data.activePaidSubscribers>0){
          //   this.activePaidSubscribersIntervels = setInterval(()=>{
          //     this.activePaidSubscribers++;
          //     if(this.activePaidSubscribers === response.data.activePaidSubscribers){
          //       clearInterval(this.activePaidSubscribersIntervels)
          //     }
          //   },10)
          // }
          // if(response.data.inTrial>0){
          //   this.totalTrialIntervels = setInterval(()=>{
          //     this.totalTrial++;
          //     if(this.totalTrial === response.data.inTrial){
          //       clearInterval(this.totalTrialIntervels)
          //     }
          //   },50)
          // }

          this.subscribedData = this.createSubscribedChartData(
            this.paidsubscriberCounts,
            this.freeSubscriberCount,
            this.trialSubscriberCount
          );
          const gradient2 = this.doughnutChart["chart"].canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 300);
          const gradient = this.doughnutChart["chart"].canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 300);
          const gradient3 = this.doughnutChart["chart"].canvas
            .getContext("2d")
            .createLinearGradient(0, 0, 0, 300);
          gradient2.addColorStop(0, "#003399");
          gradient2.addColorStop(1, "#00ffff");
          gradient.addColorStop(0, "#990033");
          gradient.addColorStop(1, "#ff9900");
          gradient3.addColorStop(0, "#660066");
          gradient3.addColorStop(1, "#ffb3ff");
          // Paid Subscribers', 'Free Subscribers', 'Trial Subscribers
          this.subscribedData["datasets"].forEach((element) => {
            // if(element.label === 'Free Subscribers'){
            element.backgroundColor = [gradient3, gradient, gradient2];
            // }
          });
        }
      });
  }

  // getSubscribedCount() {
  //   this.sharedService.updateLoader(false);
  //   this.dashboardService.geSubscribedCount().subscribe((response: any) => {
  //     this.sharedService.updateLoader(false);
  //     if (response.data) {
  //       this.subscribeCount = response.data
  //       this.subscribedData = this.createSubscribedChartData( this.subscribeCount ,this.unSubscribeCount)
  //     }
  //   })
  //   this.sharedService.updateLoader(false);
  // }

  /**
   * To create summary chart data
   * @param revenue Revenue
   * @param expense Expense
   */
  createSummaryChartData(revenue, expense) {
    const summaryData = {
      labels: ["Expenses", "Revenue"],
      datasets: [
        {
          data: [expense.toFixed(2), revenue.toFixed(2)],
          backgroundColor: ["#54047c", "#df3c01"],
        },
      ],
      legend: false,
    };
    return summaryData;
  }

  /* create total play count chart */

  createTotalPlayCountChartData(
    mobileios,
    mobileandroid,
    tvandroid,
    roku,
    website,
    tvios
  ) {
    const summaryData = {
      labels: [
        "APPLE MOBILE",
        "ANDROID MOBILE",
        "ANDROID TV",
        "ROKU",
        "WEBSITE",
        "APPLE TV",
      ],
      datasets: [
        {
          data: [mobileios, mobileandroid, tvandroid, roku, website, tvios],
          // old colors
          // backgroundColor: ['#9722fb', '#ff8960', '#ffff00', '#00ff00','#ac1af0','#f58297'],
          // new colors
          backgroundColor: [
            "#930101",
            "#2c7be5",
            "#ffb600",
            "#54047c",
            "#0b6623",
            "#6cbe00",
          ],
        },
      ],
      legend: false,
    };
    return summaryData;
  }

  /* create subscribed user */

  createSubscribedChartData(
    SubscribedCount,
    unsubscribedCount,
    trailSubscriberCount
  ) {
    const summaryData = {
      labels: ["Paid Subscribers", "Free Subscribers", "Trial Subscribers"],
      datasets: [
        {
          data: [SubscribedCount, unsubscribedCount, trailSubscriberCount],
          backgroundColor: ["#54047c", "#df3c01", "#2c7be5"],
        },
      ],
      legend: false,
    };
    return summaryData;
  }

  // new me
  createMediaChartData(tv, ios, web, android) {
    const summaryData = {
      labels: ["Instagram", "Facebook", "Twiter", "ANDROID"],
      datasets: [
        {
          data: [
            tv.toFixed(2),
            ios.toFixed(2),
            web.toFixed(2),
            android.toFixed(2),
          ],
          backgroundColor: ["#9722fb", "#ff8960", "#ffff00", "#00ff00"],
        },
      ],
      legend: false,
    };
    return summaryData;
  }

  creteAllAppDownloadChartData(
    month1,
    month2,
    month3,
    month4,
    month5,
    month6,
    month1Data,
    month2Data,
    month3Data,
    month4Data,
    month5Data,
    type
  ) {
    if (type === "allTvMobile" || type === "onlymobile") {
      const appData = {
        labels: [month1, month2, month3, month4, month5, month6],

        datasets: [
          {
            label:
              type === "allTvMobile"
                ? "Mobile"
                : type === "onlytv"
                ? "TV"
                : type === "onlymobile"
                ? "Android"
                : "",
            data: month1Data,
            fill: true,
            tension: 0.4,
            // borderColor: '#42A5F5',
            backgroundColor: "#cc00cc",
            // backgroundColor: 'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%)'
          },
          {
            label:
              type === "allTvMobile"
                ? "TV"
                : type === "onlytv"
                ? "Roku"
                : type === "onlymobile"
                ? "Apple"
                : "",
            data: month2Data,
            fill: true,
            // borderDash: [5, 5],
            tension: 0.4,
            // borderColor: '#66BB6A'
            backgroundColor: "#ff9966",
            // backgroundColor: 'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%)'
          },
        ],

        legend: false,
      };
      return appData;
    } else {
      const appData = {
        labels: [month1, month2, month3, month4, month5, month6],

        datasets: [
          {
            label: "Android TV",
            data: month1Data,
            fill: true,
            tension: 0.4,
            // borderColor: '#42A5F5',
            // backgroundColor: '#a7e0f9'
          },
          {
            label: "Roku",
            data: month2Data,
            fill: true,
            // borderDash: [5, 5],
            tension: 0.4,
            // borderColor: '#66BB6A'
            // backgroundColor: '#930101'
          },
          {
            label: "Apple TV",
            data: month3Data,
            fill: true,
            // borderDash: [5, 5],
            tension: 0.4,
            // borderColor: '#66BB6A'
            // backgroundColor: '#6cbe00'
          },
          {
            label: "Amazon Fire TV",
            data: month4Data,
            fill: true,
            // borderDash: [5, 5],
            tension: 0.4,
            // borderColor: '#66BB6A'
            // backgroundColor: '#ffb600'
          },
          {
            label: "Google TV",
            data: month5Data,
            fill: true,
            // borderDash: [5, 5],
            tension: 0.4,
            // borderColor: '#66BB6A'
            // backgroundColor: '#ffb600'
          },
        ],

        legend: false,
      };
      console.log("check app data", appData);
      return appData;
    }
  }

  /* create app download chart */

  creteAppDownloadChartData(
    month1,
    month2,
    month3,
    month4,
    month5,
    month6,
    month1Data,
    month2Data,
    month3Data,
    month4Data,
    month5Data,
    month6Data,
    month7Data,
    month8Data
  ) {
    const appData = {
      labels: [month1, month2, month3, month4, month5, month6],

      datasets: [
        {
          label: "ANDROID",
          data: month1Data,
          fill: false,
          tension: 0.4,
          // borderColor: '#42A5F5',
          backgroundColor: "#42A5F5",
        },
        {
          label: "APPLE",
          data: month2Data,
          fill: false,
          borderDash: [5, 5],
          tension: 0.4,
          // borderColor: '#66BB6A'
          backgroundColor: "#66BB6A",
        },
        {
          label: "Android TV",
          data: month3Data,
          fill: true,
          borderColor: "#FFA726",
          tension: 0.4,
          // backgroundColor: '#FFA726',
        },
        {
          label: "WEB",
          data: month4Data,
          fill: true,
          borderColor: "#808080",
          tension: 0.4,
          // backgroundColor:  '#OOOOOO',
        },
        {
          label: "ROKU",
          data: month5Data,
          fill: true,
          borderColor: "#eee",
          tension: 0.4,
          // backgroundColor:  '#OOOOOO',
        },
        {
          label: "APPLE TV",
          data: month6Data,
          fill: true,
          borderColor: "#eee",
          tension: 0.4,
          // backgroundColor:  '#OOOOOO',
        },
        {
          label: "AMAZON FIRE TV",
          data: month7Data,
          fill: true,
          borderColor: "#0b6623",
          tension: 0.4,
          // backgroundColor:  '#OOOOOO',
        },
        {
          label: "GOOGLE TV",
          data: month8Data,
          fill: true,
          borderColor: "#019fb8",
          tension: 0.4,
          // backgroundColor:  '#OOOOOO',
        },
      ],

      legend: false,
    };
    return appData;
  }

  /* create app Launches */

  creteAppLaunchesChartData(
    month1,
    month2,
    month3,
    month4,
    month5,
    month6,
    month1Data,
    month2Data,
    month3Data,
    month4Data,
    month5Data,
    month6Data,
    month7Data,
    month8Data
  ) {
    const appData = {
      labels: [month1, month2, month3, month4, month5, month6],

      datasets: [
        {
          label: "ANDROID MOBILE ",
          data: month1Data,
          fill: true,
          tension: 0.4,
          // borderColor: '#42A5F5',
          borderColor: "#00008B",
          backgroundColor: "#ccccff",
        },
        {
          label: "APPLE MOBILE",
          data: month2Data,
          fill: true,
          // borderDash: [5, 5],
          tension: 0.4,
          borderColor: "#930101",
          backgroundColor: "#ffb3b3",
        },
        {
          label: "ANDROID TV",
          data: month3Data,
          fill: true,
          borderColor: "#ffb600",
          tension: 0.4,
          backgroundColor: "#ffdb80",
        },
        {
          label: "APPLE TV",
          data: month6Data,
          fill: true,
          borderColor: "#0b6623",
          tension: 0.4,
          backgroundColor: "#8cf2a7",
        },
        {
          label: "ROKU TV",
          data: month5Data,
          fill: true,
          borderColor: "#54047c",
          tension: 0.4,
          backgroundColor: "#e5b5fc",
        },
        {
          label: "AMAZON FIRE TV",
          data: month7Data,
          fill: true,
          borderColor: "#df3c01",
          tension: 0.4,
          backgroundColor: "#ffac73",
        },
        {
          label: "GOOGLE TV",
          data: month8Data,
          fill: true,
          borderColor: "#019fb8",
          tension: 0.4,
          backgroundColor: "#99ccff",
        },
      ],

      legend: false,
    };
    return appData;
  }

  /* create app impressions */

  creteAppImpressionsChartData(
    month1,
    month2,
    month3,
    month4,
    month5,
    month1Data,
    month2Data,
    month3Data,
    month4Data
  ) {
    const appData = {
      labels: [month1, month2, month3, month4, month5],

      datasets: [
        {
          label: "ANDROID",
          data: month1Data,
          fill: false,
          tension: 0.4,
          // borderColor: '#42A5F5',
          backgroundColor: "#42A5F5",
        },
        {
          label: "APPLE",
          data: month2Data,
          fill: false,
          borderDash: [5, 5],
          tension: 0.4,
          // borderColor: '#66BB6A'
          backgroundColor: "#66BB6A",
        },
        {
          label: "TV",
          data: month3Data,
          fill: true,
          borderColor: "#FFA726",
          tension: 0.4,
          // backgroundColor: '#FFA726',
        },
        {
          label: "WEB",
          data: month4Data,
          fill: true,
          borderColor: "#808080",
          tension: 0.4,
          // backgroundColor:  '#OOOOOO',
        },
      ],

      legend: false,
    };
    return appData;
  }

  /**
   * To get filtered data
   */
  getFilteredData() {
    if (!this.year && this.month) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please select year",
      });
    } else if (this.year || this.year === "") {
      this.getDashboardData();
    }
  }

  /* get most recent item */

  getRecentItem() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getRecentMediaItem().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      // this.getAmchart()

      this.mostRecentItemId = response.data.id;
      this.speaker = response.data.speaker;
      this.title = response.data.title;
      this.date = response.data.createdDate;
      this.combined = response.data;

      const format1 = "YYYYMMDD HHMMSS";
      this.date = moment(this.date).format(format1);

      this.date = moment(this.date, "YYYYMMDD  HHMMSS").fromNow();

      if (this.combined) {
        if (this.combined["wideArtwork"]) {
          // if (this.combined['wideArtwork'].document.bdImage === true) {
          var width = 500;
          var height = 300;
          let id = this.combined["wideArtwork"].document.id;
          this.combined["wideArtwork"].document["newimg"] =
            `${appConfig.imageURL}` +
            id +
            "?height=" +
            height +
            "&width=" +
            width;
          if (this.combined["wideArtwork"].document["newimg"]) {
            this.imagePath = this.combined["wideArtwork"].document["newimg"];
          }
          // }
        }
      }
    });
    this.sharedService.updateLoader(false);
  }

  getTrendingListData() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getTrendingListItem().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      this.trendinglist = response.data ? response.data : [];

      this.trendinglist.forEach((data) => {
        if (data.item.squareArtwork) {
          if (data.item.squareArtwork.artworkType === "SQUARE") {
            var width = 80;
            var height = 80;
          }
          let id = data.item.squareArtwork.document.id;
          data.item.squareArtwork.document["newimg"] =
            `${appConfig.imageURL}` +
            id +
            "?height=" +
            height +
            "&width=" +
            width;
        }
        if (data.item.bannerArtwork) {
          if (data.item.bannerArtwork.artworkType === "BANNER") {
            var width = 150;
            var height = 56;
          }
          let id = data.item.bannerArtwork.document.id;
          data.item.bannerArtwork.document["newimg"] =
            `${appConfig.imageURL}` +
            id +
            "?height=" +
            height +
            "&width=" +
            width;
        }
        if (data.item.wideArtwork) {
          if (data.item.wideArtwork.artworkType === "WIDE") {
            var width = 150;
            var height = 70;
          }
          let id = data.item.wideArtwork.document.id;
          data.item.wideArtwork.document["newimg"] =
            `${appConfig.imageURL}` +
            id +
            "?height=" +
            height +
            "&width=" +
            width;
        }
      });
    });
    this.sharedService.updateLoader(false);
  }

  getMediaPlayCount() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getMediaPlayCount().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      this.playCountList = response.data;
      this.playCountList.forEach((el) => {
        if (el.mediaPlayCount) {
          this.mediaPlayCount = el.mediaPlayCount;
          this.pastDaysCount = el.past3ODaysCount;
          // this.playCountData = this.createTotalPlayCountChartData(this.mediaPlayCount, this.pastDaysCount)
        }
        if (el.deviceType === "MOBILE_IOS") {
          this.mobileios = el.playCount;
        }
        if (el.deviceType === "MOBILE_ANDROID") {
          this.mobileandroid = el.playCount;
        }
        if (el.deviceType === "TV_ANDROID") {
          this.tvandroid = el.playCount;
        }
        if (el.deviceType === "ROKU") {
          this.roku = el.playCount;
        }
        if (el.deviceType === "WEBSITE") {
          this.website = el.playCount;
        }
        if (el.deviceType === "TV_IOS") {
          this.tvios = el.playCount;
        }
        // this.playCountData = this.createTotalPlayCountChartData(this.tv, this.ios,this.web,this.android)
      });

      this.playCountData = this.createTotalPlayCountChartData(
        this.mobileios,
        this.mobileandroid,
        this.tvandroid,
        this.roku,
        this.website,
        this.tvios
      );
    });
    this.sharedService.updateLoader(false);
  }

  getAppDownloads() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getAppDownloads().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      if (response.data[0]) {
        this.appDownloadsArray = response.data;
        console.log(this.appDownloadsArray);
        const format1 = "MMM, YYYY";
        if (
          this.appDownloadsArray.length > 0
            ? this.appDownloadsArray[0]["date"]
            : null
        ) {
          this.firstDateMonth = this.appDownloadsArray[0]["date"];
          this.firstDateMonth = moment(this.firstDateMonth).format(format1);
        }
        if (
          this.appDownloadsArray.length > 0
            ? this.appDownloadsArray[0]["total"]
            : null
        ) {
          this.appDownloads = this.appDownloadsArray[0]["total"];
        }
        if (
          this.appDownloadsArray.length > 0
            ? this.appDownloadsArray[0]["list"]
            : null
        ) {
          this.firstMonthArray = this.appDownloadsArray[0]["list"];

          this.firstMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.androidFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.iosFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.tvFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.tvIosFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.tvAmazonFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.rokuFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.googleFirstMonth = Math.floor(el.count);
            } else {
              this.webFirstMonth = Math.floor(el.count);
            }
          });

          let data = {};
          let newData = {};
          data = {
            month: this.firstDateMonth,
            android: this.androidFirstMonth,
            ios: this.iosFirstMonth,
            web: this.webFirstMonth,
            tv: this.tvFirstMonth,
            IOS_TV: this.tvIosFirstMonth,
            Amazon_TV: this.tvAmazonFirstMonth,
            google: this.googleFirstMonth,
            roku: this.rokuFirstMonth,
          };
          this.appDownArr.push(data);

          newData = {
            month: this.firstDateMonth,
            Mobile: this.androidFirstMonth + this.iosFirstMonth,
            TV:
              this.tvFirstMonth +
              this.rokuFirstMonth +
              this.tvIosFirstMonth +
              this.tvAmazonFirstMonth +
              this.googleFirstMonth,
          };
          this.allAppDownloadData.push(newData);
        }
        if (
          this.appDownloadsArray.length > 1
            ? this.appDownloadsArray[1]["date"]
            : null
        ) {
          this.secondDateMonth = this.appDownloadsArray[1]["date"];
          this.secondDateMonth = moment(this.secondDateMonth).format(format1);
        }
        if (
          this.appDownloadsArray.length > 1
            ? this.appDownloadsArray[1]["list"]
            : null
        ) {
          this.secondMonthArray = this.appDownloadsArray[1]["list"];

          this.secondMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.androidSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.iosSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.tvSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.tvIosSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.tvAmazonSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.rokuSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.googleSecondMonth = Math.floor(el.count);
            } else {
              this.webSecondMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.secondDateMonth,
            android: this.androidSecondMonth,
            ios: this.iosSecondMonth,
            web: this.webSecondMonth,
            tv: this.tvSecondMonth,
            IOS_TV: this.tvIosSecondMonth,
            Amazon_TV: this.tvAmazonSecondMonth,
            google: this.googleSecondMonth,
            roku: this.rokuSecondMonth,
          };
          this.appDownArr.push(data);

          let newData = {};
          newData = {
            month: this.secondDateMonth,
            Mobile: this.androidSecondMonth + this.iosSecondMonth,
            TV:
              this.tvSecondMonth +
              this.rokuSecondMonth +
              this.tvIosSecondMonth +
              this.tvAmazonSecondMonth +
              this.googleSecondMonth,
          };
          this.allAppDownloadData.push(newData);

          // this.secondMonthDataArray = [this.androidSecondMonth, this.iosSecondMonth, this.tvSecondMonth, this.webSecondMonth]
        }
        if (
          this.appDownloadsArray.length > 2
            ? this.appDownloadsArray[2]["date"]
            : null
        ) {
          this.thirdDateMonth = this.appDownloadsArray[2]["date"];
          this.thirdDateMonth = moment(this.thirdDateMonth).format(format1);
        }
        if (
          this.appDownloadsArray.length > 2
            ? this.appDownloadsArray[2]["list"]
            : null
        ) {
          this.thirdMonthArray = this.appDownloadsArray[2]["list"];

          this.thirdMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.androidThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.iosThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.tvThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.tvIosThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.tvAmazonThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.rokuThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.googleThirdMonth = Math.floor(el.count);
            } else {
              this.webThirdMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.thirdDateMonth,
            android: this.androidThirdMonth,
            ios: this.iosThirdMonth,
            web: this.webThirdMonth,
            tv: this.tvThirdMonth,
            IOS_TV: this.tvIosThirdMonth,
            Amazon_TV: this.tvAmazonThirdMonth,
            google: this.googleThirdMonth,

            roku: this.rokuThirdMonth,
          };
          this.appDownArr.push(data);

          let newData = {};
          newData = {
            month: this.thirdDateMonth,
            Mobile: this.androidThirdMonth + this.iosThirdMonth,
            TV:
              this.tvThirdMonth +
              this.rokuThirdMonth +
              this.tvIosThirdMonth +
              this.tvAmazonThirdMonth +
              this.googleThirdMonth,
          };
          this.allAppDownloadData.push(newData);

          // this.thirdMonthDataArray = [this.androidThirdMonth, this.iosThirdMonth, this.tvThirdMonth, this.webThirdMonth]
        }
        if (
          this.appDownloadsArray.length > 3
            ? this.appDownloadsArray[3]["date"]
            : null
        ) {
          this.fourthDateMonth = this.appDownloadsArray[3]["date"];
          this.fourthDateMonth = moment(this.fourthDateMonth).format(format1);
        }
        if (
          this.appDownloadsArray.length > 3
            ? this.appDownloadsArray[3]["list"]
            : null
        ) {
          this.fourthMonthArray = this.appDownloadsArray[3]["list"];

          this.fourthMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.androidFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.iosFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.tvFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.tvIosFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.tvAmazonFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.rokuFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.googleFourthMonth = Math.floor(el.count);
            } else {
              this.webFourthMonth = Math.floor(el.count);
            }
          });

          let data = {};
          // this.rokuFourthMonth = Math.floor(el.count)
          data = {
            month: this.fourthDateMonth,
            android: this.androidFourthMonth,
            ios: this.iosFourthMonth,
            web: this.webFourthMonth,
            tv: this.tvFourthMonth,
            IOS_TV: this.tvIosFourthMonth,
            Amazon_TV: this.tvAmazonFourthMonth,
            google: this.googleFourthMonth,
            roku: this.rokuFourthMonth,
          };
          this.appDownArr.push(data);
          let newData = {};
          newData = {
            month: this.fourthDateMonth,
            Mobile: this.androidFourthMonth + this.iosFourthMonth,
            TV:
              this.tvFourthMonth +
              this.rokuFourthMonth +
              this.tvIosFourthMonth +
              this.tvAmazonFourthMonth +
              this.googleFourthMonth,
          };
          this.allAppDownloadData.push(newData);
        }
        if (
          this.appDownloadsArray.length > 4
            ? this.appDownloadsArray[4]["date"]
            : null
        ) {
          this.fifthDateMonth = this.appDownloadsArray[4]["date"];
          this.fifthDateMonth = moment(this.fifthDateMonth).format(format1);
        }
        if (
          this.appDownloadsArray.length > 4
            ? this.appDownloadsArray[4]["list"]
            : null
        ) {
          this.fifthMonthArray = this.appDownloadsArray[4]["list"];

          this.fifthMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.androidFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.iosFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.tvFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.tvIosFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.tvAmazonFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.rokuFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.googleFifthMonth = Math.floor(el.count);
            } else {
              this.webFifthMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.fifthDateMonth,
            android: this.androidFifthMonth,
            ios: this.iosFifthMonth,
            web: this.webFifthMonth,
            tv: this.tvFifthMonth,
            IOS_TV: this.tvIosFifthMonth,
            Amazon_TV: this.tvAmazonFifthMonth,
            google: this.googleFifthMonth,
            roku: this.rokuFifthMonth,
          };
          this.appDownArr.push(data);
          let newData = {};
          newData = {
            month: this.fifthDateMonth,
            Mobile: this.androidFifthMonth + this.iosFifthMonth,
            TV:
              this.tvFifthMonth +
              this.rokuFifthMonth +
              this.tvIosFifthMonth +
              this.tvAmazonFifthMonth +
              this.googleFifthMonth,
          };
          this.allAppDownloadData.push(newData);
        }

        if (
          this.appDownloadsArray.length > 4
            ? this.appDownloadsArray[5]["date"]
            : null
        ) {
          this.sixthDateMonth = this.appDownloadsArray[5]["date"];
          this.sixthDateMonth = moment(this.sixthDateMonth).format(format1);
        }

        if (
          this.appDownloadsArray.length > 4
            ? this.appDownloadsArray[5]["list"]
            : null
        ) {
          this.sixthMonthArray = this.appDownloadsArray[5]["list"];

          // this.firstDateMonth = moment(this.firstDateMonth).format(format1);
          this.sixthMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.androidSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.iosSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.tvSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.tvIosSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.tvAmazonSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.rokuSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.googleSixthMonth = Math.floor(el.count);
            } else {
              this.webSixthMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.sixthDateMonth,
            android: this.androidSixthMonth,
            ios: this.iosSixthMonth,
            web: this.webSixthMonth,
            tv: this.tvSixthMonth,
            IOS_TV: this.tvIosSixthMonth,
            Amazon_TV: this.tvAmazonSixthMonth,
            google: this.googleSixthMonth,
            roku: this.rokuSixthMonth,
          };
          this.appDownArr.push(data);
          let newData = {};
          newData = {
            month: this.sixthDateMonth,
            Mobile: this.androidSixthMonth + this.iosSixthMonth,
            TV:
              this.tvSixthMonth +
              this.rokuSixthMonth +
              this.tvIosSixthMonth +
              this.tvAmazonSixthMonth +
              this.googleSixthMonth,
          };
          this.allAppDownloadData.push(newData);
        }

        this.firstMonthDataArray = [
          this.androidFirstMonth,
          this.androidSecondMonth,
          this.androidThirdMonth,
          this.androidFourthMonth,
          this.androidFifthMonth,
          this.androidSixthMonth,
        ];
        this.secondMonthDataArray = [
          this.iosFirstMonth,
          this.iosSecondMonth,
          this.iosThirdMonth,
          this.iosFourthMonth,
          this.iosFifthMonth,
          this.iosSixthMonth,
        ];
        this.thirdMonthDataArray = [
          this.tvFirstMonth,
          this.tvSecondMonth,
          this.tvThirdMonth,
          this.tvFourthMonth,
          this.tvFifthMonth,
          this.tvSixthMonth,
        ];
        this.fourthMonthDataArray = [
          this.webFirstMonth,
          this.webSecondMonth,
          this.webThirdMonth,
          this.webFourthMonth,
          this.webFifthMonth,
          this.webSixthMonth,
        ];
        this.fifthMonthDataArray = [
          this.rokuFirstMonth,
          this.rokuSecondMonth,
          this.rokuThirdMonth,
          this.rokuFourthMonth,
          this.rokuFifthMonth,
          this.rokuSixthMonth,
        ];
        this.sixthMonthDataArray = [
          this.tvIosFirstMonth,
          this.tvIosSecondMonth,
          this.tvIosThirdMonth,
          this.tvIosFourthMonth,
          this.tvIosFifthMonth,
          this.tvIosSixthMonth,
        ];
        this.seventhMonthDataArray = [
          this.tvAmazonFirstMonth,
          this.tvAmazonSecondMonth,
          this.tvAmazonThirdMonth,
          this.tvAmazonFourthMonth,
          this.tvAmazonFifthMonth,
          this.tvAmazonSixthMonth,
        ];
        this.eighthMonthDataArray = [
          this.googleFirstMonth,
          this.googleSecondMonth,
          this.googleThirdMonth,
          this.googleFourthMonth,
          this.googleFifthMonth,
          this.googleSixthMonth,
        ];
        this.appDownloadsData = this.creteAppDownloadChartData(
          this.firstDateMonth,
          this.secondDateMonth,
          this.thirdDateMonth,
          this.fourthDateMonth,
          this.fifthDateMonth,
          this.sixthDateMonth,
          this.firstMonthDataArray,
          this.secondMonthDataArray,
          this.thirdMonthDataArray,
          this.fourthMonthDataArray,
          this.fifthMonthDataArray,
          this.sixthMonthDataArray,
          this.seventhMonthDataArray,
          this.eighthMonthDataArray
        );

        // all data creation

        let allfirstMonthDataArray = [];
        let allsecondMonthDataArray = [];
        this.allAppDownloadData.forEach((el) => {
          allfirstMonthDataArray.push(el.Mobile);
          allsecondMonthDataArray.push(el.TV);
        });
        this.AllappDownloadsData = this.creteAllAppDownloadChartData(
          this.firstDateMonth,
          this.secondDateMonth,
          this.thirdDateMonth,
          this.fourthDateMonth,
          this.fifthDateMonth,
          this.sixthDateMonth,
          allfirstMonthDataArray,
          allsecondMonthDataArray,
          "",
          "",
          "",
          "allTvMobile"
        );

        // const gradient = this.canvas2['chart'].canvas.getContext('2d').createLinearGradient(0, 0, 0, 300);
        // const gradient2 = this.canvas2['chart'].canvas.getContext('2d').createLinearGradient(0, 0, 0, 300);
        // const gradient3 = this.canvas2['chart'].canvas.getContext('2d').createLinearGradient(0, 0, 0, 300);
        // const gradient4 = this.canvas2['chart'].canvas.getContext('2d').createLinearGradient(0, 0, 0, 300);
        // gradient.addColorStop(0, '#990033');gradient.addColorStop(1, '#ff9900');
        // gradient2.addColorStop(0, '#003399');gradient2.addColorStop(1, '#00ffff');
        // gradient3.addColorStop(0, '#003366');gradient3.addColorStop(1, '#cc33ff');
        // gradient4.addColorStop(0, '#003300');gradient4.addColorStop(1, '#99ff33');

        const iosTvColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        const iosMobileColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        const androidMobileColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        const androidTvColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        const rokuColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        const amazonTvColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        const googleTvColor = this.canvas2["chart"].canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 350);
        iosTvColor.addColorStop(0, "#6cbe00");
        iosTvColor.addColorStop(1, "#ffff66");
        iosMobileColor.addColorStop(0, "#930101");
        iosMobileColor.addColorStop(1, "#ff941a");
        // androidMobileColor.addColorStop(0, "#00008B");
        // androidMobileColor.addColorStop(1, "#cc00cc");
        androidMobileColor.addColorStop(0, "#00008B");
        androidMobileColor.addColorStop(1, "#cc00cc");
        androidTvColor.addColorStop(0, "#ffb600");
        androidTvColor.addColorStop(1, "#ff9966");
        rokuColor.addColorStop(0, "#54047c");
        rokuColor.addColorStop(1, "#99ccff");
        amazonTvColor.addColorStop(0, "#df3c01");
        amazonTvColor.addColorStop(1, "#ebe409");
        googleTvColor.addColorStop(0, "#019fb8");
        googleTvColor.addColorStop(1, "#a6f0ff");
        this.AllappDownloadsData["datasets"].forEach((element) => {
          //  if (!this.featureControlService.showTvApp) {
          //    if (element.label === "Mobile") {
          //      element.backgroundColor = [
          //        androidMobileColor,
          //        androidMobileColor,
          //        androidMobileColor,
          //        androidMobileColor,
          //        androidMobileColor,
          //        androidMobileColor,
          //      ];
          //    }
          //    this.AllappDownloadsData["datasets"] = this.AllappDownloadsData[
          //      "datasets"
          //    ].filter((element) => {
          //      return element.label !== "TV";
          //    });
          //  }

          //
          //  else {
          if (element.label === "Mobile") {
            element.backgroundColor = [
              androidMobileColor,
              androidMobileColor,
              androidMobileColor,
              androidMobileColor,
              androidMobileColor,
              androidMobileColor,
            ];
          } else if (element.label === "TV") {
            element.backgroundColor = [
              iosMobileColor,
              iosMobileColor,
              iosMobileColor,
              iosMobileColor,
              iosMobileColor,
              iosMobileColor,
            ];
          }
          //  }
        });
        let tvDataArray = [];
        let rokuDataArray = [];
        let iosTvDataArray = [];
        let amazonTvDataArray = [];
        let googleTvDataArray = [];

        this.appDownArr.forEach((el) => {
          tvDataArray.push(el.tv);
          rokuDataArray.push(el.roku);
          iosTvDataArray.push(el.IOS_TV);
          amazonTvDataArray.push(el.Amazon_TV);
          googleTvDataArray.push(el.google);
        });

        this.onlytvappDownloadsData = this.creteAllAppDownloadChartData(
          this.firstDateMonth,
          this.secondDateMonth,
          this.thirdDateMonth,
          this.fourthDateMonth,
          this.fifthDateMonth,
          this.sixthDateMonth,
          tvDataArray,
          rokuDataArray,
          iosTvDataArray,
          amazonTvDataArray,
          googleTvDataArray,
          "onlytv"
        );

        // if (!this.tvAppAndroidTV) {
        //   this.onlytvappDownloadsData["datasets"].forEach((element) => {
        //     if (element.label === "Roku") {
        //       element.backgroundColor = [
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //       ];
        //     } else if (element.label === "Apple TV") {
        //       element.backgroundColor = [
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //       ];
        //     } else if (element.label === "Amazon Fire TV") {
        //       element.backgroundColor = [
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //       ];
        //     }
        //   });
        //   this.onlytvappDownloadsData["datasets"] = this.onlytvappDownloadsData[
        //     "datasets"
        //   ].filter((element) => {
        //     return element.label !== "Android TV";
        //   });
        // } else if (!this.tvAppIOSTV) {
        //   this.onlytvappDownloadsData["datasets"].forEach((element) => {
        //     if (element.label === "Roku") {
        //       element.backgroundColor = [
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //       ];
        //     } else if (element.label === "Android TV") {
        //       element.backgroundColor = [
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //       ];
        //     } else if (element.label === "Amazon Fire TV") {
        //       element.backgroundColor = [
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //       ];
        //     }
        //   });
        //   this.onlytvappDownloadsData["datasets"] = this.onlytvappDownloadsData[
        //     "datasets"
        //   ].filter((element) => {
        //     return element.label !== "Apple TV";
        //   });
        // } else if (!this.tvAppAmazonTV) {
        //   this.onlytvappDownloadsData["datasets"].forEach((element) => {
        //     if (element.label === "Roku") {
        //       element.backgroundColor = [
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //       ];
        //     } else if (element.label === "Android TV") {
        //       element.backgroundColor = [
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //       ];
        //     } else if (element.label === "Apple TV") {
        //       element.backgroundColor = [
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //       ];
        //     }
        //   });
        //   this.onlytvappDownloadsData["datasets"] = this.onlytvappDownloadsData[
        //     "datasets"
        //   ].filter((element) => {
        //     return element.label !== "Amazon Fire TV";
        //   });
        // } else if (!this.tvAppRoku) {
        //   this.onlytvappDownloadsData["datasets"].forEach((element) => {
        //     // if (element.label === 'Roku') {
        //     //   element.backgroundColor = [rokuColor, rokuColor, rokuColor, rokuColor, rokuColor, rokuColor]
        //     // }
        //     if (element.label === "Android TV") {
        //       element.backgroundColor = [
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //       ];
        //     } else if (element.label === "Amazon Fire TV") {
        //       element.backgroundColor = [
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //       ];
        //     } else if (element.label === "Apple TV") {
        //       element.backgroundColor = [
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //       ];
        //     }
        //   });
        //   this.onlytvappDownloadsData["datasets"] = this.onlytvappDownloadsData[
        //     "datasets"
        //   ].filter((element) => {
        //     return element.label !== "Roku";
        //   });
        // } else if (!this.tvAppGoogleTV) {
        //   this.onlytvappDownloadsData["datasets"].forEach((element) => {
        //     if (element.label === "Roku") {
        //       element.backgroundColor = [
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //       ];
        //     } else if (element.label === "Android TV") {
        //       element.backgroundColor = [
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //       ];
        //     } else if (element.label === "Amazon Fire TV") {
        //       element.backgroundColor = [
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //       ];
        //     } else if (element.label === "Apple TV") {
        //       element.backgroundColor = [
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //       ];
        //     }
        //   });
        //   this.onlytvappDownloadsData["datasets"] = this.onlytvappDownloadsData[
        //     "datasets"
        //   ].filter((element) => {
        //     return element.label !== "Google TV";
        //   });
        // } else {
        //   this.onlytvappDownloadsData["datasets"].forEach((element) => {
        //     if (element.label === "Android TV") {
        //       element.backgroundColor = [
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //         androidTvColor,
        //       ];
        //     } else if (element.label === "Roku") {
        //       element.backgroundColor = [
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //         rokuColor,
        //       ];
        //     } else if (element.label === "Apple TV") {
        //       element.backgroundColor = [
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //         iosTvColor,
        //       ];
        //     } else if (element.label === "Amazon Fire TV") {
        //       element.backgroundColor = [
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //         amazonTvColor,
        //       ];
        //     } else if (element.label === "Google TV") {
        //       element.backgroundColor = [
        //         googleTvColor,
        //         googleTvColor,
        //         googleTvColor,
        //         googleTvColor,
        //         googleTvColor,
        //         googleTvColor,
        //       ];
        //     }
        //   });
        // }

        // conditionally handle

        if (!this.tvAppAndroidTV) {
          this.updateFilter("Android TV");
        }
        if (!this.tvAppIOSTV) {
          this.updateFilter("Apple TV");
        }
        if (!this.tvAppAmazonTV) {
          this.updateFilter("Amazon Fire TV");
        }
        if (!this.tvAppRoku) {
          this.updateFilter("Roku");
        }
        if (!this.tvAppGoogleTV) {
          this.updateFilter("Google TV");
        }
        if (
          this.tvAppAmazonTV &&
          this.tvAppAndroidTV &&
          this.tvAppGoogleTV &&
          this.tvAppIOSTV &&
          this.tvAppRoku
        ) {
          this.onlytvappDownloadsData["datasets"].forEach((element) => {
            if (element.label === "Android TV") {
              element.backgroundColor = [
                androidTvColor,
                androidTvColor,
                androidTvColor,
                androidTvColor,
                androidTvColor,
                androidTvColor,
              ];
            } else if (element.label === "Roku") {
              element.backgroundColor = [
                rokuColor,
                rokuColor,
                rokuColor,
                rokuColor,
                rokuColor,
                rokuColor,
              ];
            } else if (element.label === "Apple TV") {
              element.backgroundColor = [
                iosTvColor,
                iosTvColor,
                iosTvColor,
                iosTvColor,
                iosTvColor,
                iosTvColor,
              ];
            } else if (element.label === "Amazon Fire TV") {
              element.backgroundColor = [
                amazonTvColor,
                amazonTvColor,
                amazonTvColor,
                amazonTvColor,
                amazonTvColor,
                amazonTvColor,
              ];
            } else if (element.label === "Google TV") {
              element.backgroundColor = [
                googleTvColor,
                googleTvColor,
                googleTvColor,
                googleTvColor,
                googleTvColor,
                googleTvColor,
              ];
            }
          });
        }

        // get tv's data
        let androidDataArray = [];
        let iosDataArray = [];
        this.appDownArr.forEach((el) => {
          androidDataArray.push(el.android);
          iosDataArray.push(el.ios);
        });
        this.onlymobileappDownloadsData = this.creteAllAppDownloadChartData(
          this.firstDateMonth,
          this.secondDateMonth,
          this.thirdDateMonth,
          this.fourthDateMonth,
          this.fifthDateMonth,
          this.sixthDateMonth,
          androidDataArray,
          iosDataArray,
          "",
          "",
          "",
          "onlymobile"
        );
        if (!this.mobileAppAndroid) {
          this.onlymobileappDownloadsData["datasets"].forEach((element) => {
            //  if (element.label === "Android") {
            //    element.backgroundColor = [
            //      androidMobileColor,
            //      androidMobileColor,
            //      androidMobileColor,
            //      androidMobileColor,
            //      androidMobileColor,
            //      androidMobileColor,
            //    ];
            //  }
            if (element.label === "Apple") {
              element.backgroundColor = [
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
              ];
            }
            //     else if (element.label === 'Roku') {
            //   element.backgroundColor = [androidTvColor, androidTvColor, androidTvColor, androidTvColor, androidTvColor, androidTvColor]
            // }
          });
          this.onlymobileappDownloadsData["datasets"] =
            this.onlymobileappDownloadsData["datasets"].filter((element) => {
              return element.label !== "Android";
            });
        } else if (!this.mobileAppIos) {
          this.onlymobileappDownloadsData["datasets"].forEach((element) => {
            if (element.label === "Android") {
              element.backgroundColor = [
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
              ];
            }
          });
          this.onlymobileappDownloadsData["datasets"] =
            this.onlymobileappDownloadsData["datasets"].filter((element) => {
              return element.label !== "Apple";
            });
        } else {
          this.onlymobileappDownloadsData["datasets"].forEach((element) => {
            if (element.label === "Android") {
              element.backgroundColor = [
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
                androidMobileColor,
              ];
            } else if (element.label === "Apple") {
              element.backgroundColor = [
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
                iosMobileColor,
              ];
            }
          });
          // this.onlymobileappDownloadsData["datasets"] =
          //   this.onlymobileappDownloadsData["datasets"].filter((element) => {
          //     return element.label !== "Apple";
          //   });
        }

        // this.getMapApmChart()
        setTimeout(() => {
          // this.getStateMapChart()
        }, 1000);
      }
      this.sharedService.updateLoader(false);
      // this.getAppDownloadGraph(this.appDownArr)
    });
    this.sharedService.updateLoader(false);
  }

  updateFilter(excludeLabel) {
    const iosTvColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    const iosMobileColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    const androidMobileColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    const androidTvColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    const rokuColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    const amazonTvColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    const googleTvColor = this.canvas2["chart"].canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    iosTvColor.addColorStop(0, "#6cbe00");
    iosTvColor.addColorStop(1, "#ffff66");
    iosMobileColor.addColorStop(0, "#930101");
    iosMobileColor.addColorStop(1, "#ff941a");
    // androidMobileColor.addColorStop(0, "#00008B");
    // androidMobileColor.addColorStop(1, "#cc00cc");
    androidMobileColor.addColorStop(0, "#00008B");
    androidMobileColor.addColorStop(1, "#cc00cc");
    androidTvColor.addColorStop(0, "#ffb600");
    androidTvColor.addColorStop(1, "#ff9966");
    rokuColor.addColorStop(0, "#54047c");
    rokuColor.addColorStop(1, "#99ccff");
    amazonTvColor.addColorStop(0, "#df3c01");
    amazonTvColor.addColorStop(1, "#ebe409");
    googleTvColor.addColorStop(0, "#019fb8");
    googleTvColor.addColorStop(1, "#a6f0ff");
    const tvAppColors = {
      Roku: [rokuColor, rokuColor, rokuColor, rokuColor, rokuColor, rokuColor],
      "Android TV": [
        androidTvColor,
        androidTvColor,
        androidTvColor,
        androidTvColor,
        androidTvColor,
        androidTvColor,
      ],
      "Apple TV": [
        iosTvColor,
        iosTvColor,
        iosTvColor,
        iosTvColor,
        iosTvColor,
        iosTvColor,
      ],
      "Amazon Fire TV": [
        amazonTvColor,
        amazonTvColor,
        amazonTvColor,
        amazonTvColor,
        amazonTvColor,
        amazonTvColor,
      ],
      "Google TV": [
        googleTvColor,
        googleTvColor,
        googleTvColor,
        googleTvColor,
        googleTvColor,
        googleTvColor,
      ],
    };

    const tvAppLabels = Object.keys(tvAppColors);
    this.onlytvappDownloadsData["datasets"].forEach((element) => {
      const label = element.label;
      if (!excludeLabel || label !== excludeLabel) {
        element.backgroundColor = tvAppColors[label];
      }
    });

    if (excludeLabel) {
      this.onlytvappDownloadsData["datasets"] = this.onlytvappDownloadsData[
        "datasets"
      ].filter((element) => {
        return element.label !== excludeLabel;
      });
    }
  }

  getAppLaunches() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getAppLaunches().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      this.appLaunchArr = [];
      if (response.data[0]) {
        this.appLaunchesArray = response.data;
        const format1 = "MMM, YYYY";

        if (
          this.appLaunchesArray.length > 0
            ? this.appLaunchesArray[0]["date"]
            : null
        ) {
          this.appLaunchesFirstDateMonth = this.appLaunchesArray[0]["date"];
          this.appLaunchesFirstDateMonth = moment(
            this.appLaunchesFirstDateMonth
          ).format(format1);
        }
        if (
          this.appLaunchesArray.length > 0
            ? this.appLaunchesArray[0]["total"]
            : null
        ) {
          this.appLaunches = this.appLaunchesArray[0]["total"];
        }
        if (
          this.appLaunchesArray.length > 0
            ? this.appLaunchesArray[0]["list"]
            : null
        ) {
          this.appLaunchesFirstMonthArray = this.appLaunchesArray[0]["list"];

          this.appLaunchesFirstMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.appLaunchesAndroidFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.appLaunchesIosFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.appLaunchesTvFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.appLaunchestvIosFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.appLaunchestvAmazonFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.appLaunchesRokuFirstMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.appLaunchesGoogleFirstMonth = Math.floor(el.count);
            } else {
              this.appLaunchesWebFirstMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.appLaunchesFirstDateMonth,
            android: this.appLaunchesAndroidFirstMonth,
            ios: this.appLaunchesIosFirstMonth,
            web: this.appLaunchesWebFirstMonth,
            tv: this.appLaunchesTvFirstMonth,
            IOS_TV: this.appLaunchestvIosFirstMonth,
            Amazon_TV: this.appLaunchestvAmazonFirstMonth,
            google: this.appLaunchesGoogleFirstMonth,
            roku: this.appLaunchesRokuFirstMonth,
          };

          this.appLaunchArr.push(data);
        }
        if (
          this.appLaunchesArray.length > 1
            ? this.appLaunchesArray[1]["date"]
            : null
        ) {
          this.appLaunchesSecondDateMonth = this.appLaunchesArray[1]["date"];
          this.appLaunchesSecondDateMonth = moment(
            this.appLaunchesSecondDateMonth
          ).format(format1);
        }
        if (
          this.appLaunchesArray.length > 1
            ? this.appLaunchesArray[1]["list"]
            : null
        ) {
          this.appLaunchesSecondMonthArray = this.appLaunchesArray[1]["list"];

          this.appLaunchesSecondMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.appLaunchesAndroidSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.appLaunchesIosSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.appLaunchesTvSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.appLaunchestvIosSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.appLaunchestvAmazonSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.appLaunchesRokuSecondMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.appLaunchesGoogleSecondMonth = Math.floor(el.count);
            } else {
              this.appLaunchesWebSecondMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.appLaunchesSecondDateMonth,
            android: this.appLaunchesAndroidSecondMonth,
            ios: this.appLaunchesIosSecondMonth,
            web: this.appLaunchesWebSecondMonth,
            tv: this.appLaunchesTvSecondMonth,
            IOS_TV: this.appLaunchestvIosSecondMonth,
            Amazon_TV: this.appLaunchestvAmazonSecondMonth,
            google: this.appLaunchesGoogleSecondMonth,

            roku: this.appLaunchesRokuSecondMonth,
          };

          this.appLaunchArr.push(data);

          // this.secondMonthDataArray = [this.androidSecondMonth, this.iosSecondMonth, this.tvSecondMonth, this.webSecondMonth]
        }
        if (
          this.appLaunchesArray.length > 2
            ? this.appLaunchesArray[2]["date"]
            : null
        ) {
          this.appLaunchesThirdDateMonth = this.appLaunchesArray[2]["date"];
          this.appLaunchesThirdDateMonth = moment(
            this.appLaunchesThirdDateMonth
          ).format(format1);
        }
        if (
          this.appLaunchesArray.length > 2
            ? this.appLaunchesArray[2]["list"]
            : null
        ) {
          this.appLaunchesThirdMonthArray = this.appLaunchesArray[2]["list"];

          this.appLaunchesThirdMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.appLaunchesAndroidThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.appLaunchesIosThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.appLaunchesTvThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.appLaunchestvIosThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.appLaunchestvAmazonThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.appLaunchesRokuThirdMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.appLaunchesGoogleThirdMonth = Math.floor(el.count);
            } else {
              this.appLaunchesWebThirdMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.appLaunchesThirdDateMonth,
            android: this.appLaunchesAndroidThirdMonth,
            ios: this.appLaunchesIosThirdMonth,
            web: this.appLaunchesWebThirdMonth,
            tv: this.appLaunchesTvThirdMonth,
            IOS_TV: this.appLaunchestvIosThirdMonth,
            Amazon_TV: this.appLaunchestvAmazonThirdMonth,
            google: this.appLaunchesGoogleThirdMonth,

            roku: this.appLaunchesRokuThirdMonth,
          };

          this.appLaunchArr.push(data);

          // this.thirdMonthDataArray = [this.androidThirdMonth, this.iosThirdMonth, this.tvThirdMonth, this.webThirdMonth]
        }
        if (
          this.appLaunchesArray.length > 3
            ? this.appLaunchesArray[3]["date"]
            : null
        ) {
          this.appLaunchesFourthDateMonth = this.appLaunchesArray[3]["date"];
          this.appLaunchesFourthDateMonth = moment(
            this.appLaunchesFourthDateMonth
          ).format(format1);
        }
        if (
          this.appLaunchesArray.length > 3
            ? this.appLaunchesArray[3]["list"]
            : null
        ) {
          this.appLaunchesFourthMonthArray = this.appLaunchesArray[3]["list"];

          this.appLaunchesFourthMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.appLaunchesAndroidFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.appLaunchesIosFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.appLaunchesTvFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.appLaunchestvIosFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.appLaunchestvAmazonFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.appLaunchesRokuFourthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.appLaunchesGoogleFourthMonth = Math.floor(el.count);
            } else {
              this.appLaunchesWebFourthMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.appLaunchesFourthDateMonth,
            android: this.appLaunchesAndroidFourthMonth,
            ios: this.appLaunchesIosFourthMonth,
            web: this.appLaunchesWebFourthMonth,
            tv: this.appLaunchesTvFourthMonth,
            IOS_TV: this.appLaunchestvIosFourthMonth,
            Amazon_TV: this.appLaunchestvAmazonFourthMonth,
            google: this.appLaunchesGoogleFourthMonth,

            roku: this.appLaunchesRokuFourthMonth,
          };

          this.appLaunchArr.push(data);
        }
        if (
          this.appLaunchesArray.length > 4
            ? this.appLaunchesArray[4]["date"]
            : null
        ) {
          this.appLaunchesFifthDateMonth = this.appLaunchesArray[4]["date"];
          this.appLaunchesFifthDateMonth = moment(
            this.appLaunchesFifthDateMonth
          ).format(format1);
        }
        if (
          this.appLaunchesArray.length > 4
            ? this.appLaunchesArray[4]["list"]
            : null
        ) {
          this.appLaunchesFifthMonthArray = this.appLaunchesArray[4]["list"];

          this.appLaunchesFifthMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.appLaunchesAndroidFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.appLaunchesIosFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.appLaunchesTvFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.appLaunchestvIosFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.appLaunchestvAmazonFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.appLaunchesRokuFifthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.appLaunchesGoogleFifthMonth = Math.floor(el.count);
            } else {
              this.appLaunchesWebFifthMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.appLaunchesFifthDateMonth,
            android: this.appLaunchesAndroidFifthMonth,
            ios: this.appLaunchesIosFifthMonth,
            web: this.appLaunchesWebFifthMonth,
            tv: this.appLaunchesTvFifthMonth,
            IOS_TV: this.appLaunchestvIosFifthMonth,
            Amazon_TV: this.appLaunchestvAmazonFifthMonth,
            google: this.appLaunchesGoogleFifthMonth,

            roku: this.appLaunchesRokuFifthMonth,
          };

          this.appLaunchArr.push(data);
        }

        // FIFTH MONTH

        if (
          this.appLaunchesArray.length > 4
            ? this.appLaunchesArray[5]["date"]
            : null
        ) {
          this.appLaunchesSixthDateMonth = this.appLaunchesArray[5]["date"];
          this.appLaunchesSixthDateMonth = moment(
            this.appLaunchesSixthDateMonth
          ).format(format1);
        }
        if (
          this.appLaunchesArray.length > 4
            ? this.appLaunchesArray[5]["list"]
            : null
        ) {
          this.appLaunchesFifthMonthArray = this.appLaunchesArray[5]["list"];

          this.appLaunchesFifthMonthArray.forEach((el) => {
            if (el.deviceType === "MOBILE_ANDROID") {
              this.appLaunchesAndroidSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "MOBILE_IOS") {
              this.appLaunchesIosSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_ANDROID") {
              this.appLaunchesTvSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_IOS") {
              this.appLaunchestvIosSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_AMAZON") {
              this.appLaunchestvAmazonSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "ROKU") {
              this.appLaunchesRokuSixthMonth = Math.floor(el.count);
            } else if (el.deviceType === "TV_GOOGLE") {
              this.appLaunchesGoogleSixthMonth = Math.floor(el.count);
            } else {
              this.appLaunchesWebFifthMonth = Math.floor(el.count);
            }
          });

          let data = {};
          data = {
            month: this.appLaunchesSixthDateMonth,
            android: this.appLaunchesAndroidSixthMonth,
            ios: this.appLaunchesIosSixthMonth,
            web: this.appLaunchesWebSixthMonth,
            tv: this.appLaunchesTvSixthMonth,
            IOS_TV: this.appLaunchestvIosSixthMonth,
            Amazon_TV: this.appLaunchestvAmazonSixthMonth,
            google: this.appLaunchesGoogleSixthMonth,

            roku: this.appLaunchesRokuSixthMonth,
          };

          this.appLaunchArr.push(data);
        }

        // if(this.appDownloadsArray[5]['date']){
        //   this.sixthDateMonth = this.appDownloadsArray[5]['date']
        //   this.sixthDateMonth = moment(this.firstDateMonth).format(format1);
        // }
        // if(this.appDownloadsArray[5]['list']){
        // this.sixthMonthArray = this.appDownloadsArray[5]['list']

        // // this.firstDateMonth = moment(this.firstDateMonth).format(format1);

        // this.sixthMonthArray.forEach((el) => {
        //   if(el.deviceType === 'ANDROID'){
        //     this.androidSixthMonth = Math.floor(el.count)
        //   }
        //   else if(el.deviceType === 'IOS'){
        //     this.iosSixthMonth = Math.floor(el.count)
        //   }
        //   else if(el.deviceType === 'TV'){
        //     this.tvSixthMonth = Math.floor(el.count)
        //   }
        //    else{
        //     this.webSixthMonth = Math.floor(el.count)
        //   }
        // } )

        // this.sixthMonthDataArray = [this.androidSixthMonth,this.iosSixthMonth,this.tvSixthMonth,this.webSixthMonth]
        // }

        this.appLaunchesFirstMonthDataArray = [
          this.appLaunchesAndroidFirstMonth,
          this.appLaunchesAndroidSecondMonth,
          this.appLaunchesAndroidThirdMonth,
          this.appLaunchesAndroidFourthMonth,
          this.appLaunchesAndroidFifthMonth,
          this.appLaunchesAndroidSixthMonth,
        ];
        this.appLaunchesSecondMonthDataArray = [
          this.appLaunchesIosFirstMonth,
          this.appLaunchesIosSecondMonth,
          this.appLaunchesIosThirdMonth,
          this.appLaunchesIosFourthMonth,
          this.appLaunchesIosFifthMonth,
          this.appLaunchesIosSixthMonth,
        ];
        this.appLaunchesThirdMonthDataArray = [
          this.appLaunchesTvFirstMonth,
          this.appLaunchesTvSecondMonth,
          this.appLaunchesTvThirdMonth,
          this.appLaunchesTvFourthMonth,
          this.appLaunchesTvFifthMonth,
          this.appLaunchesTvSixthMonth,
        ];
        this.appLaunchesFourthMonthDataArray = [
          this.appLaunchesWebFirstMonth,
          this.appLaunchesWebSecondMonth,
          this.appLaunchesWebThirdMonth,
          this.appLaunchesWebFourthMonth,
          this.appLaunchesWebFifthMonth,
          this.appLaunchesWebSixthMonth,
        ];
        this.appLaunchesFifthMonthDataArray = [
          this.appLaunchesRokuFirstMonth,
          this.appLaunchesRokuSecondMonth,
          this.appLaunchesRokuThirdMonth,
          this.appLaunchesRokuFourthMonth,
          this.appLaunchesRokuFifthMonth,
          this.appLaunchesRokuSixthMonth,
        ];

        this.appLaunchesSixthMonthDataArray = [
          this.appLaunchestvIosFirstMonth,
          this.appLaunchestvIosSecondMonth,
          this.appLaunchestvIosThirdMonth,
          this.appLaunchestvIosFourthMonth,
          this.appLaunchestvIosFifthMonth,
          this.appLaunchestvIosSixthMonth,
        ];
        this.appLaunchesSeventhMonthDataArray = [
          this.appLaunchestvAmazonFirstMonth,
          this.appLaunchestvAmazonSecondMonth,
          this.appLaunchestvAmazonThirdMonth,
          this.appLaunchestvAmazonFourthMonth,
          this.appLaunchestvAmazonFifthMonth,
          this.appLaunchestvAmazonSixthMonth,
        ];
        this.appLaunchesEightMonthDataArray = [
          this.appLaunchesGoogleFirstMonth,
          this.appLaunchesGoogleSecondMonth,
          this.appLaunchesGoogleThirdMonth,
          this.appLaunchesGoogleFourthMonth,
          this.appLaunchesGoogleFifthMonth,
          this.appLaunchesGoogleSixthMonth,
        ];
        this.appLaunchesData = this.creteAppLaunchesChartData(
          this.appLaunchesFirstDateMonth,
          this.appLaunchesSecondDateMonth,
          this.appLaunchesThirdDateMonth,
          this.appLaunchesFourthDateMonth,
          this.appLaunchesFifthDateMonth,
          this.sixthDateMonth,
          this.appLaunchesFirstMonthDataArray,
          this.appLaunchesSecondMonthDataArray,
          this.appLaunchesThirdMonthDataArray,
          this.appLaunchesFourthMonthDataArray,
          this.appLaunchesFifthMonthDataArray,
          this.appLaunchesSixthMonthDataArray,
          this.appLaunchesSeventhMonthDataArray,
          this.appLaunchesEightMonthDataArray
        );

        if (!this.mobileAppAndroid) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "ANDROID MOBILE ";
          });
        }
        if (!this.mobileAppIos) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "APPLE MOBILE";
          });
        }
        console.log("tvAppAndroidTV:", this.tvAppAndroidTV);
        console.log("Original datasets:", this.appLaunchesData["datasets"]);
        if (!this.tvAppAndroidTV) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "ANDROID TV";
          });
        }
        if (!this.tvAppAmazonTV) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "AMAZON FIRE TV";
          });
        }

        if (!this.tvAppIOSTV) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "APPLE TV";
          });
        }
        if (!this.tvAppRoku) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "ROKU TV";
          });
        }
        if (!this.tvAppGoogleTV) {
          this.appLaunchesData["datasets"] = this.appLaunchesData[
            "datasets"
          ].filter((element) => {
            return element.label !== "GOOGLE TV";
          });
        }
        console.log("Filtered datasets:", this.appLaunchesData["datasets"]);

        // this.lineChartData = this.appLaunchesData['datasets']
        // this.lineChartLabels = this.appLaunchesData['labels']

        // this.getAppLaunchesGraph(this.appLaunchArr)
      }
      this.sharedService.updateLoader(false);
      // this.getAppLaunchesGraph(this.appLaunchArr)
    });
    this.sharedService.updateLoader(false);
  }

  getMediaPlayCountData(data) {
    // moment(this.appLaunchesFirstDateMonth).format(format1);
    if (data.length > 0) {
      this.mediaPlayTotalCount = data[0].totalCount;
    }
    var newMap = data.map((el) => ({
      // date : moment(e.date).format('MMM d, y'),
      labels: moment(el.date).format("MMM, YYYY"),
      total: el.total,
      platforms: el.platforms,
    }));
    console.log(newMap);

    // if (this.featureControlService.showWeb) {
    this.basicData2 = {
      labels: [],
      datasets: [
        {
          label: "TV",
          data: [],
          fill: true,
          // borderColor: "#ffb600",
          // backgroundColor: "#ffdb80",
          borderColor: "#930101",
          backgroundColor: "#ffb3b3",
          tension: 0.4,
        },
        {
          label: "Mobile",
          data: [],
          fill: true,
          borderColor: "#00008B",
          backgroundColor: "#ccccff",
          tension: 0.4,
        },

        {
          label: "Website",
          data: [],
          fill: true,
          // borderColor: "#3399ff",
          // backgroundColor: "#99ccff",
          // tension: 0.4,
          borderColor: "#0b6623",
          tension: 0.4,
          backgroundColor: "#8cf2a7",
        },
      ],
    };
    // }

    if (
      (!this.tvAppAndroidTV && !this.tvAppIOSTV && !this.tvAppRoku) ||
      !this.featureControlService.showTvApp
    ) {
      this.basicData2["datasets"] = this.basicData2["datasets"].filter(
        (element) => {
          return element.label !== "TV";
        }
      );
    }
    if (!this.mobileAppAndroid && !this.mobileAppIos) {
      this.basicData2["datasets"] = this.basicData2["datasets"].filter(
        (element) => {
          return element.label !== "Mobile";
        }
      );
    }
    if (!this.featureControlService.showWeb) {
      this.basicData2["datasets"] = this.basicData2["datasets"].filter(
        (element) => {
          return element.label !== "Website";
        }
      );
    }
    newMap.forEach((el) => {
      el["allMobileData"] =
        el.platforms.MOBILE_ANDROID + el.platforms.MOBILE_IOS;
      el["allTvData"] =
        el.platforms.ROKU +
        el.platforms.TV_ANDROID +
        el.platforms.TV_IOS +
        el.platforms.TV_AMAZON +
        el.platforms.TV_GOOGLE;
      el["websiteData"] = el.platforms.WEBSITE;
    });
    console.log(newMap);

    newMap.splice(0, 1);
    newMap.forEach((element) => {
      this.basicData2.labels.push(element.labels);
      // this.basicData2.datasets[0].data.push(element.total)
      this.basicData2.datasets.forEach((el) => {
        if (el.label === "TV") {
          el.data.push(element.allTvData);
        } else if (el.label === "Mobile") {
          el.data.push(element.allMobileData);
        } else if (el.label === "Website") {
          el.data.push(element.websiteData);
        }
      });
    });
    console.log(this.basicData2);
  }
  kFormatter = (num) => {
    return Math.abs(num) > 999
      ? (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  getAllDaashboardData() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getAllDaashboardData().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      this.all_data = response.data;
      this.allRevenue = this.kFormatter(response.data.Revenue);
      if (response.data) {
        this.getMediaPlayCountData(this.all_data.Media_PlayCount.body.data);
      }
      this.Total_Contacts = response.data.Total_Contacts;
      // if(response.data.Total_Contacts>0){
      //   this.Total_ContactsIntervels = setInterval(()=>{
      //     if(this.Total_Contacts === response.data.Total_Contacts){
      //       clearInterval(this.Total_ContactsIntervels)
      //     }
      //   },10)
      // }
      this.Monthly_Lead_Count = response.data.Monthly_Lead_Count;
      // if(response.data.Monthly_Lead_Count>0){
      //   this.Monthly_Lead_CountIntervels = setInterval(()=>{
      //     this.Monthly_Lead_Count++;
      //     if(this.Monthly_Lead_Count === response.data.Monthly_Lead_Count){
      //       clearInterval(this.Monthly_Lead_CountIntervels)
      //     }
      //   },10)
      // }
      this.Monthly_Giving_Amount = response.data.Monthly_Giving_Amount;
      // if(response.data.Monthly_Giving_Amount>0){
      //   this.Monthly_Giving_AmountIntervels = setInterval(()=>{
      //     this.Monthly_Giving_Amount++;
      //     if(this.Monthly_Giving_Amount === response.data.Monthly_Giving_Amount){
      //       clearInterval(this.Monthly_Giving_AmountIntervels)
      //     }
      //   },10)
      // }
    });
    this.sharedService.updateLoader(false);
  }

  getTransactionalSummary(type) {
    this.emptyData = false;
    this.sharedService.updateLoader(false);
    this.dashboardService.getTransactionalSummary(type).subscribe(
      (response: any) => {
        this.showLoader = false;
        this.sharedService.updateLoader(false);
        this.transactionalSummary_data = response.data;
        if (this.transactionalSummary_data.length > 0) {
          this.transactionalSummary_data.forEach((el) => {
            el["userImg"] = el.profilePictureId
              ? `${appConfig.imageURL}` +
                el.profilePictureId +
                "?height=100&width=100"
              : null;
          });
        }
        if (this.transactionalSummary_data.length === 0) {
          this.emptyData = true;
        }
      },
      (error) => {
        this.showLoader = false;
        this.sharedService.updateLoader(false);
      }
    );
    this.sharedService.updateLoader(false);
  }

  getPromotionalImageData() {
    this.sharedService.updateLoader(false);
    this.dashboardService.getPromotionalData().subscribe((response: any) => {
      this.sharedService.updateLoader(false);
      this.promotionImage = response.data
        ? `${appConfig.imageURL}` +
          response.data.documentId +
          "?height=" +
          "430" +
          "&width=" +
          "1200"
        : null;
      this.promotionaImageBgColor = response.data
        ? response.data.imageColor
        : "";
      this.showPromotion = response.data.documentId ? true : false;
      this.promotionalArtworkId = response.data
        ? response.data.artworkId
        : null;
      this.documentId = response.data ? response.data.documentId : null;
      this.promotionalLink = response.data.promotionalLink
        ? response.data.promotionalLink
        : "";
    });
    this.sharedService.updateLoader(false);
  }

  openLink() {
    if (this.promotionalLink) {
      var url = this.promotionalLink;
      window.open(url, "_blank");
    }
  }

  deleteImage(id) {
    this.dashboardService.deletePromotionalImage(id).subscribe(
      () => {
        this.getPromotionalImageData();
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Media Series deleted Successfully.' });
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Something went wrong.",
        });
      }
    );
  }

  route(type) {
    if (type === "contact") {
      this.router.navigate(["contacts-module/contacts"]);
    } else if (type === "leads") {
      this.router.navigate(["social-module/social/lead-ad-forms"]);
    } else if (type === "giving") {
      this.router.navigate(["giving-module/giving/giving-list"]);
    } else if (type === "inTrialSubs") {
      this.router.navigate([
        "subscription-module/subscription-m/subscription-plan",
      ]);
    } else if (type === "paidSubs") {
      this.router.navigate([
        "subscription-module/subscription-m/subscribed-user",
      ]);
    } else if (type === "currentMrr") {
      this.router.navigate([
        "subscription-module/subscription-m/subscription-plan",
      ]);
    } else if (type === "yearMrr") {
      this.router.navigate([
        "subscription-module/subscription-m/subscription-plan",
      ]);
    }
  }

  activeTransaction(type) {
    this.showLoader = true;
    this.transactionalSummary_data = [];
    if (type === "user") {
      this.showUserTransaction = true;
      this.showGivingTransaction = false;
      this.showOneTimeTransaction = false;
      this.showAllTransaction = false;
      this.transactionalSummaryType = "PAID_SUBSCRIBER";
      this.getTransactionalSummary(this.transactionalSummaryType);
    } else if (type === "giving") {
      this.showUserTransaction = false;
      this.showGivingTransaction = true;
      this.showOneTimeTransaction = false;
      this.showAllTransaction = false;
      this.transactionalSummaryType = "GIVING_USERS";
      this.getTransactionalSummary(this.transactionalSummaryType);
    } else if (type === "oneTime") {
      this.showUserTransaction = false;
      this.showGivingTransaction = false;
      this.showOneTimeTransaction = true;
      this.showAllTransaction = false;
      this.transactionalSummaryType = "ONE_TIME_PAID_SUBSCRIBER";
      this.getTransactionalSummary(this.transactionalSummaryType);
    } else {
      this.showUserTransaction = false;
      this.showGivingTransaction = false;
      this.showOneTimeTransaction = false;
      this.showAllTransaction = true;
      this.transactionalSummaryType = "ALL";
      this.getTransactionalSummary(this.transactionalSummaryType);
    }
  }

  activegraph(type) {
    if (type === "mobile") {
      this.showAllbarGraph = false;
      this.showTvGraph = false;
      this.showMobileGraph = true;
    } else if (type === "tv") {
      this.showAllbarGraph = false;
      this.showTvGraph = true;
      this.showMobileGraph = false;
    } else {
      this.showAllbarGraph = true;
      this.showTvGraph = false;
      this.showMobileGraph = false;
    }
  }

  seeAllFromTransaction(type) {
    if (type === "PAID_SUBSCRIBER") {
      this.router.navigate(["subscription-module/subscription-m/subscribed-user"]);
    } else if (type === "GIVING_USERS") {
      this.router.navigate(["giving-module/giving/giving-list"]);
    } else if (type === "ONE_TIME_PAID_SUBSCRIBER") {
      this.router.navigate([
        "subscription-module/subscription-m/onetime-paidsubscriber",
      ]);
    }
  }

  routeToUsers(data) {
    if (
      data.summaryType === "USER_SUBSCRIPTION" ||
      data.summaryType === "PAID_SUBSCRIBER"
    ) {
      this.router.navigate(
        [`/contacts-module/contacts/add-contacts/${data.userId}`],
        { queryParams: { subscribeUser: true, dashboard: true } }
      );
    } else if (
      data.summaryType === "GIVING" ||
      data.summaryType === "GIVING_USERS"
    ) {
      this.router.navigate(
        [`giving-module/giving/giving-details/${data.givingId}`],
        { queryParams: { dashboard: true } }
      );
    } else if (
      data.summaryType === "MEDIA_ITEM" ||
      data.summaryType === "ONE_TIME_PAID_SUBSCRIBER"
    ) {
      this.router.navigate(
        [`/contacts-module/contacts/add-contacts/${data.userId}`],
        { queryParams: { subscribeUserOnetime: true, dashboard: true } }
      );
    }
  }

  routeToItems(data) {
    if (data.item.itemType === "LIVE_STREAMING") {
      this.router.navigate(
        [`/media-module/media/live-streaming/edit-live-stream/${data.item.id}`],
        { queryParams: { dashboard: true } }
      );
    } else if (data.item.itemType === "MEDIA_ITEM") {
      this.router.navigate(
        [`/media-module/media/on-demand/edit-media-item/${data.item.id}`],
        { queryParams: { dashboard: true } }
      );
    }
  }

  routeToAnalytic(type) {
    this.router.navigate(["apps-module/apps/analytics"], {
      queryParams: { clickFrom: type },
    });
  }

  openPromoLink(promotionalLink) {
    if (promotionalLink) {
      window.open(promotionalLink, "_blank");
    }
  }
}
