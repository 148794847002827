<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    <div *ngIf="medLib">

        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between align-items-center p-4">
                <h4>Media Library Embed</h4>
                <img class="cross-icon-img" src="./../../../../assets/images/library/close.svg" class=""
                    style="cursor: pointer;" (click)="closePanel()" alt="">
            </div>
        </div>
        <div class="panel-content">
            <p class="info-title">Type</p>
            <div (click)="typeLib()" [class.border-active]="mainLib" class="carousel-box px-3 py-4">

                <p class="para-text mb-0"> <span class="para-heading">Media Library-</span> Display your Media Library
                    with custom settings</p>
            </div>
            <div (click)="typeList()" [class.border-active]="mainSer" class="carousel-box px-3 py-4 my-3">

                <p class="para-text mb-0"> <span class="para-heading">Video Series or List-</span> Display a specific
                    Video Series or List</p>
            </div>

            <div *ngIf="mainLib" >
                <p class="info-title">
                    Filter
                </p>
                <div (click)="showMedLibSettings()"
                    class="google-play-container p-2 my-3  d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="sq-img-back d-flex justify-content-center align-items-center">
                            <span>

                            </span>
                        </div>
                        <div class="d-flex flex-column px-3">

                            <span class="para-heading">Video Series</span>
                            <p class="para-text mb-0">Newest First Grid</p>
                        </div>
                    </div>
                    <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                </div>
            </div>

            <div *ngIf="mainSer" >

                <p class="info-title">
                    Content Source
                </p>
                <div (click)="openPanel('mediaLib')"
                    class="google-play-container p-2 my-3  d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="sq-img-back d-flex justify-content-center align-items-center">
                            <span>

                            </span>
                        </div>
                        <div class="d-flex flex-column px-3">
                            <span class="para-heading">Content Source</span>
                            <!-- <span class="para-heading" >Media Series</span>
                                            <p class="para-text mb-0">Newest First Grid</p> -->
                        </div>
                    </div>
                    <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                </div>
            </div>
            <p class="info-title">
                Options
            </p>
            <div class="d-flex align-items-center mb-3">
                <input type="checkbox" name="" id="">
                <p class="para-text mb-0 px-2">Display NOWCAST branding below content</p>
            </div>


            <div *ngIf="mainLib" >
                <p class="info-title">
                    Embed Code
                </p>
                <p class="para-text my-3">
                    Copy and paste the embed code below into your website wherever you would like it to be shown.
                </p>

                <a>
                    Learn more about embed codes
                </a>

                <div class="form-group my-3">

                    <span class="app-password app-copy">
                        <input type="text" class="form-control" [(ngModel)]="code" readonly>
                        <em class="password-icon" [appSvgIcon]="'copy'"></em>
                    </span>
                </div>

            </div>

        </div>

    </div>

    <div *ngIf="medLibSettings">

        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between align-items-center p-4">
                <h4>Media Library Settings</h4>
                <!-- <img class="cross-icon-img"  src="./../../../../assets/images/library/close.svg"
             class="" style="cursor: pointer;" (click)="closePanel()" alt=""> -->
            </div>
        </div>
        <div class="app-filters d-flex pt-1 justify-content-center">
            <button class="btn btn-white">Apply</button>
            <button (click)="closeMedLibSettings()" class="btn btn-white">Cancel</button>
        </div>

        <div class="panel-content">
            <label for="">Display by</label>
            <div>
                <div class="d-flex align-items-center pt-2  mb-0">
                    <input type="radio" name="display" id="" checked>
                    <p class="para-text mb-0 px-2">
                        Video Series
                    </p>
                </div>
                <div class="d-flex align-items-center py-2">
                    <input type="radio" name="display" id="">
                    <p class="para-text mb-0 px-2">
                        Video
                    </p>
                </div>
            </div>
            <label for="">Maximum items shown</label>
            <div>
                <div class="d-flex align-items-center pt-2 mb-0">
                    <input type="radio" name="items" id="" checked>
                    <p class="para-text mb-0 px-2">
                        All
                    </p>
                </div>
                <div class="d-flex align-items-center ">
                    <input type="radio" name="items" id="">
                    <p class="para-text mb-0 px-2">
                        Custom (up to 24)

                    </p>
                    <div style="width: 50px;" class="form-group mb-0">
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
            <label for="">Sort by</label>
            <div>
                <div class="d-flex align-items-center pt-2  mb-0">
                    <input type="radio" name="sort" id="" checked>
                    <p class="para-text mb-0 px-2">
                        Date created (Newest First)
                    </p>
                </div>
                <div class="d-flex align-items-center py-2">
                    <input type="radio" name="sort" id="">
                    <p class="para-text mb-0 px-2">
                        Date created (Oldest First)
                    </p>
                </div>
                <div class="d-flex align-items-center pb-2">
                    <input type="radio" name="sort" id="">
                    <p class="para-text mb-0 px-2">
                        A-Z
                    </p>
                </div>
            </div>
            <label for="">Layout</label>
            <div>
                <div class="d-flex align-items-center pt-2  mb-0">
                    <input type="radio" name="layout" checked id="">
                    <p class="para-text mb-0 px-2">
                        Grid
                    </p>
                </div>
                <div class="d-flex align-items-center py-2">
                    <input type="radio" name="layout" id="">
                    <p class="para-text mb-0 px-2">
                        Rows
                    </p>
                </div>
            </div>

        </div>

    </div>


</div>

<app-add-to-list></app-add-to-list>