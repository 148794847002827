import { MessageService } from 'primeng/api';
import { AuthenticateService } from './../../../services/authenticateService/authenticate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassword } from './../../../models/authenticate.model';
import { Component, OnInit } from '@angular/core';
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  showToken = false
  routeParams: any;
  passwordType = false;
  confirmPasswordType = false;
  resetPassword: ResetPassword;
  showThanks = false
  orgName: any;
  logoImage: any;
  logoSrcFound: boolean;
  logoId: any;
  brandColor: string;
  defaultSrc = " "
  token: string;
  created: string;
  subDomain:string
  showWebsite : boolean
  constructor(
    public route: ActivatedRoute,
    public authenticateService: AuthenticateService,
    public messageService: MessageService,
    public router: Router
  ) {
    this.resetPassword = new ResetPassword();
  }

  ngOnInit(): void {

    // console.log(this.getbackgroundBrandColor(this.token));

    this.route.queryParams.subscribe((params) => {
      this.routeParams = params;
      this.token = params['token'];
      console.log(this.token);
      this.getbackgroundBrandColor()
      this.logoId = params['logoId'];
      this.created = params['created'] ? params['created'] : 'user';
      this.logoId = `${appConfig.imageURL}` + this.logoId
      if (this.logoId) {
        this.logoSrcFound = true;
      }
      else {
        this.logoSrcFound = false;
      }
      // this.brandColor = '#'+ params['brandColor'];
      console.log(this.brandColor);


      this.orgName = this.routeParams.orgName
      if (this.routeParams.accept === 'true') {

        this.acceptInvite(this.routeParams.id)
      }

      this.checkTokenValidate(this.routeParams.id, this.token)

    });
  }

  /* Accept Invite */

  acceptInvite(id) {
    this.authenticateService.acceptInvite(id).subscribe((response: any) => {
      console.log(response)
    })
  }


  /**
   * To reset password
   */
  reset() {
    if (this.resetPassword.password.length >= 6) {
      if (this.resetPassword.confirmPassword.length >= 6) {
        if (this.resetPassword.password === this.resetPassword.confirmPassword) {
          this.resetPassword.token = this.routeParams.token;
          this.authenticateService.resetPassword(this.resetPassword, this.routeParams.id).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password set successfully' });
            if (this.routeParams.created === 'user') {
              this.showThanks = true;
            }
            else {
              localStorage.clear();
              this.router.navigate(['/login']);
            }
          }, (error) => {
            let expiredTokenExist;
            if (error.error && Array.isArray(error.error.errors)) {
              expiredTokenExist = error.error.errors.some((el) => el.fieldCode === 'Expired');
            }
            this.messageService.add({ severity: 'error', summary: 'Error', detail: expiredTokenExist ? 'Token Expired' : error.error.message });
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Password does not match' });
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Confirm Password should be of minimum 6 characters' });
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Password should be of minimum 6 characters' });
    }
  }


  getbackgroundBrandColor() {
    this.authenticateService.getBrandingbgColor(this.token).subscribe((res: any) => {
      this.brandColor = res.data.brandingColor
      this.subDomain = res.data.subDomain
      console.log(this.subDomain);  
      console.log(this.brandColor);
      if (this.subDomain) {
        this.showWebsite = true
      }
      else {
        this.showWebsite = false
      }
    }, (err: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err.err.message });
    }
    )
  }

  openLink(){
    var subDomainUrl = this.subDomain

    if (subDomainUrl.includes("https://")){
      window.open(subDomainUrl , '_blank')
    }
    else{
      window.open("https://" + subDomainUrl, '_blank');
    }
  }


  checkTokenValidate(id, token) {
    this.authenticateService.checkTokenValidate(id, token).subscribe((res: any) => {
      if (res.body.data === true) {
        this.showToken = true
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Token is expired' });
      }
    }, (err: any) => {
      // this.messageService.add({ severity: 'error', summary: 'Error', detail:err.err.message });
      if (err.error.errors[0].fieldError == 'Token is expired') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.errors[0].fieldError });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.message });
      }
    }
    )
  }



}
