<p-dialog *ngIf="showStorageModal" class="main-stepper-div" header="Info" [(visible)]="showStorageModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false" [closeIcon]="false">    
        <p>The data upload limit has increased to more than {{ percentageStorage > 100 ? '100': percentageStorage}}%. To raise your upload limit, Please <span class="link" routerLink="/settings-module/settings/general-settings" (click)="showStorageModal = false; showVodModal = false; showLiveStreamModal = false;">click here</span>.</p>
    <p-footer>
        <!-- <button type="button" class="btn btn-white" (click)="showConfirmModal=true">Save</button> -->
        <button type="button" class="btn btn-white" (click)="showStorageModal = false">Ok</button>
    </p-footer>
</p-dialog>

<p-dialog *ngIf="showVodModal" class="main-stepper-div" header="Info" [(visible)]="showVodModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false" [closeIcon]="false">    
        <p>
            The VOD data usage limit has increased to more than {{ percentageVodData > 100 ? '100': percentageVodData }}%. To raise your usage limit, Please <span class="link" routerLink="/settings-module/settings/general-settings" (click)="showVodModal = false; showStorageModal = false; showLiveStreamModal = false;">click here</span>.</p>
    <p-footer>
        <!-- <button type="button" class="btn btn-white" (click)="showConfirmModal=true">Save</button> -->
        <button type="button" class="btn btn-white" (click)="showVodModal = false">Ok</button>
    </p-footer>
</p-dialog>

<p-dialog *ngIf="showLiveStreamModal" class="main-stepper-div" header="Info" [(visible)]="showLiveStreamModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false" [closeIcon]="false">    
        <p>
            The Live Stream usage limit has increased to more than {{ percentageLiveUsageData > 100 ? '100': percentageLiveUsageData }}%. To raise your usage limit, Please <span class="link" routerLink="/settings-module/settings/general-settings" (click)="showVodModal = false; showStorageModal = false; showLiveStreamModal = false;">click here</span>.</p>
    <p-footer>
        <!-- <button type="button" class="btn btn-white" (click)="showConfirmModal=true">Save</button> -->
        <button type="button" class="btn btn-white" (click)="showLiveStreamModal = false">Ok</button>
    </p-footer>
</p-dialog>