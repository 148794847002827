export class MediaItem {
  title: string;
  mediaSeriesId: string;
  itemType: string;
  constructor() {
    this.mediaSeriesId = null;
  }
}

export class SeriesItem {
  title: string;
  // mediaSeriesId: string;
  seriesType: string;
  status: string;
  constructor() {
    // this.mediaSeriesId = null;
  }
}

export class SongItem {
  title: string;
  albumId: string;

  constructor() {
    this.albumId = null;
  }
}

export class LiveStream {
  title: string;
  mediaSeriesId: string;
  itemType: string;
  liveStreamDataDTO = new LiveStreamDataDto();
  constructor(obj?) {
    this.mediaSeriesId = null;
    this.liveStreamDataDTO =
      obj && obj.liveStreamDataDTO
        ? obj.liveStreamDataDTO
        : new LiveStreamDataDto();
  }
}

export class EditMediaItem {
  isOneTimePurchase: boolean;
  price: any;
  additionalLabel: string;
  audioId: number;
  bannerArtworkId: number;
  date: any;
  description: string;
  documentId: number;
  document: any;
  documentTitle: string;
  speaker: string;
  squareArtworkId: number;
  subTitle: string;
  title: string;
  videoId: number;
  tvLogoId: number;
  tvLogoName: any;
  webLinkDTO = new WebLinkDto();
  liveStreamDataDTO = new LiveStreamDataDto();
  wideArtworkId: number;
  videoDTO: any;
  audioDTO: any;
  bannerArtwork: any;
  squareArtwork: any;
  wideArtwork: any;
  publishedDate: any;
  isPaid: string;
  isVideo: string;
  downloads: boolean;
  notes: boolean;
  subscriptionPlanIds: Array<number>;
  audioUrl: string;
  audioUrlType: string;
  videoUrl: string;
  videouUrlType: string;
  scheduleDate: any;
  scheduleTime: any;
  isGivingEnabled: boolean;
  mediaAccessType: any;
  itemType: string;
  isPublished: boolean;
  status: string;
  isYoutubeUrl : string
  constructor(obj?) {
    this.price = obj && obj.price ? obj.price : null;
    this.isOneTimePurchase =
      obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false;
    this.additionalLabel =
      obj && obj.additionalLabel ? obj.additionalLabel : "";
    this.audioId = obj && obj.audioId ? obj.audioId : "";
    this.bannerArtworkId =
      obj && obj.bannerArtworkId ? obj.bannerArtworkId : null;
    this.isGivingEnabled =
      obj && obj.isGivingEnabled ? obj.isGivingEnabled : false;
    this.downloads = obj && obj.downloads ? obj.downloads : false;
    this.notes = obj && obj.notes ? obj.notes : false;
    // this.date = obj && obj.date ? obj.date : new Date();
    this.date = obj && obj.date ? obj.date : null;
    this.description = obj && obj.description ? obj.description : "";
    this.documentId = obj && obj.documentId ? obj.documentId : null;
    this.document = obj && obj.document ? obj.document : null;
    this.documentTitle = obj && obj.documentTitle ? obj.documentTitle : "";
    this.speaker = obj && obj.speaker ? obj.speaker : "";
    this.squareArtworkId =
      obj && obj.squareArtworkId ? obj.squareArtworkId : null;
    this.subTitle = obj && obj.subTitle ? obj.subTitle : "";
    this.title = obj && obj.title ? obj.title : "";
    this.videoId = obj && obj.videoId ? obj.videoId : null;
    this.tvLogoId = obj && obj.tvLogoId ? obj.tvLogoId : null;
    this.webLinkDTO = obj && obj.webLinkDTO ? obj.webLinkDTO : new WebLinkDto();
    this.liveStreamDataDTO =
      obj && obj.liveStreamDataDTO
        ? obj.liveStreamDataDTO
        : new LiveStreamDataDto();
    this.wideArtworkId = obj && obj.wideArtworkId ? obj.wideArtworkId : null;
    this.videoDTO = obj && obj.videoDTO ? obj.videoDTO : null;
    this.audioDTO = obj && obj.audioDTO ? obj.audioDTO : null;
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
    this.isPaid = obj && obj.isPaid ? obj.isPaid : "false";
    this.isVideo = obj && obj.isVideo ? obj.isVideo : "false";
    this.isYoutubeUrl
      = obj && obj.isYoutubeUrl
        ? obj.isYoutubeUrl
        : "false";

    this.subscriptionPlanIds =
      obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
    this.audioUrl = obj && obj.audioUrl ? obj.audioUrl : "";
    this.audioUrlType = obj && obj.audioUrlType ? obj.audioUrlType : "LINK";
    this.videoUrl = obj && obj.videoUrl ? obj.videoUrl : null;
    this.videouUrlType = obj && obj.videouUrlType ? obj.videouUrlType : "LINK";
    this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
    this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
    this.mediaAccessType =
      obj && obj.mediaAccessType ? obj.mediaAccessType : "FREE";
    this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
    this.status = obj && obj.status ? obj.status : "";
    this.tvLogoName = obj && obj.tvLogoName ? obj.tvLogoName : "";
    this.itemType = obj && obj.itemType ? obj.itemType : "";
  }
}

export class LiveStreamDataDto {
  id: number;
  deviceType: string;
  enableFacebookLiveStream: boolean;
  enableYoutubeLiveStream: boolean;
  endTime: any;
  startTime: any;
  startDate: any;
  endDate: any;
  facebookSocialMediaTokenInfoId: number;
  // startDateTime : any;
  streamId: number;
  streamName: string;
  videoLiveType: string;
  youtubeSocialMediaTokenInfoId: number;
  videoId: number;
  videoDTO: any;
  liveStreamSettingId: number;
  m3u8Url: string;
  rtmpUrl: string;
  streamStatus: string;
  socialMediaTokenInfoDTO: any;
  // socialMediaType: string;
  youtubeSocialMediaTokenInfoDTO = new YoutubeSocialMediaTokenInfoDTO();
  socialMediaLiveStreamDTO: any;
  liveTypeDevice: string;
  isLandScape: boolean;
  landScapeType: string;
  validateMessage: string;
  constructor(obj?) {
    this.id = obj && obj.id ? obj.id : null;
    this.deviceType = obj && obj.deviceType ? obj.deviceType : "WEBSITE";
    this.enableFacebookLiveStream =
      obj && obj.enableFacebookLiveStream
        ? obj.enableFacebookLiveStream
        : false;
    this.enableYoutubeLiveStream =
      obj && obj.enableYoutubeLiveStream ? obj.enableYoutubeLiveStream : false;
    this.startDate = obj && obj.startDate ? obj.startDate : new Date();
    this.endDate = obj && obj.endDate ? obj.endDate : new Date();
    this.startTime = obj && obj.startTime ? obj.startTime : new Date();
    this.endTime = obj && obj.endTime ? obj.endTime : new Date();
    this.facebookSocialMediaTokenInfoId =
      obj && obj.facebookSocialMediaTokenInfoId
        ? obj.facebookSocialMediaTokenInfoId
        : null;
    // this.startDateTime = obj && obj.startDateTime ? obj.startDateTime : new Date();
    this.streamId = obj && obj.streamId ? obj.streamId : null;
    this.streamName = obj && obj.streamName ? obj.streamName : null;
    this.videoLiveType = obj && obj.videoLiveType ? obj.videoLiveType : "LIVE";
    this.youtubeSocialMediaTokenInfoId =
      obj && obj.youtubeSocialMediaTokenInfoId
        ? obj.youtubeSocialMediaTokenInfoId
        : null;
    this.videoId = obj && obj.videoId ? obj.videoId : null;
    this.videoDTO = obj && obj.videoDTO ? obj.videoDTO : null;
    this.liveStreamSettingId =
      obj && obj.liveStreamSettingId ? obj.liveStreamSettingId : null;
    this.m3u8Url = obj && obj.m3u8Url ? obj.m3u8Url : "";
    this.rtmpUrl = obj && obj.rtmpUrl ? obj.rtmpUrl : "";
    this.streamStatus = obj && obj.streamStatus ? obj.streamStatus : "NOTLIVE";
    this.socialMediaTokenInfoDTO =
      obj && obj.socialMediaTokenInfoDTO ? obj.socialMediaTokenInfoDTO : [];
    this.youtubeSocialMediaTokenInfoDTO =
      obj && obj.youtubeSocialMediaTokenInfoDTO
        ? obj.youtubeSocialMediaTokenInfoDTO
        : new YoutubeSocialMediaTokenInfoDTO();
    // this.socialMediaType =
    //   obj && obj.socialMediaType ? obj.socialMediaType : null;
    this.socialMediaLiveStreamDTO =
      obj && obj.socialMediaLiveStreamDTO
        ? obj.socialMediaLiveStreamDTO
        : new SocialMediaLiveStreamDTO();
    this.isLandScape = obj && obj.isLandScape ? obj.isLandScape : null;
    this.landScapeType = obj && obj.landScapeType ? obj.landScapeType : null;
    this.liveTypeDevice = obj && obj.liveTypeDevice ? obj.liveTypeDevice : null;
    this.validateMessage = obj && obj.validateMessage ? obj.validateMessage : null;
  }
}

export class SocialMediaLiveStreamDTO {
  rtmpUrl: string;
  socialMediaType: string;
  streamKey: string;
  constructor(obj?) {
    this.rtmpUrl = obj && obj.rtmpUrl ? obj.rtmpUrl : "";
    this.socialMediaType =
      obj && obj.socialMediaType ? obj.socialMediaType : null;
    this.streamKey = obj && obj.streamKey ? obj.streamKey : "";
  }
}

export class YoutubeSocialMediaTokenInfoDTO {
  id: number;
  pageName: string;
  profilePictureUrl: string;
  socialMediaAccountType: string;
  socialMediaType: string;
  userName: string;
  constructor(obj?) {
    this.id = obj && obj.id ? obj.id : null;
    this.pageName = obj && obj.pageName ? obj.pageName : null;
    this.profilePictureUrl =
      obj && obj.profilePictureUrl ? obj.profilePictureUrl : null;
    this.socialMediaAccountType =
      obj && obj.socialMediaAccountType ? obj.socialMediaAccountType : null;
    this.socialMediaType =
      obj && obj.socialMediaType ? obj.socialMediaType : null;
    this.userName = obj && obj.userName ? obj.userName : null;
  }
}

export class WebLinkDto {
  link: string;
  title: string;
}

export class EditMediaSeries {
  title: string;
  subTitle: string;
  squareArtworkId: number;
  wideArtworkId: number;
  bannerArtworkId: number;
  bannerArtwork: any;
  squareArtwork: any;
  wideArtwork: any;
  publishedDate: any;
  seriesType: any;
  isPublished: boolean;
  contents: any;
  status: string;
  scheduleDate: any;
  scheduleTime: any;
  constructor(obj?) {
    this.title = obj && obj.title ? obj.title : "";
    this.subTitle = obj && obj.subtitle ? obj.subtitle : "";
    this.squareArtworkId =
      obj && obj.squareArtwork ? obj.squareArtwork.id : null;
    this.wideArtworkId = obj && obj.wideArtworkId ? obj.bannerArtwork.id : null;
    this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.wideArtwork.id : "";
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
    this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
    this.contents = obj && obj.contents ? obj.contents : [];
    this.status = obj && obj.status ? obj.status : "";
    this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
    this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
  }
}

export class MediaSortOrderDTO {
  id: number;
  sortOrder: number;
}

export class EditAlbumSeries {
  title: string;
  subtitle: string;
  squareArtworkId: number;
  wideArtworkId: number;
  bannerArtworkId: number;
  audioId: number;
  // audioDTO: any;
  bannerArtwork: any;
  squareArtwork: any;
  publishedDate: any;
  wideArtwork: any;
  contents: any;
  scheduleDate: any;
  scheduleTime: any;
  isPublished: boolean;
  status: string;
  albumAccessType: any;
  isOneTimePurchase: boolean;
  subscriptionPlanIds: Array<number>;
  isPaid: string;
  price: any;

  constructor(obj?) {
    this.title = obj && obj.title ? obj.title : "";
    this.subtitle = obj && obj.subtitle ? obj.subtitle : "";
    this.squareArtworkId =
      obj && obj.squareArtwork ? obj.squareArtwork.id : null;
    this.wideArtworkId = obj && obj.wideArtworkId ? obj.bannerArtwork.id : null;
    this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.wideArtwork.id : "";
    this.audioId = obj && obj.audioId ? obj.audioId : "";
    // this.audioDTO = obj && obj.audioDTO ? obj.audioDTO : null;
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
    this.contents = obj && obj.contents ? obj.contents : [];
    this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
    this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
    this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
    this.status = obj && obj.status ? obj.status : "";
    this.albumAccessType =
      obj && obj.albumAccessType ? obj.albumAccessType : "FREE";
    this.isOneTimePurchase =
      obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false;
    this.subscriptionPlanIds =
      obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
    this.isPaid = obj && obj.isPaid ? obj.isPaid : "false";
    this.price = obj && obj.price ? obj.price : null;
  }
}

export class EditSongSeries {
  title: string;
  artist: string;
  squareArtworkId: number;
  wideArtworkId: number;
  bannerArtworkId: number;
  audioId: number;
  // audioDTO: any;
  isPublished: boolean;
  status: string;
  bannerArtwork: any;
  squareArtwork: any;
  wideArtwork: any;
  musicAccessType: any;
  subscriptionPlanIds: Array<number>;
  isPaid: string;
  isOneTimePurchase: boolean;
  price: any;
  scheduleDate: any;
  scheduleTime: any;
  audioUrl: string

  constructor(obj?) {
    this.title = obj && obj.title ? obj.title : "";
    this.artist = obj && obj.artist ? obj.artist : "";
    this.squareArtworkId =
      obj && obj.squareArtwork ? obj.squareArtwork.id : null;
    this.wideArtworkId = obj && obj.wideArtworkId ? obj.bannerArtwork.id : null;
    this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.wideArtwork.id : "";
    // this.audioId = obj && obj.audioId ? obj.audioId : '';
    this.audioId = obj && obj.audioId ? obj.audioId : null;
    this.audioUrl = obj && obj.audioUrl ? obj.audioUrl : "";
    // this.audioDTO = obj && obj.audioDTO ? obj.audioDTO : null;
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
    this.status = obj && obj.status ? obj.status : "";
    this.musicAccessType =
      obj && obj.musicAccessType ? obj.musicAccessType : "FREE";
    this.subscriptionPlanIds =
      obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
    this.isPaid = obj && obj.isPaid ? obj.isPaid : "false";
    this.isOneTimePurchase =
      obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false;
    this.price = obj && obj.price ? obj.price : null;
    this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
    this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
  }
}

export class MediaPlayCount {
  deviceType: string;
  mediaId: number;
  mediaItemId: number;
  mediaType: string;
  constructor(obj?) {
    this.deviceType = obj && obj.deviceType ? obj.deviceType : "WEB";
    this.mediaId = obj && obj.mediaId ? obj.mediaId : null;
    this.mediaItemId = obj && obj.mediaItemId ? obj.mediaItemId : null;
    this.mediaType = obj && obj.mediaType ? obj.mediaType : null;
  }
}

export class Ebookitem {
  title: string;
  mediaSeriesId: string;
  itemType: any;
  constructor() {
    this.mediaSeriesId = null;
  }
}

export class EbookSeries {
  title: string;
  // mediaSeriesId: string;
  seriesType: any;
  status: string;
  constructor() {
    // this.mediaSeriesId = null;
    this.status = "DRAFT";
  }
}

export class EditEbookitem {
  additionalLabel: string;
  audioId: number;
  bannerArtworkId: number;
  date: any;
  description: string;
  documentId: number;
  document: any;
  documentTitle: string;
  speaker: string;
  squareArtworkId: number;
  subTitle: string;
  title: string;
  videoId: number;
  tvVideoId: number;
  webLinkDTO = new WebLinkDto();
  wideArtworkId: number;
  ebookArtworkId: number;
  videoDTO: any;
  audioDTO: any;
  bannerArtwork: any;
  ebookArtwork: any;
  squareArtwork: any;
  wideArtwork: any;
  publishedDate: any;
  isPaid: string;
  isVideo: string;
  subscriptionPlanIds: Array<any>;
  audioUrl: string;
  audioUrlType: string;
  videoUrl: string;
  videouUrlType: string;
  scheduleDate: any;
  scheduleTime: any;
  isGivingEnabled: boolean;
  mediaAccessType: any;
  itemType: string;
  isPublished: boolean;
  status: string;
  isOneTimePurchase: boolean;
  price: any;
  constructor(obj?) {
    this.additionalLabel =
      obj && obj.additionalLabel ? obj.additionalLabel : "";
    this.audioId = obj && obj.audioId ? obj.audioId : "";
    this.bannerArtworkId =
      obj && obj.bannerArtworkId ? obj.bannerArtworkId : null;
    this.isGivingEnabled =
      obj && obj.isGivingEnabled ? obj.isGivingEnabled : false;
    // this.date = obj && obj.date ? obj.date : new Date();
    this.date = obj && obj.date ? obj.date : null;
    this.description = obj && obj.description ? obj.description : "";
    this.documentId = obj && obj.documentId ? obj.documentId : null;
    this.document = obj && obj.document ? obj.document : null;
    this.documentTitle = obj && obj.documentTitle ? obj.documentTitle : "";
    this.speaker = obj && obj.speaker ? obj.speaker : "";
    this.squareArtworkId =
      obj && obj.squareArtworkId ? obj.squareArtworkId : null;
    this.ebookArtworkId = obj && obj.ebookArtworkId ? obj.ebookArtworkId : null;
    this.subTitle = obj && obj.subTitle ? obj.subTitle : "";
    this.title = obj && obj.title ? obj.title : "";
    this.videoId = obj && obj.videoId ? obj.videoId : null;
    this.tvVideoId = obj && obj.tvVideoId ? obj.tvVideoId : null;
    this.webLinkDTO = obj && obj.webLinkDTO ? obj.webLinkDTO : new WebLinkDto();
    this.wideArtworkId = obj && obj.wideArtworkId ? obj.wideArtworkId : null;
    this.videoDTO = obj && obj.videoDTO ? obj.videoDTO : null;
    this.audioDTO = obj && obj.audioDTO ? obj.audioDTO : null;
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.ebookArtwork = obj && obj.ebookArtwork ? obj.ebookArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
    this.isPaid = obj && obj.isPaid ? obj.isPaid : "false";
    this.isVideo = obj && obj.isVideo ? obj.isVideo : "false";
    this.subscriptionPlanIds =
      obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
    this.audioUrl = obj && obj.audioUrl ? obj.audioUrl : "";
    this.audioUrlType = obj && obj.audioUrlType ? obj.audioUrlType : "LINK";
    this.videoUrl = obj && obj.videoUrl ? obj.videoUrl : "";
    this.videouUrlType = obj && obj.videouUrlType ? obj.videouUrlType : "LINK";
    this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
    this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
    this.mediaAccessType =
      obj && obj.mediaAccessType ? obj.mediaAccessType : "FREE";
    this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
    this.status = obj && obj.status ? obj.status : "";
    this.isOneTimePurchase =
      obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false;
    this.price = obj && obj.price ? obj.price : null;
  }
}
export class EditCollection {
  title: string;
  subTitle: string;
  squareArtworkId: number;
  wideArtworkId: number;
  bannerArtworkId: number;
  bannerArtwork: any;
  squareArtwork: any;
  wideArtwork: any;
  publishedDate: any;
  seriesType: any;
  status: string;
  isPublished: boolean;
  scheduleDate: any;
  scheduleTime: any;

  contents: any;
  constructor(obj?) {
    this.title = obj && obj.title ? obj.title : "";
    this.subTitle = obj && obj.subtitle ? obj.subtitle : "";
    this.squareArtworkId =
      obj && obj.squareArtwork ? obj.squareArtwork.id : null;
    this.wideArtworkId = obj && obj.wideArtworkId ? obj.bannerArtwork.id : null;
    this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.wideArtwork.id : "";
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
    this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
    this.status = obj && obj.status ? obj.status : "";
    this.contents = obj && obj.contents ? obj.contents : [];
    this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
    this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
  }
}

export class editLiveSetting {
  deviceType: string;
  enableHLS: boolean;
  enableIpFilter: boolean;
  enableRecording: boolean;
  resolutionDTO = new ResolutionDTO();
  constructor(obj?) {
    this.deviceType = obj && obj.deviceType ? obj.deviceType : "WEBSITE";
    this.enableHLS = obj && obj.enableHLS ? obj.enableHLS : true;
    this.enableIpFilter = obj && obj.enableIpFilter ? obj.enableIpFilter : true;
    this.enableRecording =
      obj && obj.enableRecording ? obj.enableRecording : true;
    this.resolutionDTO =
      obj && obj.resolutionDTO ? obj.resolutionDTO : new ResolutionDTO();
  }
}

export class ResolutionDTO {
  hight: number;
  width: number;
  resolutionType: string;
  constructor(obj?) {
    this.hight = obj && obj.hight ? obj.hight : 1080;
    this.width = obj && obj.width ? obj.width : 1920;
    this.resolutionType =
      obj && obj.resolutionType ? obj.resolutionType : "HD_1080";
  }
}

export class ViewerCount {
  id: number;
  status: string;
  type: string;
  duration: string;
  hlsViewerCount: string;
  dashViewerCount: string;
  webRTCViewerCount: string;
  rtmpViewerCount: string;
  constructor(obj?) {
    this.id = obj && obj.id ? obj.id : null;
    this.status = obj && obj.status ? obj.status : null;
    this.type = obj && obj.type ? obj.type : null;
    this.duration = obj && obj.duration ? obj.duration : null;
    this.hlsViewerCount = obj && obj.hlsViewerCount ? obj.hlsViewerCount : null;
    this.dashViewerCount =
      obj && obj.dashViewerCount ? obj.dashViewerCount : null;
    this.webRTCViewerCount =
      obj && obj.webRTCViewerCount ? obj.webRTCViewerCount : null;
    this.rtmpViewerCount =
      obj && obj.rtmpViewerCount ? obj.rtmpViewerCount : null;
  }
}

export class SocialMediaLivestreaming {
  id: number
  rtmpUrl: string;
  socialMediaType: string;
  streamId: string;
  streamKey: string;
  constructor(obj?) {
    this.id = obj && obj.id ? obj.id : null;
    this.rtmpUrl = obj && obj.rtmpUrl ? obj.rtmpUrl : null;
    this.socialMediaType =
      obj && obj.socialMediaType ? obj.socialMediaType : null;
    this.streamId = obj && obj.streamId ? obj.streamId : null;
    this.streamKey = obj && obj.streamKey ? obj.streamKey : null;
  }
}

export class EditChannelItem {
  bannerArtworkId: number;
  catalogContent: any;
  squareArtworkId: number;
  subTitle: string
  title: string;
  wideArtworkId: number;
  bannerArtwork: any;
  squareArtwork: any;
  wideArtwork: any;
  description: any
  constructor(obj?) {
    this.bannerArtworkId = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.catalogContent = obj && obj.catalogContent ? obj.catalogContent : [];
    this.squareArtworkId = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.subTitle = obj && obj.subTitle ? obj.subTitle : '';
    this.title = obj && obj.title ? obj.title : '';
    this.wideArtworkId = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
    this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
    this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
    this.description = obj && obj.description ? obj.description : '';

  }
}