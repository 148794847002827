import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { appConfig } from "./../../app.config";
import { ResourceService } from "./../../services/resource/resource.service";

@Injectable({
  providedIn: "root",
})
export class SettingsServiceService {
  constructor(
    public resourceService: ResourceService,
    public http: HttpClient
  ) {}

  sendCancelAppIconValue = new Subject();
  sendCancelAppIconValue$ = this.sendCancelAppIconValue.asObservable();

  sendCancelAppIcon(state) {
    this.sendCancelAppIconValue.next(state);
  }

  sendCancelLogoValue = new Subject();
  sendCancelLogoValue$ = this.sendCancelLogoValue.asObservable();

  sendCancelLogo(state) {
    this.sendCancelLogoValue.next(state);
  }

  sendAppIconId = new Subject();
  sendAppIconId$ = this.sendAppIconId.asObservable();

  sendAppIconImageId(data) {
    this.sendAppIconId.next(data);
  }

  sendAppIconColor = new Subject();
  sendAppIconColor$ = this.sendAppIconColor.asObservable();

  sendColor(data) {
    this.sendAppIconColor.next(data);
  }

  sendAppIconImage = new Subject();
  sendAppIconImage$ = this.sendAppIconImage.asObservable();

  sendImage(data) {
    this.sendAppIconImage.next(data);
  }

  sendAppIconLaunchScreenImage = new Subject();
  sendAppIconLaunchScreenImage$ =
    this.sendAppIconLaunchScreenImage.asObservable();

  sendAppIconLaunchScreensImage(data) {
    this.sendAppIconLaunchScreenImage.next(data);
  }

  logoColor = new Subject();
  logoColor$ = this.logoColor.asObservable();

  sendLogoColor(data) {
    this.logoColor.next(data);
  }

  sendBrandingDataToApp = new Subject();
  sendBrandingDataToApp$ = this.sendBrandingDataToApp.asObservable();

  sendBrandingToApp(data) {
    this.sendBrandingDataToApp.next(data);
  }

  sendBrandingDataToLogo = new Subject();
  sendBrandingDataToLogo$ = this.sendBrandingDataToLogo.asObservable();

  sendBrandingToLogo(data) {
    this.sendBrandingDataToLogo.next(data);
  }

  appLogoData = new Subject();
  appLogoData$ = this.appLogoData.asObservable();

  appLogo(data) {
    this.appLogoData.next(data);
  }

  logoData = new Subject();
  logoData$ = this.logoData.asObservable();

  appLogoImageId = new Subject();
  appLogoImageId$ = this.appLogoImageId.asObservable();

  sendAppLogoImageId(data) {
    this.appLogoImageId.next(data);
  }

  brandLogoId = new Subject();
  brandLogoId$ = this.brandLogoId.asObservable();

  sendBrandLogoId(data) {
    this.brandLogoId.next(data);
  }

  appPrevLogoId = new Subject();
  appPrevLogoId$ = this.appPrevLogoId.asObservable();

  sentAppPrevLogoId(data) {
    this.appPrevLogoId.next(data);
  }

  appPrevScreensImage = new Subject();
  appPrevScreensImage$ = this.appPrevScreensImage.asObservable();

  sendPrevScreensImage(data) {
    this.appPrevScreensImage.next(data);
  }

  appPrevSelectedColor = new Subject();
  appPrevSelectedColor$ = this.appPrevSelectedColor.asObservable();
  sentPrevSelectedColor(data) {
    this.appPrevSelectedColor.next(data);
  }

  appPrevSelectedVideo = new Subject();
  appPrevSelectedVideo$ = this.appPrevSelectedVideo.asObservable();
  sentPrevSelectedVideo(data) {
    this.appPrevSelectedVideo.next(data);
  }

  sendLogo(data) {
    this.logoData.next(data);
  }

  brandLogoData = new Subject();
  brandLogoData$ = this.brandLogoData.asObservable();

  brandLogo(data) {
    this.brandLogoData.next(data);
  }

  logoId = new Subject();
  logoId$ = this.logoId.asObservable();

  sendLogoId(data) {
    this.logoId.next(data);
  }

  splashScreen = new Subject();
  splashScreen$ = this.splashScreen.asObservable();

  sendSplashScreen(data) {
    this.splashScreen.next(data);
  }

  splashScreenData = new Subject();
  splashScreenData$ = this.splashScreenData.asObservable();

  sendSplashScreenData(data) {
    this.splashScreenData.next(data);
  }

  splashScreenDataFromBranding = new Subject();
  splashScreenDataFromBranding$ =
    this.splashScreenDataFromBranding.asObservable();

  sendSplashScreenDataFromBranding(data) {
    this.splashScreenDataFromBranding.next(data);
  }

  brandLogoArtworkId = new Subject();
  brandLogoArtworkId$ = this.brandLogoArtworkId.asObservable();

  sendBrandLogoArtworkId(data) {
    this.brandLogoArtworkId.next(data);
  }

  appArtworkId = new Subject();
  appArtworkId$ = this.appArtworkId.asObservable();

  sendAppArtworkId(data) {
    this.appArtworkId.next(data);
  }

  givingId = new Subject();
  givingId$ = this.givingId.asObservable();

  givingAppearanceArtworkId(data) {
    this.givingId.next(data);
  }

  appLaunchScreenId = new Subject();
  appLaunchScreenId$ = this.appLaunchScreenId.asObservable();

  sendLaunchScreenId(data) {
    this.appLaunchScreenId.next(data);
  }

  changesDetect = new Subject();
  changesDetect$ = this.changesDetect.asObservable();

  sendChangesDetect(data) {
    this.changesDetect.next(data);
  }

  inviteUsers = new Subject();
  inviteUsers$ = this.inviteUsers.asObservable();

  sendInviteUsers() {
    this.inviteUsers.next();
  }

  /* create App Store Info */

  createAppStoreInfo(data) {
    const createAppStoreInfoURL = `${appConfig.appStoreInfo}/createAppStoreInfo`;
    return this.resourceService.postData(createAppStoreInfoURL, data);
  }

  /* get app store info by id */

  getAppStoreInfoById() {
    const getAppStoreInfoByIdURL = `${appConfig.appStoreInfo}`;
    return this.resourceService.getData(getAppStoreInfoByIdURL);
  }

  /* update app store info */

  updateAppStoreInfo(id, data) {
    const updateAppStoreInfoURL = `${appConfig.appStoreInfo}/${id}`;
    return this.resourceService.putData(updateAppStoreInfoURL, data);
  }

  // taxInfo/documentDelete/289?documentId=7179
  deleteTaxInfoDocument(mediaId, docId) {
    const itemType = "documentId";
    if (docId) {
      var deleteMediaVideoUrl = `${appConfig.taxInfo}/documentDelete/${mediaId}?${itemType}=${docId}`;
    }
    return this.resourceService.deleteData(deleteMediaVideoUrl);
  }

  /* Create Tax Info */

  createTaxInfo(data) {
    const createTaxInfoURL = `${appConfig.taxInfo}/createTaxInfo`;
    return this.resourceService.postData(createTaxInfoURL, data);
  }

  /* get Tax Info */

  getTaxInfoById() {
    const getTaxInfoByIdURL = `${appConfig.taxInfo}`;
    return this.resourceService.getData(getTaxInfoByIdURL);
  }

  /* update Tax Info */

  updateTaxInfo(id, data) {
    const updateTaxInfoURL = `${appConfig.taxInfo}/${id}`;
    return this.resourceService.putData(updateTaxInfoURL, data);
  }

  /* create developer account */

  createDeveloperAccount(data) {
    const createDeveloperAccountURL = `${appConfig.developerAccount}/createDeveloperAccount`;
    return this.resourceService.postData(createDeveloperAccountURL, data);
  }

  /* get Developer Account */

  getDeveloperAccount() {
    const getDeveloperAccountURL = `${appConfig.developerAccount}/getAllDeveloperAccountList`;
    return this.resourceService.getData(getDeveloperAccountURL);
  }

  /* update Developer Account */

  updateDeveloperAccount(id, data) {
    const updateDeveloperAccountURL = `${appConfig.developerAccount}/${id}`;
    return this.resourceService.putData(updateDeveloperAccountURL, data);
  }

  /* General Settings Time Zone */

  getTimeZone() {
    const getTimezoneURL = `${appConfig.organizationSetting}`;
    return this.resourceService.getData(getTimezoneURL);
  }

  /* get general setting list */

  getGeneralSettingList() {
    const getGeneralSettingListURL = `${appConfig.organizationSetting}/getAllOrganizationSetting`;
    return this.resourceService.getData(getGeneralSettingListURL);
  }

  /* create General Setting  */

  createGeneralSetting(data) {
    const createGeneralSettingURL = `${appConfig.organizationSetting}/createOrganizationSetting`;
    return this.resourceService.postData(createGeneralSettingURL, data);
  }

  /* update General Setting */

  updateGeneralSetting(data) {
    const updateGeneralSettingURL = `${appConfig.organizationSetting}`;
    return this.resourceService.putData(updateGeneralSettingURL, data);
  }

  /* get promotional Images */

  // const getDownload = URLS.downloadPartyContent + "?contentId=" + id;
  //  return this.http.get(getDownload, { responseType: "blob" });

  getPromotionalImages(id) {
    const getPromotionalImagesURL = `${appConfig.organizationSetting}/getPromotionalImages?organizationId=${id}`;
    // return this.resourceService.getData(getPromotionalImagesURL)
    return this.http.get(getPromotionalImagesURL, { responseType: "blob" });
  }

  /* create Branding */

  createBranding(data) {
    const createBrandingURL = `${appConfig.branding}/createBranding`;
    return this.resourceService.postData(createBrandingURL, data);
  }

  /* update Branding */

  updateBranding(id, data) {
    const updateBrandingURL = `${appConfig.branding}/${id}`;
    return this.resourceService.putData(updateBrandingURL, data);
  }

  /* get Branding */

  getBranding() {
    const getBrandingURL = `${appConfig.branding}`;
    return this.resourceService.getData(getBrandingURL);
  }

  /* create user */

  createUser(data) {
    const createUserURL = `${appConfig.user}`;
    return this.resourceService.postData(createUserURL, data);
  }

  /* get user */

  getUser() {
    const getUserURL = `${appConfig.user}/invitedUser`;
    return this.resourceService.getData(getUserURL);
  }

  /* get deactivate user */

  getDeactivateUser(page, size) {
    const getUserURL = `${appConfig.user}/deactivatedUsersList?page=${page}&size=${size}`;
    return this.resourceService.getData(getUserURL);
  }

  /* get User By Id */

  getUserById(id) {
    const getUserByIdURL = `${appConfig.user}/${id}`;
    return this.resourceService.getData(getUserByIdURL);
  }

  /* update user */

  updateUser(id, data) {
    const updateUserURL = `${appConfig.user}/${id}`;
    return this.resourceService.putData(updateUserURL, data);
  }

  /* deactivate user */

  deactivateUser(id, value) {
    const deactivateUserURL = `${appConfig.user}/activateDeactivate?userId=${id}&value=${value}`;
    return this.resourceService.putData(deactivateUserURL);
  }

  activateUser(id, value) {
    const activateUserURL = `${appConfig.user}/activateDeactivate?userId=${id}&value=${value}`;
    return this.resourceService.putData(activateUserURL);
  }

  addcardDetails(data) {
    const addcardDetailsURL = `${appConfig.usersubScription}/userCard`;
    return this.resourceService.postData(addcardDetailsURL, data);
  }

  updateCardDetails(id, data) {
    const updateCardDetailsURL = `${appConfig.usersubScription}/${id}`;
    return this.resourceService.putData(updateCardDetailsURL, data);
  }

  getCardDetails(value) {
    const getCardDetailsURL = value
      ? `${appConfig.usersubScription}/cardDetails?isActiveCard=${value}`
      : `${appConfig.usersubScription}/cardDetails`;
    return this.resourceService.getData(getCardDetailsURL);
  }

  getSecretKey() {
    const getSecretKeyURL = `${appConfig.usersubScription}/secretKey?isGiving=false`;
    return this.resourceService.getData(getSecretKeyURL);
  }

  getSecretKeyWithoutAuth() {
    const getSecretKeyURL = `${appConfig.usersubScription}/secretKeyWithoutLogin?organizationId=1`;
    return this.resourceService.getData(getSecretKeyURL);
  }

  makePayment(data) {
    const addcardDetailsURL = `${appConfig.giving}/payment`;
    return this.resourceService.postData(addcardDetailsURL, data);
  }

  deleteCard(id, cardIds) {
    const deleteCardUrl = `${appConfig.usersubScription}/${id}?cardIds=${cardIds}`;
    return this.resourceService.deleteData(deleteCardUrl);
  }

  getMemoryStorage(orgId) {
    if(orgId){
      var getMemoryStorageURL = `${appConfig.memoryStorage}/storageInfo?organizationId=${orgId}`;
    }
    else{
      var getMemoryStorageURL = `${appConfig.memoryStorage}/storageInfo`;
    }
    return this.resourceService.getData(getMemoryStorageURL);
  }

  submitSripeCredentials(data) {
    const submitStripeCredentialsURL = `${appConfig.organizationSetting}/organizationStripeCredentials`;
    return this.resourceService.postData(submitStripeCredentialsURL, data);
  }

  getStripeCredentials() {
    const getStripeCredentialsURL = `${appConfig.usersubScription}/secretKey`;
    return this.resourceService.getData(getStripeCredentialsURL);
  }
  generateRequest(data) {
    const generateRequestURL = `${appConfig.settingChangedRequest}/generateRequest`;
    return this.resourceService.postData(generateRequestURL, data);
  }
  updateRequestStatusPending(data) {
    const updateRequestStatusPendingURL = `${appConfig.settingChangedRequest}/updateRequestStatusPending`;
    return this.resourceService.putData(updateRequestStatusPendingURL, data);
  }

  // get super admin settings

  getSuperAdminSetting() {
    const getSuperAdminServiceUrl = `${appConfig.settings}`;
    return this.resourceService.getData(getSuperAdminServiceUrl);
  }

  // update super admin settings

  updateSuperAdminSetting(data) {
    const updateSuperAdminServiceUrl = `${appConfig.settings}`;
    return this.resourceService.putData(updateSuperAdminServiceUrl, data);
  }
  
  getDataTranferUsage(date: any, mediaType: any, organizationId: any, defaultVal:any) {
    const getDataTranferUsageUrl = `${appConfig.organization}/dataTransferInfo?date=${date}&mediaType=${mediaType}&organizationId=${organizationId}&isDefault=${defaultVal}`;
    return this.resourceService.getData(getDataTranferUsageUrl);
  }

  getLiveStreamDataUsage(date: any, organizationId: any, defaultVal:any) {
    const getDataTranferUsageUrl = `${appConfig.organization}/liveStreamDataTransfer?date=${date}&organizationId=${organizationId}&isDefault=${defaultVal}`;
    return this.resourceService.getData(getDataTranferUsageUrl);
  }

  getOrganisationInfoForStorage(type: string, orgId: number) {
    const getCountryUrl = `${appConfig.organization}/organizationPlanCardDetails?itemType=${type}&organizationId=${orgId}`;
    return this.resourceService.getData(getCountryUrl);
  }

  makeStoragePayment(data,type:any) {
    const addcardDetailsURL = `${appConfig.organization}/updateDataPricingPlan?itemType=${type}`;
    return this.resourceService.putData(addcardDetailsURL, data);
  }

  getVastTagData(orgId:any) {
      const getVastUrl = `${appConfig.pageCustomization}/vastTag`;
      return this.resourceService.getData(getVastUrl);
  }

  updateVastTagSetting(data) {
    const updateVastSettingURL = `${appConfig.pageCustomization}/vastTag/`;
    return this.resourceService.putData(updateVastSettingURL, data);
  }

  saveGHLKey(orgId:number,apiKey:string,isGHLEnabled = true) {
    const createGeneralSettingURL = `${appConfig.organizationSetting}/goHighLevel?apiKey=${apiKey}&isGHLEnabled=${isGHLEnabled}`;
    return this.resourceService.postData(createGeneralSettingURL);
  }

  getGHLSettingData(orgId:any) {
    const getVastUrl = `${appConfig.organizationSetting}/goHighLevel`;
    return this.resourceService.getData(getVastUrl);
}
  
}
