import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MediaService } from "src/app/services/media/media.service";
import { LibraryServiceService } from "./../../../../services/library/library-service.service";
import { AppAnimation } from "./../../../../utilities/animations/animations";
import { MediaItem } from "src/app/models/media.model";
import {
  CreateLinkItem,
  ListItem,
  RssFeeds,
} from "src/app/models/library.model";
import { MessageService } from "primeng/api";
import { ArtWorkService } from "src/app/services/artwork/artwork.service";
import { SharedService } from "src/app/services/shared/shared.service";
import { Subscription } from "rxjs";
import { ThisReceiver, ThrowStmt } from "@angular/compiler";
import { element } from "protractor";
import { ActivatedRoute, Router } from "@angular/router";
import { appConfig } from "src/app/app.config";
import { EventService } from "src/app/services/events/event-services";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  window,
} from "rxjs/operators";
import { timeStamp } from "console";
import { threadId } from "worker_threads";
import { NgForm } from "@angular/forms";
import { AppsService } from "src/app/services/apps/apps-service";
import { EbookreaderService } from "src/app/services/collection/ebook/ebookreader.service";
import * as moment from "moment";
import "moment-timezone";
import { FeatureControlService } from "src/app/services/feature-control.service";

@Component({
  selector: "app-add-to-list",
  templateUrl: "./add-to-list.component.html",
  styleUrls: ["./add-to-list.component.css"],
  animations: AppAnimation,
})
export class AddToListComponent implements OnInit {
  submitted = false;
  clickFrom: any;
  createLinkinfo: any;
  keyExist = false;
  overlay = false;
  sidePanel = false;
  mediaItemRadio = false;
  totalLinkList = 0;
  totalRecentSeries = 0;
  totaltrendingList = 0;
  totalmusicList = 0;
  totalsongList = 0;
  totalrssList = 0;
  totalebookItemList = 0;
  totalebookList = 0;
  totalChannelList = 0;
  totalChannelItemList: any;
  totalrecentMedia = 0;
  totalallEventItem = 0;
  totalcalenderItem = 0;
  recentSeries: any[] = [];
  recentSeriesList: any[] = [];
  trendingList: any[] = [];
  linkList: any[] = [];
  recentMedia: any[] = [];
  recentStream: any[] = [];
  recentStreamList: any[] = [];
  ebookItemList: any[] = [];
  totalEbookItemList: any[] = [];
  totalEbooks = 0;
  totalChannels = 0;
  channelItemList: any[] = [];
  m3uChannelItemList: any[] = [];
  totalM3uChannelItemList: any[] = [];
  totalMediaItems = 0;
  totalListItems = 0;
  mediaSeries = true;
  livestream = false;
  listItems = false;
  mediaItems = false;
  selectAll = false;
  mediaItem: MediaItem;
  listItem: ListItem;
  selectedItems: any[] = [];
  selectedMediaSeriesItems: any[] = [];
  selectedStreamItems: any[] = [];
  selectedMediaItems: any[] = [];
  selectedFolderItems: any[] = [];
  selectedLinkItems: any[] = [];
  selectedRssItems: any[] = [];
  selectedEbookItem: any[] = [];
  selectedAlbumItems: any[] = [];
  selectedSongsItems: any[] = [];
  selectedEventItems: any[] = [];
  selectedCalenderItems: any[] = [];
  selectedEbookItems: any[] = [];
  selectedChannelItems: any[] = [];
  folderId: any;
  mediaSerId: any;
  mediaItId: any;
  albumItemId: number;
  mediaSeriesId: number;
  listItemId: number;
  mediaSeriesTitle: string;
  createMediaSer = false;
  createMedIt = false;
  createListIt = false;
  createLinkIt = false;
  createRssIt = false;
  createSongIt = false;
  createAlbumIt = false;
  createCalenderIt = false;
  createEventIt = false;
  showOptions = true;
  selected = -1;
  songTitle: string;
  songSubtitle: string;
  totalLinkItems = 100;
  linkItem = false;
  newData: any[] = [];
  newMediaItemData: any[] = [];
  newLiveStreamData: any[] = [];
  newFolderItemData: any[] = [];
  newLinkItemData: any[] = [];
  newRssitemData: any[] = [];
  newEbookData: any[] = [];
  newChannelData: any[] = [];
  newAlbumItemData: any[] = [];
  newSongItemData: any[] = [];
  newSongItemDataFoList: any[] = [];
  newEventItemData: any[] = [];
  newCalenderItemData: any[] = [];
  newEbookItemData: any[] = [];
  newData1: any[] = [];
  newData2: any[] = [];
  newData3: any[] = [];
  newData4: any[] = [];
  newData5: any[] = [];
  newData10: any[] = [];
  newData6: any[] = [];
  newData7: any[] = [];
  newData8: any[] = [];
  newData9: any[] = [];
  newData11: any[] = [];
  combined: any[] = [];
  combined1: any[] = [];
  rss = false;
  ebook = false;
  // livestream = false;
  ebookItem = false;
  channelItem = false;
  song = false;
  album = false;
  channel = false;
  rssList: any[] = [];
  ebookList: any[] = [];
  totalRssFeedItems = 0;
  totalRecords = 0;
  totalEbookItems = 0;
  totalChannelItems = 0;
  totalMediaSeries = 0;
  totalSongs = 0;
  albumList: any[] = [];
  musicList: any[] = [];
  allMusicList: any[] = [];
  showallFolder = false;
  showAllEbook = false;
  showAllChannel = false;
  showAllLink = false;
  showallMediaSeries = false;
  showallLiveStream = false;
  showAllMediaItem = false;
  showAllRssItem = false;
  showAllEbookItem = false;
  showAllChannelItem = false;
  showAllSongs = false;
  showAllLiveStream = false;
  totalList: any[] = [];
  totalLink: any[] = [];
  totalRss: any[] = [];
  totalEbook: any[] = [];
  totalChannel: any[] = [];
  totalMediaItem: any[] = [];
  songList: any[] = [];
  totalSongList: any[] = [];
  opacity: false;
  clickedFrom: string;
  carouselMaxLength: number;
  selMedItLen = 0;
  selMedSeLen = 0;
  selLiveStreamLen = 0;
  selFolItLen = 0;
  selLinItLen = 0;
  selAlbItLen = 0;
  selRssItLen = 0;
  selEbookLen = 0;
  selChannelLen = 0;
  selSongLen = 0;
  selEventLen = 0;
  selCalenderItLen = 0;
  popUp = false;
  medSeLen = 0;
  medItLen = 0;
  folItLen = 0;
  linItLen = 0;
  rssItLen = 0;
  ebookLen = 0;
  stripeKeyModal = false;

  AlbItLen = 0;
  songItLen = 0;
  eventItLen = 0;
  calenderItLen = 0;
  responseData: number;
  removedDataArray: any[] = [];
  totalCheckedLength: number;
  totalAlbumList: number;
  albumTitle: string;
  rssTitle: string;
  rssSubtitle: string;
  rssUrl: string;
  showall = false;
  arrayMobile: any[] = [];
  routeFrom: any;
  subsciptionData = new Subscription();
  subsciptionData2 = new Subscription();
  allEventItem: any[] = [];
  totalEvents = 0;
  events = false;
  allEventItemList: any[] = [];
  showAllEvents = false;
  calenderItem: any[] = [];
  calenderItemList: any[] = [];
  audioId: number;

  totalCalender = 0;
  calender = false;
  showAllCalender = false;
  showLiveStream: boolean;
  showLive = false;
  // subsciptionData = new Subscription();
  tvMed = false;
  folderChecked = false;
  ebookItemChecked = false;
  channelItemChecked = false;
  m3uChannelItemChecked = false;
  EventChecked = false;
  mediaitemChecked = false;
  claenderChecked = false;
  mediaSeriesChecked = true;
  albumChecked = false;
  linkChecked = false;
  rssChecked = false;
  ebookChecked = false;
  channelChecked = false;
  songChecked = false;
  // new
  urlbox = true;
  cntctBox = false;
  rssFeeds: RssFeeds;
  rssFeedId: number;
  artworkList: any = {};
  LinkTitle: string;
  LinkSubtitle: string;
  Linktype: string = "APP_LINK";
  LinkUrl: string;
  contact: string;
  EventTitle: string;
  EventSubtitle: string;
  EventstartDate: any;
  EventendDate: any;
  EventstartTime: any;
  EventendTime: any;
  Eventpinned: string;
  calenderItemId: number;
  data: any;
  calenderTitle: string;
  calenderSubtitle: string;
  listAlbumname: any[] = [];
  listCalendername: any[] = [];
  calenderId: string = null;
  type: string;
  carouselArtworkType: any;
  timeZone: any;

  invalidStartTime = true;
  invalidEndTime = true;
  date: any;
  eventSubmitted = false;

  checkStartTime: any;
  checkEndTime: any;

  startTimeData: any;
  endTimeData: any;

  tabType: string;
  tabId: number;
  panel: string;
  profitable: any;
  showGivingCard: boolean;
  featureList: any[] = [];
  showGiving: boolean;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchMediaItems") searchMediaItems: ElementRef;
  @ViewChild("searchMediaSer") searchMediaSer: ElementRef;
  @ViewChild("searchSongs") searchSongs: ElementRef;
  @ViewChild("searchAlbums") searchAlbums: ElementRef;
  @ViewChild("searchEbook") searchEbook: ElementRef;
  @ViewChild("searchCalenders") searchCalenders: ElementRef;
  @ViewChild("searchEvents") searchEvents: ElementRef;
  @ViewChild("searchRssFeed") searchRssFeed: ElementRef;
  @ViewChild("searchLinks") searchLinks: ElementRef;
  @ViewChild("searchEbookItem") searchEbookItem: ElementRef;
  @ViewChild("searchChannelItem") searchChannelItem: ElementRef;
  @ViewChild("searchLiveStream") searchLiveStream: ElementRef;

  repeatDay: string;

  constructor(
    public libraryService: LibraryServiceService,
    public mediaService: MediaService,
    public ebookService: EbookreaderService,
    public messageService: MessageService,
    public artWorkService: ArtWorkService,
    public sharedService: SharedService,
    public route: ActivatedRoute,
    public router: Router,
    public eventService: EventService,
    public appsService: AppsService,
    public featureControlService: FeatureControlService
  ) {
    this.mediaItem = new MediaItem();
    this.listItem = new ListItem();
  }

  ngOnInit(): void {
    this.listItemId = +this.route.snapshot.paramMap.get("id");
    this.getSubscriptionData();
    this.getSubscriptionData2();
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
    if (this.subsciptionData2) {
      this.subsciptionData2.unsubscribe();
    }
  }

  ngAfterViewInit() {
    fromEvent(this.searchMediaSer.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getMediaSeries(1, 10);
          this.showallMediaSeries = false;
        })
      )
      .subscribe();

    fromEvent(this.searchMediaItems.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getMediaItems(1, 10);
          this.showAllMediaItem = false;
        })
      )
      .subscribe();

    fromEvent(this.searchChannelItem.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getChannelItemlist(1, 40);
          setTimeout(() => {
            this.showAllChannel = false;
          }, 1000);
        })
      )
      .subscribe();

    fromEvent(this.searchSongs.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getAllSongList(1, 10);
          this.showAllSongs = false;
        })
      )
      .subscribe();

    fromEvent(this.searchAlbums.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getAlbumList(1, 10);
          this.showall = false;
        })
      )
      .subscribe();

    fromEvent(this.searchEbook.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getEbooklist(1, 10);
          this.showAllEbookItem = false;
        })
      )
      .subscribe();

    fromEvent(this.searchCalenders.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getCalenderItem(1, 10);
          this.showAllCalender = false;
        })
      )
      .subscribe();

    fromEvent(this.searchEvents.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getAllEventItem(1, 10);
          this.showAllEvents = false;
        })
      )
      .subscribe();

    fromEvent(this.searchLinks.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getLinkItems(1, 10);
          this.showAllLink = false;
        })
      )
      .subscribe();

    fromEvent(this.searchRssFeed.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getRssFeedList(1, 10);
          this.showAllRssItem = false;
        })
      )
      .subscribe();

    fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getListItem(1, 10);
          this.showallFolder = false;
        })
      )
      .subscribe();

    fromEvent(this.searchEbookItem.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.getEbookItemlist(1, 10);
          this.showAllEbook = false;
        })
      )
      .subscribe();
  }

  emptySearch() {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = null;
    }
    if (this.searchMediaItems) {
      this.searchMediaItems.nativeElement.value = null;
    }
    if (this.searchMediaSer) {
      this.searchMediaSer.nativeElement.value = null;
    }
    if (this.searchLinks) {
      this.searchLinks.nativeElement.value = null;
    }
    if (this.searchRssFeed) {
      this.searchRssFeed.nativeElement.value = null;
    }
    if (this.searchSongs) {
      this.searchSongs.nativeElement.value = null;
    }
    if (this.searchCalenders) {
      this.searchCalenders.nativeElement.value = null;
    }
    if (this.searchEvents) {
      this.searchEvents.nativeElement.value = null;
    }
    if (this.searchAlbums) {
      this.searchAlbums.nativeElement.value = null;
    }
    if (this.searchEbook) {
      this.searchEbook.nativeElement.value = null;
    }
    if (this.searchEbookItem) {
      this.searchEbookItem.nativeElement.value = null;
    }
    if (this.searchChannelItem) {
      this.searchChannelItem.nativeElement.value = null;
    }
  }

  closePanel(type) {
    if (this.clickedFrom === "mediaLib") {
      this.mediaService.openEmbedPanel(true);
    }
    if (this.clickedFrom === "eventEmbed") {
      this.mediaService.openEventEmbed(true);
    }
    if (this.clickedFrom === "mobile") {
      this.libraryService.sendArtworkType(this.carouselArtworkType);
    }
    this.panel = null;
    this.tabType = null;
    this.tabId = null;
    this.sidePanel = false;
    this.showall = false;
    this.overlay = false;
    this.libraryService.updatePanel({ showPanel: false, opacity: false });
    if (this.featureControlService.showVOD) {
      this.mediaSeries = true;
      this.mediaSeriesChecked = true;
    } else if (this.featureControlService.showFolder) {
      this.listItems = true;
      this.folderChecked = true;
    } else if (this.featureControlService.showMusic) {
      this.album = true;
      this.albumChecked = true;
    } else if (this.featureControlService.showEvent) {
      this.events = true;
      this.EventChecked = true;
    } else if (this.featureControlService.showLinks) {
      this.linkItem = true;
      this.linkChecked = true;
    } else if (this.featureControlService.showRss) {
      this.rss = true;
      this.rssChecked = true;
    } else if (this.featureControlService.showBookStore) {
      this.ebook = true;
      this.ebookItemChecked = true;
    } else if (this.featureControlService.showLive) {
      this.livestream = true;
      // this.liveS = true;
    } else {
      this.mediaSeries = false;
      this.mediaSeriesChecked = false;
      this.listItems = false;
      this.folderChecked = false;
      this.album = false;
      this.albumChecked = false;
      this.rss = false;
      this.rssChecked = false;
      this.ebook = false;
      this.ebookItemChecked = false;
      this.events = false;
      this.EventChecked = false;
      this.linkItem = false;
      this.linkChecked = false;
      this.livestream = false;
    }

    // this.ebook = false;
    this.ebookItem = false;
    this.song = false;
    // this.album = false;
    // this.livestream = false;
    this.mediaItems = false;
    // this.mediaSeries = true;
    // this.linkItem = false;
    // this.listItems = false;
    // this.events = false;
    this.calender = false;
    this.showallMediaSeries = false;
    this.showAllLiveStream = false;
    this.tvMed = false;
    this.folderChecked = false;
    this.createMediaSer = false;
    this.createMedIt = false;
    this.createListIt = false;
    this.createLinkIt = false;
    this.createRssIt = false;
    this.createSongIt = false;
    this.createAlbumIt = false;
    this.createCalenderIt = false;
    this.createEventIt = false;
    this.mediaSeriesTitle = "";
    this.LinkTitle = "";
    this.LinkSubtitle = "";
    this.LinkUrl = "";
    this.contact = "";
    this.rssSubtitle = "";
    this.rssTitle = "";
    this.rssUrl = "";
    this.albumTitle = "";
    this.songTitle = "";
    this.songSubtitle = "";
    this.calenderTitle = "";
    this.calenderSubtitle = "";
    this.EventTitle = "";
    this.EventSubtitle = "";
    this.EventendTime = "";
    this.EventendDate = "";
    this.EventstartTime = "";
    this.EventstartDate = "";
    this.repeatDay = "";
    this.showOptions = true;
    // this.folderChecked = false;
    // this.EventChecked = false;
    this.mediaitemChecked = false;

    this.combined = [];
    this.combined1 = [];
    this.claenderChecked = false;
    // this.mediaSeriesChecked = true;
    // this.albumChecked = false;
    // this.linkChecked = false;
    // this.rssChecked = true;
    // this.ebookChecked = false;
    this.songChecked = false;
    // this.ebookItemChecked = false;
    // this.selectedMediaSeriesItems = []
    // this.selectedMediaItems = []
    // this.selectedFolderItems = []
    // this.selectedCalenderItems = []
    // this.selectedEventItems = []
    // this.selectedLinkItems = []
    // this.selectedRssItems = []
    // this.selectedAlbumItems = []
    // this.selectedSongsItems = []
    // this.selectedEbookItem = []
    // this.selectedEbookItems = []
    this.searchChannelItem.nativeElement.value = ''
    if (type === "cancel") {
      this.newData9 = [];
      this.newData8 = [];
      this.newData = [];
      this.newData1 = [];
      this.newData2 = [];
      this.newData3 = [];
      this.newData4 = [];
      this.newData5 = [];
      this.newData10 = [];
      this.newData6 = [];
      this.newData7 = [];
      this.newData11 = [];
    }
  }

  showmoreFolder() {
    this.showallFolder = true;
    this.getListItem(1, this.totalListItems);
  }

  showmoreEbook() {
    this.showAllEbookItem = true;
    // this.getListItem(1, this.totalListItems)
    this.getEbooklist(1, this.totalEbookItems);
    // this.getEbookItemlist(1,this.totalEbooks)
  }

  showMoreLink() {
    this.showAllLink = true;
    this.getLinkItems(1, this.totalLinkItems);
  }

  showMoreMediaSeries() {
    this.showallMediaSeries = true;
    this.getMediaSeries(1, this.totalMediaSeries);
  }

  showmoreMediaItem() {
    this.showAllMediaItem = true;
    this.getMediaItems(1, this.totalMediaItems);
  }

  showmoreRssItem() {
    this.showAllRssItem = true;
    this.getRssFeedList(1, this.totalRssFeedItems);
  }

  showMoreLiveStream() {
    this.showAllLiveStream = true;
    this.getLiveStreamItems(1, this.totalRecords);
  }

  showmoreEbookItem() {
    this.showAllEbook = true;
    this.getEbookItemlist(1, this.totalEbooks);
    // this.getEbooklist(1,this.totalEbookItems)
  }

  showmoreChannelItem() {
    this.showAllChannel = true;
    this.getChannelItemlist(1, this.totalChannels);
    // this.getEbooklist(1,this.totalEbookItems)
  }

  showmoreSongsList() {
    this.showAllSongs = true;
    this.getAllSongList(1, this.totalSongs);
  }

  showMoreEventList() {
    this.showAllEvents = true;
    this.getAllEventItem(1, this.totalEvents);
  }

  showMoreCalenderList() {
    this.showAllCalender = true;
    this.getCalenderItem(1, this.totalCalender);
  }

  getCalenItem(page, size) {
    this.eventService
      .getCalenderData(
        page,
        size,
        this.searchCalenders ? this.searchCalenders.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.listCalendername = response.data.content;
      });
  }

  getCalenderItem(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.ebook = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.linkItem = false;
    this.listItems = false;
    this.events = false;
    this.calender = true;
    this.newData9 = [];
    this.newCalenderItemData = [];
    this.eventService
      .getCalenderData(
        page,
        size,
        this.searchCalenders ? this.searchCalenders.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.calenderItem = response.data.content;
        this.totalcalenderItem = response.data.totalElements;
        // this.calenderItem = this.calenderItem.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        this.totalCalender = response.data.totalElements;
        this.calenderItemList = this.calenderItem.slice(0, 4);

        // this.listCalendername = response.data.content

        // this.listCalendername = response.data.content.map((obj: any) => {
        //   return { label: (obj.title), value: obj.id }
        // });

        // if (response.data.totalElements < 5) {
        //   this.showAllCalender = true
        // }
        // else{
        //   this.showAllCalender = false
        // }
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.calenderItem.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selCalenderItLen = count;
                this.newData9.push(data);
              }
              //  this.selMedSeLen = count
            });
          });
          // this.selMedSeLen = this.arrayMobile.length
          // this.newFunction()
        }
        // this.selectedCalenderItems = []
        this.selectedCalenderItems.forEach((data) => {
          this.calenderItem.forEach((el) => {
            if (data.id === el.id && data.title === el.title) {
              el["itemChecked"] = true;
            }
          });
        });

        this.newCalenderItemData = this.selectedCalenderItems.map((el) => ({
          id: el.id,
          sortOrder: 9,
          type: "CALENDAR",
        }));

        this.calenderItem.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });
      });
  }

  getAllEventItem(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.calender = false;
    this.rss = false;
    this.livestream = false;
    this.ebook = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.linkItem = false;
    this.listItems = false;
    this.events = true;
    this.newData8 = [];
    this.newEventItemData = [];

    this.eventService
      .getallEventData(
        page,
        size,
        this.searchEvents ? this.searchEvents.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.allEventItem = response.data.content;
        this.totalallEventItem = response.data.totalElements;
        // this.allEventItem = this.allEventItem.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        this.totalEvents = response.data.totalElements;
        this.allEventItemList = this.allEventItem.slice(0, 4);
        // if (response.data.totalElements < 5) {
        //   this.showAllEvents = true
        // }
        // else{
        //   this.showAllEvents = false
        // }
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.allEventItem.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selEventLen = count;
                this.newData8.push(data);
              }
              //  this.selMedSeLen = count
            });
          });
          // this.selMedSeLen = this.arrayMobile.length
          // this.newFunction()
        }
        // this.selectedEventItems = []
        this.selectedEventItems.forEach((data) => {
          this.allEventItem.forEach((el) => {
            if (data.id === el.id && data.title === el.title) {
              el["itemChecked"] = true;
            }
          });
        });

        this.newEventItemData = this.selectedEventItems.map((el) => ({
          id: el.id,
          sortOrder: 8,
          type: "EVENT",
        }));

        this.allEventItem.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });
      });
  }

  getAllSongList(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.calender = false;
    this.rss = false;
    this.livestream = false;
    this.ebook = false;
    this.song = true;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.linkItem = false;
    this.listItems = false;
    this.events = false;
    this.newData7 = [];
    this.newSongItemData = [];

    this.mediaService
      .getAllSongList(
        page,
        size,
        this.searchSongs ? this.searchSongs.nativeElement.value : ""
      )
      .subscribe((response) => {
        this.songList = response.data.content;
        this.totalsongList = response.data.totalElements;
        // this.songList = this.songList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        this.totalSongs = response.data.totalElements;
        // this.totalSongList = this.songList.slice(0, 4)

        // if (response.data.totalElements < 5) {
        //   this.showAllSongs = true
        // }else{
        //   this.showAllSongs = false
        // }
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.songList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selSongLen = count;
                this.newData7.push(data);
              }
              //  this.selMedSeLen = count
            });
          });
          // this.selMedSeLen = this.arrayMobile.length
          // this.newFunction()
        }
        // this.selectedSongsItems = []
        this.selectedSongsItems.forEach((data) => {
          this.songList.forEach((el) => {
            if (data.id === el.id && data.title === el.title) {
              el["itemChecked"] = true;
            }
          });
        });

        this.newSongItemData = this.selectedSongsItems.map((el) => ({
          id: el.id,
          sortOrder: 7,
          type: "MUSIC",
        }));

        this.songList.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });

        this.totalSongList = this.songList.slice(0, 4);
      });
  }

  getMediaSeries(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.ebook = false;
    this.livestream = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = true;
    this.linkItem = false;
    this.listItems = false;
    this.events = false;
    this.calender = false;
    this.newData1 = [];
    this.newData = [];

    this.mediaService
      .getMediaSeries(
        page,
        size,
        this.searchMediaSer ? this.searchMediaSer.nativeElement.value : "",
        "MEDIA_SERIES"
      )
      .subscribe((response: any) => {
        this.recentSeries = response.data.content;
        this.totalRecentSeries = response.data.totalElements;
        // this.recentSeries = this.recentSeries.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        this.recentSeriesList = this.recentSeries.slice(0, 4);
        // this.selectedItems.forEach((el) => el.itemChecked = event.target.checked);
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.recentSeries.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selMedSeLen = count;
                this.newData1.push(data);
              }
              //  this.selMedSeLen = count
            });
          });
          // this.selMedSeLen = this.arrayMobile.length
          // this.newFunction()
          // this.selectedMediaSeriesItems = [];
          this.selectedMediaSeriesItems.forEach((data) => {
            this.recentSeries.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newData = this.selectedMediaSeriesItems.map((el) => ({
          id: el.id,
          sortOrder: 1,
          type: "MEDIASERIES",
        }));

        this.recentSeries.forEach((data) => {
          if (data.squareArtwork) {
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document["newimg"] =
              `${appConfig.imageURL}` +
              id +
              "?height=" +
              `${appConfig.squareHeight}` +
              "&width=" +
              `${appConfig.squareWidth}`;
          }
          if (data.bannerArtwork) {
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document["newbannerimg"] =
              `${appConfig.imageURL}` +
              id +
              "?height=" +
              `${appConfig.bannerHeight}` +
              "&width=" +
              `${appConfig.bannerWidth}`;
          }
          if (data.wideArtwork) {
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document["newwideimg"] =
              `${appConfig.imageURL}` +
              id +
              "?height=" +
              `${appConfig.wideHeight}` +
              "&width=" +
              `${appConfig.wideWidth}`;
          }
        });
        this.totalMediaSeries = response.data.totalElements;

        // if (response.data.totalElements < 5) {
        //   this.showallMediaSeries = true
        // }else{
        //   this.showallMediaSeries = false
        // }
        // this.recentSeriesList = this.recentSeries.slice(0, 4);
        // // this.selectedItems.forEach((el) => el.itemChecked = event.target.checked);
        // if (this.arrayMobile) {
        //   let count = 0
        //   this.arrayMobile.forEach((data) => {

        //     this.recentSeries.forEach(el => {

        //       if (data.id === el.id) {
        //         el['itemChecked'] = true
        //         count = count + 1
        //         this.selMedSeLen = count
        //       }
        //       //  this.selMedSeLen = count
        //     });
        //   })
        //   // this.selMedSeLen = this.arrayMobile.length
        //   // this.newFunction()
        //   this.selectedMediaSeriesItems = [];
        //   this.selectedMediaSeriesItems.forEach((data => {
        //     this.recentSeries.forEach((el) => {
        //       if (data.id === el.id) {
        //         el['itemChecked'] = true
        //       }
        //     })
        //   }))
        // }
      });
  }

  getListItem(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.ebook = false;
    this.livestream = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.linkItem = false;
    this.listItems = true;
    this.folderChecked = true;
    this.mediaSeriesChecked = false;
    this.events = false;
    this.calender = false;
    this.newData4 = [];
    this.newFolderItemData = [];
    this.libraryService
      .getListItems(
        page,
        size,
        this.searchInput ? this.searchInput.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.trendingList = response.data.content ? response.data.content : [];
        this.totaltrendingList = response.data.totalElements;
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.trendingList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selFolItLen = count;
                this.newData4.push(data);
              }
            });
          });
          // this.selectedMediaSeriesItems = [];
          // this.selectedFolderItems = []
          this.selectedFolderItems.forEach((data) => {
            this.trendingList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newFolderItemData = this.selectedFolderItems.map((el) => ({
          id: el.id,
          sortOrder: 4,
          type: "LIST",
        }));

        this.totalListItems = response.data.totalElements;

        // if (response.data.totalElements < 5) {
        //   this.showallFolder = true
        // }else{
        //   this.showallFolder = false
        // }

        this.totalList = this.trendingList.slice(0, 4);
        this.trendingList.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            // if (data.squareArtwork.artworkType === "SQUARE") {
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document["newimg"] =
              `${appConfig.imageURL}` +
              id +
              "?height=" +
              `${appConfig.squareHeight}` +
              "&width=" +
              `${appConfig.squareWidth}`;
            // }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            // if (data.bannerArtwork.artworkType === "BANNER") {
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document["newbannerimg"] =
              `${appConfig.imageURL}` +
              id +
              "?height=" +
              `${appConfig.bannerHeight}` +
              "&width=" +
              `${appConfig.bannerWidth}`;
            // }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            // if (data.wideArtwork.artworkType === "WIDE") {
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document["newwideimg"] =
              `${appConfig.imageURL}` +
              id +
              "?height=" +
              `${appConfig.wideHeight}` +
              "&width=" +
              `${appConfig.wideWidth}`;
            // }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });
      });
  }

  getLinkItems(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.ebook = false;
    this.livestream = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.listItems = false;
    this.linkItem = true;
    this.events = false;
    this.calender = false;
    this.newData3 = [];
    this.newLinkItemData = [];

    this.libraryService
      .getLInkItem(
        page,
        size,
        this.searchLinks ? this.searchLinks.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.linkList = response.data.content;
        this.totalLinkList = response.data.totalElements;
        // this.linkList = this.linkList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.linkList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selLinItLen = count;
                this.newData3.push(data);
              }
            });
          });
          // this.selectedLinkItems = [];
          this.selectedLinkItems.forEach((data) => {
            this.linkList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newLinkItemData = this.selectedLinkItems.map((el) => ({
          id: el.id,
        }));

        this.totalLink = this.linkList.slice(0, 4);
        this.linkList.forEach((data) => {
          if (data.squareArtwork) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
          }
          if (data.bannerArtwork) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
          }
          if (data.wideArtwork) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
          }
        });
      });
  }

  getMediaItems(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.ebook = false;
    this.song = false;
    this.livestream = false;
    this.album = false;
    this.mediaItems = true;
    this.mediaSeries = false;
    this.listItems = false;
    this.linkItem = false;
    this.events = false;
    this.calender = false;
    this.newData2 = [];
    this.newMediaItemData = [];

    this.mediaService
      .getMediaItems(
        page,
        size,
        this.searchMediaItems ? this.searchMediaItems.nativeElement.value : "",
        "MEDIA_ITEM",
        ""
      )
      .subscribe((response: any) => {
        this.recentMedia = response.data.content;
        this.totalrecentMedia = response.data.totalElements;
        // this.recentMedia = this.recentMedia.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.recentMedia.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.newData2.push(data);
              }
            });
          });
          // this.selectedMediaItems = []
          this.selectedMediaItems.forEach((data) => {
            this.recentMedia.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newMediaItemData = this.selectedMediaItems.map((el) => ({
          id: el.id,
          sortOrder: 2,
          type: "MEDIA_ITEM",
        }));

        this.totalMediaItems = response.data.totalElements;
        // if (response.data.totalElements < 5) {
        //   this.showAllMediaItem = true
        // }else{
        //   this.showAllMediaItem = false
        // }

        this.totalMediaItem = this.recentMedia.slice(0, 4);
        this.recentMedia.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });
      });
  }

  getRssFeedList(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = true;
    this.ebook = false;
    this.song = false;
    this.livestream = false;
    this.album = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.listItems = false;
    this.linkItem = false;
    this.events = false;
    this.calender = false;
    this.newData5 = [];
    this.newRssitemData = [];
    this.libraryService
      .getRssFeed(
        page,
        size,
        this.searchRssFeed ? this.searchRssFeed.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.rssChecked = false;

        this.rssList = response.data.content;
        this.totalrssList = response.data.totalElements;
        // this.rssList = this.rssList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.rssList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selRssItLen = count;
                this.newData5.push(data);
              }
            });
          });
          // this.selectedRssItems = []
          this.selectedRssItems.forEach((data) => {
            this.rssList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newRssitemData = this.selectedRssItems.map((el) => ({
          id: el.id,
          sortOrder: 5,
          type: "RSSFEED",
        }));

        this.totalRssFeedItems = response.data.totalElements;
        // if (response.data.totalElements < 5) {
        //   this.showAllRssItem = true
        // }else{
        //   this.showAllRssItem = false
        // }
        this.totalRss = this.rssList.slice(0, 4);
        this.rssList.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });
      });
  }

  /**
   * To get Media Items
   * @param page Page
   * @param size Size
   */
  getEbooklist(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.song = false;
    this.album = false;
    this.livestream = false;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.listItems = false;
    this.linkItem = false;
    this.events = false;
    this.calender = false;
    this.ebook = true;
    this.newData10 = [];
    this.newEbookData = [];
    this.clickedFrom;
    this.mediaService
      .getMediaSeries(
        page,
        size,
        this.searchEbook ? this.searchEbook.nativeElement.value : "",
        "EBOOK_SERIES"
      )
      .subscribe((response: any) => {
        this.ebookList = response.data.content;
        this.totalebookList = response.data.totalElements;
        // this.ebookList = this.ebookList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.ebookList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selEbookLen = count;
                this.newData10.push(data);
              }
            });
          });
          // this.selectedRssItems = []
          this.selectedEbookItem.forEach((data) => {
            this.ebookList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newEbookData = this.selectedEbookItem.map((el) => ({
          id: el.id,
          sortOrder: 5,
          type: "EBOOK_READER",
        }));

        this.totalEbookItems = response.data.totalElements;
        // if (response.data.totalElements < 5) {
        //   this.showAllRssItem = true
        // }else{
        //   this.showAllRssItem = false
        // }
        this.totalEbook = this.ebookList.slice(0, 4);
        this.ebookList.forEach((data) => {
          if (data.squareArtwork) {
            // if(data.squareArtwork.document.bdImage === true){
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // }
          }
          if (data.bannerArtwork) {
            // if(data.bannerArtwork.document.bdImage === true){
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }

            // }
          }
          if (data.wideArtwork) {
            // if(data.wideArtwork.document.bdImage === true){
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }

            // }
          }
        });
        // this.getMediaSer(1,this.totalRecords)
      });
  }

  getEbookItemlist(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.ebook = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.livestream = false;
    this.ebookItem = true;
    this.mediaSeries = false;
    this.listItems = false;
    this.linkItem = false;
    this.events = false;
    this.calender = false;
    this.newData11 = [];
    this.newEbookItemData = [];

    this.mediaService
      .getMediaItems(
        page,
        size,
        this.searchEbookItem ? this.searchEbookItem.nativeElement.value : "",
        "EBOOK",
        ""
      )
      .subscribe((response: any) => {
        this.ebookItemList = response.data.content ? response.data.content : [];
        this.totalebookItemList = response.data.totalElements;
        // this.ebookItemList = this.ebookItemList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.ebookItemList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.newData11.push(data);
              }
            });
          });
          // this.selectedMediaItems = []
          this.selectedEbookItems.forEach((data) => {
            this.ebookItemList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newEbookItemData = this.selectedEbookItems.map((el) => ({
          id: el.id,
          sortOrder: 1,
          type: "EBOOK",
        }));

        this.totalEbooks = response.data.totalElements;
        // if (response.data.totalElements < 5) {
        //   this.showAllMediaItem = true
        // }else{
        //   this.showAllMediaItem = false
        // }

        this.totalEbookItemList = this.ebookItemList.slice(0, 4);

        this.ebookItemList.forEach((data) => {
          if (data.ebookArtwork) {
            if (data.ebookArtwork.artworkType === "EBOOK") {
              let id = data.ebookArtwork.document.id;
              data.ebookArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.ebookHeight}` +
                "&width=" +
                `${appConfig.ebookWidth}`;
            }
          }
        });
        // this.getMediaSer(1,this.totalRecords)
      });
  }

  getChannelItemlist(page, size) {
    this.rss = false;
    this.ebook = false;
    this.song = false;
    this.album = false;
    this.mediaItems = false;
    this.livestream = false;
    this.channelItem = true;
    this.mediaSeries = false;
    this.mediaSeriesChecked = false;
    this.listItems = false;
    this.linkItem = false;
    this.events = false;
    this.calender = false;
    this.newData11 = [];
    this.newChannelData = [];

    this.mediaService
      .getMediaItems(
        page,
        size,
        this.searchChannelItem
          ? this.searchChannelItem.nativeElement.value
          : "",
        "CHANNEL",
        ""
      )
      .subscribe((response: any) => {
        this.channelItemList = response.data.content
          ? response.data.content
          : [];
        this.channelItemList = this.channelItemList.filter((el, i) => {
          if (this.featureControlService.showCustomChannel) {
            if (el.channelType === "CUSTOM") {
              if(this.m3uChannelItemChecked){
                this.onItemChange("m3uChannel");
              }
              else{
                this.onItemChange("customChannel");
              }
              return el;
            }
          } else if (this.featureControlService.showM3uChannel) {
              if(this.m3uChannelItemChecked){
                this.onItemChange("m3uChannel");
              }
              else{
                this.onItemChange("customChannel");
              }
          } else if (
            this.featureControlService.showCustomChannel &&
            this.featureControlService.showM3uChannel
          ) {
              if(this.m3uChannelItemChecked){
                this.onItemChange("m3uChannel");
              }
              else{
                this.onItemChange("customChannel");
              }
          }
        });
        if (!this.featureControlService.showVOD) {
          if(this.m3uChannelItemChecked){
            this.onItemChange("m3uChannel");
          }
          else{
            this.onItemChange("customChannel");
          }
        }
        this.m3uChannelItemList = response.data.content
          ? response.data.content
          : [];
        this.m3uChannelItemList = this.m3uChannelItemList.filter((el, i) => {
          if (el.channelType === "M3U") {
            return el;
          }
        });
        this.totalChannelItemList = response.data.content
          ? response.data.content
          : [];
        this.totalChannelItemList = this.totalChannelItemList.filter(
          (el, i) => {
            if (this.featureControlService.showCustomChannel) {
              if (el.channelType === "CUSTOM") {
                return el;
              }
            }
          }
        );
        this.totalM3uChannelItemList = response.data.content
          ? response.data.content
          : [];
        this.totalM3uChannelItemList = this.totalM3uChannelItemList.filter(
          (el, i) => {
            if (el.channelType === "M3U") {
              return el;
            }
          }
        );
        // this.ebookItemList = this.ebookItemList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.channelItemList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.newData11.push(data);
              }
            });
            this.m3uChannelItemList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.newData11.push(data);
              }
            });
          });
          // this.selectedMediaItems = []
          this.selectedChannelItems.forEach((data) => {
            this.channelItemList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
            this.m3uChannelItemList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newChannelData = this.selectedChannelItems.map((el) => ({
          id: el.id,
          sortOrder: 1,
          type: "CHANNEL",
        }));

        this.totalChannels = response.data.totalElements;
        // if (response.data.totalElements < 5) {
        //   this.showAllMediaItem = true
        // }else{
        //   this.showAllMediaItem = false
        // }

        // this.totalChannelItemList = this.channelItemList.slice(0, 4)

        this.channelItemList.forEach((data) => {
          if (data.squareArtwork) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
          }
        });
        this.m3uChannelItemList.forEach((data) => {
          if (data.squareArtwork) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
          }
        });
        // this.getMediaSer(1,this.totalRecords)
      });
  }

  getAlbumList(page, size) {
    // this.selectedMediaSeriesItems = this.selectedMediaSeriesItems ? this.selectedMediaSeriesItems : this.selectedMediaSeriesItems = [];
    // this.selectedMediaItems = this.selectedMediaItems ? this.selectedMediaItems : this.selectedMediaItems = [];
    // this.selectedLinkItems = this.selectedLinkItems ? this.selectedLinkItems : this.selectedLinkItems = [];
    // this.selectedFolderItems = this.selectedFolderItems ? this.selectedFolderItems : this.selectedFolderItems = [];
    // this.selectedRssItems = this.selectedRssItems ? this.selectedRssItems : this.selectedRssItems = [];
    // this.selectedEbookItem = this.selectedEbookItem ? this.selectedEbookItem : this.selectedEbookItem = [];
    // this.selectedAlbumItems = this.selectedAlbumItems ? this.selectedAlbumItems : this.selectedAlbumItems = [];
    // this.selectedSongsItems = this.selectedSongsItems ? this.selectedSongsItems : this.selectedSongsItems = [];
    // this.selectedEventItems = this.selectedEventItems ? this.selectedEventItems : this.selectedEventItems = []
    // this.selectedCalenderItems = this.selectedCalenderItems ? this.selectedCalenderItems : this.selectedCalenderItems = []
    this.rss = false;
    this.song = false;
    this.album = true;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.listItems = false;
    this.livestream = false;
    this.linkItem = false;
    this.events = false;
    this.calender = false;
    this.ebook = false;
    this.newData6 = [];
    this.newAlbumItemData = [];
    this.mediaService
      .getAlbumList(
        page,
        size,
        this.searchAlbums ? this.searchAlbums.nativeElement.value : ""
      )
      .subscribe((response: any) => {
        this.musicList = response.data.content;
        this.totalmusicList = response.data.totalElements;
        // this.musicList = this.musicList.filter((el,i)=>{
        //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
        //           return el
        //         }
        // })
        this.listAlbumname = response.data.content.map((obj: any) => {
          return { label: obj.title, value: obj.id };
        });

        //

        // if (response.data.totalElements < 5) {
        //   this.showall = true
        // }else{
        //   this.showall = false
        // }
        this.totalAlbumList = response.data.totalElements;
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.musicList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.selAlbItLen = count;
                this.newData6.push(data);
              }
            });
          });
          // this.selectedAlbumItems = []
          this.selectedAlbumItems.forEach((data) => {
            this.musicList.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }

        this.newAlbumItemData = this.selectedAlbumItems.map((el) => ({
          id: el.id,
          sortOrder: 6,
          type: "ALBUM",
        }));

        this.allMusicList = this.musicList.slice(0, 4);
        this.musicList.forEach((data) => {
          if (data.squareArtwork) {
            // if (data.squareArtwork.document.bdImage === true) {
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.bannerArtwork) {
            // if (data.bannerArtwork.document.bdImage === true) {
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newbannerimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
          if (data.wideArtwork) {
            // if (data.wideArtwork.document.bdImage === true) {
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newwideimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

            // })
            // }
          }
        });
        // this.recentMusic = (search.trim() === '') ? response.data.mediaItemsDTOPage.content.slice(0, 5) : response.data.mediaItemsDTOPage.content;
        // this.recentSeriesList = (search.trim() === '') ? response.data.mediaSeriesDTOPage.content.slice(0, 4) : response.data.mediaSeriesDTOPage.content;
      });
  }

  createMediaItem(mediaItem) {
    // mediaItem.itemType = 'MEDIA_ITEM'
    if(this.type !== 'CHANNEL'){
      mediaItem.mediaSeriesId = this.tabId;
    }
    mediaItem.itemType = this.type;
    let channeltype = '';
    if(!this.featureControlService.showCustomChannel){
      channeltype = 'M3U'
    }
    this.mediaService.createChannelMediaItem(mediaItem,channeltype).subscribe(
      (response: any) => {
        if (this.type === "MEDIA_ITEM") {
          this.getMediaItems(1, 10);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Media Item created Successfully.",
          });
        } else if (this.type === "CHANNEL") {
          this.getChannelItemlist(1, 40);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Media Item created Successfully.",
          });
        } else {
          this.getEbookItemlist(1, 10);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Ebook created Successfully.",
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Something went wrong.",
        });
      }
    );
    if (
      this.clickedFrom === "ebookItem" ||
      this.clickedFrom === "videoItem" ||
      this.clickedFrom === "channelItem"
    ) {
      this.libraryService.sendUpdateToCreate(true);
    }
    if (this.type === "EBOOK") {
      this.ebookItem = true;
      this.ebookItemChecked = true;
    } else if (this.type === "CHANNEL") {
      this.channelItem = true;
      this.channelItemChecked = true;
    } else {
      this.mediaItems = true;
      this.mediaitemChecked = true;
    }

    // this.mediaItems = true;
    this.createMedIt = false;
    this.showOptions = true;
    // this.mediaitemChecked=true
    this.folderChecked = false;
    this.EventChecked = false;
    this.claenderChecked = false;
    this.albumChecked = false;
    this.linkChecked = false;
    this.rssChecked = false;
    this.ebookChecked = false;
    this.channelChecked = false;
    this.songChecked = false;
  }

  createMediaSeries() {
    this.mediaService
      .createMediaSeries({
        title: this.mediaSeriesTitle,
        seriesType: this.type,
      })
      .subscribe(
        (response: any) => {
          if (this.type === "EBOOK_SERIES") {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Ebook Library created Successfully.",
            });
            this.getEbooklist(1, 10);
          } else {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Video Series created Successfully.",
            });
            this.getMediaSeries(1, 10);
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong.",
          });
        }
      );
    if (this.type === "EBOOK_SERIES") {
      this.ebook = true;
      this.ebookChecked = true;
    } else {
      this.mediaSeries = true;
      this.mediaSeriesChecked = true;
    }
    // this.mediaSeries = true;
    this.createMediaSer = false;
    this.showOptions = true;
    this.mediaitemChecked = false;
    this.folderChecked = false;
    this.EventChecked = false;
    this.claenderChecked = false;
    this.albumChecked = false;
    this.linkChecked = false;
    this.rssChecked = false;
    // this.ebookChecked = false
    this.songChecked = false;
    this.channelChecked = false;
  }

  createListItem(listItem) {
    this.libraryService.createListItem(listItem).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Folder Item created Successfully",
        });
        this.getListItem(1, 10);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Something went wrong.",
        });
      }
    );
    this.listItems = true;
    this.createListIt = false;
    this.showOptions = true;
    this.mediaitemChecked = false;
    this.folderChecked = true;
    this.EventChecked = false;
    this.claenderChecked = false;
    this.albumChecked = false;
    this.linkChecked = false;
    this.rssChecked = false;
    this.ebookChecked = false;
    this.channelChecked = false;
    this.songChecked = false;
  }

  // selectMediaSeries(event, item,name) {
  //   if (item === 'all') {
  //     this.selectAll = event.target.checked;
  //     this.recentSeriesList.forEach((el) => el.itemChecked = event.target.checked);
  //     this.selectedItems = event.target.checked ? this.recentSeriesList : [];
  //   } else {
  //     this.recentSeriesList.forEach((el) => {
  //       if (el.id === item.id) {
  //         el.itemChecked = event.target.checked;
  //       }
  //     });
  //     this.selectedItems = this.recentSeriesList.filter((el) => el.itemChecked);
  //     this.selectAll = (this.selectedItems.length === this.recentSeriesList.length);
  //     let newdata = []
  //     // newdata =  this.selectedItems.map((data) => {
  //     //   itemName: name;
  //     //   itemData: data
  //     // })
  //     newdata = this.selectedItems.map((el) => ({
  //       id: el.id,
  //       name: name,
  //     }));
  //
  //
  //   }
  // }

  createMedSeries(type) {
    this.type = type;
    if (this.type === "EBOOK_SERIES") {
      this.ebook = false;
    } else {
      this.mediaSeries = false;
    }
    this.createMediaSer = true;
    this.showOptions = false;
    this.mediaSeriesTitle = "";
  }

  closeMediaSeries() {
    this.mediaSeries = true;
    this.createMediaSer = false;
    this.showOptions = true;
  }

  createMedItem(type) {
    this.type = type;
    if (this.type === "EBOOK") {
      this.ebookItem = false;
      this.ebookItemChecked = true;
    } else if (this.type === "CHANNEL") {
      this.channelItem = false;
      this.channelItemChecked = true;
      this.m3uChannelItemChecked = false;
    } else {
      this.mediaItems = false;
      this.mediaitemChecked = true;
    }

    this.createMedIt = true;
    this.showOptions = false;
    this.mediaItem = new MediaItem();
  }

  closeMediaItem(type) {
    this.type = type;
    if (this.type === "EBOOK") {
      this.ebookItem = true;
    } else if (this.type === "CHANNEL") {
      this.channelItem = true;
      this.m3uChannelItemChecked = false;
      this.channelItemChecked = true;
    } else {
      this.mediaItems = true;
    }

    this.createMedIt = false;
    this.showOptions = true;
  }

  createFolItem() {
    this.listItems = false;
    this.createListIt = true;
    this.showOptions = false;
    this.listItem = new ListItem();
  }

  createItem(data) {
    this.showOptions = false;
    if (data === "link") {
      this.linkItem = false;
      this.createLinkIt = true;
      this.LinkTitle = "";
      this.LinkSubtitle = "";
      this.LinkUrl = "";
      this.contact = "";
      this.Linktype = "APP_LINK";
      this.linkChecked = true;
    }
    if (data === "rss") {
      this.rss = false;
      this.createRssIt = true;
      this.rssSubtitle = "";
      this.rssTitle = "";
      this.rssUrl = "";
      this.rssChecked = true;
    }
    if (data === "EBOOK_READER") {
      this.ebook = false;
      this.ebookChecked = true;
    }
    if (data === "album") {
      this.album = false;
      this.createAlbumIt = true;
      this.albumTitle = "";
      this.albumChecked = true;
    }
    if (data === "song") {
      this.song = false;
      this.createSongIt = true;
      this.songTitle = "";
      this.songSubtitle = "";
      if (this.clickedFrom === "albumItem") {
        this.albumItemId = this.tabId;
      } else {
        this.albumItemId = null;
      }
      this.songChecked = true;
    }
    if (data === "calen") {
      this.calender = false;
      this.createCalenderIt = true;
      this.claenderChecked = true;
      this.calenderTitle = "";
      this.calenderSubtitle = "";
      this.claenderChecked = true;
    }
    if (data === "event") {
      this.events = false;
      this.createEventIt = true;
      this.EventChecked = true;
      this.EventTitle = "";
      this.EventSubtitle = "";
      this.EventendTime = "";
      this.EventendDate = "";
      this.EventstartTime = "";
      this.EventstartDate = "";
      this.repeatDay = "";
      this.calenderId = null;

      this.eventSubmitted = false;

      if (this.timeZone === "IST") {
        this.timeZone = "Asia/Kolkata";
      }
      // this.date = new Date()
      // this.EventstartDate = new Date()
      // this.EventendDate = new Date()

      if (!this.EventstartDate) {
        this.EventstartDate = new Date();
        let newscheduleDate = moment.utc(this.EventstartDate);
        var displayCutoff = newscheduleDate.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventstartDate = formatDate;
        this.EventstartDate = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
      } else {
        this.EventstartDate = this.EventstartDate + " " + this.EventstartTime;
        let newscheduleDate = moment.utc(this.EventstartDate);
        var displayCutoff = newscheduleDate.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD");
        this.EventstartDate = formatDate;
        this.EventstartDate = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
      }

      if (!this.EventendDate) {
        this.EventendDate = new Date();
        let newscheduleDate = moment.utc(this.EventendDate);
        var displayCutoff = newscheduleDate.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventendDate = formatDate;
        this.EventendDate = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
      } else {
        this.EventendDate = this.EventendDate + " " + this.EventendTime;
        let newscheduleDate = moment.utc(this.EventendDate);
        var displayCutoff = newscheduleDate.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventendDate = formatDate;
        this.EventendDate = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
      }

      if (!this.EventstartTime) {
        // this.EventstartTime = this.EventstartDate
        // this.EventstartTime = this.EventendDate
        this.EventstartTime = new Date();
        let newscheduleTime = moment.utc(this.EventstartTime);
        var displayCutoff = newscheduleTime.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventstartTime = formatDate;
        this.EventstartTime = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
        this.checkStartTime = this.EventstartTime;

        this.startTimeData = moment(this.EventstartTime).format("h:mm a");
      } else {
        this.EventstartDate = moment(this.EventstartDate).format("YYYY-MM-DD");
        this.EventstartTime = this.EventstartDate + " " + this.EventstartTime;

        // this.editMedia.liveStreamDataDTO.startTime = new Date(this.editMedia.liveStreamDataDTO.startTime);

        let newscheduleTime = moment.utc(this.EventstartTime);
        var displayCutoff = newscheduleTime.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventstartTime = formatDate;

        // this.showScheduleTime = this.editMedia.scheduleTime;

        this.EventstartTime = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
        this.checkStartTime = this.EventstartTime;
        // this.editMedia.liveStreamDataDTO.startTime = new Date(this.editMedia.liveStreamDataDTO.startTime);
        this.startTimeData = moment(this.EventstartTime).format("h:mm a");
      }
      // this.editMedia.liveStreamDataDTO.startTime = this.editMedia.liveStreamDataDTO.startDate + " " + this.editMedia.liveStreamDataDTO.startTime

      if (!this.EventendTime) {
        // this.EventendTime = this.EventendDate
        this.EventendTime = new Date();
        let newscheduleTime = moment.utc(this.EventendTime);
        var displayCutoff = newscheduleTime.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventendTime = formatDate;
        this.EventendTime = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
        this.checkEndTime = this.EventendTime;

        this.endTimeData = moment(this.EventendTime).format("h:mm a");
      } else {
        this.EventendDate = moment(this.EventendDate).format("YYYY-MM-DD");
        this.EventendTime = this.EventendDate + " " + this.EventendTime;

        // this.editMedia.liveStreamDataDTO.endTime = new Date(this.editMedia.liveStreamDataDTO.endTime);

        let newscheduleTime = moment.utc(this.EventendTime);
        var displayCutoff = newscheduleTime.clone().tz(this.timeZone);
        let formatDate = moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
        this.EventendTime = formatDate;

        // this.showScheduleTime = this.editMedia.scheduleTime;

        // this.editMedia.liveStreamDataDTO.endTime = new Date(this.editMedia.liveStreamDataDTO.endTime);

        this.EventendTime = moment(formatDate, "YYYY-MM-DD, h:mm a").toDate();
        this.checkEndTime = this.EventendTime;

        this.endTimeData = moment(this.EventendTime).format("h:mm a");
        // this.editMedia.liveStreamDataDTO.endTime = moment(this.editMedia.liveStreamDataDTO.endTime).toDate();
      }

      if (!this.date) {
        this.date = new Date();
        let newscheduleTime3 = moment.utc(this.date);
        var displayCutoff3 = newscheduleTime3.clone().tz(this.timeZone);
        let formatDate3 = moment(displayCutoff3).format("YYYY-MM-DD, h:mm a");
        this.date = formatDate3;
        this.date = moment(formatDate3, "YYYY-MM-DD, h:mm a").toDate();
      }

      this.invalidStartTime = true;
      this.invalidEndTime = true;

      this.getCalenItem(1, 1000);
    }
  }

  closeItem(data) {
    this.showOptions = true;
    if (data === "link") {
      this.linkItem = true;
      this.createLinkIt = false;
    }
    if (data === "rss") {
      this.rss = true;
      this.createRssIt = false;
    }
    if (data === "EBOOK_READER") {
      this.ebook = true;
      // this.createEbook = false
    }
    if (data === "album") {
      this.album = true;
      this.createAlbumIt = false;
    }
    if (data === "song") {
      this.song = true;
      this.createSongIt = false;
    }
    if (data === "calen") {
      this.calender = true;
      this.createCalenderIt = false;
    }
    if (data === "event") {
      this.events = true;
      this.createEventIt = false;
    }
  }

  closeFolderItem() {
    this.listItems = true;
    this.createListIt = false;
    this.showOptions = true;
    this.folderChecked = true;
  }

  getSubscriptionData2() {
    this.subsciptionData2.add(
      this.libraryService.clicked$.subscribe((response: any) => {
        if (
          response.clickedFrom === "mobile" ||
          response.clickedFrom === "list"
        ) {
          setTimeout(() => {
            let element: HTMLElement = document.getElementById(
              "mediaSeriesId"
            ) as HTMLElement;
            element.click();
          }, 500);
        }
        if (response.clickedFrom === "music") {
          setTimeout(() => {
            let element: HTMLElement = document.getElementById(
              "album"
            ) as HTMLElement;
            element.click();
          }, 500);
        }
      })
    );
  }

  getSubscriptionData() {
    this.timeZone = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.timeZone
      : "";
    this.profitable = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
          .organization.profit
      : "";
    this.emptySearch();
    if (this.profitable === true) {
      this.showGivingCard = false;
    } else {
      this.showGivingCard = true;
    }
    this.featureList = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.featureList
      : null;
    // console.log(this.showHideGiving);
    console.log(this.profitable);
    if (this.featureList) {
      this.featureList.forEach((el) => {
        console.log(el.name);

        if (el.name === "Giving") {
          this.showGiving = el.isView;
        }
      });
    }
    let liveStreamShowHide = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
          .organization.liveStreamShowHide
      : "";
    let featurelist = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.featureList
      : "";
    this.showLive = false;
    featurelist.forEach((element) => {
      if (element.name === "Live Streaming") {
        this.showLive = true;
      }
    });
    if (liveStreamShowHide === "true") {
      this.showLiveStream = true;
    } else {
      this.showLiveStream = false;
    }
    this.subsciptionData.add(
      this.libraryService.panel$.subscribe((state: any) => {
        console.log("check --- state", state);
        this.overlay = state.showPanel;
        this.sidePanel = state.showPanel;
        this.getStripeDataExist();
        if (state.tvMed) {
          this.tvMed = state.tvMed;
        }
        if (state.type) {
          if (state.type === "LIST") {
            this.folderChecked = true;
          }
        }
        this.panel = state?.panel ? state?.panel : null;
        this.tabId = state?.tabId ? state?.tabId : null;
        // this.opacity = state.opacity
        this.clickedFrom = state.clickedFrom;
        console.log("check---------,", this.clickedFrom);
        if (this.route.snapshot.paramMap["params"]["getFrom"]) {
          this.routeFrom = this.route.snapshot.paramMap["params"]["getFrom"];
        }
        if (this.clickedFrom === "albumItem") {
          this.albumItemId = this.tabId;
        }

        if (
          this.clickedFrom === "buildOwnView" ||
          this.clickedFrom === "ebook" ||
          this.clickedFrom === "tv"
        ) {
          // this.tabId = state.tabId ? state.tabId : null;
          this.arrayMobile = state.arrayMobile;

          if (this.arrayMobile.length === 0) {
            this.combined = [];
            this.combined1 = [];
            this.selectedMediaSeriesItems = [];
            this.selectedStreamItems = [];
            this.selectedMediaItems = [];
            this.selectedLinkItems = [];
            this.selectedFolderItems = [];
            this.selectedRssItems = [];
            this.selectedEbookItem = [];
            this.selectedAlbumItems = [];
            this.selectedSongsItems = [];
            this.selectedEventItems = [];
            this.selectedCalenderItems = [];
            this.newData = [];
            this.newData1 = [];
            this.newData2 = [];
            this.newData3 = [];
            this.newData4 = [];
            this.newData5 = [];
            this.newData6 = [];
            this.newData7 = [];
            this.newData8 = [];
            this.newData9 = [];
            this.newData10 = [];
            this.newData11 = [];
          }
          this.popUp = false;

          if (this.featureControlService.showVOD) {
            this.mediaSeries = true;
            this.mediaSeriesChecked = true;
          } else if (this.featureControlService.showMusic) {
            this.album = true;
            this.albumChecked = true;
          } else if (this.featureControlService.showEvent) {
            this.events = true;
            this.EventChecked = true;
          } else if (this.featureControlService.showLinks) {
            this.linkItem = true;
            this.linkChecked = true;
          } else if (this.featureControlService.showRss) {
            this.rss = true;
            this.rssChecked = true;
          } else if (this.featureControlService.showBookStore) {
            this.ebook = true;
            this.ebookItemChecked = true;
          } else {
            this.mediaSeries = false;
            this.mediaSeriesChecked = false;
            this.rss = false;
            this.rssChecked = false;
            this.ebook = false;
            this.ebookItemChecked = false;
            this.events = false;
            this.EventChecked = false;
            this.linkItem = false;
            this.linkChecked = false;
          }

          // this.ebook = false;
          this.channel = false;
          this.song = false;
          this.album = false;
          this.mediaItems = false;
          // this.mediaSeries = true;
          // this.linkItem = false;
          this.listItems = false;
          // this.events = false;
          this.calender = false;
          this.showOptions = true;
          this.folderChecked = false;
          this.EventChecked = false;
          this.mediaitemChecked = false;
          this.claenderChecked = false;
          // this.mediaSeriesChecked = true;
          this.albumChecked = false;
          // this.linkChecked = false;
          // this.rssChecked = true;
          this.ebookChecked = false;
          this.channelChecked = false;
          this.songChecked = false;

          if (this.clickedFrom === "ebook") {
            setTimeout(() => {
              let element: HTMLElement = document.getElementById(
                "ebookId"
              ) as HTMLElement;
              element.click();
            }, 500);
            this.getEbooklist(1, 1000);
          }
          // else if (this.clickedFrom === 'channelItem') {
          //   setTimeout(() => {
          //     let element: HTMLElement = document.getElementById('channelId') as HTMLElement;
          //     element.click();
          //   }, 500);
          //   this.getEbooklist(1, 1000)
          // }
          else if (state.itemType === "series") {
            let element: HTMLElement = document.getElementById(
              "mediaSeriesId"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "vidItem") {
            let element: HTMLElement = document.getElementById(
              "mediaItem"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "libItem") {
            let element: HTMLElement = document.getElementById(
              "ebook"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "liveItem") {
            let element: HTMLElement = document.getElementById(
              "livestream"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "rssItem") {
            let element: HTMLElement = document.getElementById(
              "rss"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "linkItem") {
            let element: HTMLElement = document.getElementById(
              "link"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "eventItem") {
            let element: HTMLElement = document.getElementById(
              "event"
            ) as HTMLElement;
            element.click();
          } else if (state.itemType === "calendarItem") {
            let element: HTMLElement = document.getElementById(
              "calender"
            ) as HTMLElement;
            element.click();
          }
          //  else if (state.itemType === "albumItem") {
          //   let element: HTMLElement = document.getElementById(
          //     "album"
          //   ) as HTMLElement;
          //   element.click();
          // }
          else if (state.itemType === "songItem") {
            let element: HTMLElement = document.getElementById(
              "song"
            ) as HTMLElement;
            element.click();
          } else {
            // if (this.featureControlService.showVOD) {
            //   let element: HTMLElement = document.getElementById(
            //     "mediaSeriesId"
            //   ) as HTMLElement;
            //   element.click();
            //   console.log("inside vod");
            // } else if (this.featureControlService.showFolder) {
            //   let element: HTMLElement = document.getElementById(
            //     "folder"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (this.featureControlService.showMusic) {
            //   let element: HTMLElement = document.getElementById(
            //     "album"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (this.featureControlService.showEvent) {
            //   let element: HTMLElement = document.getElementById(
            //     "event"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (this.featureControlService.showLinks) {
            //   let element: HTMLElement = document.getElementById(
            //     "link"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (this.featureControlService.showRss) {
            //   let element: HTMLElement = document.getElementById(
            //     "rss"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (this.featureControlService.showBookStore) {
            //   let element: HTMLElement = document.getElementById(
            //     "ebook"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (this.featureControlService.showLive) {
            //   let element: HTMLElement = document.getElementById(
            //     "livestream"
            //   ) as HTMLElement;
            //   element.click();
            // }

            if (this.featureControlService.showVOD) {
              let element: HTMLElement = document.getElementById(
                "mediaSeriesId"
              ) as HTMLElement;
              element.click();
              console.log("inside vod");
            } else if (this.featureControlService.showFolder) {
              let element: HTMLElement = document.getElementById(
                "folder"
              ) as HTMLElement;
              element.click();
            } else if (this.featureControlService.showEvent) {
              let element: HTMLElement = document.getElementById(
                "event"
              ) as HTMLElement;
              element.click();
            } else if (this.featureControlService.showMusic) {
              let element: HTMLElement = document.getElementById(
                "album"
              ) as HTMLElement;
              element.click();
            } else if (this.featureControlService.showLinks) {
              let element: HTMLElement = document.getElementById(
                "link"
              ) as HTMLElement;
              element.click();
            } else if (this.featureControlService.showRss) {
              let element: HTMLElement = document.getElementById(
                "rss"
              ) as HTMLElement;
              element.click();
            } else if (this.featureControlService.showBookStore) {
              let element: HTMLElement = document.getElementById(
                "ebook"
              ) as HTMLElement;
              element.click();
            } else if (this.featureControlService.showLive) {
              let element: HTMLElement = document.getElementById(
                "livestream"
              ) as HTMLElement;
              element.click();
            }
          }
        }
        // if(this.clickedFrom === 'music'){
        //   setTimeout(() => {
        //     let element:HTMLElement = document.getElementById('album') as HTMLElement;
        //   element.click();
        //   }, 500);
        // }
        if (this.clickedFrom === "notification") {
          if (this.featureControlService.showVOD) {
            let element: HTMLElement = document.getElementById(
              "mediaSeriesId"
            ) as HTMLElement;
            element.click();
          } else {
            let element: HTMLElement = document.getElementById(
              "folder"
            ) as HTMLElement;
            element.click();
          }
        }
        if (this.clickedFrom === "mobile" || this.clickedFrom === "list") {
          setTimeout(() => {
            if (state.itemType === "series") {
              let element: HTMLElement = document.getElementById(
                "mediaSeriesId"
              ) as HTMLElement;
              element.click();
            }
            // else if (state.itemType === "vidItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "mediaItem"
            //   ) as HTMLElement;
            //   element.click();
            // }
            // if (state.itemType === "libItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "ebook"
            //   ) as HTMLElement;
            //   element.click();
            // }
            // else if (state.itemType === "liveItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "livestream"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (state.itemType === "rssItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "rss"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (state.itemType === "linkItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "link"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (state.itemType === "eventItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "event"
            //   ) as HTMLElement;
            //   element.click();
            // } else if (state.itemType === "calendarItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "calender"
            //   ) as HTMLElement;
            //   element.click();
            // }
            // else if (state.itemType === "albumItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "album"
            //   ) as HTMLElement;
            //   element.click();
            // }
            // else if (state.itemType === "songItem") {
            //   let element: HTMLElement = document.getElementById(
            //     "song"
            //   ) as HTMLElement;
            //   element.click();
            // }
            else {
              if (this.featureControlService.showVOD) {
                let element: HTMLElement = document.getElementById(
                  "mediaSeriesId"
                ) as HTMLElement;
                element.click();
                console.log("inside vod");
              } else if (this.featureControlService.showFolder) {
                let element: HTMLElement = document.getElementById(
                  "folder"
                ) as HTMLElement;
                element.click();
              } else if (this.featureControlService.showMusic) {
                let element: HTMLElement = document.getElementById(
                  "album"
                ) as HTMLElement;
                element.click();
              } else if (this.featureControlService.showEvent) {
                let element: HTMLElement = document.getElementById(
                  "event"
                ) as HTMLElement;
                element.click();
              } else if (this.featureControlService.showLinks) {
                let element: HTMLElement = document.getElementById(
                  "link"
                ) as HTMLElement;
                element.click();
              } else if (this.featureControlService.showRss) {
                let element: HTMLElement = document.getElementById(
                  "rss"
                ) as HTMLElement;
                element.click();
              } else if (this.featureControlService.showBookStore) {
                let element: HTMLElement = document.getElementById(
                  "ebook"
                ) as HTMLElement;
                element.click();
              } else if (this.featureControlService.showLive) {
                let element: HTMLElement = document.getElementById(
                  "livestream"
                ) as HTMLElement;
                element.click();
              }
            }
          }, 500);

          //   let element: HTMLElement = document.getElementById('mediaSeriesId') as HTMLElement;
          //   element ? element.click() : null;
          // }, 500);
        }
        if (this.routeFrom === "music") {
          setTimeout(() => {
            if (state.itemType === "songItem") {
              let element: HTMLElement = document.getElementById(
                "song"
              ) as HTMLElement;
              element.click();
            } 
            else{
              let element: HTMLElement = document.getElementById(
                "album"
                ) as HTMLElement;
                element.click();
                this.getAlbumList(1, 10);
              }
            if (state.arrayMobile ? state.arrayMobile.length === 0 : null) {
              this.combined = [];
              this.combined1 = [];
              this.selectedMediaSeriesItems = [];
              this.selectedStreamItems = [];
              this.selectedMediaItems = [];
              this.selectedLinkItems = [];
              this.selectedFolderItems = [];
              this.selectedRssItems = [];
              this.selectedEbookItem = [];
              this.selectedAlbumItems = [];
              this.selectedSongsItems = [];
              this.selectedEventItems = [];
              this.selectedCalenderItems = [];
              this.newData = [];
              this.newData1 = [];
              this.newData2 = [];
              this.newData3 = [];
              this.newData4 = [];
              this.newData5 = [];
              this.newData6 = [];
              this.newData7 = [];
              this.newData8 = [];
              this.newData9 = [];
              this.newData10 = [];
              this.newData11 = [];
            }
          }, 500);
        } 
        else if (this.clickedFrom === "eventEmbed") {
          this.getCalenderItem(1, 10);
        } else if (this.tvMed) {
          this.getMediaItems(1, 10);
        } else if (this.clickedFrom === "tvApp") {
          this.popUp = false;
          this.folderChecked = true;
          this.showallFolder = false;
          this.listItems = true;
          this.createListIt = false;
          this.showOptions = true;
          this.getListItem(1, 10);
        } else if (this.clickedFrom === "ebookItem") {
          this.popUp = false;
          this.ebookItemChecked = true;
          this.ebookItem = true;
          this.showAllEbook = false;
          // this.listItems = true
          // this.createListIt = false
          this.showOptions = true;
          if (!state.selectData) {
            this.selectedEbookItems = [];
          }
          // this.getListItem(1, 10)
          this.getEbookItemlist(1, 10);
        } else if (this.clickedFrom === "channelItem") {
          this.popUp = false;
          this.channelItemChecked = true;
          this.channelItem = false;
          this.mediaSeriesChecked = false;
          this.mediaSeries = false;
          setTimeout(() => {
            this.showAllChannel = false;
          }, 2000);
          // this.listItems = true
          // this.createListIt = false
          this.showOptions = true;
          this.m3uChannelItemChecked = false;
          this.channelItemChecked = true;
          if (!state.selectData) {
            this.selectedChannelItems = [];
          }
          if (state.selectedItem) {
            if (state.selectedItem.length > 0) {
              this.selectedChannelItems = state.selectedItem;
              this.newData11 = this.selectedChannelItems;
              this.newData11 = this.newData11.map((el) => ({
                ebookArtworkId: el.ebookArtwork
                  ? el.ebookArtwork.document.id
                  : null,
                bannerArtworkId: el.bannerArtwork
                  ? el.bannerArtwork.document.id
                  : null,
                id: el.id,
                itemChecked: el.itemChecked,
                squareArtworkId: el.squareArtwork
                  ? el.squareArtwork.document.id
                  : null,
                subtitle: el.subTitle,
                title: el.title,
                wideArtworkId: el.wideArtwork
                  ? el.wideArtwork.document.id
                  : null,
                type: "CHANNEL",
                channelType: el.channelType,
              }));
            }
          }
          // this.getListItem(1, 10)
          this.getChannelItemlist(1, 40);
        } else if (this.clickedFrom === "videoItem") {
          this.popUp = false;
          this.mediaitemChecked = true;
          this.mediaItems = true;
          this.showAllMediaItem = false;
          this.showOptions = true;
          if (!state.selectData) {
            this.selectedMediaItems = [];
          }
          this.getMediaItems(1, 10);
        } else if (this.clickedFrom === "albumItem") {
          this.popUp = false;
          this.songChecked = true;
          this.song = true;
          this.showAllSongs = false;
          this.showOptions = true;
          if (!state.selectData) {
            this.selectedSongsItems = [];
          }
          this.getAllSongList(1, 10);
        } else if (this.clickedFrom === "calendarItem") {
          this.popUp = false;
          this.EventChecked = true;
          this.events = true;
          this.showAllEvents = false;
          this.showOptions = true;
          this.getAllEventItem(1, 10);
          if (!state.selectData) {
            this.selectedEventItems = [];
          }
        } else if (this.clickedFrom === "podcast") {
          if (this.featureControlService.showVOD) {
            let element: HTMLElement = document.getElementById(
              "mediaSeriesId"
            ) as HTMLElement;
            element.click();
            // if (this.mediaSeriesChecked == true) {
            //   this.getMediaSeries(1, 10)
            // }
          } else {
            let element: HTMLElement = document.getElementById(
              "folder"
            ) as HTMLElement;
            element.click();
            // this.getListItem(1, 10)
          }
        }

        // else if(this.folderChecked){
        //   this.showallFolder = false
        //   this.listItems = true
        //   this.createListIt = false
        //   this.showOptions = true
        //   this.getListItem(1,5)
        // }
        else {
          if (this.clickedFrom === "ebook") {
            this.getEbooklist(1, 10);
          } else {
            if (this.clickedFrom === "mobile") {
              // this.getEbooklist(1, 1000);
              // this.getCalenderItem(1, 1000);
              // this.getAllEventItem(1, 1000);
              // this.getAllSongList(1, 1000);
              if (this.featureControlService.showVOD) {
                this.getMediaSeries(1, 1000);
              } else if (this.featureControlService.showFolder) {
                this.getListItem(1, 1000);
              } else if (this.featureControlService.showEvent) {
                this.getAllEventItem(1, 1000);
              } else if (this.featureControlService.showMusic) {
                this.getAlbumList(1, 1000);
              } else if (this.featureControlService.showLinks) {
                this.getLinkItems(1, 1000);
              } else if (this.featureControlService.showRss) {
                this.getRssFeedList(1, 1000);
              } else if (this.featureControlService.showLive) {
                this.getLiveStreamItems(1, 1000);
              }
              // this.getAllSongList(1, 1000);
              // this.getMediaItems(1, 1000);
              // this.getCalenderItem(1, 1000);

              // else if (this.featureControlService.showMusic) {
              //   this.getAlbumList(1, 1000);
              // } else if (this.featureControlService.showLive) {
              //   this.getLiveStreamItems(1, 1000);
              // }
              // this.getLinkItems(1, 1000);
              // this.getRssFeedList(1, 1000);
            } else {
              if (state.itemType === "vidItem") {
                let element: HTMLElement = document.getElementById(
                  "mediaItem"
                ) as HTMLElement;
                element.click();
              }
              //  else if (state.itemType === 'series') {
              //   let element: HTMLElement = document.getElementById('mediaSeriesId') as HTMLElement;
              //   element.click();
              // }
              // else if (state.itemType === 'vidItem') {
              //   let element: HTMLElement = document.getElementById('mediaItem') as HTMLElement;
              //   element.click();
              // }
              else if (state.itemType === "libItem") {
                let element: HTMLElement = document.getElementById(
                  "ebook"
                ) as HTMLElement;
                element.click();
              } else if (state.itemType === "liveItem") {
                let element: HTMLElement = document.getElementById(
                  "livestream"
                ) as HTMLElement;
                element.click();
              } else if (state.itemType === "rssItem") {
                let element: HTMLElement = document.getElementById(
                  "rss"
                ) as HTMLElement;
                element.click();
              } else if (state.itemType === "linkItem") {
                let element: HTMLElement = document.getElementById(
                  "link"
                ) as HTMLElement;
                element.click();
              } else if (state.itemType === "eventItem") {
                let element: HTMLElement = document.getElementById(
                  "event"
                ) as HTMLElement;
                element.click();
              } else if (state.itemType === "calendarItem") {
                let element: HTMLElement = document.getElementById(
                  "calender"
                ) as HTMLElement;
                element.click();
              } else if (state.itemType === "albumItem") {
                // let element: HTMLElement = document.getElementById(
                //   "album"
                // ) as HTMLElement;
                // element.click();
              } else if (state.itemType === "songItem") {
                let element: HTMLElement = document.getElementById(
                  "song"
                ) as HTMLElement;
                element.click();
              } else {
                if (this.featureControlService.showVOD) {
                  this.getMediaSeries(1, 10);
                }
              }
            }
          }
        }
      })
    );

    this.subsciptionData.add(
      this.libraryService.sendartworktype$.subscribe((response: any) => {
        this.carouselArtworkType = response?.carouselArtworkType
          ? response?.carouselArtworkType
          : null;
        // //  this.clickedFrom = response.clickedFrom
        //
      })
    );

    this.subsciptionData.add(
      this.libraryService.sendDataListToAddList$.subscribe((response: any) => {
        this.responseData = response;
        if (this.combined.length === 0) {
          this.selectedMediaSeriesItems = [];
          this.selectedStreamItems = [];
          this.selectedMediaItems = [];
          this.selectedLinkItems = [];
          this.selectedFolderItems = [];
          this.selectedRssItems = [];
          this.selectedEbookItem = [];
          this.selectedAlbumItems = [];
          this.selectedSongsItems = [];
          this.selectedEventItems = [];
          this.selectedCalenderItems = [];
          this.medSeLen = 0;
          this.selMedSeLen = 0;
          this.selLiveStreamLen = 0;
          this.medItLen = 0;
          this.selMedItLen = 0;
          this.folItLen = 0;
          this.selFolItLen = 0;
          this.linItLen = 0;
          this.selLinItLen = 0;
          this.rssItLen = 0;
          this.ebookLen = 0;
          this.selRssItLen = 0;
          this.selEbookLen = 0;
          this.songItLen = 0;
          this.selSongLen = 0;
          this.eventItLen = 0;
          this.selEventLen = 0;
          this.calenderItLen = 0;
          this.selCalenderItLen = 0;
          this.AlbItLen = 0;
          this.selAlbItLen = 0;
        }
      })
    );

    this.subsciptionData.add(
      this.libraryService.sendSaveCarousel$.subscribe((response: any) => {
        this.arrayMobile = response;
        if (this.arrayMobile.length === 5) {
          this.popUp = true;
        } else if (this.arrayMobile.length === 0) {
          this.selectedMediaSeriesItems = [];
          this.selectedStreamItems = [];
          this.selectedMediaItems = [];
          this.selectedLinkItems = [];
          this.selectedFolderItems = [];
          this.selectedRssItems = [];
          this.selectedEbookItem = [];
          this.selectedAlbumItems = [];
          this.selectedSongsItems = [];
          this.selectedEventItems = [];
          this.selectedCalenderItems = [];
          this.selectedEbookItems = [];
          this.medSeLen = 0;
          this.selMedSeLen = 0;
          this.selLiveStreamLen = 0;
          this.medItLen = 0;
          this.selMedItLen = 0;
          this.folItLen = 0;
          this.selFolItLen = 0;
          this.linItLen = 0;
          this.selLinItLen = 0;
          this.rssItLen = 0;
          this.ebookLen = 0;
          this.selRssItLen = 0;
          this.selEbookLen = 0;
          this.songItLen = 0;
          this.selSongLen = 0;
          this.eventItLen = 0;
          this.selEventLen = 0;
          this.calenderItLen = 0;
          this.selCalenderItLen = 0;
          this.AlbItLen = 0;
          this.selAlbItLen = 0;
          this.popUp = false;
        } else {
          this.popUp = false;
        }
        if (this.arrayMobile.length > 0) {
          this.medSeLen = 0;
          this.selMedSeLen = 0;
          this.selLiveStreamLen = 0;
          this.medItLen = 0;
          this.selMedItLen = 0;
          this.folItLen = 0;
          this.selFolItLen = 0;
          this.linItLen = 0;
          this.selLinItLen = 0;
          this.rssItLen = 0;
          this.ebookLen = 0;
          this.selRssItLen = 0;
          this.selEbookLen = 0;
          this.songItLen = 0;
          this.selSongLen = 0;
          this.eventItLen = 0;
          this.selEventLen = 0;
          this.calenderItLen = 0;
          this.selCalenderItLen = 0;
          this.AlbItLen = 0;
          this.selAlbItLen = 0;
          this.arrayMobile.forEach((el) => {
            if (el.type === "MEDIASERIES") {
              this.medSeLen = this.medSeLen + 1;
              this.selMedSeLen = this.medSeLen;
            } else if (el.type === "MEDIA_ITEM") {
              this.medItLen = this.medItLen + 1;
              this.selMedItLen = this.medItLen;
            } else if (el.type === "LIST") {
              this.folItLen = this.folItLen + 1;
              this.selFolItLen = this.folItLen;
            } else if (el.type === "LINK") {
              this.linItLen = this.linItLen + 1;
              this.selLinItLen = this.linItLen;
            } else if (el.type === "RSSFEED") {
              this.rssItLen = this.rssItLen + 1;
              this.selRssItLen = this.rssItLen;
            } else if (el.type === "EBOOK_READER") {
              this.ebookLen = this.ebookLen + 1;
              this.selEbookLen = this.ebookLen;
            } else if (el.type === "SONG") {
              this.songItLen = this.songItLen + 1;
              this.selSongLen = this.songItLen;
            } else if (el.type === "EVENT") {
              this.eventItLen = this.eventItLen + 1;
              this.selEventLen = this.eventItLen;
            } else if (el.type === "CALENDAR") {
              this.calenderItLen = this.calenderItLen + 1;
              this.selCalenderItLen = this.calenderItLen;
            } else {
              this.AlbItLen = this.AlbItLen + 1;
              this.selAlbItLen = this.AlbItLen;
            }
          });
        }
        // this.getMediaSeries(1, 10)
      })
    );

    // this.getMediaSeries(1, 10)
    // this.getLinkItems(1,5)

    this.subsciptionData.add(
      this.libraryService.sendCarouselMobileLength$.subscribe(
        (response: any) => {
          this.carouselMaxLength = response;
        }
      )
    );

    this.totalCheckedLength =
      this.selMedSeLen +
      this.selLiveStreamLen +
      this.selMedItLen +
      this.selFolItLen +
      this.selLinItLen +
      this.selAlbItLen +
      this.selRssItLen +
      this.selEbookLen +
      this.selSongLen;

    this.subsciptionData.add(
      this.libraryService.sendRemovedData$.subscribe((response: any) => {
        this.removedDataArray = response;

        this.removedDataArray;

        this.removedDataArray.forEach((el) => {
          if (el.type === "MEDIASERIES") {
            this.selectedMediaSeriesItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedMediaSeriesItems.indexOf(element);
                this.selectedMediaSeriesItems.splice(i, 1);
                this.selMedSeLen = this.selectedMediaSeriesItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "MEDIA_ITEM") {
            this.selectedMediaItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedMediaItems.indexOf(element);
                this.selectedMediaItems.splice(i, 1);
                this.selMedItLen = this.selectedMediaItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "LIST") {
            this.selectedFolderItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedFolderItems.indexOf(element);
                this.selectedFolderItems.splice(i, 1);
                this.selFolItLen = this.selectedFolderItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "LINK") {
            this.selectedLinkItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedLinkItems.indexOf(element);
                this.selectedLinkItems.splice(i, 1);
                this.selLinItLen = this.selectedLinkItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "RSSFEED") {
            this.selectedRssItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedRssItems.indexOf(element);
                this.selectedRssItems.splice(i, 1);
                this.selRssItLen = this.selectedRssItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "EBOOK_READER") {
            this.selectedEbookItem.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedEbookItem.indexOf(element);
                this.selectedEbookItem.splice(i, 1);
                this.selEbookLen = this.selectedEbookItem.length;
                this.newFunction();
              }
            });
          } else if (el.type === "EBOOK") {
            this.selectedEbookItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedEbookItems.indexOf(element);
                this.selectedEbookItems.splice(i, 1);
                // this.selEbookLen = this.selectedEbookItem.length
                // this.newFunction()
              }
            });
          } else if (el.type === "CHANNEL") {
            this.selectedChannelItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedChannelItems.indexOf(element);
                this.selectedChannelItems.splice(i, 1);
                // this.selEbookLen = this.selectedEbookItem.length
                // this.newFunction()
              }
            });
          } else if (el.type === "MUSIC") {
            this.selectedSongsItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedSongsItems.indexOf(element);
                this.selectedSongsItems.splice(i, 1);
                this.selSongLen = this.selectedSongsItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "EVENT") {
            this.selectedEventItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedEventItems.indexOf(element);
                this.selectedEventItems.splice(i, 1);
                this.selEventLen = this.selectedEventItems.length;
                this.newFunction();
              }
            });
          } else if (el.type === "CALENDER") {
            this.selectedCalenderItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedCalenderItems.indexOf(element);
                this.selectedCalenderItems.splice(i, 1);
                this.selCalenderItLen = this.selectedCalenderItems.length;
                this.newFunction();
              }
            });
          } else {
            this.selectedAlbumItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false;
                let i = this.selectedAlbumItems.indexOf(element);
                this.selectedAlbumItems.splice(i, 1);
                this.selAlbItLen = this.selectedAlbumItems.length;
                this.newFunction();
              }
            });
          }
        });
      })
    );
  }

  dblClickselectMediaSeries(item) {
    if (item.status !== "DRAFT") {
      this.selectMediaSeries("dbl", item);
      this.saveData();
    }
  }

  selectMediaSeries(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.recentSeries.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      if (event === "dbl") {
        this.selectAll = true;
      } else {
        this.selectAll = event.target.checked;
      }
      this.recentSeries.forEach((el) => {
        if (event === "dbl") {
          el.itemChecked = true;
        } else {
          el.itemChecked = event.target.checked;
        }
      });
      if (event === "dbl") {
        this.selectedMediaSeriesItems = true ? this.recentSeries : [];
      } else {
        this.selectedMediaSeriesItems = event.target.checked
          ? this.recentSeries
          : [];
      }
    } else {
      if (this.showallMediaSeries) {
        this.recentSeries.forEach((el) => {
          if (el.id === item.id) {
            if (event === "dbl") {
              el.itemChecked = true;
            } else {
              el.itemChecked = event.target.checked;
            }
          }
        });
        this.selectedMediaSeriesItems = this.recentSeries.filter(
          (el) => el.itemChecked
        );
        this.selMedSeLen = this.selectedMediaSeriesItems.length;
        this.selectAll =
          this.selectedMediaSeriesItems.length === this.recentSeries.length;
      } else {
        this.recentSeries.forEach((el) => {
          if (el.id === item.id) {
            if (event === "dbl") {
              el.itemChecked = true;
            } else {
              el.itemChecked = event.target.checked;
            }
            if (el.itemChecked === false) {
              this.arrayMobile.forEach((data, i) => {
                if (data.id === item.id) {
                  this.arrayMobile.splice(i, 1);
                }
              });
            }
          }
        });
        if (
          this.clickedFrom === "notification" ||
          this.clickedFrom === "tvApp" ||
          this.clickedFrom === "podcast"
        ) {
          this.selectedMediaSeriesItems = [];
          this.selectedStreamItems = [];
          this.selectedMediaItems = [];
          this.selectedLinkItems = [];
          this.selectedFolderItems = [];
          this.selectedRssItems = [];
          this.selectedEbookItem = [];
          this.selectedChannelItems = [];
          this.selectedAlbumItems = [];
          this.selectedSongsItems = [];
          this.selectedEventItems = [];
          this.selectedCalenderItems = [];
          this.newData9 = [];
          this.newData8 = [];
          this.newData = [];
          this.newData1 = [];
          this.newData2 = [];
          this.newData3 = [];
          this.newData4 = [];
          this.newData5 = [];
          this.newData10 = [];
          this.newData6 = [];
          this.newData7 = [];
          this.newData11 = [];
          this.selectedMediaSeriesItems.push(item);
        } else {
          this.selectedMediaSeriesItems = this.recentSeries.filter(
            (el) => el.itemChecked
          );
        }
        this.selMedSeLen = this.selectedMediaSeriesItems.length;
        this.selectAll =
          this.selectedMediaSeriesItems.length === this.recentSeries.length;
      }

      this.newData1 = this.selectedMediaSeriesItems;
      this.newData1 = this.newData1.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subtitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "MEDIASERIES",
      }));
      // let newData = []

      this.newData = this.selectedMediaSeriesItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: "MEDIASERIES",
      }));
    }

    this.selMedSeLen = this.selectedMediaSeriesItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectLiveStram(item) {
    if (item.status !== "DRAFT") {
      this.selectLiveStram("dbl", item);
      this.saveData();
    }
  }
  selectLiveStram(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.recentStreamList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      if (event === "dbl") {
        this.selectAll = true;
      } else {
        this.selectAll = event.target.checked;
      }
      this.recentStreamList.forEach((el) => {
        if (event === "dbl") {
          el.itemChecked = true;
        } else {
          el.itemChecked = event.target.checked;
        }
      });
      if (event === "dbl") {
        this.selectedStreamItems = true ? this.recentStreamList : [];
      } else {
        this.selectedStreamItems = event.target.checked
          ? this.recentStreamList
          : [];
      }
    } else {
      if (this.showallLiveStream) {
        this.recentStreamList.forEach((el) => {
          if (el.id === item.id) {
            if (event === "dbl") {
              el.itemChecked = true;
            } else {
              el.itemChecked = event.target.checked;
            }
          }
        });
        this.selectedStreamItems = this.recentStreamList.filter(
          (el) => el.itemChecked
        );
        this.selLiveStreamLen = this.selectedStreamItems.length;
        this.selectAll =
          this.selectedStreamItems.length === this.recentStreamList.length;
      } else {
        this.recentStreamList.forEach((el) => {
          if (el.id === item.id) {
            if (event === "dbl") {
              el.itemChecked = true;
            } else {
              el.itemChecked = event.target.checked;
            }
            if (el.itemChecked === false) {
              this.arrayMobile.forEach((data, i) => {
                if (data.id === item.id) {
                  this.arrayMobile.splice(i, 1);
                }
              });
            }
          }
        });
        if (
          this.clickedFrom === "notification" ||
          this.clickedFrom === "tvApp" ||
          this.clickedFrom === "podcast"
        ) {
          this.selectedMediaSeriesItems = [];
          this.selectedStreamItems = [];
          this.selectedMediaItems = [];
          this.selectedLinkItems = [];
          this.selectedFolderItems = [];
          this.selectedRssItems = [];
          this.selectedEbookItem = [];
          this.selectedChannelItems = [];
          this.selectedAlbumItems = [];
          this.selectedSongsItems = [];
          this.selectedEventItems = [];
          this.selectedCalenderItems = [];
          this.newData9 = [];
          this.newData8 = [];
          this.newData = [];
          this.newData1 = [];
          this.newData2 = [];
          this.newData3 = [];
          this.newData4 = [];
          this.newData5 = [];
          this.newData10 = [];
          this.newData6 = [];
          this.newData7 = [];
          this.newData11 = [];
          this.selectedStreamItems.push(item);
        } else {
          this.selectedStreamItems = this.recentStreamList.filter(
            (el) => el.itemChecked
          );
        }
        this.selLiveStreamLen = this.selectedStreamItems.length;
        this.selectAll =
          this.selectedStreamItems.length === this.recentStreamList.length;
      }

      this.newData11 = this.selectedStreamItems;
      this.newData11 = this.newData11.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subtitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "MEDIA_ITEM",
      }));
      // let newData = []

      this.newLiveStreamData = this.selectedStreamItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: "MEDIA_ITEM",
      }));
    }

    this.selLiveStreamLen = this.selectedStreamItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectItems(item) {
    if (item.status !== "DRAFT") {
      this.selectItems("dbl", item);
      this.saveData();
    }
  }

  selectItems(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.recentMedia.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      if (event === "dbl") {
        this.selectAll = true;
      } else {
        this.selectAll = event.target.checked;
      }
      this.recentMedia.forEach((el) => {
        if (event === "dbl") {
          el.itemChecked = true;
        } else {
          el.itemChecked = event.target.checked;
        }
      });
      if (event === "dbl") {
        this.selectedMediaItems = true ? this.recentMedia : [];
      } else {
        this.selectedMediaItems = event.target.checked ? this.recentMedia : [];
      }
    } else {
      this.recentMedia.forEach((el) => {
        if (el.id === item.id) {
          if (event === "dbl") {
            el.itemChecked = true;
          } else {
            el.itemChecked = event.target.checked;
          }
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (
        this.clickedFrom === "notification" ||
        this.clickedFrom === "tvApp" ||
        this.clickedFrom === "podcast"
      ) {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedChannelItems = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedMediaItems.push(item);
      } else {
        this.selectedMediaItems = this.recentMedia.filter(
          (el) => el.itemChecked
        );
      }
      this.selectAll =
        this.selectedMediaItems.length === this.recentMedia.length;
      this.newData2 = this.selectedMediaItems;

      this.newData2 = this.newData2.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "MEDIA_ITEM",
      }));
      this.newMediaItemData = this.selectedMediaItems.map((el) => ({
        id: el.id,
        sortOrder: 2,
        type: "MEDIA_ITEM",
      }));
    }

    this.selMedItLen = this.selectedMediaItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
    // if(this.carouselMaxLength === 0 ){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 1){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 2){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 3){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 4){
    //   this.popUp = false
    // }
    // else{
    //   this.popUp = true
    // }
  }

  dblClickselectLinks(item) {
    if (item.status !== "DRAFT") {
      this.selectLinks("dbl", item);
      this.saveData();
    }
  }

  selectLinks(event, list) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.linkList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (list === "all") {
      if (event === "dbl") {
        this.selectAll = true;
      } else {
        this.selectAll = event.target.checked;
      }
      this.linkList.forEach((el) => {
        if (event === "dbl") {
          el.itemChecked = true;
        } else {
          el.itemChecked = event.target.checked;
        }
      });
      if (event === "dbl") {
        this.selectedLinkItems = true ? this.linkList : [];
      } else {
        this.selectedLinkItems = event.target.checked ? this.linkList : [];
      }
    } else {
      this.linkList.forEach((el) => {
        if (el.id === list.id) {
          if (event === "dbl") {
            el.itemChecked = true;
          } else {
            el.itemChecked = event.target.checked;
          }
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === list.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedChannelItems = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedLinkItems.push(list);
      } else {
        this.selectedLinkItems = this.linkList.filter((el) => el.itemChecked);
      }

      this.newData3 = this.selectedLinkItems;
      this.newData3 = this.newData3.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "LINK",
      }));
      this.selectAll = this.selectedLinkItems.length === this.linkList.length;
      this.newLinkItemData = this.selectedLinkItems.map((el) => ({
        id: el.id,
        sortOrder: 3,
        type: "LINK",
      }));
    }
    this.selLinItLen = this.selectedLinkItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === list.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectFolder(item) {
    if (item.status !== "DRAFT") {
      this.selectFolder("dbl", item);
      this.saveData();
    }
  }

  selectFolder(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.trendingList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      if (event === "dbl") {
        this.selectAll = true;
      } else {
        this.selectAll = event.target.checked;
      }
      this.trendingList.forEach((el) => {
        if (event === "dbl") {
          el.itemChecked = true;
        } else {
          el.itemChecked = event.target.checked;
        }
      });
      if (event === "dbl") {
        this.selectedFolderItems = true ? this.trendingList : [];
      } else {
        this.selectedFolderItems = event.target.checked
          ? this.trendingList
          : [];
      }
    } else {
      this.trendingList.forEach((el) => {
        if (el.id === item.id) {
          if (event === "dbl") {
            el.itemChecked = true;
          } else {
            el.itemChecked = event.target.checked;
          }
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (
        this.clickedFrom === "notification" ||
        this.clickedFrom === "tvApp" ||
        this.clickedFrom === "podcast"
      ) {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedChannelItems = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedFolderItems.push(item);
      } else {
        this.selectedFolderItems = this.trendingList.filter(
          (el) => el.itemChecked
        );
      }

      this.newData4 = this.selectedFolderItems;
      this.newData4 = this.newData4.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "LIST",
      }));
      this.selectAll =
        this.selectedFolderItems.length === this.trendingList.length;
      this.newFolderItemData = this.selectedFolderItems.map((el) => ({
        id: el.id,
        sortOrder: 4,
        type: "LIST",
      }));
    }

    this.selFolItLen = this.selectedFolderItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectRss(item) {
    if (item.status !== "DRAFT") {
      this.selectRss("dbl", item);
      this.saveData();
    }
  }

  selectRss(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.rssList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      if (event === "dbl") {
        this.selectAll = true;
      } else {
        this.selectAll = event.target.checked;
      }
      this.rssList.forEach((el) => {
        if (event === "dbl") {
          el.itemChecked = true;
        } else {
          el.itemChecked = event.target.checked;
        }
      });
      if (event === "dbl") {
        this.selectedRssItems = true ? this.rssList : [];
      } else {
        this.selectedRssItems = event.target.checked ? this.rssList : [];
      }
    } else {
      this.rssList.forEach((el) => {
        if (el.id === item.id) {
          if (event === "dbl") {
            el.itemChecked = true;
          } else {
            el.itemChecked = event.target.checked;
          }
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedChannelItems = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedRssItems.push(item);
      } else {
        this.selectedRssItems = this.rssList.filter((el) => el.itemChecked);
      }

      this.newData5 = this.selectedRssItems;
      this.newData5 = this.newData5.map((el) => ({
        bannerArtworkId: el.bannerArtwork.document.id,
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork.document.id,
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork.document.id,
        type: "RSSFEED",
      }));
      this.selectAll = this.selectedRssItems.length === this.rssList.length;
      this.newRssitemData = this.selectedRssItems.map((el) => ({
        id: el.id,
        sortOrder: 5,
        type: "RSSFEED",
      }));
    }

    this.selRssItLen = this.selectedRssItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectEbook(item) {
    if (item.status !== "DRAFT") {
      this.selectEbook("dbl", item);
      this.saveData();
    }
  }

  selectEbook(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.ebookList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      this.ebookList.forEach(
        (el) => (el.itemChecked = event === "dbl" ? true : event.target.checked)
      );
      this.selectedEbookItem = (event === "dbl" ? true : event.target.checked)
        ? this.ebookList
        : [];
    } else {
      this.ebookList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event === "dbl" ? true : event.target.checked;
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedEbookItem.push(item);
      } else {
        this.selectedEbookItem = this.ebookList.filter((el) => el.itemChecked);
      }

      this.newData10 = this.selectedEbookItem;
      this.newData10 = this.newData10.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : null,
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : null,
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : null,
        type: "MEDIASERIES",
      }));
      this.selectAll = this.selectedEbookItem.length === this.ebookList.length;
      this.newEbookData = this.selectedEbookItem.map((el) => ({
        id: el.id,
        sortOrder: 5,
        type: "MEDIASERIES",
      }));
    }

    this.selEbookLen = this.selectedEbookItem.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectEbookItems(item) {
    if (item.status !== "DRAFT") {
      this.selectEbookItems("dbl", item);
      this.saveData();
    }
  }

  selectEbookItems(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.ebookItemList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      this.ebookItemList.forEach(
        (el) => (el.itemChecked = event === "dbl" ? true : event.target.checked)
      );
      this.selectedEbookItems = (event === "dbl" ? true : event.target.checked)
        ? this.ebookList
        : [];
    } else {
      this.ebookItemList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event === "dbl" ? true : event.target.checked;
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedEbookItems = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedEbookItems.push(item);
      } else {
        this.selectedEbookItems = this.ebookItemList.filter(
          (el) => el.itemChecked
        );
      }

      this.newData11 = this.selectedEbookItems;
      this.newData11 = this.newData11.map((el) => ({
        ebookArtworkId: el.ebookArtwork ? el.ebookArtwork.document.id : null,
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : null,
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : null,
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : null,
        type: "EBOOK",
      }));
      this.selectAll =
        this.selectedEbookItems.length === this.ebookItemList.length;
      this.newEbookData = this.selectedEbookItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: "EBOOK",
      }));
    }

    this.selEbookLen = this.selectedEbookItem.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectChannelItems(item) {
    if (item.status !== "DRAFT") {
      this.selectChannelItems("dbl", item);
      this.saveData();
    }
  }

  selectChannelItems(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      if (this.m3uChannelItemChecked) {
        this.m3uChannelItemList.forEach((el) => {
          el.itemChecked = false;
        });
      } else {
        this.channelItemList.forEach((el) => {
          el.itemChecked = false;
        });
      }
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      if (this.m3uChannelItemChecked) {
        this.m3uChannelItemList.forEach(
          (el) =>
            (el.itemChecked = event === "dbl" ? true : event.target.checked)
        );
      } else {
        this.channelItemList.forEach(
          (el) =>
            (el.itemChecked = event === "dbl" ? true : event.target.checked)
        );
      }
      this.selectedChannelItems = (
        event === "dbl" ? true : event.target.checked
      )
        ? this.ebookList
        : [];
    } else {
      if (this.m3uChannelItemChecked) {
        this.m3uChannelItemList.forEach((el) => {
          el.itemChecked = false;
        });
        this.m3uChannelItemList.forEach((el) => {
          if (el.id === item.id) {
            el.itemChecked = event === "dbl" ? true : event.target.checked;
            if (el.itemChecked === false) {
              this.arrayMobile.forEach((data, i) => {
                if (data.id === item.id) {
                  this.arrayMobile.splice(i, 1);
                }
              });
            }
          }
        });
      } else {
        this.channelItemList.forEach((el) => {
          if (el.id === item.id) {
            el.itemChecked = event === "dbl" ? true : event.target.checked;
            if (el.itemChecked === false) {
              this.arrayMobile.forEach((data, i) => {
                if (data.id === item.id) {
                  this.arrayMobile.splice(i, 1);
                }
              });
            }
          }
        });
      }
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedEbookItems = [];
        this.selectedChannelItems = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedChannelItems.push(item);
      } else {
        let selectedCustomChannel = [];
        let selectedM3uChannel = [];
        // if(this.m3uChannelItemChecked){
        selectedCustomChannel = this.m3uChannelItemList.filter(
          (el) => el.itemChecked
        );
        // }
        // else{
        selectedM3uChannel = this.channelItemList.filter(
          (el) => el.itemChecked
        );
        // }
        this.selectedChannelItems = [
          ...selectedCustomChannel,
          ...selectedM3uChannel,
        ];
      }

      this.newData11 = this.selectedChannelItems;
      this.newData11 = this.newData11.map((el) => ({
        ebookArtworkId: el.ebookArtwork ? el.ebookArtwork.document.id : null,
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : null,
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : null,
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : null,
        type: "CHANNEL",
        channelType: el.channelType,
      }));
      if (this.m3uChannelItemChecked) {
        this.selectAll =
          this.selectedChannelItems.length === this.m3uChannelItemList.length;
      } else {
        this.selectAll =
          this.selectedChannelItems.length === this.channelItemList.length;
      }
      this.newChannelData = this.selectedChannelItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: "CHANNEL",
        channelType: el.channelType,
      }));
    }

    this.selChannelLen = this.selectedChannelItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectAlbum(item) {
    if (item.status !== "DRAFT") {
      this.selectAlbum("dbl", item);
      this.saveData();
    }
  }

  selectAlbum(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.musicList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      this.musicList.forEach(
        (el) => (el.itemChecked = event === "dbl" ? true : event.target.checked)
      );
      this.selectedAlbumItems = (event === "dbl" ? true : event.target.checked)
        ? this.musicList
        : [];
    } else {
      this.musicList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event === "dbl" ? true : event.target.checked;
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (
        this.clickedFrom === "notification" ||
        this.clickedFrom === "tvApp" ||
        this.clickedFrom === "podcast"
      ) {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedAlbumItems.push(item);
      } else {
        this.selectedAlbumItems = this.musicList.filter((el) => el.itemChecked);
      }

      this.newData6 = this.selectedAlbumItems;
      this.newData6 = this.newData6.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "ALBUM",
      }));
      this.selectAll = this.selectedAlbumItems.length === this.musicList.length;
      this.newAlbumItemData = this.selectedAlbumItems.map((el) => ({
        id: el.id,
        sortOrder: 6,
        type: "ALBUM",
      }));
    }

    this.selAlbItLen = this.selectedAlbumItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectSong(item) {
    if (item.status !== "DRAFT") {
      this.selectSong("dbl", item);
      this.saveData();
    }
  }

  selectSong(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.songList.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      this.songList.forEach(
        (el) => (el.itemChecked = event === "dbl" ? true : event.target.checked)
      );
      this.selectedSongsItems = (event === "dbl" ? true : event.target.checked)
        ? this.songList
        : [];
    } else {
      this.songList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event === "dbl" ? true : event.target.checked;
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData8 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData11 = [];
        this.selectedSongsItems.push(item);
      } else {
        this.selectedSongsItems = this.songList.filter((el) => el.itemChecked);
      }

      this.newData7 = this.selectedSongsItems;
      this.newData7 = this.newData7.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "MUSIC",
      }));
      this.selectAll = this.selectedSongsItems.length === this.musicList.length;
      this.newSongItemData = this.selectedSongsItems.map((el) => ({
        id: el.id,
        sortOrder: 7,
        type: "MUSIC",
      }));
    }

    this.selSongLen = this.selectedSongsItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectEvent(item) {
    if (item.status !== "DRAFT") {
      this.selectEvent("dbl", item);
      this.saveData();
    }
  }

  selectEvent(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.allEventItem.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      this.allEventItem.forEach(
        (el) => (el.itemChecked = event === "dbl" ? true : event.target.checked)
      );
      this.selectedEventItems = (event === "dbl" ? true : event.target.checked)
        ? this.allEventItem
        : [];
    } else {
      this.allEventItem.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event === "dbl" ? true : event.target.checked;
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData9 = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData6 = [];
        this.newData7 = [];
        this.newData8 = [];
        this.newData11 = [];
        this.selectedEventItems.push(item);
      } else {
        this.selectedEventItems = this.allEventItem.filter(
          (el) => el.itemChecked
        );
      }

      this.newData8 = this.selectedEventItems;
      this.newData8 = this.newData8.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "EVENT",
        startedDate: el.startedDate,
        endedDate: el.endedDate,
        endTime: el.endTime,
        startTime: el.startTime,
        timezone: el.timezone,
      }));
      this.selectAll =
        this.selectedEventItems.length === this.allEventItem.length;
      this.newEventItemData = this.selectedEventItems.map((el) => ({
        id: el.id,
        sortOrder: 8,
        type: "EVENT",
      }));
    }

    this.selEventLen = this.selectedEventItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }

    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  dblClickselectCalender(item) {
    if (item.status !== "DRAFT") {
      this.selectCalender("dbl", item);
      this.saveData();
    }
  }

  selectCalender(event, item) {
    if (
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast"
    ) {
      this.calenderItem.forEach((el) => {
        el.itemChecked = false;
      });
    }
    if (item === "all") {
      this.selectAll = event === "dbl" ? true : event.target.checked;
      this.calenderItem.forEach(
        (el) => (el.itemChecked = event === "dbl" ? true : event.target.checked)
      );
      this.selectedCalenderItems = (
        event === "dbl" ? true : event.target.checked
      )
        ? this.allEventItem
        : [];
    } else {
      this.calenderItem.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event === "dbl" ? true : event.target.checked;
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1);
              }
            });
          }
        }
      });
      if (this.clickedFrom === "notification" || this.clickedFrom === "tvApp") {
        this.selectedMediaSeriesItems = [];
        this.selectedStreamItems = [];
        this.selectedMediaItems = [];
        this.selectedLinkItems = [];
        this.selectedFolderItems = [];
        this.selectedRssItems = [];
        this.selectedEbookItem = [];
        this.selectedAlbumItems = [];
        this.selectedSongsItems = [];
        this.selectedEventItems = [];
        this.selectedCalenderItems = [];
        this.newData = [];
        this.newData1 = [];
        this.newData2 = [];
        this.newData3 = [];
        this.newData4 = [];
        this.newData5 = [];
        this.newData10 = [];
        this.newData11 = [];
        this.folderChecked = false;
        this.EventChecked = false;
        this.claenderChecked = false;
        this.albumChecked = false;
        this.linkChecked = false;
        this.rssChecked = false;
        this.ebookChecked = false;
        this.songChecked = false;
        this.newData8 = [];
        this.newData9 = [];
        this.selectedCalenderItems.push(item);
      } else {
        this.selectedCalenderItems = this.calenderItem.filter(
          (el) => el.itemChecked
        );
      }

      this.newData9 = this.selectedCalenderItems;
      this.newData9 = this.newData9.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : "",
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : "",
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : "",
        type: "CALENDAR",
      }));
      this.selectAll =
        this.selectedCalenderItems.length === this.calenderItem.length;
      this.newCalenderItemData = this.selectedCalenderItems.map((el) => ({
        id: el.id,
        sortOrder: 9,
        type: "CALENDAR",
      }));
    }

    this.selCalenderItLen = this.selectedCalenderItems.length;
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen +
        this.selSongLen +
        this.selEventLen +
        this.selCalenderItLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }

    if (this.clickedFrom === "mobile") {
      if (this.arrayMobile.length > 0) {
        this.arrayMobile.forEach((el, i) => {
          if (el.id === item.id) {
            this.arrayMobile.splice(i, 1);
          }
        });
      }
    }
  }

  folder(item) {
    this.folderId = item.id;
    this.sendFolderId(this.folderId);
  }

  mediaIt(newMediaItemData) {
    // this.mediaItId = recent.id
    this.sendMediaItemId(this.newMediaItemData);

    // this.sendMediaSeriesId(this.newData)
  }

  sendData(newData) {
    if (this.clickedFrom === "channelItem") {
      let isAllCustom: any;
      if (this.newData11.length > 1) {
        isAllCustom = this.newData11.every(
          (element) => element.channelType === "CUSTOM"
        );
        if (!isAllCustom) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "You can only select custom channels or single m3u.",
          });
          return;
        }
      }
      // this.newData11.forEach(el=>{
      //   if(this.newData11.length > 1){

      //   }
      // })
    }
    if (this.carouselArtworkType) {
      this.libraryService.sendArtworkType(this.carouselArtworkType);
    }
    if (this.clickedFrom === "mobile") {
      this.combined = [
        ...this.newData,
        ...this.newLiveStreamData,
        ...this.newMediaItemData,
        ...this.newLinkItemData,
        ...this.newFolderItemData,
        ...this.newRssitemData,
        ...this.newEbookData,
        ...this.newChannelData,
        ...this.newAlbumItemData,
        ...this.newSongItemData,
        ...this.newEventItemData,
        ...this.newCalenderItemData,
        ...this.newEbookItemData,
        ...this.arrayMobile,
      ];
    } else {
      this.combined = [
        ...this.newData,
        ...this.newLiveStreamData,
        ...this.newMediaItemData,
        ...this.newLinkItemData,
        ...this.newFolderItemData,
        ...this.newRssitemData,
        ...this.newEbookData,
        ...this.newChannelData,
        ...this.newAlbumItemData,
        ...this.newSongItemData,
        ...this.newEventItemData,
        ...this.newCalenderItemData,
        ...this.newEbookItemData,
      ];
    }
    this.combined.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type,
    }));
    if (this.clickedFrom === "mobile") {
      this.combined1 = [
        ...this.newData1,
        ...this.newData2,
        ...this.newData3,
        ...this.newData4,
        ...this.newData5,
        ...this.newData10,
        ...this.newData6,
        ...this.newData7,
        ...this.newData8,
        ...this.newData9,
        ...this.newData11,
        ...this.arrayMobile,
      ];
    } else {
      this.combined1 = [
        ...this.newData1,
        ...this.newData2,
        ...this.newData3,
        ...this.newData4,
        ...this.newData5,
        ...this.newData10,
        ...this.newData6,
        ...this.newData7,
        ...this.newData8,
        ...this.newData9,
        ...this.newData11,
      ];
    }
    this.sendMediaSeriesId(this.combined);
    this.sendFolderId(this.combined1);
    this.libraryService.updatePanel({ showPanel: false });

    // this.rss = false
    // this.song = false
    // this.album = false
    // this.mediaItems = false
    // this.mediaSeries = true
    // this.listItems = false
    // this.linkItem = false
    this.closePanel("apply");
  }

  sendFolderId(folderId) {
    // this.combined1 = [...this.newData1, ...this.newData2, ...this.newData3, ...this.newData4, ...this.newData5, ...this.newData6]
    if (
      this.clickedFrom === "list" ||
      this.clickedFrom === "buildOwnView" ||
      this.clickedFrom === "ebook" ||
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast" ||
      this.clickedFrom === "ebookItem" ||
      this.clickedFrom === "channelItem" ||
      this.clickedFrom === "videoItem" ||
      this.clickedFrom === "albumItem" ||
      this.clickedFrom === "calendarItem"
    ) {
      this.libraryService.sendFolderItem(this.combined1);
    } else {
      this.libraryService.sendMobileDat2(this.combined1);
    }
  }
  // sendPodcastId(){
  //   if (this.clickedFrom === 'podcast') {
  //     this.libraryService.sendFolderItem(this.combined1)
  //   } else {
  //     this.libraryService.sendMobileDat2(this.combined1)
  //   }
  // }

  // sendMediaSeriesId(mediaSerId){
  //   this.libraryService.sendMediaSeries(this.mediaSerId)
  // }

  sendMediaSeriesId(newData) {
    if (
      this.clickedFrom === "list" ||
      this.clickedFrom === "buildOwnView" ||
      this.clickedFrom === "ebook" ||
      this.clickedFrom === "notification" ||
      this.clickedFrom === "tvApp" ||
      this.clickedFrom === "podcast" ||
      this.clickedFrom === "ebookItem" ||
      this.clickedFrom === "channelItem" ||
      this.clickedFrom === "videoItem" ||
      this.clickedFrom === "albumItem" ||
      this.clickedFrom === "calendarItem"
    ) {
      this.libraryService.sendMediaSeries(newData);
    } else {
      this.libraryService.sendMobileDat1(newData);
    }
  }

  sendMediaItemId(newMediaItemData) {
    this.libraryService.sendMediaItem(newMediaItemData);
  }

  newFunction() {
    if (
      this.selMedSeLen +
        this.selLiveStreamLen +
        this.selMedItLen +
        this.selFolItLen +
        this.selLinItLen +
        this.selAlbItLen +
        this.selRssItLen +
        this.selEbookLen -
        this.carouselMaxLength ===
        5 - this.carouselMaxLength - this.responseData &&
      this.clickedFrom === "mobile"
    ) {
      this.popUp = true;
    } else {
      this.popUp = false;
    }
  }

  createAlbum() {
    this.mediaService.createAlbum({ title: this.albumTitle }).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Album created Successfully.",
        });
        this.getAlbumList(1, 10);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error.error ? error.error.message : "Something went wrong.",
        });
      }
    );
    this.mediaItems = true;
    this.createAlbumIt = false;
    this.showOptions = true;
    this.albumChecked = true;
    this.mediaitemChecked = false;
    this.folderChecked = false;
    this.EventChecked = false;
    this.claenderChecked = false;
    this.linkChecked = false;
    this.rssChecked = false;
    this.ebookChecked = false;
    this.songChecked = false;
  }

  createMusic() {
    this.mediaService
      .createMusic({
        title: this.songTitle,
        subtitle: this.songSubtitle,
        albumId: this.albumItemId,
      })
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Song created Successfully.",
          });
          if (this.clickedFrom === "albumItem") {
            this.libraryService.sendUpdateToCreate(true);
          }
          this.getAllSongList(1, 10);
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong.",
          });
        }
      );

    this.mediaItems = true;
    this.createSongIt = false;
    this.showOptions = true;
    this.songChecked = true;
    this.mediaitemChecked = false;
    this.folderChecked = false;
    this.EventChecked = false;
    this.claenderChecked = false;
    this.albumChecked = false;
    this.linkChecked = false;
    this.rssChecked = false;
    this.ebookChecked = false;
  }

  createRss() {
    this.libraryService
      .createRssFeed({
        title: this.rssTitle,
        subtitle: this.rssSubtitle,
        url: this.rssUrl,
      })
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Rss created Successfully.",
          });
          this.getRssFeedList(1, 10);
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong.",
          });
        }
      );
    this.mediaItems = true;
    this.createRssIt = false;
    this.showOptions = true;
    this.rssChecked = true;
    this.mediaitemChecked = false;
    this.folderChecked = false;
    this.EventChecked = false;
    this.claenderChecked = false;
    this.albumChecked = false;
    this.linkChecked = false;
    this.songChecked = false;
  }

  createLink(form) {
    if (form.submitted) {
      this.submitted = true;
    }
    if (form.form.valid) {
      if (this.LinkUrl) {
        if (
          this.LinkUrl.indexOf("http://") == 0 ||
          this.LinkUrl.indexOf("https://") == 0
        ) {
          // do something here
          this.LinkUrl = this.LinkUrl;
        } else {
          this.LinkUrl = this.LinkUrl ? "https://" + this.LinkUrl : null;
        }
      }
      this.contact = this.contact ? this.contact : null;
      this.libraryService
        .createLinkItem({
          title: this.LinkTitle,
          subtitle: this.LinkSubtitle,
          type: this.Linktype,
          url: this.LinkUrl,
        })
        .subscribe(
          (response: any) => {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Link created Successfully.",
            });
            this.getLinkItems(1, 10);
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Something went wrong.",
            });
          }
        );
      this.mediaItems = true;
      this.createLinkIt = false;
      this.showOptions = true;
      this.linkChecked = true;
      this.mediaitemChecked = false;
      this.folderChecked = false;
      this.EventChecked = false;
      this.claenderChecked = false;
      this.albumChecked = false;
      this.rssChecked = false;
      this.ebookChecked = false;
      this.songChecked = false;
    }
  }

  removeUnnecessaryKeys(data) {
    const objKeys = Object.keys(data);
    for (const key of objKeys) {
      if (
        !data[key] ||
        key === "squareArtwork" ||
        key === "wideArtwork" ||
        key === "bannerArtwork" ||
        key === "publishedDate"
      ) {
        delete data[key];
      }
    }
    return data;
  }

  createCalender() {
    this.eventService
      .createClaender1({
        title: this.calenderTitle,
        subTitle: this.calenderSubtitle,
        status: "DRAFT",
      })
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Calendar created Successfully.",
          });
          this.getCalenderItem(1, 10);
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong.",
          });
        }
      );
    this.mediaItems = true;
    this.createCalenderIt = false;
    this.showOptions = true;
    this.claenderChecked = true;
    this.mediaitemChecked = false;
    this.folderChecked = false;
    this.EventChecked = false;
    this.albumChecked = false;
    this.linkChecked = false;
    this.rssChecked = false;
    this.ebookChecked = false;
    this.songChecked = false;
  }

  newStartTime() {
    let startedDate = moment(this.EventstartDate).format("YYYY-MM-DD");
    let sendStartTime = moment(this.EventstartTime).format();
    sendStartTime = sendStartTime.slice(11, 19);
    sendStartTime = startedDate + " " + sendStartTime;
    this.checkStartTime = new Date(sendStartTime);
    this.EventstartTime = this.checkStartTime;
  }

  newEndTime() {
    let endedDate = moment(this.EventendDate).format("YYYY-MM-DD");
    let sendEndTime = moment(this.EventendTime).format();
    sendEndTime = sendEndTime.slice(11, 19);
    sendEndTime = endedDate + " " + sendEndTime;
    this.checkEndTime = new Date(sendEndTime);
    this.EventendTime = this.checkEndTime;
  }

  setTime(e, type) {
    if (type === "startTime") {
      this.EventstartTime = e;
    } else if (type === "endTime") {
      this.EventendTime = e;
    }
    // else if (type === 'scheduleTime') {
    //   this.editEvent.scheduleTime = e
    // }
  }

  createEventItem() {
    this.eventSubmitted = true;

    if (
      this.EventTitle === null ||
      this.EventTitle?.trim() === "" ||
      this.calenderId === null ||
      this.calenderId?.trim() === ""
    ) {
      return;
    }
    // if(moment(this.checkEndTime).isBefore(this.date) || moment(this.checkStartTime).isBefore(this.date) ){
    //    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Events cannot be created in past.' });
    // }

    //   else if (moment(this.checkEndTime).isBefore(this.checkStartTime)) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Event end time cannot be before Event start time.' });
    // }
    // else{

    let squareArtworkId = null;
    let wideArtworkId = null;
    let bannerArtworkId = null;

    this.listCalendername.forEach((el: any) => {
      if (el.id == this.calenderId) {
        squareArtworkId = el.squareArtwork?.id;
        wideArtworkId = el.wideArtwork?.id;
        bannerArtworkId = el.bannerArtwork?.id;
      }
    });

    let startedDate = moment(this.EventstartDate).format("YYYY-MM-DD");

    let endedDate = moment(this.EventendDate).format("YYYY-MM-DD");

    let sendStartTimeData = moment(this.EventstartTime, ["h:mm A"]).format(
      "HH:mm:ss"
    );
    // dataToSend.startTime = sendStartTimeData
    let sendStartTime = sendStartTimeData;

    let sendEndTimeData = moment(this.EventendTime, ["h:mm A"]).format(
      "HH:mm:ss"
    );
    // dataToSend.startTime = sendStartTimeData
    let sendEndTime = sendEndTimeData;

    // let sendStartTime = moment(this.EventstartTime).format();
    // sendStartTime = sendStartTime.slice(11, 19);

    // let sendEndTime = moment(this.EventendTime).format();
    // sendEndTime = sendEndTime.slice(11, 19);

    // this.eventService.createEventItem({ title: this.EventTitle, subtitle: this.EventSubtitle, startDateevent: startedDate, endDateevent: endedDate, startTimeevent: sendStartTime, endTimeevent: sendEndTime, calendarId: this.calenderId, repeatDay: "DOES_NOT_REPEAT", squareArtworkId : squareArtworkId, wideArtworkId : wideArtworkId, bannerArtworkId : bannerArtworkId , status : 'DRAFT' }).subscribe((response: any) => {
    this.eventService
      .createEventItem({
        title: this.EventTitle,
        subTitle: this.EventSubtitle,
        startedDate: startedDate,
        endedDate: endedDate,
        startTime: sendStartTime,
        endTime: sendEndTime,
        calendarId: this.calenderId,
        repeatDay: "DOES_NOT_REPEAT",
        squareArtworkId: squareArtworkId,
        wideArtworkId: wideArtworkId,
        bannerArtworkId: bannerArtworkId,
        status: "DRAFT",
        registerToggleCheck: false,
        registrationTitle: "",
      })
      .subscribe(
        (response: any) => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Event created Successfully.",
          });
          this.getAllEventItem(1, 10);
          this.calenderId = null;

          // this.mediaItems = true;
          this.createEventIt = false;
          this.showOptions = true;
          this.EventChecked = true;
          this.mediaitemChecked = false;
          this.folderChecked = false;
          this.claenderChecked = false;
          this.albumChecked = false;
          this.linkChecked = false;
          this.rssChecked = false;
          this.ebookChecked = false;
          this.songChecked = false;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong.",
          });
        }
      );
    // }
  }

  status(event) {
    if (event.target.value === "NOWCAST_GIVING") {
      this.urlbox = false;
      this.cntctBox = false;
      if (this.keyExist == false) {
        if (this.router.url === "/apps-module/apps/mobileApp/MobileApp") {
          this.clickFrom = "TabItem";
        } else if (
          this.router.url ===
          "/library-module/library/folder/create-list/" +
            this.listItemId +
            "/edit"
        ) {
          this.clickFrom = "createListItem";
        } else if (this.router.url === "/apps-module/apps/tvApp") {
          this.clickFrom = "createTvappItem";
        }

        this.stripeKeyModal = true;
      }
    }

    if (event.target.value === "CONTACT_US" || event.target.value === "BIBLE") {
      this.urlbox = false;
      this.cntctBox = false;
    }
    if (
      event.target.value === "APP_LINK" ||
      event.target.value === "WEBSITE" ||
      event.target.value === "VIDEO" ||
      event.target.value === "GIVING"
    ) {
      this.urlbox = true;
      this.cntctBox = false;
    }

    if (event.target.value === "CONTACT") {
      this.urlbox = false;
      this.cntctBox = true;
    }
  }

  getStripeDataExist() {
    this.appsService.getCheckStripeKeys().subscribe((response: any) => {
      this.keyExist = response.data.givingStripeKey;
    });
  }

  redirectToStripe() {
    if (this.clickFrom === "TabItem") {
      this.router.navigate(["settings-module/settings/stripe-settings"], {
        queryParams: { MobileApp: true, createAppinfo: true },
      });
    }
    if (this.clickFrom === "createListItem") {
      this.router.navigate(["settings-module/settings/stripe-settings"], {
        queryParams: {
          createlist: true,
          createLinkinfo: true,
          id: this.listItemId ? this.listItemId : null,
        },
      });
    }
    if (this.clickFrom === "createTvappItem") {
      this.router.navigate(["settings-module/settings/stripe-settings"], {
        queryParams: { tvApp: true, createTvInfo: true },
      });
    }

    // else{
    //   this.router.navigate(['settings-module/settings/stripe-settings'], { queryParams: { createlink: true, createLinkinfo: false} });
    // }
  }

  cancelStripeModal() {
    if (this.clickFrom === "createLinkItem") {
      this.stripeKeyModal = false;
      this.clickFrom = "";
      this.Linktype = "APP_LINK";
    } else {
      this.stripeKeyModal = false;
      this.clickFrom = "";
      this.Linktype = "APP_LINK";
    }
  }

  saveData() {
    this.sendData(this.selectedItems);
  }

  // popupwrong(){
  //  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You can not select more 5 items' });

  // }

  enterStartTime(event) {
    if (event) {
      this.invalidStartTime = true;
    } else {
      this.invalidStartTime = false;
    }
  }

  enterEndTime(event) {
    if (event) {
      this.invalidEndTime = true;
    } else {
      this.invalidEndTime = false;
    }
  }

  startTime(data) {
    this.EventstartTime = data;
    if (moment(this.EventendTime).isBefore(data)) {
      this.EventendTime = data;
    }
  }

  startDate(data) {
    let date = moment(data);
    if (moment(this.EventendDate).isBefore(data)) {
      this.EventendDate = data;
    }
  }

  endTime(data) {
    this.EventendTime = data;
  }

  getLiveStreamItems(page, size) {
    this.rss = false;
    this.ebook = false;
    this.song = false;
    this.album = false;
    this.livestream = true;
    this.mediaItems = false;
    this.mediaSeries = false;
    this.linkItem = false;
    this.listItems = false;
    this.events = false;
    this.calender = false;

    this.mediaService
      .getMediaItems(
        page,
        size,
        this.searchLiveStream ? this.searchLiveStream.nativeElement.value : "",
        "LIVE_STREAMING",
        ""
      )
      .subscribe((response: any) => {
        this.recentStream = response.data.content;
        // this.totalMediaItems = response.data.totalElements;
        this.totalRecords = response.data.totalElements;
        this.recentStreamList = this.recentStream.slice(0, 4);
        if (this.arrayMobile) {
          let count = 0;
          this.arrayMobile.forEach((data) => {
            this.recentStream.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
                count = count + 1;
                this.newData11.push(data);
              }
            });
          });
          // this.selectedMediaItems = []
          this.selectedStreamItems.forEach((data) => {
            this.recentStream.forEach((el) => {
              if (data.id === el.id && data.title === el.title) {
                el["itemChecked"] = true;
              }
            });
          });
        }
        // this.getMediaSer(page,size)
        this.recentStream.forEach((data) => {
          if (data.squareArtwork) {
            // if(data.squareArtwork.document.bdImage === true){
            if (data.squareArtwork.artworkType === "SQUARE") {
              let id = data.squareArtwork.document.id;
              data.squareArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.squareHeight}` +
                "&width=" +
                `${appConfig.squareWidth}`;
            }
            // }
          } else if (data.bannerArtwork) {
            // if(data.bannerArtwork.document.bdImage === true){
            if (data.bannerArtwork.artworkType === "BANNER") {
              let id = data.bannerArtwork.document.id;
              data.bannerArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.bannerHeight}` +
                "&width=" +
                `${appConfig.bannerWidth}`;
            }

            // }
          } else if (data.wideArtwork) {
            // if(data.wideArtwork.document.bdImage === true){
            if (data.wideArtwork.artworkType === "WIDE") {
              let id = data.wideArtwork.document.id;
              data.wideArtwork.document["newimg"] =
                `${appConfig.imageURL}` +
                id +
                "?height=" +
                `${appConfig.wideHeight}` +
                "&width=" +
                `${appConfig.wideWidth}`;
            }

            // }
          }
          if (this.timeZone === "IST") {
            this.timeZone = "Asia/Kolkata";
          }
          if (data.createdDate) {
            if (this.timeZone) {
              var cutoffString = data.createdDate; // in utc
              var utcCutoff = moment.utc(cutoffString, "YYYYMMDD HH:mm:ss");
              var displayCutoff = utcCutoff.clone().tz(this.timeZone);
              let formatDate = moment(displayCutoff).format("MMM DD YYYY");
              data.createdDate = formatDate;
            }
          }
        });

        // this.getMediaSer(1,this.totalRecords)
      });
  }

  goTo(data, type) {
    if (data?.status && data?.status === "DRAFT") {
      if (
        this.panel === "mobile" ||
        this.panel === "list" ||
        this.panel === "tv-panel" ||
        this.panel === "musicPanel"
      ) {
        let panelType =
          this.panel === "mobile"
            ? "app-panel"
            : this.panel === "tv-panel"
            ? "tv-panel"
            : "list-panel";
        if (type === "series") {
          this.router.navigate(
            [`/media-module/media/on-demand/edit-media-series/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "vidItem") {
          this.router.navigate(
            [`/media-module/media/on-demand/edit-media-item/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "folderItem") {
          // this.router.navigate([`/media-module/media/on-demand/edit-media-series/${data.id}`], { queryParams: { type: type, from: panelType, tabType: this.clickedFrom, tabId: this.tabId } });
        } else if (type === "linkItem") {
          this.router.navigate(
            [`/library-module/library/links/create-link/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "rssItem") {
          this.router.navigate(
            [`/library-module/library/rss-feeds/create-rss-item/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "albumItem") {
          this.router.navigate(
            [`/media-module/media/music/createAlbum/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.listItemId,
                panel: this.panel,
              },
            }
          );
        } else if (type === "songItem") {
          this.router.navigate(
            [`/media-module/media/music/createSong/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.listItemId,
                panel: this.panel
              },
            }
          );
        } else if (type === "calendarItem") {
          this.router.navigate(
            [`/events-module/events/edit-calender/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "eventItem") {
          this.router.navigate(
            [`/events-module/events/edit-event/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "libItem") {
          this.router.navigate(
            [`/media-module/media/ebook/edit-collection/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        } else if (type === "liveItem") {
          this.router.navigate(
            [`/media-module/media/live-streaming/edit-live-stream/${data.id}`],
            {
              queryParams: {
                type: type,
                from: panelType,
                tabType: this.clickedFrom,
                tabId: this.tabId,
              },
            }
          );
        }
      } else if (this.panel === "add-series-panel") {
        let panelType = "add-series-panel";
        this.router.navigate(
          [`/media-module/media/on-demand/edit-media-item/${data.id}`],
          { queryParams: { type: type, from: panelType, serId: this.tabId } }
        );
      } else if (this.panel === "add-calendar-panel") {
        let panelType = "add-calendar-panel";
        this.router.navigate([`/events-module/events/edit-event/${data.id}`], {
          queryParams: { type: type, from: panelType, calenId: this.tabId },
        });
      } else if (this.panel === "add-album-panel") {
        let panelType = "add-album-panel";
        this.router.navigate(
          [`/media-module/media/music/createSong/${data.id}`],
          { queryParams: { type: type, from: panelType, albId: this.tabId } }
        );
      } else if (this.panel === "add-ebook-panel") {
        let panelType = "add-ebook-panel";
        this.router.navigate(
          [`/media-module/media/ebook/edit-ebook/${data.id}`],
          { queryParams: { type: type, from: panelType, ebId: this.tabId } }
        );
      } else if (this.panel === "channelItem") {
        let panelType = "channelItem";
        this.router.navigate(
          [`/channel-module/myChannel/add-channel/${data.id}`],
          { queryParams: { type: type, from: panelType, ebId: this.tabId } }
        );
      } else if (this.panel === "add-channel-panel") {
        let panelType = "add-channel-panel";
        this.router.navigate(
          [`/channel-module/myChannel/add-channel/${data.id}`],
          {
            queryParams: { type: type, from: panelType, networkId: this.tabId },
          }
        );
      }
    }
  }

  onItemChange(type) {
    if (type === "m3uChannel") {
      this.channelItemChecked = false;
      this.m3uChannelItemChecked = true;
    } else {
      this.m3uChannelItemChecked = false;
      this.channelItemChecked = true;
    }
  }
}
