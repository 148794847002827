import { LoginAuthGuard } from './../../auth-guards/login-auth.guard';
import { ForgetPasswordComponent } from './../../components/authenticate/forget-password/forget-password.component';
import { SignupComponent } from './../../components/authenticate/signup/signup.component';
import { LoginComponent } from './../../components/authenticate/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticateRoutes } from './authenticate.route';
import { ResetPasswordComponent } from './../../components/authenticate/reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { SharedModule } from '../shared/shared.module';
import { NgxStripeModule } from 'ngx-stripe';
import { PlatformsComponent } from 'src/app/components/authenticate/platforms/platforms.component';
import {CarouselModule} from 'primeng/carousel';
import { SignupOrgComponent } from 'src/app/components/authenticate/signup-org/signup-org.component';
import { TvPreviewComponent } from 'src/app/components/authenticate/tv-preview/tv-preview.component';
import { HorizontalScrollDirectiveDirective } from 'src/app/components/authenticate/horizontal-scroll-directive.directive';
import { EmailVerificationComponent } from 'src/app/components/authenticate/email-verification/email-verification.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PlyrModule } from 'ngx-plyr';
import { UpgradeSubscriptionPlanComponent } from 'src/app/components/authenticate/upgrade-subscription-plan/upgrade-subscription-plan.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(AuthenticateRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    MatProgressSpinnerModule,
    NgxStripeModule.forChild(),
    CarouselModule,
    PlyrModule
  ],
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    PlatformsComponent,
    SignupOrgComponent,
    TvPreviewComponent,
    HorizontalScrollDirectiveDirective,
    EmailVerificationComponent,
    UpgradeSubscriptionPlanComponent
  ],
  providers: [MessageService,
    LoginAuthGuard],
  exports: [],
})
export class AuthenticateModule { }
