import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {ResourceService} from './../../services/resource/resource.service';
import {appConfig} from './../../app.config';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LibraryServiceService {
  panelState = new Subject();
  panelState$ = this.panelState.asObservable();

  calendarPanelState  = new Subject();
  calendarPanelState$ = this.calendarPanelState.asObservable();

  ebookPanelState = new Subject();
  ebookPanelState$ = this.ebookPanelState.asObservable();

  networkPanelState = new Subject();
  networkPanelState$ = this.networkPanelState.asObservable();

  channelPanelState = new Subject();
  channelPanelState$ = this.channelPanelState.asObservable();

  panel = new Subject();
  panel$ = this.panel.asObservable();

  channelPanel = new Subject();
  channelPanel$ = this.channelPanel.asObservable();

  clicked = new Subject();
  clicked$ = this.clicked.asObservable();

  cancel = new Subject();
  cancel$ = this.cancel.asObservable()

  panel1 = new Subject();
  panel1$ = this.panel1.asObservable();

  panel2 = new Subject();
  panel2$ = this.panel2.asObservable();

  panel3 = new Subject();
  panel3$ = this.panel3.asObservable();

  panel5 = new Subject();
  panel5$ = this.panel5.asObservable();
  
  mediaSeries = new Subject()
  mediaSeries$ = this.mediaSeries.asObservable()

  mediaItem = new Subject()
  mediaItem$ = this.mediaItem.asObservable()

  folderItem = new Subject()
  folderItem$ = this.folderItem.asObservable()
  
  addLink = new Subject()
  addLink$ = this.addLink.asObservable()
  
  addRssFeed = new Subject;
  addRssFeed$ = this.addRssFeed.asObservable()

  sendCatalog = new Subject()
  sendCatalog$ = this.sendCatalog.asObservable()
  
  sendId = new Subject();
  sendId$ = this.sendId.asObservable()
  
  sendMobileId = new Subject();
  sendMobileId$ = this.sendMobileId.asObservable()

  senddesignddata = new Subject();
  senddesignddata$ = this.senddesignddata.asObservable()

  sendartworktype = new Subject();
  sendartworktype$ = this.sendartworktype.asObservable()

  sendWebId = new Subject();
  sendWebId$ = this.sendWebId.asObservable()

  sendTvId = new Subject();
  sendTvId$ = this.sendTvId.asObservable()
  
  sendStaticImage = new Subject();
  sendStaticImage$ = this.sendStaticImage.asObservable()

  sendCaraousalImage = new Subject();
  sendCaraousalImage$ = this.sendCaraousalImage.asObservable()
  

  sendDataItems1 = new Subject();
  sendDataItems1$ = this.sendDataItems1.asObservable()

  sendDataItems2 = new Subject();
  sendDataItems2$ = this.sendDataItems2.asObservable()
  
  sendMobileData1 = new Subject()
  sendMobileData1$ = this.sendMobileData1.asObservable()

  sendMobileData2 = new Subject()
  sendMobileData2$ = this.sendMobileData2.asObservable()

  sendUpdate = new Subject()
  sendUpdate$ = this.sendUpdate.asObservable()
  
  sendCarouselLength = new Subject()
  sendCarouselLength$ = this.sendCarouselLength.asObservable()
  
  sendCarouselMobileLength = new Subject()
  sendCarouselMobileLength$ = this.sendCarouselMobileLength.asObservable()
  
  sendRemovedData = new Subject()
  sendRemovedData$ = this.sendRemovedData.asObservable()

  sendDataListToAddList = new Subject()
  sendDataListToAddList$ = this.sendDataListToAddList.asObservable()
  
  sendSaveCarousel = new Subject()
  sendSaveCarousel$ = this.sendSaveCarousel.asObservable()
  
  sendTvDataToFeatured = new Subject()
  sendTvDataToFeatured$ = this.sendTvDataToFeatured.asObservable()

  sendRemovedTvData = new Subject()
  sendRemovedTvData$ = this.sendRemovedData.asObservable()
  
  sendDeletedTvData = new Subject()
  sendDeletedTvData$ = this.sendDeletedTvData.asObservable()

  sendBannerCarouselIds = new Subject()
  sendBannerCarouselIds$ = this.sendBannerCarouselIds.asObservable()

  constructor(
    public resourceService : ResourceService
  ) { }

  sendBannerCarousel(data){
    this.sendBannerCarouselIds.next(data)
  }
  
  sendDeletedData(data){
    this.sendDeletedTvData.next(data)
  }

  sendRemovedTv(data){
    this.sendRemovedTvData.next(data)
  }

  sendTvData(data){
    this.sendTvDataToFeatured.next(data)
  }

  sendSaveCarouselData(data){
    this.sendSaveCarousel.next(data)
  }

  sendDataList(data){
    this.sendDataListToAddList.next(data)
  }
  
  sendRemovedDataFromMobile(data){
    this.sendRemovedData.next(data)
  }

  sendCarouselLengthData(data){
    this.sendCarouselLength.next(data)
  }

  sendCarouselMobileLengthData(data){
    this.sendCarouselMobileLength.next(data)
  }

  sendUpdateToCreate(state){
  //   if(this.sendUpdate.observers[1]){
  //     this.sendUpdate.observers.splice(1, 1);
  // }
    this.sendUpdate.next(state)
  }

  sendMobileDat1(data){
    this.sendMobileData1.next(data)
  }

  sendMobileDat2(data){
    this.sendMobileData2.next(data)
  }
  
  sendDataItem1(data){
    this.sendDataItems1.next(data)
  }

  sendDataItem2(data){
    this.sendDataItems2.next(data)
  }

  sendStaticImageId(id){
    this.sendStaticImage.next(id)
  }

  sendCaraousalData(id){
    this.sendCaraousalImage.next(id)
  }

  sendMobile(id){
   this.sendMobileId.next(id)
  }

  sendDesignData(data){
    this.senddesignddata.next(data)
   }

   sendArtworkType(data){
    this.sendartworktype.next(data)
   }
  
  sendTv(id){
    this.sendTvId.next(id)
  }

  sendWeb(id){
   this.sendWebId.next(id)
  }

  sendIdCatalog(id){
    this.sendId.next(id)
  }

  sendCatalogId(id){
    this.sendCatalog.next(id)
  }

cancelPanel(data){
  this.cancel.next(data)
}

updatePanel1(state){
//   if(this.panel1.observers[1]){
//     this.panel1.observers.splice(1, 1);
// }
  this.panel1.next(state);
}
updatePanel2(state){
  this.panel2.next(state);
}
updatePanel3(state){
  this.panel3.next(state);
}

updatePanel5(state){
  this.panel5.next(state);
}

updatePanelState(state){
  this.panelState.next(state);
}
updateCalendarPanelState(state){
  this.calendarPanelState.next(state);
}
updateEbookPanelState(state){
  this.ebookPanelState.next(state);
}
updateNetworkPanelState(state){
  this.networkPanelState.next(state);
}
updateChannelPanelState(state){
  this.channelPanelState.next(state);
}
updatePanel(state){
//   if(this.panel.observers[1]){
//     this.panel.observers.splice(1, 1);
// }
  this.panel.next(state)
}

updateChannelPanel(state){
  this.channelPanel.next(state)
}

clickedFrom(state){
  if(this.clicked.observers[1]){
    this.clicked.observers.splice(1, 1);
}
  this.clicked.next(state)
}

addLinkToList(select){
  this.addLink.next(select)
}

addRssFeedToList(select){
  this.addRssFeed.next(select)
}

sendMediaSeries(id){
  this.mediaSeries.next(id)
}

sendFolderItem(id){
  this.folderItem.next(id)
}

sendMediaItem(id){
 this.mediaItem.next(id)
}



createListItem(data){
  const createListItemURL = `${appConfig.catalog}/createCatalog`;
  return this.resourceService.postData(createListItemURL,data)
}

createDataInSortedManner(data){
  const createDataInSortedMannerURL = `${appConfig.catalog}/getCatalogListInSortedOrder`;
  return this.resourceService.postData(createDataInSortedMannerURL,data)
}

getListItems(page, size,search) {
  const filter = search ? `&searchkey=${search}` : ''
  const getListItemUrl = `${appConfig.catalog}?page=${page}${filter}&size=${size}`;
  return this.resourceService.getData(getListItemUrl);
}

getSmartList(page, size,search,smartList) {
  const list = smartList ? `&smartList=${smartList}` : ''
  const filter = search ? `&searchkey=${search}` : ''
  const getListItemUrl = `${appConfig.catalog}?page=${page}${filter}&size=${size}${list}`;
  return this.resourceService.getData(getListItemUrl);
}

updateListItem(id, data) {
  const updateListItemUrl = `${appConfig.catalog}/${id}` ;
  return this.resourceService.putData(updateListItemUrl, data);
}

getListItemById(id) {
  const getListItemItemUrl = `${appConfig.catalog}/${id}`;
  return this.resourceService.getData(getListItemItemUrl);
}

filterSmartList(id,displayBy,noOfItem,sortBy) {
  var getListItemItemUrl = appConfig.catalog + "/getSmartListById?";
        if (displayBy !== null)
        getListItemItemUrl += "displayBy=" + encodeURIComponent("" + displayBy) + "&"; 
        if (id !== null)
        getListItemItemUrl += "id=" + encodeURIComponent("" + id) + "&"; 
        if (noOfItem !== null)
        getListItemItemUrl += "noOfItem=" + encodeURIComponent("" + noOfItem) + "&"; 
        if (sortBy !== null)
        getListItemItemUrl += "sortBy=" + encodeURIComponent("" + sortBy) + "&"; 
      
        getListItemItemUrl = getListItemItemUrl.replace(/[?&]$/, "");
        return this.resourceService.getData(getListItemItemUrl);
}


deleteListItem(id) {
  const deleteListItemUrl = `${appConfig.catalog}/${id}`;
  return this.resourceService.deleteData(deleteListItemUrl);
}

deleteListItems(filter) {
  const deleteListItemUrl = (`${appConfig.catalog}/delete?${filter}`);
  return this.resourceService.deleteData(deleteListItemUrl);
}

/* create List Design */

createListDesign(data){
  const createListDesignURL = (`${appConfig.listDesign}/createListDesign`)
  return this.resourceService.postData(createListDesignURL,data)
}

/* update list design */

updateListDesign(id,data){
  const updateListDesignURL = (`${appConfig.listDesign}/${id}`)
  return this.resourceService.putData(updateListDesignURL,data)
}

/* get List */

getListContent(page,size){
  const getListContentURL = (`${appConfig.catalog}/getAllCatalogContentList`)
  return this.resourceService.getData(getListContentURL)
}

/* Remove Catalog Content */

removeCatalogContent(data){
  const removeCatalogContentURL = (`${appConfig.catalog}/delete`)
  return this.resourceService.postData(removeCatalogContentURL,data)

}


/* Link Items */

/*  get Link */

getLInkItem(page,size,search){
  const filter = search ? `&searchkey=${search}` : '';
  const getLnkItemURL = (`${appConfig.link}/getAllLinkList?page=${page}${filter}&size=${size}`)
  return this.resourceService.getData(getLnkItemURL)
}
// for selectAll
getAllLInkItem(page,size,search,isSelected){
  const selected = isSelected ? `?isSelected=${isSelected}` : ''
  const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
  const filter = search ? `&searchkey=${search}` : '';
  const getLnkItemURL = (`${appConfig.link}/getAllLinkList${selected}${pages}${filter}&size=${size}`)
  return this.resourceService.getData(getLnkItemURL)
}

/*  get linkItem list in sorted manner   */

getLinkListInSortedManner(data){
  const getLinkListInSortedMannerUrl = (`${appConfig.link}/getLinkListInSortedOrder`)
  return this.resourceService.postData(getLinkListInSortedMannerUrl,data)
}
  

  /* createLink */

  createLinkItem(data){
    const createLinkItemURL = (`${appConfig.link}/createLink`)
    return this.resourceService.postData(createLinkItemURL,data)
  }
  

  /* get Link by Id */

  getLinkItemById(id){
    const getLinkItemByIdURL = (`${appConfig.link}/${id}`)
      return this.resourceService.getData(getLinkItemByIdURL)  
  }

  /* update Link */
   
  updateLinkItem(id,data){
    const updateLinkItemURL = (`${appConfig.link}/${id}`)
      return this.resourceService.putData(updateLinkItemURL,data)  
  }

  /* single delete link item */

  deleteLinkItem(id){
    const deleteLinkItemURL = (`${appConfig.link}?ids=${id}`)
    return this.resourceService.deleteData(deleteLinkItemURL)
  }
  
  /* bulk Delete Link Item */
  
   bulkdeleteLinkItem(id){
     const deleteLinkItemURL = (`${appConfig.link}?${id}`)
     return this.resourceService.deleteData(deleteLinkItemURL)
   }
   
  /*  Add link to list */

  addLinkList(listId,linkId){
    const addLinkToListURL = (`${appConfig.link}/${listId}/{linkId}?linkId=${linkId}`)
    return this.resourceService.putData(addLinkToListURL)

  }

  // change tabs folder data 
  changetabFolderdata(catalogId,tabId,type){
    if(type === 'calendar'){
      var changeTabFolderURL = (`${appConfig.tabs}?calendarId=${catalogId}&tabId=${tabId}`)
    }
    else if(type === 'ebook' || type === 'NETWORK'){
      var changeTabFolderURL = (`${appConfig.tabs}?mediaSeriesId=${catalogId}&tabId=${tabId}`)
    }
    else if(type === 'CHANNEL'){
      var changeTabFolderURL = (`${appConfig.tabs}?channelId=${catalogId}&tabId=${tabId}`)
    }
    else{
      var changeTabFolderURL = (`${appConfig.tabs}?catalogId=${catalogId}&tabId=${tabId}`)
    }
    return this.resourceService.putData(changeTabFolderURL)

  }

  /* Get rssFeed */

  getRssFeed(page,size,search){
    const filter = search ? `&searchkey=${search}` : ''
    const getRssFeedURL = (`${appConfig.rssFeed}/getAllRSSFeedList?page=${page}${filter}&size=${size}`)
    return this.resourceService.getData(getRssFeedURL)
  }

  getAllRssFeed(page,size,search,isSelected){
    const selected = isSelected ? `?isSelected=${isSelected}` : ''
    const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
    const filter = search ? `&searchkey=${search}` : ''
    const getRssFeedURL = (`${appConfig.rssFeed}/getAllRSSFeedList${selected}${pages}${filter}&size=${size}`)
    return this.resourceService.getData(getRssFeedURL)
  }

  /* Create RSS Feed  */

  createRssFeed(data){
    const createRssFeedURL = (`${appConfig.rssFeed}/createRSSFeed`)
    return this.resourceService.postData(createRssFeedURL,data)
  }

  /* get RSS Feed by Id */

  getRssFeedById(id){
    const getRssFeedByIdURL = (`${appConfig.rssFeed}/${id}`)
    return this.resourceService.getData(getRssFeedByIdURL)
  }
  
  /* Update RSS Feed */
  
  updateRssFeed(id,data){
    const updateRssFeedURL = (`${appConfig.rssFeed}/${id}`)
    return this.resourceService.putData(updateRssFeedURL,data)
  }
  
  /* get RSS Feed in sorted Manner */

  getRssFeedInSortedManner(data){
    const getRssFeedInSortedMannerURL = (`${appConfig.rssFeed}/getRSSFeedListInSortedOrder`)
    return this.resourceService.postData(data)
  }
  
  /* bulk delete Rss Feeds */
  
   bulkdeleteRssFeed(id){
     const deleteRssFeedURL = (`${appConfig.rssFeed}?${id}`)
     return this.resourceService.deleteData(deleteRssFeedURL)
   }

   deleteRssFeed(id){
    const deleteRssFeedURL = (`${appConfig.rssFeed}?ids=${id}`)
    return this.resourceService.deleteData(deleteRssFeedURL)
  }
   
  /* add Rss Feed to List */

  addRssFeedList(listId,rssFeedId){
     const addRssFeedListURL = (`${appConfig.rssFeed}/${listId}/{rssFeedId}?rssFeedId=${rssFeedId}`)
     return this.resourceService.putData(addRssFeedListURL)
  }

  /* get Design */

 getListDesign(id){
  const getListDesignURL = (`${appConfig.listDesign}/${id}`)
  return this.resourceService.getData(getListDesignURL)
}

/* delete List Design */
deleteListDesign(id){
  const deleteListDesignURL = (`${appConfig.listDesign}/${id}`)
  return this.resourceService.deleteData(deleteListDesignURL)
}

}

