import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticateService {

  constructor(public resourceService: ResourceService) { }

  loginUser(formData) {
    const loginUrl = appConfig.logIn;
    return this.resourceService.postData(loginUrl, formData);
  }

  getCountryList() {
    const getCountryUrl = appConfig.countryList;
    return this.resourceService.getData(getCountryUrl);
  }

  signUp(formData, UUID) {
    const signUpUrl = `${appConfig.signUp}?uuid=${UUID}`;
    return this.resourceService.postData(signUpUrl, formData);
  }

  resetPassword(formData, userId) {
    const resetUrl = `${appConfig.reset}?id=${userId}`;
    return this.resourceService.postData(resetUrl, formData);
  }

  forgetPassword(data) {
    const forgetUrl = `${appConfig.forgetPassword}`;
    return this.resourceService.postData(forgetUrl, data);
  }

  getLogo(uuid) {
    const getLogoUrl = `${appConfig.updateOrganization}/getDocumentDetails?uuid=${uuid}`;
    return this.resourceService.getData(getLogoUrl);
  }

  tokenValidate() {
    const tokenValidateUrl = `${appConfig.tokenValidate}`;
    return this.resourceService.putData(tokenValidateUrl);
  }

  /* Accept Invite */

  acceptInvite(id){
    const acceptInviteURL = `${appConfig.user}/inviteAccept?userId=${id}`
    return this.resourceService.putData(acceptInviteURL)
  }

  getTabsWithoutAuth(id){
    const getTabsWithoutAuthUrl = `${appConfig.tabs}/getActiveTabList?organizationId=${id}`;
    return this.resourceService.getData(getTabsWithoutAuthUrl);
  }

  getTabDataByIdWithoutAuth(tabId,orgId){
    const getTabsDataWithoutAuthUrl = `${appConfig.tabs}/tabId/${tabId}?organizationId=${orgId}`;
    return this.resourceService.getData(getTabsDataWithoutAuthUrl);
  }

  getBrandingDataWithoutAuth(orgId){
    const getBrandingWithoutAuthUrl = `${appConfig.branding}/getBrandingInfo?organizationId=${orgId}`;
    return this.resourceService.getData(getBrandingWithoutAuthUrl);
  }

  getBrandingbgColor(token:string ){
    const getBrandingbgColor = `${appConfig.branding}/getBrandColor?token=${token}`;
    return this.resourceService.getData(getBrandingbgColor);
  }

checkTokenValidate(id,token){
  const getTokenValidate = `${appConfig.checkTokenValidate}/?id=${id}&token=${token}`;
    return this.resourceService.postData(getTokenValidate);
}


  signUpOrg(formData) {
    const signUpOrgUrl = `${appConfig.updateOrganization}/organizationSignUp`;
    return this.resourceService.postData(signUpOrgUrl, formData);
  }

  verifyEmail(code,token) {
    const verifyEmail = `${appConfig.updateUser}/emailVerification?code=${code}&token=${token}`;
    return this.resourceService.postData(verifyEmail);
  }


  getBrandingColor(token:string) {
    const brandColor = `${appConfig.branding}/getBrandColor?token=${token}`;
    return this.resourceService.getData(brandColor);
  }



  getTvAppAllDetails(orgId){
    const getTvAppAllDetailsUrl = `${appConfig.tabs}/getTvAppAllDetails?organizationId=${orgId}`;
    return this.resourceService.getData(getTvAppAllDetailsUrl);
  }

  getTvAppSelectedType(orgId){
    const getTvAppSelectedTypeUrl = `${appConfig.tabs}/tvAppContent?organizationId=${orgId}`;
    return this.resourceService.getData(getTvAppSelectedTypeUrl);
  }

  getRssFeedByURL(url){
    const getRssFeedByURL = `${appConfig.rssFeed}/getRSSFeedResponse?url=${url}`;
    return this.resourceService.getData(getRssFeedByURL);
  }

  getInvoiceById(invoiceId){
    const getInvoiceByIdURL = `${appConfig.invoice}/${invoiceId}`;
    return this.resourceService.getData(getInvoiceByIdURL);
  }

  getOrganisationInfoForStorage(type: string, orgId: number) {
    const getCountryUrl = `${appConfig.organization}/organizationPlanCardDetails?itemType=${type}&organizationId=${orgId}`;
    return this.resourceService.getData(getCountryUrl);
  }

  makeStoragePayment(data,type:any) {
    const addcardDetailsURL = `${appConfig.organization}/updateDataPricingPlan?itemType=${type}`;
    return this.resourceService.putData(addcardDetailsURL, data);
  }

  addcardDetailsWithoutAuth(data) {
    const addcardDetailsURL = `${appConfig.usersubScription}`;
    return this.resourceService.postData(addcardDetailsURL, data);
  }


}

