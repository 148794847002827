import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appScrollUp]'
})
export class ScrollUpDirective {
  private lastScrollTop = 0;
  @Output() scrolledUp = new EventEmitter<void>();

  constructor(private el: ElementRef) { 
  }

  @HostListener('scroll', [])
  onScroll() {
    const st = this.el.nativeElement.scrollTop;

    if (st == '0') {
      this.scrolledUp.emit();
    }

    this.lastScrollTop = st;
  }

}
