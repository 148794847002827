export class CreateSubscription{
    description : string;
    documentId : number;
    name : string;
    price : number;
    subscriptionPlanDuration : string;
    trialDays : number;
    vastTagEnabled : boolean;

    constructor(obj?){
        this.description = obj && obj.description ? obj.description : null;
        this.documentId = obj && obj.documentId ? obj.documentId : null;
        this.name = obj && obj.name ? obj.name : null;
        this.price = obj && obj.price ? obj.price : null;
        this.subscriptionPlanDuration = obj && obj.subscriptionPlanDuration ? obj.subscriptionPlanDuration : null;
        this.trialDays = obj && obj.trialDays ? obj.trialDays : null;
        this.vastTagEnabled = obj && obj.vastTagEnabled ? obj.vastTagEnabled : false;
    }
}

export class CreateCustomSubscription {
    name: string;
    price: number;
    subscriptionPlanDuration: string;
    trialDays: number;
    // permission: Array<any>
    // type: string
    constructor(obj?) {
        this.name = obj && obj.name ? obj.name : '';
        this.price = obj && obj.price ? obj.price : null;
        this.subscriptionPlanDuration = obj && obj.subscriptionPlanDuration ? obj.subscriptionPlanDuration : null;
        this.trialDays = obj && obj.trialDays ? obj.trialDays : null;
        // this.permission = obj && obj.permission ? obj.permission : [];
        // this.type = obj && obj.type ? obj.type : null;

    }
}


export class EditSubscription{
    planAmount: number
    trialDays: number
    constructor(obj?){
        this.planAmount = obj && obj.planAmount ? obj.planAmount : null;
        this.trialDays = obj && obj.trialDays ? obj.trialDays : 0;

    }
}