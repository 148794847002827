import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileServicesService {
  
  profileImage = new Subject();
  profileImage$ = this.profileImage.asObservable();

  constructor(
    public resourceService : ResourceService
  ) { }
  
  sendProfile(data){
    this.profileImage.next(data)
  }

  getOrganizationProfile(){
    const getOrganizationURL = `${appConfig.organization}/myProfile`
    return this.resourceService.getData(getOrganizationURL)
  }

  updateOrganizationProfile(data){
    const updateOrganizationURL = `${appConfig.organization}/myProfile`
    return this.resourceService.putData(updateOrganizationURL,data)
  }

  changePassword(id,data){
    const changePasswordURL = `${appConfig.organization}/changePassword/${id}`
    return this.resourceService.putData(changePasswordURL,data)
  }

}
