import { SharedService } from './services/shared/shared.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticateService } from './services/authenticateService/authenticate.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import FacebookPixelService from './services/facebook-pixel/facebook-pixel.service';
import { TimezoneService } from './services/shared/timezone.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'nowcast';
  showLoader = false;
  showLoader1 = false;
  loaderSubscription: Subscription;
  loaderSubscription1: Subscription 
  gettoken: any;
  isPaymentDone: boolean
  activeCard: boolean
  role : string
  constructor(
    public sharedService: SharedService,
    public authService: AuthenticateService,
    public router: Router,
    private cdref: ChangeDetectorRef,
    private facebookPixelService: FacebookPixelService,
    private timezoneService: TimezoneService
  ) {

  }

  ngAfterContentChecked() {
    // this.cdref.detectChanges();
  }

  ngOnInit() {
    this.isPaymentDone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.isPaymentDone : null;
    this.activeCard = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.activeCard : null;
    this.role = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.roles[0].name : null
    if (!this.activeCard && this.activeCard !== null && this.role !== 'ROLE_SUPER_ADMIN') {

      this.router.navigate(['/payment-module/payment'])

    }

    if(this.router.url === 'organisationSignup'){
      setTimeout(() => {
        localStorage.clear();
        this.router.navigate(['/organisationSignup']);
      }, 100);
    }
    this.timezoneService.getTimezoneChanges().subscribe((timezone) => {
      localStorage.setItem('timeZone', timezone);
    });

    this.loaderSubscription = this.sharedService.loaderState$.subscribe((state: any) => {
      this.showLoader = state;
    });

    this.loaderSubscription1 = this.sharedService.loaderState1$.subscribe((state: any) => {
      this.showLoader1 = state;
    });
    // if(this.router.url !== '/login' && this.router.url !== '/' && this.router.url !== '/reset-password' && this.router.url !== '/signup' ){
    //   this.tokenValidate();
    // }
    setTimeout(() => {
      const isSignupPage = this.router.url.includes('organizationSignup') || this.router.url.includes('payment');
      this.facebookPixelService.initPixel('455037236820317', isSignupPage);
    }, 100);

  }

  ngOnDestroy() {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
    if (this.loaderSubscription1) {
      this.loaderSubscription1.unsubscribe();
    }
  }


  tokenValidate() {
    // if(this.router.url !== '/login'){
    this.authService.tokenValidate().subscribe((responce) => {
      this.gettoken = responce.body.data;
      // this.messageService.add({ severity: 'success', summary: 'Success', detail: `Media Item ${type === 'PUBLISH' ? 'published' : 'updated'} successfully` });
      // this.router.navigate(['/media-module/media/on-demand']);
    }, (error) => {
      setTimeout(() => {
        localStorage.clear();
        this.router.navigate(['/login']);
      }, 100);
      // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    // }
  }
}
