import { Injectable } from '@angular/core';
import { Observable, fromEvent, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private timezoneChanges$: Observable<string>;
  private observer: Observer<string>;

  constructor() {
    this.timezoneChanges$ = new Observable<string>((observer) => {
      this.observer = observer;
      observer.next(this.getCurrentTimezone());
    });

    fromEvent(window, 'timezonechange').subscribe(() => {
      this.observer.next(this.getCurrentTimezone());
    });
  }

  public getTimezoneChanges(): Observable<string> {
    return this.timezoneChanges$;
  }

  private getCurrentTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
