import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// declare const fbq: Function;

interface FBQWindow extends Window {
    fbq?: Function;
  }

@Injectable({
    providedIn: 'root'
  })
  export class FacebookPixelService {
    constructor(private router: Router) {
        const fbqWindow = window as FBQWindow;
        if (fbqWindow.fbq) {
        fbqWindow.fbq('init', '455037236820317');
        fbqWindow.fbq('track', 'PageView');
        }
     }

    initPixel(pixelId: string, shouldInitialize: boolean) {
        if (shouldInitialize) {
            // // Facebook Pixel initialization
            // fbq('init', pixelId);
        
            // // Track route changes
            // this.router.events.subscribe(event => {
            // if (event instanceof NavigationEnd) {
            //     fbq('track', 'PageView');
            // }
            // });
            const fbqWindow = window as FBQWindow;
            if (fbqWindow.fbq) {
            fbqWindow.fbq('init', pixelId);
        
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                fbqWindow.fbq('track', 'PageView');
                }
            });
            }
        }
      }
  }
  
  export default FacebookPixelService;