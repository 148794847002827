import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription, fromEvent } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { EventService } from 'src/app/services/events/event-services';
import { MediaService } from 'src/app/services/media/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AppAnimation } from 'src/app/utilities/animations/animations';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: 'app-change-eventpanel',
  templateUrl: './change-eventpanel.component.html',
  styleUrls: ['./change-eventpanel.component.css'],
  animations: AppAnimation
  
})
export class ChangeEventpanelComponent implements OnInit {

  overlay = false;
  sidePanel = false;
  eventId: number
  calenderItem: any[]=[];
  totalCalender: number;
  selectedItems: any;
  showall =  false;
  subsciptionData = new Subscription();
  calendarId : number;
  radCheck = false;

  @ViewChild('searchInput') searchInput: ElementRef;
  constructor(
    public eventService : EventService,
    public sharedService: SharedService,
    public messageService: MessageService,
    public router: Router,
    public mediaService : MediaService
  ) { }

  ngOnInit(): void {
    this.getSubscriptionData()
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
    
      this.getCalenderItem(1, 5);
      
    })).subscribe();
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }
 
  getSubscriptionData(){
    this.subsciptionData.add(
      this.eventService.panelState$.subscribe(
        (state: any) => {
          this.overlay = state.showPanel
          this.sidePanel = state.showPanel;
          this.eventId = state.id
          this.calendarId = state.calendarId
          this.getCalenderItem(1,8)
          if(this.searchInput){
            this.searchInput.nativeElement.value = null
          }
        })
      )
  }

  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.eventService.updatePanelState({ showPanel: false })
  }

  cancel() {
    this.radCheck = true
    this.eventService.updatePanelState({ showPanel: false })
  }

  changeCalender(){
      // let filter = '';
      // filter += filter += `ids=${this.mediaItemId}`
      let id = this.eventId
      this.eventService.moveEvent({ calendarId: this.selectedItems },id).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Event Moved Successfully.' });
        // this.router.navigate([`/media-module/media/on-demand/edit-media-series/${this.msId}`]);
        this.eventService.updatePanelState({ showPanel: false })
        this.mediaService.sendUpdateToCreate({ update : true })
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    
  }


  getCalenderItem(page,size) {
    // var search = ''
    this.eventService.getCalenderDataOnPanel(this.eventId,page,size,this.searchInput ? this.searchInput.nativeElement.value : '').subscribe((response: any) => {
      this.calenderItem = response.data.content;
      this.totalCalender = response.data.totalElements
      this.calenderItem.forEach(data =>{
        if(data.squareArtwork){
          // if(data.squareArtwork.document.bdImage === true){
            if(data.squareArtwork.artworkType === "SQUARE"){var width = 270;var height = 270;}
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
              
            // })
          // }
        }
        else if(data.bannerArtwork){
          // if(data.bannerArtwork.document.bdImage === true){
            if(data.bannerArtwork.artworkType === "BANNER"){var width = 300;var height = 100;}
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
              
            // })
          // }
        }
        else if(data.wideArtwork){
          // if(data.wideArtwork.document.bdImage === true){
            if(data.wideArtwork.artworkType === "WIDE"){var width = 270;var height = 130;}
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
            //   this.sharedService.updateLoader(false);
            //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
              
            // })
          // }
        }
        if(data.id === this.calendarId){
          this.radCheck = true
          data['itemChecked'] = true
        }
      })
    });
  }

  selectedCalender(data) {
    this.selectedItems = data.id
  }

  showmore() {
    this.showall = true
    this.getCalenderItem(1, this.totalCalender)
  }

}
