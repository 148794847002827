import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaService } from 'src/app/services/media/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AppAnimation } from 'src/app/utilities/animations/animations';
import { appConfig } from 'src/app/app.config';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, window } from 'rxjs/operators';
import { LiveStream, MediaItem } from 'src/app/models/media.model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';

@Component({
  selector: 'app-static-header-panel',
  templateUrl: './static-header-panel.component.html',
  styleUrls: ['./static-header-panel.component.css'],
  animations: AppAnimation
})
export class StaticHeaderPanelComponent implements OnInit {

  subscriptionData = new Subscription()
  overlay = false
  sidePanel = false
  showOptions = true
  mediaItems = true
  livestreamItem = false
  mediaChecked = true
  livestreamChecked = false
  arrayMobile: any[] = []
  recentMedia: any[] = []
  selectedMediaItems: any[] = []
  newMediaItemData: any[] = []
  newData2: any[] = []
  totalMediaItems: number = 0
  totalMediaItem: any[] = []
  showAllMediaItem = false
  carouselArtworkType = 'WIDE'
  type = 'MEDIA_ITEM'
  totalLivestreams: number = 0
  totalLivestream: any[] = []
  recentLivestream: any[] = []
  showAllLivestream = false
  mediaItem: MediaItem
  livestreamItemModel: LiveStream
  createMedIt = false
  selectedId: number
  tabId: number
  clickedFrom: string

  @ViewChild('searchMediaItems') searchMediaItems: ElementRef;
  @ViewChild('searchInputLivestream') searchInputLivestream: ElementRef;

  constructor(
    public sharedService: SharedService,
    public mediaService: MediaService,
    public messageService: MessageService,
    public router: Router,
    private libraryService: LibraryServiceService
  ) {
    this.mediaItem = new MediaItem()
    this.livestreamItemModel = new LiveStream()
  }

  ngOnInit(): void {
    this.getSubscription()
  }

  getSubscription() {
    this.subscriptionData.add(
      this.sharedService.openStaticPanel$.subscribe(
        (state: any) => {
          this.emptySearch()
          this.type = 'MEDIA_ITEM'
          // panel:true,mediaItemId
          this.overlay = state.panel
          this.sidePanel = state.panel
          this.selectedId = state.mediaItemId
          this.tabId = state?.tabId
          this.clickedFrom = state?.tabType
          if (state?.itemType === 'vidItem') {
            this.type = 'MEDIA_ITEM'
          } else if (state?.itemType === 'liveItem') {
            this.type = 'LIVE_STREAMING'
          }
          this.getMediaItems(1, 5)
        }))
  }

  closePanel() {
    this.overlay = false
    this.sidePanel = false
    this.selectedMediaItems = []
    this.newMediaItemData = []
    this.showAllLivestream = false
    this.showAllMediaItem = false
    this.showOptions = true
    this.mediaItems = true
    this.livestreamItem = false
    this.mediaChecked = true
    this.livestreamChecked = false
    this.sharedService.openStaticPanelHeader({ panel: false, opacity: false, mediaItemId: null })
  }

  createMedItem(type) {
    this.showOptions = false
    this.createMedIt = true
    if (this.type !== 'LIVE_STREAMING') {
      this.mediaItem = new MediaItem()
    } else {
      this.livestreamItemModel = new LiveStream()
    }
  }

  closeMediaItem(type) {
    this.showOptions = true
    this.createMedIt = false
  }

  createMediaItem(mediaItem) {
    mediaItem.itemType = this.type
    if (this.type === 'LIVE_STREAMING') {
      mediaItem.liveStreamDataDTO.streamName = mediaItem.title
      mediaItem.liveStreamDataDTO.startDate = null
      mediaItem.liveStreamDataDTO.startTime = ''
      mediaItem.liveStreamDataDTO.endDate = null
      mediaItem.liveStreamDataDTO.endTime = ''
    }

    this.mediaService.createMediaItem(mediaItem).subscribe((response: any) => {
      this.getMediaItems(1, 10);
      this.showOptions = true
      this.createMedIt = false
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.type === 'MEDIA_ITEM' ? 'Video  created Successfully.' : 'Livestream created Successfully.' });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });

  }

  showmoreMediaItem() {
    if (this.type !== "LIVE_STREAMING") {
      this.showAllMediaItem = true
      this.getMediaItems(1, this.totalMediaItems)
    } else {
      this.showAllLivestream = true
      this.getMediaItems(1, this.totalLivestreams)
    }
  }

  getMediaItems(page, size) {
    if (this.type === "LIVE_STREAMING") {
      this.livestreamItem = true
      this.livestreamChecked = true
      this.mediaItems = false
      this.mediaChecked = false
    } else {
      this.mediaItems = true
      this.mediaChecked = true
      this.livestreamItem = false
      this.livestreamChecked = false
    }
    this.selectedMediaItems = []
    this.newMediaItemData = []
    this.mediaService.getMediaItemsOnTop(page, size, this.selectedId, this.searchMediaItems ? this.searchMediaItems.nativeElement.value : '', this.type, '').subscribe((response: any) => {
      if (this.type === "LIVE_STREAMING") {
        this.recentLivestream = response.data.content;
        this.totalLivestreams = response.data.totalElements
        this.recentLivestream = this.getData(this.recentLivestream)
        this.totalLivestream = this.recentLivestream.slice(0, 4)
      } else {
        this.recentMedia = response.data.content;
        this.totalMediaItems = response.data.totalElements
        this.recentMedia = this.getData(this.recentMedia)
        this.totalMediaItem = this.recentMedia.slice(0, 4)
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptionData) {
      this.subscriptionData.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.type === 'LIVE_STREAMING') {
      fromEvent(this.searchInputLivestream.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
        this.getMediaItems(1, 10);
        this.showAllLivestream = false
      })).subscribe();
    } else {
      fromEvent(this.searchMediaItems.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
        this.getMediaItems(1, 10);
        this.showAllMediaItem = false
      })).subscribe();
      this.showAllMediaItem = false
    }
  }

  emptySearch() {
    if (this.searchInputLivestream) {
      this.searchInputLivestream.nativeElement.value = null
    }
    if (this.searchMediaItems) {
      this.searchMediaItems.nativeElement.value = null
    }
  }

  selectItems(event, item) {
    this.selectedMediaItems = []
    if (this.type === "MEDIA_ITEM") {
      this.recentMedia.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        } else {
          el.itemChecked = false
        }
      });
      this.selectedMediaItems = this.recentMedia.filter((el) => el.itemChecked);
      this.newMediaItemData = this.selectedMediaItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: 'MEDIA_ITEM',
      }))
    } else {
      this.recentLivestream.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        } else {
          el.itemChecked = false
        }
      });
      this.selectedMediaItems = this.recentLivestream.filter((el) => el.itemChecked);
      this.newMediaItemData = this.selectedMediaItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: 'LIVE_STREAMING',
      }))
    }

  }

  apply() {
    this.sharedService.sendStaticPanelHeader({ realData: this.selectedMediaItems, mappedData: this.newMediaItemData })
    this.closePanel()
  }

  saveData() {
    this.apply()
  }

  cancelPanel(){
    this.libraryService.cancelPanel(true)
  }

  getData(array) {
    array.forEach(data => {
      if (data.squareArtwork) {
        if (data.squareArtwork.artworkType === "SQUARE") {
          let id = data.squareArtwork.document.id;
          data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;
        }
      }
      if (data.bannerArtwork) {
        if (data.bannerArtwork.artworkType === "BANNER") {
          let id = data.bannerArtwork.document.id;
          data.bannerArtwork.document['newbannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;
        }
      }
      if (data.wideArtwork) {
        if (data.wideArtwork.artworkType === "WIDE") {
          let id = data.wideArtwork.document.id;
          data.wideArtwork.document['newwideimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.wideHeight}` + '&width=' + `${appConfig.wideWidth}`;
        }
      }
      if (data.id === this.selectedId && this.selectedId) {
        data['itemChecked'] = true
        this.selectedMediaItems.push(data)
        this.newMediaItemData = this.selectedMediaItems.map((el) => ({
          id: el.id,
          sortOrder: 1,
          type: this.type,
        }))
      } else {
        data['itemChecked'] = false
      }
    })
    return array;
  }

  goTo(data, type) {
    // if (this.panel === 'mobile' || this.panel === 'list') {
    if (data?.status && data?.status === 'DRAFT') {
      // let panelType = this.panel === "mobile" ? 'app-panel' : 'list-panel'
      let panelType = 'header-panel'
      if (type === 'vidItem') {
        this.router.navigate([`/media-module/media/on-demand/edit-media-item/${data.id}`], { queryParams: { type: type, from: panelType, tabType: this.clickedFrom, tabId: this.tabId } });
      }
      else {
        this.router.navigate([`/media-module/media/live-streaming/edit-live-stream/${data.id}`], { queryParams: { type: type, from: panelType, tabType: this.clickedFrom, tabId: this.tabId } });
      }
    }
    // }
  }

}
