import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { appConfig } from 'src/app/app.config';
import { emptyPlayListData } from 'src/app/models/authenticate.model';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';
import { MediaService } from 'src/app/services/media/media.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tv-preview',
  templateUrl: './tv-preview.component.html',
  styleUrls: ['./tv-preview.component.css']
})
export class TvPreviewComponent implements OnInit {

  mainHomeBgImage = ''
  leftGradiant = 'linear-gradient(to left, rgba(42, 42, 42, 0.1) 65%, rgb(42, 42, 42))'
  mainBGBlur = 'radial-gradient(at 55% 40%, rgba(42, 42, 42, 0.1), rgb(42, 42, 42))'
  bottomGradiant = 'linear-gradient(rgba(42, 42, 42, 0.1) 70%, rgb(42, 42, 42))'
  leftBottomGradiant = 'linear-gradient(200deg, rgba(42, 42, 42, 10%) 65%, rgb(42, 42, 42))'
  mediaData: any[]=[];
  allTvAppData: any[]=[];
  orgId: any;
  showTitle :any
  showSubTitle:any
  showDesc:any;
  logo: any;
  logoHeight: any;
  showData = false
  currentSelectedChannelLogo
  showVod = false
  showChannel = false
  hamOptions = false;
  showNetwork = false
  autoPlayChange = true
  logoOnRight: any
  logoOnPlayer: any
  playlistType: any
  videoOnLoop: any
  currentSelectedChannelName: any
  currentSelectedChannelDesc: any
  categoryData: any[]=[]
  currentDate = new Date()
  tillCurrectTimeSec: any;
  currentDay: any
  sendDate: any
  allDayDefault: any[]=[]
  checkCurrentTimeInterval: any
  currentPlayingItemName: any;
  currentStartItemTime: any;
  currentEndItemTime: any;
  currentItemRemainingTime: any;
  currentItemCurrentTime: any;
  videoUrl: any;
  streamId: any
  selectedChannel: any
  itemDescription: any;
  isManual = false
  channelId: number
  networkId: number
  channelType: any
  networkType: any
  m3uData: any[]=[]
  channelData: any[]=[]
  customNetworkData: any[]=[]
  videoJsPlayerUrl: any;
  videoJsPlayerType: any;
  showChannelVOD= false;
  showNetworkVOD= false;
  isVod = false;
  showUI = false
  isChannel = false;
  isNetwork = false;
  selectedChannelId: any
  showSpinner = false
  showm3uContainer = false
  showErrorMessage = false
  showViewAll = false
  showNotFound = false
  showM3uMenu = true
  m3uType = ''
  selectedM3uCategory: any
  selectedM3uCategoryNameFromDropdown: any
  viewAllSelected = false
  selectedM3uCategoryName: any
  pageForChannelM3u = 1;
  allM3uData: any[]=[]
  data: any[]=[]
  m3uCategory: any[]=[]
  allCatagoryData: any[]=[]
  showCategory = 'All'
  interval: any
  showLoader=false;
  sendDateData = {
    slotInterval: 30,
    openTime: '00:00:00',
    closeTime: '23:59:59',
    date: moment(this.currentDate).format('YYYY-MM-DD')
  };
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == this.KEY_CODE.DOWN_ARROW){
      // Your row selection code
      console.log(event);
    }
    else if(event.keyCode == this.KEY_CODE.UP_ARROW){
      // Your row selection code
      console.log(event);
    }
    else if(event.keyCode == this.KEY_CODE.RIGHT_ARROW){
      // Your row selection code
      console.log(event);
    }
    else if(event.keyCode == this.KEY_CODE.LEFT_ARROW){
      // Your row selection code
      console.log(event);
    }
  }

   KEY_CODE = {
    UP_ARROW : 38,
    DOWN_ARROW : 40,
    RIGHT_ARROW : 39,
    LEFT_ARROW : 37
}
@ViewChild('scrollOne') scrollOne: ElementRef;
@ViewChild('scrollTwo') scrollTwo: ElementRef;
@ViewChild('scrollthree') scrollthree : ElementRef;
  constructor(
    private authService : AuthenticateService,
    private sanitizer: DomSanitizer,
    private _mediaService: MediaService,
    private cdr: ChangeDetectorRef
  ) {
    this.generateCalendar(this.sendDateData)
  }

  ngOnInit(): void {
    this.orgId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.id : '';
    this.getTvAppSelectedType(this.orgId);
  }

  ngOnDestroy(): void {
    if (this.checkCurrentTimeInterval) {
      clearInterval(this.checkCurrentTimeInterval);
    }
    if(this.interval){
      clearInterval(this.interval)
    }
  }

  activeData(mediaSeriesIndex,mediaItemIndex){
    console.log(mediaSeriesIndex,mediaItemIndex);
    this.allTvAppData.forEach((el,i)=>{
      el.mediaItemDTO.forEach((element,index) => {
        element['active'] = false;
      })
    })
    this.allTvAppData[mediaSeriesIndex].mediaItemDTO[mediaItemIndex].active = true;
    this.showTitle = this.allTvAppData[mediaSeriesIndex].mediaItemDTO[mediaItemIndex].title
    this.showSubTitle = this.allTvAppData[mediaSeriesIndex].mediaItemDTO[mediaItemIndex].subTitle
    this.showDesc = this.allTvAppData[mediaSeriesIndex].mediaItemDTO[mediaItemIndex].description
    this.mainHomeBgImage = this.allTvAppData[mediaSeriesIndex].mediaItemDTO[mediaItemIndex].wideArtwork.document.newimgBanner
  }

  getTvAppSelectedType(id) {
    this.authService.getTvAppSelectedType(id).subscribe((response: any) => {
      this.showVod =false
      this.showChannel = false
      this.showNetwork = false
      this.isVod = response.data.isVod === true ? true: false;
      this.isChannel = response.data.isChannel === true ? true: false;
      this.isNetwork = response.data.isNetwork === true ? true: false;
      this.getTvAppAllDetails(this.orgId,response.data.isVod)
      // if(response.data.isVod === true){
      // }
      this.showChannelVOD = false
      this.showNetworkVOD = false
      if(response.data.isChannel === true && response.data.isVod === true){
        this.showChannelVOD = true
        this.channelId = response.data.id
        this.getMediaItemData(this.channelId, 'channelData');
      }
      if(response.data.isNetwork === true && response.data.isVod === true){
        this.showNetworkVOD = true
      }
      if(response.data.isChannel === true && response.data.isVod === false && response.data.isNetwork === false){
        this.channelId = response.data.id
        this.getMediaItemData(this.channelId, 'channelData');
      }
      if(response.data.isNetwork === true && response.data.isVod === false && response.data.isChannel === false){
        this.showNetworkView(response)
      }
      if(response.data.isNetwork === false && response.data.isVod === true && response.data.isChannel === false){
        this.showVod = true
        // this.getTvAppAllDetails(this.orgId,response.data.isVod)
      }
      if(response.data.isChannel === true){
        this.showVod = false
        this.showChannel = true
        this.showNetwork = false
      }
      if(response.data.isNetwork === true){
        this.showVod = false
        this.showChannel = false
        this.showNetwork = true
        this.showNetworkView(response)
      }
      setTimeout(() => {
        this.showData = true
      }, 2500);
    })
  }

  showNetworkView(response){
    setInterval(() => {
      this.currentDate = new Date()
      this.currentDay = moment(this.currentDate).format('dddd').substring(0,3)

      let sendDateTime = new Date() 
      let todayDateTime = moment(sendDateTime).format('YYYY-MM-DD') + ' ' + '00:00:00'
      let currentDateTime = moment(sendDateTime).format('YYYY-MM-DD HH:mm:ss')
      var getDiff = moment(currentDateTime).diff(moment(todayDateTime))
      // let secondsDiff =  currentDateTime.diff(todayDateTime)
      let getDiffinSec = getDiff / 1000;
      // console.log(secondsDiff,'<=============secondsDiff');
      
      this.tillCurrectTimeSec = getDiffinSec
    }, 1000);
    this.networkId = response.data.id
    this.sendDate = moment(this.currentDate).format('YYYY-MM-DD')
    this.getMediaSeriesData(this.sendDate,this.networkId,10)
  }

  switchMenu(type){
    switch (type) {

      case 'channel':
        this.showVod = false
        this.showChannel = true
        this.showNetwork = false
        this.getMediaItemData(this.channelId, 'channelData');
        break;

      case 'vod':
        this.showVod = true
        this.showChannel = false
        this.showNetwork = false
        this.getTvAppAllDetails(this.orgId,true)
        break;

      case 'network':
        this.showVod = false
        this.showChannel = false
        this.showNetwork = true
        this.showM3uMenu = true
        break;

      default: return;

    }
  }

  getTvAppAllDetails(id,isActive) {
    this.authService.getTvAppAllDetails(id).subscribe((response: any) => {
      this.logo = `${appConfig.imageURL}` + response.data[0].organizationLogo.id;
      if(isActive){
        this.allTvAppData = response.data[0].mediaSeriesDTOList;
        this.allTvAppData.forEach((el,i)=>{
          el.mediaItemDTO.forEach((element,index) => {
            element['index'] = index
            if (element.wideArtwork) {
              var width = 160; var height = 90; 
              let id = element.wideArtwork.document.id;
              element.wideArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              element.wideArtwork.document['newimgBanner'] = `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              if(i === 0){
                el.mediaItemDTO[0]['active'] = true;
                this.mainHomeBgImage = el.mediaItemDTO[0].wideArtwork.document.newimgBanner;
                this.showTitle = el.mediaItemDTO[0].title;
                this.showSubTitle = el.mediaItemDTO[0].subTitle
                this.showDesc = el.mediaItemDTO[0].description
              }
            }
          });
        })
        this.mediaData = this.allTvAppData.map((el, i) => ({
          title: el.title,
          mediaItem: el.mediaItemDTO,
        }))
        console.log(this.mediaData);
      }
      
    })
  }

  /**
   * To get Media Item data
   * @param id Media Item Id
   */
   getMediaItemData(id, type) {
    this._mediaService.getMediaById(id).subscribe((response: any) => {
      this.showChannel = true
      this.channelType = response.data.channelInfoDTO.channelType
      this.videoJsPlayerUrl = ''
      this.videoJsPlayerType = ''
      if(this.channelType === 'M3U8'){
        this.videoJsPlayerUrl = response.data.channelInfoDTO.m3u8Url
        this.videoJsPlayerType = 'application/x-mpegURL'
      }
      else{
        this.videoJsPlayerUrl = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
        this.videoJsPlayerType = 'application/x-mpegURL'
      }
    });
  }

  getMediaSeriesData(date,id,noOfItems) {
    // this.m3uData = []
    this._mediaService.getNetworkData(date,id,noOfItems).subscribe((response: any) => {
      this.showNetwork = true
      this.networkType = response.data.isM3U ? 'M3U': 'CUSTOM'
      // this.networkType = 'M3U'
      if(this.networkType === 'M3U'){
        // this.m3uData = response.data.m3uChannelList ? response.data.m3uChannelList : []
        this.isManual = false
        this.selectedChannelId = response.data.manualChannelList[0].Playlist[0].id
          let m3uStatus = response.data.manualChannelList[0].Playlist[0].processURL
            if(m3uStatus === 'IN_PROGRESS'){
              this.showSpinner = true
              this.showm3uContainer = false
              this.interval = setInterval(() => {
                this.getMediaSeriesData(this.sendDate,this.networkId,10)
            }, 10000);
          }
          else if(m3uStatus === 'ERROR'){
            if(this.interval){
              clearInterval(this.interval)
            }
            this.showSpinner = false
            this.showm3uContainer = false
            this.showErrorMessage = true
          }
          else{
            if(this.interval){
              clearInterval(this.interval)
            }
            this.showSpinner = false
            // this.showm3uContainer = true
            // this.selectM3uType(this.m3uType)
          }
      }
      else{
        this.customNetworkData = response.data.manualChannelList ? response.data.manualChannelList : []
        this.isManual = true
        this.videoJsPlayerUrl = ''
        this.videoJsPlayerType = ''
        // this.videoJsPlayerType = 'application/x-mpegURL'
        if(this.customNetworkData.length>0){
          this.customNetworkData.forEach((el: any,i: any)=>{
            if(el.Playlist.length>0){
              el.Playlist.forEach((element:any,index: any) => {
                element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                element['isSelected'] = false
              });
              this.customNetworkData[0].Playlist[0]['isSelected'] = true
              setTimeout(() => {
                if(i === 0){
                  if(this.customNetworkData[i].Playlist[i]){
                    this.selectChannel(this.customNetworkData[0].Playlist[0])
                  }
                }
                // this.streamId = this.customNetworkData[0].Playlist[0].streamId
              }, 1000);
              // this.videoJsPlayerType = ''
              // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
              this.videoJsPlayerUrl = this.customNetworkData[0].Playlist[0].playlistUrl
              this.currentSelectedChannelLogo = this.customNetworkData[0].Playlist[0].logoPath
              this.logoOnRight = this.customNetworkData[0].Playlist[0].logoOnRight
              this.logoOnPlayer = this.customNetworkData[0].Playlist[0].logoOnPlayer
              this.playlistType = this.customNetworkData[0].Playlist[0].playlistType
              this.videoOnLoop = this.customNetworkData[0].Playlist[0].videoOnLoop
              this.currentSelectedChannelName = this.customNetworkData[0].Playlist[0].title
              this.currentSelectedChannelDesc = this.customNetworkData[0].Playlist[0].description
            }
          })
          this.categoryData = this.customNetworkData.map((el)=>({
            categoryName : el.Category
          }))
          // let newCategoryData = [...new Set(this.categoryData)];
          // console.log(newCategoryData);
          
        }
      }
    });
  }

  handleMissingImage(event:any){
    event.target.src = 'assets/images/common/no-image-icon-23494.png';
    (event.target as HTMLImageElement).style.width = "55px";
  }

  generateCalendar(x: any) {
    //Format the time
    let start = x.date + ' ' + x.openTime
    let end = x.date + ' ' + x.closeTime
    let startTime = moment(start, "YYYY-MM-DD HH:mm");

    //Format the end time and the next day to it 
    let endTime = moment(end, "YYYY-MM-DD HH:mm").add(0, 'days');

    //Times
    let allTimes: any = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    this.allDayDefault = []
    this.allDayDefault = allTimes.map((el: any, i: any) => ({
      date: x.date,
      end: allTimes[i + 1],
      start: el,
    })
    )
  }

  generateVideoUrl(videoId: string): void {
    // const url = `https://media.nowcast.cc:5443/LiveApp/play.html?name=${videoId}`;
    const url = `${environment.antMediaServerUrl}/LiveApp/play.html?name=${videoId}`;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  selectChannel(data: any){
    this.videoJsPlayerUrl = ''
    this.videoJsPlayerType = ''
    this.currentSelectedChannelLogo = ''
    this.currentPlayingItemName = ''
    this.currentStartItemTime = ''
    this.currentEndItemTime = ''
    this.itemDescription = ''
    // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
    if(this.m3uType === 'M3U_CHANNEL'){
      if (data.channelUri) {
        let size = data.channelUri?.split(".").length;
        let ext = data.channelUri?.split(".")[size - 1];
        let urlHasExt = false
        if (/(?:\/|^)[^.\/]+$/.test(data.channelUri)) {
            // Successful match
            urlHasExt = true
        } else {
          urlHasExt = false
            // Match attempt failed
        }
        if(!urlHasExt){
          if (ext === "m3u8" || data.channelUri.includes('.m3u8')) {
            this.videoJsPlayerType = 'application/x-mpegURL';
            this.videoJsPlayerUrl = data.channelUri;
          } 
          else {
            this.videoJsPlayerUrl = data.channelUri;
            this.videoJsPlayerType = `video/mp4`;
          }
        }
        else{
          let modifiedURL = this.addLiveAfterDomain(data.channelUri)
          this.videoJsPlayerType = 'application/x-mpegURL';
          this.videoJsPlayerUrl = modifiedURL;
        }
        this.autoPlayChange = true
      }
      this.currentSelectedChannelLogo = data.tvgLogo ? data.tvgLogo : null
      this.currentSelectedChannelName = data.tvgName ? data.tvgName : ''
      // this.currentPlayingItemName = data.tvgName
      // this.itemDescription = data.groupTitle
    }
    else{
      this.videoJsPlayerUrl = data.playlistUrl
      this.videoJsPlayerType = 'application/x-mpegURL'
      this.currentSelectedChannelLogo = data.logoId ? `${appConfig.imageURL}` + data.logoId : null
      this.currentSelectedChannelName = data.title
      this.currentSelectedChannelDesc = data.description
    }
    this.logoOnRight = data.logoOnRight
    this.streamId = data.streamId
    this.generateVideoUrl(this.streamId)
    this.logoOnPlayer = data.logoOnPlayer
    this.playlistType = data.playlistType
    this.videoOnLoop = data.videoOnLoop
    if(this.m3uType === 'M3U_CHANNEL'){
      if(this.channelData.length>0){
        this.channelData.forEach((el: any)=>{
          if(el.Playlist.length>0){
            el.Playlist.forEach((element:any) => {
              if(element.tvgId === data.tvgId && element.groupTitle === data.groupTitle && element.tvgName === data.tvgName){
                element.isSelected = true
                this.selectedChannel = element
                let sendDate = new Date()
                this.getCurrentPlayItemForM3u(sendDate,element)
              }
              else{
                element.isSelected = false
              }
            });
          }
        })
      }
    }
    else{
      if(this.customNetworkData.length>0){
        this.customNetworkData.forEach((el: any)=>{
          if(el.Playlist.length>0){
            el.Playlist.forEach((element:any) => {
              if(element.id === data.id){
                element.isSelected = true
                this.selectedChannel = element
                let sendDate = new Date()
                this.getCurrentPlayItem(sendDate,element)
              }
              else{
                element.isSelected = false
              }
            });
          }
        })
      }
    }
  }


  addLiveAfterDomain(url: any) {
    var domainPattern = /\/\/([^:/]+(:\d+)?)(\/|$)/;
    var domainMatch = url.match(domainPattern);
    if (domainMatch) {
        var domain = domainMatch[1];
        var modifiedURL = url.replace(domain, domain + '/live');
        return modifiedURL + '.m3u8';
    }
    return url;
  }

  getCurrentPlayItem(currentDateTime: any,getData: any){
    let currectDateTime = moment(currentDateTime).format('YYYY-MM-DD HH:mm:ss')
    if(this.checkCurrentTimeInterval){
      clearInterval(this.checkCurrentTimeInterval)
    }
    if(this.customNetworkData.length>0){
      this.customNetworkData.forEach((el: any)=>{
        if(el.Playlist.length>0){
          el.Playlist.forEach((element:any) => {
            if(element.id === getData.id){
              if(element.schedulingChannelInfoDTO){ 
              if(element.schedulingChannelInfoDTO.length>0){
                element.schedulingChannelInfoDTO.forEach((data: any)=>{
                  data.start = data.date + ' ' + data.startTime
                  data.end = data.date + ' ' + data.endTime
                  let time : any
                  if(this.playlistType === 'LOOP' && this.videoOnLoop){
                    let selectedTime =  moment(currentDateTime).format('HH:mm:ss')
                    let selectedDateTime = data.date + ' ' + selectedTime
                    time = moment(selectedDateTime,'YYYY-MM-DD HH:mm:ss')
                  }
                  else{
                    time = moment(currectDateTime,'YYYY-MM-DD HH:mm:ss')
                  }
                  let beforeTime = moment(data.start, 'YYYY-MM-DD HH:mm:ss')
                  let afterTime = moment(data.end, 'YYYY-MM-DD HH:mm:ss');
                  if (time.isBetween(beforeTime, afterTime)) {
                    this.currentStartItemTime = data.start
                    this.currentEndItemTime = data.end
                    if(this.m3uType === 'M3U_CHANNEL'){
                      this.currentPlayingItemName = data.tvgName
                      this.itemDescription = data.groupTitle
                    }
                    else{
                      this.currentPlayingItemName = data.name
                      this.itemDescription = data.itemDescription
                    }
                    this.checkCurrentTimeInterval = setInterval(() => {
                      let sendCurrentDateTime = new Date()
                      let sendDateData = moment(sendCurrentDateTime).format('YYYY-MM-DD HH:mm:ss')
                      let sendStartTime = data.start
                      var getDiff = moment(sendCurrentDateTime).diff(moment(sendStartTime))
                      let currDiff = moment.utc(getDiff).format('HH:mm:ss')
                      
                      // var getDiff = moment.utc(moment(sendDateData, "HH:mm:ss").diff(moment(sendStartTime, "HH:mm:ss"))).format("HH:mm:ss")
                      this.currentItemCurrentTime = currDiff;
        
                      let sendEndTime =  data.end
                      var getRemDiff = moment(sendEndTime).diff(moment(sendDateData))
                      let remainDiff = moment.utc(getRemDiff).format('HH:mm:ss')
                      // var getRemDiff = moment.utc(moment(sendEndTime, "HH:mm:ss").diff(moment(sendDateData, "HH:mm:ss"))).format("HH:mm:ss")
                      this.currentItemRemainingTime = remainDiff;
                      if(this.currentItemRemainingTime == '00:00:00'){
                        if (this.selectedChannel) {
                          if(this.checkCurrentTimeInterval){
                            clearInterval(this.checkCurrentTimeInterval)
                          }
                          this.currentPlayingItemName = ''
                          this.currentStartItemTime = ''
                          this.currentEndItemTime = ''
                          this.currentItemRemainingTime = ''
                          this.currentItemCurrentTime = ''
                          this.itemDescription = ''
                          setTimeout(() => {
                            this.selectChannel(this.selectedChannel)  
                          }, 2000);
                          // this.getMediaItemData(this.mediaItemId, 'channelData');
                          // let sendDate = moment(this.currentDate).format('YYYY-MM-DD')
                          // this.getSchedulingChannelInfoWithDate(sendDate,this.mediaItemId)
                        }
                      }
                    }, 1000);
                  }
                })
              }
            }
            }
          });
        }
      })
    }
    }

    getCurrentPlayItemForM3u(currentDateTime: any,getData: any){
      let currectDateTime = moment(currentDateTime).format('YYYY-MM-DD HH:mm:ss')
      if(this.checkCurrentTimeInterval){
        clearInterval(this.checkCurrentTimeInterval)
      }
      if(this.channelData.length>0){
        this.channelData.forEach((el: any)=>{
          if(el.Playlist.length>0){
            el.Playlist.forEach((element:any) => {
              if(element.tvgId === getData.tvgId && element.groupTitle === getData.groupTitle && element.tvgName === getData.tvgName){
                if(element.playlist){ 
                if(element.playlist.length>0){
                  element.playlist.forEach((data: any)=>{
                    data.start = data.date + ' ' + data.startTime
                    data.end = data.date + ' ' + data.endTime
                    let time : any
                    // if(this.playlistType === 'LOOP' && this.videoOnLoop){
                    //   let selectedTime =  moment(currentDateTime).format('HH:mm:ss')
                    //   let selectedDateTime = data.date + ' ' + selectedTime
                    //   time = moment(selectedDateTime,'YYYY-MM-DD HH:mm:ss')
                    // }
                    // else{
                      time = moment(currectDateTime,'YYYY-MM-DD HH:mm:ss')
                    // }
                    let beforeTime = moment(data.start, 'YYYY-MM-DD HH:mm:ss')
                    let afterTime = moment(data.end, 'YYYY-MM-DD HH:mm:ss');
                    if (time.isBetween(beforeTime, afterTime)) {
                      this.currentPlayingItemName = data.tvgName
                      this.currentStartItemTime = data.start
                      this.currentEndItemTime = data.end
                      this.itemDescription = data.groupTitle
                      this.checkCurrentTimeInterval = setInterval(() => {
                        let sendCurrentDateTime = new Date()
                        let sendDateData = moment(sendCurrentDateTime).format('YYYY-MM-DD HH:mm:ss')
                        let sendStartTime = data.start
                        var getDiff = moment(sendCurrentDateTime).diff(moment(sendStartTime))
                        let currDiff = moment.utc(getDiff).format('HH:mm:ss')
                        
                        // var getDiff = moment.utc(moment(sendDateData, "HH:mm:ss").diff(moment(sendStartTime, "HH:mm:ss"))).format("HH:mm:ss")
                        this.currentItemCurrentTime = currDiff;
          
                        let sendEndTime =  data.end
                        var getRemDiff = moment(sendEndTime).diff(moment(sendDateData))
                        let remainDiff = moment.utc(getRemDiff).format('HH:mm:ss')
                        // var getRemDiff = moment.utc(moment(sendEndTime, "HH:mm:ss").diff(moment(sendDateData, "HH:mm:ss"))).format("HH:mm:ss")
                        this.currentItemRemainingTime = remainDiff;
                        if(this.currentItemRemainingTime == '00:00:00'){
                          if (this.selectedChannel) {
                            if(this.checkCurrentTimeInterval){
                              clearInterval(this.checkCurrentTimeInterval)
                            }
                            this.currentPlayingItemName = ''
                            this.currentStartItemTime = ''
                            this.currentEndItemTime = ''
                            this.currentItemRemainingTime = ''
                            this.currentItemCurrentTime = ''
                            this.itemDescription = ''
                            setTimeout(() => {
                              this.selectChannel(this.selectedChannel)  
                            }, 2000);
                            // this.getMediaItemData(this.mediaItemId, 'channelData');
                            // let sendDate = moment(this.currentDate).format('YYYY-MM-DD')
                            // this.getSchedulingChannelInfoWithDate(sendDate,this.mediaItemId)
                          }
                        }
                      }, 1000);
                    }
                  })
                }
              }
              }
            });
          }
        })
      }
      }

  selectM3UItem(data){
  //   if(this.m3uData){
  //     this.m3uData.forEach(el=>{
  //       if(el.Playlist){
  //         el.Playlist.forEach(element=>{
  //           if(element.channelName == data.channelName){
  //             element.isSelected = true
  //             this.showPlayer = true
  //           }
  //           else{
  //             element.isSelected = false
  //           }
  //         })
  //       }
  //     })
  //   }
  //   this.videoJsPlayerUrl = ''
  //   this.videoJsPlayerType = ''
  //   if (data.channelUri) {
  //     let size = data.channelUri?.split(".").length;
  //     let ext = data.channelUri?.split(".")[size - 1];
  //     if (ext === "m3u8") {
  //       this.videoJsPlayerType = 'application/x-mpegURL';
  //       this.videoJsPlayerUrl = data.channelUri;
  //     } 
  //     else {
  //       this.videoJsPlayerUrl = data.channelUri;
  //       this.videoJsPlayerType = `video/mp4`;
  //     }
  // }
}

updateScroll(){
  const scrollOne = this.scrollOne.nativeElement as HTMLElement;
  const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;
  const scrollthree = this.scrollthree.nativeElement as HTMLElement;
  // do logic and set
  scrollTwo.scrollLeft = scrollOne.scrollLeft;
  scrollthree.scrollLeft = scrollOne.scrollLeft;
}

selectM3uType(type: any){
  this.m3uType = type
  this.m3uData = []
  this.showViewAll=false;
  this.showSpinner=true;
  this.selectedM3uCategory=null;
  this.selectedM3uCategoryNameFromDropdown=true;
  this.viewAllSelected=false;
  this.selectedM3uCategoryName = null
  this.pageForChannelM3u = 1
  if(this.m3uType === 'M3U_CHANNEL'){
    this.getM3uData(this.m3uType,this.pageForChannelM3u,100,false,'')
  }else{
    this.getM3uCategory(this.m3uType)
    this.getM3uData(this.m3uType,1,300,false,'')
  }
}
selectCategory(type){
  this.showCategory = ''
  if(type === 'all'){
    this.showCategory = 'All'
    this.closeNav()
    this.getM3uData(this.m3uType,1,300,false,'')
  }
  else{
    this.showCategory = type.Category
    this.closeNav()
    this.getM3uData(this.m3uType,1,300,false,this.showCategory)
  }
}
getM3uCategory(m3uType){
  this._mediaService.getM3uCategory(this.selectedChannelId,m3uType).subscribe((response)=>{
    this.allCatagoryData = response.data
  })
}

getM3uData(m3uType: any,page: any,size: any,isOnScroll:boolean,categoryName){
  // this.sharedService.updateLoader(true);
  this._mediaService.getM3uDataForTv(this.selectedChannelId,m3uType,this.sendDate,page,size,'',categoryName).subscribe((response: any) => {
    // console.log(response.data)
    this.showm3uContainer = true
    if(this.m3uType === 'M3U_CHANNEL'){
      if(response.data.content.length>0){
        response.data.content.forEach((el: any,i: any)=>{
          if(!el.playlist){
            el.playlist = emptyPlayListData
          }
        }) 
      }
    }
    if(isOnScroll){
      this.m3uData = this.m3uData.concat(response.data.content);
    }
    else{
      if(categoryName === 'All' || categoryName === ''){
        this.m3uData = response.data.content
      }
      else{
        this.m3uData = response.data.Playlist.content
      }
    }
    this.allM3uData = response.data.content
    // this.data = this.m3uData
    if(this.m3uData){
      if(this.m3uData.length>0){
        this.showUI = true
        setTimeout(() => {
          const scrollOne = this.scrollOne.nativeElement as HTMLElement;
          const scrollTo = this.tillCurrectTimeSec ? (((this.tillCurrectTimeSec * 0.13888889 - 200)) + 'px') : '0'
          scrollOne.scrollLeft = parseInt(scrollTo)
        }, 500);
        this.channelData = this.m3uData.map((el)=>({
            Playlist : [el]
        }))
        if(this.channelData.length>0){
          this.channelData.forEach((el: any,i: any)=>{
            if(el.Playlist.length>0){
              el.Playlist.forEach((element:any,index: any) => {
                // element['logoPath'] = element.logoId ? element.logoId : null
                element['isSelected'] = false
                if(element.playlist){
                  if(element.playlist.length>0){
                    element.playlist.forEach((item: any)=>{
                      // item.duration = 1800
                      let date = item.date
                      item.duration = this.getDiffBetweenStartEndTime(item.startTime, item.endTime,date)
                    })
                  }
                }
              });
              setTimeout(() => {
                if(i === 0){
                  this.channelData[0].Playlist[0]['isSelected'] = true
                  if(this.channelData[i].Playlist[i]){
                    this.selectChannel(this.channelData[0].Playlist[0])
                  }
                }
              }, 1000);
            }
          })
        }
      }
    }
    // this.sharedService.updateLoader(false);
    if(this.m3uData.length){
      this.showSpinner=false
      this.showNotFound = false
      this.m3uCategory = this.m3uData.map((el)=>({
        categoryName : el.Category
      }))
    }
    else{
      this.showSpinner=false
      this.showNotFound = true
    }
    this.cdr.detectChanges();
  });
  // this.sharedService.updateLoader(true);
}

onScrollEnd() {
  this.pageForChannelM3u++
  if(this.m3uType === 'M3U_CHANNEL'){
    this.getM3uData(this.m3uType,this.pageForChannelM3u,100,true,'')
  }else{
    this.getM3uData(this.m3uType,'','',false,'')
  }
}

getDiffBetweenStartEndTime(startTime: any,endTime: any,date: any){
  const startDateTime = date + ' ' + startTime;
  const endDateTime = date + ' ' + endTime;
  const startMoment = moment(startDateTime);
  const endMoment = moment(endDateTime);
  var diffMilliseconds : any;
  if (endMoment.isBefore(startMoment)) {
    let newEndTime = date + ' ' + (endTime === '00:00:00' ? '23:59:59' : endTime )
    const sendEndMoment = moment(newEndTime);
    diffMilliseconds = sendEndMoment.diff(startMoment);
  } else {
    diffMilliseconds = endMoment.diff(startMoment);
  }
  // Convert the difference to seconds
  const diffSeconds = diffMilliseconds / 1000;
  return diffSeconds
}

openNav() {
    this.hamOptions = true;
    let sidebar = document.getElementById("mySidenav");
    sidebar ? (sidebar.style.width = "200px") : null;
    sidebar ? (sidebar.style.right = "-150px") : null;
  
  // document.getElementById("mySidenav").style.width = "250px";
}

closeNav() {
    this.hamOptions = false;
    let sidebar = document.getElementById("mySidenav");
    sidebar ? (sidebar.style.width = "0") : null;
    sidebar ? (sidebar.style.right = "0") : null;
    event.stopPropagation();
  // document.getElementById("mySidenav").style.width = "250px";
}

onImageLoad(){
  this.showLoader = true
}

}
