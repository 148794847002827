export class Channel {
    title: string;
    itemType: string;
    mediaSeriesId: number
    constructor(obj?) {
        this.mediaSeriesId = null;
    }
}

export class ChannelData {
    title: string;
    isOneTimePurchase: boolean;
    price: any;
    description: string;
    wideArtworkId: number;
    bannerArtworkId: number;
    squareArtworkId: number;
    wideArtwork: any;
    squareArtwork: any;
    bannerArtwork: any;
    publishedDate: any;
    isPaid: string;
    isVideo: string;
    subscriptionPlanIds: Array<number>;
    scheduleDate: any;
    scheduleTime: any;
    isGivingEnabled: boolean;
    mediaAccessType: any;
    itemType: string;
    isPublished: boolean;
    status: string;
    channelInfoDTO = new channelInfoDTO();
    constructor(obj?) {
        this.price = obj && obj.price ? obj.price : null;
        this.isOneTimePurchase =
            obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false;
        this.isGivingEnabled =
            obj && obj.isGivingEnabled ? obj.isGivingEnabled : false;
        this.description = obj && obj.description ? obj.description : "";
        this.title = obj && obj.title ? obj.title : "";
        this.wideArtworkId = obj && obj.wideArtworkId ? obj.wideArtworkId : null;
        this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.bannerArtworkId : null;
        this.squareArtworkId = obj && obj.squareArtworkId ? obj.squareArtworkId : null;
        this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
        this.isPaid = obj && obj.isPaid ? obj.isPaid : "false";
        this.isVideo = obj && obj.isVideo ? obj.isVideo : "false";
        this.subscriptionPlanIds =
            obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
        this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
        this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
        this.mediaAccessType =
            obj && obj.mediaAccessType ? obj.mediaAccessType : "FREE";
        this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
        this.status = obj && obj.status ? obj.status : "";
        this.itemType = obj && obj.itemType ? obj.itemType : "";
        this.channelInfoDTO =
            obj && obj.channelInfoDTO
                ? obj.channelInfoDTO
                : new channelInfoDTO();
    }
}

export class channelInfoDTO {
    automation: boolean;
    channelNumber: any;
    categoryId: any;
    channelUrl: string;
    logoOnPlayer: boolean;
    logoOnRight: boolean;
    schedulingChannelInfoDTO: any;
    stop: boolean;
    videoOnLoop: boolean;
    airStartTime: string;
    airEndTime: string;
    logoId: any;
    playlistType: string;
    channelType: any;
    m3u8Url: string;
    m3uUrl: string;
    constructor(obj?) {
        this.automation = obj && obj.automation ? obj.automation : false
        this.categoryId = obj && obj.categoryId ? obj.categoryId : ""
        this.channelUrl = obj && obj.channelUrl ? obj.channelUrl : ''
        this.airStartTime = obj && obj.airStartTime ? obj.airStartTime : '00:00:00'
        this.airEndTime = obj && obj.airEndTime ? obj.airEndTime : '23:59:59'
        this.channelNumber = obj && obj.channelNumber ? obj.channelNumber : "";
        this.logoOnPlayer = obj && obj.logoOnPlayer ? obj.logoOnPlayer : false
        this.logoOnRight = obj && obj.logoOnRight ? obj.logoOnRight : true
        this.schedulingChannelInfoDTO = obj && obj.schedulingChannelInfoDTO ? obj.schedulingChannelInfoDTO : [];
        this.stop = obj && obj.stop ? obj.stop : true
        this.videoOnLoop = obj && obj.videoOnLoop ? obj.videoOnLoop : false
        this.logoId = obj && obj.logoId ? obj.logoId : null;
        this.playlistType = obj && obj.playlistType ? obj.playlistType : 'LOOP';
        this.channelType = obj && obj.channelType ? obj.channelType : 'CUSTOM';
        this.m3u8Url = obj && obj.m3u8Url ? obj.m3u8Url : '';
        this.m3uUrl = obj && obj.m3uUrl ? obj.m3uUrl : '';
    }
}

export class NetworkItem {
    title: string;
    // mediaSeriesId: string;
    seriesType: string;
    status: string;
    constructor() {
      // this.mediaSeriesId = null;
    }
  }
  export class EditNetworkSeries {
    title: string;
    subTitle: string;
    squareArtworkId: number;
    wideArtworkId: number;
    bannerArtworkId: number;
    bannerArtwork: any;
    squareArtwork: any;
    wideArtwork: any;
    publishedDate: any;
    seriesType: any;
    isPublished: boolean;
    contents: any;
    status: string;
    scheduleDate: any;
    scheduleTime: any;
    m3uUrl: string;
    networkType: string
    constructor(obj?) {
      this.title = obj && obj.title ? obj.title : "";
      this.subTitle = obj && obj.subtitle ? obj.subtitle : "";
      this.squareArtworkId =
        obj && obj.squareArtwork ? obj.squareArtwork.id : null;
      this.wideArtworkId = obj && obj.wideArtworkId ? obj.bannerArtwork.id : null;
      this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.wideArtwork.id : "";
      this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
      this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
      this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
      this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
      this.isPublished = obj && obj.isPublished ? obj.isPublished : false;
      this.contents = obj && obj.contents ? obj.contents : [];
      this.status = obj && obj.status ? obj.status : "";
      this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
      this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : "";
      this.m3uUrl = obj && obj.m3uUrl ? obj.m3uUrl : '';
      this.networkType = obj && obj.networkType ? obj.networkType : 'CUSTOM';
    }
  }
  