<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">
    <div class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">
            <h4>Add to List</h4>
            <img class="cross-icon-img" (click)="closePanel()" src="./../../../../assets/images/library/close.svg"
                alt="">
        </div>
    </div>

    <div *ngIf="showOptions" class="app-filters d-flex justify-content-center pt-1">
        <button (click)="sendData(selectedItems)" class="btn btn-white mr-10">Apply</button>
        <button (click)="closePanel()" class="btn btn-white">Cancel</button>
    </div>

    <div *ngIf="showOptions" class="table">
        <form>
            <!-- <div class="top-inputs pb-3"> -->
            <div class="p-3">
                <table>
                    <tr>
                        <td>
                            <div>
                                <input type="radio"  (change)="getMediaSeries(1,5)" name="optradio" checked>
                                <label for="" name="optradio">Video Series</label>
                            </div>
                        </td>
                        <!-- <td>
                            <div>
                                <input type="radio" (change)="getListItem(1,5)" name="optradio">
                                <label for="" name="optradio">Folder</label>
                            </div>
                        </td>
                        <td>
                            <div>
                                <input type="radio" name="optradio">
                                <label for="" name="optradio">Event</label>
                            </div>
                        </td> -->
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <input type="radio" (change)="getMediaItems(1,5)" name="optradio">
                                <label for="" name="optradio">Video</label>
                            </div>
                        </td>
                        <!-- <td>
                            <div>
                                <input type="radio" (click)="getAlbumList(1 , 5)" name="optradio">
                                <label for="" name="optradio">Album</label>
                            </div>
                        </td>
                        <td>
                            <div>
                                <input type="radio" name="optradio">
                                <label for="" name="optradio">Song</label>
                            </div>
                        </td> -->
                    </tr>
                    <tr>


                        <td>
                            <div>
                                <input type="radio" (change)="getListItem(1,5)" name="optradio">
                                <label for="" name="optradio">Folder</label>
                            </div>
                        </td>

                        <!-- <td>
                            <div>
                                <input type="radio" name="optradio">
                                <label for="" name="optradio">Calender</label>
                            </div>
                        </td>
                        <td>
                            <div>
                                <input type="radio" (change)="getLinkItems(1,5)" name="optradio">
                                <label for="" name="optradio">Link</label>
                            </div>
                        </td>
                        <td>
                            <div>
                                <input type="radio" (click)="getRssFeedList(1,5)" name="optradio">
                                <label for="" name="optradio">RSS</label>
                            </div>
                        </td> -->
                    </tr>

                </table>
            </div>
        </form>

    </div>


    <div>

        <!-- <div>
            <div class="app-block">
                <p class="info-title">Basic Info</p>
                <form>
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" [(ngModel)]="media.title" name="title" required
                            appWhiteSpaceValidator #title="ngModel">
                        <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                            <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                Title is required</p>
                        </ng-container>
                    </div>  
                    <!-- <div class="form-group">
                    <label>Subtitle</label>
                    <input type="text" class="form-control" [(ngModel)]="editMedia.subTitle" name="subTitle">
                </div> -->
        <!-- </form>
            </div>
            <div class="app-filters d-flex justify-content-center my-4">
                <button type="button" class="btn btn-white mr-10">Add to List</button>
            </div>
        </div>  -->

        <!-- <div class="panel-content"> -->

        <!-- <div class="app-select-bar">
            <div class="selected-count">
                <img src="/assets/images/common/close.svg" alt="" class="pointer">
                <span>{{selectedItems.length}} Item Selected</span>
            </div>
            <!-- <div class="app-filters m-0">
                <button type="button"
                    >Delete</button>
            </div> -->
        <!-- </div> -->

        <!-- <div class="app-filters d-flex">
            <div class="app-search app-search-big search-box mr-10">
                <input type="text" placeholder="Search" class="form-control w-100 ">
            </div>
            <button class="btn btn-white">Create Media Series</button>

        </div> -->

        <div *ngIf="mediaSeries">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 ">
                </div>
                <button (click)="createMedSeries()" class="btn btn-white">Add Video Series</button>

            </div>
        </div>

        <div *ngIf="mediaItems">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 ">
                </div>
                <button (click)="createMedItem()" class="btn btn-white">Add Video</button>


            </div>
        </div>

        <div *ngIf="listItems">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #folderSearch >
                </div>
                <button (click)="createFolItem()" class="btn btn-white">Create Folder Item</button>

            </div>
        </div>

        <!-- <div *ngIf="linkItem">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 ">
                </div>
                <button class="btn btn-white">Create Link Item</button>
            </div>
        </div>

        <div *ngIf="song">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 ">
                </div>
                <button class="btn btn-white">Create Album</button>

            </div>
        </div>

        <div *ngIf="song">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 ">
                </div>
                <button class="btn btn-white">Create Song</button>

            </div>
        </div>

        <div *ngIf="rss">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 ">
                </div>
                <button class="btn btn-white">Create RSS</button>

            </div>
        </div> -->


        <ng-container>


            <div *ngIf="createMedIt">
                <div class="app-block">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em [appSvgIcon]="'back'" (click)="closeMediaItem()"></em>
                    </div>

                    <form #createMediaItemForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="mediaItem.title" name="title" required
                                appWhiteSpaceValidator #title="ngModel">
                            <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container>
                        </div>

                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createMediaItemForm.valid" (click)="createMediaItem(mediaItem)"
                                type="button" class="btn btn-white mr-10">Add
                                to List</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createMediaSer">
                <div class="app-block">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em [appSvgIcon]="'back'" (click)="closeMediaSeries()"></em>
                    </div>

                    <form #createMediaSeriesForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="mediaSeriesTitle"
                                name="mediaSeriesTitle" #mediaSeries="ngModel" appWhiteSpaceValidator required>
                            <ng-container *ngIf="mediaSeries.errors && (mediaSeries.touched || mediaSeries.dirty)">
                                <p class="error"
                                    [hidden]="!mediaSeries.errors.required && !mediaSeries.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container>
                        </div>
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button (click)="createMediaSeries()" [disabled]="!createMediaSeriesForm.valid"
                                type="button" class="btn btn-white mr-10">Add to
                                List</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createListIt">
                <div class="app-block">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em [appSvgIcon]="'back'" (click)="closeFolderItem()"></em>
                    </div>

                    <form #createListItemForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="listItem.title" name="title" required
                                appWhiteSpaceValidator #title="ngModel">
                            <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container>
                        </div>
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createListItemForm.valid" (click)="createListItem(listItem)"
                                type="button" class="btn btn-white mr-10">Add to
                                List</button>
                        </div>
                    </form>
                </div>
            </div>




            <!-- <div class="app-block" *ngIf="mediaSeries"> -->

            <!-- <div class="app-select-bar">
                    <div class="selected-count">
                        <img src="/assets/images/common/close.svg" alt="" class="pointer">
                        <span>{{selectedItems.length}} Item Selected</span>
                    </div>
                    <!-- <div class="app-filters m-0">
                        <button type="button"
                            >Delete</button>
                    </div> -->
            <!-- </div> -->


            <!-- <div class="app-filters d-flex">
                    <div class="app-search app-search-big search-box mr-10">
                        <input type="text" placeholder="Search" class="form-control w-100 ">
                    </div>
                    <button class="btn btn-white">Create Media Series</button>

                </div> -->

            <div class="app-block" *ngIf="mediaSeries">
                <div class="app-block-title">
                    <p class="info-title">Video Series</p>
                </div>



                <ul *ngIf="!showallMediaSeries" class="recent-item-list m-0">
                    <li *ngFor="let series of recentSeriesList">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="series.squareArtwork ? series.squareArtwork.document.path : (series.wideArtwork ? series.wideArtwork.document.path : (series.bannerArtwork ? series.bannerArtwork.document.path : 'assets/images/dashboard/bg.jpg')) "
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{series.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>

                            </div>

                        </div>
                        <div>
                            <input type="checkbox" name="" id="" (change)="selectMediaSeries($event, series)"
                               [disabled]="!series.itemChecked && popUp" [(ngModel)]="series.itemChecked">
                        </div>


                    </li>
                    <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                </ul>
                <ul *ngIf="showallMediaSeries" class="recent-item-list m-0">
                    <li *ngFor="let series of recentSeries">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="series.squareArtwork ? series.squareArtwork.document.path : (series.wideArtwork ? series.wideArtwork.document.path : (series.bannerArtwork ? series.bannerArtwork.document.path : 'assets/images/dashboard/bg.jpg')) "
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{series.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>

                            </div>

                        </div>
                        <div>
                            <!-- disabled="!series.itemChecked && (x+y=z) === 5" -->
                            <input type="checkbox" name="chk" id="" (change)="selectMediaSeries($event, series)"
                            [disabled]="!series.itemChecked && popUp"   [(ngModel)]="series.itemChecked">
                        </div>


                    </li>
                </ul>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showallMediaSeries">
                    <button class="btn btn-white" (click)="showMoreMediaSeries()">Show more</button>
                </div>

            </div>
            <!-- </div> -->


            <div *ngIf="listItems">

                <div class="app-block">
                    <div class="app-block-title">
                        <p class="info-title">Folder Items</p>
                    </div>

                    <ng-container>

                        <ul *ngIf="showallFolder" class="recent-item-list m-0">
                            <li *ngFor="let item of trendingList">
                                <div class="d-flex align-items-center">
                                    <span class="user-img">
                                        <img [src]="item.squareArtwork ? item.squareArtwork.document.path : (item.wideArtwork ? item.wideArtwork.document.path : (item.bannerArtwork ? item.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                            alt="">
                                    </span>
                                    <div class="recent-info">
                                        <p class="pointer">
                                            {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </div>
                                </div>
                                <div>
                                    <input type="checkbox" name="chk"  (change)="selectFolder($event, item)"
                                    [disabled]="!item.itemChecked && popUp"   [(ngModel)]="item.itemChecked" id="">
                                </div>

                            </li>
                        </ul>

                        <ul *ngIf="!showallFolder" class="recent-item-list m-0">
                            <li *ngFor="let item of totalList">
                                <div class="d-flex align-items-center">
                                    <span class="user-img">
                                        <img [src]="item.squareArtwork ? item.squareArtwork.document.path : (item.wideArtwork ? item.wideArtwork.document.path : (item.bannerArtwork ? item.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                            alt="">
                                    </span>
                                    <div class="recent-info">
                                        <p class="pointer">
                                            {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </div>
                                </div>
                                <div>
                                    <input type="checkbox" name="chk" (change)="selectFolder($event, item)"
                                       [disabled]="!item.itemChecked && popUp" [(ngModel)]="item.itemChecked" id="">
                                </div>

                            </li>

                            <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->

                        </ul>

                    </ng-container>

                </div>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showallFolder">
                    <button class="btn btn-white" (click)="showmoreFolder()">Show more</button>
                </div>

            </div>


            <!-- <div *ngIf="linkItem">

                <div class="app-block">
                    <div class="app-block-title">
                        <p class="info-title">Link Items</p>
                    </div>
                    <ul *ngIf="showAllLink" class="recent-item-list m-0">
                        <li *ngFor="let list of linkList">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.path : (list.wideArtwork ? list.wideArtwork.document.path : (list.bannerArtwork ? list.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{list.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
            <!-- </div>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="" (change)="selectLinks($event, list)"
                                    [(ngModel)]="list.itemChecked">
                            </div>

                        </li> -->
            <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
            <!-- </ul>
                    <ul *ngIf="!showAllLink" class="recent-item-list m-0">
                        <li *ngFor="let list of totalLink">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.path : (list.wideArtwork ? list.wideArtwork.document.path : (list.bannerArtwork ? list.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{list.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
            <!-- </div>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="" (change)="selectLinks($event, list)"
                                    [(ngModel)]="list.itemChecked">
                            </div>

                        </li>
                    </ul>
                </div>
                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showAllLink">
                    <button class="btn btn-white" (click)="showMoreLink()">Show more</button>
                </div>

            </div>

            <div *ngIf="album">

                <div class="app-block">
                    <div class="app-block-title">
                        <p class="info-title">Album</p>
                    </div>
                    <ul class="recent-item-list m-0">
                        <li *ngFor="let list of musicList">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.path : (list.wideArtwork ? list.wideArtwork.document.path : (list.bannerArtwork ? list.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{list.title}}</p>
                                    <span>Click here to watch 24/7 Livestream</span>
                                </div>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="" (change)="selectAlbum($event, list)"
                                    [(ngModel)]="list.itemChecked">
                            </div>

                        </li> -->
            <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
            <!-- </ul>
                </div>

            </div>

            <div *ngIf="song">

                <div class="app-block">
                    <div class="app-block-title">
                        <p class="info-title">Song</p>
                    </div>
                    <ul class="recent-item-list m-0">
                        <li *ngFor="let list of linkList">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.path : (list.wideArtwork ? list.wideArtwork.document.path : (list.bannerArtwork ? list.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{list.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
            <!-- </div>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="">
                            </div>

                        </li> -->
            <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
            <!-- </ul>
                </div>

            </div>

            <div *ngIf="rss">

                <div class="app-block">
                    <div class="app-block-title">
                        <p class="info-title">RSS</p>
                    </div>
                    <ul *ngIf="showAllRssItem" class="recent-item-list m-0">
                        <li *ngFor="let list of rssList">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.path : (list.wideArtwork ? list.wideArtwork.document.path : (list.bannerArtwork ? list.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{list.title}}</p> -->
            <!-- <span>Click here to watch 24/7 Livestream</span> -->
            <!-- </div>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="" (change)="selectRss($event, list)"
                                    [(ngModel)]="list.itemChecked">
                            </div>

                        </li> -->
            <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
            <!-- </ul>

                    <ul *ngIf="!showAllRssItem" class="recent-item-list m-0">
                        <li *ngFor="let list of totalRss">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.path : (list.wideArtwork ? list.wideArtwork.document.path : (list.bannerArtwork ? list.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{list.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
            <!-- </div>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="" (change)="selectRss($event, list)"
                                    [(ngModel)]="list.itemChecked">
                            </div>

                        </li>
                    </ul>
                </div>

                <div *ngIf="!showAllRssItem" class="app-filters mt-20 d-flex justify-content-center" >
                    <button class="btn btn-white" (click)="showmoreRssItem()">Show more</button>
                </div> -->

            <!-- </div>  -->





            <div *ngIf="mediaItems">
                <div class="app-block">
                    <div class="app-block-title">
                        <p class="info-title">Videos</p>
                    </div>
                    <ul *ngIf="showAllMediaItem" class="recent-item-list m-0">
                        <li *ngFor="let recent of recentMedia ; let i = index">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.path : (recent.wideArtwork ? recent.wideArtwork.document.path : (recent.bannerArtwork ? recent.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>

                            </div>

                            <div>
                                <input type="checkbox" name="" (change)="selectItems($event, recent)"
                                  [disabled]="!recent.itemChecked && popUp"  [(ngModel)]="recent.itemChecked">
                            </div>
                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <ul *ngIf="!showAllMediaItem" class="recent-item-list m-0">
                        <li *ngFor="let recent of totalMediaItem ; let i = index">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.path : (recent.wideArtwork ? recent.wideArtwork.document.path : (recent.bannerArtwork ? recent.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>

                            </div>

                            <div>
                                <input type="checkbox" name="" ng-model="recent.itemChecked"
                                [disabled]="!recent.itemChecked && popUp"  (change)="selectItems($event, recent)" [(ngModel)]="recent.itemChecked">
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showAllMediaItem" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" (click)="showmoreMediaItem()">Show more</button>
                </div>
            </div>

            <!-- <div *ngIf="showOptions" class="app-filters d-flex justify-content-center py-4">
                <button (click)="sendData(selectedItems)" class="btn btn-white mr-10">Apply</button>
                <button (click)="closePanel()" class="btn btn-white">Cancel</button>
            </div> -->

        </ng-container>
    </div>
</div>

<!-- <p-dialog *ngIf="popUp" header="Delete Media" [(visible)]="deleteMediaModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div>
        Do not select more than 5 items -->
        <!-- Are you sure, you want to delete <ng-container *ngIf="isBulkDelete else deleteItem">selected media items
        </ng-container> -->
<!-- <ng-template #deleteItem>{{selectedMedia.title}}</ng-template> ? This cannot be undone. -->
<!-- </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)="closePopUp()" >Ok</button>
        <!-- <button type="button" class="btn btn-white" (click)="deleteMediaModal = false;">No,
            Cancel</button> -->
<!-- </p-footer>
</p-dialog> -->