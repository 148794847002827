import { Injectable, ErrorHandler } from "@angular/core";
import { MessageService } from "primeng/api";
import { SharedService } from "./services/shared/shared.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
constructor(
    private sharedService: SharedService,
    private messageService: MessageService
    ){}
  handleError(error: any): void {
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
    //   window.location.reload();
        this.sharedService.updateLoader(true);
        this.messageService.add({ severity: 'error', summary: '',sticky: true,life: 5000, detail: 'Something went wrong. Application is reloading. Try agian after reload' });
        setTimeout(() => {
            this.sharedService.updateLoader(true);
            window.location.reload();
        }, 5000);
        this.sharedService.updateLoader(true);
        console.log('<---------------- error come from chunk load --------->');
    }
// other stuff for error handling.
  }
}