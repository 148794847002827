import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";

@Injectable()
export class SubscriptionDetailResolver implements Resolve<any> {
  constructor(public subscrptionService: SubscriptionServiceService) {}
  page: number = 1;
  rows: number = 10;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.subscrptionService
      .getSubscriptionPlanbylist(this.page, this.rows, "")
      .toPromise()
      .then((data) => data.data);
  }
}
