import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResourceService } from '../resource/resource.service';

@Injectable({
    providedIn: 'root'
})

export class HeaderService {

    constructor(public resourceService: ResourceService) { }

    /**
     * To logout user
     */
    logOutUser() {
        const logOutUrl = appConfig.logOut;
        return this.resourceService.getData(logOutUrl);
    }
}
