import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MediaService } from 'src/app/services/media/media.service';
import { LibraryServiceService } from './../../../../services/library/library-service.service'
import { AppAnimation } from './../../../../utilities/animations/animations';
import { MediaItem } from 'src/app/models/media.model'
import { ListItem } from 'src/app/models/library.model'
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-featured-pop-up',
  templateUrl: './featured-pop-up.component.html',
  styleUrls: ['./featured-pop-up.component.css'],
  animations: AppAnimation
})
export class FeaturedPopUpComponent implements OnInit {

  @ViewChild('inputSelect') inputSelect: ElementRef;


  overlay = false;
  sidePanel = false;
  recentSeries: any[] = [];
  recentSeriesList: any[] = [];
  trendingList: any[] = [];
  linkList: any[] = []
  recentMedia: any[] = []
  totalMediaItems = 0;
  totalListItems = 0;
  mediaSeries = true;
  listItems = false;
  mediaItems = false;
  selectAll = false;
  mediaItem: MediaItem;
  listItem: ListItem
  selectedItems: any[] = [];
  selectedMediaSeriesItems: any[] = []
  selectedMediaItems: any[] = []
  selectedFolderItems: any[] = []
  nonSelectedItems: any[] = [];
  folderId: any
  mediaSerId: any
  mediaItId: any
  mediaSeriesTitle: string;
  createMediaSer = false
  createMedIt = false
  createListIt = false
  showOptions = true
  selected = -1
  totalLinkItems = 0
  linkItem = false
  newData: any[] = []
  newMediaItemData: any[] = []
  newFolderItemData: any[] = []
  newLinkItemData: any[] = []
  newRssitemData: any[] = []
  newAlbumItemData: any[] = []
  newData1: any[] = []
  newData2: any[] = []
  newData3: any[] = []
  newData4: any[] = []
  newData5: any[] = []
  newData6: any[] = []
  combined: any[] = []
  combined1: any[] = []
  rss = false;
  song = false;
  album = false;
  rssList: any[] = []
  totalRssFeedItems = 0;
  totalMediaSeries = 0
  albumList: any[] = [];
  musicList: any[] = []
  allMusicList: any[] = [];
  showallFolder = false;
  showAllLink = false
  showallMediaSeries = false
  showAllMediaItem = false
  showAllRssItem = false
  totalList: any[] = []
  totalLink: any[] = []
  totalRss: any[] = []
  totalMediaItem: any[] = []
  arrayTv: any[] = []
  removedDataArray: any[] = []
  popUp = false
  deleteMediaModal = false
  x = 0;
  y = 0;
  z = 0;
  medSeLen = 0
  medItLen = 0
  folItLen = 0
  carouselItemLength: number
  isDisabled = false
  subsciptionData = new Subscription();
  @ViewChild('folderSearch') folderSearch: ElementRef

  constructor(
    public libraryService: LibraryServiceService,
    public mediaService: MediaService,
    public messageService: MessageService

  ) {
    this.mediaItem = new MediaItem();
    // this.mediaItem = new MediaItem();
    this.listItem = new ListItem()
  }

  ngOnInit(): void {
    // this.libraryService.panel5$.subscribe(
    //   (state: any) => {
    //     this.overlay = state.showPanel
    //     this.sidePanel = state.showPanel;
    //   })
    // this.getMediaSeries(1, 5)
    // // this.getLinkItems(1,5)

    // console.log(this.x + this.y + this.z)

    // this.libraryService.sendCarouselLength$.subscribe((response:any)=>{
    //    this.carouselItemLength = response
    // })
    this.getSubscriptionData()
  }

  ngAfterViewInit() {
    if (this.folderSearch) {
      fromEvent(this.folderSearch.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
        this.getListItem(1, 5)
      })).subscribe();
    }
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }

  getSubscriptionData() {
    this.subsciptionData.add(
      this.libraryService.panel5$.subscribe(
        (state: any) => {
          this.overlay = state.showPanel
          this.sidePanel = state.showPanel;
          this.getMediaSeries(1, 5)
        })
    )

    this.subsciptionData.add(

      this.libraryService.sendTvDataToFeatured$.subscribe((response: any) => {
        this.arrayTv = response
        if (this.arrayTv) {
          this.arrayTv.forEach((el) => {
            if (el.type === 'MEDIASERIES') {
              this.medSeLen = this.medSeLen + 1
              this.x = this.medSeLen
            }
            else if (el.type === 'MEDIA_ITEM') {
              this.medItLen = this.medItLen + 1
              this.y = this.medItLen
            }
            else {
              this.folItLen = this.folItLen + 1
              this.z = this.folItLen
            }
          })
        }
        // this.getMediaSeries(1, 5)
      })
    )

    this.subsciptionData.add(

      this.libraryService.sendDeletedTvData$.subscribe((response: any) => {
        this.removedDataArray = response

        console.log(`a`, response, `a`)

        this.removedDataArray.forEach((el) => {

          if (el.type === 'MEDIASERIES') {
            this.selectedMediaSeriesItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false
                let i = this.selectedMediaSeriesItems.indexOf(element)
                this.selectedMediaSeriesItems.splice(i, 1)
                this.x = this.selectedMediaSeriesItems.length
                this.newFunction()
              }
            })
          }
          else if (el.type === 'MEDIA_ITEM') {
            this.selectedMediaItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false
                let i = this.selectedMediaItems.indexOf(element)
                this.selectedMediaItems.splice(i, 1)
                this.y = this.selectedMediaItems.length
                this.newFunction()
              }
            })
          }
          else {
            this.selectedFolderItems.forEach((element) => {
              if (el.id === element.id) {
                element.itemChecked = false
                let i = this.selectedFolderItems.indexOf(element)
                this.selectedFolderItems.splice(i, 1)
                this.z = this.selectedFolderItems.length
                this.newFunction()
              }
            })
          }

        })
      })
    )
    // this.getMediaSeries(1, 5)
    // this.getLinkItems(1,5)

    console.log(this.x + this.y + this.z)

    this.subsciptionData.add(
      this.libraryService.sendCarouselLength$.subscribe((response: any) => {
        this.carouselItemLength = response
      })

    )

  }



  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.libraryService.updatePanel({ showPanel: false })
  }
  showmoreFolder() {
    this.showallFolder = true
    this.getListItem(1, this.totalListItems)
  }

  showMoreLink() {
    this.showAllLink = true;
    this.getLinkItems(1, this.totalLinkItems)
  }

  showMoreMediaSeries() {
    this.showallMediaSeries = true
    this.getMediaSeries(1, this.totalMediaSeries)
  }

  showmoreMediaItem() {
    this.showAllMediaItem = true
    this.getMediaItems(1, this.totalMediaItems)
  }

  showmoreRssItem() {
    this.showAllRssItem = true
    this.getRssFeedList(1, this.totalRssFeedItems)
  }

  getMediaSeries(page, size) {
    this.mediaSeries = true;
    this.mediaItems = false;
    this.listItems = false;
    this.linkItem = false
    var search = ''
    this.mediaService.getMediaSeries(page, size,search,'MEDIA_SERIES').subscribe((response: any) => {
      this.recentSeries = response.data.content;

      if (this.arrayTv) {
        let count = 0
        this.arrayTv.forEach((data) => {
          this.recentSeries.forEach(el => {
            if (data.id === el.id) {
              el['itemChecked'] = true
              count = count + 1
              this.x = count
            }
          });
        })
      }

      this.selectedMediaSeriesItems.forEach((data => {
        this.recentSeries.forEach((el) => {
          if (data.id === el.id) {
            el['itemChecked'] = true
          }
        })
      }))



      this.totalMediaSeries = response.data.totalElements
      this.recentSeriesList = this.recentSeries.slice(0, 4);
    });
  }

  getListItem(page, size) {
    this.rss = false
    this.song = false
    this.album = false
    this.mediaItems = false
    this.mediaSeries = false
    this.linkItem = false
    this.listItems = true
    this.libraryService.getListItems(page, size, this.folderSearch ? this.folderSearch.nativeElement.value : '').subscribe((response: any) => {

      this.trendingList = response.data.content ? response.data.content : []
      console.log(this.trendingList)

      if (this.arrayTv) {
        let count = 0
        this.arrayTv.forEach((data) => {
          this.trendingList.forEach(el => {
            if (data.id === el.id) {
              el['itemChecked'] = true
              count = count + 1
              this.z = count
            }
          });
        })
      }

      this.selectedFolderItems.forEach((data => {
        this.trendingList.forEach((el) => {
          if (data.id === el.id) {
            el['itemChecked'] = true
          }
        })
      }))


      this.totalListItems = response.data.totalElements;
      this.totalList = this.trendingList.slice(0, 4)
      console.log(this.trendingList)
      console.log(this.totalListItems)
    });
  }

  getLinkItems(page, size) {
    this.rss = false
    this.song = false
    this.album = false
    this.mediaItems = false
    this.mediaSeries = false
    this.listItems = false
    this.linkItem = true
    var search = ''
    this.libraryService.getLInkItem(page, size,search).subscribe((response: any) => {
      this.linkList = response.data.content
      this.totalLinkItems = response.data.totalElements
      this.totalLink = this.linkList.slice(0, 4)
    })
  }


  getMediaItems(page, size) {
    this.rss = false
    this.song = false
    this.album = false
    this.mediaItems = true;
    this.mediaSeries = false
    this.listItems = false
    this.linkItem = false
    var search = ''
    this.mediaService.getMediaItems(page, size,search,'MEDIA_ITEM','').subscribe((response: any) => {
      this.recentMedia = response.data.content;

      if (this.arrayTv) {
        let count = 0
        this.arrayTv.forEach((data) => {
          this.recentMedia.forEach(el => {
            if (data.id === el.id) {
              el['itemChecked'] = true
              count = count + 1
              this.y = count
            }
          });
        })
      }

      this.selectedMediaItems.forEach((data => {
        this.recentMedia.forEach((el) => {
          if (data.id === el.id) {
            el['itemChecked'] = true
          }
        })
      }))


      this.totalMediaItems = response.data.totalElements;
      this.totalMediaItem = this.recentMedia.slice(0, 4)
    });
  }

  getRssFeedList(page, size) {
    this.rss = true
    this.song = false
    this.album = false
    this.mediaItems = false;
    this.mediaSeries = false
    this.listItems = false
    this.linkItem = false
    var search = ''
    this.libraryService.getRssFeed(page, size,search).subscribe((response: any) => {
      console.log(response)
      this.rssList = response.data.content
      this.totalRssFeedItems = response.data.totalElements
      this.totalRss = this.rssList.slice(0, 4)
      console.log(this.totalRssFeedItems)
    })
  }

  getAlbumList(page, size) {
    this.rss = false
    this.song = false
    this.album = true
    this.mediaItems = false;
    this.mediaSeries = false
    this.listItems = false
    this.linkItem = false
    var search = ''
    this.mediaService.getAlbumList(page, size,search).subscribe((response: any) => {
      this.musicList = response.data.content;
      this.allMusicList = this.musicList.slice(0, 4);
      // this.recentMusic = (search.trim() === '') ? response.data.mediaItemsDTOPage.content.slice(0, 5) : response.data.mediaItemsDTOPage.content;
      // this.recentSeriesList = (search.trim() === '') ? response.data.mediaSeriesDTOPage.content.slice(0, 4) : response.data.mediaSeriesDTOPage.content;
    });
  }


  createMediaItem(mediaItem) {
    mediaItem.itemType = 'MEDIA_ITEM'
    this.mediaService.createMediaItem(mediaItem).subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Media Item created Successfully.' });
      this.getMediaItems(1, 5);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    this.mediaItems = true;
    this.createMedIt = false;
    this.showOptions = true
  }


  createMediaSeries() {
    this.mediaService.createMediaSeries({ title: this.mediaSeriesTitle, seriesType: 'MEDIA_SERIES' }).subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Media Series created Successfully.' });
      this.getMediaSeries(1, 5)
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    this.mediaSeries = true;
    this.createMediaSer = false;
    this.showOptions = true
  }


  createListItem(listItem) {
    this.libraryService.createListItem(listItem).subscribe((response: any) => {
      console.log(response)
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Folder Item created Successfully' })
      this.getListItem(1, 5)
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    this.listItems = true
    this.createListIt = false
    this.showOptions = true
  }


  // selectMediaSeries(event, item,name) {
  //   if (item === 'all') {
  //     this.selectAll = event.target.checked;
  //     this.recentSeriesList.forEach((el) => el.itemChecked = event.target.checked);
  //     this.selectedItems = event.target.checked ? this.recentSeriesList : [];
  //   } else {
  //     this.recentSeriesList.forEach((el) => {
  //       if (el.id === item.id) {
  //         el.itemChecked = event.target.checked;
  //       }
  //     });
  //     this.selectedItems = this.recentSeriesList.filter((el) => el.itemChecked);
  //     this.selectAll = (this.selectedItems.length === this.recentSeriesList.length);
  //     let newdata = [] 
  //     // newdata =  this.selectedItems.map((data) => {
  //     //   itemName: name;
  //     //   itemData: data
  //     // })
  //     newdata = this.selectedItems.map((el) => ({
  //       id: el.id,
  //       name: name,
  //     }));
  //     console.log(newdata)
  //     console.log(this.selectedItems)
  //   }
  // }

  createMedSeries() {
    this.mediaSeries = false;
    this.createMediaSer = true
    this.showOptions = false
  }

  closeMediaSeries() {
    this.mediaSeries = true;
    this.createMediaSer = false
    this.showOptions = true
  }

  createMedItem() {
    this.mediaItems = false
    this.createMedIt = true
    this.showOptions = false
  }

  closeMediaItem() {
    this.mediaItems = true
    this.createMedIt = false
    this.showOptions = true
  }

  createFolItem() {
    this.listItems = false
    this.createListIt = true
    this.showOptions = false
  }

  closeFolderItem() {
    this.listItems = true
    this.createListIt = false
    this.showOptions = true
  }

  selectMediaSeries(event, item) {
    if (item === 'all') {
      this.selectAll = event.target.checked;
      this.recentSeriesList.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedMediaSeriesItems = event.target.checked ? this.recentSeriesList : [];
    } else {
      this.recentSeriesList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedMediaSeriesItems = this.recentSeriesList.filter((el) => el.itemChecked);
      this.recentSeriesList.forEach((el) => !el.itemChecked)
      this.selectAll = (this.selectedMediaSeriesItems.length === this.recentSeriesList.length);
      console.log(this.selectedItems)
      this.newData1 = this.selectedMediaSeriesItems
      // let newData = []

      this.newData = this.selectedMediaSeriesItems.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: "MEDIASERIES",

      }))
    }
    this.x = this.selectedMediaSeriesItems.length
    console.log(this.x + this.y + this.z)

    if (((this.x + this.y + this.z) - this.carouselItemLength) === (5 - this.carouselItemLength)) {
      this.popUp = true
      this.deleteMediaModal = true
      // this.isDisabled = true
    }
    else {
      this.popUp = false
    }

  }


  selectItems(event, item) {
    if (item === 'all') {
      this.selectAll = event.target.checked;
      this.recentMedia.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedMediaItems = event.target.checked ? this.recentMedia : [];
    } else {
      this.recentMedia.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedMediaItems = this.recentMedia.filter((el) => el.itemChecked);
      this.nonSelectedItems = this.trendingList.filter((el) => !el.itemChecked)
      this.selectAll = (this.selectedMediaItems.length === this.recentMedia.length);
      this.newData2 = this.selectedMediaItems
      this.newMediaItemData = this.selectedMediaItems.map((el) => ({
        id: el.id,
        sortOrder: 2,
        type: 'MEDIA_ITEM',
      }))



    }
    console.log(`oodles`, this.inputSelect)
    // console.log(this.selectedItems.length)
    this.y = this.selectedMediaItems.length
    console.log(this.x + this.y + this.z)


    if (((this.x + this.y + this.z) - this.carouselItemLength) === (5 - this.carouselItemLength)) {
      this.popUp = true
      this.deleteMediaModal = true
      // this.isDisabled = true
    }
    else {
      this.popUp = false
    }


    // if((this.x + this.y + this.z) === 5){
    // this.inputSelect.nativeElement.style.pointerEvents = 'none'
    //   this.popUp = true
    //   this.deleteMediaModal = true
    //   // this.isDisabled = true
    // }
  }

  selectLinks(event, list) {
    if (list === 'all') {
      this.selectAll = event.target.checked;
      this.linkList.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedItems = event.target.checked ? this.linkList : [];
      console.log(this.selectItems)
    } else {
      this.linkList.forEach((el) => {
        if (el.id === list.id) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedItems = this.linkList.filter((el) => el.itemChecked);
      console.log(this.selectedItems)
      this.newData3 = this.selectedItems
      this.selectAll = (this.selectedItems.length === this.linkList.length);
      this.newLinkItemData = this.selectedItems.map((el) => ({
        id: el.id,
        sortOrder: 3,
        type: 'LINK',
      })

      )
    }

  }

  selectFolder(event, item) {
    if (item === 'all') {
      this.selectAll = event.target.checked;
      this.trendingList.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedFolderItems = event.target.checked ? this.trendingList : [];
    } else {
      this.trendingList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedFolderItems = this.trendingList.filter((el) => el.itemChecked);
      this.nonSelectedItems = this.trendingList.filter((el) => !el.itemChecked)
      console.log(this.selectedItems)
      this.newData4 = this.selectedFolderItems
      this.selectAll = (this.selectedFolderItems.length === this.trendingList.length);
      this.newFolderItemData = this.selectedFolderItems.map((el) => ({
        id: el.id,
        sortOrder: 4,
        type: 'LIST',
      }))

      if (((this.x + this.y + this.z) - this.carouselItemLength) === (5 - this.carouselItemLength)) {
        this.popUp = true
        this.deleteMediaModal = true
        // this.isDisabled = true
      }
      else {
        this.popUp = false
      }

    }
    this.z = this.selectedFolderItems.length
  }

  selectRss(event, item) {
    if (item === 'all') {
      this.selectAll = event.target.checked;
      this.rssList.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedItems = event.target.checked ? this.rssList : [];
      console.log(this.selectItems)
    } else {
      this.trendingList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedItems = this.rssList.filter((el) => el.itemChecked);
      console.log(this.selectedItems)
      this.newData5 = this.selectedItems
      this.selectAll = (this.selectedItems.length === this.rssList.length);
      this.newRssitemData = this.selectedItems.map((el) => ({
        id: el.id,
        sortOrder: 5,
        type: 'RSSFEED'
      }))
    }

  }

  selectAlbum(event, item) {
    if (item === 'all') {
      this.selectAll = event.target.checked;
      this.musicList.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedItems = event.target.checked ? this.musicList : [];
      console.log(this.selectItems)
    } else {
      this.trendingList.forEach((el) => {
        if (el.id === item.id) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedItems = this.musicList.filter((el) => el.itemChecked);
      console.log(this.selectedItems)
      this.newData6 = this.selectedItems
      this.selectAll = (this.selectedItems.length === this.musicList.length);
      this.newAlbumItemData = this.selectedItems.map((el) => ({
        id: el.id,
        sortOrder: 6,
        type: 'ALBUM'
      }))
    }

  }




  folder(item) {
    this.folderId = item.id
    this.sendFolderId(this.folderId)
  }

  mediaIt(newMediaItemData) {
    // this.mediaItId = recent.id
    this.sendMediaItemId(this.newMediaItemData)

    // this.sendMediaSeriesId(this.newData)
  }

  sendData(newData) {
    // if (this.combined.length <= 5) {
    this.combined = [...this.newData, ...this.newMediaItemData, ...this.newLinkItemData, ...this.newFolderItemData, ...this.newRssitemData, ...this.newAlbumItemData]
    this.combined.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }))
    console.log(this.combined)

    this.sendMediaSeriesId(this.combined)
    this.sendFolderId(this.combined1)
    this.libraryService.updatePanel5({ showPanel: false })

    console.log(this.combined1)
    console.log(this.combined)
    // }else{
    //   this.popUp = true
    //   this.deleteMediaModal = true
    // }
  }

  sendFolderId(folderId) {
    this.combined1 = [...this.newData1, ...this.newData2, ...this.newData3, ...this.newData4, ...this.newData5, ...this.newData6]
    this.libraryService.sendDataItem2(this.combined1)
    console.log('a')
  }

  // sendMediaSeriesId(mediaSerId){
  //   this.libraryService.sendMediaSeries(this.mediaSerId)
  // }

  sendMediaSeriesId(newData) {
    this.libraryService.sendDataItem1(newData)
    console.log('b')
  }

  sendMediaItemId(newMediaItemData) {
    this.libraryService.sendMediaItem(newMediaItemData)
  }

  closePopUp() {
    this.popUp = false
    this.deleteMediaModal = false
  }

  fun() {
    var a = document.getElementsByName('chk')
    var newVar = 0;
    var count;
    // for(count=0;count<a.length;count++){
    //   if(a[count].checked==true){


    //   }
    // }
  }

  newFunction() {
    if (((this.x + this.y + this.z) - this.carouselItemLength) === (5 - this.carouselItemLength)) {
      this.popUp = true
      this.deleteMediaModal = true
      // this.isDisabled = true
    }
    else {
      this.popUp = false
    }

  }

}


