import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';

@Injectable({
  providedIn: 'root'
})

export class SecurityService {

  constructor(public resourceService: ResourceService) { }

  getSecurityGroup(page,size,search) {
    const filter = search ? `&searchkey=${search}`:'';
    const getSecurityGroupUrl = `${appConfig.getSecurityGroup}?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getSecurityGroupUrl);
  }

  updateGroup(groupData, groupId) {
    const updateGroupUrl = groupId ? `${appConfig.updateSecurityGroup}/${groupId}` : appConfig.updateSecurityGroup;
    if (groupId) {
      return this.resourceService.putData(updateGroupUrl, groupData);
    } else {
      return this.resourceService.postData(updateGroupUrl, groupData);
    }
  }

  getPermission() {
    const getPermissionUrl = appConfig.updatePermission;
    return this.resourceService.getData(getPermissionUrl);
  }

  getGroupData(groupId) {
    const getGroupUrl = `${appConfig.updateSecurityGroup}/${groupId}`;
    return this.resourceService.getData(getGroupUrl);
  }

  deleteGroup(groupId) {
    const deleteGroupUrl = `${appConfig.updateSecurityGroup}/${groupId}`;
    return this.resourceService.deleteData(deleteGroupUrl);
  }

}

