import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticateService } from './../../../services/authenticateService/authenticate.service';
import { SignUpModel } from './../../../models/authenticate.model';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  submitted = false
  signUp: SignUpModel;
  stateList: any[] = [];
  cityList: any[] = [];
  cityDropdown = false;
  signedUp = false;
  uuid: string;
  signUpLogo: string;
  brandColor: any
  privacyPolicy: any;
  termsOfUse: any;
  isWhiteLabel: boolean;
  constructor(
    public authenticateService: AuthenticateService,
    public messageService: MessageService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.signUp = new SignUpModel();
  }

  ngOnInit(): void {
    // this.uuid = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.uuid : '';
    this.route.queryParams.subscribe((params) => {
      this.signedUp = (params.signup);
      this.uuid = this.route.snapshot.paramMap.get('uuid');
    });
    this.getLogo();
    this.getCountryData();
  }

  getLogo() {
    const orgUuid = this.route.snapshot.paramMap.get('uuid');
    this.authenticateService.getLogo(orgUuid).subscribe((response: any) => {
      this.signUpLogo = response.data ? response.data.documentResponseDTO.path : '';
      this.brandColor = response.data ? response.data.brandColor : '';
      this.privacyPolicy = response.data ? response.data.privacyPolicy : '';
      this.termsOfUse = response.data ? response.data.termsOfUse : '';
      this.isWhiteLabel = response.data ? response.data.isWhiteLabel : false
    });
  }

  /**
   * To signup user
   */
  signUpUser(form) {

    if (form.submitted) {
      this.submitted = true
    }

    if (form.form.valid) {
      if (((this.signUp.basicInfo.mobileNumber && this.signUp.basicInfo.mobileNumber.toString().trim() !== '') && (this.signUp.basicInfo.mobileNumber.toString().length < 5 || this.signUp.basicInfo.mobileNumber.toString().length > 15))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Phone number should be between 5 to 15 digits.' });
        return
      }
      this.signUp.sourceType = "SIGNUP_URL"
      this.authenticateService.signUp(this.signUp, this.uuid).subscribe(() => {
        this.router.navigate([`/signup/${this.uuid}`], { queryParams: { signup: true } });
        this.signedUp = true;

        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'You have signed up successfully ' });
        // this.router.navigate(['/contacts-module/contacts']);
      }
        , (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        })
    }

    // });
  }

  /**
   * To get country data
   */
  getCountryData() {
    this.authenticateService.getCountryList().subscribe((response: any) => {
      this.stateList = response.data.countryList[0] ? response.data.countryList[0].states : [];
    });
  }

  /**
   * To update city list
   * @param event State value
   */
  updateCityList(event) {
    const selStateObj = this.stateList.filter((el) => el.code === event.target.value);
    if (selStateObj.length && selStateObj[0].cities.length) {
      this.cityDropdown = true;
      this.cityList = selStateObj[0].cities;
      this.signUp.mailingAddress.city = this.cityList[0].cityName;
    } else {
      this.cityDropdown = false;
      this.signUp.mailingAddress.city = '';
    }
  }

}
