import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { ResourceService } from '../resource/resource.service';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public webSocket: WebSocket;;
  public subject: Subject<any>;
  messageArray = new Subject<any>();
  constructor(public resourceService: ResourceService) {
    this.subject = new Subject<any>();
  }

  connect(id: any): Observable<any> {
    const socket = new WebSocket(`${appConfig.chatSupportWebSocket}/?token=${id}`);
    this.webSocket = socket;
    this.webSocket.OPEN;
    let message: any;
    socket.onmessage = (event) => {
      message = event.data;
      if (typeof message === 'string') {
        let array = [];
        array.push(message)
        this.messageArray.next(array)
      }
    }
    return this.subject.asObservable();
  }

  sendMessage(message): void {
    let msg = JSON.stringify(message)
    this.webSocket.send(msg);
  }
  getChatWindowHistory(token: any, user_id: any, page?: any, limit?: any, page_param?: any) {
    const url = `${appConfig.chatSupportServiceUrl}/chat_window_history/?user_id=${user_id}&page_param=${page_param}`
    return this.resourceService.getData(url);

  }

  uploadTraningFile(data, token: any) {
    const fileUploadUrl = `${appConfig.chatSupportServiceUrl}/file_upload/`
    return this.resourceService.postData(fileUploadUrl, data);
  }
}





