import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms"; // Import these if you want to use a form
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";
import { SharedService } from "src/app/services/shared/shared.service";
import { MessageService } from "primeng/api";
import { CreateCustomSubscription } from "src/app/models/subscription-module.model";
import { environment } from "./../../../../environments/environment";
import { appConfig } from "src/app/app.config";

@Component({
  selector: "app-create-custom-plan",
  templateUrl: "./create-custom-plan.component.html",
  styleUrls: ["./create-custom-plan.component.css"],
})
export class CreateCustomPlanComponent implements OnInit {
  updateSubscriptionForm: FormGroup;
  isDetail = false;
  name: any;
  SubscriptionPlanName = "";
  trialDays: any;
  subscriptionPlanDuration = "";
  ListPlanDetails: Array<any>;
  createSubscription: CreateCustomSubscription;
  planAmount: number;
  duration: any;
  submitted = false;
  price : any;
  featureList: number[] = [];
  detailsList: any[] = [];
  PlanName: string;
  showUI = false;
  subscriptionPlanId: any;
  signUpFormUrl: any;
  tvAppRows: any[] = [];
  isTVAppToggleOn: boolean = false;
  type = "CUSTOMPLAN";
  expandedFeatures: any[] = [];
  recommendedPrice = 0;
  selectedStorage: any;
  selectedStorageOptions: any;
  oldSelectedStorageOption: any;
  roundedTotalDiskSpace: any;
  roundValue: any;
  selectedStorageInBytes: any;
  atLeastOneSubFeatureIsViewed: boolean;
  showInfoModal = false;
  switchToggleFor: any;
  selectedOptionFromModal: any;
  permissionList: any[] = [];
  isAnyAssociatedOrganization: any;
  quantityWithPrice: any[] = [];
  storageOptions: any[] = [];
  vodOptions: any[] = [];
  selectedStorageOption: string = "";
  selectedVODOption: string = "";

  convertedQuantity: any;
  convertedVODQuantity: any;
  selectedStoragePrice: any;
  selectedVODPrice: any;
  dataUsagePricingInfoId: number;
  dataUsagePricingVODInfoId: number;
  byDefaultSelectedPrice: any;
  byDefaultSelectedPriceVOD: any;
  storageQuantity: any;
  @ViewChild("SelectStorageDropdown") SelectStorageDropdown: ElementRef;
  selectedLiveDataUsage :any;
  liveStreamDataUsageOptions: any;
  viewerCountList = []

  selectedViewersCount = 1
  convertedLiveStreamQuantity: any ;
  selectedLiveStreamPrice: number;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public subscrptionService: SubscriptionServiceService,
    public sharedService: SharedService,
    public route: ActivatedRoute,
    public messageService: MessageService
  ) {
    this.createSubscription = new CreateCustomSubscription();
    this.updateSubscriptionForm = this.fb.group({
      name: ["", [Validators.required]],
      trialDays: ["", [Validators.required, Validators.min(0)]],
    });
  }

  ngOnInit() {
    this.name = "";
    this.trialDays = 0;
    this.subscriptionPlanDuration = "";
    this.duration = "";
    this.subscriptionPlanId = +this.route.snapshot.paramMap.get("id");
    this.name = this.route.snapshot.paramMap.get("name");

    if (this.subscriptionPlanId) {
      this.getSubscriptionPlanbyid(this.subscriptionPlanId);
    } else {
      this.getSubscriptionPlanList();
    }
    // Set initial values for storage and VOD options if creating a new plan
    // if (!this.subscriptionPlanId) {
    //   // Assuming storageOptions and vodOptions are populated properly
    //   if (this.storageOptions.length > 0) {
    //     this.dataUsagePricingInfoId =
    //       this.storageOptions[0].dataUsagePricingInfoId;
    //   }
    //   if (this.vodOptions.length > 0) {
    //     this.dataUsagePricingVODInfoId =
    //       this.vodOptions[0].dataUsagePricingInfoId;
    //   }
    // }
  }

  getSubscriptionPlanList() {
    this.subscrptionService
      .getAllSubscriptionPlanList()
      .subscribe((response: any) => {
        this.ListPlanDetails = response.data;
        this.detailsList = response.data;

        const liveStream = this.ListPlanDetails.find(
          (feature) => feature.name === "Live Streaming"
        );
        this.viewerCountList = liveStream.subFeature.find(
          (feature) => feature.name === "Live Stream Viewers Count"
        ).viewersCountList.map((e,i) => 
          {
            if(e != 'Unlimited'){
              return { id : Number(e), name: e}
            }
            else{
             return { id : -1, name: e}
            }
          }
        );

        this.selectedViewersCount = liveStream.subFeature.find((feature) => feature.name === "Live Stream Viewers Count").quantity;
        
        const storageQuantityInBytes = response.data[24].quantity;
        this.selectedStorageOption = this.mapBytesToStorageOption(
          storageQuantityInBytes
        );
        console.log("check storaghsds", this.selectedStorageOption);
        this.oldSelectedStorageOption = this.selectedStorageOption;
        this.recommendedPrice = 0;
        console.log("test selectedStorga", this.selectedStorageOption);
        this.selectedStoragePrice = response.data[24].defaultPrice;
        this.selectedVODPrice = response.data[25].defaultPrice;
        const storageFeature = this.ListPlanDetails.find(
          (feature) => feature.name === "Storage"
        );

        const selectedOptions = storageFeature.quantityWithPrice.find(
          (option) => option.id === storageFeature.quantityWithPrice[0].id
        );

        console.log("test", this.dataUsagePricingInfoId);

        if (selectedOptions) {
          const { quantity, unit } = selectedOptions;
          console.log("Quantity:", quantity);
          console.log("Unit:", unit);
          this.convertedQuantity = quantity;
          if (unit === "GB") {
            this.convertedQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
          } else if (unit === "TB") {
            this.convertedQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
          }
          // Now you have the quantity and unit of the selected option
        }

        if (storageFeature && storageFeature.quantityWithPrice) {
          console.log("test", this.dataUsagePricingInfoId);
          this.storageOptions = storageFeature.quantityWithPrice.map(
            (option) => {
              // this.convertedQuantity = option.quantity;
              // if (option.unit === "GB") {
              //   this.convertedQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
              // } else if (option.unit === "TB") {
              //   this.convertedQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
              // }
              return {
                name: `${option.name}`,
                storageQuantity: `${option.quantity}`,
                unit: `${option.unit}`,
                value: option.setPrice,
                convertedQuantity: this.convertedQuantity,
                dataUsagePricingInfoId: option.id,
              };
            }
          );

          this.dataUsagePricingInfoId =
            parseFloat(this.storageOptions[0].dataUsagePricingInfoId);
          this.byDefaultSelectedPrice = parseFloat(this.storageOptions[0].value);
          storageFeature.defaultPrice = parseFloat(this.byDefaultSelectedPrice);
        }

        // else {
        //   this.storageOptions = [];
        // }
        const vodFeature = this.ListPlanDetails.find(
          (feature) => feature.name === "VOD Data Usage"
        );

        const selectedVODOptions = vodFeature.quantityWithPrice.find(
          (option) => option.id === vodFeature.quantityWithPrice[0].id
        );

        if (selectedVODOptions) {
          const { quantity, unit } = selectedVODOptions;
          console.log("Quantity:", quantity);
          console.log("Unit:", unit);
          this.convertedVODQuantity = quantity;
          if (unit === "GB") {
            this.convertedVODQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
          } else if (unit === "TB") {
            this.convertedVODQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
          }
          // Now you have the quantity and unit of the selected option
        }
        if (vodFeature && vodFeature.quantityWithPrice) {
          this.vodOptions = vodFeature.quantityWithPrice.map((vodOption) => {
            // this.convertedVODQuantity = vodOption.quantity;
            // if (vodOption.unit === "GB") {
            //   this.convertedVODQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
            // } else if (vodOption.unit === "TB") {
            //   this.convertedVODQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
            // }
            return {
              name: `${vodOption.name}`,
              storageQuantity: `${vodOption.quantity}`,
              unit: `${vodOption.unit}`,
              value: vodOption.setPrice,
              convertedQuantity: this.convertedVODQuantity,
              dataUsagePricingInfoId: vodOption.id,
            };
          });
          this.dataUsagePricingVODInfoId =
            parseFloat(this.vodOptions[0].dataUsagePricingInfoId);
          this.byDefaultSelectedPriceVOD = parseFloat(this.vodOptions[0].value);
          vodFeature.defaultPrice = parseFloat(this.byDefaultSelectedPriceVOD);
        }
        //  else {
        //   this.vodOptions = [];
        // }

        const liveStreamFeature = this.ListPlanDetails.find(
          (feature) => feature.name === "Live Streaming"
        );

        const liveStreamDataUsageList = liveStreamFeature.subFeature.find(
          (subFeature) => subFeature.name === "Live Stream Data Usage"
        );
        
        if (liveStreamDataUsageList && liveStreamDataUsageList.quantityWithPrice) {
          const { id } = liveStreamDataUsageList.quantityWithPrice.find(
            (option) => option.id === liveStreamDataUsageList.dataUsagePricingInfoId
          );
          this.selectedLiveDataUsage = id;
          const selectedLiveOptions = liveStreamDataUsageList.quantityWithPrice.find(
            (option) => option.id === liveStreamDataUsageList.dataUsagePricingInfoId
          );
          if (selectedLiveOptions) {
            const { quantity, unit } = selectedLiveOptions;
            this.convertedLiveStreamQuantity = quantity;
            if (unit === "GB") {
              this.convertedLiveStreamQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
            } else if (unit === "TB") {
              this.convertedLiveStreamQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
            }
          }

          this.liveStreamDataUsageOptions = liveStreamDataUsageList.quantityWithPrice.map((options) => {
            return {
              name: `${options.name}`,
              storageQuantity: `${options.quantity}`,
              unit: `${options.unit}`,
              value: options.setPrice,
              convertedQuantity: this.convertedVODQuantity,
              dataUsagePricingInfoId: options.id,
            };
          });
  
          this.selectedLiveDataUsage =  parseFloat(this.liveStreamDataUsageOptions[0].dataUsagePricingInfoId)
        }
        else {
        this.liveStreamDataUsageOptions = []; // Set empty array if no storage options found
        }

        for (const template of this.ListPlanDetails) {
          if (template.isView) {
            this.featureList.push(template.id);
            this.recommendedPrice += template.defaultPrice;
            if (template.subFeature && template.subFeature.length > 0) {
              for (const subFeature of template.subFeature) {
                if (subFeature.isView) {
                  this.recommendedPrice += subFeature.defaultPrice;
                }
              }
            }
            const recommendedprice = this.recommendedPrice;
            this.price = recommendedprice.toFixed(3)
          }
        }
      });
  }

  getSubscriptionPlanbyid(subscriptionPlanId) {
    this.subscrptionService
      .getSubscriptionPlanbyid(subscriptionPlanId)
      .subscribe((response: any) => {
        this.permissionList = [];
        localStorage.setItem(
          "permissionList",
          JSON.stringify(response.data.permission)
        );
        this.detailsList = response.data.permission;
        this.ListPlanDetails = response.data.permission;
        this.recommendedPrice = response.data.recommendedPrice;
        this.isAnyAssociatedOrganization =
          response.data.isAnyAssociatedOrganization;
        const storageQuantityInBytes = response.data.permission[24].quantity;

        this.selectedStoragePrice = response.data.permission[24].defaultPrice;
        this.selectedVODPrice = response.data.permission[25].defaultPrice;
        const storageFeature = this.ListPlanDetails.find(
          (feature) => feature.name === "Storage"
        );

        if (storageFeature && storageFeature.quantityWithPrice) {
          const { id } = storageFeature.quantityWithPrice.find(
            (option) => option.id === storageFeature.dataUsagePricingInfoId
          );
          this.dataUsagePricingInfoId = id;
          const selectedOptions = storageFeature.quantityWithPrice.find(
            (option) => option.id === storageFeature.dataUsagePricingInfoId
          );

          console.log("test", this.dataUsagePricingInfoId);

          if (selectedOptions) {
            const { quantity, unit } = selectedOptions;
            console.log("Quantity:", quantity);
            console.log("Unit:", unit);
            this.convertedQuantity = quantity;
            if (unit === "GB") {
              this.convertedQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
            } else if (unit === "TB") {
              this.convertedQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
            }
            // Now you have the quantity and unit of the selected option
          }
          console.log("checkkkk", this.convertedQuantity);
          this.storageOptions = storageFeature.quantityWithPrice.map(
            (option) => {
              // this.convertedQuantity = option.quantity;
              // if (option.unit === "GB") {
              //   this.convertedQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
              // } else if (option.unit === "TB") {
              //   this.convertedQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
              // }
              return {
                name: `${option.name}`,
                storageQuantity: `${option.quantity}`,
                unit: `${option.unit}`,
                value: option.setPrice,
                convertedQuantity: this.convertedQuantity,
                dataUsagePricingInfoId: option.id,
              };
            }
          );
          this.byDefaultSelectedPrice = parseFloat(storageFeature.defaultPrice);

          storageFeature.defaultPrice = parseFloat(this.byDefaultSelectedPrice);
        } else {
          this.storageOptions = []; // Set empty array if no storage options found
        }
        const vodFeature = this.ListPlanDetails.find(
          (feature) => feature.name === "VOD Data Usage"
        );
        if (vodFeature && vodFeature.quantityWithPrice) {
          const { id } = vodFeature.quantityWithPrice.find(
            (option) => option.id === vodFeature.dataUsagePricingInfoId
          );
          this.dataUsagePricingVODInfoId = id;

          console.log("test", this.dataUsagePricingVODInfoId);

          const selectedVODOptions = vodFeature.quantityWithPrice.find(
            (option) => option.id === vodFeature.dataUsagePricingInfoId
          );

          if (selectedVODOptions) {
            const { quantity, unit } = selectedVODOptions;
            console.log("Quantity:", quantity);
            console.log("Unit:", unit);
            this.convertedVODQuantity = quantity;
            if (unit === "GB") {
              this.convertedVODQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
            } else if (unit === "TB") {
              this.convertedVODQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
            }
            // Now you have the quantity and unit of the selected option
          }
          console.log("checkkkk", this.convertedVODQuantity);
          this.vodOptions = vodFeature.quantityWithPrice.map((vodOption) => {
            // this.convertedVODQuantity = vodOption.quantity;
            // if (vodOption.unit === "GB") {
            //   this.convertedVODQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
            // } else if (vodOption.unit === "TB") {
            //   this.convertedVODQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
            // }
            return {
              name: `${vodOption.name}`,
              storageQuantity: `${vodOption.quantity}`,
              unit: `${vodOption.unit}`,
              value: vodOption.setPrice,
              convertedQuantity: this.convertedVODQuantity,
              dataUsagePricingInfoId: vodOption.id,
            };
          });
          this.byDefaultSelectedPriceVOD = parseFloat(vodFeature.defaultPrice);
          vodFeature.defaultPrice = parseFloat(this.byDefaultSelectedPriceVOD);
        } else {
          this.vodOptions = []; // Set empty array if no storage options found
        }
        
        const liveStream = this.ListPlanDetails.find(
          (feature) => feature.name === "Live Streaming"
        );
        this.viewerCountList = liveStream.subFeature.find(
          (feature) => feature.name === "Live Stream Viewers Count"
        ).viewersCountList.map((e,i) => 
          {
            if(e != 'Unlimited'){
              return { id : Number(e), name: e}
            }
            else{
             return { id : -1, name: e}
            }
          }
        );

        this.selectedViewersCount = liveStream.subFeature.find((feature) => feature.name === "Live Stream Viewers Count").quantity;
        
        const liveStreamFeature = this.ListPlanDetails.find(
          (feature) => feature.name === "Live Streaming"
        );
        const liveStreamDataUsageList = liveStreamFeature.subFeature.find(
          (subFeature) => subFeature.name === "Live Stream Data Usage"
        );
        if (liveStreamDataUsageList && liveStreamDataUsageList.quantityWithPrice) {
          const { id } = liveStreamDataUsageList.quantityWithPrice.find(
            (option) => option.id === liveStreamDataUsageList.dataUsagePricingInfoId
          );
          this.selectedLiveDataUsage = id;
          const selectedLiveOptions = liveStreamDataUsageList.quantityWithPrice.find(
            (option) => option.id === liveStreamDataUsageList.dataUsagePricingInfoId
          );
          if (selectedLiveOptions) {
            const { quantity, unit } = selectedLiveOptions;
            this.convertedLiveStreamQuantity = quantity;
            if (unit === "GB") {
              this.convertedLiveStreamQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
            } else if (unit === "TB") {
              this.convertedLiveStreamQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
            }
          }
          this.liveStreamDataUsageOptions = liveStreamDataUsageList.quantityWithPrice.map((options) => {
            return {
              name: `${options.name}`,
              storageQuantity: `${options.quantity}`,
              unit: `${options.unit}`,
              value: options.setPrice,
              convertedQuantity: this.convertedVODQuantity,
              dataUsagePricingInfoId: options.id,
            };
          });
          this.selectedLiveDataUsage = id
          // this.selectedLiveDataUsage =  parseFloat(this.liveStreamDataUsageOptions[0].dataUsagePricingInfoId)
          // this.byDefaultSelectedPriceVOD = parseFloat(vodFeature.defaultPrice);
          // vodFeature.defaultPrice = parseFloat(this.byDefaultSelectedPriceVOD);
        } else {
          this.liveStreamDataUsageOptions = []; // Set empty array if no storage options found
        }

        this.selectedStorageOption = this.mapBytesToStorageOption(
          storageQuantityInBytes
        );
        this.ListPlanDetails.forEach((feature) => {
          feature.expanded = false;
          if (feature.subFeature) {
            feature.subFeature.forEach((subFeature) => {
              subFeature.expanded = false;
            });
          }
        });
        this.trialDays = response.data.trialDays;
        this.price = response.data.planAmount;
        this.signUpFormUrl = `${appConfig.baseURL}/organizationSignup/${this.subscriptionPlanId}`;
        for (const template of this.ListPlanDetails) {
          if (template.isView) {
            this.featureList.push(template.id);

            this.price = response.data.planAmount;
          }
        }
        // this.calculateRecommendedPrice()
        this.showUI = true;
      });
  }

  // mapBytesToStorageOption(bytes: number): string {
  //   if (bytes === 536870912000) {
  //     return "500gb";
  //   } else if (bytes === 1099511627776) {
  //     return "1tb";
  //   } else if (bytes === 5497558138880) {
  //     return "5tb";
  //   }
  // }

  mapBytesToStorageOption(bytes: number): string {
    const GB = bytes / (1024 * 1024 * 1024); // Convert bytes to GB
    const TB = GB / 1024; // Convert GB to TB

    if (GB <= 1000) {
      return `${GB} GB`;
    } else {
      return `${TB} TB`;
    }
  }

  copyUrl(el) {
    el.select();
    document.execCommand("copy");
    el.setSelectionRange(0, 0);
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Url Copied successfully",
    });
  }

  changePrice() {
    this.price = this.price;
  }

  saveSubscription(form: NgForm) {
    if (form.valid) {
      if (this.isAnyAssociatedOrganization) {
        const getPermissionList = localStorage.getItem("permissionList");
        const oldPermssionList = JSON.parse(getPermissionList);
        const result = this.compareObjects(oldPermssionList, this.detailsList);
        if (!result) {
          this.openModal();
        } else {
          this.selectedOptionFromModal = "yes";
          if (this.subscriptionPlanId) {
            this.updateSubscriptionPlan();
          } else {
            this.createSubscriptionData(form);
          }
        }
      } else {
        this.selectedOptionFromModal = "yes";
        if (this.subscriptionPlanId) {
          this.updateSubscriptionPlan();
        } else {
          this.createSubscriptionData(form);
        }
      }
    } else {
      this.submitted = true;
    }
  }

  createSubscriptionData(form) {
    if (form.submitted) {
      this.submitted = true;
    }
    if (form.form.valid) {
      // this.selectedStorageInBytes = 0;
      // if (this.selectedStorageOption === "500gb") {
      //   this.selectedStorageInBytes = 500 * 1024 ** 3; // 500 GB to bytes
      // } else if (this.selectedStorageOption === "1tb") {
      //   this.selectedStorageInBytes = 1 * 1024 ** 4; // 1 TB to bytes
      // } else if (this.selectedStorageOption === "5tb") {
      //   this.selectedStorageInBytes = 5 * 1024 ** 4; // 5 TB to bytes
      // }

      let selectedViewersVal = this.viewerCountList.find((ele) => ele.id == this.selectedViewersCount).id;

      const sendPermission = this.subFeatures(this.detailsList).map((el) => ({
        featureId: el.id,
        view: el.isView,
        quantity:
          el.name === "Storage"
            ? this.convertedQuantity
            : el.name === "VOD Data Usage"
            ? this.convertedVODQuantity
            : el.name === "Live Stream Viewers Count" ? selectedViewersVal
            : el.name === "Live Stream Data Usage" ? this.convertedLiveStreamQuantity : el.quantity,
        defaultPrice:
          el.name === "Storage"
            ? this.byDefaultSelectedPrice
            : el.name === "VOD Data Usage"
            ? this.byDefaultSelectedPriceVOD
            : el.defaultPrice,
        dataUsagePricingInfoId:
          el.name === "Storage"
            ? this.dataUsagePricingInfoId
            : el.name === "VOD Data Usage"
            ? this.dataUsagePricingVODInfoId
            : el.name === "Live Stream Data Usage" ? this.selectedLiveDataUsage
            : null,
      }));
      if (sendPermission) {
        for (const permission of sendPermission) {
          if (permission.view) {
            if (permission.quantity === 0) {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Quantity must be greater than 0.",
              });
              return;
            } else if (permission.quantity % 1 !== 0) {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Quantity must be a valid number.",
              });
              return;
            } else if (permission.quantity < 0 && permission.featureId !== 51) {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Quantity cannot be negative number.",
              });
              return;
            } else if (!permission.quantity) {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Quantity is required.",
              });
              return;
            }
          }
        }
      }

      if (this.selectedOptionFromModal === "yes") {
        var data = {
          name: this.name,
          trialDays: this.trialDays,
          recommendedPrice: this.recommendedPrice.toString(),
          price: this.price.toString(),
          permission: sendPermission,
          onlyForUpcomingOrg: false,
        };
      } else {
        var data = {
          name: this.name,
          trialDays: this.trialDays,
          recommendedPrice: this.recommendedPrice.toString(),
          price: this.price.toString(),
          permission: sendPermission,
          onlyForUpcomingOrg: true,
        };
      }

      if (this.trialDays % 1 !== 0) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Trial Days cannot be a decimal number.",
        });
      } else if (this.trialDays < 0) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Trial Days cannot be a negative number.",
        });
        return;
      } else if (!this.name || !this.name.trim()) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Plan name cannot be blank.",
        });
      } else if (this.price < 0) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Price cannot be negative.",
        });
      } else {
        this.subscrptionService.createSubscription(data).subscribe(
          (response: any) => {
            this.showInfoModal = false;
            this.switchToggleFor = "";
            this.selectedOptionFromModal = "";
            console.log(response);
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: `Subscription Plan created successfully`,
            });
            this.router.navigate(["/subscription"]);
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: error.error
                ? error.error.message
                : "Something went wrong.",
            });
          }
        );
      }
    }
  }

  updateSubscriptionPlan() {
    let selectedViewersVal = this.viewerCountList.find((ele) => ele.id == this.selectedViewersCount).id;

    const sendPermission = this.subFeatures(this.detailsList).map((el) => ({
      featureId: el.id,
      view: el.isView,
      quantity:
        el.name === "Storage"
        ? this.convertedQuantity
        : el.name === "VOD Data Usage"
        ? this.convertedVODQuantity
        : el.name === "Live Stream Viewers Count" ? selectedViewersVal
        : el.name === "Live Stream Data Usage" ? this.convertedLiveStreamQuantity : el.quantity,
      defaultPrice:
        el.name === "Storage"
          ? this.selectedStoragePrice
          : el.name === "VOD Data Usage"
          ? this.selectedVODPrice
          : el.defaultPrice,
      dataUsagePricingInfoId:
        el.name === "Storage"
        ? this.dataUsagePricingInfoId
        : el.name === "VOD Data Usage"
        ? this.dataUsagePricingVODInfoId
        : el.name === "Live Stream Data Usage" ? this.selectedLiveDataUsage
        : null,
    }));
    if (sendPermission) {
      for (const permission of sendPermission) {
        if (permission.view) {
          if (permission.quantity === 0) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Quantity must be greater than 0.",
            });
            return;
          } else if (permission.quantity % 1 !== 0) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Quantity must be a valid number.",
            });
            return;
          } else if (permission.quantity < 0 && permission.featureId !== 51) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Quantity cannot be negative number.",
            });
            return;
          } else if (!permission.quantity) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Quantity is required.",
            });
            return;
          }
        }
      }
    }
    if (this.selectedOptionFromModal === "yes") {
      var data = {
        subscriptionPlanName: this.name,
        trialDays: this.trialDays,
        recommendedPrice: this.recommendedPrice.toString(),
        price: this.price.toString(),
        permission: sendPermission,
        subscriptionPlanId: this.subscriptionPlanId,
        onlyForUpcomingOrg: false,
      };
    } else {
      var data = {
        subscriptionPlanName: this.name,
        trialDays: this.trialDays,
        recommendedPrice: this.recommendedPrice.toString(),
        price: this.price.toString(),
        permission: sendPermission,
        subscriptionPlanId: this.subscriptionPlanId,
        onlyForUpcomingOrg: true,
      };
    }

    if (this.trialDays % 1 !== 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Trial Days cannot be a decimal number.",
      });
    } else if (this.trialDays < 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Trial Days cannot be a negative number.",
      });
      return;
    } else if (!this.name || !this.name.trim()) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Plan name cannot be blank.",
      });
    } else if (this.price < 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Price cannot be negative.",
      });
    } else {
      this.subscrptionService.updateSubscriptionPlan(data).subscribe(
        (response: any) => {
          this.recommendedPrice = 0;
          this.showInfoModal = false;
          this.switchToggleFor = "";
          this.selectedOptionFromModal = "";
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Plan updated successfully.",
          });
          this.getSubscriptionPlanbyid(this.subscriptionPlanId);
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong.",
          });
        }
      );
    }
  }

  // calculateRecommendedPrice() {
  //   this.recommendedPrice = 0;
  //   this.featureList = [];
  //   let isTVAppView = true;

  //   for (const template of this.ListPlanDetails) {
  //     if (
  //       template.name === "TV App" ||
  //       template.name === "Mobile App" ||
  //       template.name === "Videos" ||
  //       template.name === "Channels" ||
  //       template.name === "Music"
  //     ) {
  //       if (template.subFeature) {
  //         isTVAppView = template.subFeature.some(
  //           (subFeature) => subFeature.isView
  //         );
  //       }

  //       if (template.isView) {
  //         this.featureList.push(template.id);
  //          if (this.subscriptionPlanId || this.subscriptionPlanId === 0){
  //       this.recommendedPrice += template.defaultPrice;
  //       if (template.subFeature && template.subFeature.length > 0) {
  //         for (const subFeature of template.subFeature) {
  //           if (subFeature.isView) {
  //             this.recommendedPrice += subFeature.defaultPrice;
  //           }
  //         }
  //       }
  //       if(this.subscriptionPlanId === 0){
  //       this.price = this.recommendedPrice;
  //       }
  //          }

  //       }
  //       this.price = this.recommendedPrice;
  //     }
  //      this.updateNetworkView(template);
  //   }

  // }

  calculateRecommendedPrice() {
    // if (this.subscriptionPlanId === 0) {
    this.recommendedPrice = 0;
    this.featureList = [];
    let isTVAppView = true;
    let liveStreamFeature = this.subFeatures(this.detailsList).find(
      (el) => el.name === "Live Streaming"
    );
    const customChannel = this.subFeatures(this.detailsList).find(
      (el) => el.name === "Custom Channel"
    );
    const m3u8Channel = this.subFeatures(this.detailsList).find(
      (el) => el.name === "m3u8 Channel"
    );
    const m3uChannel = this.subFeatures(this.detailsList).find(
      (el) => el.name === "m3u Channels"
    );
    const networks = this.subFeatures(this.detailsList).find(
      (el) => el.name === "Networks"
    );
    if (liveStreamFeature.isView) {
      liveStreamFeature = liveStreamFeature.subFeature[0];
      const liveStreamQuantity = liveStreamFeature.quantity || 0;
      if (liveStreamFeature.quantity > 1) {
        const livstreamPrice = liveStreamFeature.defaultPrice || 0;
        const liveStreamTotalPrice =
          liveStreamQuantity * livstreamPrice - liveStreamFeature.defaultPrice;
        this.recommendedPrice += liveStreamTotalPrice;
      }
    }
    if (customChannel.isView) {
      const channelsQuantity = customChannel.quantity || 0;
      if (customChannel.quantity > 1) {
        const channelsPrice = customChannel.defaultPrice || 0;
        const channelsTotalPrice =
          channelsQuantity * channelsPrice - customChannel.defaultPrice;
        this.recommendedPrice += channelsTotalPrice;
      }
    }
    if (m3u8Channel.isView) {
      const channelsQuantity = m3u8Channel.quantity || 0;
      if (m3u8Channel.quantity > 1) {
        const channelsPrice = m3u8Channel.defaultPrice || 0;
        const channelsTotalPrice =
          channelsQuantity * channelsPrice - m3u8Channel.defaultPrice;
        this.recommendedPrice += channelsTotalPrice;
      }
    }
    if (m3uChannel.isView) {
      const channelsQuantity = m3uChannel.quantity || 0;
      if (m3uChannel.quantity > 1) {
        const channelsPrice = m3uChannel.defaultPrice || 0;
        const channelsTotalPrice =
          channelsQuantity * channelsPrice - m3uChannel.defaultPrice;
        this.recommendedPrice += channelsTotalPrice;
      }
    }
    if (networks.isView) {
      const channelsQuantity = networks.quantity || 0;
      if (networks.quantity > 1) {
        const channelsPrice = networks.defaultPrice || 0;
        const channelsTotalPrice =
          channelsQuantity * channelsPrice - networks.defaultPrice;
        this.recommendedPrice += channelsTotalPrice;
      }
    }
    for (const template of this.ListPlanDetails) {
      if (
        template.name === "TV App" ||
        template.name === "Mobile App" ||
        template.name === "Videos" ||
        template.name === "Channels" ||
        template.name === "Music"
      ) {
        if (template.subFeature) {
          isTVAppView = template.subFeature.some(
            (subFeature) => subFeature.isView
          );
        }

        template.isView = isTVAppView;
      }

      if (template.isView) {
        this.featureList.push(template.id);
        if (this.subscriptionPlanId || this.subscriptionPlanId === 0) {
          this.recommendedPrice += template.defaultPrice;

          if (template.subFeature && template.subFeature.length > 0) {
            for (const subFeature of template.subFeature) {
              if (subFeature.isView) {
                this.recommendedPrice += subFeature.defaultPrice;
              }
            }
          }
          // if (this.subscriptionPlanId === 0) {
          //   this.price = this.recommendedPrice;
          // }
        }
      } else {
        template.expanded = false;
      }
      this.updateNetworkView(template);
    }
    // }
  }

  updateParentView(template: any) {
    if (!template.isView) {
      template.expanded = false;
      if (template.subFeature && template.subFeature.length > 0) {
        for (const subFeature of template.subFeature) {
          subFeature.isView = false;
        }
      }
    } else {
      template.expanded = true;
      if (template.subFeature && template.subFeature.length > 0) {
        for (const subFeature of template.subFeature) {
          subFeature.isView = true;
        }
      }
    }
    if(template.name === "Videos" && !template.isView){
      this.detailsList.forEach(el=>{
        if(el.name === 'Channels'){
          el.subFeature.forEach(ele=>{
            if(ele.name === 'Custom Channel'){
              ele.isView = false
              ele.isDisable = true
            }
          })
        }
      })
    }
    this.calculateRecommendedPrice();
  }

  expandRow(template: any) {
    if (template.subFeature) {
      const atLeastOneSubFeatureIsViewed = template.subFeature.some(
        (subFeature) => subFeature.isView
      );
      this.atLeastOneSubFeatureIsViewed = atLeastOneSubFeatureIsViewed;

      if (atLeastOneSubFeatureIsViewed) {
        template.expanded = !template.expanded;
      } else {
        template.expanded = !template.expanded;
        // template.expanded = true
        template.isView = true;
      }
    } else {
      template.expanded = !template.expanded;
    }

    const allSubFeaturesFalse =
      template.subFeature &&
      template.subFeature.every((subFeature) => !subFeature.isView);
    if (allSubFeaturesFalse && !template.expanded) {
      template.isView = false;
    } else {
      template.isView = template.subFeature.some(
        (subFeature) => subFeature.isView
      );
    }
  }

  updateNetworkView(template: any) {
    if (template.name === "Channels") {
      const customChannel = template.subFeature.find(
        (feature) => feature.name === "Custom Channel"
      );
      const m3u = template.subFeature.find(
        (feature) => feature.name === "m3u Channels"
      );
      const m3u8 = template.subFeature.find(
        (feature) => feature.name === "m3u8 Channel"
      );
      const network = template.subFeature.find(
        (subFeature) => subFeature.name === "Networks"
      );
      if (customChannel && m3u && m3u8) {
        const areThreeFalse =
          !customChannel.isView && !m3u.isView && !m3u8.isView;
        const areTwoFalse = !customChannel.isView && !m3u.isView;
        if (areTwoFalse) {
          network.isDisable = true;
          network.isView = false;
          // template.isView = false;
          // template.expanded = false;
        } else {
          network.isDisable = false;
        }
        if (areThreeFalse) {
          network.isView = false;
          template.isView = false;
          template.expanded = false;
        }
      }
      // if(network){
      //   if(network.isView === false){
      //     if(m3u.isView === true){
      //       m3u.isView = false
      //       m3u.isDisable = true
      //     }
      //     else if(m3u.isView === false && network.isView === false){
      //       m3u.isDisable = true
      //     }
      //     else{
      //       m3u.isDisable = false
      //     }
      //   }
      //   else{
      //       m3u.isDisable = false
      //   }
      // }
    }
    if (template.name === "Videos") {
      template.subFeature.forEach((data) => {
        if (data.name === "Videos (mp4, m4v, mov, wmv, flv, mkv, avi)") {
          if (!data.isView) {
            this.unableDisableCustomChannel(true);
          } else {
            this.unableDisableCustomChannel(false);
          }
        }
      });
    }
  }

  unableDisableCustomChannel(view) {
    this.ListPlanDetails.forEach((el) => {
      if (el.name === "Channels") {
        if (el.subFeature) {
          el.subFeature.forEach((ele) => {
            if (ele.name === "Custom Channel") {
              if (view === true) {
                ele.isView = false;
                ele.isDisable = true;
              } else {
                ele.isDisable = false;
              }
            }
          });
        }
      }
    });
  }

  hasAllSubFeaturesToggledOff(feature: any) {
    if (feature.subFeature) {
      return feature.subFeature.every((subFeature: any) => !subFeature.isView);
    }
    return false;
  }

  toggleTVApp() {
    this.isTVAppToggleOn = !this.isTVAppToggleOn;
  }

  openDetail() {
    this.isDetail = true;
    this.getSubscriptionPlanList();
    this.price = this.recommendedPrice;
  }

  goBack() {
    this.isDetail = false;
  }

  subFeatures(detailsList: any[]) {
    const flattenedList = [];
    for (const detail of detailsList) {
      flattenedList.push(detail);
      if (detail.subFeature) {
        for (const subFeature of detail.subFeature) {
          flattenedList.push(subFeature);
        }
      }
    }
    return flattenedList;
  }

  hasSubFeatureWithView(feature: any) {
    if (feature.subFeature) {
      const hasViewedSubFeature = feature.subFeature.some(
        (subFeature) => subFeature.isView
      );
      return hasViewedSubFeature;
    }
    return false;
  }

  allSubFeaturesTrue(template: any) {
    if (template.subFeature) {
      return template.subFeature.every((subFeature) => !subFeature.isView);
    } else {
      return false;
    }
  }

  selectStorage(event) {
    const selectedItem = event;
    const selectedOption = this.storageOptions.find(
      (option) => option.dataUsagePricingInfoId == selectedItem
    );

    if (selectedOption) {
      this.selectedStorageOption = selectedOption.name;

      const { storageQuantity, unit } = selectedOption;
      console.log("Quantity:", storageQuantity);
      console.log("Unit:", unit);
      this.convertedQuantity = storageQuantity;
      if (unit === "GB") {
        this.convertedQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
      } else if (unit === "TB") {
        this.convertedQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
      }
      // this.convertedQuantity = selectedOption.convertedQuantity;
      this.selectedStoragePrice = parseFloat(selectedOption.value);
      this.dataUsagePricingInfoId = parseFloat(selectedOption.dataUsagePricingInfoId);

      const storageFeature = this.ListPlanDetails.find(
        (feature) => feature.name === "Storage"
      );
      if (storageFeature && storageFeature.isView) {
        storageFeature.defaultPrice = this.selectedStoragePrice;
        storageFeature.dataUsagePricingInfoId = this.dataUsagePricingInfoId;
        this.calculateRecommendedPrice();
      }
    } else {
      console.error("Selected option not found in storageOptions.");
    }
  }

  selectVOD(event) {
    const selectedName = event;
    const selectedVODOption = this.vodOptions.find(
      (option) => option.dataUsagePricingInfoId == selectedName
    );

    if (selectedVODOption) {
      this.selectedVODOption = selectedVODOption.name;
      const { storageQuantity, unit } = selectedVODOption;
      console.log("Quantity:", storageQuantity);
      console.log("Unit:", unit);
      this.convertedVODQuantity = storageQuantity;
      if (unit === "GB") {
        this.convertedVODQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
      } else if (unit === "TB") {
        this.convertedVODQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
      }
      // this.convertedVODQuantity = selectedVODOption.convertedQuantity;
      this.selectedVODPrice = parseFloat(selectedVODOption.value);
      console.log("test vod selected ");
      this.dataUsagePricingVODInfoId = parseFloat(selectedVODOption.dataUsagePricingInfoId);
      const vodFeature = this.ListPlanDetails.find(
        (feature) => feature.name === "VOD Data Usage"
      );
      if (vodFeature && vodFeature.isView) {
        vodFeature.defaultPrice = this.selectedVODPrice;
        vodFeature.dataUsagePricingInfoId = this.dataUsagePricingVODInfoId;

        this.calculateRecommendedPrice();
      }
    } else {
      console.error("Selected option not found in storageOptions.");
    }
  }

  selectLiveStream(event){
    const selectedName = event;
    const selectedVODOption = this.liveStreamDataUsageOptions.find(
      (option) => option.dataUsagePricingInfoId == selectedName
    );

    if (selectedVODOption) {
      this.selectedVODOption = selectedVODOption.name;
      const { storageQuantity, unit } = selectedVODOption;
      console.log("Quantity:", storageQuantity);
      console.log("Unit:", unit);
      this.convertedLiveStreamQuantity = storageQuantity;
      if (unit === "GB") {
        this.convertedLiveStreamQuantity *= 1024 * 1024 * 1024; // Convert GB to bytes
      } else if (unit === "TB") {
        this.convertedLiveStreamQuantity *= 1024 * 1024 * 1024 * 1024; // Convert TB to bytes
      }
      // this.convertedVODQuantity = selectedVODOption.convertedQuantity;
      this.selectedLiveStreamPrice = parseFloat(selectedVODOption.value);
      console.log("test vod selected ");
      this.selectedLiveDataUsage = parseFloat(selectedVODOption.dataUsagePricingInfoId);
      const liveFeature = this.ListPlanDetails.find(
        (feature) => feature.name === "Live Streaming"
      );
      if (liveFeature.subFeature[2] && liveFeature.subFeature[2].isView) {
        liveFeature.subFeature[2].defaultPrice = this.selectedLiveStreamPrice;
        liveFeature.subFeature[2].dataUsagePricingInfoId = this.selectedLiveDataUsage;

        this.calculateRecommendedPrice();
      }
    } else {
      console.error("Selected option not found in storageOptions.");
    }
  }

  openModal() {
    this.showInfoModal = true;
  }

  selectOption(item, form) {
    this.selectedOptionFromModal = item;
    if (this.subscriptionPlanId) {
      this.updateSubscriptionPlan();
    } else {
      this.createSubscriptionData(form);
    }
  }

  compareObjects(obj1, obj2) {
    // Check if the number of elements in both arrays is different
    if (obj1.length !== obj2.length) {
      return false;
    }

    // Iterate through each object in the arrays
    for (let i = 0; i < obj1.length; i++) {
      const item1 = obj1[i];
      const item2 = obj2[i];

      // Check if 'isView' property is different
      if (item1.isView !== item2.isView) {
        return false;
      }

      // check if storage value property is different
      if (this.oldSelectedStorageOption !== this.selectedStorageOption) {
        return false;
      }
      if (item1.name === "Live Streaming" && item2.name === "Live Streaming") {
        if (item1.quantity !== item2.quantity) {
          return false;
        }
      }

      if (item1.name === "Custom Channel" && item2.name === "Custom Channel") {
        if (item1.quantity !== item2.quantity) {
          return false;
        }
      }
      if (item1.name === "m3u8 Channel" && item2.name === "m3u8 Channel") {
        if (item1.quantity !== item2.quantity) {
          return false;
        }
      }
      if (item1.name === "m3u Channels" && item2.name === "m3u Channels") {
        if (item1.quantity !== item2.quantity) {
          return false;
        }
      }
      if (item1.name === "Networks" && item2.name === "Networks") {
        if (item1.quantity !== item2.quantity) {
          return false;
        }
      }

      // Check if 'subFeature' property is present and different
      if (
        (item1.subFeature && !item2.subFeature) ||
        (!item1.subFeature && item2.subFeature)
      ) {
        return false;
      }

      // If both have 'subFeature', recursively check 'isView' within 'subFeature'
      if (item1.subFeature && item2.subFeature) {
        if (!this.compareObjects(item1.subFeature, item2.subFeature)) {
          return false;
        }
      }
    }

    // If everything matches, return true
    return true;
  }
}
