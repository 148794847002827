<div class="design-wrapper d-flex justify-content-between mt-20">
    <div class="col-4 mobileapp-content pt-0 ps-0 mb-10">
        <div class="folder-items" [ngClass]="[theme === 'light' ? 'light' : 'dark', theme === 'light' ? 'lightText' : 'darkText']" style="max-height: 600px;min-height: 600px;overflow-y: scroll;">
            <div class="folder-items-header d-flex align-items-center justify-content-between ">
                <p class="info-title my-0" style="font-weight: 800;font-size: 18px;" [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">Content</p>
                <div class="app-filters" [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '']">
                    <button *ngIf="!viewAllItems" type="button" (click)="openPanel('list')" style="margin: 0;"
                        class="btn-white-rounded">
                        <em [appSvgIcon]="'plus'"></em> Add item
                    </button>

                    <!-- <div class="d-flex align-items-center" *ngIf="viewAllItems">
                    <div>
                        <span class="form-check btn-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="selectAll"
                                [(ngModel)]="selectAll" (change)="selectItems($event, 'all')">
                            <label class="form-check-label" for="selectAll">Select All</label>
                        </span>
                    </div>
                    <div style="margin-left: 10px;" >
                        <img src="/assets/images/common/close.svg" alt="" class="pointer"
                    (click)="viewAllItems = false; selectAll = false;  selectedItems = []">
                    </div>
                </div>

                <button *ngIf="!viewAllItems" (click)="viewAll()" type="button" class="btn-white-rounded">View
                    All <span>({{contentLength}})</span></button> -->
                </div>
            </div>

            <ul class="trending-list1"
    [ngClass]="[itemLayout === 'GRID' && itemImages === 'SQUARE' ? 'gridSquareStyle' : '', itemLayout === 'GRID' && itemImages === 'WIDE' ? 'gridWidestyle' : '', itemLayout === 'STACKED' && itemImages === 'WIDE' ? 'stackWidestyle' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerstylw' : '', itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'rowSquarestyle' : '' , itemLayout === 'ROWS' && itemImages === 'WIDE' ? 'rowWidestyle' : '']"
    cdkDropList (cdkDropListDropped)="drop($event,'content')">

    <div *ngIf="carouselActive" class="platform-carousal-wrapper" style="width: 100%;"
        [ngClass]="[marginType === 'VERYTHIN' ? 'carousel-p1' : '' || marginType === 'THIN' ? 'carousel-p2' : '' || marginType === 'THICK' ? 'carousel-p4' : '' || marginType === '' ? 'carousel-p0' : '']">
        <p-carousel [value]="products" [numVisible]="1" [circular]="carousalRotate"
            [autoplayInterval]="3000" [numScroll]="1"
            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'carousradius1' : '']">

            <ng-template let-product pTemplate="item">
                <div class="product-item"
                    [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'carousradius1' : '']">
                    <div class="product-item-content"
                        [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'carousradius1' : '']">
                        <span style="display:inline-block"
                            [ngClass]="[marginType === 'VERYTHIN' ? 'm1' : '' || marginType === 'THIN' ? 'm2' : '' || marginType === 'THICK' ? 'm4' : '', marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'm4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'm2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'm1-Bottom' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'm4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'm2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'm1-Bottom' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'carousradius1' : '', showBannerCarousal ? 'bannerHeight': 'wideHeight']"
                            [ngStyle]="{'background-color': showBannerCarousal ? product.bannerImgColor : product.wideImgColor}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'carousradius1' : '']"
                                [src]="showBannerCarousal ? product.bannerImg : product.wideImg"
                                onload="style.opacity = 1" class="product-image" />
                        </span>

                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
    <div [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '' || (marginType === '' || marginType === null) ? 'p0' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p1-Bottom' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p4-Bottom' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p2-Bottom' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p1-Bottom' : '', staticActive && withWideArtwork && staticWideHeaderImg && video ? 'videoHeader' : '']"
        *ngIf="staticActive" class="platform-carousal-wrapper" style="width: 100%;">
        <div class="static-header" *ngIf="staticActive && withWideArtwork"
            [ngClass]="[staticActive ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'staticradius1' : '', staticActive && withWideArtwork && staticWideHeaderImg ? 'wideHeight' : '']"
            [ngStyle]="{'background-color': staticHeaderColor && !video ? staticHeaderColor : ''}"
            [class.no-background]="video">
            <!-- <span [ngClass]="[staticActive ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'staticradius1' : '', staticActive && withWideArtwork && staticWideHeaderImg ? 'wideHeight' : '']" [ngStyle]="{'background-color': staticHeaderColor}"> -->
            <img *ngIf="!video"
                [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                [src]="staticWideHeaderImg" alt="" onload="style.opacity = 1" style="opacity: 0;">

            <div [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                *ngIf="m3u8 && video" plyr plyrType="video" style="height: 100%;width:100%;"
                [plyrOptions]="options" plyrTitle="Video 1" [plyrPlaysInline]="true"
                [plyrDriver]="hlsjsDriver2" [plyrSources]="currentVedio" (plyrInit)="players = $event"
                [plyrPoster]="poster"></div>

            <!-- (plyrEnded)="completeLiveVideo($event)" (plyrStateChange)="stateChange($event)"
                (plyrEnterFullScreen)="playedFullScreen($event)" (plyrPlay)="liveVideoPlayed($event)" -->


            <div [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                [plyrOptions]="options" *ngIf="!m3u8 && video" id="vidPlay" plyr plyrType="video"
                style="height: 100%;width:100%;" plyrTitle="Video 1" [plyrPlaysInline]="true"
                [plyrSources]=videoPath (plyrInit)="players = $event" [plyrPoster]="poster"></div>

            <!-- (plyrEnded)="completeLiveVideo($event)"
                (plyrStateChange)="stateChange($event)" (plyrEnterFullScreen)="playedFullScreen($event)"
                (plyrPlay)="liveVideoplayed($event)" -->


            <!-- <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                style="background-color: #e6e6e6;opacity: 0;" alt=""> -->
            <!-- </span> -->
        </div>
        <div class="static-header" *ngIf="staticActive && !withWideArtwork"
            [ngClass]="[staticActive ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'staticradius1' : '', staticActive && !withWideArtwork && staticBannerHeaderImg ? 'bannerHeight' : '']"
            [ngStyle]="{'background-color': staticBannerHeaderColor ? staticBannerHeaderColor : ''}">
            <!-- <span [ngClass]="[staticActive ? 'staticHeader': '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'staticradius1' : '', staticActive && !withWideArtwork && staticBannerHeaderImg ? 'bannerHeight' : '']" [ngStyle]="{'background-color': staticBannerHeaderColor}"> -->

            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '']"
                [src]="staticBannerHeaderImg" alt="" onload="style.opacity = 1" style="opacity: 0;">
            <!-- <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                style="background-color: #e6e6e6;opacity: 0;" alt=""> -->
            <!-- </span> -->
        </div>
    </div>

    <li *ngFor="let item of combined" class="d-flex"
        [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '' || marginType === '' ? 'p0' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , itemLayout === 'GRID' && itemImages === 'SQUARE' ? 'gridSquareContainer' : '', itemLayout === 'GRID' && itemImages === 'WIDE' ? 'gridWideContainer' : '', itemLayout === 'STACKED' && itemImages === 'WIDE' ? 'stackWideContainer' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerContainer' : '', itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'rowSquareContainer' : '' , itemLayout === 'ROWS' && itemImages === 'WIDE' ? 'rowWideContainer' : '', theme === 'light' ? 'light' : 'dark', theme === 'light' ? 'lightText' : 'darkText', !showImage && !showDate ? 'onlyTextTrue' : '']">
        <div class="main-content-div py-2 d-flex ">
            <div class="d-flex align-items-center pl-0 list-wrapper">
                <!-- <div style="margin-right: 15px;cursor: grabbing;">
                    <img src="assets/images/common/dots-menu.png" style="width:15px; height:15px " alt="">
                </div> -->
                <div class="item-image" (click)="goToView(item)"
                    [ngClass]="[itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'wrow' : '', itemImages === 'WIDE' && itemLayout === 'ROWS' ? 'rowWide' : '', !showImage && !showDate ? 'hideContainer' : '']">
                    <span *ngIf="showImage"
                        [ngClass]="[itemLayout === 'GRID' && itemImages === 'SQUARE' ? 'gridSquareImg user-img' : '', itemLayout === 'GRID' && itemImages === 'WIDE' ? 'gridWideImg user-img-wide' : '', itemLayout === 'STACKED' && itemImages === 'WIDE' ? 'stackWide user-img-wide' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerImg user-img-banner' : '', itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'rowSquareImg user-img' : '' , itemLayout === 'ROWS' && itemImages === 'WIDE' ? 'rowWideImg user-img-wide' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                        [ngStyle]="{'background-color': item.Imgcolor}">

                        <span *ngIf="showSquare" class="user-img"
                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                            [ngStyle]="{'background-color': item.squareArtworkImageColor ? item.squareArtworkImageColor : '#e6e6e6'}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                [src]="item.squareArtworkId ? item.newimg :  ''" onload="style.opacity = 1"
                                style="background-color: #e6e6e6;opacity: 0;" alt="">
                        </span>
                        <span *ngIf="showWide" class="user-img-wide"
                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                            [ngStyle]="{'background-color': item.wideArtworkImageColor ? item.wideArtworkImageColor : '#e6e6e6'}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                [src]="item.wideArtworkId ? item.newWideimg :  ''"
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;"
                                alt="">
                        </span>
                        <span *ngIf="showBanner" class="user-img-banner"
                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                            [ngStyle]="{'background-color': item.bannerArtworkImageColor ? item.bannerArtworkImageColor : '#e6e6e6'}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                [src]="item.bannerArtworkId ? item.newBannerimg :  ''"
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;"
                                alt="">
                        </span>
                    </span>
                    <div class="date-wrapper" *ngIf="showDate">
                        <div class="date dateMonth">{{item.createdDate | date: 'MMM'}}</div>
                        <div class="date dateDate">{{item.createdDate | date: 'dd'}}</div>
                        <div class="date dateYear">{{item.createdDate | date: 'YYYY'}}</div>
                    </div>
                </div>
                <div class="text-wrapper"
                    [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && itemImages === 'SQUARE' ? 'w70' : '' , itemImages === 'WIDE' && itemLayout === 'ROWS'  ? 'w50' : '' , !showImage && !showDate ? 'onlyText' : '' ]"
                    *ngIf="showTitle">
                    <div class="item-title" (click)="goToView(item)"
                        [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">{{item.title}}</div>
                    <div class="item-subtitle">{{item.subtitle}}</div>
                </div>

                <span class="delete-icon-container" [ngClass]="[showTitle ? 'titleOn' : 'titleOff']">
                    <img class="delete_icon" (click)="deleteCatalogData(item)"
                        src="./../../../../assets/images/library/trash.svg" alt="delete-icon">
                </span>
            </div>
            <!-- <div class="d-flex align-items-center pl-0">
                <span class="user-img" style="background-color: #e6e6e6;">
                    <img [src]="item.squareArtworkId ? item.newimg : ''" onload="style.opacity = 1"
                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                </span>
                <div class="d-flex justify-content-between">
                    <div class="text-content">
                        <h6 (click)="goToView(item)" class="my-0 px-1 ellipsis-text-content"><b>{{item.title}}</b></h6>
                        <p class="my-0 px-1">{{item.subtitle}}</p>
                    </div>
                </div>
            </div>
            <div class="side-icons d-flex justify-content-between align-items-center">
                <input class="mx-2" type="checkbox" *ngIf="viewAllItems">
                <img (click)="deleteCatalogData(item)" src="./../../../../assets/images/library/trash.svg"
                    alt="delete-icon">

            </div> -->
        </div>
    </li>


    <li *ngFor="let item of trendingList; let i = index" class="d-flex" cdkDrag
        [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '' || marginType === '' ? 'p0' : '', marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'STACKED' ? 'p-none' : '' , marginType === 'THICK' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'THIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , marginType === 'VERYTHIN' && itemTitle === 'BELOW' && itemLayout === 'GRID' ? 'p-none' : '' , itemLayout === 'GRID' && itemImages === 'SQUARE' ? 'gridSquareContainer' : '', itemLayout === 'GRID' && itemImages === 'WIDE' ? 'gridWideContainer' : '', itemLayout === 'STACKED' && itemImages === 'WIDE' ? 'stackWideContainer' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerContainer' : '', itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'rowSquareContainer' : '' , itemLayout === 'ROWS' && itemImages === 'WIDE' ? 'rowWideContainer' : '', theme === 'light' ? 'light' : 'dark', theme === 'light' ? 'lightText' : 'darkText',!showImage && !showDate ? 'onlyTextTrue' : '']">
        <!-- <label for="selected-item-{{i}}"></label> -->
        <input type="checkbox" class="selectCheckBox"
            [tooltip]="newselectedData.length === 0 ? 'Select any item to change its position' : newselectedData.length === 1 ? 'Select to move item here' : '' "
            name="item" id="selected-item-{{i}}" [checked]="item.isSelected"
            (change)="changePosition($event,item)">
        <div class="main-content-div py-2 d-flex ">
            <div class="d-flex align-items-center pl-0 list-wrapper">
                <!-- <div style="margin-right: 15px;cursor: grabbing;">
                    <img src="assets/images/common/dots-menu.png" style="width:15px; height:15px " alt="">
                </div> -->
                <div class="item-image" (click)="goToView(item)"
                    [ngClass]="[itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'wrow' : '', itemImages === 'WIDE' && itemLayout === 'ROWS' ? 'rowWide' : '', !showImage && !showDate ? 'hideContainer' : '']">
                    <span *ngIf="showImage"
                        [ngClass]="[itemLayout === 'GRID' && itemImages === 'SQUARE' ? 'gridSquareImg user-img' : '', itemLayout === 'GRID' && itemImages === 'WIDE' ? 'gridWideImg user-img-wide' : '', itemLayout === 'STACKED' && itemImages === 'WIDE' ? 'stackWide user-img-wide' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerImg user-img-banner' : '', itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'rowSquareImg user-img' : '' , itemLayout === 'ROWS' && itemImages === 'WIDE' ? 'rowWideImg user-img-wide' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                        [ngStyle]="{'background-color': item.Imgcolor}">
                        <!-- <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                    style="background-color: #e6e6e6;opacity: 0;" alt=""> -->

                        <span *ngIf="showSquare" class="user-img"
                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                            [ngStyle]="{'background-color': item.squareArtworkImageColor ? item.squareArtworkImageColor : '#e6e6e6'}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''" onload="style.opacity = 1"
                                style="background-color: #e6e6e6;opacity: 0;" alt="">
                        </span>
                        <span *ngIf="showWide" class="user-img-wide"
                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                            [ngStyle]="{'background-color': item.wideArtworkImageColor ? item.wideArtworkImageColor : '#e6e6e6'}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                [src]="item.wideArtwork ? item.wideArtwork.document.newWideimg :  ''"
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;"
                                alt="">
                        </span>
                        <span *ngIf="showBanner" class="user-img-banner"
                            [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                            [ngStyle]="{'background-color': item.bannerArtworkImageColor ? item.bannerArtworkImageColor : '#e6e6e6'}">
                            <img [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']"
                                [src]="item.bannerArtwork ? item.bannerArtwork.document.newBannerimg :  ''"
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;"
                                alt="">
                        </span>
                    </span>
                    <div class="date-wrapper" *ngIf="showDate">
                        <div class="date dateMonth">{{item.createdDate | date: 'MMM'}}</div>
                        <div class="date dateDate">{{item.createdDate | date: 'dd'}}</div>
                        <div class="date dateYear">{{item.createdDate | date: 'YYYY'}}</div>
                    </div>
                    <div class="status-card" *ngIf="item.status === 'SCHEDULED'">
                        <div class="status">Scheduled</div>
                    </div>
                </div>
                <!-- <span *ngIf="showSquare" class="user-img" [ngStyle]="{'background-color': item.squareArtworkImageColor ? item.squareArtworkImageColor : '#e6e6e6'}">
                    <img [ngClass]="[showShadow === true ? 'imgShadow':'']" [src]="item.squareArtworkId ? item.newimg :  ''" onload="style.opacity = 1"
                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                </span>
                <span *ngIf="showWide" class="user-img-wide" [ngStyle]="{'background-color': item.wideArtworkImageColor ? item.wideArtworkImageColor : '#e6e6e6'}">
                    <img [ngClass]="[showShadow === true ? 'imgShadow':'']" [src]="item.wideArtWorkId ? item.newWideimg :  ''" onload="style.opacity = 1"
                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                </span> -->
                <div class="text-wrapper"
                    [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && itemImages === 'SQUARE' ? 'w70' : '' , itemImages === 'WIDE' && itemLayout === 'ROWS'  ? 'w50' : '' , !showImage && !showDate ? 'onlyText' : '' ]"
                    *ngIf="showTitle">
                    <div class="item-title" (click)="goToView(item)"
                        [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">{{item.title}}</div>
                    <div class="item-subtitle">{{item.subtitle}}</div>
                </div>
                <!-- <div class="d-flex justify-content-between">
                    <div class="text-content">
                        <h6 (click)="goToView(item)" class="my-0 px-1 ellipsis-text-content"><b>{{item.title}}</b></h6>
                        <p class="my-0 px-1">{{item.subtitle}}</p>
                    </div>
                </div> -->
                <span class="delete-icon-container" *ngIf="showDeleteIcon"
                    [ngClass]="[showTitle ? 'titleOn' : 'titleOff', overlayText ? 'overlayText' : '']">
                    <img class="delete_icon" (click)="removeCatalogData(item)"
                        src="./../../../../assets/images/library/trash.svg" alt="delete-icon">
                </span>
            </div>
            <!-- <div class="item-wrapper" [ngClass]="[marginType === 'VERYTHIN' ? 'p1' : '' || marginType === 'THIN' ? 'p2' : '' || marginType === 'THICK' ? 'p4' : '', itemLayout === 'STACKED' ? 'w100' : '' || itemLayout === 'ROWS' ? 'rowLayout' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerImg' : '', overlayText === true ? 'overlayWrapper' : '', itemLayout === 'STACKED' && itemTitle === 'BELOW' && itemImages === 'WIDE' ? 'stackWideBelowTitle' : '' , theme === 'light' ? 'light' : 'dark' ]"  *ngFor="let item of contents">
                   
                        <div  class="item-image" [ngClass]="[itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'wrow' : '', itemImages === 'WIDE' && itemLayout === 'ROWS' ? 'rowWide' : '', !showImage && !showDate ? 'hideContainer' : '']">
                            <span *ngIf="showImage" [ngClass]="[itemLayout === 'GRID' && itemImages === 'SQUARE' ? 'gridSquareImg user-img' : '', itemLayout === 'GRID' && itemImages === 'WIDE' ? 'gridWideImg user-img-wide' : '', itemLayout === 'STACKED' && itemImages === 'WIDE' ? 'stackWide user-img-wide' : '', itemLayout === 'STACKED' && itemImages === 'BANNER' ? 'stackBannerImg user-img-banner' : '', itemLayout === 'ROWS' && itemImages === 'SQUARE' ? 'rowSquareImg user-img' : '' , itemLayout === 'ROWS' && itemImages === 'WIDE' ? 'rowWideImg user-img-wide' : '', marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [ngStyle]="{'background-color': item.Imgcolor}">
                                <img  [ngClass]="[marginEdges === 'SHARP' ? 'radius0' : '' || marginEdges === 'CURVE' ? 'radius1' : '', showShadow === true ? 'shadow1' : '' , theme === 'light' ? 'light' : 'dark']" [src]="item.newimg" onload="style.opacity = 1"
                            style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="date-wrapper" *ngIf="showDate">
                                <div class="date dateMonth">{{item.createdDate | date: 'MMM'}}</div>
                                <div class="date dateDate">{{item.createdDate | date: 'dd'}}</div>
                                <div class="date dateYear">{{item.createdDate | date: 'YYYY'}}</div>
                            </div>
                        </div>
                        <div class="text-wrapper" [ngClass]="[overlayText === true ? 'overlay' : '', itemLayout === 'ROWS'  && itemImages === 'SQUARE' ? 'w70' : '' , itemImages === 'WIDE' && itemLayout === 'ROWS'  ? 'w60' : '' , !showImage ? 'onlyText' : '' ]" *ngIf="showTitle">
                            <div class="item-title" [ngClass]="[theme === 'light' ? 'lightText' : 'darkText']">{{item.title}}</div>
                            <div class="item-subtitle">{{item.subtitle}}</div>
                        </div>
                </div> -->
            <!-- <div class="side-icons d-flex justify-content-between align-items-center ">
                <input class="mx-2" type="checkbox" [(ngModel)]="item.itemChecked" *ngIf="viewAllItems"
                    (change)="selectItems($event, item)">

                <img (click)="removeCatalogData(item)" src="./../../../../assets/images/library/trash.svg"
                    alt="delete-icon">

            </div> -->
        </div>
    </li>

    <li *ngIf="!trendingList.length && !combined.length" class="no-media">
        <div class="d-flex align-items-center">
            <div class="recent-info">
                <span>No Data Exists</span>
            </div>
        </div>
    </li>

</ul>



        </div>
    </div>

    <div class="col-8 DesignAdvance">

        <div class="app-block">
            <div class="panel-content">

                <ng-container>

                    <div *ngIf="!eventPanel && !calenderPanel">
                        <!-- <h5>Header</h5> -->

                        <p class="info-title" style="text-transform: uppercase;font-weight: bold;margin-bottom: 6px;">Top Banner</p>

                        <div class="d-flex card-row ">
                            <span
                                class="active p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                                *ngIf="offActive" (click)="offActivate('OFF')">
                                <img src="./../../../../assets/images/library/on-off-button.svg" alt="off">
                                <p class="sub-head-p my-2">Off</p>
                            </span>
                            <span class="web-options p-4 d-flex flex-column align-items-center justify-content-center "
                                *ngIf="!offActive" (click)="offActivate('OFF')">
                                <img src="./../../../../assets/images/library/on-off-button-light.svg" alt="off">
                                <p class="sub-head-p my-2">Off</p>
                            </span>

                            <span *ngIf="staticActive"
                                class="active p-4 d-flex flex-column align-items-center justify-content-center web-options">
                                <img src="./../../../../assets/images/library/staticdark.svg" alt="">
                                <p class="sub-head-p my-2">Fixed</p>
                            </span>

                            <span *ngIf="!staticActive"
                                class="web-options p-4 d-flex flex-column align-items-center justify-content-center "
                                (click)="staticActivate('STATIC')">
                                <img src="./../../../../assets/images/library/static.svg" alt="static">
                                <p class="sub-head-p my-2">Fixed</p>
                            </span>
                            <span *ngIf="!carouselActive"
                                class="d-flex p-4 flex-column align-items-center justify-content-center web-options"
                                (click)="carouselActivate('CAROUSEL')">
                                <img src="./../../../../assets/images/library/rotate_grey.svg" alt="carousel">
                                <p class="sub-head-p my-2">Rotating</p>
                            </span>
                            <span
                                class="active p-4 d-flex flex-column align-items-center justify-content-center web-options"
                                *ngIf="carouselActive">
                                <img src="./../../../../assets/images/library/rotate_white.svg" alt="carousel">
                                <p class="sub-head-p my-2">Rotating</p>
                            </span>

                        </div>
                    </div>

                    <!-- <div (click)="openPanel('carousel')" -->

                    <!-- <div (click)="openMobilePanel()"
    
                        class=" carousel-box d-flex justify-content-between align-items-center " 
                        *ngIf="carouselActive && (!eventPanel || !calenderPanel )">
                        <div class="d-flex align-items-center">
                            <span>
                                <img src="./../../../../assets/images/library/carousel-dark.svg" alt="">
                            </span>
                            <span class="px-4 d-flex flex-column">
                                <span>Carousel items</span>
                                <span >{{carousalData.length}}/5 added</span>
                            </span>
                        </div>
                        <div>
                            <span>
                                <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                            </span>
                        </div>
                    </div> -->


                    <div *ngIf="carouselActive && (!eventPanel || !calenderPanel )">


                        <div class="mt-3">
                            <!-- <h6>Image view</h6> -->
                            <p class="info-title pt-1" style="text-transform: uppercase;font-weight: bold;margin-bottom:-1px;display: flex;align-items: center;">Rotating Banner Design  <span>(Rotating items {{carousalData.length}}/5)</span></p>
                            <div>
                                <form class="d-flex py-1 artworSizes" action="">
                                    <!-- class="py-1 d-flex align-items-center" -->
                                    <div style="margin-right: 15px;">
                                        <label class="" for="carouselWide">

                                            <div>
                                                <input type="radio" (click)="openMobilePanel('WIDE')"
                                                    [(ngModel)]="listDesign.carouselType" id="carouselWide"
                                                    name="carouselType" (change)="changeCarouselType('WIDE')"
                                                    value="WIDE" checked>
                                                <label class="px-1" for="">Poster Box</label>
                                            </div>
                                            <img class="carousel-img"
                                                [src]="products.length>0 ? products[0].wideImg : './../../../../../assets/images/common/defaultWide.png'"
                                                alt="">
                                        </label>
                                    </div>

                                    <div>
                                        <label class="" for="carouselBanner">

                                            <div>
                                                <input type="radio" (click)="openMobilePanel('BANNER')"
                                                    [(ngModel)]="listDesign.carouselType" id="carouselBanner"
                                                    name="carouselType" (change)="changeCarouselType('BANNER')"
                                                    value="BANNER">
                                                <label class="px-1" for="">Landscape Box</label>
                                            </div>
                                            <img class="carousel-img"
                                                [src]="products.length>0 ? products[0].bannerImg : './../../../../../assets/images/common/DefaultBanner.png'"
                                                alt="">
                                        </label>
                                    </div>

                                </form>
                            </div>
                        </div>


                    </div>

                    <!-- <div (click)="openPanel('static')"
                class=" carousel-box d-flex justify-content-between align-items-center"
                *ngIf="staticActive && (!eventPanel && !calenderPanel ) && withWideArtwork">
                <div class="d-flex align-items-center">
                    <span>
                        <img class="static-imgs"  [ngStyle]="{'width': imagePathWide ? '200px' : '50px'}"
                            [src]=" imagePathWide ? imagePathWide :'./../../../../assets/images/library/static.svg'"
                            alt="img">
                    </span>
                    <span class="px-3 d-flex flex-column">
                        <span>Static Image</span>
                        <span *ngIf="!imagePathWide">Add or select an image</span>
                        <span class="ellipsis-text" *ngIf="imagePathWide"> {{staticImageName}}</span>
                    </span>
                </div>
                <div>
                    <span>
                        <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                    </span>
                </div>
            </div> -->

                    <!-- <div (click)="openPanel('static')"
                class=" carousel-box d-flex justify-content-between align-items-center"
                *ngIf="staticActive && (!eventPanel && !calenderPanel ) && !withWideArtwork">
                <div class="d-flex align-items-center">
                    <span>
                        <img class="static-imgs" [ngStyle]="{'width': imagePath ? '200px' : '50px'}"
                            [src]=" imagePath ? imagePath :'./../../../../assets/images/library/static.svg'"
                            alt="img">
                    </span>
                    <span class="px-3 d-flex flex-column">
                        <span>Static Image</span>
                        <span *ngIf="!imagePath">Add or select an image</span>
                        <span class="ellipsis-text" *ngIf="imagePath"> {{staticImageName}}</span>
                    </span>
                </div>
                <div>
                    <span>
                        <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                    </span>
                </div>
            </div> -->

                    <!-- <div (click)="openPanel('static')"
                    class=" carousel-box d-flex justify-content-between align-items-center my-2 py-2 px-2" style="margin-right: 10px;"
                    *ngIf="staticActive && (!eventPanel && !calenderPanel )">
                    <div class="d-flex align-items-center">
                        <span>
                                <img class="static-imgs" style="width: 200px;"
                                [src]="staticHeaderImg ? staticHeaderImg : './../../../../assets/images/library/static.svg'"
                                alt="img">
                        </span>
                        <span class="px-3 d-flex flex-column">
                            <span>Static Image</span>
                            <span>Add or select an image</span>
                            <span class="ellipsis-text"> staticImageName</span>
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                        </span>
                    </div>
                </div> -->

                    <div *ngIf="staticActivate && !carouselActive && !offActive">


                        <div class="" style="margin-top: -5px;">
                            <!-- <h6>Image view</h6> -->
                            <p class="info-title pt-3" style="text-transform: uppercase;font-weight: bold">Fixed Banner Design
                            </p>
                            <div>
                                <form class="d-flex py-1 artworSizes" action="">
                                    <!-- class="py-1 d-flex align-items-center" -->
                                    <div style="margin-right: 15px;">
                                        <label class="" for="staticWide">
                                            <div>
                                                <!-- (click)="openPanel('static')" -->
                                                <input type="radio" (click)="openStaticModal()"
                                                    [(ngModel)]="listDesign.staticType" id="staticWide"
                                                    name="staticType" (change)="changeStaticType('WIDE');" value="WIDE"
                                                    checked>
                                                <label class="px-1" for="">Poster Box</label>
                                                <!-- <p>Image/Video</p> -->
                                            </div>
                                            <img class="carousel-img"
                                                [src]="imagePathWide ? imagePathWide : './../../../../../assets/images/common/defaultWide.png'"
                                                alt="">
                                        </label>
                                    </div>

                                    <div>
                                        <label class="" for="staticBanner">

                                            <div>
                                                <input type="radio" (click)="openPanel('static')"
                                                    [(ngModel)]="listDesign.staticType" id="staticBanner"
                                                    name="staticType" (change)="changeStaticType('BANNER');"
                                                    value="BANNER">
                                                <label class="px-1" for="">Landscape Box</label>
                                            </div>
                                            <img class="carousel-img"
                                                [src]="imagePath ? imagePath : './../../../../../assets/images/common/DefaultBanner.png'"
                                                alt="">
                                        </label>
                                    </div>

                                </form>
                            </div>
                        </div>


                    </div>




                    <div *ngIf="!eventPanel && !calenderPanel" class="py-2">
                        <!-- <h6>Item Layout</h6> -->
                        <p class="info-title" style="text-transform: uppercase;font-weight: bold;margin-bottom: 6px;">App configuration</p>
                        <div class="d-flex card-row ">
                            <span
                                class="p-4 active d-flex flex-column align-items-center justify-content-center  web-options"
                                *ngIf="rowActive" (click)="rowActivate('ROWS')">
                                <img src="./../../../../assets/images/library/row-dark.svg" alt="row">
                                <p class="sub-head-p my-2">Lines</p>
                            </span>
                            <span *ngIf="!rowActive"
                                class="p-4 d-flex flex-column align-items-center justify-content-center web-options"
                                (click)="rowActivate('ROWS')">
                                <img src="./../../../../assets/images/library/row-light.svg" alt="row">
                                <p class="sub-head-p my-2">Lines</p>
                            </span>
                            <span *ngIf="!gridActive"
                                class="p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                                (click)="gridActivate('GRID')">
                                <img src="./../../../../assets/images/library/grid.svg" alt="grid">
                                <p class="sub-head-p my-2">Squares</p>
                            </span>
                            <span *ngIf="gridActive"
                                class="p-4 d-flex flex-column align-items-center justify-content-center  active web-options">
                                <img src="./../../../../assets/images/library/grid-dark.svg" alt="grid">
                                <p class="sub-head-p my-2">Squares</p>
                            </span>
                            <span *ngIf="!stackActive"
                                class="p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                                (click)="stackActivate('STACKED')">
                                <img src="./../../../../assets/images/library/stack-light.svg" alt="stacked">
                                <p class="sub-head-p my-2">Rectangle</p>
                            </span>
                            <span *ngIf="stackActive"
                                class="p-4 d-flex flex-column align-items-center justify-content-center  active web-options">
                                <img src="./../../../../assets/images/library/stack-dark.svg" alt="stacked">
                                <p class="sub-head-p my-2">Rectangle</p>
                            </span>
                        </div>
                    </div>

                    <div *ngIf="eventPanel || calenderPanel" class="py-2">
                        <p class="info-title">App configuration</p>
                        <span
                            class="p-4 active d-flex flex-column align-items-center justify-content-center  web-options"
                            *ngIf="rowActive" (click)="rowActivate('ROWS')">
                            <img src="./../../../../assets/images/library/row-dark.svg" alt="row">
                            <p class="sub-head-p my-2">Lines</p>
                        </span>
                    </div>

                    <div class="py-1 d-flex flex-column"
                        *ngIf="(offActive && rowActive) || (carouselActive && rowActive) || (staticActive && rowActive) ">
                        <div>
                            <!-- <h6>Image view</h6> -->
                            <p class="info-title">Media view</p>
                            <form action="" class="radio-btn-card d-flex">
                                <div class="py-1 d-flex align-items-center">

                                    <label class="px-1 cards" for="row-image-view-image"
                                        [ngClass]="[listDesign.itemDisplay==='IMAGE' ? 'active' : '']">
                                        <input type="radio" [(ngModel)]="listDesign.itemDisplay"
                                            id="row-image-view-image" name="itemDisplay"
                                            (ngModelChange)="getListDesignChange(listDesign.itemDisplay)"
                                            #itemDisplay="ngModel" value="IMAGE">
                                        <span>Image</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="row-image-view-date"
                                        [ngClass]="[listDesign.itemDisplay==='DATE' ? 'active' : '']">
                                        <input type="radio" [(ngModel)]="listDesign.itemDisplay"
                                            id="row-image-view-date" name="itemDisplay"
                                            (ngModelChange)="getListDesignChange(listDesign.itemDisplay)"
                                            #itemDisplay="ngModel" value="DATE">
                                        <span>Date</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="row-image-view-title"
                                        [ngClass]="[listDesign.itemDisplay==='TITLE_ONLY' ? 'active' : '']">
                                        <input type="radio" [(ngModel)]="listDesign.itemDisplay"
                                            id="row-image-view-title" name="itemDisplay"
                                            (ngModelChange)="getListDesignChange(listDesign.itemDisplay)"
                                            #itemDisplay="ngModel" value="TITLE_ONLY">
                                        <span>Title Only</span>
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="listDesign.itemDisplay==='IMAGE'">
                            <div>
                                <!-- <h6>Image size</h6> -->
                                <p class="info-title">Media Box Design</p>
                                <form action="" class="radio-btn-card d-flex">
                                    <div class="py-1 d-flex align-items-center">

                                        <label class="px-1 cards" for="row-image-size-square"
                                            [ngClass]="[listDesign.itemImages==='SQUARE' ? 'active' : '']">
                                            <input type="radio" checked [(ngModel)]="listDesign.itemImages"
                                                id="row-image-size-square" name="itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="SQUARE">
                                            <span>Simple Box</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="row-image-size-wide"
                                            [ngClass]="[listDesign.itemImages==='WIDE' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.itemImages"
                                                id="row-image-size-wide" name="itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="WIDE">
                                            <span>Poster Box</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center" style="opacity: 0;">
                                        <label class="px-1 cards" for="row-image-size-wide"
                                            [ngClass]="[listDesign.itemImages==='WIDE' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.itemImages"
                                                id="row-image-size-wide" name="itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="WIDE">
                                            <span>Poster Box</span>
                                        </label>
                                    </div>
                                    <!-- <div class="py-1 d-flex align-items-center">
                                        <input type="radio" [(ngModel)]="listDesign.itemImages" name="itemImages" #itemImages="ngModel" value="BANNER">
                                        <label class="px-1" for="">Banner</label>
                                    </div> -->





                                </form>
                                <div class="py-1 d-flex align-items-center toggleBtn">
                                    <!-- <label class="switch-input " tooltip="" container="body">
                                        <input type="checkbox" [checked]="listDesign.shadow"
                                            [(ngModel)]="listDesign.shadow" name="shadow" #shadow="ngModel" (change)="shadowMargin('shadow',listDesign.shadow)"
                                            (ngModelChange)="getListDesignChange(listDesign.shadow)">
                                        <span class="slider "></span>
                                    </label> -->
                                    <label class="px-1 d-flex flex-column" for="shadow">
                                        <p class="info-title">Drop Down Shadow</p>
                                        <p-toggleButton (onChange)="handleShadow($event)" id='shadow'
                                            name="isGivingChecked" [(ngModel)]="checked" onLabel="Yes" offLabel="No">
                                        </p-toggleButton>
                                    </label>
                                </div>
                            </div>
                            <!-- <div class="py-3" *ngIf="carouselActive">
                                <p class="info-title">Margin Edges</p>
                                <form action="">
                                    <div class="py-1 d-flex align-items-center">
                                        <input type="radio" [(ngModel)]="listDesign.marginEdges" value="SHARP" name="marginEdges" checked (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                        <label class="px-1" for="">Sharp</label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <input type="radio" [(ngModel)]="listDesign.marginEdges" value="CURVE" name="marginEdges" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                        <label class="px-1" for="">Curve</label>
                       carousalData             </div>
                                </form>
                            </div> -->
                            <!-- <div class="py-3">
                                <div class="py-1 d-flex align-items-center">
                                    <label class="switch-input" tooltip="" container="body">
                                        <input type="checkbox" [checked]="">
                                        <span class="slider"></span>
                                    </label>
                                    <label class="px-1" for="">Shadows</label>
                                </div>
                            </div>  -->
                        </div>
                    </div>


                    <div class="py-2 d-flex flex-column"
                        *ngIf="(offActive && gridActive) || (carouselActive && gridActive ) || (staticActive && gridActive) ">
                        <div>
                            <div>
                                <!-- <h6>Item Titles</h6> -->
                                <p class="info-title">Media Titles</p>
                                <form action="" class="radio-btn-card d-flex">
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="item-title-below"
                                            [ngClass]="[listDesign.itemTitles==='BELOW' ? 'active' : '']">
                                            <input type="radio" checked name="itemTitles" id="item-title-below"
                                                [(ngModel)]="listDesign.itemTitles"
                                                (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                                #itemTitles="ngModel" value="BELOW">
                                            <span>Below</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="item-title-off"
                                            [ngClass]="[listDesign.itemTitles==='OFF' ? 'active' : '']">
                                            <input type="radio" name="itemTitles" id="item-title-off"
                                                [(ngModel)]="listDesign.itemTitles"
                                                (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                                #itemTitles="ngModel" value="OFF">
                                            <span>Off</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center" style="opacity: 0;">
                                        <label class="px-1 cards" for="item-title-off"
                                            [ngClass]="[listDesign.itemTitles==='OFF' ? 'active' : '']">
                                            <input type="radio" name="itemTitles" id="item-title-off"
                                                [(ngModel)]="listDesign.itemTitles"
                                                (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                                #itemTitles="ngModel" value="OFF">
                                            <span>Off</span>
                                        </label>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div>
                            <div>
                                <!-- <h6>Image size</h6> -->
                                <p class="info-title">Media Box Design</p>
                                <form action="" class="radio-btn-card d-flex">
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="image-size-square"
                                            [ngClass]="[listDesign.itemImages==='SQUARE' ? 'active' : '']">
                                            <input type="radio" checked name="itemImages" id="image-size-square"
                                                [(ngModel)]="listDesign.itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="SQUARE">
                                            <span>Simple Box</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="image-size-wide"
                                            [ngClass]="[listDesign.itemImages==='WIDE' ? 'active' : '']">
                                            <input type="radio" name="itemImages" id="image-size-wide"
                                                [(ngModel)]="listDesign.itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="WIDE">
                                            <span>Poster Box</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center" style="opacity: 0;">
                                        <label class="px-1 cards" for="image-size-wide"
                                            [ngClass]="[listDesign.itemImages==='WIDE' ? 'active' : '']">
                                            <input type="radio" name="itemImages" id="image-size-wide"
                                                [(ngModel)]="listDesign.itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="WIDE">
                                            <span>Poster Box</span>
                                        </label>
                                    </div>



                                </form>
                                <div *ngIf="!listDesign.margins" class=" d-flex align-items-center toggleBtn">
                                    <!-- <label class="switch-input" tooltip="" container="body">
                                        <input type="checkbox" [checked]="listDesign.margins"
                                            [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                            (change)="marginSlider($event);getListDesignChange(listDesign.margins)">
                                        <span class="slider"></span>
                                    </label>
                                    <label class="px-1" for="">Margins</label> -->
                                    <label class="px-1 d-flex flex-column" for="margins">
                                        <p class="info-title">Borders</p>
                                        <p-toggleButton (onChange)="handleMargin($event);marginSlider($event)"
                                            id='margins' name="marginsChecked" [(ngModel)]="checkMargins" onLabel="Yes"
                                            offLabel="No"></p-toggleButton>
                                    </label>
                                </div>
                                <div *ngIf="!listDesign.margins" class=" d-flex align-items-center toggleBtn" style="margin-top: 10px;">
                                    <!-- <label class="switch-input"
                                        [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                        container="body">
                                        <input type="checkbox" [checked]="listDesign.shadow"
                                            [(ngModel)]="listDesign.shadow" name="shadow" #shadow="ngModel"
                                            [disabled]="!listDesign.margins">
                                        <span class="slider"></span>
                                    </label> -->
                                    <!-- <label class="px-1" for="">Shadows</label> -->
                                    <label class="px-1 d-flex flex-column" for="shadow">
                                        <p class="info-title">Drop Down Shadow</p>
                                        <p-toggleButton (onChange)="handleShadow($event)"
                                            [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                            [disabled]="!listDesign.margins" id='shadow' name="isGivingChecked"
                                            [(ngModel)]="checked" onLabel="Yes" offLabel="No"></p-toggleButton>
                                    </label>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="d-flex flex-column" style="margin-top: -10px;"
                        *ngIf="((offActive && gridActive) || (carouselActive && gridActive ) || (staticActive && gridActive)  ) && listDesign.margins ">

                        <!-- <div class="py-2"> -->
                        <!-- <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div> -->


                        <div class="d-flex align-items-center toggleBtn">
                            <!-- <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="listDesign.margins"
                                        [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)"
                                        (change)="marginSlider($event);getListDesignChange(listDesign.margins)"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label> -->
                            <label class="px-1 d-flex flex-column" for="margins">
                                <p class="info-title">Borders</p>
                                <p-toggleButton (onChange)="handleMargin($event);marginSlider($event)" id='margins'
                                    name="marginsChecked" [(ngModel)]="checkMargins" onLabel="Yes" offLabel="No">
                                </p-toggleButton>
                            </label>
                        </div>


                        <!-- </div> -->

                        <div class="py-2 px-1">
                            <!-- <h6>Margins</h6> -->
                            <p class="info-title">Border Type</p>
                            <form action="" class="radio-btn-card d-flex">
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="margin-very-thin"
                                        [ngClass]="[listDesign.marginType==='VERYTHIN' ? 'active' : '']">
                                        <input type="radio" name="marginType" id="margin-very-thin"
                                            [(ngModel)]="listDesign.marginType" value="VERYTHIN"
                                            (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                        <span>Very Thin</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="margin-thin"
                                        [ngClass]="[listDesign.marginType==='THIN' ? 'active' : '']">
                                        <input type="radio" name="marginType" id="margin-thin"
                                            [(ngModel)]="listDesign.marginType" value="THIN"
                                            (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                        <span>Thin</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="margin-thick"
                                        [ngClass]="[listDesign.marginType==='THICK' ? 'active' : '']">
                                        <input type="radio" name="marginType" id="margin-thick"
                                            [(ngModel)]="listDesign.marginType" value="THICK"
                                            (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                        <span>Thick</span>
                                    </label>
                                </div>
                            </form>
                        </div>



                        <div style="margin-top: -15px;">
                            <div class="py-2 px-1">
                                <!-- <h6>Margin Edges</h6> -->
                                <p class="info-title">Border Edges</p>
                                <form action="" class="radio-btn-card d-flex">
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="margin-edges-sharp"
                                            [ngClass]="[listDesign.marginEdges==='SHARP' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.marginEdges"
                                                id="margin-edges-sharp" name="marginEdges" value="SHARP"
                                                (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                            <span>Sharp</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="margin-edges-curve"
                                            [ngClass]="[listDesign.marginEdges==='CURVE' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.marginEdges"
                                                id="margin-edges-curve" name="marginEdges" value="CURVE"
                                                (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                            <span>Curve</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center" style="opacity: 0;">
                                        <label class="px-1 cards" for="margin-edges-curve"
                                            [ngClass]="[listDesign.marginEdges==='CURVE' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.marginEdges"
                                                id="margin-edges-curve" name="marginEdges" value="CURVE"
                                                (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                            <span>Curve</span>
                                        </label>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="py-1 d-flex align-items-center toggleBtn" style="margin-top: -15px;">
                            <!-- <label class="switch-input"
                                [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                container="body">
                                <input type="checkbox" [checked]="listDesign.shadow" [(ngModel)]="listDesign.shadow"
                                    (ngModelChange)="getListDesignChange(listDesign.shadow)" name="shadow"
                                    #shadow="ngModel" [disabled]="!listDesign.margins"
                                    (ngModelChange)="getListDesignChange(listDesign.shadow)">
                                <span class="slider"></span>
                            </label>
                            <label class="px-1" for="">Shadows</label> -->
                            <label class="px-1 d-flex flex-column" for="shadow">
                                <p class="info-title">Drop Down Shadow</p>
                                <p-toggleButton (onChange)="handleShadow($event)"
                                    [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                    [disabled]="!listDesign.margins" id='shadow' name="isGivingChecked"
                                    [(ngModel)]="checked" onLabel="Yes" offLabel="No"></p-toggleButton>
                            </label>
                        </div>
                    </div>



                    <div class="py-2 d-flex flex-column"
                        *ngIf="(offActive && stackActive) || (carouselActive && stackActive ) || (staticActive && stackActive)">
                        <div>

                            <p class="info-title">Media Titles</p>
                            <form action="" class="radio-btn-card d-flex">
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="item-title-below"
                                        [ngClass]="[listDesign.itemTitles==='BELOW' ? 'active' : '']">
                                        <input type="radio" checked id="item-title-below"
                                            [(ngModel)]="listDesign.itemTitles" name="itemTitles"
                                            (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                            #itemTitles="ngModel" value="BELOW">
                                        <span>Below</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="item-title-overlay"
                                        [ngClass]="[listDesign.itemTitles==='OVERLAY' ? 'active' : '']">
                                        <input type="radio" [(ngModel)]="listDesign.itemTitles" id="item-title-overlay"
                                            name="itemTitles"
                                            (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                            #itemTitles="ngModel" value="OVERLAY">
                                        <span>Overlay</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="item-title-off"
                                        [ngClass]="[listDesign.itemTitles==='OFF' ? 'active' : '']">
                                        <input type="radio" [(ngModel)]="listDesign.itemTitles" id="item-title-off"
                                            name="itemTitles"
                                            (ngModelChange)="getListDesignChange(listDesign.itemTitles)"
                                            #itemTitles="ngModel" value="OFF">
                                        <span>Off</span>
                                    </label>
                                </div>
                            </form>
                        </div>




                        <div>
                            <div>


                                <p class="info-title">Media Box Design</p>
                                <form action="" class="radio-btn-card d-flex">
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="image-size-wide"
                                            [ngClass]="[listDesign.itemImages==='WIDE' ? 'active' : '']">
                                            <input type="radio" checked name="itemImages" id="image-size-wide"
                                                [(ngModel)]="listDesign.itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="WIDE" checked>
                                            <span>Poster Box</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="image-size-banner"
                                            [ngClass]="[listDesign.itemImages==='BANNER' ? 'active' : '']">
                                            <input type="radio" name="itemImages" id="image-size-banner"
                                                [(ngModel)]="listDesign.itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="BANNER">
                                            <span>Landscape Box</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center" style="opacity: 0;">
                                        <label class="px-1 cards" for="image-size-banner"
                                            [ngClass]="[listDesign.itemImages==='BANNER' ? 'active' : '']">
                                            <input type="radio" name="itemImages" id="image-size-banner"
                                                [(ngModel)]="listDesign.itemImages"
                                                (ngModelChange)="getListDesignChange(listDesign.itemImages)"
                                                #itemImages="ngModel" value="BANNER">
                                            <span> Box</span>
                                        </label>
                                    </div>
                                </form>
                            </div>



                            <!-- <div *ngIf="listDesign.margins" class="py-3 mt-2">
                                                <p class="info-title py-1">Margin Edges</p>
                                <form action="">
                                    <div class="py-1 d-flex align-items-center">
                                        <input type="radio" [(ngModel)]="listDesign.marginEdges" (ngModelChange)="getListDesignChange(listDesign.marginEdges)" name="marginEdges" value="SHARP"  (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                        <label class="px-1" for="">Sharp</label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <input type="radio" [(ngModel)]="listDesign.marginEdges" (ngModelChange)="getListDesignChange(listDesign.marginEdges)" name="marginEdges" value="CURVE" (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                        <label class="px-1" for="">Curve</label>
                                    </div>
                                </form>
                            </div> -->
                            <div *ngIf="!listDesign.margins" class="">
                                <form action="">


                                    <div class="d-flex align-items-center toggleBtn">
                                        <!-- <label class="switch-input" tooltip="" container="body">
                                            <input type="checkbox" [checked]="listDesign.margins"
                                                [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel" (ngModelChange)="getListDesignChange(listDesign.margins)"
                                                (change)="marginSlider($event);getListDesignChange(listDesign.margins)">
                                            <span class="slider"></span>
                                        </label>
                                        <label class="px-1" for="">Margins</label> -->
                                        <label class="px-1 d-flex flex-column" for="margins">
                                            <p class="info-title">Borders</p>
                                            <p-toggleButton (onChange)="handleMargin($event);marginSlider($event)"
                                                id='margins' name="marginsChecked" [(ngModel)]="checkMargins"
                                                onLabel="Yes" offLabel="No"></p-toggleButton>
                                        </label>
                                    </div>

                                    <div class="py-1 d-flex align-items-center toggleBtn" style="margin-top: 10px;">
                                        <!-- <label class="switch-input" [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': '' " container="body">
                                            <input type="checkbox" [checked]="listDesign.shadow"
                                                [(ngModel)]="listDesign.shadow" name="shadow" #shadow="ngModel" (ngModelChange)="getListDesignChange(listDesign.shadow)"
                                                [disabled]="!listDesign.margins">
                                            <span class="slider"></span>
                                        </label>
                                        <label class="px-1" for="">Shadows</label> -->
                                        <label class="px-1 d-flex flex-column" for="shadow">
                                            <p class="info-title">Drop Down Shadow</p>
                                            <p-toggleButton (onChange)="handleShadow($event)"
                                                [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                                [disabled]="!listDesign.margins" id='shadow' name="isGivingChecked"
                                                [(ngModel)]="checked" onLabel="Yes" offLabel="No"></p-toggleButton>
                                        </label>
                                    </div>


                                </form>
                            </div>

                        </div>
                    </div>


                    <div class="d-flex flex-column" style="margin-top: -10px;"
                        *ngIf="((offActive && stackActive) || (carouselActive && stackActive ) || (staticActive && stackActive)  ) && listDesign.margins">

                        <!-- <div class="py-2"> -->
                        <!-- <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label>
                            </div>
                            <div class="py-1 d-flex align-items-center">
                                <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Shadows</label>
                            </div> -->


                        <div class=" d-flex align-items-center toggleBtn">
                            <!-- <label class="switch-input" tooltip="" container="body">
                                    <input type="checkbox" [checked]="listDesign.margins"
                                        [(ngModel)]="listDesign.margins" name="margins" #margins="ngModel"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)"
                                        (change)="marginSlider($event);getListDesignChange(listDesign.margins)"
                                        (ngModelChange)="getListDesignChange(listDesign.margins)">
                                    <span class="slider"></span>
                                </label>
                                <label class="px-1" for="">Margins</label> -->
                            <label class="px-1 d-flex flex-column" for="margins">
                                <p class="info-title">Borders</p>
                                <p-toggleButton (onChange)="handleMargin($event);marginSlider($event)" id='margins'
                                    name="marginsChecked" [(ngModel)]="checkMargins" onLabel="Yes" offLabel="No">
                                </p-toggleButton>
                            </label>
                        </div>


                        <!-- </div> -->

                        <div class="py-2 px-1">
                            <!-- <h6>Margins</h6> -->

                            <p class="info-title">Border Type</p>
                            <form action="" class="radio-btn-card d-flex">
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="margin-very-thin"
                                        [ngClass]="[listDesign.marginType==='VERYTHIN' ? 'active' : '']">
                                        <input type="radio" name="marginType" id="margin-very-thin"
                                            [(ngModel)]="listDesign.marginType" value="VERYTHIN"
                                            (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                        <span>Very Thin</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="margin-thin"
                                        [ngClass]="[listDesign.marginType==='THIN' ? 'active' : '']">
                                        <input type="radio" name="marginType" id="margin-thin"
                                            [(ngModel)]="listDesign.marginType" value="THIN"
                                            (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                        <span>Thin</span>
                                    </label>
                                </div>
                                <div class="py-1 d-flex align-items-center">
                                    <label class="px-1 cards" for="margin-thick"
                                        [ngClass]="[listDesign.marginType==='THICK' ? 'active' : '']">
                                        <input type="radio" name="marginType" id="margin-thick"
                                            [(ngModel)]="listDesign.marginType" value="THICK"
                                            (ngModelChange)="getListDesignChange(listDesign.marginType)">
                                        <span>Thick</span>
                                    </label>
                                </div>
                            </form>
                        </div>



                        <div style="margin-top: -15px;">
                            <div class="py-2 px-1">
                                <!-- <h6>Margin Edges</h6> -->
                                <p class="info-title">Border Edges</p>
                                <form action="" class="radio-btn-card d-flex">
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="margin-edge-sharp"
                                            [ngClass]="[listDesign.marginEdges==='SHARP' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.marginEdges"
                                                id="margin-edge-sharp" name="marginEdges" value="SHARP"
                                                (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                            <span>Sharp</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center">
                                        <label class="px-1 cards" for="margin-edge-curve"
                                            [ngClass]="[listDesign.marginEdges==='CURVE' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.marginEdges"
                                                id="margin-edge-curve" name="marginEdges" value="CURVE"
                                                (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                            <span>Curve</span>
                                        </label>
                                    </div>
                                    <div class="py-1 d-flex align-items-center" style="opacity: 0;">
                                        <label class="px-1 cards" for="margin-edge-curve"
                                            [ngClass]="[listDesign.marginEdges==='CURVE' ? 'active' : '']">
                                            <input type="radio" [(ngModel)]="listDesign.marginEdges"
                                                id="margin-edge-curve" name="marginEdges" value="CURVE"
                                                (ngModelChange)="getListDesignChange(listDesign.marginEdges)">
                                            <span>Curve</span>
                                        </label>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <div class="py-1 d-flex align-items-center toggleBtn" style="margin-top: -15px;">
                            <!-- <label class="switch-input"
                                [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                container="body">
                                <input type="checkbox" [checked]="listDesign.shadow" [(ngModel)]="listDesign.shadow"
                                    (ngModelChange)="getListDesignChange(listDesign.shadow)" name="shadow"
                                    #shadow="ngModel" [disabled]="!listDesign.margins"
                                    (ngModelChange)="getListDesignChange(listDesign.shadow)">
                                <span class="slider"></span>
                            </label>
                            <label class="px-1" for="">Shadows</label> -->
                            <label class="px-1 d-flex flex-column" for="shadow">
                                <p class="info-title">Drop Down Shadow</p>
                                <p-toggleButton (onChange)="handleShadow($event)"
                                    [tooltip]="!listDesign.margins ? 'Shadows can only be used if Margins are enabled': ''"
                                    [disabled]="!listDesign.margins" id='shadow' name="isGivingChecked"
                                    [(ngModel)]="checked" onLabel="Yes" offLabel="No"></p-toggleButton>
                            </label>
                        </div>
                    </div>

                    <!-- <div class="info-msg" >
                        <p>This design may be tricky for users</p>
                        <p>A Static Header can look similar to Stacked Item Layouts without Margins. Users may have a tough time telling the difference between your content and your Header, so we recommend changing your Header.</p>
                    </div> -->



                </ng-container>
                <!-- </div>  -->
                <!-- <div class="app-filters d-flex p-4 justify-content-center">
                    <button (click)="createListDesign()" class="btn btn-white">Apply</button>
                    <button (click)="closePanel()" class="btn btn-white">Cancel</button>
                </div> -->
            </div>
        </div>

        <!-- <div class="text-center mb-10 ">
            <button type="button" (click)="saveBuildYourOwn()" class="btn btn-white mr-10"
                style="width: 100%;">Save</button>
        </div> -->


        <div *ngIf="subTab">
            <div class="panels-header">
                <div class="d-flex align-items-center justify-content-between p-4">
                    <h4>Rotating Items</h4>
                    <!-- <img class="cross-icon-img" (click)="back()" src="./../../../../assets/images/library/back-button.svg"
                        alt=""> -->
                </div>
            </div>
            <div class="panels-header">
                <div class="app-filters d-flex pt-1 justify-content-center">
                    <button (click)="back()" class="btn btn-white">Apply</button>
                    <button (click)="back()" class="btn btn-white">Cancel</button>
                </div>


            </div>
            <div class="d-flex my-2 align-items-center justify-content-center">
                <div class="app-filters " style="padding:10px">
                    <button class="btn-white-rounded" (click)="openCarouselPanel('mobile')">
                        <em [appSvgIcon]="'plus'"></em> Add item
                    </button>
                    <span class="span-text px-3" style="font-weight: 600;">Add up to 5 Rotating items</span>
                </div>

            </div>
            <div class="panel-content" style="padding:0">
                <ng-container>
                    <!-- <div class="d-flex align-items-center">
                        <div class="app-filters d-flex align-items-center justify-content-between">
                            <button class="btn-white-rounded" (click)="openCarouselPanel('mobile')">
                                <em [appSvgIcon]="'plus'"></em> Add item
                            </button>
                            <span class="span-text px-3">Add up to 5 carousel items</span>
                        </div>
                    </div> -->
                    <!-- <div class="folder-items">
                        <ul class="trending-list">
                            <li *ngFor="let item of combined">
                                <div class="main-content-div py-2">
                                    <div class="d-flex align-items-center">
                                        <span class="user-img">
                                            <img src="./../../../../assets/images/library/square-img.png" alt="">
                                        </span>
                                        <span class="text-content ">
                                            <!-- <h6 class="my-0 px-1">Watch Live </h6> -->
                    <!-- <p class="my-0 px-1">{{item.title}} </p>
                                        </span>
                                    </div>
                                </div>
                                <div class="side-icons d-flex justify-content-between align-items-center pb-3 ">
    
    
                                    <img src="./../../../../assets/images/library/trash.svg" alt="delete-icon">
    
                                </div>
                            </li>
    
    
                            <li *ngFor="let item of dataList">
                                <div class="main-content-div py-2">
                                    <div class="d-flex align-items-center">
                                        <span class="user-img">
                                            <img src="./../../../../assets/images/library/square-img.png" alt="">
                                        </span>
                                        <span class="text-content ">
                                            <h6 class="my-0 px-1">{{item.id}} </h6>
                                            <p class="my-0 px-1">{{item.title}} </p>
                                        </span>
                                    </div>
                                </div>
                                <div class="side-icons d-flex justify-content-between align-items-center pb-3 ">
    
    
                                    <img src="./../../../../assets/images/library/trash.svg" alt="delete-icon">
    
                                </div>
                            </li>
    
                        </ul>
                    </div> -->


                    <div class="app-block" style="box-shadow: none;">
                        <!-- <div class="app-block-title">
                            <p class="info-title">Folder Items</p>
                        </div> -->
                        <!-- <ul  class="recent-item-list m-0">
                            <li *ngFor="let item of combined">
                                <div class="d-flex align-items-center">
                                    <span class="user-img">
                                        <img [src]="item.squareArtwork ? item.squareArtwork.document.path : (item.wideArtwork ? item.wideArtwork.document.path : (item.bannerArtwork ? item.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                            alt="">
                                    </span>
                                    <div class="recent-info">
                                        <p class="pointer">
                                            {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                        <!-- </div>
                                </div>
                                <div> -->
                        <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                        [(ngModel)]="item.itemChecked" id=""> -->
                        <!-- <img src="./../../../../assets/images/library/trash.svg" alt="delete-icon">
                                </div>
    
                            </li>
                        </ul> -->

                        <ul class="recent-item-list m-0">
                            <li *ngFor="let item of combined;index as i">
                                <div class="d-flex align-items-center">
                                    <span class="user-img" style="background-color: #e6e6e6;">
                                        <img [src]="item.squareArtworkId ? item.newimg :  ''" onload="style.opacity = 1"
                                            *ngIf="item.squareArtworkId" style="background-color: #e6e6e6;opacity: 0;"
                                            alt="">
                                        <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''"
                                            *ngIf="!item.squareArtworkId" onload="style.opacity = 1"
                                            style="background-color: #e6e6e6;opacity: 0;" alt="">
                                    </span>
                                    <div class="recent-info">
                                        <p class="pointer ellipsis-text">
                                            {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </div>
                                </div>
                                <div style="
                                display: flex;
                                width: auto;
                                height: 17px;
                                margin-top: 8%;
                            ">
                                    <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                        [(ngModel)]="item.itemChecked" id=""> -->
                                    <img (click)="deleteItem(i)" src="./../../../../assets/images/library/trash.svg"
                                        alt="delete-icon">

                                </div>

                            </li>

                            <li *ngFor="let item of dataList;index as i">
                                <div class="d-flex align-items-center">
                                    <span class="user-img" style="background-color: #e6e6e6;">
                                        <img [src]="item.squareArtworkId ? item.newimg :  ''" onload="style.opacity = 1"
                                            *ngIf="item.squareArtworkId" style="background-color: #e6e6e6;opacity: 0;"
                                            alt="">
                                        <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''"
                                            *ngIf="!item.squareArtworkId" onload="style.opacity = 1"
                                            style="background-color: #e6e6e6;opacity: 0;" alt="">
                                    </span>
                                    <div class="recent-info">
                                        <p class="pointer ellipsis-text">
                                            {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </div>
                                </div>
                                <div style="
                                display: flex;
                                width: auto;
                                height: 17px;
                                margin-top: 8%;
                            ">
                                    <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                        [(ngModel)]="item.itemChecked" id=""> -->
                                    <img (click)="delete(i,item)" src="./../../../../assets/images/library/trash.svg"
                                        alt="delete-icon">

                                </div>

                            </li>

                            <!-- <li *ngIf="!combined.length && dataList.length" class="no-media">
                                <div class="d-flex align-items-center">
                                    <div class="recent-info">
                                        <span>No Recent Media Exists</span>
                                    </div>
                                </div>
                            </li> -->

                        </ul>
                    </div>





                </ng-container>
            </div>


        </div>

        <div *ngIf="infoMsg" class="info-msg">
            <p class="info-msg-heading">This design may be tricky for users</p>
            <p class="info-msg-content">A Fixed Banner can look similar to Rectangle App Configuration without Borders. Users
                may
                have a tough time telling the difference between your content and your Banner, so we recommend changing
                your
                Header.</p>

            <div class="d-flex justify-content-between align-items-center">

                <span (click)="closeInfoMsg()" class="info-msg-close">
                    NO THANKS
                </span>

                <span (click)="offActivate('OFF')" class="info-msg-off">
                    TURN HEADER OFF
                </span>

            </div>

        </div>

    </div>
</div>


<!-- ----------------------- Static Header modal -------------------- -->
<p-dialog *ngIf="staticModal" class="static-modal" header="" [(visible)]="staticModal" [modal]="true"
    [draggable]="false" [style]="{width: '30vw'}" [resizable]="false">
    <div class="DesignAdvance"  style="width:100%">

        <p style="font-size: 16px;text-align:center;font-weight: 600;margin-bottom: 5px !important;" class="mb-4">Please Select Image Or Video.</p>


        <div style="padding: 16px;" class="d-flex justify-content-center card-row ">
            <span style="width: 178px;height:160px;margin-right:10px;"
                class="active p-4 d-flex flex-column align-items-center justify-content-center  web-options"
                *ngIf="image">
                <!-- <img src="./../../../../assets/images/library/on-off-button.svg" alt="off"> -->
                <i style="font-size: 20px;" class="fas fa-image"></i>
                <p class="sub-head-p my-2">Image</p>
            </span>
            <span style="width: 178px;height:160px;margin-right:10px;" (click)="selectImage('image')"
                class="web-options p-4 d-flex flex-column align-items-center justify-content-center " *ngIf="!image">
                <!-- <img src="./../../../../assets/images/library/on-off-button-light.svg" alt="off"> -->
                <i style="font-size: 20px;" class="fas fa-image"></i>
                <p class="sub-head-p my-2">Image</p>
            </span>

            <span style="width: 178px;height:160px;" *ngIf="!image"
                class="active p-4 d-flex flex-column align-items-center justify-content-center web-options">
                <!-- <img src="./../../../../assets/images/library/staticdark.svg" alt=""> -->
                <i style="font-size: 20px;" class="fa fa-file-video-o" aria-hidden="true"></i>
                <p class="sub-head-p my-2">Video</p>
            </span>

            <span style="width: 178px;height:160px;" *ngIf="image" (click)="selectImage('video')"
                class="web-options p-4 d-flex flex-column align-items-center justify-content-center ">
                <!-- <img src="./../../../../assets/images/library/static.svg" alt="static"> -->
                <i style="font-size: 20px;" class="fa fa-file-video-o" aria-hidden="true"></i>
                <p class="sub-head-p my-2">Video</p>
            </span>


        </div>


    </div>
    <p-footer>
        <button type="button" (click)="selectImageOrVideo()" class="btn btn-white">Select</button>
        <button type="button" class="btn btn-white" (click)="staticModal = false; ">
            Cancel</button>
    </p-footer>
</p-dialog>

<!-- <app-add-to-list></app-add-to-list> -->

<app-web-list></app-web-list>


<app-tv-list></app-tv-list>

<app-mobile-app-list></app-mobile-app-list>

<!-- <app-add-to-list></app-add-to-list> -->

<app-add-to-folder-side-pop-up></app-add-to-folder-side-pop-up>


<app-artwork (updateArtwork)="getArtwork($event)"></app-artwork>
