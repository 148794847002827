import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CustomerService } from "./customer/customer.service";
import { SharedService } from "./shared/shared.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class FeatureControlService {
  showContact: boolean;
  showEmail: boolean;
  showSocialMedia: boolean;
  showEvent: boolean;
  showLibrary: boolean;
  showRss: boolean;
  showLinks: boolean;
  showFolder: boolean;
  showGiving: boolean;
  showWeb: boolean;
  showMobileApp: boolean;
  showLive: boolean;
  showBookStore: boolean;
  showContactUs: boolean;
  showTvApp: boolean;
  showAnalytics: boolean;
  showNotification: boolean;
  showMusic: boolean;
  showPodcast: boolean;
  giving: boolean;
  showEmbed: boolean;
  showVOD: boolean;
  tvAppAndroidisView: boolean;
  tvAppIosIsView: boolean;
  featureList: any[] = [];
  showHideChannel: boolean;
  showCustomChannel: boolean;
  showM3u8Channel: boolean;
  showM3uChannel: boolean;
  showNetwork: boolean;
  videosExternalStorage: boolean;
  videosInternalStorage: boolean;
  musicExternalStorage: boolean;
  musicInternalStorage: boolean;
  showVastTagAndIfram: boolean;
  mobileAppAndroid: boolean;
  mobileAppIos: boolean;
  showIframe : boolean;
  constructor(
    public customerService: CustomerService,
    public sharedService: SharedService,
    public router: Router
  ) {}

  getFeatureList() {
    const userName = localStorage.getItem("loggedInUserName");
    //  console.log(data)
    const dataToSend = {};
    dataToSend["username"] = userName;

    this.featureList = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.featureList
      : null;
    if (this.featureList) {
      this.showContact = false;
      this.showEmail = false;
      this.showSocialMedia = false;
      this.showEvent = false;
      this.showLibrary = false;
      this.showRss = false;
      this.showLinks = false;
      this.showFolder = false;
      this.showGiving = false;
      this.showWeb = false;
      this.showMobileApp = false;
      this.showLive = false;
      this.showBookStore = false;
      this.showContactUs = false;
      this.showTvApp = false;
      this.showAnalytics = false;
      this.showNotification = false;
      this.showMusic = false;
      this.showPodcast = false;
      this.giving = false;
      this.showEmbed = false;
      this.showVOD = false;
      this.showHideChannel = false;
      this.showCustomChannel = false;
      this.showM3u8Channel = false;
      this.showM3uChannel = false;
      this.showNetwork = false;
      this.videosExternalStorage = false;
      this.videosInternalStorage = false;
      this.musicExternalStorage = false;
      this.musicInternalStorage = false;
      this.tvAppAndroidisView = false;
      this.tvAppIosIsView = false;
      this.mobileAppAndroid = false;
      this.mobileAppIos = false;
      this.showVastTagAndIfram = false;
      this.featureList.forEach((el) => {
        if (el.name === "Contacts") {
          this.showContact = el.isView;
        }
        if (el.name === "Email Templates") {
          this.showEmail = el.isView;
        }
        if (el.name === "Social Media") {
          this.showSocialMedia = el.isView;
        }
        if (el.name === "Events") {
          this.showEvent = el.isView;
        }
        if (el.name === "Library") {
          this.showLibrary = el.isView;
        }
        if (el.name === "RSS Feeds") {
          this.showRss = el.isView;
        }
        if (el.name === "Links") {
          this.showLinks = el.isView;
        }
        if (el.name === "Folder") {
          this.showFolder = el.isView;
        }
        if (el.name === "Website") {
          this.showWeb = el.isView;
        }
        if (el.name === "Mobile App") {
          this.showMobileApp = el.isView;
          console.log("test", el.name);
          // this.showTvApp = el.isView
          const mobileAppSubfeatures = el.subFeature;
          if (mobileAppSubfeatures && mobileAppSubfeatures.length > 0) {
            mobileAppSubfeatures.forEach((subfeature) => {
              if (subfeature.name === "Android Mobile App") {
                this.mobileAppAndroid = subfeature.isView;
                console.log(
                  "check tv app android subfeature---",
                  this.mobileAppAndroid
                );
              }
              if (subfeature.name === "IOS Mobile App") {
                this.mobileAppIos = subfeature.isView;
                console.log(
                  "check mobile app ios subfeature---",
                  this.mobileAppIos
                );
              }
            });
          }
        }
        if (el.name === "Bookstore") {
          this.showBookStore = el.isView;
        }
        if (el.name === "Contact Us") {
          this.showContactUs = el.isView;
        }
        if (el.name === "TV App") {
          this.showTvApp = el.isView;
          const tvAppSubfeatures = el.subFeature;
          if (tvAppSubfeatures && tvAppSubfeatures.length > 0) {
            tvAppSubfeatures.forEach((subfeature) => {
              if (subfeature.name === 'Android TV"') {
                this.tvAppAndroidisView = subfeature.isView;
                console.log(
                  "check tv app subfeature---",
                  this.tvAppAndroidisView
                );
              }
              if (subfeature.name === 'IOS TV"') {
                this.tvAppIosIsView = subfeature.isView;
                console.log(
                  "check tv app IOS subfeature---",
                  this.tvAppIosIsView
                );
              }
            });
          }
        }
        if (el.name === "Analytics") {
          this.showAnalytics = el.isView;
        }
        if (el.name === "Live Streaming") {
          this.showLive = el.isView;
        }
        if (el.name === "Push Notification") {
          this.showNotification = el.isView;
        }
        if (el.name === "Podcast") {
          this.showPodcast = el.isView;
        }
        if (el.name === "Music") {
          this.showMusic = el.isView;
          if (el.subFeature && el.subFeature.length > 0) {
            el.subFeature.forEach((data) => {
              if (data.name === "Music urls (mp3)") {
                this.musicExternalStorage = data.isView;
              }
              if (data.name === "Music(mp3, m4a, wav)") {
                this.musicInternalStorage = data.isView;
              }
            });
          }
        }
        if (el.name === "Giving") {
          this.showGiving = el.isView;
        }
        //  this.profitable = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.profit : '';
        //  if (this.showGiving && this.profitable === false) {
        //    this.hideGiving = true
        //  }
        //  else {
        //    this.hideGiving = false
        //  }

        if (el.name === "Embed") {
          this.showEmbed = el.isView;
        }
        if (el.name === "Videos") {
          this.showVOD = el.isView;
          if (el.subFeature && el.subFeature.length > 0) {
            el.subFeature.forEach((data) => {
              if (data.name === "Video urls (Youtube, vimeo, mp4, m3u8)") {
                this.videosExternalStorage = data.isView;
              }
              if (data.name === "Videos (mp4, m4v, mov, wmv, flv, mkv, avi)") {
                this.videosInternalStorage = data.isView;
              }
            });
          }
        }
        if (el.name === "Channels") {
          this.showHideChannel = el.isView;
          if (el.subFeature && el.subFeature.length > 0) {
            el.subFeature.forEach((data) => {
              if (data.name === "Networks") {
                this.showNetwork = data.isView;
              }
              if (data.name === "Custom Channel") {
                this.showCustomChannel = data.isView;
              }
              if (data.name === "m3u8 Channel") {
                this.showM3u8Channel = data.isView;
              }
              if (data.name === "m3u Channels") {
                this.showM3uChannel = data.isView;
              }
            });
          }
        }
        if (el.name === "Website Vast Tag") {
          this.showVastTagAndIfram = el.isView;
        }
        if (el.name === "Website Iframe") {
          this.showIframe = el.isView;
        }
        // if(this.showMobileApp === false && this.showTvApp === false && this.showAnalytics === false && this.showNotification === false){
        //   this.showApps = false
        // }
        // if(this.showPodcastandMusic === false &&
        //    this.showEmbed === false &&
        //    this.showVOD === false &&
        //    this.showAnalytics === false && this.showLive === false){
        //      this.showMedia = false
        //    }
      });
      //  if (this.showMobileApp === false && this.showTvApp === false && this.showAnalytics === false && this.showNotification === false) {
      //    this.showApps = false
      //  }
      //  else {
      //    this.showApps = true
      //  }
      //  if (this.showPodcast === false && this.showMusic === false &&
      //    this.showEmbed === false &&
      //    this.showVOD === false &&
      //    this.showAnalytics === false && this.showLive === false) {
      //    this.showMedia = false
      //  }
      //  else {
      //    this.showMedia = true
      //  }
    }
    //  this.customerService.switchUser(dataToSend).subscribe((response) => {
    //    localStorage.setItem('loggedInUser', JSON.stringify(response.body.data));
    //    this.sharedService.updateSwitchUserData({
    //      switch: false,
    //      userDetails: response.body.data.userDetails
    //    });
    //    const userDetails = response.body.data.userDetails
    //    console.log('test--', userDetails)
    //  });
  }
}
