import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { MediaService } from 'src/app/services/media/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AppAnimation } from 'src/app/utilities/animations/animations';
import { fromEvent } from 'rxjs';
import { EbookreaderService } from 'src/app/services/collection/ebook/ebookreader.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: 'app-changemediaseries-popup',
  templateUrl: './changemediaseries-popup.component.html',
  styleUrls: ['./changemediaseries-popup.component.css'],
  animations: AppAnimation,
})
export class ChangemediaseriesPopupComponent implements OnInit {

  overlay = false;
  sidePanel = false;
  mediaItemId: any
  songItemId:any
  ebookId:any
  albumId:any
  ebookReaderId:any
  clickFrom: string;
  recentSeries: any[] = [];
  recentMedia : any[] = []
  recentCollections: any[] = [];
  recentAlbum: any[] = [];
  recentAlbumList : any[] = [];
  recentSeriesList: any[] = [];
  recentCollectionsList: any[] = [];
  totalMediaItems = 0;
  selectedItems: any;
  mediaSerieId: any;
  total: number;
  showall = false;
  msId: any;
  clId: any;
  alId:any;
  mediaSId: any;
  radCheck = false
  subsciptionData = new Subscription();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchAlbum') searchAlbum: ElementRef;
  @ViewChild('searchEbook') searchEbook: ElementRef;


  constructor(
    public mediaService: MediaService,
    public messageService: MessageService,
    public artWorkService: ArtWorkService,
    public SharedService: SharedService,
    public route: ActivatedRoute,
    public router: Router,
    public ebook:EbookreaderService
  ) { }

  ngOnInit(): void {
    // this.mediaService.panel$.subscribe(
    //   (state: any) => {
    //     this.overlay = state.showPanel
    //     this.sidePanel = state.showPanel
    //     this.mediaItemId = state.mediaItemId
    //     this.clickFrom = state.clickFrom
    //     this.msId = state.mediaSeriesId
    //     this.getMediaSeries(1, 10, this.mediaItemId)
    //   })
    this.getSubscription()
    // this.radCheck = false
    // this.mediaSId = +this.route.snapshot.paramMap.get('id');
    // this.getMediaSeries(1, 10)
  }

  ngAfterViewInit(){
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      this.getMediaSeries(1,10,this.mediaItemId);      
    })).subscribe();
    fromEvent(this.searchAlbum.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      this.getAlbumData(1, 5, this.songItemId)
         })).subscribe();
    fromEvent(this.searchEbook.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      this.getCollection(1, 5, this.ebookId);
        })).subscribe();
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }

  getSubscription(){

      this.subsciptionData.add(
        this.mediaService.panel$.subscribe(
          (state: any) => {
            this.overlay = state.showPanel
            this.sidePanel = state.showPanel
            this.mediaItemId = state.mediaItemId
            this.songItemId = state.songItemId
            this.ebookId = state.ebookId
            this.clickFrom = state.clickFrom
            if(((this.clickFrom === 'changecollection') ||(this.clickFrom === 'movecll'))){
              this.clId = state.mediaSeriesId
              console.log(this.clId);
              
            }
            else if ((((this.clickFrom === 'changeAlbum') || (this.clickFrom === 'movealbm')))){
              this.alId = state.albumId
            }
            else{
              this.msId = state.mediaSeriesId
            }
            // this.msId = state.mediaSeriesId
            // this.clId = state.ebookReaderId
            // this.alId = state.albumId
            this.radCheck = false
            if(((this.clickFrom === 'changecollection') ||(this.clickFrom === 'movecll'))){
              this.getCollection(1, 5, this.ebookId)
              this.showall = false
            }
            else if (((this.clickFrom === 'changeAlbum') || (this.clickFrom === 'movealbm'))){
              this.getAlbumData(1, 5, this.songItemId)
              this.showall = false
            }
            else{
              this.getMediaSeries(1, 5, this.mediaItemId)
              this.showall = false
            }
           

            this.mediaSId = +this.route.snapshot.paramMap.get('id');
          })

      )
  }

  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.radCheck = false;
    this.searchInput.nativeElement.value = null
    this.mediaService.updatePanel({ showPanel: false })
  }

  getMediaSeries(page, size, id) {
    
    // var search = ''
    this.mediaService.getMediaSeriesWithMediaItem(page, size, id,this.searchInput ? this.searchInput.nativeElement.value : '').subscribe((response: any) => {
      this.recentSeries = response.data.content;
      // this.selectedItems = this.recentSeries.filter(el =>{
      //   if(el.id === this.mediaSId){return el.id}
      // })
      this.recentSeries.forEach(data => {
        if (data.squareArtwork) {
          let id = data.squareArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;

        }
        else if (data.bannerArtwork) {
          let id = data.bannerArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;

        }
        else if (data.wideArtwork) {
          let id = data.wideArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.wideHeight}` + '&width=' + `${appConfig.wideWidth}`;

        }
        if (data.id === this.msId) {
          this.radCheck = true
          this.selectedItems = data.id
          data['itemChecked'] = true
        }
      })
      this.total = response.data.totalElements;
      console.log(this.total);  
      this.recentSeriesList = this.recentSeries.slice(0, 4);
    });
  }

 

  showmore() {
    this.showall = true
    this.getMediaSeries(1, this.total, this.mediaItemId)
    // this.radCheck = false
  }

  cancel() {
    this.radCheck = false
    this.mediaService.updatePanel({ showPanel: false })
    this.recentSeriesList.forEach((el) => {
      el.itemChecked = false
    })
  }

  medSeries(data) {
    this.radCheck = true
    this.selectedItems = data.id
    this.recentSeriesList.forEach((el) => {
      if (data.id === el.id) {
        el['itemChecked'] = true
      }
    })
  }


  medCollection(data) {
    this.radCheck = true
    this.selectedItems = data.id
    this.recentCollectionsList.forEach((el) => {
      if (data.id === el.id) {
        el['itemChecked'] = true
      }
    })
  }

  medAlbum(data) {
    this.radCheck = true
    this.selectedItems = data.id
    this.recentAlbumList.forEach((el) => {
      if (data.id === el.id) {
        el['itemChecked'] = true
      }
    })
  }


  changeMediaSeries() {
    if (this.clickFrom === 'change') {
      let filter = '';
      filter += filter += `ids=${this.mediaItemId}`
      console.log(this.mediaItemId);
      console.log(this.selectedItems);
      
      
      this.mediaService.changeSeries(filter, { mediaSeriesId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Media Series updated Successfully.' });
        this.mediaService.sendUpdateToCreate({ update: true })
        this.mediaService.updatePanel({ showPanel: false })
        this.radCheck = false
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
    else if (this.clickFrom === 'move') {
      // let filter = '';
      // filter += filter += `ids=${this.mediaItemId}`
      let mediaItemId = this.mediaItemId
      this.mediaService.moveMediaItem(mediaItemId, { mediaItemIds: mediaItemId, mediaSeriesId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Media Item Moved Successfully.' });
        // this.router.navigate([`/media-module/media/on-demand/edit-media-series/${this.msId}`]);
        this.mediaService.updatePanel({ showPanel: false })
        this.radCheck = false
        this.mediaService.sendUpdateToCreate({ update: true })
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
  }



  changeCollection() {

    if (this.clickFrom === 'changecollection') {
      let filter = '';
      filter += filter += `ids=${this.ebookId}`
      this.mediaService.changeSeries(filter, { mediaSeriesId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ebook series updated Successfully.' });
        this.mediaService.updatePanel({ showPanel: false })
        this.radCheck = false
        this.ebook.sendUpdateToCreate({ update: true })

        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
    else if (this.clickFrom === 'movecll') {
      // let filter = '';
      // filter += filter += `ids=${this.ebookId}`
      let ebookId = this.ebookId
      console.log('ebook id' , ebookId);
      console.log('ebook lib id', this.selectedItems);
      
      
      this.mediaService.moveMediaItem(ebookId, { mediaItemIds: ebookId, mediaSeriesId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ebook Moved Successfully.' });
        // this.router.navigate([`/media-module/media/on-demand/edit-media-series/${this.msId}`]);
        this.ebook.updatePanel({ showPanel: false })
        this.mediaService.updatePanel({ showPanel: false })
        this.radCheck = false
        this.ebook.sendUpdateToCreate({ update: true })
        this.mediaService.sendUpdateToCreate({ update: true })
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
  }

  changeAlbum(){

    if (this.clickFrom === 'changeAlbum') {
      let filter = '';
      filter += filter += `&ids=${this.songItemId}`
      console.log('song Id :' , this.songItemId);
      console.log('album Id',this.selectedItems);
      
      this.mediaService.changeAlbum(filter, { albumId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Album updated Successfully.' });
        this.mediaService.sendUpdateToCreate({ update: true })
        this.mediaService.updatePanel({ showPanel: false })
        this.mediaService.updatePanel({ showPanel: false })
        this.radCheck = false
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
    else if (this.clickFrom === 'movealbm') {
  
      let songItemId = this.songItemId
      console.log('song id' , this.songItemId);
      console.log('album id' , this.selectedItems);
      
      
      this.mediaService.moveAlbum(songItemId, { musicIds: songItemId, albumId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Song Moved Successfully.' });
    
        // this.ebook.updatePanel({ showPanel: false })
        // this.mediaService.updatePanel({ showPanel: false })
        // this.radCheck = false
        // this.ebook.sendUpdateToCreate({ update: true })
        // this.mediaService.sendUpdateToCreate({ update: true })
        // this.selectedItems = [];
        this.mediaService.sendUpdateToCreate({ update: true })
        this.mediaService.updatePanel({ showPanel: false })
        this.mediaService.updatePanel({ showPanel: false })
        this.radCheck = false
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
  }

  getCollection(page, size, id) {
    var search = ''
    this.mediaService.getMediaSeries(page,size, this.searchEbook ? this.searchEbook.nativeElement.value : '','EBOOK_SERIES').subscribe((response: any) => {
      this.recentCollections = response.data.content;
      // this.selectedItems = this.recentCollections.filter(el =>{
      //   if(el.id === this.mediaSId){return el.id}
      // })
      this.recentCollections.forEach(data => {
        if (data.squareArtwork) {
          let id = data.squareArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;

        }
        else if (data.bannerArtwork) {
          let id = data.bannerArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;

        }
        else if (data.wideArtwork) {
          let id = data.wideArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.wideHeight}` + '&width=' + `${appConfig.wideWidth}`;

        }
        if (data.id === this.clId) {
          this.radCheck = true
          this.selectedItems = data.id
          data['itemChecked'] = true
        }
      })
      this.total = response.data.totalElements;
      this.recentCollectionsList = this.recentCollections.slice(0, 4);
    });
  }

  getAlbumData(page, size, id) {
    // var search = ''
    this.mediaService.getAlbumList(page, size, this.searchAlbum ? this.searchAlbum.nativeElement.value : '').subscribe((response: any) => {
      this.recentAlbum = response.data.content;
      // this.selectedItems = this.recentAlbum.filter(el =>{
      //   if(el.id === this.mediaSId){return el.id}
      // })
      this.recentAlbum.forEach(data => {
        if (data.squareArtwork) {
          let id = data.squareArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;

        }
        else if (data.bannerArtwork) {
          let id = data.bannerArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;

        }
        else if (data.wideArtwork) {
          let id = data.wideArtwork.document.id;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.wideHeight}` + '&width=' + `${appConfig.wideWidth}`;

        }
        if (data.id === this.alId) {
          this.radCheck = true
          this.selectedItems = data.id
          data['itemChecked'] = true
        }
      })
      this.total = response.data.totalElements;
      this.recentAlbumList = this.recentAlbum.slice(0, 4);
    });
  }

  showmore1() {
    this.showall = true
    this.getCollection(1, this.total, this.mediaItemId)
    // this.radCheck = false
  }

  showmore2() {
    this.showall = true
    this.getAlbumData(1, this.total, this.songItemId)
  }

}
