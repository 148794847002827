<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    <div class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">
            <h4>Web Design Layout</h4>
            <img class="cross-icon-img" (click)="closePanel()" src="./../../../../assets/images/library/close.svg" alt="">
        </div>
    </div>
     
    <div class="app-filters pt-1 d-flex justify-content-center"  >
        <button (click)="createListDesign()" class="btn btn-white" >Apply</button>
        <button (click)="closePanel()" class="btn btn-white" >Cancel</button>
    </div>

    <div class="d-flex img-div justify-content-center align-items-center" >
        <img *ngIf='row' src="./../../../../assets/images/library/web-row.svg" alt="web-row" class="web-img" >
        <img *ngIf="grid" src="./../../../../assets/images/library/web-grid.svg" alt="web-grid" class="web-img" > 
    </div>

    <div class="panel-content">


        <ng-container>
            
            <div class="py-4">
                <p class="info-title">Item Layout</p>
                <div class="d-flex justify-content-center" >
                        <span class="p-4 d-flex flex-column align-items-center justify-content-center web-options active"   *ngIf="row" >
                            <img src="./../../../../assets/images/library/row-dark.svg" alt="">
                            <p class="my-2" >Rows</p>
                        </span>
                        <span class="p-4 d-flex flex-column align-items-center justify-content-center web-options" *ngIf="!row" (click)="rowOption('ROWS')">
                            <img src="./../../../../assets/images/library/row-light.svg"    alt="">
                            <p class="my-2" >Rows</p>
                        </span>
                        <span class="p-4 d-flex flex-column align-items-center justify-content-center web-options" *ngIf="!grid" (click)="gridOption('GRID')" >
                            <img src="./../../../../assets/images/library/grid.svg" alt="">
                            <p class="my-2" >Grid</p>
                        </span>
                        <span class="p-4 d-flex flex-column align-items-center justify-content-center web-options active" *ngIf="grid" >
                            <img src="./../../../../assets/images/library/grid-dark.svg" alt="">
                            <p class="my-2" >Grid</p>
                        </span>
                </div>
            </div>
        </ng-container>
    </div>

</div>
