<div class="app-block m-10">
    <div class="app-filters">
        <div>
            <div class="app-search app-search-big">
                <input type="text" placeholder="Search" class="form-control w-100"
                    (input)="templateTable.filterGlobal($event.target.value, 'contains')">
            </div>
            <span *ngIf="showTempData" class="app-dropdown">
                <select class="form-control w-70" (change)="sortBy($event)">
                    <option value="">Sort by</option>
                    <option value="new">Newest First</option>
                </select>
            </span>
            <!-- <button type="button" routerLink="/customer-module/customer/create-template">Create Template</button> -->
            <!-- <button class="crttempltebtn" type="button"
                routerLink="/email-templates-module/template-type/create-template"
                [queryParams]="{type: 'Saved',from:'customer'}">Create Template</button> -->
        </div>
        <button *ngIf="showTempData" type="button" routerLink="/customer-module/customer" class="m-0">Back</button>
    </div>

    <!-- <p-table #templateTable [columns]="cols" [value]="templates" [paginator]="true" [rows]="rowCount"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        [rowsPerPageOptions]="[10,25,50, 100]" [totalRecords]="totalRecords"> -->

    <p-table #templateTable [columns]="cols" [resizableColumns]="true" columnResizeMode="expand" [value]="templates"
        [paginator]="true" [rows]="rowCount" [showCurrentPageReport]="false"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}" [totalRecords]="totalRecords"
        class="tmplttable" styleClass="tmplttable custom-dataTable table-striped">

        <ng-template pTemplate="header" let-columns>
            <tr>
                <!-- <th  width="100">S.no</th> -->
                <th></th>
                <th *ngFor="let col of columns" [class.template-name]="col.field === 'templateName'">
                    {{col.header}}
                </th>
                <th *ngIf="showTempData" width="100">Action</th>
                <th *ngIf="!showTempData" width="100">Preview</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" class="pointer">
                <!-- <td>
                    <span class="mx-2">{{rowIndex+1}}</span>
                </td> -->
                <td>
                    <span class="form-check" style="position: absolute;left: 2%;margin-top: 15px;">
                        <input style="margin-top: -22px;" class="form-check-input" type="radio" name="templates"
                            (change)="selectedVl($event,rowData)" [checked]="rowData.checked">
                    </span>
                </td>


                <td *ngFor="let col of columns">
                    <span *ngIf="col.field !== 'selected'">{{rowData[col.field]}}</span>
                    <input type="checkbox" *ngIf="col.field === 'selected'" style="margin-left: 20px;"
                        [disabled]="col.field === true" [ngClass]="{disable: col.field === true}"
                        (ngModelChange)="select(rowData)" [(ngModel)]="rowData[col.field]" id="">
                    <!-- {{col.field | json}} -->
                </td>
                <!-- <td *ngIf="col.field === selected">
                    <input type="checkbox"  id="">
                </td> -->
                <td *ngIf="showTempData">
                    <span class="d-flex align-items-center">
                        <!-- <em tooltip="edit" [appSvgIcon]="'edit'"
                        routerLink="/customer-module/customer/create-template/{{rowData.id}}"></em> -->
                        <em tooltip="edit" [appSvgIcon]="'edit'"
                            routerLink="/email-templates-module/create-template/{{rowData.id}}"></em>
                        <em tooltip="delete" [appSvgIcon]="'delete'" [ngClass]="{disable: rowData.editable === false}"
                            (click)="deleteModal = true; selectedTemplate = rowData"></em>
                        <em tooltip="preview" [appSvgIcon]="'customize'"
                            routerLink="/customer-module/customer/send-mail/{{rowData.id}}"
                            [queryParams]="{update: true}"></em>
                    </span>
                </td>

                <td *ngIf="!showTempData">
                    <span class="d-flex mx-4 align-items-center">
                        <em tooltip="preview" (click)="getTemplateContent(rowData.id)" [appSvgIcon]="'customize'"></em>
                    </span>
                </td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">
                    No templates found.
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
<div class="app-filters save-template-btn">
    <button class=" crttempltebtn" type="button" (click)="saveTemplate()">Save </button>
</div>

<p-dialog *ngIf="deleteModal" header="Delete Template" [(visible)]="deleteModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div>
        Are you sure, you want to delete {{selectedTemplate.templateName}} ? This cannot be undone.
    </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)="deleteTemplate()">Yes, Delete</button>
        <button type="button" class="btn btn-white" (click)="deleteModal = false">No, Cancel</button>
    </p-footer>
</p-dialog>

<p-dialog *ngIf="templateModal" header="Template Preview" [(visible)]="templateModal" [modal]="true" [draggable]="false"
    [resizable]="false">
    <div class="radio-group">
        <div class="form-check">
            <input class="form-check-input" type="radio" name="template" id="desktop" checked
                (change)="templateView = 'desktop'">
            <label class="form-check-label" for="desktop">
                Desktop
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="template" id="mobile" (change)="templateView = 'mobile'">
            <label class="form-check-label" for="mobile">
                Mobile
            </label>
        </div>
    </div>
    <div class="template-preview" [style.width]="templateView === 'desktop' ? '1024px' : '600px'">
        <div [innerHTML]="templateContent | safe"></div>
    </div>
</p-dialog>