import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppsService {

  constructor(public resourceService: ResourceService) { }

  panel = new Subject();
  panel$ = this.panel.asObservable();

  updatePanel(state){
    this.panel.next(state)
  }


  getAllTabList() {
    const getTabsItemUrl = `${appConfig.upload}`;
    return this.resourceService.getData(getTabsItemUrl);
  }

  getActiveTabs(){
    const getActiveTabsItemUrl = `${appConfig.tabs}/getAllActiveTabList`;
    return this.resourceService.getData(getActiveTabsItemUrl);
  }

  changeTabIndex(data){
    const changeTabIndexUrl = `${appConfig.tabs}/changeIndex`;
      return this.resourceService.putData(changeTabIndexUrl,data);
  }

  getTabDataByID(id) {
    const getTabDataByIDUrl = `${appConfig.tabs}/${id}`;
    return this.resourceService.getData(getTabDataByIDUrl);
  }

  getTabsList(page,size){
    const getAllTabsUrl = `${appConfig.tabs}/getAllTabList?page=${page}&size=${size}`;
    return this.resourceService.getData(getAllTabsUrl);
  }

  createTabs(data,id){
     if(id){
      var createMediaSeriesUrl = `${appConfig.tabs}/${id}`;
      return this.resourceService.putData(createMediaSeriesUrl, data);
     }
     else{
      var createMediaSeriesUrl = `${appConfig.tabs}/createTab`;
      return this.resourceService.postData(createMediaSeriesUrl, data);
     }
  }

  createSubtab(data,id){
    const createSubTabsUrl = `${appConfig.tabs}/createSubTab/${id}`;
      return this.resourceService.postData(createSubTabsUrl, data);
  }

  activeDeactiveTabs(id,status,tabIndex) {
    const activeDeactiveTabsUrl = `${appConfig.tabs}/status/${id}?status=${status}&tabIndex=${tabIndex}`;
    return this.resourceService.putData(activeDeactiveTabsUrl);
  }

  // delete tabs
  deleteTab(id) {
    const deleteTabUrl = `${appConfig.tabs}/${id}`;
    return this.resourceService.deleteData(deleteTabUrl);
  }

   // delete tabs
   deleteSubtab(id) {
    const deleteSubtabUrl = `${appConfig.tabs}/subTab/${id}`;
    return this.resourceService.deleteData(deleteSubtabUrl);
  }

  getbibleReadingPlan(){
    const getbibleReadingPlanUrl = `${appConfig.bibleReadingPlan}/getListOfBibleReadingPlans`;
    return this.resourceService.getData(getbibleReadingPlanUrl);
  }

  renameSubTab(id,data) {
    const renameSubTabsUrl = `${appConfig.tabs}/subTab/${id}`;
    return this.resourceService.putData(renameSubTabsUrl,data);
  }

  getTvAppData(){
    const getTvAppDataUrl = `${appConfig.tabs}/getTvAppInfo`;
    return this.resourceService.getData(getTvAppDataUrl);
  }

  createTvApp(id,data){
    if(id){
      const createTvAppUrl = `${appConfig.tabs}/${id}`;
    return this.resourceService.putData(createTvAppUrl,data);
    }
    else{
      const createTvAppUrl = `${appConfig.tabs}/createTab`;
    return this.resourceService.postData(createTvAppUrl,data);
    }
  }

  // push notifications apis

  createNotificationGroup(data,id){
    if(id){
      const createNotificationGroupUrl = `${appConfig.notification}/${id}`;
      return this.resourceService.putData(createNotificationGroupUrl, data);
    }else{
      const createNotificationGroupUrl = `${appConfig.notification}`;
      return this.resourceService.postData(createNotificationGroupUrl, data);
    }
  }

  getNotificationList(organizationId){
    const getNotificationDataUrl = `${appConfig.notification}?organizationId=${organizationId}`;
    return this.resourceService.getData(getNotificationDataUrl);
  }

  getPushNotificationDetailsList(page, size, organizationId, clickFrom, groupid,endDate,startDate) {
    if (clickFrom === 'APP_DOWNLOAD' || clickFrom === 'APP_LAUNCHES'){
      const getPushNotificationDetailsDataUrl = `${appConfig.appInfo}/appDeviceDetailsWithoutLogin?appInfoType=${clickFrom}&endDate=${endDate}&organizationId=${organizationId}&page=${page}&pushNotification=${false}&size=${size}&startDate=${startDate}`;
      return this.resourceService.getData(getPushNotificationDetailsDataUrl);
    }
    else{
      const getPushNotificationDetailsDataUrl = `${appConfig.appInfo}/appDeviceDetailsWithoutLogin?groupId=${groupid}&organizationId=${organizationId}&page=${page}&pushNotification=${true}&size=${size}`;
      return this.resourceService.getData(getPushNotificationDetailsDataUrl);
    }
  
  }

  archivedGroup(id){
    const archivedGroupUrl = `${appConfig.notification}/archive/${id}`;
    return this.resourceService.putData(archivedGroupUrl);
  }

  reArchivedGroup(id){
    const reArchivedGroupUrl = `${appConfig.notification}/reactivate/${id}`;
    return this.resourceService.putData(reArchivedGroupUrl);
  }

  getArchiveGroup(organizationId){
    const getArchiveGroupDataUrl = `${appConfig.notification}/archived?organizationId=${organizationId}`;
    return this.resourceService.getData(getArchiveGroupDataUrl);
  }

  createNotification(data,type,id){
    if(type === 'save' || type === 'schedule'){var sendBoolean = false}
    else if(type === 'send' || type === 'resend'){var sendBoolean = true}
    if(type === 'resend'){
      const createNotificationUrl = `${appConfig.notificationInfo}/${id}?sendnotification=${sendBoolean}`;
      return this.resourceService.postData(createNotificationUrl);
    }
    else if(id){
      const createNotificationUrl = `${appConfig.notificationInfo}/${id}?sendnotification=${sendBoolean}`;
      return this.resourceService.putData(createNotificationUrl, data);
    }
    else {
      const createNotificationUrl = `${appConfig.notificationInfo}?sendnotification=${sendBoolean}`;
      return this.resourceService.postData(createNotificationUrl, data);
    }
  }

  duplicateNotification(id){
      const createNotificationGroupUrl = `${appConfig.notificationInfo}/duplicate?notificationInfoId=${id}`;
      return this.resourceService.postData(createNotificationGroupUrl);
  }

  // getAllNotificationInfo(){
  //   const getAllNotificationInfoUrl = `${appConfig.notificationInfo}/All`;
  //   return this.resourceService.getData(getAllNotificationInfoUrl);
  // }

  getDraftNotificationInfo(page,size,groupName ){
    if(groupName){
      var getDraftNotificationInfoUrl = `${appConfig.notificationInfo}/draft?groupName=${groupName}&page=${page}&size=${size}`
    }else{
      var getDraftNotificationInfoUrl = `${appConfig.notificationInfo}/draft?page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getDraftNotificationInfoUrl);
  }

  getDraftNotificationInfoList(page,size,groupName  ){
    if(groupName){
      var getDraftNotificationInfoUrl = `${appConfig.notificationInfo}/draft?groupName=${groupName}&page=${page}&size=${size}`
    }else{
      var getDraftNotificationInfoUrl = `${appConfig.notificationInfo}/draft?page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getDraftNotificationInfoUrl);
  }

  // for  selectAll
  getAllDraftNotificationInfoList(page,size,groupName,isSelected){
    const selected = isSelected ? `?isSelected=${isSelected}` : ''
    const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
    const groupNames = (isSelected ? `&groupName=${groupName}` : `?groupName=${groupName}`)
    if(groupName){
      var getDraftNotificationInfoUrl = `${appConfig.notificationInfo}/draft${selected}${groupNames}&page=${page}&size=${size}`
    }else{
      var getDraftNotificationInfoUrl = `${appConfig.notificationInfo}/draft${selected}${pages}&size=${size}`;
    }
    return this.resourceService.getData(getDraftNotificationInfoUrl);
  }

  
  getHistoryNotificationInfoList(page,size,groupName  ){
    if(groupName){
      var getHistoryNotificationInfoUrl = `${appConfig.notificationInfo}/history?groupName=${groupName}&page=${page}&size=${size}`
    }else{
      var getHistoryNotificationInfoUrl = `${appConfig.notificationInfo}/history?page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getHistoryNotificationInfoUrl);
  }
  
  
   getAllHistoryNotificationInfoList(page,size,groupName,isSelected){
    const selected = isSelected ? `?isSelected=${isSelected}` : ''
    const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
    const groupNames = (isSelected ? `&groupName=${groupName}` : `?groupName=${groupName}`)
    if(groupName){
      var getHistoryNotificationInfoUrl = `${appConfig.notificationInfo}/history${selected}${groupNames}&page=${page}&size=${size}`
    }else{
      var getHistoryNotificationInfoUrl = `${appConfig.notificationInfo}/history${selected}${pages}&size=${size}`;
    }
    return this.resourceService.getData(getHistoryNotificationInfoUrl);
  }

  getScheduledNotificationInfoList(page,size,groupName  ){
    if(groupName){
      var getScheduledNotificationInfoUrl = `${appConfig.notificationInfo}/scheduled?groupName=${groupName}&page=${page}&size=${size}`
    }else{
      var getScheduledNotificationInfoUrl = `${appConfig.notificationInfo}/scheduled?page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getScheduledNotificationInfoUrl);
  }

  getAllScheduledNotificationInfoList(page,size,groupName  ){
    if(groupName){
      var getScheduledNotificationInfoUrl = `${appConfig.notificationInfo}/scheduled?isSelected=true&groupName=${groupName}&page=${page}&size=${size}`
    }else{
      var getScheduledNotificationInfoUrl = `${appConfig.notificationInfo}/scheduled?isSelected=true&page=${page}&size=${size}`;
    }
    return this.resourceService.getData(getScheduledNotificationInfoUrl);
  }

  getNotificationInfoByID(id){
    const getNotificationInfoByIdUrl = `${appConfig.notificationInfo}/${id}`;
    return this.resourceService.getData(getNotificationInfoByIdUrl);
  }

  deleteNotificationInfo(id){
    const deletenotificationInfoUrl = `${appConfig.notificationInfo}/${id}`;
    return this.resourceService.deleteData(deletenotificationInfoUrl);
  }

  deleteMultipleNotifications(filter) {
    const deletenotificationInfoUrl = `${appConfig.notificationInfo}?${filter}`;
    return this.resourceService.deleteData(deletenotificationInfoUrl);
  }

  deleteNotificationGroup(id){
    const deletenotificationGroupUrl = `${appConfig.notification}/${id}`;
    return this.resourceService.deleteData(deletenotificationGroupUrl);
  }

  // check stripe api and secret key

  getCheckStripeKeys(){
    const checkStripeKeyURL = `${appConfig.usersubScription}/checkSecretAndStripeKey`;
    return this.resourceService.getData(checkStripeKeyURL)
  }
 
  // check subdomain keys
  
  getSubdomainKeys(){
    const checkSubdomainKeyURL = `${appConfig.usersubScription}/checkSubdomain`;
    return this.resourceService.getData(checkSubdomainKeyURL)
  }

  //check giving keys

  getGivingKeys() {
    const getGivingKeyURL = `${appConfig.pageCustomization}/checkWebGiving`;
    return this.resourceService.getData(getGivingKeyURL)
  }


}

