<div class="main-div">
    <div class="sub-main">
        <div class="nowcast-container">
            <div class="nowcast-container-wrapp">
                <div class="logo animateLogo" [ngClass]="[setLogoPosition ? 'setPosition' : '']">
                    <img src="/assets/images/authenticate/logo.png" alt="logo">
                </div>
                <div class="login-form app-block-small-form" [ngClass]="[formAnimate ? 'showopacity' : '']">
                    <form autocomplete="off" #loginForm="ngForm" name="form" (ngSubmit)="loginForm.form;loginUser()">
                        <div class="login-email">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" name="username" [(ngModel)]="login.username"
                                #username="ngModel" id="username" autocomplete="new-email" ngControl="username" required
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                            <!-- <ng-container *ngIf="username.errors && (username.touched || username.dirty)">
                                <p class="error" [hidden]="!username.errors.pattern">Please enter a valid email</p>
                            </ng-container> -->
                        </div>
                        <div class="login-password">
                            <label for="password">Password</label>
                            <span class="app-password">
                                <input [type]="passwordType ? 'text' : 'password'" class="form-control" name="password"
                                    [(ngModel)]="login.password" required #password="ngModel"
                                    (keydown.space)="$event.preventDefault();" autocomplete="new-password">
                                <em class="password-icon" [class.view]="passwordType" *ngIf="!passwordType" [appSvgIcon]="'crossEyeIcon'"
                                    (click)="passwordType = !passwordType"></em>
                                <em class="password-icon" [class.view]="passwordType" *ngIf="passwordType" [appSvgIcon]="'EyeIcon'"
                                    (click)="passwordType = !passwordType"></em>
                            </span>
                            <!-- <ng-container *ngIf="password.errors && (password.touched || password.dirty)">
                                <p class="error" [hidden]="!password.errors.required">This field is required</p>
                            </ng-container> -->
                        </div>
                        <div class="button-div">
                            <a class="but-anc pb-1" routerLink="/forget-password">Forgot Password?</a>
                            <button type="submit" [ngClass]="{disable: !login.username || !login.password}"
                                class="submit-btn">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
