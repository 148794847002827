<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    

        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between align-items-center p-4">
                <h4>Mobile and TV App promo Embed</h4>
                <img class="cross-icon-img"  src="./../../../../assets/images/library/close.svg"
                 class="" (click)="closePanel()" style="cursor: pointer;" alt="">
            </div>
        </div>
        <div class="panel-content">

            <div>
                <p class="info-title">
                    Embed Code
                </p>
                <p class="para-text my-3">
                    Copy and paste the embed code below into your website wherever you would like it to be shown.
                </p>
    
                <a  >
                    Learn more about embed codes
                </a>
               
                <div class="form-group my-3">
                    
                    <span class="app-password app-copy">
                        <input type="text" class="form-control" [(ngModel)]="code" readonly  >
                        <em class="password-icon" [appSvgIcon]="'copy'"  ></em>
                    </span>
                </div>
    
            </div>


        </div>
</div>
