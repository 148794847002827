import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';
import { Subject, forkJoin, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(
    public resourceService: ResourceService
  ) { }
  
  getBaseData(year, month) {
    const filterUrl = (year && !month) ? `&year=${year}` : ((year && month) ? `&yearMonth=${year}-${month}` : '');
    const getExpenseList = this.resourceService.getData(`${appConfig.getExpenseList}?page=1&size=100&${filterUrl}`);
    const getRevenueList = this.resourceService.getData(`${appConfig.getRevenueList}?page=1&size=100&${filterUrl}`);
    return forkJoin([getExpenseList, getRevenueList]).pipe(catchError(this.errorHandler));
  }

  getDashBaseData(year, month) {
    const filterUrl = (year && !month) ? `&year=${year}` : ((year && month) ? `&yearMonth=${year}-${month}` : '');
    const filter = (year && !month) ? `?year=${year}` : ((year && month) ? `?year=${year}&yearMonth=${year}-${month}` : '');
    const getExpenseList = this.resourceService.getData(`${appConfig.getExpenseList}?page=1&size=100&${filterUrl}`);
    const getRevenueList = this.resourceService.getData(`${appConfig.getRevenueListBoard}${filter}`);
    return forkJoin([getExpenseList, getRevenueList]).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error || 'Server Error');
  }

  /* get Media Play Count */

  // getMediaPlayCount(){
  //   const getMediaPlayCountURL = `${appConfig.}`
  // }


  /*  get recent item */

  getRecentMediaItem(){
    const getRecentMediaItemURL = `${appConfig.dashboard}/mostRecentItem`
    return this.resourceService.getData(getRecentMediaItemURL)
  }

  /* get trending list item */

  getTrendingListItem(){
    const getTrendingListItemURL = `${appConfig.dashboard}/getTrendingItemList`
    return this.resourceService.getData(getTrendingListItemURL)
  }

  /* get Media Play Count */

  getMediaPlayCount(){
    const getMediaPlayCountURL = `${appConfig.dashboard}/getMediaPlayCount`
    return this.resourceService.getData(getMediaPlayCountURL)
  }

  /* get app downloads */

  getAppDownloads(){
    const getAppDownloadsURL = `${appConfig.appInfo}/getAppDownloads`
    return this.resourceService.getData(getAppDownloadsURL)
  }

  // get promotional image data

  getPromotionalData(){
    const getPromotionalDataURL = `${appConfig.dashboard}`
    return this.resourceService.getData(getPromotionalDataURL)
  }

  deletePromotionalImage(id){
    const deletePromotionalImageUrl = `${appConfig.dashboard}/${id}`;
    return this.resourceService.deleteData(deletePromotionalImageUrl);
  }

  /* get App impressions */

  getAppImpressions(){
    const getAppImpressionsURL = `${appConfig.appInfo}/getAppImpression`
    return this.resourceService.getData(getAppImpressionsURL)
  }

  /* get app launches */

  getAppLaunches(){
    const getAppLaunchesURL = `${appConfig.appInfo}/getAppLaunches`
    return this.resourceService.getData(getAppLaunchesURL)
  }

  getUnsubscribedCount(){
    const unSubscribeCountURL = `${appConfig.subscription}/unSubscribeUsersCount`
    return this.resourceService.getData(unSubscribeCountURL)
  }

  getFreeSubscriberCount(){
    const freeSubscribeCountURL = `${appConfig.subscription}/subscribersCount`
    return this.resourceService.getData(freeSubscribeCountURL)
  }


  geSubscribedCount(){
    const subscribeCountURL = `${appConfig.subscription}/SubscribedUsersCount`
    return this.resourceService.getData(subscribeCountURL)
  }

  getAllDaashboardData(){
    const allDashboardDataURL = `${appConfig.dashboard}/analytics`
    return this.resourceService.getData(allDashboardDataURL)
  }

  getTransactionalSummary(type:any){
    const transactionalSummaryDataURL = `${appConfig.dashboard}/transactionalSummary?summaryType=${type}`
    return this.resourceService.getData(transactionalSummaryDataURL)
  }

  getDataUsagePercentageInfo(date: any){
    const dataUsagePercentageInfoURL = `${appConfig.dataUsageInfo}/dataUsagePercentageInfo?date=${date}`
    return this.resourceService.getData(dataUsagePercentageInfoURL)
  }

}

