import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { MediaService } from 'src/app/services/media/media.service';
import { AppAnimation } from 'src/app/utilities/animations/animations';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { FeatureControlService } from 'src/app/services/feature-control.service';
@Component({
  selector: 'app-change-channel-side-panel',
  templateUrl: './change-channel-side-panel.component.html',
  styleUrls: ['./change-channel-side-panel.component.css'],
  animations: AppAnimation,
})
export class ChangeChannelSidePanelComponent implements OnInit {
  overlay = false;
  sidePanel = false;
  subsciptionData = new Subscription();
  recentSeries: any[]=[]
  totalMediaSeries: any
  totalMediaSeriesRecords: any
  dataId : any;
  showall = false;
  listIdArray : any[]=[];
  clickFrom: any
  listId: any
  tabId: any
  itemId: any
  mobileTabId: any
  selectedChannelData: any

  @ViewChild ('searchInput') searchInput : ElementRef
  constructor(
    public libraryService: LibraryServiceService,
    private messageService: MessageService,
    private mediaService: MediaService,
    private router: Router,
    public artworkService: ArtWorkService,
    public featureControlService: FeatureControlService
  ) { }

  ngOnInit(): void {
    this.getSubscriptionData()
  }


  getSubscriptionData(){
    this.subsciptionData.add(
    this.libraryService.channelPanelState$.subscribe(
      (state:any) => {
        this.sidePanel = state.showPanel;
        this.overlay = state.showPanel
        this.dataId = state.Id
        this.clickFrom = state.clickFrom
        this.itemId = state.itemId
        this.listIdArray = []
        this.mobileTabId = state.tabId
        this.listId = 0
        if(this.featureControlService.showM3u8Channel && !this.featureControlService.showCustomChannel){
          this.getChannelList(1,25,'')
        }
        else{
          this.getChannelList(1,20,'')  
        } 
      }
    )
    )
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }

  ngAfterViewInit() {
   
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      if(this.searchInput.nativeElement.value.length>2){
        this.getChannelList(1,10,this.searchInput.nativeElement.value);
      }
      else if(this.searchInput.nativeElement.value.length === 0){
        this.getChannelList(1,10,this.searchInput.nativeElement.value);
        setTimeout(() => {
          this.showall = false
        }, 1000);
      }
    })).subscribe();
  }
  
  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.showall = false;
    this.libraryService.updateEbookPanelState({ showPanel: false })
  }

  showmore(){
    this.showall = true
    this.getChannelList(1,this.totalMediaSeries,'');
  }


  getChannelList(page,size,search) {
    this.mediaService.getMediaItems(page,size,search,'CHANNEL','').subscribe((response: any) => {
      this.recentSeries = response.data.content ? response.data.content: [];
      this.recentSeries = this.recentSeries.filter(data => {
        if(this.featureControlService.showM3u8Channel && !this.featureControlService.showCustomChannel){
          return data.channelType === 'M3U8'
        }
        else if(this.featureControlService.showCustomChannel && !this.featureControlService.showM3u8Channel){
          return data.channelType === 'CUSTOM'
        }
        else{
          return data.channelType === 'M3U8' || data.channelType === 'CUSTOM';
        }
      })
      this.totalMediaSeries = response.data.totalElements ? response.data.totalElements : null
      this.totalMediaSeriesRecords = response.data.totalElements ? response.data.totalElements : null
      if(response.data.totalElements < 5){
        this.showall = true
      }
  
      this.recentSeries.forEach(data =>{
        if(data.squareArtwork){
            var width = 270;var height = 270;
            let id = data.squareArtwork.document.id;
            console.log(id,`id`)
            data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            console.log(data['newimg'],`new`)
        }
        else if(data.bannerArtwork){
           var width = 300;var height = 100;
            let id = data.bannerArtwork.document.id;
            data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;

        }
        else if(data.wideArtwork){
            var width = 300;var height = 145;
            let id = data.wideArtwork.document.id;
            data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;

        }
      })
      this.recentSeries.forEach((el) => {
          if (this.itemId === el.id) {
            // el['itemChecked'] = true
            let selectedId = 'selected-list-'+this.recentSeries.indexOf(el)
            setTimeout(() => {
              let element: HTMLElement = document.getElementById(selectedId) as HTMLElement;
              element.click();
            }, 500);
            
          }
      })
    });
  }

  apply(){
    this.changeEbookSeries()
  }
  changeEbookSeries(){
    if (this.clickFrom === 'tvApp') {
      this.mediaService.getMediaById(this.listId).subscribe((response: any) => {
        this.libraryService.sendUpdateToCreate({ update: true })
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update successfully.' });
        setTimeout(() => {
          this.artworkService.sendChannelPanelId(this.selectedChannelData)
        }, 1000);
        this.closePanel()
      }
        , (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
    }
    else if(this.dataId ){
      this.tabId = this.dataId
      this.libraryService.changetabFolderdata(this.listId,this.tabId,'CHANNEL').subscribe((response:any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update successfully.' });
        this.libraryService.sendUpdateToCreate({ update: true })
        this.closePanel()
      }
      ,(error) =>{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });

    }
  }

  selectItems(event,item) {
    if(this.clickFrom === 'subtabs'){
      this.listIdArray.push(item.id)
    }else{
      this.listId = event.target.value
      this.selectedChannelData = item
    }
    console.log(this.listId)
    }

    redirectToDetailPage(data){
      if (data.status === 'DRAFT' && this.clickFrom === 'channelTab' ){
        this.router.navigate(['/channel-module/myChannel/add-channel/' + data.id], { queryParams: { mobile: true, 'redirectId': this.mobileTabId, channelSidePanel: true } })
      }
      else if(data.status === 'DRAFT' && this.clickFrom === 'tvApp' ){
      this.router.navigate(['/channel-module/myChannel/add-channel/' + data.id], { queryParams: { tvApp: true, 'redirectId': this.mobileTabId, channelSidePanel: true } })

      }
     
    }


  dblClickSelectNetwork(item) {
    this.apply()
  }

}
