export const appSvgIcons = {
  dashboard: `
    <svg width="30" height="30" viewBox="0 0 15 11.785">
        <path class="a" d="M13.37,11.786H1.633a.512.512,0,0,1-.452-.242A7.485,7.485,0,0,1,.6,4.587,7.375,7.375,0,0,1,4.589.594a7.439,7.439,0,0,1,5.826,0,7.37,7.37,0,0,1,3.992,3.993,7.478,7.478,0,0,1-.586,6.956A.512.512,0,0,1,13.37,11.786ZM8.725,3.906a.512.512,0,0,0-.26.074.53.53,0,0,0-.251.331l-.846,3.2a1.6,1.6,0,0,0-.9.364,1.6,1.6,0,0,0-.36,2.047,1.544,1.544,0,0,0,.979.745,1.687,1.687,0,0,0,.423.056,1.578,1.578,0,0,0,.8-.223,1.609,1.609,0,0,0,.695-1.958,1.6,1.6,0,0,0-.6-.762l.845-3.2a.546.546,0,0,0-.526-.672Zm4.134,2.523a1.071,1.071,0,0,0,0,2.143,1.033,1.033,0,0,0,.753-.31A1.047,1.047,0,0,0,13.93,7.5a1.072,1.072,0,0,0-1.071-1.071Zm-10.714,0a1.037,1.037,0,0,0-.758.314,1.07,1.07,0,0,0,0,1.515,1.037,1.037,0,0,0,.758.314,1.033,1.033,0,0,0,.753-.31A1.047,1.047,0,0,0,3.216,7.5,1.033,1.033,0,0,0,2.9,6.743,1.033,1.033,0,0,0,2.145,6.429Zm9.107-3.75A1.072,1.072,0,0,0,10.18,3.75a1.071,1.071,0,1,0,1.83-.758A1.037,1.037,0,0,0,11.252,2.678Zm-7.5,0A1.072,1.072,0,0,0,2.68,3.75,1.072,1.072,0,0,0,3.752,4.821a1.033,1.033,0,0,0,.753-.31,1.046,1.046,0,0,0,.318-.761A1.072,1.072,0,0,0,3.752,2.678ZM7.5,1.071A1.071,1.071,0,0,0,6.43,2.143,1.069,1.069,0,0,0,7.5,3.214a1.027,1.027,0,0,0,.753-.31,1.041,1.041,0,0,0,.319-.761A1.072,1.072,0,0,0,7.5,1.071Z" transform="translate(-0.002)"/>
    </svg>`,
  media: `
    <svg width="30" height="30" viewBox="0 0 15 11">
        <g transform="translate(0.001 0)"><path class="a" d="M220.949,145.7l2.587-1.391-2.587-1.416Zm0,0" transform="translate(-214.534 -138.936)"/>
            <path class="a" d="M14.889,2.6V2.589A3.255,3.255,0,0,0,14.227.9,2.479,2.479,0,0,0,12.61.17L12.536.161l-.026,0C10.527.019,7.532,0,7.5,0H7.5c-.03,0-3.025.019-5.026.159l-.026,0L2.374.17A2.415,2.415,0,0,0,.767.926,3.413,3.413,0,0,0,.111,2.581l0,.015C.105,2.645,0,3.8,0,4.965V6.052c0,1.163.106,2.321.11,2.369v.007a3.224,3.224,0,0,0,.659,1.665,2.531,2.531,0,0,0,1.671.724c.061.007.114.013.149.019l.035,0c1.145.106,4.735.158,4.887.16H7.52c.03,0,3.025-.019,5.008-.159l.026,0,.084-.009a2.36,2.36,0,0,0,1.593-.738,3.413,3.413,0,0,0,.656-1.655l0-.015c0-.049.11-1.207.11-2.369V4.965c0-1.163-.106-2.32-.11-2.369ZM9.8,5.886,6.49,7.612a.621.621,0,0,1-.6-.013.581.581,0,0,1-.3-.506V3.612a.581.581,0,0,1,.3-.507.621.621,0,0,1,.6-.01L9.808,4.85a.581.581,0,0,1,0,1.036Zm0,0" transform="translate(0)"/>
        </g>
    </svg>`,
  liveStream: `<svg width="30" height="30" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5894 0.619697C13.2738 0.584884 12.99 0.812505 12.9551 1.12812C12.9205 1.44354 13.1481 1.72758 13.4638 1.7622C14.9454 1.92249 16.326 2.59063 17.3712 3.65316C18.4096 4.70194 19.069 6.06671 19.2452 7.5321C19.2746 7.82514 19.5219 8.04777 19.8163 8.04625H19.8793C20.0334 8.03076 20.1746 7.9531 20.2704 7.83145C20.3662 7.70979 20.4085 7.55428 20.3877 7.40069C20.184 5.67136 19.403 4.06118 18.1711 2.83049C16.9416 1.59118 15.3245 0.810982 13.5895 0.619716L13.5894 0.619697Z" fill="white"/>
    <path d="M13.6122 2.40758C13.2968 2.36033 13.0026 2.57762 12.9552 2.89323C12.9079 3.20865 13.1254 3.50282 13.4408 3.55008C14.45 3.70176 15.3837 4.17441 16.103 4.89839C16.8264 5.62104 17.3008 6.55563 17.4569 7.56631C17.4997 7.84788 17.7434 8.05503 18.0282 8.05178H18.1024C18.2528 8.02806 18.3873 7.94524 18.4762 7.82186C18.5651 7.69868 18.6013 7.5449 18.5766 7.39493C18.3827 6.14529 17.7958 4.99 16.9012 4.09635C16.0066 3.2027 14.8505 2.6172 13.6007 2.42476L13.6122 2.40758Z" fill="white"/>
    <path d="M16.1882 8.04618C16.2299 8.05173 16.2721 8.05173 16.3138 8.04618C16.6212 7.9779 16.8154 7.67396 16.748 7.36637C16.5728 6.61252 16.1897 5.92282 15.6425 5.37556C15.0952 4.82831 14.4054 4.44538 13.6516 4.26998C13.3362 4.20074 13.0241 4.40025 12.9546 4.71566C12.8854 5.03127 13.0849 5.34323 13.4003 5.41266C13.9422 5.54044 14.4384 5.8153 14.8343 6.20666C15.2307 6.60203 15.5078 7.1009 15.6341 7.6464C15.7098 7.8876 15.9353 8.05037 16.1882 8.04617L16.1882 8.04618Z" fill="white"/>
    <path d="M14.6683 7.46933C14.6683 8.10037 14.1568 8.61183 13.5258 8.61183C12.8948 8.61183 12.3833 8.10037 12.3833 7.46933C12.3833 6.83829 12.8948 6.32663 13.5258 6.32663C14.1568 6.32663 14.6683 6.83829 14.6683 7.46933Z" fill="white"/>
    <path d="M17.5312 16.6154V10.3314C17.5312 10.016 17.2752 9.7601 16.9598 9.7601C16.6444 9.7601 16.3885 10.016 16.3885 10.3314V16.6154C16.3885 16.9186 16.2682 17.2091 16.0539 17.4234C15.8397 17.6376 15.549 17.7581 15.246 17.7581H2.67758C2.3746 17.7581 2.08405 17.6376 1.86982 17.4234C1.6554 17.2091 1.53509 16.9186 1.53509 16.6154V6.3323C1.53509 6.02932 1.6554 5.73877 1.86982 5.52454C2.08405 5.31012 2.37462 5.1898 2.67758 5.1898H10.6759C10.9913 5.1898 11.247 4.93406 11.247 4.61845C11.247 4.30304 10.9913 4.0473 10.6759 4.0473H2.67758C2.07161 4.0473 1.49032 4.28793 1.06186 4.71659C0.633208 5.14505 0.392578 5.72634 0.392578 6.3323V16.6154C0.392578 17.2216 0.633208 17.8027 1.06186 18.2313C1.49032 18.6598 2.07161 18.9006 2.67758 18.9006H15.246C15.852 18.9006 16.4332 18.6598 16.8619 18.2313C17.2904 17.8027 17.5312 17.2216 17.5312 16.6154V16.6154Z" fill="white"/>
    <path d="M6.86574 8.33803C6.47286 8.56603 6.23127 8.98625 6.23147 9.44057V13.3425C6.23013 13.5659 6.28828 13.7857 6.3996 13.9794C6.51111 14.1732 6.67179 14.3339 6.86574 14.445C7.05913 14.5573 7.2789 14.6166 7.50271 14.6166C7.72631 14.6166 7.94609 14.5573 8.13967 14.445L11.5159 12.4968C11.7104 12.3859 11.8721 12.2256 11.9843 12.0318C12.0968 11.8383 12.1559 11.6183 12.1557 11.3943C12.1571 11.1701 12.0985 10.9498 11.9861 10.7558C11.8734 10.5621 11.7112 10.4018 11.5159 10.2918L8.13967 8.33808C7.9461 8.22561 7.72632 8.1665 7.50271 8.1665C7.27891 8.1665 7.05913 8.22555 6.86574 8.33803Z" fill="white"/>
    </svg>`,
  app: `
    <svg width="30" height="30" viewBox="0 0 15.5 14.208">
        <g transform="translate(0 -1)">
            <path class="a" d="M13.2,11.229H5.13A1.131,1.131,0,0,1,4,10.1V9.13A1.131,1.131,0,0,1,5.13,8H13.2a1.131,1.131,0,0,1,1.13,1.13V10.1A1.131,1.131,0,0,1,13.2,11.229Z" transform="translate(-1.417 -2.479)"/>
            <path class="a" d="M17.776,18.229h-.969A.808.808,0,0,1,16,17.422V15.807A.808.808,0,0,1,16.807,15h.969a.808.808,0,0,1,.807.807v1.615A.808.808,0,0,1,17.776,18.229Z" transform="translate(-5.667 -4.958)"/>
            <path class="a" d="M5.776,18.229H4.807A.808.808,0,0,1,4,17.422V15.807A.808.808,0,0,1,4.807,15h.969a.808.808,0,0,1,.807.807v1.615A.808.808,0,0,1,5.776,18.229Z" transform="translate(-1.417 -4.958)"/>
            <path class="a" d="M11.776,18.229h-.969A.808.808,0,0,1,10,17.422V15.807A.808.808,0,0,1,10.807,15h.969a.808.808,0,0,1,.807.807v1.615A.808.808,0,0,1,11.776,18.229Z" transform="translate(-3.542 -4.958)"/>
            <path class="a" d="M13.563,1H1.938A1.94,1.94,0,0,0,0,2.938V13.271a1.94,1.94,0,0,0,1.938,1.938H13.563A1.94,1.94,0,0,0,15.5,13.271V2.938A1.94,1.94,0,0,0,13.563,1Zm0,12.917H1.938a.646.646,0,0,1-.646-.646V4.229H14.208v9.042A.646.646,0,0,1,13.563,13.917Z"/>
        </g>
    </svg>`,
  web: `
    <svg width="30" height="30" viewBox="0 0 15.667 14.443">
        <path class="a" d="M13.831,0H1.836A1.838,1.838,0,0,0,0,1.836V12.607a1.838,1.838,0,0,0,1.836,1.836H13.831a1.838,1.838,0,0,0,1.836-1.836V1.836A1.838,1.838,0,0,0,13.831,0ZM1.836,1.224H13.831a.613.613,0,0,1,.612.612V3.672H1.224V1.836A.613.613,0,0,1,1.836,1.224ZM13.831,13.219H1.836a.613.613,0,0,1-.612-.612V4.9H14.443v7.711A.613.613,0,0,1,13.831,13.219ZM2.142,2.448a.612.612,0,1,1,.612.612A.612.612,0,0,1,2.142,2.448Zm2.142,0A.612.612,0,1,1,4.9,3.06.612.612,0,0,1,4.284,2.448ZM2.448,11.995H7.221V6.12H2.448ZM3.672,7.344H6v3.427H3.672Zm4.773,1.1h4.773V9.669H8.445Zm0,2.326h4.773v1.224H8.445Zm0-4.651h4.773V7.344H8.445Zm0,0"/>
    </svg>`,
  event: `
    <svg width="30" height="30" viewBox="0 0 15.093 15.333">
        <g transform="translate(-0.977)"><g transform="translate(0.977)">
            <path class="a" d="M39.765,54.505l-.235,1.4a.509.509,0,0,0,.738.537l1.256-.656,1.256.656a.51.51,0,0,0,.739-.537l-.235-1.4,1.012-.992a.51.51,0,0,0-.282-.869l-1.4-.208-.631-1.269a.51.51,0,0,0-.913,0l-.631,1.269-1.4.208a.51.51,0,0,0-.282.869Z" transform="translate(-33.978 -44.634)"/>
            <path class="a" d="M14.548,1.63H12.943V.74A.74.74,0,0,0,12.2,0h-.117a.74.74,0,0,0-.74.74v.89H5.683V.74A.74.74,0,0,0,4.943,0H4.826a.74.74,0,0,0-.74.74v.89H2.5A1.524,1.524,0,0,0,.977,3.153V13.811A1.525,1.525,0,0,0,2.5,15.333H14.548a1.525,1.525,0,0,0,1.523-1.523V3.153A1.525,1.525,0,0,0,14.548,1.63Zm-.2,11.982H2.7v-8.3H14.349Z" transform="translate(-0.977)"/>
        </g>
    </svg>`,
  giving: `
    <svg width="30" height="30" viewBox="0 0 15.51 15.5">
        <g transform="translate(0.001 -0.168)">
            <g transform="translate(-0.001 8.613)">
                <g transform="translate(0)">
                    <path class="a" d="M13.956,280.962H15.51v-1.23H14.345l-2.46-1.23H6.448a.612.612,0,0,0-.647.647v.647a1.964,1.964,0,0,0,1.036,1.748l-.453.194L2.564,280.7A1.965,1.965,0,0,0,.234,282l-.194.777a.673.673,0,0,0,.388.841l5.825,1.942h.259l7.7-.647H15.51v-1.295H14.15l-7.638.647L1.464,282.58c.129-.388.388-.712.841-.583l4.013.971h.388l3.236-1.295a.66.66,0,0,0-.259-1.295H7.742a.611.611,0,0,1-.647-.647h4.4Z" transform="translate(0.001 -278.502)"/>
                </g>
            </g>
            <g transform="translate(3.466 0.168)">
                <g transform="translate(0 0)">
                    <path class="a" d="M121.914.91a2.644,2.644,0,0,0-3.366-.259,2.637,2.637,0,0,0-3.3.259,3.332,3.332,0,0,0-.453,4.142,9.558,9.558,0,0,0,3.5,2.783.9.9,0,0,0,.583,0,10.12,10.12,0,0,0,3.5-2.783A3.332,3.332,0,0,0,121.914.91ZM121.2,4.276a10.971,10.971,0,0,1-2.654,2.265,8.163,8.163,0,0,1-2.719-2.2,1.968,1.968,0,0,1,.324-2.524,1.363,1.363,0,0,1,.841-.324,1.861,1.861,0,0,1,1.165.453.591.591,0,0,0,.777,0c.777-.583,1.489-.647,2.007-.129A1.959,1.959,0,0,1,121.2,4.276Z" transform="translate(-114.272 -0.168)"/>
                </g>
            </g>
        </g>
    </svg>`,
  messaging: `
    <svg width="30" height="30" viewBox="0 0 16.333 12.415">
        <path class="a" d="M11.328,59.5H1.581A1.582,1.582,0,0,0,0,61.077v5.664a1.582,1.582,0,0,0,1.581,1.581H3l.03,1.427a.527.527,0,0,0,.844.41L6.31,68.321h5.018a1.582,1.582,0,0,0,1.581-1.581V61.077A1.582,1.582,0,0,0,11.328,59.5Zm-3.063,6.29H4.643a.527.527,0,0,1,0-1.054H8.265a.527.527,0,0,1,0,1.054Zm.955-2.305H3.688a.527.527,0,1,1,0-1.054H9.22a.527.527,0,1,1,0,1.054Zm7.113.889v4.347A1.581,1.581,0,0,1,14.753,70.3H13.332L13.3,71.4a.528.528,0,0,1-.527.513c-.208,0-.056.06-2.729-1.614H7.739a1.574,1.574,0,0,1-1.05-.4.527.527,0,0,1,.35-.921h4.794a1.851,1.851,0,0,0,1.851-1.851V63.316a.527.527,0,0,1,.527-.527h.543a1.581,1.581,0,0,1,1.581,1.581Z" transform="translate(0 -59.496)"/>
    </svg>`,
  library: `
    <svg width="30" height="30" viewBox="0 0 20.172 16.499">
        <g transform="translate(0.499 -48.553)">
            <g transform="translate(0.002 49.054)">
                <g transform="translate(0)">
                    <path class="a" d="M18.182,55.121a3.389,3.389,0,0,0-2.065-.991,3.29,3.29,0,0,0-5.443-2.782,4.44,4.44,0,0,0-4.02-2.292c-.055,0-.112,0-.175.009a.374.374,0,1,0,.053.747l.146-.008a3.685,3.685,0,0,1,3.541,2.332.374.374,0,0,0,.641.1A2.541,2.541,0,0,1,15.316,54.4a.377.377,0,0,0,.115.364.445.445,0,0,0,.327.094,2.667,2.667,0,0,1,2.668,2.688A2.694,2.694,0,0,1,15.719,60.2H15.7V57.313h0a.374.374,0,0,0-.374-.374H14.081v-.913a.374.374,0,0,0-.374-.374H10.835a1.7,1.7,0,0,0-1.257.554,1.7,1.7,0,0,0-1.257-.554H5.449a.374.374,0,0,0-.374.374v.913H3.811a.374.374,0,0,0-.374.374h0V60.2a2.711,2.711,0,0,1-1.917-.816A2.623,2.623,0,0,1,.751,57.49,2.668,2.668,0,0,1,2.95,54.9a.374.374,0,0,0,.3-.466A3.693,3.693,0,0,1,3.124,53.3a3.634,3.634,0,0,1,.826-2.147.374.374,0,1,0-.58-.474,4.378,4.378,0,0,0-.994,2.587,4.464,4.464,0,0,0,.063.985A3.419,3.419,0,0,0,0,57.48,3.367,3.367,0,0,0,.986,59.9a3.455,3.455,0,0,0,2.45,1.039v3.234a.374.374,0,0,0,.374.374H15.327a.374.374,0,0,0,.374-.374V60.944h.018a3.445,3.445,0,0,0,3.455-3.39,3.393,3.393,0,0,0-.991-2.433ZM10.835,56.4h2.5v5.856h-2.5a1.7,1.7,0,0,0-.883.247V56.915l.162-.186a.954.954,0,0,1,.721-.329Zm-2.514,0a.955.955,0,0,1,.721.329l.161.186V62.5a1.7,1.7,0,0,0-.883-.247h-2.5V56.4ZM4.185,57.688h.889v4.943a.374.374,0,0,0,.374.374H8.321a.963.963,0,0,1,.721.329l.162.187V63.8H4.185ZM14.952,63.8h-5v-.283l.163-.188a.957.957,0,0,1,.719-.327h2.872a.374.374,0,0,0,.374-.374V57.688h.871Z" transform="translate(-0.002 -49.054)"/><path class="a" d="M122.12,61.581a.381.381,0,1,0,.206-.5A.385.385,0,0,0,122.12,61.581Z" transform="translate(-117.523 -60.598)"/>
                    <path class="b" d="M288.148,289.482h1.08a.381.381,0,0,0,0-.762h-1.08a.381.381,0,1,0,0,.762Z" transform="translate(-277.01 -279.753)"/>
                    <path class="b" d="M288.148,338.386h1.08a.381.381,0,0,0,0-.762h-1.08a.381.381,0,1,0,0,.762Z" transform="translate(-277.01 -326.828)"/>
                    <path class="b" d="M178.257,289.482H179.3a.381.381,0,1,0,0-.762h-1.042a.381.381,0,0,0,0,.762Z" transform="translate(-171.227 -279.753)"/>
                    <path class="b" d="M178.257,338.386H179.3a.381.381,0,1,0,0-.762h-1.042a.381.381,0,0,0,0,.762Z" transform="translate(-171.227 -326.828)"/>
                </g>
            </g>
        </g>
    </svg>`,
  setting: `
    <svg width="30" height="30" viewBox="0 0 16 15.935">
        <g transform="translate(0 -0.114)">
            <g transform="translate(0 4.63)">
                <g transform="translate(0 0)">
                    <path class="a" d="M10.521,21.978a4.789,4.789,0,0,0-.03-1.158l.877-.391a5.776,5.776,0,0,0-1.059-2.378l-.877.391a4.8,4.8,0,0,0-.84-.8l.344-.9a5.8,5.8,0,0,0-2.43-.934l-.344.9A4.789,4.789,0,0,0,5,16.743l-.389-.873A5.8,5.8,0,0,0,2.24,16.936l.386.867a4.8,4.8,0,0,0-.8.84L.934,18.3a5.7,5.7,0,0,0-.608,1.161A5.866,5.866,0,0,0,0,20.728l.9.344a4.789,4.789,0,0,0,.03,1.158L.05,22.62A5.771,5.771,0,0,0,1.109,25l.877-.391a4.788,4.788,0,0,0,.84.8l-.344.9a5.8,5.8,0,0,0,2.43.934l.344-.9a4.8,4.8,0,0,0,1.158-.031l.389.872a5.8,5.8,0,0,0,2.376-1.065l-.386-.867a4.8,4.8,0,0,0,.8-.84l.893.343a5.7,5.7,0,0,0,.608-1.161,5.8,5.8,0,0,0,.328-1.268Zm-2.382.479a2.6,2.6,0,1,1-1.5-3.363A2.6,2.6,0,0,1,8.139,22.458Z" transform="translate(0 -15.816)"/>
                </g>
            </g>
            <g transform="translate(9.175 0.114)">
                <path class="a" d="M38.1,3.646a2.8,2.8,0,0,0-.048-.509l.672-.388A3.528,3.528,0,0,0,37.8,1.138l-.674.389a2.769,2.769,0,0,0-.881-.508V.242a3.439,3.439,0,0,0-1.859,0V1.02a2.778,2.778,0,0,0-.881.508l-.674-.389A3.535,3.535,0,0,0,31.9,2.749l.672.388a2.687,2.687,0,0,0,0,1.018l-.672.388a3.534,3.534,0,0,0,.928,1.611l.674-.389a2.772,2.772,0,0,0,.881.508V7.05a3.439,3.439,0,0,0,1.859,0V6.273a2.775,2.775,0,0,0,.881-.508l.674.389a3.528,3.528,0,0,0,.928-1.611l-.672-.388A2.78,2.78,0,0,0,38.1,3.646Zm-2.788,1.3a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,35.316,4.948Z" transform="translate(-31.903 -0.114)"/>
            </g>
        </g>
    </svg>`,

  request: `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
  <g id="change-request-svgrepo-com" transform="translate(-2 -2)">
    <path id="Path_18867" data-name="Path 18867" d="M16.267,8.667h2.667a.642.642,0,0,0,.667-.583V6h9.333V8a.642.642,0,0,0,.583.667h2.75a.642.642,0,0,0,.667-.583V6a4.058,4.058,0,0,0-4-4H19.6a4.058,4.058,0,0,0-4,4V8a.658.658,0,0,0,.667.667Z" transform="translate(-2.267)" fill="#fff"/>
    <path id="Path_18868" data-name="Path 18868" d="M38,14.8H6a4.058,4.058,0,0,0-4,4V40.133a4.058,4.058,0,0,0,4,4H38a4.058,4.058,0,0,0,4-4V18.8a4.058,4.058,0,0,0-4-4ZM19.833,29.883l-5.083,5.25a.9.9,0,0,1-.917,0l-5.167-5.25c-.417-.333-.083-.917.583-.917h3.167A10,10,0,0,1,22.25,18.8h.333v3.833a6.825,6.825,0,0,0-6.417,6.333h3C19.833,28.967,20.167,29.55,19.833,29.883Zm16.167,0H32.833A10.133,10.133,0,0,1,22.75,40.05H22.5V36.217c3.833,0,6.5-2.5,6.5-6.333H25.917c-.667,0-.917-.5-.583-.917l5.167-5.25a.9.9,0,0,1,.917,0l5.167,5.25c.333.333,0,.917-.583.917Z" transform="translate(0 -2.133)" fill="#fff"/>
  </g>
</svg>
    `,

  help: `
    <svg width="16.572" height="16.572" viewBox="0 0 16.572 16.572">
        <circle class="a" cx="0.809" cy="0.809" r="0.809" transform="translate(7.477 11.442)"/>
        <path class="a" d="M8.286,0a8.286,8.286,0,1,0,8.286,8.286A8.282,8.282,0,0,0,8.286,0Zm0,15.278a6.991,6.991,0,1,1,6.991-6.991A6.988,6.988,0,0,1,8.286,15.278Z"/>
        <path class="a" d="M178.589,128.5A2.592,2.592,0,0,0,176,131.089a.647.647,0,1,0,1.295,0,1.295,1.295,0,1,1,1.295,1.295.647.647,0,0,0-.647.647v1.618a.647.647,0,0,0,1.295,0V133.6a2.59,2.59,0,0,0-.647-5.1Z" transform="translate(-170.303 -124.341)"/>
    </svg>`,
  whatsNew: `
    <svg width="18.739" height="14.994" viewBox="0 0 18.739 14.994">
        <g transform="translate(0.5 -2.491)">
            <path class="a" d="M11.98,3.04,3.737,6.68H1.5A1.586,1.586,0,0,0,0,8.336v2.024a1.422,1.422,0,0,0,1.5,1.472h.567l.605,4.678a.519.519,0,0,0,.495.474h2A.482.482,0,0,0,5.54,16.8a.591.591,0,0,0,.124-.43L5.27,12.51l6.709,2.963a.463.463,0,0,0,.186.04.471.471,0,0,0,.281-.095.57.57,0,0,0,.219-.457V3.552a.57.57,0,0,0-.219-.457A.459.459,0,0,0,11.98,3.04ZM1,10.361V8.336a.529.529,0,0,1,.5-.552H3.333v2.944H1.5C1.272,10.729,1,10.665,1,10.361Zm3.607,5.521H3.6l-.523-4.048h.661l.481.212Zm7.059-1.735-6.783-3a.473.473,0,0,0-.087-.039l-.462-.2v-3.3l7.333-3.239Z" transform="translate(0 0)"/>
            <path class="a" d="M21.219,9.336l2.5-2a.751.751,0,1,0-.938-1.172l-2.5,2a.751.751,0,0,0,.938,1.172Z" transform="translate(-6.334 -0.971)"/>
            <path class="a" d="M21.219,14.664a.751.751,0,1,0-.938,1.172l2.5,2a.751.751,0,0,0,.938-1.172Z" transform="translate(-6.334 -3.721)"/>
            <path class="a" d="M23.25,11.25h-2.5a.75.75,0,0,0,0,1.5h2.5a.75.75,0,0,0,0-1.5Z" transform="translate(-6.334 -2.364)"/>
        </g>
    </svg>`,
  contact: `
    <svg width="30" height="30" viewBox="0 0 20.334 17.031">
        <g transform="translate(0.5 -43.229)">
            <g transform="translate(0 50.751)">
                <path class="a" d="M16.5,231.133H14.609a2.824,2.824,0,0,0-.744.1,2.835,2.835,0,0,0-2.525-1.553H7.994a2.835,2.835,0,0,0-2.525,1.553,2.824,2.824,0,0,0-.744-.1H2.832A2.835,2.835,0,0,0,0,233.965v3.025a1.7,1.7,0,0,0,1.7,1.7H17.635a1.7,1.7,0,0,0,1.7-1.7v-3.025A2.835,2.835,0,0,0,16.5,231.133Zm-11.34,1.379v5.045H1.7a.567.567,0,0,1-.566-.566v-3.025a1.7,1.7,0,0,1,1.7-1.7H4.725a1.694,1.694,0,0,1,.444.059C5.165,232.387,5.162,232.449,5.162,232.512Zm7.877,5.045H6.3v-5.045a1.7,1.7,0,0,1,1.7-1.7H11.34a1.7,1.7,0,0,1,1.7,1.7Zm5.162-.566a.567.567,0,0,1-.566.566H14.172v-5.045c0-.063,0-.125-.007-.187a1.7,1.7,0,0,1,.444-.059H16.5a1.7,1.7,0,0,1,1.7,1.7Z" transform="translate(0 -229.68)"/>
            </g>
            <g transform="translate(1.262 46.855)">
                <g transform="translate(0 0)">
                    <path class="a" d="M35.931,126.5a2.517,2.517,0,1,0,2.517,2.517A2.52,2.52,0,0,0,35.931,126.5Zm0,3.9a1.384,1.384,0,1,1,1.384-1.384A1.385,1.385,0,0,1,35.931,130.4Z" transform="translate(-33.414 -126.504)"/>
                </g>
            </g>
            <g transform="translate(6.305 43.729)">
                <g transform="translate(0 0)">
                    <path class="a" d="M170.324,43.729a3.362,3.362,0,1,0,3.362,3.362A3.366,3.366,0,0,0,170.324,43.729Zm0,5.592a2.229,2.229,0,1,1,2.229-2.229A2.232,2.232,0,0,1,170.324,49.321Z" transform="translate(-166.962 -43.729)"/>
                </g>
            </g>
            <g transform="translate(13.039 46.855)">
                <g transform="translate(0 0)">
                    <path class="a" d="M347.811,126.5a2.517,2.517,0,1,0,2.517,2.517A2.52,2.52,0,0,0,347.811,126.5Zm0,3.9a1.384,1.384,0,1,1,1.384-1.384A1.385,1.385,0,0,1,347.811,130.4Z" transform="translate(-345.294 -126.504)"/>
                </g>
            </g>
        </g>
    </svg>`,
  subscription: `
    <svg width="30" height="30" viewBox="0 0 20.108 19.188">
        <g transform="translate(-1 -0.004)">
            <path class="a" d="M18.353,247.219a.578.578,0,0,0-.636.524,7.99,7.99,0,0,1-7.805,7.232,7.667,7.667,0,0,1-5.018-1.891,8.292,8.292,0,0,1-2.559-4.129l.684.393a.559.559,0,0,0,.777-.237.613.613,0,0,0-.226-.813l-1.756-1.009a.559.559,0,0,0-.777.236L.071,249.36a.614.614,0,0,0,.225.813.558.558,0,0,0,.777-.236l.225-.427a9.488,9.488,0,0,0,2.865,4.495,8.783,8.783,0,0,0,5.748,2.167,9.155,9.155,0,0,0,8.942-8.288.593.593,0,0,0-.5-.665Zm0,0" transform="translate(1 -236.98)"/>
            <path class="a" d="M47.831,5.471a.6.6,0,0,0-.8.334l-.192.467a10.114,10.114,0,0,0-2.964-4.116A9.256,9.256,0,0,0,37.927,0a9.376,9.376,0,0,0-6.1,2.28,10.181,10.181,0,0,0-3.314,5.7.613.613,0,1,0,1.2.264A8.887,8.887,0,0,1,32.6,3.274a8.185,8.185,0,0,1,5.322-1.99,8.08,8.08,0,0,1,5.189,1.878A8.842,8.842,0,0,1,45.792,6.99l-.774-.349a.6.6,0,0,0-.8.335.652.652,0,0,0,.32.841L46.5,8.7a.569.569,0,0,0,.8-.335l.846-2.057a.652.652,0,0,0-.319-.841Zm0,0" transform="translate(-27.092)"/>
            <path class="a" d="M154.15,89.629a.592.592,0,0,0-.611.571v.229A2.259,2.259,0,0,0,155.87,92.6h.254V93.62a.613.613,0,0,0,1.222,0V92.6h.735V93.62a.613.613,0,0,0,1.222,0V92.6h.254a2.259,2.259,0,0,0,2.331-2.175V90.1a1.944,1.944,0,0,0-1.5-1.842l-1.087-.283v-2.8h.254a1.074,1.074,0,0,1,1.108,1.033V86.3a.612.612,0,0,0,1.222,0V86.2a2.259,2.259,0,0,0-2.33-2.175H159.3v-.935a.612.612,0,0,0-1.222,0v.935h-.735v-.935a.613.613,0,0,0-1.222,0v.935h-.254a2.259,2.259,0,0,0-2.33,2.175,1.944,1.944,0,0,0,1.5,1.842l1.087.283v3.133h-.254a1.074,1.074,0,0,1-1.108-1.033V90.2A.592.592,0,0,0,154.15,89.629Zm5.911-.273a.783.783,0,0,1,.6.742v.331a1.074,1.074,0,0,1-1.108,1.033H159.3v-2.3Zm-1.981-4.185v2.483l-.735-.192V85.171Zm-2.715,1.775a.783.783,0,0,1-.6-.742,1.074,1.074,0,0,1,1.108-1.033h.254v1.973Zm1.981,1.7.735.191v2.623h-.735Zm0,0" transform="translate(-146.485 -78.437)"/>
        </g>
    </svg>`,
  form: `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20.959 21"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-0.5)"><path class="a" d="M18.362,14.807a3.078,3.078,0,0,0-1.71.517l-1.369-1.369A5.524,5.524,0,0,0,15.3,7.069l1.376-1.376a3.1,3.1,0,1,0-.879-.861L14.436,6.194A5.523,5.523,0,0,0,7.5,6.176L6.161,4.832a3.1,3.1,0,1,0-.879.861L6.635,7.046A5.524,5.524,0,0,0,6.671,14l-1.34,1.34a3.1,3.1,0,1,0,.861.879L7.55,14.861a5.524,5.524,0,0,0,6.863-.036l1.369,1.369a3.1,3.1,0,1,0,2.58-1.387Zm0-13.576A1.866,1.866,0,1,1,16.5,3.1,1.868,1.868,0,0,1,18.362,1.23ZM1.73,3.1A1.866,1.866,0,1,1,3.6,4.963,1.868,1.868,0,0,1,1.73,3.1ZM7.9,7.47l.015-.013.013-.015a4.3,4.3,0,1,1,5.488,6.591v-.457a2.461,2.461,0,1,0-4.922,0v.455A4.3,4.3,0,0,1,7.9,7.47ZM3.6,19.77A1.866,1.866,0,1,1,5.463,17.9,1.868,1.868,0,0,1,3.6,19.77Zm6.13-5.143v-1.05a1.23,1.23,0,1,1,2.461,0v1.052A4.309,4.309,0,0,1,9.726,14.627Zm8.636,5.143A1.866,1.866,0,1,1,20.229,17.9,1.868,1.868,0,0,1,18.362,19.77Z" transform="translate(0 0)"/><path class="a" d="M212.437,184.875a1.937,1.937,0,1,0-1.937-1.937A1.94,1.94,0,0,0,212.437,184.875Zm0-2.583a.646.646,0,1,1-.646.646A.647.647,0,0,1,212.437,182.292Z" transform="translate(-201.478 -173.655)"/></g></svg>`,
  hamburger: `
    <svg fill="none" height="25" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="25">
        <line x1="3" x2="21" y1="12" y2="12" /><line x1="3" x2="21" y1="6" y2="6" /><line x1="3" x2="21" y1="18" y2="18" />
    </svg>`,
  phone: `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/> </svg>
    `,
  security: `
    <svg width="19" height="19" viewBox="0 0 19 19">
        <path class="a" d="M16.822,17H15.39a.58.58,0,1,1,0-1.159h1.433a.979.979,0,0,0,.99-.966V2.125a.979.979,0,0,0-.99-.966H2.177a.979.979,0,0,0-.99.966v12.75a.979.979,0,0,0,.99.966H3.61A.58.58,0,1,1,3.61,17H2.177A2.153,2.153,0,0,1,0,14.875V2.125A2.153,2.153,0,0,1,2.177,0H16.822A2.153,2.153,0,0,1,19,2.125v12.75A2.153,2.153,0,0,1,16.822,17Z"/>
        <path class="a" d="M18.406,5H.594A.686.686,0,0,1,0,4.25.686.686,0,0,1,.594,3.5H18.406A.686.686,0,0,1,19,4.25.686.686,0,0,1,18.406,5Z"/>
        <path class="a" d="M12,24a.746.746,0,0,1-.293-.06C11.474,23.841,6,21.458,6,16.554V12.536a.75.75,0,0,1,.509-.71l5.25-1.786a.737.737,0,0,1,.482,0l5.25,1.786a.748.748,0,0,1,.509.71v4.018c0,4.9-5.474,7.288-5.707,7.387A.763.763,0,0,1,12,24ZM7.5,13.073v3.481C7.5,19.9,10.955,21.9,12,22.42c1.042-.528,4.5-2.531,4.5-5.866V13.073L12,11.542Z" transform="translate(-2.5 -5)"/>
        <path class="a" d="M11.75,19.5a.749.749,0,0,1-.53-.22l-2-2a.75.75,0,1,1,1.061-1.061l1.389,1.389L14.15,14.3a.75.75,0,1,1,1.2.9l-3,4a.746.746,0,0,1-.547.3.443.443,0,0,1-.052,0Z" transform="translate(-2.572 -3.784)"/>
    </svg>`,
  onboardingUser: `
    <svg width="17.204" height="18.705" viewBox="0 0 17.204 18.705">
        <g transform="translate(-6.666 1.674)">
            <path class="a" d="M279.4,152a2.47,2.47,0,0,0-2.467,2.467v2.588a2.453,2.453,0,0,0-.987-.206h0a2.452,2.452,0,0,0-1.744.723l-2.2,2.2v4.068h4.935v-.8l2.961-4.187V152Zm-.493,6.547-3.047,4.309h-2.875v-2.672l1.914-1.914a1.483,1.483,0,0,1,1.685-.29l-2.206,2.206.7.7,2.844-2.844v-3.574a1.483,1.483,0,0,1,.987-1.4Zm0,0" transform="translate(-256.025 -147.094)"/>
            <path class="a" d="M5.828,157.706a2.51,2.51,0,0,0-1.786-.74h0a2.511,2.511,0,0,0-1.01.211v-2.65A2.529,2.529,0,0,0,.505,152H0v7.024l3.031,4.287v.814H8.083v-4.164Zm1.245,5.41H4.13L1.01,158.7V153.1a1.518,1.518,0,0,1,1.01,1.429v3.659L4.932,161.1l.714-.714-2.259-2.259a1.508,1.508,0,0,1,.654-.147h0a1.505,1.505,0,0,1,1.071.444l1.96,1.96Zm0,0" transform="translate(6.667 -147.094)"/>
            <path class="a" d="M186.334,2.667a2.667,2.667,0,1,0-2.667,2.667A2.67,2.67,0,0,0,186.334,2.667Zm-2.667,1.6a1.6,1.6,0,1,1,1.6-1.6A1.6,1.6,0,0,1,183.667,4.267Zm0,0" transform="translate(-168.735 -1.674)"/>
            <path class="a" d="M157.915,153.457a3.457,3.457,0,1,0-6.915,0v1.482h6.915Zm-.988.494h-4.939v-.494a2.47,2.47,0,1,1,4.939,0Zm0,0" transform="translate(-139.493 -145.539)"/>
        </g>
    </svg>`,
  transcoding: `
 <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.861 21.746C41.3207 17.4702 39.265 13.53 36.0672 10.6408C32.8693 7.75163 28.7414 6.10501 24.433 5.99997L26.739 3.70997C26.9273 3.52312 27.0337 3.26913 27.0347 3.00386C27.0357 2.73858 26.9313 2.48377 26.7445 2.29547C26.5576 2.10716 26.3037 2.00079 26.0384 1.99976C25.7731 1.99873 25.5183 2.10312 25.33 2.28997L21.168 6.41897C20.9797 6.6057 20.8733 6.85956 20.8722 7.12472C20.8711 7.38989 20.9753 7.64464 21.162 7.83297L25.324 12.033C25.4159 12.1288 25.5259 12.2054 25.6476 12.2582C25.7694 12.3111 25.9005 12.3392 26.0332 12.3409C26.1659 12.3425 26.2977 12.3178 26.4207 12.268C26.5438 12.2183 26.6557 12.1445 26.75 12.051C26.8442 11.9576 26.9189 11.8463 26.9697 11.7236C27.0205 11.601 27.0464 11.4695 27.0458 11.3367C27.0453 11.204 27.0183 11.0727 26.9665 10.9505C26.9147 10.8283 26.839 10.7176 26.744 10.625L24.138 7.99997C26.758 8.02428 29.3319 8.69115 31.6341 9.94207C33.9363 11.193 35.8963 12.9897 37.3423 15.1747C38.7882 17.3597 39.6759 19.8661 39.9275 22.4741C40.179 25.0821 39.7867 27.7119 38.785 30.133C37.7477 32.6222 36.1025 34.8113 34 36.5C33.8975 36.5821 33.8122 36.6836 33.7489 36.7987C33.6857 36.9139 33.6457 37.0403 33.6313 37.1709C33.6169 37.3014 33.6284 37.4335 33.665 37.5597C33.7017 37.6858 33.7629 37.8035 33.845 37.906C33.9271 38.0085 34.0287 38.0938 34.1438 38.157C34.2589 38.2203 34.3853 38.2603 34.5159 38.2747C34.6465 38.2891 34.7786 38.2776 34.9047 38.2409C35.0308 38.2043 35.1485 38.1431 35.251 38.061C37.6571 36.1352 39.5285 33.6238 40.6859 30.7675C41.8433 27.9112 42.2479 24.8055 41.861 21.748V21.746Z" fill="white"/>
<path d="M22.676 35.97C22.5842 35.8742 22.4741 35.7976 22.3524 35.7447C22.2306 35.6919 22.0996 35.6638 21.9668 35.6621C21.8341 35.6604 21.7024 35.6852 21.5793 35.7349C21.4562 35.7847 21.3443 35.8584 21.2501 35.9519C21.1558 36.0454 21.0811 36.1567 21.0303 36.2793C20.9795 36.4019 20.9536 36.5335 20.9542 36.6662C20.9547 36.7989 20.9817 36.9302 21.0335 37.0524C21.0854 37.1746 21.161 37.2853 21.256 37.378L23.862 40.006C21.2415 39.9822 18.6668 39.3155 16.364 38.0644C14.0613 36.8133 12.1008 35.0161 10.6547 32.8305C9.20859 30.645 8.32109 28.1378 8.07008 25.5292C7.81906 22.9206 8.21222 20.2902 9.21503 17.869C10.2519 15.379 11.8972 13.1891 14 11.5C14.1057 11.4189 14.1942 11.3176 14.2602 11.2018C14.3262 11.0861 14.3684 10.9584 14.3843 10.8261C14.4003 10.6939 14.3896 10.5598 14.3531 10.4317C14.3165 10.3036 14.2546 10.1841 14.1712 10.0803C14.0878 9.9764 13.9845 9.89025 13.8673 9.82687C13.7502 9.76348 13.6215 9.72415 13.4889 9.71118C13.3564 9.6982 13.2225 9.71184 13.0953 9.75131C12.9681 9.79077 12.85 9.85525 12.748 9.94097C10.3419 11.8668 8.47049 14.3781 7.3131 17.2344C6.15572 20.0908 5.75111 23.1964 6.13803 26.254C6.67873 30.5297 8.73468 34.4698 11.9326 37.3589C15.1306 40.2481 19.2585 41.8948 23.567 42L21.261 44.29C21.0727 44.4768 20.9664 44.7308 20.9653 44.9961C20.9643 45.2614 21.0687 45.5162 21.2555 45.7045C21.4424 45.8928 21.6964 45.9991 21.9616 46.0002C22.2269 46.0012 22.4817 45.8968 22.67 45.71L26.832 41.581C27.0203 41.3942 27.1267 41.1404 27.1278 40.8752C27.1289 40.6101 27.0247 40.3553 26.838 40.167L22.676 35.97Z" fill="white"/>
<path d="M32.075 22.268L21 15.874C20.696 15.6985 20.3511 15.6061 20 15.6061C19.6489 15.6061 19.3041 15.6985 19 15.874C18.696 16.0495 18.4435 16.302 18.268 16.606C18.0924 16.9101 18 17.255 18 17.606V30.394C18 30.7451 18.0924 31.09 18.268 31.394C18.4435 31.698 18.696 31.9505 19 32.126C19.3041 32.3016 19.6489 32.394 20 32.394C20.3511 32.394 20.696 32.3016 21 32.126L32.075 25.732C32.379 25.5565 32.6315 25.304 32.807 25C32.9825 24.696 33.0749 24.3511 33.0749 24C33.0749 23.649 32.9825 23.3041 32.807 23.0001C32.6315 22.696 32.379 22.4436 32.075 22.268V22.268ZM20 30.394V17.606L31.075 24L20 30.394Z" fill="white"/>
</svg>`,
  accounting: `
    <svg width="15" height="19" viewBox="0 0 15 19">
        <defs><style>.acc-a{fill:none;stroke:#ced9e0;stroke-miterlimit:10;stroke-width:2px;}.acc-b{fill:#ced9e0;}</style></defs>
        <g transform="translate(-6 -2)">
            <rect class="acc-a" width="13" height="17" transform="translate(7 3)"/>
            <path class="acc-a" d="M16.131,13,11,13.1v-6L16.131,7Z" transform="translate(0 -1.394)"/>
            <rect class="acc-b" width="2" height="2" transform="translate(10 13)"/>
            <rect class="acc-b" width="2" height="2" transform="translate(15 13)"/>
            <rect class="acc-b" width="2" height="2" transform="translate(10 16)"/>
            <rect class="acc-b" width="2" height="2" transform="translate(15 16)"/>
        </g>
    </svg>
    `,
  edit: `
    <svg width="13.197" height="13.197" viewBox="0 0 13.197 13.197">
        <g transform="translate(0 -0.004)">
            <g transform="translate(0 0.946)">
                <path d="M10.84,39.793a.471.471,0,0,0-.471.471v4.713a.471.471,0,0,1-.471.471H1.414a.471.471,0,0,1-.471-.471V35.551a.471.471,0,0,1,.471-.471H7.07a.471.471,0,1,0,0-.943H1.414A1.414,1.414,0,0,0,0,35.551v9.426a1.414,1.414,0,0,0,1.414,1.414H9.9a1.414,1.414,0,0,0,1.414-1.414V40.264A.471.471,0,0,0,10.84,39.793Z" transform="translate(0 -34.137)"/>
            </g>
            <g transform="translate(2.828 0.004)">
                <g transform="translate(0 0)">
                    <path d="M112.249.532a1.8,1.8,0,0,0-2.552,0L103.489,6.74a.476.476,0,0,0-.114.184l-.943,2.828a.471.471,0,0,0,.447.62.479.479,0,0,0,.149-.024l2.828-.943a.471.471,0,0,0,.184-.114l6.208-6.208A1.8,1.8,0,0,0,112.249.532Zm-.667,1.886-6.127,6.127-1.831.611.609-1.828L110.364,1.2a.861.861,0,1,1,1.219,1.217Z" transform="translate(-102.409 -0.004)"/>
                </g>
            </g>
        </g>
    </svg>
    `,
  edit2: `
    <svg width="24" height="24" viewBox="0 0 13.197 13.197">
        <g transform="translate(0 -0.004)">
            <g transform="translate(0 0.946)">
                <path d="M10.84,39.793a.471.471,0,0,0-.471.471v4.713a.471.471,0,0,1-.471.471H1.414a.471.471,0,0,1-.471-.471V35.551a.471.471,0,0,1,.471-.471H7.07a.471.471,0,1,0,0-.943H1.414A1.414,1.414,0,0,0,0,35.551v9.426a1.414,1.414,0,0,0,1.414,1.414H9.9a1.414,1.414,0,0,0,1.414-1.414V40.264A.471.471,0,0,0,10.84,39.793Z" transform="translate(0 -34.137)"/>
            </g>
            <g transform="translate(2.828 0.004)">
                <g transform="translate(0 0)">
                    <path d="M112.249.532a1.8,1.8,0,0,0-2.552,0L103.489,6.74a.476.476,0,0,0-.114.184l-.943,2.828a.471.471,0,0,0,.447.62.479.479,0,0,0,.149-.024l2.828-.943a.471.471,0,0,0,.184-.114l6.208-6.208A1.8,1.8,0,0,0,112.249.532Zm-.667,1.886-6.127,6.127-1.831.611.609-1.828L110.364,1.2a.861.861,0,1,1,1.219,1.217Z" transform="translate(-102.409 -0.004)"/>
                </g>
            </g>
        </g>
    </svg>
    `,
  delete: `
    <svg width="13.068" height="14" viewBox="0 0 13.068 14">
        <path d="M18.812,2.035H15.271V0H9.285V2.035H5.744V3.252h1.2L7.757,14H16.8l.811-10.748h1.2V2.035ZM10.5,1.217h3.551v.818H10.5Zm5.168,11.566H8.885l-.72-9.531H16.39Z" transform="translate(-5.744)"/>
        <rect width="0.974" height="5.374" transform="translate(6.047 5.33)"/>
        <rect width="0.974" height="5.374" transform="translate(4.181 5.33)"/>
        <rect width="0.974" height="5.374" transform="translate(7.913 5.33)"/>
    </svg>
    `,
  upload: `
    <svg width="31" height="31" viewBox="0 0 31 31">
        <path d="M15.5,0A15.5,15.5,0,1,0,31,15.5,15.5,15.5,0,0,0,15.5,0Zm0,28.417A12.917,12.917,0,1,1,28.417,15.5,12.917,12.917,0,0,1,15.5,28.417Z"/>
        <path d="M156.708,107.048h0q-.045-.044-.093-.084c-.015-.012-.031-.023-.046-.035s-.036-.028-.055-.041-.037-.023-.056-.034l-.052-.031-.059-.028-.056-.026-.058-.021-.061-.021-.059-.015-.064-.016c-.023,0-.046-.007-.068-.01l-.056-.008a1.3,1.3,0,0,0-.255,0l-.056.008c-.023,0-.046.006-.068.01l-.064.016-.059.015-.061.021-.058.021-.056.026c-.02.009-.04.018-.059.028l-.052.031c-.019.011-.038.022-.056.034s-.037.027-.055.041-.031.022-.046.035q-.049.04-.093.084h0l-5.167,5.167a1.292,1.292,0,1,0,1.827,1.827l2.962-2.962v12.382a1.292,1.292,0,0,0,2.583,0V111.079l2.962,2.962a1.292,1.292,0,0,0,1.827-1.827Z" transform="translate(-140.295 -100.211)"/>
    </svg>
    `,
  resize: `
    <svg width="13.982" height="14" viewBox="0 0 13.982 14">
        <rect width="6.991" height="6.991" transform="translate(0 7)"/>
        <path d="M31.856,0H30.084V1.183H34.12v4.06H35.3V0Z" transform="translate(-21.321 0.009)"/>
        <path d="M34.113,33.083h.006v.986H30.084v1.174h1.432v.009H35.3V30H34.113Z" transform="translate(-21.321 -21.252)"/>
        <path d="M1.189,3.206H1.183V1.152H5.267V-.031H0V5.221H1.189Z" transform="translate(0 0.031)"/>
    </svg>
    `,
  duplicate: `
    <svg width="13.833" height="13.833" viewBox="0 0 13.833 13.833">
        <g transform="translate(2.515 2.515)">
            <path d="M85.333,85.333V96.651h7.545l3.773-3.773V85.333Zm7.545,9.538V92.878h1.993Zm2.515-3.251H91.621v3.773h-5.03v-8.8h8.8Z" transform="translate(-85.333 -85.333)"/>
        </g>
        <g transform="translate(5.03 5.659)">
            <rect width="1.258" height="1.258"/>
        </g>
        <g transform="translate(7.545 5.659)">
            <rect width="3.144" height="1.258"/>
        </g>
        <g transform="translate(5.03 8.174)">
            <rect width="2.515" height="1.258"/>
        </g>
        <path d="M0,0V10.06H2.515V8.822H1.258V1.258H8.174V2.515H9.432V0Z"/>
    </svg>
    `,
  draft: `
    <svg width="15.499" height="15.499" viewBox="0 0 15.499 15.499">
        <path d="M14.894,0H.605A.605.605,0,0,0,0,.605V14.894a.605.605,0,0,0,.605.605H14.894a.605.605,0,0,0,.605-.605V.605A.605.605,0,0,0,14.894,0Zm-.605,14.288H1.211V1.211H14.288ZM5.509,4.177a.605.605,0,0,1,.605-.605h6.024a.605.605,0,1,1,0,1.211H6.115A.605.605,0,0,1,5.509,4.177ZM2.755,7.75a.605.605,0,0,1,.605-.605h8.779a.605.605,0,0,1,0,1.211H3.36A.605.605,0,0,1,2.755,7.75Zm0,3.572a.605.605,0,0,1,.605-.605h8.779a.605.605,0,1,1,0,1.211H3.36A.605.605,0,0,1,2.755,11.322Zm0,0"/>
    </svg>
    `,
  copy: `
    <svg width="12.091" height="14" viewBox="0 0 12.091 14">
        <path d="M47.159,0H39.523A1.276,1.276,0,0,0,38.25,1.273v8.909h1.273V1.273h7.636Zm1.909,2.545h-7A1.276,1.276,0,0,0,40.8,3.818v8.909A1.276,1.276,0,0,0,42.068,14h7a1.276,1.276,0,0,0,1.273-1.273V3.818A1.276,1.276,0,0,0,49.068,2.545Zm0,10.182h-7V3.818h7Z" transform="translate(-38.25)"/>
    </svg>
    `,
  crop: `
    <svg width="15.035" height="15.035" viewBox="0 0 15.035 15.035">
        <path d="M3.759,0H1.879V1.879H0V3.759H1.879v9.4h8.457V11.276H3.759Z"/>
        <path d="M161.1,70.456v-9.4h-8.457v1.879h6.578V74.215H161.1V72.335h1.879V70.456Z" transform="translate(-147.948 -59.18)"/>
    </svg>
    `,
  back: `
    <svg width="16" height="16" viewBox="0 0 16 16">
        <g transform="translate(-0.002)">
            <path d="M8,16A8,8,0,1,0,0,8,8.009,8.009,0,0,0,8,16ZM8,1.1A6.9,6.9,0,1,1,1.1,8,6.913,6.913,0,0,1,8,1.1Z"/>
            <path d="M54.236,67.464a.548.548,0,1,0,.774-.774l-2.1-2.1H59a.548.548,0,0,0,0-1.1H52.905l2.1-2.1a.548.548,0,0,0-.774-.774L51.2,63.651a.548.548,0,0,0,0,.774Z" transform="translate(-47.308 -56.037)"/>
        </g>
    </svg>
    `,
  stock: `
    <svg width="20.189" height="20.189" viewBox="0 0 20.189 20.189" fill="#fff">
        <g transform="translate(0.5 0.5)">
            <path d="M18.23,0H.959A.959.959,0,0,0,0,.959V13.752a.959.959,0,0,0,.959.959H2.878V17.59a1.6,1.6,0,0,0,1.6,1.6H14.712a1.6,1.6,0,0,0,1.6-1.6V14.712H18.23a.959.959,0,0,0,.959-.959V.959A.959.959,0,0,0,18.23,0ZM.959.64H18.23a.32.32,0,0,1,.32.32v1.6H.64V.959A.32.32,0,0,1,.959.64ZM15.671,17.59a.959.959,0,0,1-.959.959H5.117c-.177,0-.008.06-.133,0H4.625a1.1,1.1,0,0,1-1.107-.959V10.554a.959.959,0,0,1,.959-.959h2.19a.954.954,0,0,0,.89-.6l.576-1.436a.32.32,0,0,1,.294-.2h2.335a.32.32,0,0,1,.295.2l.576,1.434a.954.954,0,0,0,.889.6h2.19a.959.959,0,0,1,.959.959ZM6.077,8.955H4.8v-.32a.32.32,0,0,1,.32-.32h.64a.32.32,0,0,1,.32.32ZM18.23,14.072H16.311V10.554a1.6,1.6,0,0,0-1.6-1.6h-2.19a.318.318,0,0,1-.3-.2l-.576-1.432a.963.963,0,0,0-.887-.605H8.427a.963.963,0,0,0-.888.6L6.963,8.754a.315.315,0,0,1-.248.193V8.635a.959.959,0,0,0-.959-.959H5.117a.959.959,0,0,0-.959.959v.352a1.6,1.6,0,0,0-1.279,1.567v3.518H.959a.32.32,0,0,1-.32-.32V3.2h17.91V13.752a.32.32,0,0,1-.32.32Z"/>
            <path d="M27.875,41a.875.875,0,0,0-.875.875.292.292,0,0,0,.583,0,.292.292,0,0,1,.292-.292.292.292,0,1,0,0-.583Z" transform="translate(-17.988 -27.805)"/>
            <path d="M25.078,37.559a3.213,3.213,0,1,0-1.152,1.318l.263.263A.873.873,0,1,0,25.423,37.9Zm-5.522-1.371a2.625,2.625,0,1,1,2.625,2.625A2.625,2.625,0,0,1,19.556,36.188Zm5.454,2.538a.292.292,0,0,1-.41,0l-.214-.214a3.232,3.232,0,0,0,.383-.437l.243.243a.285.285,0,0,1,0,.408Z" transform="translate(-12.565 -22.666)"/>
            <path d="M43.382,12.086a.292.292,0,0,0-.412,0l-.875.875a.292.292,0,0,0,.412.412L42.884,13v1.921a.292.292,0,1,0,.583,0V13l.377.377a.292.292,0,0,0,.412-.412Z" transform="translate(-28.395 -8.087)"/>
            <path d="M11.382,12.086a.292.292,0,0,0-.412,0l-.875.875a.292.292,0,0,0,.412.412L10.884,13v1.921a.292.292,0,0,0,.583,0V13l.377.377a.292.292,0,0,0,.412-.412Z" transform="translate(-6.767 -8.087)"/>
        </g>
    </svg>
    `,
  plus: `
    <svg width="14" height="14" viewBox="0 0 14 14">
        <path d="M7,0a7,7,0,1,0,7,7A7.008,7.008,0,0,0,7,0Zm3.063,7.583H7.583v2.479a.583.583,0,0,1-1.167,0V7.583H3.938a.583.583,0,1,1,0-1.167H6.417V3.938a.583.583,0,1,1,1.167,0V6.417h2.479a.583.583,0,0,1,0,1.167Zm0,0"/>
    </svg>
    `,
  view: `
    <svg width="15.998" height="10.197" viewBox="0 0 15.998 10.197">
        <g transform="translate(0 -92.835)">
            <g transform="translate(0 92.835)">
                <g transform="translate(0 0)">
                    <path d="M15.9,97.622c-.143-.2-3.548-4.787-7.9-4.787S.244,97.427.1,97.622a.528.528,0,0,0,0,.623c.143.2,3.548,4.787,7.9,4.787s7.755-4.592,7.9-4.787A.527.527,0,0,0,15.9,97.622ZM8,101.977c-3.2,0-5.978-3.048-6.8-4.044.82-1,3.589-4.043,6.8-4.043s5.978,3.047,6.8,4.044C13.978,98.931,11.209,101.977,8,101.977Z" transform="translate(0 -92.835)"/>
                </g>
            </g>
            <g transform="translate(4.834 94.769)">
                <g transform="translate(0 0)">
                    <path d="M157.886,154.725a3.164,3.164,0,1,0,3.164,3.164A3.168,3.168,0,0,0,157.886,154.725Zm0,5.274a2.11,2.11,0,1,1,2.11-2.11A2.112,2.112,0,0,1,157.886,160Z" transform="translate(-154.722 -154.725)"/>
                </g>
            </g>
        </g>
    </svg>
    `,
  crossEyeIcon: `
    <svg xmlns="http://www.w3.org/2000/svg" width="21.966" height="17.121" viewBox="0 0 21.966 17.121">
      <g id="view_1" data-name="view 1" transform="translate(0 1.651)">
        <g id="view" transform="translate(0)" opacity="0.85">
          <g id="Group_110" data-name="Group 110">
            <g id="Group_109" data-name="Group 109" transform="translate(0 0)">
              <path id="Path_87" data-name="Path 87" d="M21.826,99.408c-.2-.268-4.872-6.573-10.843-6.573S.335,99.139.139,99.408a.725.725,0,0,0,0,.855c.2.268,4.872,6.573,10.843,6.573s10.647-6.3,10.843-6.573A.724.724,0,0,0,21.826,99.408Zm-10.843,5.979c-4.4,0-8.208-4.184-9.336-5.552,1.126-1.369,4.928-5.551,9.336-5.551s8.208,4.184,9.336,5.552C19.192,101.2,15.391,105.387,10.983,105.387Z" transform="translate(0 -92.835)"/>
            </g>
          </g>
          <g id="Group_112" data-name="Group 112" transform="translate(6.638 2.655)">
            <g id="Group_111" data-name="Group 111" transform="translate(0 0)">
              <path id="Path_88" data-name="Path 88" d="M159.067,154.725a4.345,4.345,0,1,0,4.345,4.345A4.35,4.35,0,0,0,159.067,154.725Zm0,7.241a2.9,2.9,0,1,1,2.9-2.9A2.9,2.9,0,0,1,159.067,161.966Z" transform="translate(-154.722 -154.725)"/>
            </g>
          </g>
          <line id="XMLID_15_" x2="15" y2="15" transform="translate(2.968 -0.59)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
        </g>
      </g>
    </svg>
    `,
  EyeIcon: `
    <svg id="view" xmlns="http://www.w3.org/2000/svg" width="21.966" height="14" viewBox="0 0 21.966 14">
      <g id="view-2" data-name="view" transform="translate(0)" opacity="0.85">
        <g id="Group_110" data-name="Group 110">
          <g id="Group_109" data-name="Group 109" transform="translate(0 0)">
            <path id="Path_87" data-name="Path 87" d="M21.826,99.408c-.2-.268-4.872-6.573-10.843-6.573S.335,99.139.139,99.408a.725.725,0,0,0,0,.855c.2.268,4.872,6.573,10.843,6.573s10.647-6.3,10.843-6.573A.724.724,0,0,0,21.826,99.408Zm-10.843,5.979c-4.4,0-8.208-4.184-9.336-5.552,1.126-1.369,4.928-5.551,9.336-5.551s8.208,4.184,9.336,5.552C19.192,101.2,15.391,105.387,10.983,105.387Z" transform="translate(0 -92.835)"/>
          </g>
        </g>
        <g id="Group_112" data-name="Group 112" transform="translate(6.638 2.655)">
          <g id="Group_111" data-name="Group 111" transform="translate(0 0)">
            <path id="Path_88" data-name="Path 88" d="M159.067,154.725a4.345,4.345,0,1,0,4.345,4.345A4.35,4.35,0,0,0,159.067,154.725Zm0,7.241a2.9,2.9,0,1,1,2.9-2.9A2.9,2.9,0,0,1,159.067,161.966Z" transform="translate(-154.722 -154.725)"/>
          </g>
        </g>
      </g>
    </svg>
    `,
  toggle: `
    <svg viewBox="0 0 16 16" fill="#000">
       <g><circle cx="2" cy="8" r="2"/><circle cx="8" cy="8" r="2"/><circle cx="14" cy="8" r="2"/></g>
    </svg>`,
  customize: `
    <svg width="10.573" height="13.444" viewBox="0 0 10.573 13.444">
    <defs><style>.customize{fill:none;stroke:#929292;stroke-linecap:round;}</style></defs>
        <g transform="translate(0.5 0.5)">
            <path class="customize" d="M5,6H9.786" transform="translate(-3.086 -3.607)"/>
            <path class="customize" d="M5,11H9.786" transform="translate(-3.086 -6.214)"/>
            <path class="customize" d="M5,16H8.35" transform="translate(-3.086 -8.821)"/>
            <path class="customize" d="M17,21.829V18" transform="translate(-9.342 -9.864)"/>
            <path class="customize" d="M16.829,22H13" transform="translate(-7.256 -11.949)"/>
            <path class="customize" d="M6.265,13.444H2.914A1.914,1.914,0,0,1,1,11.529V2.914A1.914,1.914,0,0,1,2.914,1H7.7A1.914,1.914,0,0,1,9.615,2.914V7.461" transform="translate(-1 -1)"/>
        </g>
    </svg>
    `,
  circle: `
    <svg width="15.91" height="16.333" viewBox="0 0 15.91 16.333" fill="#fff">
        <path d="M8.672,1.964a5.242,5.242,0,0,1,4.876,3.318l.037.1H12.292L14.5,7.586l2.207-2.208H15.579l-.017-.085a7.174,7.174,0,0,0-14.1,1.879c.867,0,1.966.035,1.966.035A5.242,5.242,0,0,1,8.672,1.964Z" transform="translate(-0.796)"/>
        <path d="M8.387,20.175a5.241,5.241,0,0,1-4.877-3.319l-.037-.1H4.765L2.559,14.554.352,16.761H1.479l.018.087a7.173,7.173,0,0,0,14.1-1.88c-.867,0-1.966-.035-1.966-.035A5.242,5.242,0,0,1,8.387,20.175Z" transform="translate(-0.352 -5.808)"/>
    </svg>
    `,
  move: `
    <svg viewBox="0 0 21 20">
        <path fill-rule="evenodd" d="M11.5 8h-9v4h9V8zm.878 7.59c-.485.495-.878.333-.878-.36V4.647c0-.693.391-.856.878-.359l4.832 4.938a1.02 1.02 0 0 1 0 1.426l-4.832 4.937z"></path>
    </svg>
    `,
  add: `
    <svg viewBox="0 0 20 20">
        <path d="M11.111 8.889V5.55c0-.6-.497-1.106-1.111-1.106-.618 0-1.111.496-1.111 1.106v3.34H5.55c-.6 0-1.106.497-1.106 1.111 0 .618.496 1.111 1.106 1.111h3.34v3.339c0 .6.497 1.106 1.111 1.106.618 0 1.111-.496 1.111-1.106V11.11h3.339c.6 0 1.106-.497 1.106-1.111 0-.618-.496-1.111-1.106-1.111H11.11zM10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z" fill-rule="evenodd"></path>
    </svg>
  `,
  info: `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>
  `,
  download: `
  <svg xmlns="http://www.w3.org/2000/svg" width="14.75" height="18.75" viewBox="0 0 18.75 18.75">
  <g id="Group_8619" data-name="Group 8619" transform="translate(-319.594 -550)">
    <g id="upload" transform="translate(319.594 550)">
      <g id="Group_262" data-name="Group 262">
        <g id="Group_261" data-name="Group 261">
          <path id="Path_1126" data-name="Path 1126" d="M9.375,0A9.375,9.375,0,1,0,18.75,9.375,9.375,9.375,0,0,0,9.375,0Zm0,17.187a7.812,7.812,0,1,1,7.812-7.812A7.813,7.813,0,0,1,9.375,17.187Z"/>
        </g>
      </g>
    </g>
    <g id="_3325114_download_icon" data-name="3325114_download_icon" transform="translate(322.148 553)">
      <path id="Path_19407" data-name="Path 19407" d="M9.643,15v1.676a.793.793,0,0,1-.738.838H3.738A.793.793,0,0,1,3,16.676V15" transform="translate(0.5 -7.297)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      <path id="Path_19408" data-name="Path 19408" d="M7,10l1.676,1.676L10.352,10" transform="translate(-1.824 -3.973)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      <line id="Line_132" data-name="Line 132" y1="5.027" transform="translate(6.852 2)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    </g>
  </g>
</svg>
`,
  email: `
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 26.411 17.582">
  <g id="email-svgrepo-com" transform="translate(0 -85.575)">
    <g id="Group_8536" data-name="Group 8536" transform="translate(0 85.575)">
      <g id="Group_8535" data-name="Group 8535" transform="translate(0 0)">
        <path id="Path_18871" data-name="Path 18871" d="M64.538,127.637H63.73v12.839H42.062v.808H64.538Z" transform="translate(-39.892 -125.467)" fill="#fff"/>
        <path id="Path_18872" data-name="Path 18872" d="M97.96,161.867v12.839H76.293v.808H98.769V161.867Z" transform="translate(-72.357 -157.932)" fill="#fff"/>
        <path id="Path_18873" data-name="Path 18873" d="M22.88,85.575H0V99.626H22.88Zm-1.657.808L11.44,93.427,1.657,86.383Zm.849,12.435H.808V86.768L11.44,94.423l10.632-7.655Z" transform="translate(0 -85.575)" fill="#fff"/>
        <rect id="Rectangle_4262" data-name="Rectangle 4262" width="0.883" height="0.808" transform="translate(9.232 11.036)" fill="#fff"/>
        <rect id="Rectangle_4263" data-name="Rectangle 4263" width="0.883" height="0.808" transform="translate(10.998 11.036)" fill="#fff"/>
        <rect id="Rectangle_4264" data-name="Rectangle 4264" width="0.883" height="0.808" transform="translate(12.764 11.036)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>
`,
  uploadImg: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
<g id="Image" transform="translate(-353 -238)">
  <g id="Rectangle_4277" data-name="Rectangle 4277" transform="translate(353 238)" fill="none" stroke="#424f67" stroke-width="1">
    <rect width="26" height="26" rx="5" stroke="none"/>
    <rect x="0.5" y="0.5" width="25" height="25" rx="4.5" fill="none"/>
  </g>
  <path id="Path_18900" data-name="Path 18900" d="M494.693,210.128l5.609-8.008,4.6,8.008,3.971-5.025,5.262,5.025" transform="translate(-138.193 46)" fill="none" stroke="#424f67" stroke-width="1"/>
  <g id="Ellipse_63" data-name="Ellipse 63" transform="translate(371 242)" fill="none" stroke="#424f67" stroke-width="1">
    <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
    <circle cx="2.5" cy="2.5" r="2" fill="none"/>
  </g>
</g>
</svg>
`,
  bar: `
<svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="21" viewBox="0 0 20.5 21">
  <g id="Music_bar" data-name="Music bar" transform="translate(-1040 -415)">
    <rect id="Rectangle_4273" data-name="Rectangle 4273" width="4" height="13" transform="translate(1040 423)" fill="#4cc6fe"/>
    <rect id="Rectangle_4274" data-name="Rectangle 4274" width="4" height="21" transform="translate(1045.5 415)" fill="#4cc6fe"/>
    <rect id="Rectangle_4275" data-name="Rectangle 4275" width="4" height="9" transform="translate(1051 427)" fill="#4cc6fe"/>
    <rect id="Rectangle_4276" data-name="Rectangle 4276" width="4" height="12" transform="translate(1056.5 424)" fill="#4cc6fe"/>
  </g>
</svg>
`,
  downArrow: `
<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" stroke-width="0.9" stroke="#fff" fill="#fff"> </path>
</svg>
`,
  upArrow: `
<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16" id="IconChangeColor"> <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" id="mainIconPathAttribute" stroke-width="0.9" stroke="#fff" fill="#fff"></path> </svg>
`,
  uploadIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16"> <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/> <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/> </svg>`,
  doubleLeftIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" stroke-width="0.9" stroke="#fff" fill="#fff"></path> <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" stroke-width="0.9" stroke="#fff" fill="#fff"></path> </svg>`,
  leftCompactArrow: `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-compact-left" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z" stroke-width="0.9" stroke="#000" fill="#000"></path> </svg>`,
  rightCompactArrow: `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" stroke-width="0.9" stroke="#000" fill="#000"></path> </svg>`,
  clock: `<svg xmlns="http://www.w3.org/2000/svg" width="14.334" height="14.334" viewBox="0 0 14.334 14.334">
<path id="time" d="M7.434.264A7.167,7.167,0,1,0,14.6,7.429,7.179,7.179,0,0,0,7.434.264Zm0,1.3A5.864,5.864,0,1,1,1.566,7.429,5.851,5.851,0,0,1,7.434,1.567Zm-.01,1.294a.651.651,0,0,0-.645.664v3.9a.651.651,0,0,0,.193.461L9.577,10.5a.651.651,0,1,0,.919-.924L8.082,7.159V3.525a.651.651,0,0,0-.659-.664Z" transform="translate(-0.264 -0.264)" fill="#818181" fill-rule="evenodd"/>
</svg>
`,
  calendar: `<svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="14.333" height="14.333" viewBox="0 0 14.333 14.333">
<g id="Group_71" data-name="Group 71">
  <g id="Group_70" data-name="Group 70">
    <path id="Path_82" data-name="Path 82" d="M12.654,1.12h-.672V0h-1.12V1.12H3.471V0H2.352V1.12H1.68A1.682,1.682,0,0,0,0,2.8v9.854a1.682,1.682,0,0,0,1.68,1.68H12.654a1.682,1.682,0,0,0,1.68-1.68V2.8A1.682,1.682,0,0,0,12.654,1.12Zm.56,11.534a.561.561,0,0,1-.56.56H1.68a.561.561,0,0,1-.56-.56V5.263H13.214Zm0-8.511H1.12V2.8a.561.561,0,0,1,.56-.56h.672v1.12h1.12V2.24h7.391v1.12h1.12V2.24h.672a.561.561,0,0,1,.56.56Z" fill="#818181"/>
  </g>
</g>
<g id="Group_73" data-name="Group 73" transform="translate(2.345 6.515)">
  <g id="Group_72" data-name="Group 72" transform="translate(0 0)">
    <rect id="Rectangle_40" data-name="Rectangle 40" width="0.782" height="0.782" transform="translate(0)" fill="#818181"/>
  </g>
</g>
<g id="Group_75" data-name="Group 75" transform="translate(4.691 6.515)">
  <g id="Group_74" data-name="Group 74" transform="translate(0 0)">
    <rect id="Rectangle_41" data-name="Rectangle 41" width="0.782" height="0.782" fill="#818181"/>
  </g>
</g>
<g id="Group_77" data-name="Group 77" transform="translate(7.037 6.515)">
  <g id="Group_76" data-name="Group 76" transform="translate(0 0)">
    <rect id="Rectangle_42" data-name="Rectangle 42" width="0.782" height="0.782" transform="translate(0)" fill="#818181"/>
  </g>
</g>
<g id="Group_79" data-name="Group 79" transform="translate(8.6 6.515)">
  <g id="Group_78" data-name="Group 78" transform="translate(0 0)">
    <rect id="Rectangle_43" data-name="Rectangle 43" width="1.564" height="0.782" fill="#818181"/>
  </g>
</g>
<g id="Group_81" data-name="Group 81" transform="translate(10.946 6.515)">
  <g id="Group_80" data-name="Group 80" transform="translate(0 0)">
    <rect id="Rectangle_44" data-name="Rectangle 44" width="0.782" height="0.782" transform="translate(0)" fill="#818181"/>
  </g>
</g>
<g id="Group_83" data-name="Group 83" transform="translate(2.345 8.861)">
  <g id="Group_82" data-name="Group 82" transform="translate(0 0)">
    <rect id="Rectangle_45" data-name="Rectangle 45" width="0.782" height="0.782" transform="translate(0)" fill="#818181"/>
  </g>
</g>
<g id="Group_85" data-name="Group 85" transform="translate(4.691 8.861)">
  <g id="Group_84" data-name="Group 84" transform="translate(0 0)">
    <rect id="Rectangle_46" data-name="Rectangle 46" width="0.782" height="0.782" fill="#818181"/>
  </g>
</g>
<g id="Group_87" data-name="Group 87" transform="translate(7.037 8.861)">
  <g id="Group_86" data-name="Group 86" transform="translate(0 0)">
    <rect id="Rectangle_47" data-name="Rectangle 47" width="0.782" height="0.782" transform="translate(0)" fill="#818181"/>
  </g>
</g>
<g id="Group_89" data-name="Group 89" transform="translate(8.6 8.861)">
  <g id="Group_88" data-name="Group 88" transform="translate(0 0)">
    <rect id="Rectangle_48" data-name="Rectangle 48" width="1.564" height="0.782" fill="#818181"/>
  </g>
</g>
<g id="Group_91" data-name="Group 91" transform="translate(2.345 11.206)">
  <g id="Group_90" data-name="Group 90" transform="translate(0 0)">
    <rect id="Rectangle_49" data-name="Rectangle 49" width="0.782" height="0.782" transform="translate(0 0)" fill="#818181"/>
  </g>
</g>
<g id="Group_93" data-name="Group 93" transform="translate(4.691 11.206)">
  <g id="Group_92" data-name="Group 92" transform="translate(0 0)">
    <rect id="Rectangle_50" data-name="Rectangle 50" width="0.782" height="0.782" transform="translate(0 0)" fill="#818181"/>
  </g>
</g>
<g id="Group_95" data-name="Group 95" transform="translate(7.037 11.206)">
  <g id="Group_94" data-name="Group 94" transform="translate(0 0)">
    <rect id="Rectangle_51" data-name="Rectangle 51" width="0.782" height="0.782" transform="translate(0 0)" fill="#818181"/>
  </g>
</g>
<g id="Group_97" data-name="Group 97" transform="translate(8.6 11.206)">
  <g id="Group_96" data-name="Group 96" transform="translate(0 0)">
    <rect id="Rectangle_52" data-name="Rectangle 52" width="1.564" height="0.782" transform="translate(0 0)" fill="#818181"/>
  </g>
</g>
<g id="Group_99" data-name="Group 99" transform="translate(10.946 8.861)">
  <g id="Group_98" data-name="Group 98" transform="translate(0 0)">
    <rect id="Rectangle_53" data-name="Rectangle 53" width="0.782" height="0.782" transform="translate(0)" fill="#818181"/>
  </g>
</g>
</svg>
`,
  sync: `<svg xmlns="http://www.w3.org/2000/svg" width="24.505" height="15.939" viewBox="0 0 24.505 20.939">
<g id="Layer_2" data-name="Layer 2" transform="translate(-1.741 -3.533)">
  <g id="sync">
    <path id="Path_26382" data-name="Path 26382" d="M25.021,11.594a.723.723,0,0,0,.082-.222l.875-4.667a1.185,1.185,0,1,0-2.333-.42l-.432,2.333A10.757,10.757,0,0,0,3.87,9.6a1.17,1.17,0,0,0,2.17.875,8.26,8.26,0,0,1,7.793-5.145,8.365,8.365,0,0,1,7.28,4.177L19.188,9.19a1.182,1.182,0,1,0-.373,2.333l4.958.828h.187a1.085,1.085,0,0,0,.4-.07.385.385,0,0,0,.117-.07.91.91,0,0,0,.233-.128l.093-.117a1.248,1.248,0,0,0,.163-.187.676.676,0,0,0,.058-.187ZM22.945,15.91a1.167,1.167,0,0,0-1.528.653,8.26,8.26,0,0,1-7.747,5.1,8.365,8.365,0,0,1-7.28-4.177l1.925.315H8.5a1.17,1.17,0,0,0,.187-2.333L3.73,14.662a1.062,1.062,0,0,0-.385,0H3.251a1.342,1.342,0,0,0-.373.163,1.167,1.167,0,0,0-.21.21l-.1.117a.98.98,0,0,0-.082.222.513.513,0,0,0-.082.2l-.875,4.667a1.167,1.167,0,0,0,.933,1.423h.21a1.167,1.167,0,0,0,1.167-.957l.432-2.333A10.757,10.757,0,0,0,23.61,17.4a1.167,1.167,0,0,0-.665-1.493Z" transform="translate(0.249 0.505)" fill="#000000"/>
  </g>
</g>
</svg>
`,
  startLive: `<svg xmlns="http://www.w3.org/2000/svg" width="68.844" height="55.434" viewBox="0 0 68.844 96.434">
<g id="Group_9009" data-name="Group 9009" transform="translate(-1319 -51)">
  <path id="video-camera-icon" d="M7.875,25.552H38.064a5.1,5.1,0,0,1,5.09,5.085V49.621a5.111,5.111,0,0,1-5.09,5.09H28.737L35.514,65.58H29.2l-7.384-9.946-7.47,9.93h-6.4L14.678,54.7h-6.8a5.106,5.106,0,0,1-5.09-5.074V30.637A5.1,5.1,0,0,1,7.875,25.552ZM8,5.277a8,8,0,1,1-8,8,8,8,0,0,1,8-8ZM32.579,0a10.607,10.607,0,1,1-7.506,3.107A10.612,10.612,0,0,1,32.579,0ZM46.046,48.554V32.216l13.8-8.131V56.9Z" transform="translate(1328 51)" fill="#fff" fill-rule="evenodd"/>
  <path id="Path_30826" data-name="Path 30826" d="M3.65,380.15H65.13a3.665,3.665,0,0,1,3.65,3.653v23.552A3.668,3.668,0,0,1,65.13,411H3.65A3.665,3.665,0,0,1,0,407.354V383.8A3.662,3.662,0,0,1,3.65,380.15Zm17.787,23.162h-9.9V387.847h4.948v11.5h4.948Zm1.732,0V387.847h4.948v15.465ZM39.4,387.847H44.62l-3.833,15.465H33.561l-3.836-15.465h5.221l2.1,9.822h.223l2.127-9.822Zm16.728,9.624H51.179v1.88H57.24v3.96H46.23V387.847H57.118L56.5,391.8H51.179v2.08h4.948Z" transform="translate(1319 -263.57)" fill="#fff" fill-rule="evenodd"/>
</g>
</svg>`,
  stopLive: `<svg xmlns="http://www.w3.org/2000/svg" width="78.758" height="45.754" viewBox="0 0 78.758 78.754">
<g id="no-video-icon" transform="translate(-0.124)">
  <path id="Path_30822" data-name="Path 30822" d="M19.823,64.458,64.45,19.824A31.668,31.668,0,0,0,19.823,64.458Zm49.913-38.9L25.551,69.712A31.655,31.655,0,0,0,75.535,43.869h0a31.469,31.469,0,0,0-5.825-18.31Z" transform="translate(-4.365 -4.411)" fill="#fff"/>
  <path id="Path_30823" data-name="Path 30823" d="M37.628,30.384a6.422,6.422,0,1,1-4.5,1.882A6.422,6.422,0,0,1,37.628,30.384Zm-.077,13.827H56.438a3.211,3.211,0,0,1,3.211,3.211V61.236a3.211,3.211,0,0,1-3.211,3.211H50.6l4.251,6.769H50.89L46.272,65,41.6,71.216h-4l4.213-6.8H37.551A3.211,3.211,0,0,1,34.34,61.2V47.4a3.211,3.211,0,0,1,3.211-3.211ZM61.429,59.393V49.175l8.625-5.086V64.64ZM53,27.07a8.066,8.066,0,1,1-5.7,2.363A8.067,8.067,0,0,1,53,27.07Z" transform="translate(-11.137 -9.685)" fill-rule="evenodd"/>
  <path id="Path_30824" data-name="Path 30824" d="M39.5,0A39.374,39.374,0,1,1,24.449,2.993,39.375,39.375,0,0,1,39.5,0ZM65.345,21.148,21.193,65.3A31.648,31.648,0,0,0,71.157,39.458h0a31.424,31.424,0,0,0-5.831-18.31Zm-49.887,38.9L60.092,15.413A31.674,31.674,0,0,0,15.458,60.047Z" transform="translate(0)" fill="#d92d27"/>
</g>
</svg>
`,
  greenLive: `<svg xmlns="http://www.w3.org/2000/svg" width="59.846" height="45.58" viewBox="0 0 59.846 65.58">
<g id="Group_9009" data-name="Group 9009" transform="translate(-1328 -51)">
  <path id="video-camera-icon" d="M7.875,25.552H38.064a5.1,5.1,0,0,1,5.09,5.085V49.621a5.111,5.111,0,0,1-5.09,5.09H28.737L35.514,65.58H29.2l-7.384-9.946-7.47,9.93h-6.4L14.678,54.7h-6.8a5.106,5.106,0,0,1-5.09-5.074V30.637a5.1,5.1,0,0,1,5.087-5.085ZM8,5.277a8,8,0,1,1-8,8,8,8,0,0,1,8-8ZM32.579,0a10.607,10.607,0,1,1-7.506,3.107A10.607,10.607,0,0,1,32.579,0ZM46.046,48.554V32.216l13.8-8.131V56.9Z" transform="translate(1328 51)" fill="#00ab41" fill-rule="evenodd"/>
</g>
</svg>
`,
  greyLive: `<svg xmlns="http://www.w3.org/2000/svg" width="59.846" height="45.58" viewBox="0 0 59.846 65.58">
<g id="Group_9009" data-name="Group 9009" transform="translate(-1328 -51)">
  <path id="video-camera-icon" d="M7.875,25.552H38.064a5.1,5.1,0,0,1,5.09,5.085V49.621a5.111,5.111,0,0,1-5.09,5.09H28.737L35.514,65.58H29.2l-7.384-9.946-7.47,9.93h-6.4L14.678,54.7h-6.8a5.106,5.106,0,0,1-5.09-5.074V30.637a5.1,5.1,0,0,1,5.087-5.085ZM8,5.277a8,8,0,1,1-8,8,8,8,0,0,1,8-8ZM32.579,0a10.607,10.607,0,1,1-7.506,3.107A10.607,10.607,0,0,1,32.579,0ZM46.046,48.554V32.216l13.8-8.131V56.9Z" transform="translate(1328 51)" fill="#404040" fill-rule="evenodd"/>
</g>
</svg>
`,
  whiteLive: `<svg xmlns="http://www.w3.org/2000/svg" width="59.846" height="45.58" viewBox="0 0 59.846 65.58">
<g id="Group_9009" data-name="Group 9009" transform="translate(-1328 -51)">
  <path id="video-camera-icon" d="M7.875,25.552H38.064a5.1,5.1,0,0,1,5.09,5.085V49.621a5.111,5.111,0,0,1-5.09,5.09H28.737L35.514,65.58H29.2l-7.384-9.946-7.47,9.93h-6.4L14.678,54.7h-6.8a5.106,5.106,0,0,1-5.09-5.074V30.637a5.1,5.1,0,0,1,5.087-5.085ZM8,5.277a8,8,0,1,1-8,8,8,8,0,0,1,8-8ZM32.579,0a10.607,10.607,0,1,1-7.506,3.107A10.607,10.607,0,0,1,32.579,0ZM46.046,48.554V32.216l13.8-8.131V56.9Z" transform="translate(1328 51)" fill="#ffffff" fill-rule="evenodd"/>
</g>
</svg>
`,
  chatSupport: `<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 115.98 122.88" style="enable-background:new 0 0 115.98 122.88" xml:space="preserve"><g><path d="M17.2,0h59.47c4.73,0,9.03,1.93,12.15,5.05c3.12,3.12,5.05,7.42,5.05,12.15v38.36c0,4.73-1.93,9.03-5.05,12.15 c-3.12,3.12-7.42,5.05-12.15,5.05H46.93L20.81,95.21c-1.21,1.04-3.04,0.9-4.08-0.32c-0.51-0.6-0.74-1.34-0.69-2.07l1.39-20.07H17.2 c-4.73,0-9.03-1.93-12.15-5.05C1.93,64.59,0,60.29,0,55.56V17.2c0-4.73,1.93-9.03,5.05-12.15C8.16,1.93,12.46,0,17.2,0L17.2,0z M102.31,27.98c3.37,0.65,6.39,2.31,8.73,4.65c3.05,3.05,4.95,7.26,4.95,11.9v38.36c0,4.64-1.89,8.85-4.95,11.9 c-3.05,3.05-7.26,4.95-11.9,4.95h-0.61l1.42,20.44l0,0c0.04,0.64-0.15,1.3-0.6,1.82c-0.91,1.07-2.52,1.19-3.58,0.28l-26.22-23.2 H35.01l17.01-17.3h36.04c7.86,0,14.3-6.43,14.3-14.3V29.11C102.35,28.73,102.34,28.35,102.31,27.98L102.31,27.98z M25.68,43.68 c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9h30.35c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9H25.68L25.68,43.68z M25.68,29.32c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9H68.7c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9H25.68L25.68,29.32z M76.66,5.8H17.2c-3.13,0-5.98,1.28-8.05,3.35C7.08,11.22,5.8,14.06,5.8,17.2v38.36c0,3.13,1.28,5.98,3.35,8.05 c2.07,2.07,4.92,3.35,8.05,3.35h3.34v0.01l0.19,0.01c1.59,0.11,2.8,1.49,2.69,3.08l-1.13,16.26L43.83,67.8 c0.52-0.52,1.24-0.84,2.04-0.84h30.79c3.13,0,5.98-1.28,8.05-3.35c2.07-2.07,3.35-4.92,3.35-8.05V17.2c0-3.13-1.28-5.98-3.35-8.05 C82.65,7.08,79.8,5.8,76.66,5.8L76.66,5.8z"/></g></svg> `,
  messageIcon: ` <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#040e33" class="bi bi-messenger" viewBox="0 0 16 16">
<path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"/>
</svg>`,
  messageClose: ` <svg  xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-chevron-down" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
</svg>`,
  backArrowChat: `<svg width="24px" height="24px" viewBox="0 0 24 24" class="GfYBMd iNfgyc w6RD9e"><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path><path fill="none" d="M0 0h24v24H0z"></path></svg>`,
  trainedData: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="24" width="24" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 117.72 117.72" style="enable-background:new 0 0 117.72 117.72" xml:space="preserve"><style type="text/css"><![CDATA[
	.st0{fill:#01A601;}
]]></style><g><path class="st0" d="M58.86,0c9.13,0,17.77,2.08,25.49,5.79c-3.16,2.5-6.09,4.9-8.82,7.21c-5.2-1.89-10.81-2.92-16.66-2.92 c-13.47,0-25.67,5.46-34.49,14.29c-8.83,8.83-14.29,21.02-14.29,34.49c0,13.47,5.46,25.66,14.29,34.49 c8.83,8.83,21.02,14.29,34.49,14.29s25.67-5.46,34.49-14.29c8.83-8.83,14.29-21.02,14.29-34.49c0-3.2-0.31-6.34-0.9-9.37 c2.53-3.3,5.12-6.59,7.77-9.85c2.08,6.02,3.21,12.49,3.21,19.22c0,16.25-6.59,30.97-17.24,41.62 c-10.65,10.65-25.37,17.24-41.62,17.24c-16.25,0-30.97-6.59-41.62-17.24C6.59,89.83,0,75.11,0,58.86 c0-16.25,6.59-30.97,17.24-41.62S42.61,0,58.86,0L58.86,0z M31.44,49.19L45.8,49l1.07,0.28c2.9,1.67,5.63,3.58,8.18,5.74 c1.84,1.56,3.6,3.26,5.27,5.1c5.15-8.29,10.64-15.9,16.44-22.9c6.35-7.67,13.09-14.63,20.17-20.98l1.4-0.54H114l-3.16,3.51 C101.13,30,92.32,41.15,84.36,52.65C76.4,64.16,69.28,76.04,62.95,88.27l-1.97,3.8l-1.81-3.87c-3.34-7.17-7.34-13.75-12.11-19.63 c-4.77-5.88-10.32-11.1-16.79-15.54L31.44,49.19L31.44,49.19z"/></g></svg>`,
  crossIconDownload: `<svg width="14px" height="14px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="122.881px" height="122.88px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88" xml:space="preserve"><g><path d="M61.44,0c16.966,0,32.326,6.877,43.445,17.996c11.119,11.118,17.996,26.479,17.996,43.444 c0,16.967-6.877,32.326-17.996,43.444C93.766,116.003,78.406,122.88,61.44,122.88c-16.966,0-32.326-6.877-43.444-17.996 C6.877,93.766,0,78.406,0,61.439c0-16.965,6.877-32.326,17.996-43.444C29.114,6.877,44.474,0,61.44,0L61.44,0z M80.16,37.369 c1.301-1.302,3.412-1.302,4.713,0c1.301,1.301,1.301,3.411,0,4.713L65.512,61.444l19.361,19.362c1.301,1.301,1.301,3.411,0,4.713 c-1.301,1.301-3.412,1.301-4.713,0L60.798,66.157L41.436,85.52c-1.301,1.301-3.412,1.301-4.713,0c-1.301-1.302-1.301-3.412,0-4.713 l19.363-19.362L36.723,42.082c-1.301-1.302-1.301-3.412,0-4.713c1.301-1.302,3.412-1.302,4.713,0l19.363,19.362L80.16,37.369 L80.16,37.369z M100.172,22.708C90.26,12.796,76.566,6.666,61.44,6.666c-15.126,0-28.819,6.13-38.731,16.042 C12.797,32.62,6.666,46.314,6.666,61.439c0,15.126,6.131,28.82,16.042,38.732c9.912,9.911,23.605,16.042,38.731,16.042 c15.126,0,28.82-6.131,38.732-16.042c9.912-9.912,16.043-23.606,16.043-38.732C116.215,46.314,110.084,32.62,100.172,22.708 L100.172,22.708z"/></g></svg>`,
  toggleSupport: `
<svg viewBox="0 0 16 16" fill="#fff">
   <g><circle cx="2" cy="8" r="2"/><circle cx="8" cy="8" r="2"/><circle cx="14" cy="8" r="2"/></g>
</svg>`,
  attachment: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="25px" width="40px" version="1.1" id="Layer_1" viewBox="0 0 280.067 280.067" xml:space="preserve">
<g>
	<path style="fill:#7c7c7c;" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429   c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0   s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394   c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599   c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403   c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485   c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098   l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
</g>
</svg>`,
  twitter: `<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" viewBox="0 0 512 512" id="twitter"><g clip-path="url(#clip0_84_15697)"><rect width="512" height="512" fill="#000" rx="60"></rect><path fill="#fff" d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"></path></g><defs><clipPath id="clip0_84_15697"><rect width="512" height="512" fill="#fff"></rect></clipPath></defs></svg>

`,
};
