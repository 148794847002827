<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    

        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between align-items-center p-4">
                <h4>Events Embed</h4>
                <img class="cross-icon-img"  src="./../../../../assets/images/library/close.svg"
                 class="" (click)="closePanel()" style="cursor: pointer;" alt="">
            </div>
        </div>
        <div class="panel-content">
         
            <label for="">Choose a Calendar from your Library.</label>


            <div (click)="openPanel('eventEmbed')"
                            class="google-play-container p-2 my-3  d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="sq-img-back d-flex justify-content-center align-items-center">
                                <img src="./../../../../../assets/images/common/avatar-square.png" alt="">
                            </div>
                            <div class="d-flex flex-column px-3">
                                
                                    <span class="para-heading" >nowcast Calendar</span>
                                    <!-- <p class="para-text mb-0">Newest First Grid</p> -->
                            </div>
                        </div>
                        <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                    </div>

                    <p class="info-title">
                        Options
                    </p>
                    <div class="d-flex align-items-center mb-3" >
                        <input type="checkbox" name="" id="">
                        <p class="para-text mb-0 px-2">Display Nowcast branding below content</p>
                    </div>
        
        
                    <div>
                        <p class="info-title">
                            Embed Code
                        </p>
                        <p class="para-text my-3">
                            Copy and paste the embed code below into your website wherever you would like it to be shown.
                        </p>
            
                        <a >
                            Learn more about embed codes
                        </a>
                       
                        <div class="form-group my-3">
                            
                            <span class="app-password app-copy">
                                <input type="text" class="form-control" [(ngModel)]="code" readonly  >
                                <em class="password-icon" [appSvgIcon]="'copy'"  ></em>
                            </span>
                        </div>
            
                    </div>
          

        </div>
</div>

<app-add-to-list></app-add-to-list>