import { Router } from '@angular/router';
import { CustomerService } from './../../../services/customer/customer.service';
import { SharedService } from './../../../services/shared/shared.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as moment from 'moment';

@Component({
  selector: 'app-initial',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.css']
})
export class InitialComponent implements OnInit, OnDestroy {
  sidebarState = false;
  timeoutId;
  scrollTimeOut;
  switchView = false;
  userName = '';
  orgId: any;
  subscriptonData = new Subscription();
  showChatSupport: boolean = false;
  infoData: any;
  startDate: any;
  currentDate = new Date();
  constructor(
    public sharedService: SharedService,
    public customerService: CustomerService,
    public dashboardService: DashboardService,
    public router: Router
  ) {
    this.sidebarState = (window.innerWidth >= 767);
  }

  ngOnInit(): void {
    const loginWith = localStorage.getItem('loggedInUser') && JSON.parse(localStorage.getItem('loggedInUser')).userDetails.roles[0].name == 'ROLE_ORGANIZATION_ADMIN' ? true : false;
    if (loginWith) {
      const enableChatSupport = JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.enableChatSupport;
      if (enableChatSupport === "true") {
        this.showChatSupport = true;
      }
    }
    this.getSubsciptionData();
    setTimeout(() => {
      const loggedInUserData = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : [];
      if (!Array.isArray(loggedInUserData)) {
        const userRoles = loggedInUserData.userDetails.roles.map((el) => el.name);
        this.sharedService.updateUserRoleData(userRoles);
      }
    }, 0);
    this.switchView = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).switchedView : false;
    this.userName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.firstName : '';
    this.orgId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.id : '';
  }

  /**
   * To get subscription data
   */
  getSubsciptionData() {
    this.subscriptonData.add(
      this.sharedService.updateSideBar$.subscribe((data: any) => {
        this.sidebarState = data;
      }),
    );
    this.subscriptonData.add(
      this.sharedService.switchUserData$.subscribe((switchData: any) => {
        this.switchView = switchData.switch;
        this.userName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.firstName : '';
        this.orgId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.id : '';
        const loginWith = localStorage.getItem('loggedInUser') && JSON.parse(localStorage.getItem('loggedInUser')).userDetails.roles[0].name === 'ROLE_ORGANIZATION_ADMIN' ? true : false;
        if (loginWith) {
          this.startDate = moment(this.currentDate).format('YYYY-MM')
          this.getDataUsagePercentageInfo(this.startDate)
          const enableChatSupport = JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.enableChatSupport;
          if (enableChatSupport === "true") {
            this.showChatSupport = true;
          }
        }
        else {
          this.showChatSupport = false;
        }
      })
    );
  }

  ngAfterViewInit(): void {
    const loginWith = localStorage.getItem('loggedInUser') && JSON.parse(localStorage.getItem('loggedInUser')).userDetails.roles[0].name === 'ROLE_ORGANIZATION_ADMIN' ? true : false;
    if (loginWith) {
      this.startDate = moment(this.currentDate).format('YYYY-MM')
      this.getDataUsagePercentageInfo(this.startDate)
    }
  }

  ngOnDestroy() {
    if (this.subscriptonData) {
      this.subscriptonData.unsubscribe();
    }
  }

  getSidebarState(state) {
    this.sidebarState = state;
  }

  /**
   * To switch back
   */
  switchBack() {
    const userName = localStorage.getItem('loggedInUserName');
    const dataToSend = {};
    dataToSend['username'] = userName
    let page = null
    let first = null
    let paymentStatus = null
    let rows = null
    page = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).page : '';
    first = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).first : '';
    rows = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).rows : '';
    paymentStatus = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).paymentStatus : '';
    this.customerService.switchUser(dataToSend).subscribe((response) => {
      localStorage.setItem('loggedInUser', JSON.stringify(response.body.data));
      this.router.navigate(['/customer-module/customer'], { queryParams: { page: page, first: first, row: rows, paymentStatus: paymentStatus } });
      this.sharedService.updateSwitchUserData({
        switch: false,
        userDetails: response.body.data.userDetails
      });
      const userRoles = response.body.data.userDetails.roles.map((el) => el.name);
      this.sharedService.updateUserRoleData(userRoles);
    });
  }

  onContentScroll() {
    if (document.getElementsByClassName('custom-popover')[0]) {
      window.clearTimeout(this.scrollTimeOut);
      this.scrollTimeOut = window.setTimeout(() => {
        this.sharedService.updatePopOverState(false);
      }, 0);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      if (window.innerWidth <= 768 && this.sidebarState) {
        this.sidebarState = false;
      }
    }, 0);
  }
  getDataUsagePercentageInfo(startDate){
    let isPaymentDone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails?.isPaymentDone : false;
    startDate = moment(startDate).format("YYYY-MM")
    this.dashboardService.getDataUsagePercentageInfo(startDate).subscribe(response=>{
      this.infoData = { isPaymentDone : isPaymentDone , ...response }
      this.sharedService.openModal();
    })
  }

  closeModal(): void {
    this.sharedService.closeModal();
  }
}

