import { MessageService } from 'primeng/api';
import { CustomerService } from './../../services/customer/customer.service';
import { TemplateCols } from './../../modules/customer/customer';
import { TemplateCols1 } from './../../modules/customer/customer'
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionServiceService } from 'src/app/services/subscription/subscription-service.service';

@Component({
  selector: 'app-subscription-template',
  templateUrl: './subscription-template.component.html',
  styleUrls: ['./subscription-template.component.css']
})
export class SubscriptionTemplateComponent implements OnInit {
  cols = TemplateCols;
  templates: any[] = [];
  rowCount: number;
  totalRecords = 0;
  deleteModal = false;
  selectedTemplate: any;
  showTempData = false

  templateContent: string;
  templateName: string;
  templateView = 'desktop';
  templateModal = false;
  temp = true
  @Input() subscriptionID: any;
  @Input() templateId: any;
  @Output() updateTemplatePopup = new EventEmitter();
  constructor(
    public customerService: CustomerService,
    public messageService: MessageService,
    public router: Router,
    public subscriptionService: SubscriptionServiceService
  ) { }

  // @ViewChild('searchInput') searchInput : ElementRef

  ngOnInit(): void {

    if (this.router.url === `/customer-module/customer/email-template`) {
      this.showTempData = true
      this.cols = TemplateCols;
      this.temp = false
    }
    else {
      this.cols = TemplateCols1;
    }

    this.rowCount = (window.innerWidth >= 1600) ? 25 : 10;
    this.getTemplateList('SAMPLE');
  }

  getTemplateList(type) {
    // this.customerService.getTemplate().subscribe((response: any) => {
    //   this.templates = response.data;
    //   this.totalRecords = this.templates.length;
    // });

    // this.customerService.getTemplateForOthers(type).subscribe((response:any) => {
    var search = ''
    this.customerService.getEmailTemplateByType(search, type).subscribe((response: any) => {

      // this.sampleTemplateArray = response.data
      this.templates = response.data;
      this.totalRecords = this.templates.length;
      this.templates.forEach(el => {
        if (el.id === this.templateId) {
          el.checked = true;
        }
        else {
          el.checked = false
        }
      })
      // this.templates[1].checked = true
      // if(this.sampleTemplateArray.length>0){
      //   this.sampleTemplateArray.forEach((el) => {
      //     el.imageBytes = "data:image/png;base64," + el.imageBytes 
      //   } )
      // }
    })


  }

  goToRoute(col, rowData) {
    if (col.field !== 'selected') {
      this.router.navigate([`/customer-module/customer/send-mail/${rowData.id}`])
    }
  }

  select(value) {
    this.customerService.updateSelectedTemplate(value.id).subscribe(() => {
      this.getTemplateList('SAMPLE');
      // this.router.navigate(['/customer-module/customer/email-template']);
      // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Template created successfully' });
    }, (error) => {
      // this.messageService.add({
      //   severity: 'error', summary: 'Error',
      //   detail: error.error ? error.error.message : 'Something went wrong.'
      // });
    });
  }

  deleteTemplate() {
    this.customerService.deleteTemplate(this.selectedTemplate.id).subscribe(() => {
      this.templates = this.templates.filter((el) => el.id !== this.selectedTemplate.id);
      this.deleteModal = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Template deleted successfully' });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    });
  }

  sortBy(event) {
    this.templates.sort((a, b) => {
      return event.target.value ? b.id - a.id : a.id - b.id;
    });
  }

  getTemplateContent(templateId) {
    this.customerService.getContent(templateId).subscribe((response: any) => {
      // if (this.routeParam.update) {
      //   this.sendMail.templateName = response.data.templateName;
      //   this.sendMail['design'] = response.data.design
      // }
      // this.previewImg = response.data.imageBytes ? "data:image/png;base64," + response.data.imageBytes :  '/assets/images/common/square.svg';
      // this.sendMail.subject = response.data.subject ? response.data.subject : '';
      this.templateModal = true
      this.templateView = 'desktop';
      this.templateContent = response.data.content;
      this.templateName = response.data.templateName;
    });
  }

  saveTemplate() {
    const templateId = this.selectedTemplate;
    this.subscriptionService.updateSubscriptionTemplate(this.subscriptionID, templateId).subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Template is updated successfully` });
      this.updateTemplatePopup.emit('false');
    }
      , (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      }
    )
  }

  selectedVl(val: any, selectedData) {
    this.selectedTemplate = selectedData.id
  }

}