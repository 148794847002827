import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { CustomerService } from "src/app/services/customer/customer.service";
import { SettingsServiceService } from "src/app/services/settings/settings-service.service";
import { SharedService } from "src/app/services/shared/shared.service";

@Component({
  selector: "app-storage-usage",
  templateUrl: "./storage-usage.component.html",
  styleUrls: ["./storage-usage.component.css"],
})
export class StorageUsageComponent implements OnInit {
  totalUsedMemory: any;
  totalDiskSpace: any;
  totalDiskSpaceGB: any;
  totalDiskSpaceTB: any;
  totalUsedMemoryPercent: any;
  totalVideoPercent: any;
  totalAudioPercent: any;
  totalImagePercent: any;
  totalDocumentPercent: any;
  videoMemory: any;
  audioMemory: any;
  documentMemory: any;
  imageMemory: any;
  organizationPlan: string;
  planStorage: string;
  customerId: any;
  selectedFilter: any = "month";
  displayScheduleModal: boolean;
  firstDate: null;
  lastDate: null;
  filterValue: any;
  today: any;
  yesterday: any;
  startDate: any;
  endDate: any;
  isDefault: boolean;
  page: number;
  dataUsage: any;
  usedUsageMemory: any;
  totalMemory: any;
  totalUsedUsagePercent: any;
  dateData: any;
  currentDate = new Date();
  selectAllType = true;
  monthName: any;
  isSuperAdmin: any;
  totalVODDataUsage: any;
  totalStorageMemory: any;
  usedUsageStorageMemory: any;
  percentageUsed: any;
  subscriptonData = new Subscription();
  defaultVal : boolean = true;
  liveStreamTotalMemory: string;
  liveStreamUsageMemory: string;
  liveStreamTotalUsedUsagePercent: string;
  liveStreamTotalDataUsage: any;
  liveStreamDataUsage: any;
  liveStreamCurrentDate: Date;
  liveDateData: string;
  liveStartDate: string;
  liveDefaultVal: boolean = false;
  liveSelectAllType: boolean = false;
  @Input() isLiveSectionShow: boolean = false;
  constructor(
    private settingsService: SettingsServiceService,
    private sharedService: SharedService,
    public route: ActivatedRoute,
    private customerService: CustomerService
  ) {
    this.monthName = moment(this.currentDate)
      .subtract(0, "month")
      .startOf("month")
      .format("MMMM");
    this.monthName = this.monthName.substring(0, 3);
  }

  ngOnInit(): void {
    this.customerId = +this.route.snapshot.paramMap.get("id");
    this.getMemoryStorage(this.customerId);
    this.customerService.sharedData$.subscribe((data) => {
      if(data === true){
        this.getMemoryStorage(this.customerId);
      }
      // Do something with the updated data
    });
  }

  getMemoryStorage(customerId) {
    this.settingsService
      .getMemoryStorage(customerId)
      .subscribe((response: any) => {
        this.totalDiskSpace = response.data.totalDiskSpace;
        this.startDate = moment(this.currentDate).format("YYYY-MM");
        setTimeout(() => {
          this.getDataTransfer(this.startDate, "VOD", this.customerId);
          this.getLiveStreamTransfer(this.startDate, this.customerId);
        }, 200);

        this.dataUsage = response.data.totalDiskSpaceInUse;
        const usedMb = this.dataUsage / (1024 * 1024); // Used space in Mb
        this.totalStorageMemory = this.formatBytes(this.totalDiskSpace);
        this.usedUsageStorageMemory = this.formatBytes(this.dataUsage);
        // Example usage
        this.percentageUsed = this.calculateUsagePercentage(this.totalDiskSpace,this.dataUsage).toFixed(2);
        // if (this.totalDiskSpace === 536870912000) {
        //   this.totalDiskSpaceGB = (this.totalDiskSpace / 1024 ** 3).toFixed(2);
        //   console.log("test------------", this.totalDiskSpaceGB);
        //   this.totalDiskSpaceGB = Math.round(this.totalDiskSpaceGB / 500) * 500;
        //   console.log("test GB", this.totalDiskSpaceGB);
        // }
        // else {
        //   this.convertToTb(this.totalDiskSpace);
        // }
        if (response.data.totalDiskSpaceInUse) {
          this.totalUsedMemory = response.data.totalDiskSpaceInUse;
          this.totalUsedMemory = (this.totalUsedMemory / 1024).toFixed(2);
          this.calculateStorage();
        } else {
          this.totalUsedMemory = 0;
          this.totalUsedMemoryPercent = 0;
        }
        if (response.data.video) {
          this.videoMemory = this.convertMBtoGBorTB(response.data.video);
          this.totalVideoPercent = this.calculatePercent(this.videoMemory)
        }

        if (response.data.music) {
          this.audioMemory = this.convertMBtoGBorTB(response.data.music);
          this.totalAudioPercent = this.calculatePercent(this.audioMemory)
        }

        if (response.data.image) {
          this.imageMemory = this.convertMBtoGBorTB(response.data.image);
          this.totalImagePercent = this.calculatePercent(this.imageMemory)
        }

        if (response.data.document) {
          this.documentMemory = this.convertMBtoGBorTB(response.data.document);
          this.totalDocumentPercent = this.calculatePercent(this.documentMemory)
        }
      });
    this.sharedService.updateLoader(false);
  }

  convertToTb(diskSpace) {
    // Convert to gigabytes
    let totalDiskSpaceInGB = diskSpace / 1024 ** 3;
    // Round off to the nearest whole number
    let totalDiskSpaceInGBRounded = Math.round(totalDiskSpaceInGB);
    this.totalDiskSpaceGB = totalDiskSpaceInGBRounded;
    console.log(`Total Disk SPace in GB:  ${totalDiskSpaceInGBRounded}`);
    // Convert to terabytes
    let totalDiskSpaceInTB = diskSpace / 1024 ** 4;
    // Round off to the nearest whole number
    let totalDiskSpaceInTBRounded = Math.round(totalDiskSpaceInTB);
    this.totalDiskSpaceTB = totalDiskSpaceInTBRounded;
    console.log(`Total Disk Space in GB: ${totalDiskSpaceInGBRounded}`);
    console.log(`Total Disk Space in TB: ${totalDiskSpaceInTBRounded}`);
  }

  calculateStorage() {
    // if (this.organizationPlan === "Basic") {
    //   this.totalUsedMemoryPercent = (
    //     (this.totalUsedMemory / 500) *
    //     100
    //   ).toFixed(2);
    // }
    // else if (this.organizationPlan === "Standard") {
    //   this.totalUsedMemoryPercent = (
    //     (this.totalUsedMemory / (1 * 1024)) *
    //     100
    //   ).toFixed(2);
    // }
    //  else {

    // this.totalUsedMemoryPercent = (
    //   (this.totalUsedMemory / (this.totalDiskSpaceTB * 1024)) *
    //   100
    // ).toFixed(2);
    // }

    if (this.totalDiskSpaceGB === 500) {
      this.totalUsedMemoryPercent = (
        (this.totalUsedMemory / 500) *
        100
      ).toFixed(2);
    } else if (this.totalDiskSpaceTB === 1 || this.totalDiskSpaceTB === 5) {
      this.totalUsedMemoryPercent = (
        (this.totalUsedMemory / (this.totalDiskSpaceTB * 1024)) *
        100
      ).toFixed(2);
    }
    // else{

    // }
  }

  changeMonth(data) {
    this.startDate = data;
    this.currentDate = this.startDate;
    this.selectAllType = false;
    this.defaultVal = false;
    this.getDataTransfer(this.startDate, "VOD", this.customerId);
    // console.log(moment(e).format("YYYY/MM"))
    // console.log( moment(e).subtract(0, "month").startOf("month").format('MMMM'));
    // console.log( moment(date).subtract(0, "month").startOf("month").format('MMMM'));
  }

  SelectCurrent() {
    this.currentDate = new Date();
    this.dateData = "";
    this.startDate = moment(this.currentDate).format("YYYY-MM");
    this.defaultVal = true;
    this.getDataTransfer(this.startDate, "VOD", this.customerId);
    this.selectAllType = true;
  }

  // getDataTransfer(startDate: any, mediaType: any, organizationId: any) {
  //   startDate = moment(startDate).format("YYYY-MM");
  //   this.settingsService
  //     .getDataTranferUsage(startDate, mediaType, organizationId)
  //     .subscribe((res) => {
  //       this.dataUsage = res.data.vodUsage;
  //       const usedMb = this.dataUsage / (1024 * 1024); // Used space in Mb
  //       this.totalMemory = this.formatBytes(this.totalDiskSpace);
  //       this.usedUsageMemory = this.formatBytes(this.dataUsage);
  //       if (this.totalDiskSpace === 536870912000) {
  //         this.totalUsedUsagePercent = ((usedMb / (500 * 1024)) * 100).toFixed(
  //           2
  //         );
  //       } else if (this.totalDiskSpace === 1099511627776) {
  //         this.totalUsedUsagePercent = (
  //           ((usedMb / (1 * 1024)) * 100) /
  //           1024
  //         ).toFixed(2);
  //       } else {
  //         this.totalUsedUsagePercent = (
  //           ((usedMb / (5 * 1024)) * 100) /
  //           1024
  //         ).toFixed(2);
  //       }
  //     });
  // }

  getDataTransfer(startDate: any, mediaType: any, organizationId: any) {
    startDate = moment(startDate).format("YYYY-MM");
    this.settingsService
      .getDataTranferUsage(startDate, mediaType, organizationId,this.defaultVal)
      .subscribe((res) => {
        this.dataUsage = res.data.vodUsage;
        this.totalVODDataUsage = res.data.totalDiskSpace;
        const usedMb = this.dataUsage / (1024 * 1024); // Used space in Mb
        this.totalMemory = this.formatBytes(this.totalVODDataUsage);
        this.usedUsageMemory = this.formatBytes(this.dataUsage);
        // if (this.totalDiskSpace === 536870912000) {
        //   this.totalUsedUsagePercent = ((usedMb / (500 * 1024)) * 100).toFixed(
        //     2
        //   );
        // }
        // else if (this.totalDiskSpace === 1099511627776) {
        //   this.totalUsedUsagePercent = (
        //     ((usedMb / (1 * 1024)) * 100) /
        //     1024
        //   ).toFixed(2);
        // }
        // else {
        // this.totalUsedUsagePercent = (
        //   ((usedMb / (5 * 1024)) * 100) /
        //   1024
        // ).toFixed(2);
        // }

        // this.totalUsedUsagePercent = (
        //   ((usedMb / (5 * 1024)) * 100) /
        //   1024
        // ).toFixed(2);

        this.totalUsedUsagePercent  = this.calculateUsagePercentage(res.data.totalDiskSpace, res.data.vodUsage).toFixed(2);
      });
  }

  calculateUsagePercentage(totalBytes: number, usedBytes: number): number {
    if (totalBytes <= 0) {
        throw new Error('Total bytes must be greater than zero.');
    }
    return (usedBytes / totalBytes) * 100;
}

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  convertMBtoGBorTB(mb) {
    const gbValue = mb / 1024;
    const tbValue = mb / (1024 * 1024);

    // Set a threshold value (e.g., 1 TB) to decide between GB and TB
    const thresholdTB = 1;

    if (tbValue >= thresholdTB) {
      return `${tbValue.toFixed(2)} TB`;
    } else {
      return `${gbValue.toFixed(2)} GB`;
    }
  }

  calculateStoragePercentage(totalDiskSpace,totalDiskSpaceInUse) {
    let totalBytes = totalDiskSpace;
    // Convert used usage storage memory to bytes
    const usedBytes = parseFloat(totalDiskSpaceInUse) * 1024 * 1024 ;
    // Calculate percentage used
    const percentageUsed = (usedBytes / totalBytes) * 100;
    return percentageUsed.toFixed(2);
  }

  calculatePercent(data){
    let totalBytes;
    if (this.totalStorageMemory.includes("TB")) {
      totalBytes =
        parseFloat(this.totalStorageMemory) * 1024 * 1024 * 1024 * 1024;
    } else if (this.totalStorageMemory.includes("GB")) {
      totalBytes = parseFloat(this.totalStorageMemory) * 1024 * 1024 * 1024;
    }

    // Convert used usage storage memory to bytes
    const usedBytes =
      parseFloat(data) * 1024 * 1024 * 1024;

    // Calculate percentage used
    const percentageUsed = (usedBytes / totalBytes) * 100;

    return percentageUsed.toFixed(2);
  }

  getLiveStreamTransfer(startDate: any, organizationId: any) {
    startDate = moment(startDate).format("YYYY-MM");
    this.settingsService
      .getLiveStreamDataUsage(startDate, organizationId,this.liveDefaultVal)
      .subscribe((res) => {
        this.liveStreamDataUsage = res.data.livestreamUsage;
        this.liveStreamTotalDataUsage = res.data.totalDiskSpace;
        this.liveStreamTotalMemory = this.formatBytes(this.liveStreamTotalDataUsage);
        this.liveStreamUsageMemory = this.formatBytes(this.liveStreamDataUsage);
        this.liveStreamTotalUsedUsagePercent  = this.calculateUsagePercentage(res.data.totalDiskSpace, res.data.livestreamUsage).toFixed(2);
      });
  }

  changeMonthLiveStream(data) {
    this.liveStartDate = data;
    this.liveStreamCurrentDate = this.startDate;
    this.liveSelectAllType = false;
    this.liveDefaultVal = false;
    this.getLiveStreamTransfer(this.startDate, this.customerId);
  }

  selectLiveStreamCurrentDate() {
    this.liveStreamCurrentDate = new Date();
    this.liveDateData = "";
    this.liveStartDate = moment(this.liveStreamCurrentDate).format("YYYY-MM");
    this.liveDefaultVal = true;
    this.getLiveStreamTransfer(this.startDate, this.customerId);
    this.liveSelectAllType = true;
  }
}