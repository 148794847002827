import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppsService } from "src/app/services/apps/apps-service";
import { LazyLoadEvent, MessageService } from "primeng/api";
import * as moment from "moment";
@Component({
  selector: "app-push-notification-subscribers",
  templateUrl: "./push-notification-subscribers.component.html",
  styleUrls: ["./push-notification-subscribers.component.css"],
})
export class PushNotificationSubscribersComponent implements OnInit {
  orgId: number;
  pages: any[] = [];
  first = 0;
  page: number = 1;
  rows: number = 10;
  totalRecords = 0;
  clickFrom: any;
  groupid: any;
  rowCount: number;
  viewModal = false;
  selectedUser: any;
  timeZone: any;

  constructor(
    public router: Router,
    public appsService: AppsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.orgId = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails
          .organization.id
      : "";
    this.rowCount = window.innerWidth >= 1600 ? 50 : 10;
    this.timeZone = localStorage.getItem("loggedInUser")
      ? JSON.parse(localStorage.getItem("loggedInUser")).userDetails.timeZone
      : "";

    this.route.queryParams.subscribe((params) => {
      const clickFrom = params["clickFrom"];
      const groupid = params["groupid"];
      this.groupid = groupid;
      this.clickFrom = clickFrom;
      if (clickFrom === "PUSH_NOTIFICATION") {
        this.getNoticifationGroupDetails(
          this.page,
          this.rows,
          clickFrom,
          groupid
        );
      }
    });
  }

  loadCustomers(event: LazyLoadEvent) {
    this.first = event.first;
    this.page = event.first / event.rows + 1;
    this.rows = event.rows;
    this.getNoticifationGroupDetails(
      this.page,
      this.rows,
      this.clickFrom,
      this.groupid
    );
  }

  getNoticifationGroupDetails(page, size, clickFrom, groupid) {
    this.appsService
      .getPushNotificationDetailsList(
        page,
        size,
        this.orgId,
        clickFrom,
        groupid,
        "",
        ""
      )
      .subscribe((response: any) => {
        this.totalRecords = response.data.totalElements;

        if (clickFrom === "PUSH_NOTIFICATION") {
          this.pages = response.data.content.map((el) => ({
            deviceId: el.deviceDto.deviceId,
            deviceModel: el.deviceDto.deviceModel,
            deviceOS:
              el.deviceDto.deviceOS === "IOS" ? "iOS" : el.deviceDto.deviceOS,
            deviceType:
              el.deviceDto.deviceType === "MOBILE_IOS"
                ? "iOS MOBILE"
                : el.deviceDto.deviceType === "MOBILE_ANDROID"
                ? "ANDROID MOBILE"
                : el.deviceDto.deviceType === "TV_ANDROID"
                ? "ANDROID TV"
                : el.deviceDto.deviceType,
            appVersion: el.deviceDto.appVersion,
            email: el.email,
            mobile: el.mobile,
            firstName: el.firstName,
            lastName: el.lastName,
            createdDate: el.deviceDto.createdDate,
            userRegistrationDate: el.userRegistrationDate,
          }));
        }
        this.pages.forEach((el) => {
          if (el) {
            let userRegistrationDate = el.userRegistrationDate;

            if (el.userRegistrationDate) {
              let newUploadDate = moment.utc(el.userRegistrationDate);
              var displayCutoff = newUploadDate.clone().tz(this.timeZone);
              var formatDate =
                moment(displayCutoff).format("YYYY-MM-DD, h:mm a");
              el.userRegistrationDate = formatDate;
            }
          }
        });
      });
  }

  openViewModal(row) {
    console.log("row===", row);

    this.selectedUser = row;
    this.viewModal = true;
  }

  goBack() {
    this.router.navigate(["/apps-module/apps/pushNotification"]);
  }
}
