<div class="header-page-wrapper">
    <header [ngClass]="[panelState ? 'increaseHeader' : 'decreaseHeader']">
        <div class="header-content">
            <div class="d-flex align-items-center">
                <div class="ms-2">
                    <!-- <i (click)="toggle()" class="pi cp text-white pi-bars"></i> -->
                    <!-- <em (click)="toggle()" [appSvgIcon]="'hamburger'"></em> -->
                </div>
                <span class="hamburger-icon" (click)="openSidebar()">
                    <em [appSvgIcon]="'hamburger'"></em>
                </span>
                <h2>{{currentRoute}}</h2>
            </div>

            <div class="showLoadData" *ngIf="showUpdatePopup">
                <div dropdown>
                    <div class="icon-container dropup glowing" dropdownToggle>
                        <i class="fa fa-upload" aria-hidden="true"></i>
                    </div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                        <!-- <li *ngIf="!m3u8" ><a href="{{editMedia.videoUrl}}" download target="_blank">Download</a></li>
                        <li><a (click)="deleteVideo()">Delete</a></li> -->
                        <li *ngFor="let progressInfo of progressInfos; let i = index" [ngClass]="{'display-none': progressInfo.status === 'Done'}" class="mb-2 vedioUploadlist">
                            <div class="detail-wrapper">
                                <!-- <p>{{videoName}}</p>
                                <div class="progress mt-2" *ngIf="progress > 0">
                                    <div class="progress-bar  progress-bar-striped" style="background-color: #4e5071;" role="progressbar" [style.width.%]="progress">
                                        {{progress}}%
                                    </div>
                                </div> -->
                                <div >
                                    <div *ngIf="progressInfo.value > 0">
                                     <p class="ellipsis-fileName">
                                         <!-- <span style="margin-right: 10px;">{{i + 1}}.</span> -->
                                         {{ progressInfo.fileName }}</p>
                                     <div class="progress">
                                       <div
                                         class="progress-bar progress-bar-info progress-bar-striped"
                                         role="progressbar"
                                         attr.aria-valuenow="{{ progressInfo.value }}"
                                         aria-valuemin="0"
                                         aria-valuemax="100"
                                         [ngStyle]="{ width: progressInfo.value + '%' }"
                                       >
                                         {{ progressInfo.value }}%
                                       </div>
                                     </div>
                                    </div>
                                   </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="btn-group align-items-center" dropdown>
                <div class="d-flex align-items-center" dropdownToggle>
                    <span class="logged-in-user">
                        <img src="{{profileImg}}" alt="User">
                    </span>
                    <span class="pointer user-name" [tooltip]="userName">Hi, {{userName}}</span>
                </div>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                    <li *ngIf="activeCard" routerLink="/profile/my-profile"><a>My Profile</a></li>
                    <li (click)="logOut()"><a>Logout</a></li>
                </ul>
            </div>
        </div>
    </header>
</div>
