import { Component, OnInit } from '@angular/core';
import { LibraryServiceService } from './../../../../services/library/library-service.service'
import { AppAnimation } from './../../../../utilities/animations/animations';
import {SharedService} from './../../../../services/shared/shared.service'
import { WebListDesign } from './../../../../models/library.model';
import { MessageService } from 'primeng/api';
import {Subscription} from 'rxjs'

@Component({
  selector: 'app-web-list',
  templateUrl: './web-list.component.html',
  styleUrls: ['./web-list.component.css'],
  animations : AppAnimation
})
export class WebListComponent implements OnInit {
  
  overlay=false;
  sidePanel=false;
  row=false;
  grid=true;
  listDesign : WebListDesign
  itemLayouts : string
  catalogId : number
  webListDesign : any [] = []
  itemId : number
  subsciptionData = new Subscription();

  constructor(
    public libraryService:LibraryServiceService,
    public sharedService:SharedService,
    public messageService : MessageService
  ) { 
    this.listDesign = new WebListDesign()
   }

  ngOnInit(): void {
    this.libraryService.panel2$.subscribe((state:any)=>{
      this.overlay = state.showPanel
      this.sidePanel = state.showPanel
   })
   
   this.libraryService.sendCatalog$.subscribe((id:any) =>{
    this.catalogId = id
  } )

  //  this.libraryService.sendWebId$.subscribe((response : any) => {
     
  //    this.itemId = response
     
  //    this.getListDesign(this.itemId)   
  //  } )
   
  //  console.log('now')
  //     console.log('now')

  //  this.webListDesign.forEach((el) =>{
  //   this.itemId = el.id
  //   this.getListDesign(this.itemId)
  // } )
  // console.log(this.itemId)
  // console.log(this.webListDesign)

  this.getSubscription()
  
  }

  getSubscription(){

   this.subsciptionData.add(

    this.libraryService.sendWebId$.subscribe((response : any) => {
     
      this.itemId = response
      
      this.getListDesign(this.itemId)   
    } )

   )

  }

  closePanel(){
    this.overlay=false;
    this.sidePanel=false;
    this.libraryService.updatePanel2({showPanel: false})
    if(!this.itemId){
      this.row=false;
      this.grid=true;
      this.itemLayouts = 'GRID'
    }
  }
  rowOption(ROWS){
   this.row=true;
   this.grid=false
   this.itemLayouts = 'ROWS'
  }
  gridOption(GRID){
    this.grid=true
    this.row=false
    this.itemLayouts = 'GRID'
  }
  
  createListDesign(){
    const data = (JSON.parse(JSON.stringify(this.listDesign)))
    data.itemLayout = this.itemLayouts
    // data.carouselContent = [{
    //   id : 1,
    //   sortOrder : 1,
    //   type : 'MEDIA_ITEM'
    // }]
    data.catalogId = this.catalogId
    data.itemLayout = this.itemLayouts
    data.designType = 'WEB'
    // data.headerType = 'STATIC'
    // data.itemDisplay = 'IMAGE'
    // data.itemImages = "WIDE"
    // data.itemTitles = 'OVERLAY'
    // data.margins = true
    // data.shadow = true
    // data.staticImageId = 0
    
    
    
    if (this.itemId) {
      this.libraryService.updateListDesign(this.itemId, data).subscribe((response: any) => {
        
        this.libraryService.sendUpdateToCreate({ update : true })
        
        this.closePanel()
        // this.getListDesign(this.itemId)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item updated successfully` });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
    else {

    this.libraryService.createListDesign(data).subscribe((response) => {
       
       this.itemId = response.body.data.id
       this.libraryService.sendUpdateToCreate({ update : true })
        
        this.closePanel()
        // this.getListDesign(this.itemId)
       this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item created successfully` });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    
    }
  }

  getListDesign(id){
    this.libraryService.getListDesign(id).subscribe((response:any) => {
      
      this.listDesign = new WebListDesign(response.data)
      this.itemLayouts = response.data.itemLayout
      if(this.itemLayouts === 'GRID'){
        this.grid=true
         this.row=false
      }
      else{
        this.grid=false
         this.row=true
      }
    } )
  }


}
