import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, FormControl } from '@angular/forms';

@Directive({
    selector: '[appWhiteSpaceValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useClass: WhiteSpaceValidatorDirective,
            multi: true
        }
    ]
})
export class WhiteSpaceValidatorDirective implements Validator {

    validator: ValidatorFn;
    constructor() {
        this.validator = this.whiteSpaceValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    whiteSpaceValidator(): ValidatorFn {
        return (control: FormControl) => {
            if (control.value && control.value.trim() === '') {
                return {
                    whiteSpaceValidator: { valid: false }
                };
            } else {
                return null;
            }
        };
    }
}