export const TabSvgIcons = [
  //     {id:1,svgImg: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  //   <g id="Ellipse_50" data-name="Ellipse 50" fill="none" stroke="" stroke-width="2">
  //     <circle cx="8" cy="8" r="8" stroke="none"/>
  //     <circle cx="8" cy="8" r="7" fill="none"/>
  //   </g>
  // </svg>`},
  // {id:2, svgImg: `<svg xmlns="http://www.w3.org/2000/svg" width="14.309" height="16.414" viewBox="0 0 14.309 16.414">
  //   <g id="Group_237" data-name="Group 237" transform="translate(-783.992 -32.699)">
  //     <path id="Path_1079" data-name="Path 1079" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(773.406 660.406) rotate(-90)" fill="none" stroke="" stroke-width="2"/>
  //     <path id="Path_1080" data-name="Path 1080" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(778.094 660.406) rotate(-90)" fill="none" stroke="" stroke-width="2"/>
  //   </g>
  // </svg>`}
  {
    id: 1, svgImg: 'assets/images/tabIcons/Ellipse 50.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Ellipse_50" data-name="Ellipse 50" fill="none" stroke="" stroke-width="2">
      <circle cx="8" cy="8" r="8" stroke="none"/>
      <circle cx="8" cy="8" r="7" fill="none"/>
    </g>
  </svg>`},
  {
    id: 2, svgImg: 'assets/images/tabIcons/Group 237.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14.309" height="16.414" viewBox="0 0 14.309 16.414">
    <g id="Group_237" data-name="Group 237" transform="translate(-783.992 -32.699)">
      <path id="Path_1079" data-name="Path 1079" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(773.406 660.406) rotate(-90)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1080" data-name="Path 1080" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(778.094 660.406) rotate(-90)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 3, svgImg: 'assets/images/tabIcons/Group 239.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14.309" height="16.414" viewBox="0 0 14.309 16.414">
    <g id="Group_239" data-name="Group 239" transform="translate(-681.699 -32.699)">
      <path id="Path_1076" data-name="Path 1076" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(706.594 -578.594) rotate(90)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1075" data-name="Path 1075" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(701.906 -578.594) rotate(90)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 4, svgImg: 'assets/images/tabIcons/Group 240.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="14.309" viewBox="0 0 16.414 14.309">
    <g id="Group_240" data-name="Group 240" transform="translate(-626.293 -34.586)">
      <path id="Path_1073" data-name="Path 1073" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(15 24)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1074" data-name="Path 1074" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(15 28.688)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 5, svgImg: 'assets/images/tabIcons/Group 241.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14">
    <g id="Group_241" data-name="Group 241" transform="translate(-589.5 -34)">
      <line id="Line_7" data-name="Line 7" y2="12" transform="translate(595 35)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      <line id="Line_6" data-name="Line 6" x2="9" transform="translate(590.5 38)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 6, svgImg: 'assets/images/tabIcons/Group 242.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_242" data-name="Group 242" transform="translate(-544 -33)">
      <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(544 33)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <path id="Path_1072" data-name="Path 1072" d="M540,8.583v4.75l3.018,3.018" transform="translate(12 28)" fill="none" stroke="" stroke-width="1.5"/>
    </g>
  </svg>`},
  {
    id: 7, svgImg: 'assets/images/tabIcons/Group 244.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_244" data-name="Group 244" transform="translate(-460 -33)">
      <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(460 33)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(465 38)" fill="none" stroke="" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="2" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 8, svgImg: 'assets/images/tabIcons/Group 253.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_253" data-name="Group 253" transform="translate(-505 -33)">
      <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(505 33)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <g id="Polygon_1" data-name="Polygon 1" transform="translate(517.583 37.5) rotate(90)">
        <path id="Path_18906" data-name="Path 18906" d="M5.321,5.963H1.679a.5.5,0,0,1-.44-.738L3.06,1.862a.5.5,0,0,1,.879,0L5.761,5.225a.5.5,0,0,1-.44.738Z"/>
        <path id="Path_18907" data-name="Path 18907" d="M3.5,2.1,1.679,5.463H5.321L3.5,2.1m0-1a.99.99,0,0,1,.879.524L6.2,4.987a1,1,0,0,1-.879,1.476H1.679A1,1,0,0,1,.8,4.987L2.621,1.624A.99.99,0,0,1,3.5,1.1Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 9, svgImg: 'assets/images/tabIcons/Group 255.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g id="Group_255" data-name="Group 255" transform="translate(-31.5 -30.5)">
      <g id="play-button" transform="translate(32 31)">
        <g id="Group_221" data-name="Group 221">
          <g id="Group_220" data-name="Group 220">
            <path id="Path_1068" data-name="Path 1068" d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.857A6.857,6.857,0,1,1,14.857,8,6.857,6.857,0,0,1,8,14.857Z" fill="none" stroke="" stroke-width="1"/>
          </g>
        </g>
        <line id="Line_1" data-name="Line 1" y2="3" transform="translate(8 9)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
      <g id="Path_1069" data-name="Path 1069" transform="translate(39 35)">
        <path id="Path_18908" data-name="Path 18908" d="M1,1.5A.5.5,0,1,1,1.5,1,.5.5,0,0,1,1,1.5Z"/>
        <path id="Path_18909" data-name="Path 18909" d="M1,0A1,1,0,1,1,0,1,1,1,0,0,1,1,0Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 10, svgImg: 'assets/images/tabIcons/Group 256.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="12.028" height="15.414" viewBox="0 0 12.028 15.414">
    <g id="Group_256" data-name="Group 256" transform="translate(-82.486 -32.5)">
      <g id="Group_224" data-name="Group 224" transform="translate(-14 -1)">
        <line id="Line_2" data-name="Line 2" y2="13" transform="translate(102.5 34.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_1070" data-name="Path 1070" d="M149,138l4.6,4.6,4.6-4.6" transform="translate(-51.1 -95.1)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 11, svgImg: 'assets/images/tabIcons/Group 257.svg', svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="12.028" height="15.414" viewBox="0 0 12.028 15.414">
    <g id="Group_257" data-name="Group 257" transform="translate(-133.486 -31.086)">
      <g id="Group_227" data-name="Group 227" transform="translate(242 80) rotate(180)">
        <line id="Line_2" data-name="Line 2" y2="13" transform="translate(102.5 34.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_1070" data-name="Path 1070" d="M149,138l4.6,4.6,4.6-4.6" transform="translate(-51.1 -95.1)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 12, svgImg: `assets/images/tabIcons/Group 263.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_263" data-name="Group 263" transform="translate(-227 -32)">
      <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(227 32)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <g id="Group_262" data-name="Group 262" transform="translate(134.6 2.5)">
        <line id="Line_2" data-name="Line 2" y2="6.161" transform="translate(100.08 34.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_1070" data-name="Path 1070" d="M149,138l2.68,2.18,2.68-2.18" transform="translate(-51.6 -99.519)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 13, svgImg: `assets/images/tabIcons/Group 264.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_264" data-name="Group 264" transform="translate(-272 -32)">
      <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(272 32)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <g id="Group_261" data-name="Group 261" transform="translate(317.661 -60.4) rotate(90)">
        <line id="Line_2" data-name="Line 2" y2="6.161" transform="translate(100.08 34.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_1070" data-name="Path 1070" d="M149,138l2.68,2.18,2.68-2.18" transform="translate(-51.6 -99.519)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 14, svgImg: `assets/images/tabIcons/Group 265.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_265" data-name="Group 265" transform="translate(-313 -32)">
      <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(313 32)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <g id="Group_232" data-name="Group 232" transform="translate(420.76 77.661) rotate(180)">
        <line id="Line_2" data-name="Line 2" y2="6.161" transform="translate(100.08 34.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_1070" data-name="Path 1070" d="M149,138l2.68,2.18,2.68-2.18" transform="translate(-51.6 -99.519)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 15, svgImg: `assets/images/tabIcons/Group 266.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_266" data-name="Group 266" transform="translate(-351 -32)">
      <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(351 32)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <g id="Group_260" data-name="Group 260" transform="translate(321.5 139.76) rotate(-90)">
        <line id="Line_2" data-name="Line 2" y2="6.161" transform="translate(100.08 34.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
        <path id="Path_1070" data-name="Path 1070" d="M149,138l2.68,2.18,2.68-2.18" transform="translate(-51.6 -99.519)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 16, svgImg: `assets/images/tabIcons/Group 267.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
    <g id="Group_267" data-name="Group 267" transform="translate(-171 -31)">
      <g id="Group_258" data-name="Group 258">
        <g id="Rectangle_5" data-name="Rectangle 5" transform="translate(171 31)" fill="none" stroke="" stroke-width="2">
          <rect width="16" height="19" rx="2" stroke="none"/>
          <rect x="1" y="1" width="14" height="17" rx="1" fill="none"/>
        </g>
        <path id="Path_1071" data-name="Path 1071" d="M217.266,5.719V12l2.109-1.75,2,1.75V5.719Z" transform="translate(-42.375 27)" fill="none" stroke="" stroke-width="1"/>
      </g>
    </g>
  </svg>`},
  {
    id: 17, svgImg: `assets/images/tabIcons/Group 268.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.919" height="19.527" viewBox="0 0 17.919 19.527">
    <g id="Group_268" data-name="Group 268" transform="translate(-767.248 -32.1)">
      <g id="Group_236" data-name="Group 236" transform="translate(768.913 32.188)">
        <path id="Path_1081" data-name="Path 1081" d="M837.945,4.875,827.063,16.553l-1.463,4.5,4-1.875L840.487,7.624Z" transform="translate(-825.6 -3.493)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_1082" data-name="Path 1082" d="M828.938,11.188" transform="translate(-816.116 -11.188)" fill="none" stroke="#707070" stroke-width="2"/>
        <path id="Path_1083" data-name="Path 1083" d="M831.906,9.219l1.583,1.693.719.769" transform="translate(-821.747 -5.367)" fill="none" stroke="" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 18, svgImg: `assets/images/tabIcons/Group 269.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.187" viewBox="0 0 16 16.187">
    <g id="Group_269" data-name="Group 269" transform="translate(-829 -30.813)">
      <g id="Group_259" data-name="Group 259">
        <g id="Rectangle_7" data-name="Rectangle 7" transform="translate(829 33)" fill="none" stroke="" stroke-width="2">
          <rect width="16" height="14" rx="2" stroke="none"/>
          <rect x="1" y="1" width="14" height="12" rx="1" fill="none"/>
        </g>
        <g id="Rectangle_8" data-name="Rectangle 8" transform="translate(829 33)" fill="none" stroke="" stroke-width="2">
          <rect width="16" height="6" rx="2" stroke="none"/>
          <rect x="1" y="1" width="14" height="4" rx="1" fill="none"/>
        </g>
        <path id="Path_1084" data-name="Path 1084" d="M863.875,4.813V7.125" transform="translate(-32 26)" fill="none" stroke="" stroke-width="1"/>
        <path id="Path_1085" data-name="Path 1085" d="M863.875,4.813V7.125" transform="translate(-22 26)" fill="none" stroke="" stroke-width="1"/>
      </g>
    </g>
  </svg>`},
  {
    id: 19, svgImg: `assets/images/tabIcons/Group 270.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="16.632" viewBox="0 0 19 16.632">
    <g id="Group_270" data-name="Group 270" transform="translate(-894 -30)">
      <g id="Group_234" data-name="Group 234" transform="translate(-67 -1)">
        <path id="Path_1086" data-name="Path 1086" d="M889.333,7.832l3.723,3.648,3.574-4.355,3.57,4.355L904,7.832v10.8H889.333Z" transform="translate(74 28)" fill="none" stroke="" stroke-width="2"/>
        <circle id="Ellipse_9" data-name="Ellipse 9" cx="2" cy="2" r="2" transform="translate(961 31)"/>
        <circle id="Ellipse_10" data-name="Ellipse 10" cx="2" cy="2" r="2" transform="translate(968.5 31)"/>
        <circle id="Ellipse_11" data-name="Ellipse 11" cx="2" cy="2" r="2" transform="translate(976 31)"/>
      </g>
    </g>
  </svg>`},
  {
    id: 20, svgImg: `assets/images/tabIcons/Group 271.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_271" data-name="Group 271" transform="translate(-957 -30)">
      <g id="Group_235" data-name="Group 235" transform="translate(-67 -1)">
        <g id="Ellipse_12" data-name="Ellipse 12" transform="translate(1024 31)" fill="none" stroke="" stroke-width="2">
          <circle cx="8" cy="8" r="8" stroke="none"/>
          <circle cx="8" cy="8" r="7" fill="none"/>
        </g>
        <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(1028 35)" fill="none" stroke="" stroke-width="2">
          <circle cx="4" cy="4" r="4" stroke="none"/>
          <circle cx="4" cy="4" r="3" fill="none"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 21, svgImg: `assets/images/tabIcons/Group 272.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
    <g id="Group_272" data-name="Group 272" transform="translate(-1102 -32)">
      <g id="Polygon_2" data-name="Polygon 2" transform="translate(1102 32)">
        <path id="Path_18910" data-name="Path 18910" d="M7,0l7,12H0Z" fill="rgba(0,0,0,0)"/>
        <path id="Path_18911" data-name="Path 18911" d="M7,2.977,2.612,10.5h8.777L7,2.977M7,0l7,12H0Z"/>
      </g>
      <g id="Polygon_3" data-name="Polygon 3" transform="translate(1116 48) rotate(180)">
        <path id="Path_18912" data-name="Path 18912" d="M7,0l7,12H0Z" fill="rgba(0,0,0,0)"/>
        <path id="Path_18913" data-name="Path 18913" d="M7,2.977,2.612,10.5h8.777L7,2.977M7,0l7,12H0Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 22, svgImg: `assets/images/tabIcons/Group 275.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14.289" height="16.016" viewBox="0 0 14.289 16.016">
    <g id="Group_275" data-name="Group 275" transform="translate(-136.486 -79.5)">
      <g id="Union_1" data-name="Union 1" transform="translate(124.63 51.508)">
        <path id="Path_18914" data-name="Path 18914" d="M18.568,43.259,16.666,40h-3.8a.5.5,0,0,1-.432-.751L14.334,36l-1.9-3.248A.5.5,0,0,1,12.871,32h3.8l1.9-3.26a.5.5,0,0,1,.864,0l1.9,3.26h3.8a.5.5,0,0,1,.432.751L23.667,36l1.9,3.248A.5.5,0,0,1,25.13,40h-3.8l-1.9,3.26a.5.5,0,0,1-.864,0Z" fill="none" stroke="" stroke-width="1"/>
        <path id="Path_18915" data-name="Path 18915" d="M19,41.023l1.038-1.779a1.5,1.5,0,0,1,1.3-.744h2.055l-1.017-1.744a1.5,1.5,0,0,1,0-1.512L23.389,33.5H21.334a1.5,1.5,0,0,1-1.3-.744L19,30.977l-1.038,1.779a1.5,1.5,0,0,1-1.3.744H14.612l1.017,1.744a1.5,1.5,0,0,1,0,1.512L14.612,38.5h2.054a1.5,1.5,0,0,1,1.3.744L19,41.023m0,2.485a.5.5,0,0,1-.432-.248L16.666,40h-3.8a.5.5,0,0,1-.432-.751L14.334,36l-1.895-3.248A.5.5,0,0,1,12.871,32h3.8l1.9-3.26a.5.5,0,0,1,.864,0l1.9,3.26h3.8a.5.5,0,0,1,.432.751L23.667,36l1.895,3.248A.5.5,0,0,1,25.13,40h-3.8l-1.9,3.26A.5.5,0,0,1,19,43.508Z" stroke="" stroke-width="1"/>
      </g>
    </g>
  </svg>`},
  {
    id: 23, svgImg: `assets/images/tabIcons/Group 277.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17">
    <g id="Group_277" data-name="Group 277" transform="translate(-204 -79)">
      <g id="Union_2" data-name="Union 2" transform="translate(192 51)">
        <path id="Path_18916" data-name="Path 18916" d="M12.4,35.194h.05A4.606,4.606,0,0,1,12,33.209C12,30.333,14.687,28,18,28s6,2.333,6,5.209a4.7,4.7,0,0,1-.919,2.772L18.2,45Z" fill="none"/>
        <path id="Path_18917" data-name="Path 18917" d="M18,30a4.451,4.451,0,0,0-2.932,1.036A2.888,2.888,0,0,0,14,33.209a2.608,2.608,0,0,0,.257,1.126l.166.351,3.7,6.255,3.263-6.027.077-.106a2.681,2.681,0,0,0,.538-1.6,2.888,2.888,0,0,0-1.068-2.173A4.45,4.45,0,0,0,18,30m0-2c3.313,0,6,2.333,6,5.209a4.7,4.7,0,0,1-.919,2.772L18.2,45l-5.8-9.806h.05A4.606,4.606,0,0,1,12,33.209C12,30.333,14.687,28,18,28Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 24, svgImg: `assets/images/tabIcons/Group 284.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="19.483" height="14.518" viewBox="0 0 19.483 14.518">
    <g id="Group_284" data-name="Group 284" transform="translate(-412.517 -81.486)">
      <g id="Group_283" data-name="Group 283" transform="translate(5.937 -3.514)">
        <g id="Union_4" data-name="Union 4" transform="translate(399.186 60)">
          <path id="Path_18918" data-name="Path 18918" d="M21.249,37.192h-.132c-3.181,0-5.758-2.057-5.758-4.6S17.937,28,21.118,28s5.759,2.058,5.759,4.6a4.348,4.348,0,0,1-2.25,3.645l.2,3.273Z" fill="none"/>
          <path id="Path_18919" data-name="Path 18919" d="M23.148,36.637l-.073-1.2.767-.471A2.865,2.865,0,0,0,25.377,32.6a2.7,2.7,0,0,0-1.123-2.078,5.337,5.337,0,0,0-6.272,0,2.485,2.485,0,0,0,0,4.156,5.024,5.024,0,0,0,3.136,1.018h.1l.459-.008,1.467.953m1.678,2.878-3.577-2.322h-.132c-3.181,0-5.758-2.057-5.758-4.6S17.937,28,21.118,28s5.759,2.058,5.759,4.6a4.348,4.348,0,0,1-2.25,3.645Z"/>
        </g>
        <g id="Union_3" data-name="Union 3" transform="translate(390.675 57)">
          <path id="Path_18920" data-name="Path 18920" d="M17.906,36.211a4.361,4.361,0,0,1-2-3.543c0-2.578,2.57-4.668,5.741-4.668s5.742,2.091,5.742,4.668-2.571,4.669-5.742,4.669h-.255l-4.085,3.182Z" fill="none"/>
          <path id="Path_18921" data-name="Path 18921" d="M19.3,37.058l1.6-1.244.545.019a4.976,4.976,0,0,0,3.315-1.028,2.588,2.588,0,0,0,0-4.275,5.215,5.215,0,0,0-6.227,0A2.782,2.782,0,0,0,17.4,32.668a2.891,2.891,0,0,0,1.361,2.313l.753.526L19.3,37.058m-2,3.456.6-4.3a4.361,4.361,0,0,1-2-3.543c0-2.578,2.57-4.668,5.741-4.668s5.742,2.091,5.742,4.668-2.571,4.669-5.742,4.669q-.128,0-.255,0Z"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 25, svgImg: `assets/images/tabIcons/Group 286.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20.491" height="18.247" viewBox="0 0 20.491 18.247">
    <g id="Group_286" data-name="Group 286" transform="translate(-243.106 -78.316)">
      <g id="Group_276" data-name="Group 276">
        <path id="Path_1093" data-name="Path 1093" d="M242.5,47.75,236.917,60l13-3.75,1-5.083L247.75,47.75Z" transform="translate(8 35)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_1094" data-name="Path 1094" d="M239.167,59.737l6.848-5.237" transform="translate(5.986 34.917)" fill="none" stroke="" stroke-width="2"/>
        <g id="Rectangle_10" data-name="Rectangle 10" transform="translate(259.229 78.669) rotate(48)">
          <path id="Path_18922" data-name="Path 18922" d="M1,0H5A1,1,0,0,1,6,1V5H0V1A1,1,0,0,1,1,0Z" fill="none"/>
          <path id="Path_18923" data-name="Path 18923" d="M1,.75H5A.25.25,0,0,1,5.25,1V3.5a.75.75,0,0,1-.75.75h-3A.75.75,0,0,1,.75,3.5V1A.25.25,0,0,1,1,.75Z" fill="none" stroke="" stroke-width="2"/>
        </g>
        <circle id="Ellipse_18" data-name="Ellipse 18" cx="1.5" cy="1.5" r="1.5" transform="translate(251.2 87.3)"/>
      </g>
    </g>
  </svg>`},
  {
    id: 26, svgImg: `assets/images/tabIcons/Group 287.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <g id="Group_287" data-name="Group 287" transform="translate(-282 -82)">
      <g id="Polygon_7" data-name="Polygon 7" transform="translate(302 98) rotate(180)">
        <path id="Path_18924" data-name="Path 18924" d="M10,0,20,16H0Z" fill="none"/>
        <path id="Path_18925" data-name="Path 18925" d="M10,3.774,3.608,14H16.392L10,3.774M10,0,20,16H0Z"/>
      </g>
      <path id="Path_1095" data-name="Path 1095" d="M273.286,58.835H285" transform="translate(13 28)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1096" data-name="Path 1096" d="M276,62.286h6" transform="translate(13 29)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 27, svgImg: `assets/images/tabIcons/Group 291.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="14.828" viewBox="0 0 14.828 14.828">
    <g id="Group_291" data-name="Group 291" transform="translate(-487.086 -81.086)">
      <g id="Group_289" data-name="Group 289">
        <g id="Polygon_8" data-name="Polygon 8" transform="translate(491.773 86.324) rotate(45)">
          <path id="Path_18926" data-name="Path 18926" d="M4.876,3.348h-2.8L3.478,1.6Z"/>
          <path id="Path_18927" data-name="Path 18927" d="M3.478.3a.5.5,0,0,1,.39.188L6.307,3.536a.5.5,0,0,1-.39.812H1.04a.5.5,0,0,1-.39-.812L3.088.488A.5.5,0,0,1,3.478.3Z"/>
        </g>
        <line id="Line_12" data-name="Line 12" x1="3" y2="3" transform="translate(488.5 91.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
      <g id="Group_290" data-name="Group 290">
        <g id="Polygon_8-2" data-name="Polygon 8" transform="translate(497.096 90.649) rotate(-135)">
          <path id="Path_18928" data-name="Path 18928" d="M4.876,3.348h-2.8L3.478,1.6Z"/>
          <path id="Path_18929" data-name="Path 18929" d="M3.478.3a.5.5,0,0,1,.39.188L6.307,3.536a.5.5,0,0,1-.39.812H1.04a.5.5,0,0,1-.39-.812L3.088.488A.5.5,0,0,1,3.478.3Z"/>
        </g>
        <line id="Line_12-2" data-name="Line 12" y1="3" x2="3" transform="translate(497.5 82.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 28, svgImg: `assets/images/tabIcons/Group 292.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
    <g id="Group_292" data-name="Group 292" transform="translate(-525 -81)">
      <g id="Union_5" data-name="Union 5" transform="translate(513 52)">
        <path id="Path_18930" data-name="Path 18930" d="M12,44V30h5.268a2,2,0,0,1,3.464,0H26V44Z" fill="none"/>
        <path id="Path_18931" data-name="Path 18931" d="M24.5,42.5v-11H19.867l-.433-.749a.5.5,0,0,0-.868,0l-.433.749H13.5v11h11M26,44H12V30h5.268a2,2,0,0,1,3.464,0H26Z"/>
      </g>
      <path id="Path_1102" data-name="Path 1102" d="M516,61.286l2.581,2.581,4.919-4.776" transform="translate(12.5 27.052)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 29, svgImg: `assets/images/tabIcons/Group 294.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="9.379" height="16" viewBox="0 0 9.379 16">
    <g id="Group_294" data-name="Group 294" transform="translate(-455.759 -80)">
      <g id="Path_1100" data-name="Path 1100" transform="translate(455.759 80)">
        <path id="Path_18932" data-name="Path 18932" d="M3.966,7.828H1.71l2.256-4.01Z"/>
        <path id="Path_18933" data-name="Path 18933" d="M4.966,0V8.828H0Z"/>
      </g>
      <g id="Path_1101" data-name="Path 1101" transform="translate(460.172 87.172)">
        <path id="Path_18934" data-name="Path 18934" d="M.5,6.919V.5H4.111Z"/>
        <path id="Path_18935" data-name="Path 18935" d="M1,5.01,3.256,1H1V5.01M0,8.828V0H4.966Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 30, svgImg: `assets/images/tabIcons/Group 295.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="16" viewBox="0 0 16.414 16">
    <g id="Group_295" data-name="Group 295" transform="translate(-452.293 -80)">
      <g id="Group_293" data-name="Group 293">
        <g id="Path_1100" data-name="Path 1100" transform="translate(455.759 80)">
          <path id="Path_18936" data-name="Path 18936" d="M3.966,7.828H1.71l2.256-4.01Z"/>
          <path id="Path_18937" data-name="Path 18937" d="M4.966,0V8.828H0Z"/>
        </g>
        <g id="Path_1101" data-name="Path 1101" transform="translate(460.172 87.172)">
          <path id="Path_18938" data-name="Path 18938" d="M.5,6.919V.5H4.111Z"/>
          <path id="Path_18939" data-name="Path 18939" d="M1,5.01,3.256,1H1V5.01M0,8.828V0H4.966Z"/>
        </g>
      </g>
      <line id="Line_8" data-name="Line 8" x1="2" y2="2" transform="translate(466 81)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_10" data-name="Line 10" x1="2" y1="2" transform="translate(453 81)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_9" data-name="Line 9" x1="2" y2="2" transform="translate(453 93)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_11" data-name="Line 11" x1="2" y1="2" transform="translate(466 93)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 31, svgImg: `assets/images/tabIcons/Group 296.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.5" viewBox="0 0 20 16.5">
    <g id="Group_296" data-name="Group 296" transform="translate(-589.5 -77.5)">
      <g id="Union_6" data-name="Union 6" transform="translate(583 54)">
        <path id="Path_18940" data-name="Path 18940" d="M15.5,40a1,1,0,0,1-1-1V36.532a4.5,4.5,0,1,1,4,0V39a1,1,0,0,1-1,1Z" fill="none"/>
        <path id="Path_18941" data-name="Path 18941" d="M17,38.5V35.6l.832-.414a3,3,0,1,0-2.665,0L16,35.6v2.9h1m.5,1.5h-2a1,1,0,0,1-1-1V36.532a4.5,4.5,0,1,1,4,0V39A1,1,0,0,1,17.5,40Z"/>
      </g>
      <line id="Line_13" data-name="Line 13" y1="2" x2="3" transform="translate(605.39 79.686) rotate(-7)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_16" data-name="Line 16" y1="2" x2="3" transform="translate(591.389 90.69) rotate(-7)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_14" data-name="Line 14" x2="3" y2="2" transform="translate(604.633 90.325) rotate(7)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_17" data-name="Line 17" x2="3" y2="2" transform="translate(590.633 79.145) rotate(7)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_18" data-name="Line 18" y2="3" transform="translate(599.5 77.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_19" data-name="Line 19" x2="4" transform="translate(605.5 86.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_20" data-name="Line 20" x2="4" transform="translate(589.5 86.5)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 32, svgImg: `assets/images/tabIcons/Group 297.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4">
    <g id="Group_297" data-name="Group 297" transform="translate(-327 -87)">
      <g id="Ellipse_19" data-name="Ellipse 19" transform="translate(327 87)" stroke="" stroke-width="2">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="1" fill="none"/>
      </g>
      <g id="Ellipse_20" data-name="Ellipse 20" transform="translate(334 87)" stroke="" stroke-width="2">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="1" fill="none"/>
      </g>
      <g id="Ellipse_21" data-name="Ellipse 21" transform="translate(341 87)" stroke="" stroke-width="2">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="1" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 33, svgImg: `assets/images/tabIcons/Group 298.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="12" viewBox="0 0 17.5 12">
    <g id="Group_298" data-name="Group 298" transform="translate(-629 -80.5)">
      <g id="Ellipse_26" data-name="Ellipse 26" transform="translate(629 80.5)" stroke="" stroke-width="2">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" fill="none"/>
      </g>
      <g id="Ellipse_27" data-name="Ellipse 27" transform="translate(629 85.5)" stroke="" stroke-width="2">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" fill="none"/>
      </g>
      <g id="Ellipse_28" data-name="Ellipse 28" transform="translate(629 90.5)" stroke="" stroke-width="2">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" fill="none"/>
      </g>
      <line id="Line_21" data-name="Line 21" x2="11.5" transform="translate(634 81.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      <line id="Line_22" data-name="Line 22" x2="11.5" transform="translate(634 86.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      <line id="Line_23" data-name="Line 23" x2="11.5" transform="translate(634 91.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 34, svgImg: `assets/images/tabIcons/Group 299.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13">
    <g id="Group_299" data-name="Group 299" transform="translate(-661 -81)">
      <g id="Union_7" data-name="Union 7" transform="translate(651 53)">
        <path id="Path_18942" data-name="Path 18942" d="M14,41a1,1,0,0,1-1-1V35.042l-3-1.625,3-1.626V29a1,1,0,0,1,1-1H28a1,1,0,0,1,1,1V40a1,1,0,0,1-1,1Z" fill="none"/>
        <path id="Path_18943" data-name="Path 18943" d="M27.5,39.5v-10h-13v3.185l-1.351.732,1.351.732V39.5h13M28,41H14a1,1,0,0,1-1-1V35.042l-3-1.625,3-1.626V29a1,1,0,0,1,1-1H28a1,1,0,0,1,1,1V40A1,1,0,0,1,28,41Z"/>
      </g>
      <line id="Line_24" data-name="Line 24" x2="7" transform="translate(667.5 85.5)" fill="none" stroke="" stroke-width="1"/>
      <line id="Line_25" data-name="Line 25" x2="5" transform="translate(667.5 87.5)" fill="none" stroke="" stroke-width="1"/>
    </g>
  </svg>`},
  {
    id: 35, svgImg: `assets/images/tabIcons/Group 300.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16.25" height="16.5" viewBox="0 0 16.25 16.5">
    <g id="Group_300" data-name="Group 300" transform="translate(-730.5 -77)">
      <g id="Rectangle_17" data-name="Rectangle 17" transform="translate(742.5 80)">
        <path id="Path_18944" data-name="Path 18944" d="M0,0H3A1,1,0,0,1,4,1V4A1,1,0,0,1,3,5H0Z" fill="none"/>
        <path id="Path_18945" data-name="Path 18945" d="M1.5.75H3A.25.25,0,0,1,3.25,1V4A.25.25,0,0,1,3,4.25H1.5A.75.75,0,0,1,.75,3.5v-2A.75.75,0,0,1,1.5.75Z" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Rectangle_16" data-name="Rectangle 16" transform="translate(732 80)">
        <path id="Path_18946" data-name="Path 18946" d="M0,0H12V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z" fill="none"/>
        <path id="Path_18947" data-name="Path 18947" d="M1.5.75h9a.75.75,0,0,1,.75.75V8A1.25,1.25,0,0,1,10,9.25H2A1.25,1.25,0,0,1,.75,8V1.5A.75.75,0,0,1,1.5.75Z" fill="none" stroke="" stroke-width="2"/>
      </g>
      <line id="Line_26" data-name="Line 26" x2="16" transform="translate(730.5 92.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_27" data-name="Line 27" y2="2" transform="translate(736 77)" fill="none" stroke="#0f0000" stroke-width="2"/>
      <line id="Line_28" data-name="Line 28" y2="1" transform="translate(738.5 78)" fill="none" stroke="#0f0000" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 36, svgImg: `assets/images/tabIcons/Group 301.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="21.593" height="17" viewBox="0 0 21.593 17">
    <g id="Group_301" data-name="Group 301" transform="translate(-760 -79)">
      <path id="Path_1104" data-name="Path 1104" d="M748,52h2.744l4.01,8H765.94l2.5-5.812" transform="translate(12 28)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1105" data-name="Path 1105" d="M756.659,59l-1.847,2.955h15.249" transform="translate(9.939 28.754)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1106" data-name="Path 1106" d="M.2-.116c4.327-.066,12.478,0,16.594,0" transform="translate(763.799 82.162)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      <g id="Ellipse_29" data-name="Ellipse 29" transform="translate(764 93)" stroke="" stroke-width="2">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
      </g>
      <g id="Ellipse_30" data-name="Ellipse 30" transform="translate(776 93)" stroke="" stroke-width="2">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 37, svgImg: `assets/images/tabIcons/Group 8455.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
    <g id="Group_8455" data-name="Group 8455" transform="translate(-808 -80)">
      <g id="Path_18796" data-name="Path 18796" transform="matrix(-0.695, -0.719, 0.719, -0.695, 819.819, 87)">
        <path id="Path_18948" data-name="Path 18948" d="M3.567,2.768,1.976,2.752l.76-.786Z" fill="#fff"/>
        <path id="Path_18949" data-name="Path 18949" d="M2.711.551,6.068,3.793-.357,3.729Z"/>
      </g>
      <path id="Path_18795" data-name="Path 18795" d="M797.25,64.063,802.313,59l2.438,2.438L806.25,60,810,64.063" transform="translate(12 28)" fill="none" stroke="" stroke-width="2"/>
      <g id="Path_18794" data-name="Path 18794" transform="translate(808 80)">
        <path id="Path_18950" data-name="Path 18950" d="M0,0H12.732l1.19,1.391L15,2.667V16H0Z" fill="none"/>
        <path id="Path_18951" data-name="Path 18951" d="M2,2V14H13V3.4l-.6-.712L11.811,2H2M0,0H12.732l1.19,1.391L15,2.667V16H0Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 38, svgImg: `assets/images/tabIcons/Group 8456.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13">
    <g id="Group_8456" data-name="Group 8456" transform="translate(-848 -75)">
      <g id="Rectangle_18" data-name="Rectangle 18" transform="translate(848 75)" fill="none" stroke="" stroke-width="2">
        <rect width="16" height="13" rx="2" stroke="none"/>
        <rect x="1" y="1" width="14" height="11" rx="1" fill="none"/>
      </g>
      <path id="Path_18797" data-name="Path 18797" d="M836.785,50.333,844,54.688l6.667-4.354" transform="translate(12 28)" fill="none" stroke="" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 39, svgImg: `assets/images/tabIcons/Group 8457.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="15.434" height="15.434" viewBox="0 0 15.434 15.434">
    <g id="Group_8457" data-name="Group 8457" transform="translate(-913.066 -75.5)">
      <path id="google" d="M0,7.217A7.225,7.225,0,0,1,7.217,0a7.149,7.149,0,0,1,4.4,1.5l-.714.927A6.045,6.045,0,1,0,13.235,7.8H7.217V6.632h7.217v.585A7.217,7.217,0,0,1,0,7.217Z" transform="translate(913.566 76)" stroke="" stroke-width="1"/>
    </g>
  </svg>`},
  {
    id: 40, svgImg: `assets/images/tabIcons/Group 8458.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="11.769" height="15.933" viewBox="0 0 11.769 15.933">
    <g id="Group_8458" data-name="Group 8458" transform="translate(-949.038 -72.817)">
      <g id="Group_8452" data-name="Group 8452" transform="translate(949.852 73.567)">
        <g id="Group_8451" data-name="Group 8451">
          <path id="Path_18781" data-name="Path 18781" d="M81.074,0a5.075,5.075,0,0,0-4.317,7.742l4.028,6.491a.423.423,0,0,0,.359.2h0a.423.423,0,0,0,.359-.206l3.925-6.554A5.075,5.075,0,0,0,81.074,0Zm3.633,7.24L81.138,13.2,77.476,7.3a4.231,4.231,0,1,1,7.231-.057Z" transform="translate(-76)" stroke="" stroke-width="1.5"/>
        </g>
      </g>
      <g id="Group_8454" data-name="Group 8454" transform="translate(952.389 76.104)">
        <g id="Group_8453" data-name="Group 8453">
          <path id="Path_18782" data-name="Path 18782" d="M168.537,90a2.537,2.537,0,1,0,2.537,2.537A2.537,2.537,0,0,0,168.537,90Zm0,4.234a1.7,1.7,0,1,1,1.694-1.7,1.7,1.7,0,0,1-1.694,1.7Z" transform="translate(-166 -90)" stroke="" stroke-width="1"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 41, svgImg: `assets/images/tabIcons/Group 8459.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18.532" viewBox="0 0 17 18.532">
    <g id="Group_8459" data-name="Group 8459" transform="translate(-884 -76)">
      <g id="Union_8" data-name="Union 8" transform="translate(869.473 48)">
        <path id="Path_18952" data-name="Path 18952" d="M17.372,41a3,3,0,0,1-2.845-3V31a3,3,0,0,1,3-3h11a3,3,0,0,1,3,3v7a3,3,0,0,1-3,3H22.913l-6.264,5.531Z" fill="none"/>
        <path id="Path_18953" data-name="Path 18953" d="M19.323,41.5,22.156,39h6.37a1,1,0,0,0,1-1V31a1,1,0,0,0-1-1h-11a1,1,0,0,0-1,1v7a1,1,0,0,0,.947,1l2.163.109L19.323,41.5m-2.674,5.03L17.372,41a3,3,0,0,1-2.845-3V31a3,3,0,0,1,3-3h11a3,3,0,0,1,3,3v7a3,3,0,0,1-3,3H22.913Z"/>
      </g>
      <line id="Line_29" data-name="Line 29" x2="8" transform="translate(888.5 81)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_30" data-name="Line 30" x2="8" transform="translate(888.5 84)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 42, svgImg: `assets/images/tabIcons/Group 8460.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="15.186" height="16" viewBox="0 0 15.186 16">
    <g id="Group_8460" data-name="Group 8460" transform="translate(-965.407 -79)">
      <g id="Union_9" data-name="Union 9" transform="translate(936.407 29)">
        <path id="Path_18954" data-name="Path 18954" d="M34.592,66V63.658A5.978,5.978,0,0,1,33.279,63l-1.871,1.41L29,61.217l1.87-1.409a6.03,6.03,0,0,1,.067-3.815l-1.82-1.474,2.517-3.109,1.821,1.475a5.975,5.975,0,0,1,1.137-.543V50h4v2.341A5.976,5.976,0,0,1,39.907,53l1.871-1.41,2.408,3.195-1.87,1.409a6.031,6.031,0,0,1-.067,3.814l1.82,1.474-2.517,3.109-1.82-1.474a5.972,5.972,0,0,1-1.138.544V66Z" fill="none"/>
        <path id="Path_18955" data-name="Path 18955" d="M37.093,64.5V62.6l1-.353a4.481,4.481,0,0,0,.852-.407l.9-.556,1.48,1.2.629-.777L40.48,60.5l.355-1a4.531,4.531,0,0,0,.05-2.861l-.319-1.011,1.519-1.145-.6-.8-1.521,1.146-.885-.587a4.48,4.48,0,0,0-.984-.492l-1-.353V51.5h-1v1.9l-1,.354a4.484,4.484,0,0,0-.852.407l-.9.556-1.48-1.2-.629.777,1.479,1.2-.355,1a4.53,4.53,0,0,0-.05,2.862l.319,1.011L31.1,61.512l.6.8,1.521-1.146.884.587a4.483,4.483,0,0,0,.984.492l1,.354v1.9h1m1.5,1.5h-4V63.658A5.978,5.978,0,0,1,33.279,63l-1.871,1.41L29,61.217l1.87-1.409a6.03,6.03,0,0,1,.067-3.815l-1.82-1.474,2.517-3.109,1.821,1.475a5.975,5.975,0,0,1,1.137-.543V50h4v2.341A5.975,5.975,0,0,1,39.907,53l1.871-1.41,2.408,3.195-1.87,1.409a6.031,6.031,0,0,1-.067,3.814l1.82,1.474-2.517,3.109-1.82-1.474a5.972,5.972,0,0,1-1.138.544Z"/>
      </g>
      <g id="Ellipse_32" data-name="Ellipse 32" transform="translate(970 84)" fill="none" stroke="" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="2" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 43, svgImg: `assets/images/tabIcons/Group 8463.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.611" height="18.504" viewBox="0 0 17.611 18.504">
    <g id="Group_8463" data-name="Group 8463" transform="translate(-1045.964 -79.882)">
      <g id="Path_18798" data-name="Path 18798" transform="translate(1045.961 79.997)">
        <path id="Path_18956" data-name="Path 18956" d="M1.573,0l14.58.007,1.261,4.356L.2,4.365Z" fill="none"/>
        <path id="Path_18957" data-name="Path 18957" d="M15.506,3.026l-.487-1.688-12.3,0L2.195,3.026H15.506m1.908,1.336L.2,4.365,1.573,0,16.153.007Z"/>
      </g>
      <path id="Path_18799" data-name="Path 18799" d="M1034.554,30.813l-3.824,3.059-2.855,12.338" transform="translate(26.988 49.85)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18800" data-name="Path 18800" d="M1019.875,30.813l3.416,2.906,2.906,12.44" transform="translate(28.462 49.85)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18801" data-name="Path 18801" d="M1017.976,34.863l7.846,12.565,8.076-12.565" transform="translate(28.836 49.09)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 44, svgImg: `assets/images/tabIcons/Group 8464.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
    <g id="Group_8464" data-name="Group 8464" transform="translate(-71 -136)">
      <g id="Rectangle_4239" data-name="Rectangle 4239" transform="translate(71 136)">
        <path id="Path_18958" data-name="Path 18958" d="M0,0H8a4,4,0,0,1,4,4V16H0Z" fill="none"/>
        <path id="Path_18959" data-name="Path 18959" d="M2,1H8a3,3,0,0,1,3,3V14a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V2A1,1,0,0,1,2,1Z" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Path_18837" data-name="Path 18837" transform="translate(82 136)">
        <path id="Path_18960" data-name="Path 18960" d="M4,0h8V16H0V4A4,4,0,0,1,4,0Z" fill="none"/>
        <path id="Path_18961" data-name="Path 18961" d="M4,2A2,2,0,0,0,2,4V14h8V2H4M4,0h8V16H0V4A4,4,0,0,1,4,0Z"/>
      </g>
      <g id="Ellipse_33" data-name="Ellipse 33" transform="translate(80.5 149)" stroke="" stroke-width="2">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="1" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 45, svgImg: `assets/images/tabIcons/Group 8472.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18.029" height="16.072" viewBox="0 0 18.029 16.072">
    <g id="Group_8472" data-name="Group 8472" transform="translate(-1005.971 -79.917)">
      <g id="Group_8462" data-name="Group 8462" transform="translate(0.013 3.011)">
        <g id="Group_8461" data-name="Group 8461" transform="translate(1005.947 76.917)">
          <g id="Rectangle_4237" data-name="Rectangle 4237" transform="translate(0.04 4.072)">
            <path id="Path_18962" data-name="Path 18962" d="M0,0H17a1,1,0,0,1,1,1V8.989a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3Z" fill="none"/>
            <path id="Path_18963" data-name="Path 18963" d="M2,1H17V8.989a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V2A1,1,0,0,1,2,1Z" fill="none" stroke="" stroke-width="2"/>
          </g>
          <line id="Line_31" data-name="Line 31" x2="5" transform="translate(6.54 8.572)" fill="none" stroke="" stroke-width="2"/>
          <g id="Rectangle_4238" data-name="Rectangle 4238" transform="translate(-0.013 -0.011)">
            <path id="Path_18964" data-name="Path 18964" d="M2.009,5.243h15.04a1.544,1.544,0,0,0,.391-.049,1.162,1.162,0,0,0,.318-.133.7.7,0,0,0,.213-.2.422.422,0,0,0,.077-.242L18,0H.1L.024,4a.835.835,0,0,0,.148.483,1.38,1.38,0,0,0,.428.4,2.3,2.3,0,0,0,.634.266,3.079,3.079,0,0,0,.775.1Z" fill="none"/>
            <path id="Path_18965" data-name="Path 18965" d="M16.019,1.225,16.037,4H2.029l.044-2.776H16.019M18,0H.1L.024,4a.835.835,0,0,0,.148.483A1.38,1.38,0,0,0,.6,4.879a2.3,2.3,0,0,0,.634.266,3.079,3.079,0,0,0,.78.1h15.04a1.544,1.544,0,0,0,.391-.049,1.162,1.162,0,0,0,.318-.133.7.7,0,0,0,.213-.2.422.422,0,0,0,.077-.242Z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 46, svgImg: `assets/images/tabIcons/Group 8473.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.01" height="15.25" viewBox="0 0 17.01 15.25">
    <g id="Group_8473" data-name="Group 8473" transform="translate(-112.24 -136.75)">
      <g id="Group_8471" data-name="Group 8471">
        <g id="Rectangle_4241" data-name="Rectangle 4241" transform="translate(116.5 137)">
          <path id="Path_18966" data-name="Path 18966" d="M3,0h9.5V6H0V3A3,3,0,0,1,3,0Z" fill="none"/>
          <path id="Path_18967" data-name="Path 18967" d="M3,.75h8a.75.75,0,0,1,.75.75v3a.75.75,0,0,1-.75.75H1.5A.75.75,0,0,1,.75,4.5V3A2.25,2.25,0,0,1,3,.75Z" fill="none" stroke="" stroke-width="2"/>
        </g>
        <g id="Group_8466" data-name="Group 8466" transform="translate(0 2.698)">
          <g id="Ellipse_34" data-name="Ellipse 34" transform="translate(112.24 143.302)" fill="none" stroke="" stroke-width="2">
            <circle cx="3" cy="3" r="3" stroke="none"/>
            <circle cx="3" cy="3" r="2" fill="none"/>
          </g>
          <path id="Path_18802" data-name="Path 18802" d="M-.253.469V8.833" transform="translate(117.501 137.5)" fill="none" stroke="" stroke-width="2"/>
        </g>
        <g id="Group_8465" data-name="Group 8465" transform="translate(-2 2.789)">
          <g id="Ellipse_35" data-name="Ellipse 35" transform="translate(125.24 143.211)" fill="none" stroke="" stroke-width="2">
            <circle cx="3" cy="3" r="3" stroke="none"/>
            <circle cx="3" cy="3" r="2" fill="none"/>
          </g>
          <path id="Path_18803" data-name="Path 18803" d="M-.25.469V8.438" transform="translate(130.491 137.742)" fill="none" stroke="" stroke-width="2"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 47, svgImg: `assets/images/tabIcons/Group 8475.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="13.5" viewBox="0 0 10 13.5">
    <g id="Group_8475" data-name="Group 8475" transform="translate(-172.5 -136)">
      <g id="Group_8468" data-name="Group 8468">
        <g id="Path_18806" data-name="Path 18806" transform="translate(172.5 136)">
          <path id="Path_18968" data-name="Path 18968" d="M5,0a5,5,0,0,1,5,5c0,2.761-5,8.5-5,8.5S0,7.761,0,5A5,5,0,0,1,5,0Z" fill="none"/>
          <path id="Path_18969" data-name="Path 18969" d="M5,1.5A3.5,3.5,0,0,0,1.5,5c0,.293.16,1.475,2.213,4.4.447.638.9,1.233,1.287,1.729.384-.49.829-1.078,1.272-1.709C8.339,6.48,8.5,5.294,8.5,5A3.5,3.5,0,0,0,5,1.5M5,0a5,5,0,0,1,5,5c0,2.761-5,8.5-5,8.5S0,7.761,0,5A5,5,0,0,1,5,0Z"/>
        </g>
        <g id="Ellipse_38" data-name="Ellipse 38" transform="translate(176 139.5)" stroke="" stroke-width="2">
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
          <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 48, svgImg: `assets/images/tabIcons/Group 8476.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g id="Group_8476" data-name="Group 8476" transform="translate(-200 -135)">
      <g id="Group_8469" data-name="Group 8469">
        <g id="Ellipse_39" data-name="Ellipse 39" transform="translate(200 135)" fill="none" stroke="" stroke-width="2">
          <circle cx="8.5" cy="8.5" r="8.5" stroke="none"/>
          <circle cx="8.5" cy="8.5" r="7.5" fill="none"/>
        </g>
        <g id="Ellipse_40" data-name="Ellipse 40" transform="translate(205 135)" fill="none" stroke="" stroke-width="2">
          <ellipse cx="3.5" cy="8" rx="3.5" ry="8" stroke="none"/>
          <ellipse cx="3.5" cy="8" rx="2.5" ry="7" fill="none"/>
        </g>
        <path id="Path_18807" data-name="Path 18807" d="M.313,0H15.5" transform="translate(200.5 141)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18808" data-name="Path 18808" d="M.344,0H15.5" transform="translate(200.5 146)" fill="none" stroke="" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 49, svgImg: `assets/images/tabIcons/Group 8477.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="24.116" height="23.059" viewBox="0 0 24.116 23.059">
    <g id="Group_8477" data-name="Group 8477" transform="translate(-238.865 -129.47)">
      <g id="Group_8470" data-name="Group 8470">
        <g id="Path_18809" data-name="Path 18809" transform="translate(242 142)">
          <path id="Path_18970" data-name="Path 18970" d="M0,0H18s2.833,5.786-1.667,8.286A13.8,13.8,0,0,1,9.167,10,15.035,15.035,0,0,1,1.25,8.286C-3,5.786,0,0,0,0Z" fill="none" stroke="" stroke-width="1"/>
          <path id="Path_18971" data-name="Path 18971" d="M.991,1.5A8.8,8.8,0,0,0,.52,3.39c-.283,2.276.715,3.146,1.491,3.6A13.043,13.043,0,0,0,8.656,8.506c.157,0,.318,0,.48-.005A12.325,12.325,0,0,0,15.6,6.974,3.224,3.224,0,0,0,17.092,5.4a4.32,4.32,0,0,0,.272-2,8.138,8.138,0,0,0-.388-1.9H.991M0,0H18s2.833,5.786-1.667,8.286A13.8,13.8,0,0,1,9.167,10,15.035,15.035,0,0,1,1.25,8.286C-3,5.786,0,0,0,0Z" stroke="" stroke-width="1"/>
        </g>
        <path id="Path_18810" data-name="Path 18810" d="M1.764,1.938S4.125,1,4,7" transform="translate(246.923 136)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18811" data-name="Path 18811" d="M4,2S.955,1,1.08,7" transform="translate(249.85 136)" fill="none" stroke="" stroke-width="2"/>
        <g id="Path_18812" data-name="Path 18812" transform="matrix(0.643, -0.766, 0.766, 0.643, 241.182, 136.946)">
          <path id="Path_18972" data-name="Path 18972" d="M2.336-1.872S7.073,1.82,7.073,3.773A3.536,3.536,0,0,1,0,3.773C0,1.82,2.336-1.872,2.336-1.872Z" fill="none" stroke="" stroke-width="1"/>
          <path id="Path_18973" data-name="Path 18973" d="M2.741.433A9.681,9.681,0,0,0,1.5,3.773a2.036,2.036,0,0,0,4.073.014c-.013-.068-.193-.813-1.995-2.573C3.292.935,3.006.671,2.741.433m-.4-2.3S7.073,1.82,7.073,3.773A3.536,3.536,0,1,1,0,3.773C0,1.82,2.336-1.872,2.336-1.872Z" stroke="" stroke-width="1"/>
        </g>
        <g id="Path_18813" data-name="Path 18813" transform="translate(259.223 132.422) rotate(81)">
          <path id="Path_18974" data-name="Path 18974" d="M2.336-1.872S7.073,1.82,7.073,3.773A3.536,3.536,0,0,1,0,3.773C0,1.82,2.336-1.872,2.336-1.872Z" fill="none" stroke="" stroke-width="1"/>
          <path id="Path_18975" data-name="Path 18975" d="M2.741.433A9.681,9.681,0,0,0,1.5,3.773a2.036,2.036,0,0,0,4.073.014c-.013-.068-.193-.813-1.995-2.573C3.292.935,3.006.671,2.741.433m-.4-2.3S7.073,1.82,7.073,3.773A3.536,3.536,0,1,1,0,3.773C0,1.82,2.336-1.872,2.336-1.872Z" stroke="" stroke-width="1"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 50, svgImg: `assets/images/tabIcons/Group 8478.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19">
    <g id="Group_8478" data-name="Group 8478" transform="translate(-284 -133)">
      <g id="Rectangle_4243" data-name="Rectangle 4243" transform="translate(284 133)" fill="none" stroke="" stroke-width="2">
        <rect width="25" height="19" rx="4" stroke="none"/>
        <rect x="1" y="1" width="23" height="17" rx="3" fill="none"/>
      </g>
      <path id="Path_18814" data-name="Path 18814" d="M259,94.531l2.708-2.708,2.5,2.226,3.191-3.715,4.6,4.2Z" transform="translate(31 51.484)" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(291 137)" fill="#fff" stroke="" stroke-width="2">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 51, svgImg: `assets/images/tabIcons/Group 8479.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20.008" height="21.5" viewBox="0 0 20.008 21.5">
    <g id="Group_8479" data-name="Group 8479" transform="translate(-332.996 -133.5)">
      <path id="Path_18815" data-name="Path 18815" d="M18,7.5A9.26,9.26,0,0,1,9,17,9.26,9.26,0,0,1,0,7.5" transform="translate(352 151.5) rotate(180)" fill="none" stroke="" stroke-width="2"/>
      <g id="Rectangle_4244" data-name="Rectangle 4244" transform="translate(333 148)">
        <path id="Path_18976" data-name="Path 18976" d="M0,0H4A1,1,0,0,1,5,1V6A1,1,0,0,1,4,7H2A2,2,0,0,1,0,5Z"/>
        <path id="Path_18977" data-name="Path 18977" d="M2,1H4V6H2A1,1,0,0,1,1,5V2A1,1,0,0,1,2,1Z" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Rectangle_4245" data-name="Rectangle 4245" transform="translate(348 148)">
        <path id="Path_18978" data-name="Path 18978" d="M1,0H5V5A2,2,0,0,1,3,7H1A1,1,0,0,1,0,6V1A1,1,0,0,1,1,0Z"/>
        <path id="Path_18979" data-name="Path 18979" d="M1,1H3A1,1,0,0,1,4,2V5A1,1,0,0,1,3,6H1Z" fill="none" stroke="" stroke-width="2"/>
      </g>
      <line id="Line_32" data-name="Line 32" y2="4" transform="translate(334 144)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_33" data-name="Line 33" y2="4" transform="translate(352 144)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 52, svgImg: `assets/images/tabIcons/Group 8480.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="12.784" height="18.5" viewBox="0 0 12.784 18.5">
    <g id="Group_8480" data-name="Group 8480" transform="translate(-376 -134)">
      <path id="Path_18816" data-name="Path 18816" d="M10.784,6.5c0,2.52-2.414,4.563-5.392,4.563S0,9.02,0,6.5" transform="translate(377 137)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_34" data-name="Line 34" y2="4" transform="translate(382.5 148.5)" fill="none" stroke="" stroke-width="2"/>
      <g id="Rectangle_4246" data-name="Rectangle 4246" transform="translate(379.5 134)" fill="none" stroke="" stroke-width="2">
        <rect width="6" height="12" rx="3" stroke="none"/>
        <rect x="1" y="1" width="4" height="10" rx="2" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 53, svgImg: `assets/images/tabIcons/Group 8482.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="19.705" height="21.659" viewBox="0 0 19.705 21.659">
    <g id="Group_8482" data-name="Group 8482" transform="translate(-455.831 -139.465)">
      <path id="Path_18818" data-name="Path 18818" d="M4.016,0a7.8,7.8,0,1,1,0,15.6A7.99,7.99,0,0,1,0,14.49" transform="translate(456.744 145.977) rotate(-25.976)" fill="none" stroke="" stroke-width="2"/>
      <g id="Polygon_18" data-name="Polygon 18" transform="translate(459.98 148.455) rotate(-123)">
        <path id="Path_18980" data-name="Path 18980" d="M4.4,2.932H2.131L3.268,1.565Z"/>
        <path id="Path_18981" data-name="Path 18981" d="M3.268,0,6.535,3.932H0Z"/>
      </g>
      <line id="Line_37" data-name="Line 37" y2="1.642" transform="matrix(0.94, 0.342, -0.342, 0.94, 460.359, 157.436)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_38" data-name="Line 38" y2="1.642" transform="matrix(0.53, 0.848, -0.848, 0.53, 458.312, 155.358)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_39" data-name="Line 39" y2="1.642" transform="translate(457.49 152.523) rotate(90.974)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 54, svgImg: `assets/images/tabIcons/Group 8483.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="19.713" height="21.659" viewBox="0 0 19.713 21.659">
    <g id="Group_8483" data-name="Group 8483" transform="translate(1.339 1.334)">
      <path id="Path_18818" data-name="Path 18818" d="M4.016,15.6a7.8,7.8,0,1,0,0-15.6A7.99,7.99,0,0,0,0,1.112" transform="translate(10.621 19.203) rotate(-154.024)" fill="none" stroke="" stroke-width="2"/>
      <g id="Polygon_18" data-name="Polygon 18" transform="translate(17.784 2.177) rotate(123.007)">
        <path id="Path_18982" data-name="Path 18982" d="M4.4,2.932H2.131L3.268,1.565Z"/>
        <path id="Path_18983" data-name="Path 18983" d="M3.268,0,6.535,3.932H0Z"/>
      </g>
      <line id="Line_37" data-name="Line 37" y1="1.642" transform="matrix(-0.94, 0.342, -0.342, -0.94, 14.407, 18.182)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_38" data-name="Line 38" y1="1.642" transform="matrix(-0.53, 0.848, -0.848, -0.53, 17.286, 15.432)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_39" data-name="Line 39" y1="1.642" transform="translate(18.357 11.698) rotate(89.026)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 55, svgImg: `assets/images/tabIcons/Group 8484.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18">
    <g id="Group_8484" data-name="Group 8484" transform="translate(-512 -131)">
      <path id="Path_18819" data-name="Path 18819" d="M6,0H18a6,6,0,1,1,0,12" transform="translate(512 136)" fill="none" stroke="" stroke-width="2"/>
      <g id="Polygon_19" data-name="Polygon 19" transform="translate(512 142) rotate(-90)">
        <path id="Path_18984" data-name="Path 18984" d="M8.943,6H2.057L5.5,1.619Z"/>
        <path id="Path_18985" data-name="Path 18985" d="M5.5,3.237,4.115,5h2.77L5.5,3.237M5.5,0,11,7H0Z"/>
      </g>
      <line id="Line_40" data-name="Line 40" x2="2" transform="translate(528 148)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_41" data-name="Line 41" x2="2" transform="translate(524 148)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_42" data-name="Line 42" x2="2" transform="translate(520 148)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_43" data-name="Line 43" x2="2" transform="translate(516 148)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 56, svgImg: `assets/images/tabIcons/Group 8485.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="21.13" height="26.627" viewBox="0 0 21.13 26.627">
    <g id="Group_8485" data-name="Group 8485" transform="translate(-612.997 -128)">
      <line id="Line_45" data-name="Line 45" transform="translate(623 139.499)" fill="none" stroke="#707070" stroke-width="1"/>
      <g id="Path_18821" data-name="Path 18821" transform="matrix(0.616, -0.788, 0.788, 0.616, 623.698, 134.401)">
        <path id="Path_18986" data-name="Path 18986" d="M5.819,3.953,1.348,3.341,3.828,1.4Z" fill="#e4c8c8"/>
        <path id="Path_18987" data-name="Path 18987" d="M4,0,8.123,5.278-1.134,4.011Z"/>
      </g>
      <path id="Path_18820" data-name="Path 18820" d="M7.5,0c4.142,0,5.25,4.668,5.083,7.918" transform="translate(620.525 134.096)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18822" data-name="Path 18822" d="M0,0V2" transform="matrix(0.883, 0.469, -0.469, 0.883, 632.464, 144.597)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_47" data-name="Line 47" y2="2" transform="translate(629.238 148.657) rotate(61.001)" fill="none" stroke="" stroke-width="2"/>
      <g id="Path_18821-2" data-name="Path 18821" transform="matrix(-0.616, 0.788, -0.788, -0.616, 624.124, 147.332)">
        <path id="Path_18988" data-name="Path 18988" d="M6.953,3.953,2.481,3.341,4.961,1.4Z" fill="#e4c8c8"/>
        <path id="Path_18989" data-name="Path 18989" d="M5.134,0,9.257,5.278,0,4.011Z"/>
      </g>
      <path id="Path_18820-2" data-name="Path 18820" d="M12.6,7.918C8.457,7.918,7.349,3.25,7.516,0" transform="translate(606.5 140.613)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18822-2" data-name="Path 18822" d="M0,0V2" transform="matrix(-0.883, -0.469, 0.469, -0.883, 614.66, 138.03)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_47-2" data-name="Line 47" y2="2" transform="translate(617.886 133.97) rotate(-118.999)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 57, svgImg: `assets/images/tabIcons/Group 8487.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17">
    <g id="Group_8487" data-name="Group 8487" transform="translate(-656 -135)">
      <g id="Rectangle_4250" data-name="Rectangle 4250" transform="translate(656 135)" fill="none" stroke="" stroke-width="2">
        <rect width="22" height="15" rx="3" stroke="none"/>
        <rect x="1" y="1" width="20" height="13" rx="2" fill="none"/>
      </g>
      <g id="Rectangle_4251" data-name="Rectangle 4251" transform="translate(664 149)" stroke="" stroke-width="2">
        <rect width="6" height="3" stroke="none"/>
        <rect x="1" y="1" width="4" height="1" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 58, svgImg: `assets/images/tabIcons/Group 8490.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="14.5" viewBox="0 0 18.5 14.5">
    <g id="Group_8490" data-name="Group 8490" transform="translate(-706 -137.5)">
      <g id="Group_8488" data-name="Group 8488">
        <path id="Path_18824" data-name="Path 18824" d="M6,0h5" transform="translate(709 141)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18825" data-name="Path 18825" d="M3,0H5.286" transform="translate(707.714 141)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18826" data-name="Path 18826" d="M3,0H5.286" transform="translate(703 141)" fill="none" stroke="" stroke-width="2"/>
        <g id="Polygon_22" data-name="Polygon 22" transform="translate(724.5 137.5) rotate(90)">
          <path id="Path_18990" data-name="Path 18990" d="M5.259,5H1.741L3.5,1.985Z"/>
          <path id="Path_18991" data-name="Path 18991" d="M3.5,0,7,6H0Z"/>
        </g>
      </g>
      <g id="Group_8489" data-name="Group 8489" transform="translate(706 145)">
        <path id="Path_18827" data-name="Path 18827" d="M11,0H6" transform="translate(-1.5 3.5)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18828" data-name="Path 18828" d="M5.286,0H3" transform="translate(8.5 3.5)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18829" data-name="Path 18829" d="M5.286,0H3" transform="translate(13.214 3.5)" fill="none" stroke="" stroke-width="2"/>
        <g id="Polygon_23" data-name="Polygon 23" transform="translate(0 7) rotate(-90)">
          <path id="Path_18992" data-name="Path 18992" d="M5.259,5H1.741L3.5,1.985Z"/>
          <path id="Path_18993" data-name="Path 18993" d="M3.5,0,7,6H0Z"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 59, svgImg: `assets/images/tabIcons/Group 8491.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="18.5" viewBox="0 0 14.5 18.5">
    <g id="Group_8491" data-name="Group 8491" transform="translate(-137.5 724.5) rotate(-90)">
      <g id="Group_8488" data-name="Group 8488">
        <path id="Path_18824" data-name="Path 18824" d="M6,0h5" transform="translate(709 141)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18825" data-name="Path 18825" d="M3,0H5.286" transform="translate(707.714 141)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18826" data-name="Path 18826" d="M3,0H5.286" transform="translate(703 141)" fill="none" stroke="" stroke-width="2"/>
        <g id="Polygon_22" data-name="Polygon 22" transform="translate(724.5 137.5) rotate(90)">
          <path id="Path_18994" data-name="Path 18994" d="M5.259,5H1.741L3.5,1.985Z"/>
          <path id="Path_18995" data-name="Path 18995" d="M3.5,0,7,6H0Z"/>
        </g>
      </g>
      <g id="Group_8489" data-name="Group 8489" transform="translate(706 145)">
        <path id="Path_18827" data-name="Path 18827" d="M11,0H6" transform="translate(-1.5 3.5)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18828" data-name="Path 18828" d="M5.286,0H3" transform="translate(8.5 3.5)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18829" data-name="Path 18829" d="M5.286,0H3" transform="translate(13.214 3.5)" fill="none" stroke="" stroke-width="2"/>
        <g id="Polygon_23" data-name="Polygon 23" transform="translate(0 7) rotate(-90)">
          <path id="Path_18996" data-name="Path 18996" d="M5.259,5H1.741L3.5,1.985Z"/>
          <path id="Path_18997" data-name="Path 18997" d="M3.5,0,7,6H0Z"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 60, svgImg: `assets/images/tabIcons/Group 8493.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.75" viewBox="0 0 18 13.75">
    <g id="Group_8493" data-name="Group 8493" transform="translate(-775 -139.25)">
      <path id="Path_18830" data-name="Path 18830" d="M172,52H156V63.75l2-1.95h14Z" transform="translate(620 88.25)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <g id="Ellipse_42" data-name="Ellipse 42" transform="translate(780 144)" stroke="" stroke-width="2">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" fill="none"/>
      </g>
      <g id="Ellipse_43" data-name="Ellipse 43" transform="translate(783 144)" stroke="" stroke-width="2">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" fill="none"/>
      </g>
      <g id="Ellipse_44" data-name="Ellipse 44" transform="translate(786 144)" stroke="" stroke-width="2">
        <circle cx="1" cy="1" r="1" stroke="none"/>
        <circle cx="1" cy="1" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 61, svgImg: `assets/images/tabIcons/Group 8494.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
    <g id="Group_8494" data-name="Group 8494" transform="translate(-821 -135)">
      <path id="Path_18831" data-name="Path 18831" d="M8,4v8a4,4,0,1,1-8,0V0" transform="translate(824 138)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18832" data-name="Path 18832" d="M8,16V4A4,4,0,1,0,0,4V6.391" transform="translate(832 136)" fill="none" stroke="" stroke-width="2"/>
      <g id="Polygon_24" data-name="Polygon 24" transform="translate(821 135)">
        <path id="Path_18998" data-name="Path 18998" d="M4.234,4H1.766L3,1.944Z"/>
        <path id="Path_18999" data-name="Path 18999" d="M3,0,6,5H0Z"/>
      </g>
      <g id="Polygon_25" data-name="Polygon 25" transform="translate(843 154) rotate(180)">
        <path id="Path_19000" data-name="Path 19000" d="M4.234,4H1.766L3,1.944Z"/>
        <path id="Path_19001" data-name="Path 19001" d="M3,0,6,5H0Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 62, svgImg: `assets/images/tabIcons/Group 8495.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="17.999" viewBox="0 0 24 17.999">
    <g id="Group_8495" data-name="Group 8495" transform="translate(-606 -134)">
      <g id="Rectangle_4249" data-name="Rectangle 4249" transform="translate(606 134)" fill="none" stroke="" stroke-width="2">
        <rect width="20" height="14" rx="3" stroke="none"/>
        <rect x="1" y="1" width="18" height="12" rx="2" fill="none"/>
      </g>
      <g id="Polygon_21" data-name="Polygon 21" transform="translate(619.5 137.5) rotate(90)">
        <path id="Path_19002" data-name="Path 19002" d="M5.079,4H1.921L3.5,1.744h0L5.079,4h0Z" fill="#fff"/>
        <path id="Path_19003" data-name="Path 19003" d="M3.5.744a.993.993,0,0,1,.819.427L5.9,3.427A1,1,0,0,1,5.079,5H1.921A1,1,0,0,1,1.1,3.427L2.681,1.17A.993.993,0,0,1,3.5.744Z"/>
      </g>
      <path id="Path_18823" data-name="Path 18823" d="M18.5-1.188V8.879a.825.825,0,0,1-.841.807H.25" transform="translate(610.5 141.313)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 63, svgImg: `assets/images/tabIcons/Group 8496.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.5" viewBox="0 0 24 19.5">
    <g id="Group_8496" data-name="Group 8496" transform="translate(-856 -132.5)">
      <g id="Rectangle_4252" data-name="Rectangle 4252" transform="translate(856 136)" fill="none" stroke="" stroke-width="2">
        <rect width="24" height="16" rx="3" stroke="none"/>
        <rect x="1" y="1" width="22" height="14" rx="2" fill="none"/>
      </g>
      <g id="Polygon_27" data-name="Polygon 27" transform="translate(871.5 140.5) rotate(90)">
        <path id="Path_19004" data-name="Path 19004" d="M5.079,4H1.921L3.5,1.744Z" fill="#fff"/>
        <path id="Path_19005" data-name="Path 19005" d="M3.5.372a.5.5,0,0,1,.41.213l2.54,3.628A.5.5,0,0,1,6.04,5H.96a.5.5,0,0,1-.41-.787L3.09.585A.5.5,0,0,1,3.5.372Z"/>
      </g>
      <g id="Rectangle_4253" data-name="Rectangle 4253" transform="translate(860 133)">
        <path id="Path_19006" data-name="Path 19006" d="M2,0H14a2,2,0,0,1,2,2H0A2,2,0,0,1,2,0Z"/>
        <path id="Path_19007" data-name="Path 19007" d="M1.333.5H14.667a.833.833,0,0,1,.833.833h0a.167.167,0,0,1-.167.167H.667A.167.167,0,0,1,.5,1.333h0A.833.833,0,0,1,1.333.5Z" fill="none" stroke="" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 64, svgImg: `assets/images/tabIcons/Group 8497.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="15.414" height="15.414" viewBox="0 0 15.414 15.414">
    <g id="Group_8497" data-name="Group 8497" transform="translate(-944 -136)">
      <g id="Ellipse_45" data-name="Ellipse 45" transform="translate(944 136)" fill="none" stroke="" stroke-width="2">
        <circle cx="6" cy="6" r="6" stroke="none"/>
        <circle cx="6" cy="6" r="5" fill="none"/>
      </g>
      <line id="Line_48" data-name="Line 48" x2="4" y2="4" transform="translate(954 146)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 65, svgImg: `assets/images/tabIcons/Group 8498.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
    <g id="Group_8498" data-name="Group 8498" transform="translate(-983 -135)">
      <g id="Rectangle_4255" data-name="Rectangle 4255" transform="translate(983 135)" fill="none" stroke="" stroke-width="2">
        <rect width="16" height="17" rx="2" stroke="none"/>
        <rect x="1" y="1" width="14" height="15" rx="1" fill="none"/>
      </g>
      <line id="Line_49" data-name="Line 49" x2="8" transform="translate(987 138.5)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_50" data-name="Line 50" x2="8" transform="translate(987 148.5)" fill="none" stroke="" stroke-width="1.5"/>
      <g id="Rectangle_4256" data-name="Rectangle 4256" transform="translate(987 141)" stroke="" stroke-width="2">
        <rect width="8" height="5" stroke="none"/>
        <rect x="1" y="1" width="6" height="3" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 66, svgImg: `assets/images/tabIcons/Group 8499.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
    <g id="Group_8499" data-name="Group 8499" transform="translate(-1024 -134)">
      <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(1024 136)" fill="none" stroke="" stroke-width="2">
        <circle cx="8" cy="8" r="8" stroke="none"/>
        <circle cx="8" cy="8" r="7" fill="none"/>
      </g>
      <path id="Path_1072" data-name="Path 1072" d="M540,8.583v4.75l3.018,3.018" transform="translate(492 131)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_51" data-name="Line 51" y2="4" transform="translate(1032 134)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 67, svgImg: `assets/images/tabIcons/Group 8500.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.75" viewBox="0 0 18 13.75">
    <g id="Group_8500" data-name="Group 8500" transform="translate(-169 -81)">
      <path id="Path_1088" data-name="Path 1088" d="M156,52h16V63.75l-2-1.95H156Z" transform="translate(14 30)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_1089" data-name="Path 1089" d="M159,55.4h7.4" transform="translate(14 30)" fill="none" stroke="" stroke-width="1"/>
      <path id="Path_1090" data-name="Path 1090" d="M159,55.4h5" transform="translate(14 32.108)" fill="none" stroke="" stroke-width="1"/>
    </g>
  </svg>`},
  {
    id: 68, svgImg: `assets/images/tabIcons/Group 8502.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16.414" height="14.309" viewBox="0 0 16.414 14.309">
    <g id="Group_8502" data-name="Group 8502" transform="translate(-662.699 -31.699)">
      <path id="Path_1077" data-name="Path 1077" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(1290.406 56.594) rotate(180)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_1078" data-name="Path 1078" d="M612,19.5l7.5-7.5,7.5,7.5" transform="translate(1290.406 51.906) rotate(180)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 69, svgImg: `assets/images/tabIcons/Group 8503.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
    <g id="Group_8503" data-name="Group 8503" transform="translate(-33 -80)">
      <g id="Rectangle_9" data-name="Rectangle 9" transform="translate(33 80)" fill="none" stroke="" stroke-width="2">
        <rect width="14" height="15" rx="2" stroke="none"/>
        <rect x="1" y="1" width="12" height="13" rx="1" fill="none"/>
      </g>
      <g id="Polygon_6" data-name="Polygon 6" transform="translate(43.463 84) rotate(90)">
        <path id="Path_19008" data-name="Path 19008" d="M5.321,5.963H1.679a.5.5,0,0,1-.44-.738L3.06,1.862a.5.5,0,0,1,.879,0L5.761,5.225a.5.5,0,0,1-.44.738Z"/>
        <path id="Path_19009" data-name="Path 19009" d="M3.5,2.1,1.679,5.463H5.321L3.5,2.1m0-1a.99.99,0,0,1,.879.524L6.2,4.987a1,1,0,0,1-.879,1.476H1.679A1,1,0,0,1,.8,4.987L2.621,1.624A.99.99,0,0,1,3.5,1.1Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 70, svgImg: `assets/images/tabIcons/Group 8504.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="21.904" viewBox="0 0 23 21.904">
    <g id="Group_8504" data-name="Group 8504" transform="translate(-74 -174.263)">
      <g id="Rectangle_4257" data-name="Rectangle 4257" transform="translate(74 180.167)" fill="none" stroke="" stroke-width="2">
        <rect width="18" height="16" rx="3" stroke="none"/>
        <rect x="1" y="1" width="16" height="14" rx="2" fill="none"/>
      </g>
      <path id="Path_18835" data-name="Path 18835" d="M12,0h3.75c2.071,0,3.75,1.175,3.75,2.625v8.75c0,1.45-1.679,2.625-3.75,2.625H12" transform="translate(76.5 181.167)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_52" data-name="Line 52" x2="2" transform="translate(93 185.167)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_54" data-name="Line 54" x2="2" transform="translate(93 188.167)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18836" data-name="Path 18836" d="M746,116.708l4.333,2.542,4.292-5.542" transform="translate(-665 61.167)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 71, svgImg: `assets/images/tabIcons/Group 8505.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="24.25" viewBox="0 0 19 24.25">
    <g id="Group_8505" data-name="Group 8505" transform="translate(-404 -129.75)">
      <path id="Path_18840" data-name="Path 18840" d="M6,2.5V18.108c0,2.011-1.343,3.642-3,3.642s-3-1.631-3-3.642V3.541" transform="translate(411 152.5) rotate(180)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18817" data-name="Path 18817" d="M5,3.5V17.9c0,1.988-1.119,3.6-2.5,3.6S0,19.888,0,17.9V3.5" transform="translate(426.5 148) rotate(90)" fill="none" stroke="" stroke-width="2"/>
      <g id="Path_18838" data-name="Path 18838" transform="translate(410 133.5)">
        <path id="Path_19010" data-name="Path 19010" d="M0,0H13V15.5H0Z" fill="none"/>
        <path id="Path_19011" data-name="Path 19011" d="M2,2V13.5h9V2H2M0,0H13V15.5H0Z"/>
      </g>
      <line id="Line_35" data-name="Line 35" x2="4" transform="translate(414.5 140)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_36" data-name="Line 36" x2="4" transform="translate(414.5 143)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 72, svgImg: `assets/images/tabIcons/Group 8506.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="23" viewBox="0 0 17.5 23">
    <g id="Group_8506" data-name="Group 8506" transform="translate(-112.5 -178)">
      <path id="Path_18842" data-name="Path 18842" d="M5,4V16.833C5,18.858,3.881,20.5,2.5,20.5S0,18.858,0,16.833V4" transform="translate(134 195) rotate(90)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18843" data-name="Path 18843" d="M15.4.714V17a2.761,2.761,0,0,1-2.432,3H0V3" transform="translate(129 199) rotate(180)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_55" data-name="Line 55" y2="8" transform="translate(121.5 182.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
      <line id="Line_56" data-name="Line 56" x1="6" transform="translate(118.5 185.5)" fill="none" stroke="" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 73, svgImg: `assets/images/tabIcons/Group 8507.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="23" viewBox="0 0 17.5 23">
    <g id="Group_8507" data-name="Group 8507" transform="translate(-143 -178)">
      <path id="Path_18845" data-name="Path 18845" d="M5,4V16.833C5,18.858,3.881,20.5,2.5,20.5S0,18.858,0,16.833V4" transform="translate(164.5 195) rotate(90)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18844" data-name="Path 18844" d="M15.4.714V17a2.761,2.761,0,0,1-2.432,3H0V3" transform="translate(159.5 199) rotate(180)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 74, svgImg: `assets/images/tabIcons/Group 8508.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="15.45" height="18.414" viewBox="0 0 15.45 18.414">
    <g id="Group_8508" data-name="Group 8508" transform="translate(-183 -177.586)">
      <path id="Path_18846" data-name="Path 18846" d="M316,140.592l6.725-6.725,6.725,6.725v9.275h-5.122V143.81h-3.407v6.057H316Z" transform="translate(-132 45.133)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 75, svgImg: `assets/images/tabIcons/Group 8509.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="19.042" viewBox="0 0 18.001 19.042">
    <g id="Group_8509" data-name="Group 8509" transform="translate(-227 -181)">
      <g id="Path_18847" data-name="Path 18847" transform="translate(227 181)">
        <path id="Path_19012" data-name="Path 19012" d="M9,0c4.971,0,9,3.134,9,7a7.237,7.237,0,0,1-1.143,4c-1.427,2.779-6.891,7.155-8.571,8-1.13.576,2.519-5,.714-5-4.971,0-9-3.134-9-7S4.029,0,9,0Z" fill="none"/>
        <path id="Path_19013" data-name="Path 19013" d="M9,2A8.372,8.372,0,0,0,3.864,3.629,4.336,4.336,0,0,0,2,7a4.336,4.336,0,0,0,1.864,3.371A8.372,8.372,0,0,0,9,12a2.441,2.441,0,0,1,2.082,1.088,2.554,2.554,0,0,1,.394,1.019,17.56,17.56,0,0,0,3.6-4.023A5.461,5.461,0,0,0,16,7.007V7a4.336,4.336,0,0,0-1.864-3.371A8.372,8.372,0,0,0,9,2M9,0c4.971,0,9,3.134,9,7a7.237,7.237,0,0,1-1.143,4c-1.427,2.779-6.891,7.155-8.571,8-1.13.576,2.519-5,.714-5-4.971,0-9-3.134-9-7S4.029,0,9,0Z"/>
      </g>
      <g id="Ellipse_47" data-name="Ellipse 47" transform="translate(231 187)">
        <circle id="Ellipse_64" data-name="Ellipse 64" cx="1" cy="1" r="1"/>
        <circle id="Ellipse_65" data-name="Ellipse 65" transform="translate(1 1)" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Ellipse_48" data-name="Ellipse 48" transform="translate(235 187)">
        <circle id="Ellipse_66" data-name="Ellipse 66" cx="1" cy="1" r="1"/>
        <circle id="Ellipse_67" data-name="Ellipse 67" transform="translate(1 1)" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Ellipse_49" data-name="Ellipse 49" transform="translate(239 187)">
        <circle id="Ellipse_68" data-name="Ellipse 68" cx="1" cy="1" r="1"/>
        <circle id="Ellipse_69" data-name="Ellipse 69" transform="translate(1 1)" fill="none" stroke="" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 76, svgImg: `assets/images/tabIcons/Group 8512.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="25.593" viewBox="0 0 23.5 25.593">
    <g id="Group_8512" data-name="Group 8512" transform="translate(-32.5 -171.25)">
      <path id="Path_18834" data-name="Path 18834" d="M166,52H156v7.61l1.25-1.263H166Z" transform="translate(-111 120.25)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <g id="Group_8510" data-name="Group 8510">
        <g id="Ellipse_46" data-name="Ellipse 46" transform="translate(35 180)" fill="none" stroke="" stroke-width="2">
          <circle cx="4" cy="4" r="4" stroke="none"/>
          <circle cx="4" cy="4" r="3" fill="none"/>
        </g>
        <g id="Path_18833" data-name="Path 18833" transform="translate(32.5 186.657)">
          <path id="Path_19014" data-name="Path 19014" d="M13,7.889c0-3.063-2.91-5.546-6.5-5.546S0,4.826,0,7.889,13,10.952,13,7.889Z" fill="none"/>
          <path id="Path_19015" data-name="Path 19015" d="M6.5,8.186a11.039,11.039,0,0,0,4.469-.712A3.279,3.279,0,0,0,9.8,5.489,5.062,5.062,0,0,0,6.5,4.343,5.062,5.062,0,0,0,3.2,5.489,3.279,3.279,0,0,0,2.031,7.474,11.039,11.039,0,0,0,6.5,8.186m0,2c-3.25,0-6.5-.766-6.5-2.3C0,4.826,2.91,2.343,6.5,2.343S13,4.826,13,7.889C13,9.421,9.75,10.186,6.5,10.186Z"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 77, svgImg: `assets/images/tabIcons/Group 8516.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="27" height="14" viewBox="0 0 27 14">
    <g id="Group_8516" data-name="Group 8516" transform="translate(-299 -180)">
      <g id="Group_8514" data-name="Group 8514">
        <g id="Ellipse_46" data-name="Ellipse 46" transform="translate(317.5 180.16)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833" data-name="Path 18833" transform="translate(315.75 184.817)">
          <path id="Path_19016" data-name="Path 19016" d="M10.25,7.18A4.987,4.987,0,0,0,5.125,2.343,4.987,4.987,0,0,0,0,7.18C0,9.851,10.25,9.851,10.25,7.18Z" fill="none"/>
          <path id="Path_19017" data-name="Path 19017" d="M5.125,7.183a8.438,8.438,0,0,0,3.083-.47,3.057,3.057,0,0,0-3.083-2.37,3.057,3.057,0,0,0-3.083,2.37,8.438,8.438,0,0,0,3.083.47m0,2C2.563,9.183,0,8.515,0,7.18A4.987,4.987,0,0,1,5.125,2.343,4.987,4.987,0,0,1,10.25,7.18C10.25,8.515,7.688,9.183,5.125,9.183Z"/>
        </g>
      </g>
      <g id="Group_8515" data-name="Group 8515">
        <g id="Ellipse_46-2" data-name="Ellipse 46" transform="translate(301.5 180.16)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833-2" data-name="Path 18833" transform="translate(299 184.817)">
          <path id="Path_19018" data-name="Path 19018" d="M10.125,7.18A4.953,4.953,0,0,0,5.063,2.343,4.953,4.953,0,0,0,0,7.18C0,9.851,10.125,9.851,10.125,7.18Z" fill="none"/>
          <path id="Path_19019" data-name="Path 19019" d="M5.063,7.183a8.223,8.223,0,0,0,3.022-.464A3.008,3.008,0,0,0,5.063,4.343,3.008,3.008,0,0,0,2.04,6.719a8.223,8.223,0,0,0,3.022.464m0,2C2.531,9.183,0,8.515,0,7.18A4.953,4.953,0,0,1,5.063,2.343,4.953,4.953,0,0,1,10.125,7.18C10.125,8.515,7.594,9.183,5.063,9.183Z"/>
        </g>
      </g>
      <g id="Group_8513" data-name="Group 8513">
        <g id="Ellipse_46-3" data-name="Ellipse 46" transform="translate(309.5 180)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833-3" data-name="Path 18833" transform="translate(307 184.657)">
          <path id="Path_19020" data-name="Path 19020" d="M11,7.18c0-2.671-2.462-4.837-5.5-4.837S0,4.509,0,7.18,11,9.851,11,7.18Z" fill="none"/>
          <path id="Path_19021" data-name="Path 19021" d="M5.5,7.183a9.772,9.772,0,0,0,3.445-.5,2.683,2.683,0,0,0-.876-1.419A3.869,3.869,0,0,0,5.5,4.343a3.869,3.869,0,0,0-2.568.918A2.683,2.683,0,0,0,2.055,6.68a9.772,9.772,0,0,0,3.445.5m0,2c-2.75,0-5.5-.668-5.5-2C0,4.509,2.462,2.343,5.5,2.343S11,4.509,11,7.18C11,8.515,8.25,9.183,5.5,9.183Z"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 78, svgImg: `assets/images/tabIcons/Group 8518.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="20" viewBox="0 0 18.5 20">
    <g id="Group_8518" data-name="Group 8518" transform="translate(-396.5 -177.5)">
      <g id="Ellipse_51" data-name="Ellipse 51" transform="translate(399 181)" fill="none" stroke="" stroke-width="2">
        <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
        <circle cx="6.5" cy="6.5" r="5.5" fill="none"/>
      </g>
      <line id="Line_57" data-name="Line 57" y2="2" transform="translate(405.5 195.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_61" data-name="Line 61" y1="2" transform="translate(405.5 177.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_60" data-name="Line 60" x1="2" transform="translate(396.5 187.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_64" data-name="Line 64" x1="2" transform="translate(413 187.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_63" data-name="Line 63" y2="2" transform="translate(397.586 179.586) rotate(-45)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_65" data-name="Line 65" y2="2" transform="translate(411.608 194) rotate(-45)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_58" data-name="Line 58" x1="1.522" y2="1.688" transform="translate(411.5 179.812)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_62" data-name="Line 62" x1="1.522" y2="1.688" transform="translate(398.478 194)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 79, svgImg: `assets/images/tabIcons/Group 8519.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.858" viewBox="0 0 20 19.858">
    <g id="Group_8519" data-name="Group 8519" transform="translate(-434 -176.155)">
      <g id="Rectangle_4258" data-name="Rectangle 4258" transform="translate(434 186)" fill="none" stroke="" stroke-width="2">
        <rect width="8" height="10" stroke="none"/>
        <rect x="1" y="1" width="6" height="8" fill="none"/>
      </g>
      <line id="Line_66" data-name="Line 66" x2="5" transform="translate(435.5 191.5)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_68" data-name="Line 68" x2="5" transform="translate(442 183.5)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_70" data-name="Line 70" y2="4" transform="translate(448 176.5)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_71" data-name="Line 71" y2="3" transform="translate(445.643 176.6)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_74" data-name="Line 74" y2="3" transform="translate(444.5 192)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_69" data-name="Line 69" x2="3" transform="translate(444 186)" fill="none" stroke="" stroke-width="1.5"/>
      <line id="Line_72" data-name="Line 72" x2="3" transform="translate(450 187)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_73" data-name="Line 73" x2="3" transform="translate(450 191)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_67" data-name="Line 67" y2="3" transform="translate(437 184)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18848" data-name="Path 18848" d="M0,0H8.4L5.015,9H-9.8" transform="translate(440 186) rotate(-90)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18849" data-name="Path 18849" d="M0,0H4V11.313H-14.875" transform="translate(449 183.7)" fill="none" stroke="" stroke-width="2"/>
      <g id="Ellipse_52" data-name="Ellipse 52" transform="translate(443 190)" fill="#fff" stroke="" stroke-width="2">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 80, svgImg: `assets/images/tabIcons/Group 8521.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="18.001" viewBox="0 0 31 18.001">
    <g id="Group_8521" data-name="Group 8521" transform="translate(-342 -176)">
      <g id="Group_8514" data-name="Group 8514" transform="translate(47)">
        <g id="Ellipse_46" data-name="Ellipse 46" transform="translate(317.5 180.16)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833" data-name="Path 18833" transform="translate(315.75 184.817)">
          <path id="Path_19022" data-name="Path 19022" d="M10.25,7.18A4.987,4.987,0,0,0,5.125,2.343,4.987,4.987,0,0,0,0,7.18C0,9.851,10.25,9.851,10.25,7.18Z" fill="none"/>
          <path id="Path_19023" data-name="Path 19023" d="M5.125,7.183a8.438,8.438,0,0,0,3.083-.47,3.057,3.057,0,0,0-3.083-2.37,3.057,3.057,0,0,0-3.083,2.37,8.438,8.438,0,0,0,3.083.47m0,2C2.563,9.183,0,8.515,0,7.18A4.987,4.987,0,0,1,5.125,2.343,4.987,4.987,0,0,1,10.25,7.18C10.25,8.515,7.688,9.183,5.125,9.183Z"/>
        </g>
      </g>
      <g id="Group_8515" data-name="Group 8515" transform="translate(43)">
        <g id="Ellipse_46-2" data-name="Ellipse 46" transform="translate(301.5 180.16)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833-2" data-name="Path 18833" transform="translate(299 184.817)">
          <path id="Path_19024" data-name="Path 19024" d="M10.125,7.18A4.953,4.953,0,0,0,5.063,2.343,4.953,4.953,0,0,0,0,7.18C0,9.851,10.125,9.851,10.125,7.18Z" fill="none"/>
          <path id="Path_19025" data-name="Path 19025" d="M5.063,7.183a8.223,8.223,0,0,0,3.022-.464A3.008,3.008,0,0,0,5.063,4.343,3.008,3.008,0,0,0,2.04,6.719a8.223,8.223,0,0,0,3.022.464m0,2C2.531,9.183,0,8.515,0,7.18A4.953,4.953,0,0,1,5.063,2.343,4.953,4.953,0,0,1,10.125,7.18C10.125,8.515,7.594,9.183,5.063,9.183Z"/>
        </g>
      </g>
      <g id="Group_8513" data-name="Group 8513" transform="translate(43 -4)">
        <g id="Ellipse_46-3" data-name="Ellipse 46" transform="translate(310.251 180)" fill="none" stroke="" stroke-width="2">
          <circle cx="3.902" cy="3.902" r="3.902" stroke="none"/>
          <circle cx="3.902" cy="3.902" r="2.902" fill="none"/>
        </g>
        <g id="Path_18833-3" data-name="Path 18833" transform="translate(307 186.761)">
          <path id="Path_19026" data-name="Path 19026" d="M14.306,8.634c0-3.474-3.2-6.29-7.153-6.29S0,5.159,0,8.634,14.306,12.108,14.306,8.634Z" fill="none"/>
          <path id="Path_19027" data-name="Path 19027" d="M7.153,9.239a16.655,16.655,0,0,0,3.613-.366,5.246,5.246,0,0,0,1.527-.552C12.1,6.1,9.869,4.343,7.153,4.343S2.206,6.1,2.013,8.322a5.246,5.246,0,0,0,1.527.552,16.655,16.655,0,0,0,3.613.366m0,2C3.577,11.239,0,10.371,0,8.634c0-3.474,3.2-6.29,7.153-6.29s7.153,2.816,7.153,6.29C14.306,10.371,10.73,11.239,7.153,11.239Z"/>
        </g>
      </g>
    </g>
  </svg>`},
  {
    id: 81, svgImg: `assets/images/tabIcons/Group 8523.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="25" viewBox="0 0 29.25 25">
    <g id="Group_8523" data-name="Group 8523" transform="translate(-549.75 -168)">
      <g id="Group_8520" data-name="Group 8520" transform="translate(234 -1)">
        <g id="Ellipse_46" data-name="Ellipse 46" transform="translate(317.5 180.16)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833" data-name="Path 18833" transform="translate(315.75 184.817)">
          <path id="Path_19028" data-name="Path 19028" d="M10.25,7.18A4.987,4.987,0,0,0,5.125,2.343,4.987,4.987,0,0,0,0,7.18C0,9.851,10.25,9.851,10.25,7.18Z" fill="none"/>
          <path id="Path_19029" data-name="Path 19029" d="M5.125,7.183a8.438,8.438,0,0,0,3.083-.47,3.057,3.057,0,0,0-3.083-2.37,3.057,3.057,0,0,0-3.083,2.37,8.438,8.438,0,0,0,3.083.47m0,2C2.563,9.183,0,8.515,0,7.18A4.987,4.987,0,0,1,5.125,2.343,4.987,4.987,0,0,1,10.25,7.18C10.25,8.515,7.688,9.183,5.125,9.183Z"/>
        </g>
      </g>
      <g id="Group_8522" data-name="Group 8522" transform="translate(253 -1)">
        <g id="Ellipse_46-2" data-name="Ellipse 46" transform="translate(317.5 180.16)" fill="none" stroke="" stroke-width="2">
          <circle cx="3" cy="3" r="3" stroke="none"/>
          <circle cx="3" cy="3" r="2" fill="none"/>
        </g>
        <g id="Path_18833-2" data-name="Path 18833" transform="translate(315.75 184.817)">
          <path id="Path_19030" data-name="Path 19030" d="M10.25,7.18A4.987,4.987,0,0,0,5.125,2.343,4.987,4.987,0,0,0,0,7.18C0,9.851,10.25,9.851,10.25,7.18Z" fill="none"/>
          <path id="Path_19031" data-name="Path 19031" d="M5.125,7.183a8.438,8.438,0,0,0,3.083-.47,3.057,3.057,0,0,0-3.083-2.37,3.057,3.057,0,0,0-3.083,2.37,8.438,8.438,0,0,0,3.083.47m0,2C2.563,9.183,0,8.515,0,7.18A4.987,4.987,0,0,1,5.125,2.343,4.987,4.987,0,0,1,10.25,7.18C10.25,8.515,7.688,9.183,5.125,9.183Z"/>
        </g>
      </g>
      <g id="Path_18850" data-name="Path 18850" transform="translate(557 168)">
        <path id="Path_19032" data-name="Path 19032" d="M2,0H13a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H10.372L8.083,13.583,6.063,10H2A2,2,0,0,1,0,8V2A2,2,0,0,1,2,0Z" fill="none"/>
        <path id="Path_19033" data-name="Path 19033" d="M2,2V8H7.232L8.19,9.7,9.277,8H13V2H2M2,0H13a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H10.372L8.083,13.583,6.063,10H2A2,2,0,0,1,0,8V2A2,2,0,0,1,2,0Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 82, svgImg: `assets/images/tabIcons/Group 8524.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18.92" height="26.401" viewBox="0 0 18.92 26.401">
    <g id="Group_8524" data-name="Group 8524" transform="translate(-597.08 -168)">
      <g id="Path_18851" data-name="Path 18851" transform="translate(597.082 174.4)">
        <path id="Path_19034" data-name="Path 19034" d="M9.429-6.4s-1.473,1.827.884,6.07c.408.734,1.031,1.768,1.031,1.768l1.031-3.045s6.482,9.313,6.482,12.179c.442,3.241-1.6,6.032-4.338,7.937A9.1,9.1,0,0,1,9.429,20,9.461,9.461,0,0,1,0,10.571C-.153,5.06,9.429-6.4,9.429-6.4Z" fill="none"/>
        <path id="Path_19035" data-name="Path 19035" d="M7.78-.971C7.3-.3,6.8.413,6.3,1.159,2.348,7.076,1.976,9.674,2,10.516a7.829,7.829,0,0,0,2.234,5.347A7.274,7.274,0,0,0,9.429,18a7.13,7.13,0,0,0,3.938-1.126l.011-.008a9.558,9.558,0,0,0,2.785-2.848,4.84,4.84,0,0,0,.713-3.178l-.018-.134v-.136c0-.052-.052-1.323-2.959-6.141-.317-.526-.635-1.038-.941-1.522l-1.1,3.251L9.631,2.47C9.605,2.427,8.985,1.4,8.564.641A15.766,15.766,0,0,1,7.78-.971M9.429-6.4s-1.473,1.827.884,6.07c.408.734,1.031,1.768,1.031,1.768l1.031-3.045s6.482,9.313,6.482,12.179c.442,3.241-1.6,6.032-4.338,7.937A9.1,9.1,0,0,1,9.429,20,9.461,9.461,0,0,1,0,10.571C-.153,5.06,9.429-6.4,9.429-6.4Z"/>
      </g>
      <g id="Path_18852" data-name="Path 18852" transform="translate(602.581 185)">
        <path id="Path_19036" data-name="Path 19036" d="M4.729-3A5.312,5.312,0,0,0,5.468.4c.715,1.313,2.808,3.139,2.808,4.286A4.138,4.138,0,0,1,0,4.685C0,2.4,4.729-3,4.729-3Z" fill="none"/>
        <path id="Path_19037" data-name="Path 19037" d="M3.77,1.459C2.119,3.8,2.007,4.623,2,4.694a2.138,2.138,0,0,0,4.272.135,11.734,11.734,0,0,0-1.11-1.487A14.39,14.39,0,0,1,3.77,1.459M4.729-3A5.312,5.312,0,0,0,5.468.4c.715,1.313,2.808,3.139,2.808,4.286A4.138,4.138,0,0,1,0,4.685C0,2.4,4.729-3,4.729-3Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 83, svgImg: `assets/images/tabIcons/Group 8526.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="29.171" height="21.284" viewBox="0 0 29.171 21.284">
    <g id="Group_8526" data-name="Group 8526" transform="translate(-638.983 -177.716)">
      <g id="Subtraction_2" data-name="Subtraction 2" transform="translate(638.996 184.096)">
        <path id="Path_19038" data-name="Path 19038" d="M4.6,9.484H0V4.806A6.854,6.854,0,0,1,.356,2.159,3.962,3.962,0,0,1,4.052,0,1.4,1.4,0,0,1,4.234,0H23.809a15.413,15.413,0,0,1,3.879.7,2.779,2.779,0,0,1,1.47,2.154V9.482H23.84a3.017,3.017,0,0,0-5.451,0H10.054a3.018,3.018,0,0,0-5.452,0Z" fill="none"/>
        <path id="Path_19039" data-name="Path 19039" d="M3.546,7.484a5.011,5.011,0,0,1,7.563,0h6.224a5.011,5.011,0,0,1,7.563,0h2.262V3.022a.838.838,0,0,0-.251-.476,12.961,12.961,0,0,0-3.155-.541H4.165L4.1,2H4.052a2.951,2.951,0,0,0-1.017.188,1.437,1.437,0,0,0-.851.785A5.883,5.883,0,0,0,2,4.8v.009c0,.975,0,1.9,0,2.671H3.546m1.056,2H0s0-.045,0-.12V9.233C0,8.635,0,6.813,0,4.806A6.854,6.854,0,0,1,.356,2.159,3.962,3.962,0,0,1,4.052,0c.1,0,.159,0,.182.005H23.809a15.413,15.413,0,0,1,3.879.7,2.779,2.779,0,0,1,1.47,2.154V9.482H23.84a3.017,3.017,0,0,0-5.451,0H10.054a3.018,3.018,0,0,0-5.452,0Z"/>
      </g>
      <path id="Path_18854" data-name="Path 18854" d="M779,139.495l1.9-4.1a3.128,3.128,0,0,1,.958-1.213,3.265,3.265,0,0,1,1.506-.431h8.361a4.422,4.422,0,0,1,1.335.431,3.826,3.826,0,0,1,1.015.877l3.024,4.436Z" transform="translate(-135.692 44.965)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_75" data-name="Line 75" y2="6.032" transform="translate(651.494 178.5)" fill="none" stroke="" stroke-width="2"/>
      <g id="Ellipse_54" data-name="Ellipse 54" transform="translate(643 193)" fill="none" stroke="" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="2" fill="none"/>
      </g>
      <g id="Ellipse_56" data-name="Ellipse 56" transform="translate(657 193)" fill="none" stroke="" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="2" fill="none"/>
      </g>
    </g>
  </svg>`},
  {
    id: 84, svgImg: `assets/images/tabIcons/Group 8529.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="25.436" height="32.091" viewBox="0 0 25.436 32.091">
    <g id="Group_8529" data-name="Group 8529" transform="translate(-324.369 -626.288) rotate(47)">
      <g id="Group_8527" data-name="Group 8527" transform="translate(689.498 180.992)">
        <path id="Path_18855" data-name="Path 18855" d="M15.429,0V7.308a4.872,4.872,0,0,1-4.872,4.872H4.872A4.872,4.872,0,0,1,0,7.308V0" transform="translate(15.429 12.18) rotate(180)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18856" data-name="Path 18856" d="M5.278,4v6.5c0,1.794-1.182,3.248-2.639,3.248S0,12.29,0,10.5V5.244" transform="translate(10.151 2.629)" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Group_8528" data-name="Group 8528" transform="translate(694.404 185.721)">
        <path id="Path_18855-2" data-name="Path 18855" d="M15.429,0V7.308a4.872,4.872,0,0,1-4.872,4.872H4.872A4.872,4.872,0,0,1,0,7.308V0" transform="translate(0.001 4.193)" fill="none" stroke="" stroke-width="2"/>
        <path id="Path_18856-2" data-name="Path 18856" d="M0,12.932V7.248C0,5.454,1.182,4,2.639,4S5.278,5.454,5.278,7.248v5.706" transform="translate(0 -4)" fill="none" stroke="" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 85, svgImg: `assets/images/tabIcons/Group 8530.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.014" viewBox="0 0 24 20.014">
    <g id="Group_8530" data-name="Group 8530" transform="translate(-737 -181)">
      <g id="Rectangle_4259" data-name="Rectangle 4259" transform="translate(737 185)" fill="none" stroke="" stroke-width="2">
        <rect width="24" height="6" stroke="none"/>
        <rect x="1" y="1" width="22" height="4" fill="none"/>
      </g>
      <line id="Line_76" data-name="Line 76" y2="11" transform="translate(749 190)" fill="none" stroke="" stroke-width="2"/>
      <g id="Ellipse_57" data-name="Ellipse 57" transform="translate(744 181)" fill="none" stroke="" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="2" fill="none"/>
      </g>
      <g id="Ellipse_58" data-name="Ellipse 58" transform="translate(748 181)" fill="none" stroke="" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="2" fill="none"/>
      </g>
      <path id="Path_18858" data-name="Path 18858" d="M18,2v8.333A1.856,1.856,0,0,1,16,12H2a1.856,1.856,0,0,1-2-1.667V2" transform="translate(740 188)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 86, svgImg: `assets/images/tabIcons/Group 8533.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="26.222" height="18" viewBox="0 0 26.222 18">
    <g id="Group_8533" data-name="Group 8533" transform="translate(-828.937 -185)">
      <path id="Path_18865" data-name="Path 18865" d="M1050,168l2.156-5.089a1.508,1.508,0,0,1,.386-.567,2.073,2.073,0,0,1,.675-.344h13.417a1.131,1.131,0,0,1,.464.152,1.242,1.242,0,0,1,.338.373l2.9,5.475Z" transform="translate(-218 24)" fill="none" stroke="" stroke-width="2"/>
      <path id="Path_18866" data-name="Path 18866" d="M.006-1.965H7.047L6.982,19.5h-7" transform="translate(851.004 192.953) rotate(90)" fill="none" stroke="" stroke-width="2"/>
      <g id="Rectangle_4260" data-name="Rectangle 4260" transform="translate(830.5 200)">
        <path id="Path_19040" data-name="Path 19040" d="M0,0H4V3H2A2,2,0,0,1,0,1Z"/>
        <rect id="Rectangle_4274" data-name="Rectangle 4274" width="2" height="1" rx="0.5" transform="translate(1 1)" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Rectangle_4261" data-name="Rectangle 4261" transform="translate(849.99 200)">
        <path id="Path_19041" data-name="Path 19041" d="M0,0H4V1A2,2,0,0,1,2,3H0Z"/>
        <rect id="Rectangle_4275" data-name="Rectangle 4275" width="2" height="1" rx="0.5" transform="translate(1 1)" fill="none" stroke="" stroke-width="2"/>
      </g>
      <g id="Ellipse_59" data-name="Ellipse 59" transform="translate(835 194)" stroke="" stroke-width="2">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
      </g>
      <g id="Ellipse_60" data-name="Ellipse 60" transform="translate(846 194)" stroke="" stroke-width="2">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
      </g>
      <line id="Line_77" data-name="Line 77" x2="1" y2="3" transform="matrix(0.799, -0.602, 0.602, 0.799, 829.885, 190.067)" fill="none" stroke="" stroke-width="2"/>
      <line id="Line_78" data-name="Line 78" x2="1" y2="3" transform="matrix(0.326, 0.946, -0.946, 0.326, 854.224, 190.078)" fill="none" stroke="" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 87, svgImg: `assets/images/tabIcons/Group 8534.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="21.799" height="18.97" viewBox="0 0 21.799 18.97">
    <g id="Group_8534" data-name="Group 8534" transform="translate(-778.201 -183.961)">
      <g id="Group_8532" data-name="Group 8532" transform="translate(-19.4 -2.345)">
        <g id="Path_18859" data-name="Path 18859" transform="translate(800.4 191.246)">
          <path id="Path_19042" data-name="Path 19042" d="M-.4-2.009l5.584-2.3a11.449,11.449,0,0,1,2.3-.559,8.9,8.9,0,0,1,3.607.281l3.3,1.6L19-4.312V10.425H7.487L11.344,4.9,8.062,1.848a17.675,17.675,0,0,1-4.03.748,3.47,3.47,0,0,1-2.36-.748A5.835,5.835,0,0,1,.579.293C.075-.714-.4-2.009-.4-2.009Z" fill="none"/>
          <path id="Path_19043" data-name="Path 19043" d="M8.533-2.9c-.295,0-.61.009-.937.027a9.138,9.138,0,0,0-1.7.433L2.212-.923c.051.11.1.218.155.322a4.282,4.282,0,0,0,.65.965A1.913,1.913,0,0,0,4.033.6a15.187,15.187,0,0,0,3.442-.66l1.1-.34,5.4,5.021L11.321,8.425H17V-1.656l-2.789.8-3.88-1.883a7.383,7.383,0,0,0-1.8-.164m0-2a7.122,7.122,0,0,1,2.561.311l3.3,1.6L19-4.312V10.425H7.487L11.344,4.9,8.062,1.848a17.676,17.676,0,0,1-4.03.748,3.47,3.47,0,0,1-2.36-.748A5.835,5.835,0,0,1,.579.293C.075-.714-.4-2.009-.4-2.009l5.584-2.3a11.449,11.449,0,0,1,2.3-.559C7.87-4.892,8.218-4.9,8.533-4.9Z"/>
        </g>
        <g id="Group_8531" data-name="Group 8531" transform="translate(797.986 188.534) rotate(-4.992)">
          <path id="Path_18864" data-name="Path 18864" d="M0,0V8" transform="translate(0.723 1.664)" fill="none" stroke="" stroke-width="2"/>
          <path id="Path_18863" data-name="Path 18863" d="M8.045,0,0,2.564" fill="none" stroke="" stroke-width="2"/>
          <path id="Path_18862" data-name="Path 18862" d="M10.266,5.2c-.145.929.417,4.987-.465,4.987L.89,10.153C.008,10.153,0,8.9,0,7.293V0" transform="matrix(0.695, -0.719, 0.719, 0.695, 0.4, 8.992)" fill="none" stroke="" stroke-width="2"/>
        </g>
      </g>
    </g>
  </svg>`},
  // {
  //   id: 88, svgImg: `assets/images/tabIcons/Group 8535.svg`, svgTag: `<<svg xmlns="http://www.w3.org/2000/svg" width="20.357" height="16.488" viewBox="0 0 20.357 16.488">
  //   <g id="Group_8535" data-name="Group 8535" transform="translate(-369 -80.512)">
  //     <g id="Path_18867" data-name="Path 18867" transform="translate(369 81)">
  //       <path id="Path_19044" data-name="Path 19044" d="M0,0H16V16H0Z" fill="none"/>
  //       <path id="Path_19045" data-name="Path 19045" d="M2,2V14H14V2H2M0,0H16V16H0Z"/>
  //     </g>
  //     <rect id="Rectangle_12" data-name="Rectangle 12" width="5.713" height="9.489" transform="matrix(0.616, 0.788, -0.788, 0.616, 385.838, 80.512)" fill="#fff"/>
  //     <path id="Path_1097" data-name="Path 1097" d="M360.667,60.094,363.8,64,374,55" transform="translate(12.833 28)" fill="none" stroke="" stroke-width="2"/>
  //   </g>
  // </svg>`},
  // {
  //   id: 89, svgImg: `assets/images/tabIcons/Group 8536.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
  //   <g id="Group_8536" data-name="Group 8536" transform="translate(-144 -132)">
  //     <g id="Group_8467" data-name="Group 8467">
  //       <g id="Ellipse_36" data-name="Ellipse 36" transform="translate(144 145)" fill="none" stroke="" stroke-width="2">
  //         <ellipse cx="8" cy="3.5" rx="8" ry="3.5" stroke="none"/>
  //         <ellipse cx="8" cy="3.5" rx="7" ry="2.5" fill="none"/>
  //       </g>
  //       <rect id="Rectangle_4242" data-name="Rectangle 4242" width="8" height="16" rx="2" transform="translate(148 132)" fill="#fff"/>
  //       <g id="Ellipse_37" data-name="Ellipse 37" transform="translate(150.3 140)" stroke="" stroke-width="2">
  //         <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
  //         <circle cx="1.5" cy="1.5" r="0.5" fill="none"/>
  //       </g>
  //       <g id="Path_18805" data-name="Path 18805" transform="translate(147.775 137)">
  //         <path id="Path_19046" data-name="Path 19046" d="M4.025,0A4.025,4.025,0,0,1,8.05,4.025c0,2.223-4.025,7.475-4.025,7.475S0,6.248,0,4.025A4.025,4.025,0,0,1,4.025,0Z" fill="none"/>
  //         <path id="Path_19047" data-name="Path 19047" d="M4.025,1.5A2.528,2.528,0,0,0,1.5,4.025c0,.1.05,1.03,1.769,3.751.257.407.515.8.756,1.15.241-.353.5-.743.756-1.15C6.5,5.055,6.55,4.122,6.55,4.025A2.528,2.528,0,0,0,4.025,1.5m0-1.5A4.025,4.025,0,0,1,8.05,4.025c0,2.223-4.025,7.475-4.025,7.475S0,6.248,0,4.025A4.025,4.025,0,0,1,4.025,0Z"/>
  //       </g>
  //     </g>
  //   </g>
  // </svg>`},
  { id: 90, svgImg: `assets/images/tabIcons/Group 8537.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.526" viewBox="0 0 18 13.526">
  <g id="Group_8537" data-name="Group 8537" transform="translate(0.25 0.193)">
    <g id="c">
      <g id="Group_8543" data-name="Group 8543">
        <path id="Path_19048" data-name="Path 19048" d="M14.02.56a7.98,7.98,0,0,1,0,12.02" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd"/>
        <path id="Path_19049" data-name="Path 19049" d="M3.48,12.58A7.98,7.98,0,0,1,3.48.56" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd"/>
        <path id="Path_19050" data-name="Path 19050" d="M11.66,2.5a5.008,5.008,0,0,1,0,8.14" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd"/>
        <path id="Path_19051" data-name="Path 19051" d="M5.84,10.64a5.008,5.008,0,0,1,0-8.14" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd"/>
        <circle id="Ellipse_70" data-name="Ellipse 70" cx="2" cy="2" r="2" transform="translate(6.75 4.57)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
      </g>
    </g>
  </g>
</svg>` },
  {
    id: 91, svgImg: `assets/images/tabIcons/22.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.505" height="23.727" viewBox="0 0 17.505 23.727">
    <g id="_22" data-name="22" transform="translate(-474 -179.571)">
      <g id="Nopath_22" data-name="Nopath 22" transform="translate(475 180.5)">
        <path id="Path_18874" data-name="Path 18874" d="M22.15,6.5c-.426,2.112-.841,4.23-1.294,6.336a.323.323,0,0,1-.132.207l-4.4,3.51-1.746,1.4a.337.337,0,0,1-.466,0,3.57,3.57,0,0,1-1.074-2.368,3.209,3.209,0,0,1,.232-1.2h0a4.632,4.632,0,0,1,3.39-2.587.375.375,0,0,0,.285-.3,3.3,3.3,0,0,0-6.468-1.1h0v4.322" transform="translate(-6.624 -2.618)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_18875" data-name="Path 18875" d="M9.208,17.8a1.463,1.463,0,0,1-.414-.065A2.187,2.187,0,0,1,7.5,16.438V10.15L10.308,6l6.249-2.5" transform="translate(-7.5 -3.5)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_18876" data-name="Path 18876" d="M14.464,12.6h0a4.27,4.27,0,0,0-.982-.1,4.9,4.9,0,0,0-2.134.484,4.982,4.982,0,0,0-1.152.783,4.4,4.4,0,0,0-1.11,1.423l-.132.264A4.768,4.768,0,0,0,8.5,17.481a4.981,4.981,0,1,0,9.25-2.462" transform="translate(-7.207 -0.855)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 92, svgImg: `assets/images/tabIcons/23.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="17.229" height="15.83" viewBox="0 0 17.229 15.83">
    <g id="_23" data-name="23" transform="translate(-508.732 -181.263)">
      <g id="path_23" data-name="path 23" transform="translate(508.512 206.409)">
        <path id="Path_18877" data-name="Path 18877" d="M417,370.1l3.8-3.036.637-.461a1.6,1.6,0,0,1,.953-.316l5.525-.055a1.743,1.743,0,0,1-.206,1.421,1.646,1.646,0,0,1-.832.607l-2.52.061a.886.886,0,0,0-.3.607.911.911,0,0,0,.328.741l3.612-.055,4.183-4.3a1.154,1.154,0,0,1,1.39.206,1.166,1.166,0,0,1,.182,1.39l-4.718,4.833-7.055.182-1.658,1.639Z" transform="translate(-416.63 -383.03)" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.3"/>
        <path id="Path_18878" data-name="Path 18878" d="M1074,345" transform="translate(-1056.63 -364.82)" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="18"/>
      </g>
      <g id="Path_18879" data-name="Path 18879" transform="translate(515.882 181.283)">
        <path id="Path_18904" data-name="Path 18904" d="M4,7.273A9.743,9.743,0,0,1,.259,3.394,2.3,2.3,0,0,1,4,.763,2.3,2.3,0,0,1,7.741,3.394,9.743,9.743,0,0,1,4,7.273Z" fill="none"/>
        <path id="Path_18905" data-name="Path 18905" d="M4,5.679A7.351,7.351,0,0,0,6.542,2.892a1.264,1.264,0,0,0,.026-1.155,1.086,1.086,0,0,0-.9-.438A.841.841,0,0,0,5,1.59L4,2.808,3,1.59a.841.841,0,0,0-.669-.29,1.086,1.086,0,0,0-.9.438,1.264,1.264,0,0,0,.025,1.155A7.351,7.351,0,0,0,4,5.679M4,7.273A9.743,9.743,0,0,1,.259,3.394,2.3,2.3,0,0,1,4,.763,2.3,2.3,0,0,1,7.741,3.394,9.743,9.743,0,0,1,4,7.273Z"/>
      </g>
    </g>
  </svg>`},
  {
    id: 93, svgImg: `assets/images/tabIcons/32.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="22.384" viewBox="0 0 23 22.384">
    <g id="_32" data-name="32" transform="translate(172 19)">
      <g id="path_32" data-name="path 32" transform="translate(-171 -18)">
        <path id="Path_18884" data-name="Path 18884" d="M1.813-.229,0,2.675" transform="translate(4.09 2.841)" fill="none" stroke="" stroke-width="1.5"/>
        <path id="Path_18883" data-name="Path 18883" d="M0,0,2.675,2.032" transform="translate(3.782 4.7)" fill="none" stroke="" stroke-width="1.5"/>
        <circle id="Ellipse_62" data-name="Ellipse 62" cx="5.096" cy="5.096" r="5.096" transform="translate(0 0)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_18882" data-name="Path 18882" d="M946.507,212h6.964a1.485,1.485,0,0,1,1.48,1.48v14.263a1.212,1.212,0,0,1-1.208,1.208H939.152A1.156,1.156,0,0,1,938,227.8v-6.308" transform="translate(-933.951 -208.567)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
        <line id="Line_85" data-name="Line 85" x1="8.002" transform="translate(12.382 7.174)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_18885" data-name="Path 18885" d="M0-.316V2.517" transform="translate(18.623 0.881)" fill="none" stroke="" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 94, svgImg: `assets/images/tabIcons/33.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20.007" height="17.64" viewBox="0 0 20.007 17.64">
    <g id="_33" data-name="33" transform="translate(0 -17.574)">
      <g id="Group_8539" data-name="Group 8539" transform="translate(0 17.574)">
        <path id="Path_18886" data-name="Path 18886" d="M19.506,21.727l-9.147-4.079a.854.854,0,0,0-.7,0L.514,21.727a.857.857,0,0,0-.055,1.538l9.147,4.878a.858.858,0,0,0,.807,0l9.147-4.878a.857.857,0,0,0-.055-1.538Zm-9.5,4.69-7.2-3.839,7.2-3.21,7.2,3.21Z" transform="translate(-0.006 -17.573)"/>
        <path id="Path_18887" data-name="Path 18887" d="M18.748,132,10,136.665,1.26,132a.857.857,0,0,0-.807,1.511l9.147,4.88a.858.858,0,0,0,.807,0l9.147-4.879A.857.857,0,0,0,18.748,132Z" transform="translate(0 -124.197)"/>
        <path id="Path_18888" data-name="Path 18888" d="M18.748,181.661,10,186.325l-8.74-4.664a.857.857,0,0,0-.807,1.511L9.6,188.051a.858.858,0,0,0,.807,0l9.147-4.879a.857.857,0,0,0-.807-1.511Z" transform="translate(0 -170.512)"/>
      </g>
    </g>
  </svg>`},
  {
    id: 95, svgImg: `assets/images/tabIcons/34.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="26.287" height="24.212" viewBox="0 0 26.287 24.212">
    <g id="_34" data-name="34" transform="translate(-20.25 -20.25)">
      <path id="Path_18889" data-name="Path 18889" d="M23.339,21.5H35.987a1.584,1.584,0,0,1,1.584,1.584V43.212H21.5V23.339A1.839,1.839,0,0,1,23.339,21.5Z" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2.5"/>
      <path id="Path_18890" data-name="Path 18890" d="M854.5,311.411h7.716V297.084a1.588,1.588,0,0,0-1.584-1.584H854.5" transform="translate(-816.929 -268.253)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2.5"/>
      <rect id="Rectangle_4265" data-name="Rectangle 4265" width="2.115" height="2.115" transform="translate(25.921 25.921)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_4266" data-name="Rectangle 4266" width="2.115" height="2.115" transform="translate(31.112 25.921)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_4267" data-name="Rectangle 4267" width="2.115" height="2.115" transform="translate(25.921 31.304)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_4268" data-name="Rectangle 4268" width="2.115" height="2.115" transform="translate(31.094 31.304)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_4269" data-name="Rectangle 4269" width="2.115" height="2.115" transform="translate(38.893 31.304)" stroke="" stroke-miterlimit="10" stroke-width="0.8"/>
      <rect id="Rectangle_4270" data-name="Rectangle 4270" width="2.115" height="2.115" transform="translate(38.893 36.494)" stroke="" stroke-miterlimit="10" stroke-width="0.8"/>
      <rect id="Rectangle_4271" data-name="Rectangle 4271" width="2.115" height="2.115" transform="translate(25.921 36.494)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_4272" data-name="Rectangle 4272" width="2.115" height="2.115" transform="translate(31.093 36.494)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>`},
  {
    id: 96, svgImg: `assets/images/tabIcons/35.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="24.005" height="17.498" viewBox="0 0 24.005 17.498">
    <g id="_35" data-name="35" transform="translate(-7.93 -7.93)">
      <g id="Layer_1" data-name="Layer 1" transform="translate(8.93 8.93)">
        <path id="Path_18896" data-name="Path 18896" d="M89.851,153.35v6.859l-8.272,3.049-8.789-3.049.13-6.82" transform="translate(-70.318 -147.76)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Path_18897" data-name="Path 18897" d="M72.79,153.35l8.789,3.619,8.272-3.619" transform="translate(-70.318 -147.76)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <line id="Line_88" data-name="Line 88" y2="6.289" transform="translate(11.358 9.188)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Path_18898" data-name="Path 18898" d="M19.932,11.677,17.46,8.93,8.93,11.773,11.4,14.52" transform="translate(-8.93 -8.93)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Path_18899" data-name="Path 18899" d="M81.321,11.677,83.793,8.93l8.53,2.843L89.851,14.52l-8.53-2.843L72.79,14.52" transform="translate(-70.318 -8.93)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </g>
  </svg>`},
  {
    id: 97, svgImg: `assets/images/tabIcons/36.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="16.755" height="17.141" viewBox="0 0 16.755 17.141">
    <g id="_36" data-name="36" transform="translate(-17.601 -28.158)">
      <path id="Path_18891" data-name="Path 18891" d="M490.908,299.61a7.476,7.476,0,1,1-11.372.159" transform="translate(-459.31 -267.619)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.8"/>
      <line id="Line_86" data-name="Line 86" y2="7.914" transform="translate(25.999 29.426)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_18892" data-name="Path 18892" d="M151.208,30.988H148.89l1.159-1.074,1.159-1.074,1.159,1.074,1.159,1.074Z" transform="translate(-125.217)" stroke="" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>`},
  {
    id: 98, svgImg: `assets/images/tabIcons/37.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="21.37" height="24.638" viewBox="0 0 21.37 24.638">
    <path id="_37" data-name="37" d="M10.946,14.065a.312.312,0,0,1-.358.012,12.548,12.548,0,0,1-3.1-2.769A9.863,9.863,0,0,1,5.4,6.8a5.961,5.961,0,0,1,.758-4.215,5.192,5.192,0,0,1,1.15-1.279A5.621,5.621,0,0,1,10.838,0,5,5,0,0,1,14.2,1.369a5,5,0,0,1,.9,1.092,6.677,6.677,0,0,1,.637,4.831,10.633,10.633,0,0,1-4.783,6.773Zm-3.654.142v5.35l5.8,2.5v-6.89a12.533,12.533,0,0,0,1.24-1.083V22l4.808-2.421V8.813l-1.848.712a11.849,11.849,0,0,0,.458-1.5l1.788-.69a.619.619,0,0,1,.79.36.612.612,0,0,1,.042.223V19.973a.621.621,0,0,1-.385.577l-6,3.013a.621.621,0,0,1-.562,0L6.692,20.646.9,23.563a.619.619,0,0,1-.833-.273A.631.631,0,0,1,0,23.011V10.238a.621.621,0,0,1,.446-.6L3.6,8.427a11.275,11.275,0,0,0,.415,1.167L1.242,10.661V22.008L6.05,19.586V12.9a14.669,14.669,0,0,0,1.242,1.308ZM10.606,2.733A2.638,2.638,0,1,1,7.967,5.371a2.638,2.638,0,0,1,2.639-2.638Z" transform="translate(0.5 0.5)" stroke="" stroke-width="1" fill-rule="evenodd"/>
  </svg>`},
  {
    id: 99, svgImg: `assets/images/tabIcons/38.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="21.192" height="20.011" viewBox="0 0 21.192 20.011">
    <g id="_38" data-name="38" transform="translate(-17.448 -30.781)">
      <path id="Path_18893" data-name="Path 18893" d="M559.359,441.327c-.017-3.791,4.36-6.88,9.789-6.907" transform="translate(-539.5 -390.539)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_87" data-name="Line 87" x1="0.34" y2="8.025" transform="translate(22.103 38.39)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_18894" data-name="Path 18894" d="M533.518,207.091a8.606,8.606,0,0,1-1.994-3.939c-.745-3.753,1.31-7.887,5.156-10.651l2.286,5.156" transform="translate(-512.88 -160.19)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_18895" data-name="Path 18895" d="M594.1,346.995a8.568,8.568,0,0,1,4.329-4.572A9.106,9.106,0,0,1,608.5,345a10.549,10.549,0,0,1-2.723,3.7,10.9,10.9,0,0,1-5.588,2.484,12.824,12.824,0,0,1-7.684-1.216" transform="translate(-571.029 -302.43)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="2"/>
    </g>
  </svg>`},
  {
    id: 100, svgImg: `assets/images/tabIcons/39.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="21.774" height="13.286" viewBox="0 0 21.774 13.286">
    <g id="_39" data-name="39" transform="translate(-0.22 19.82)">
      <path id="Path_18877" data-name="Path 18877" d="M417,371.423l4.813-3.85.809-.585a2.033,2.033,0,0,1,1.209-.4l7.008-.069a2.21,2.21,0,0,1-.262,1.8,2.088,2.088,0,0,1-1.055.77l-3.2.077a1.124,1.124,0,0,0-.377.77,1.155,1.155,0,0,0,.416.939l4.582-.069,5.306-5.452a1.463,1.463,0,0,1,1.764.262,1.479,1.479,0,0,1,.231,1.764l-5.984,6.13-8.948.231-2.1,2.079Z" transform="translate(-416.63 -382.504)" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.3"/>
      <path id="Path_18878" data-name="Path 18878" d="M1074,345" transform="translate(-1052.068 -364.82)" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="18"/>
    </g>
  </svg>`},
  // {
  //   id: 101, svgImg: `assets/images/tabIcons/Path 1087.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="15.661" height="14.456" viewBox="0 0 15.661 14.456">
  //   <g id="Path_1087" data-name="Path 1087" fill="none">
  //     <path d="M7.831,14.456C3.62,11.57,1.412,8.944.506,6.746-1.92.857,5.01-1.96,7.831,1.517c2.821-3.477,9.75-.66,7.324,5.229C14.249,8.944,12.041,11.57,7.831,14.456Z" stroke="none"/>
  //     <path d="M 7.830507278442383 12.00133609771729 C 11.3606424331665 9.385114669799805 12.75951766967773 7.309430599212646 13.30547714233398 5.98450231552124 C 13.63847732543945 5.176102161407471 13.90633773803711 4.019652366638184 13.29131698608398 3.101152420043945 C 12.84341716766357 2.43223237991333 11.98491764068604 2.000002384185791 11.10418701171875 2.000002384185791 C 10.69701766967773 2.000002384185791 9.93205738067627 2.100932359695435 9.383657455444336 2.776892423629761 L 7.830517292022705 4.691302299499512 L 6.277367115020752 2.776902437210083 C 5.728957176208496 2.100932359695435 4.964007377624512 2.000002384185791 4.55683708190918 2.000002384185791 C 3.676257371902466 2.000002384185791 2.817807197570801 2.432282209396362 2.369857311248779 3.101272344589233 C 1.754777312278748 4.019852161407471 2.022577285766602 5.176202297210693 2.355447292327881 5.984282493591309 C 2.901488780975342 7.309410572052002 4.300371646881104 9.385112762451172 7.830507278442383 12.00133609771729 M 7.830507278442383 14.45632266998291 C 3.620097160339355 11.56987190246582 1.411897301673889 8.943972587585449 0.5062872767448425 6.746262550354004 C -1.919792652130127 0.8566725850105286 5.009835720062256 -1.959918260574341 7.830507278442383 1.516842365264893 C 10.65117835998535 -1.959918856620789 17.58081817626953 0.8566731214523315 15.1547269821167 6.746262550354004 C 14.24911689758301 8.943972587585449 12.04091739654541 11.56987190246582 7.830507278442383 14.45632266998291 Z" stroke="none" fill="#000"/>
  //   </g>
  // </svg>`},
  {
    id: 102, svgImg: `assets/images/tabIcons/Path 1103.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="10.239" height="16.75" viewBox="0 0 10.239 16.75">
    <g id="Path_1103" data-name="Path 1103" transform="translate(0.25 9.5)">
      <path id="Path_19054" data-name="Path 19054" d="M4.87-9S9.739-.559,9.739,2.13A4.87,4.87,0,1,1,0,2.13C0-.559,4.87-9,4.87-9Z" fill="none"/>
      <path id="Path_19055" data-name="Path 19055" d="M4.87-5.891c-.34.638-.716,1.362-1.092,2.116C1.664.472,1.5,1.819,1.5,2.13a3.37,3.37,0,1,0,6.739,0c0-.311-.164-1.655-2.271-5.893-.377-.758-.756-1.487-1.1-2.129M4.87-9S9.739-.559,9.739,2.13A4.87,4.87,0,1,1,0,2.13C0-.559,4.87-9,4.87-9Z" stroke="" stroke-width="0.5"/>
    </g>
  </svg>`},
  {
    id: 103, svgImg: `assets/images/tabIcons/Path 18839.svg`, svgTag: `<svg xmlns="http://www.w3.org/2000/svg" width="20.503" height="20.503" viewBox="0 0 20.503 20.503">
    <g id="Path_18839" data-name="Path 18839" transform="matrix(0.719, -0.695, 0.695, 0.719, 0.354, 10.083)">
      <path id="Path_19056" data-name="Path 19056" d="M0,0H14V14H0Z" fill="none" stroke="" stroke-width="0.5"/>
      <path id="Path_19057" data-name="Path 19057" d="M2,2V12H12V2H2M0,0H14V14H0Z" stroke="" stroke-width="0.5"/>
    </g>
  </svg>`},
];
