import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HlsjsPlyrDriver } from 'src/app/hlsjs-plyr-driver/hlsjs-plyr-driver';
import { EditListItem, CatalogContentDTO, RemoveCatalogContent, MobileListDesign } from 'src/app/models/library.model';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-mobile-apps',
  templateUrl: './mobile-apps.component.html',
  styleUrls: ['./mobile-apps.component.css']
})
export class MobileAppsComponent implements OnInit {

  trendingList: any[] = []
  allTrendingList: any[] = []
  trendingListLength: any;
  editListItem: EditListItem
  listItemId: number;
  artworkList: any = {};
  mediaSeriesList: any = {}
  basicData: any;
  basicOptions: any;
  catalogContentDTO: CatalogContentDTO
  selectAll = false;
  mediaSeriesId: number
  selectedItems: any[] = [];
  square: number;
  wide: number;
  banner: number;
  mediaSerieId: any[] = []
  mediaItemId: any[] = []
  folderItemId: any[] = []
  combined: any[] = []
  Data: any[] = []
  trendingListSpliceData: any[] = []
  selectedMedia: any;
  deleteMediaModal = false
  viewAllItems = false;
  isBulkDelete = false;
  deleteData: any[] = []
  mobileHeaderType: string
  mobileItemLayout: string
  itemDisplay: string
  itemImages: string
  itemTitles: string
  margin: boolean
  shadow: boolean
  webHeaderType: string
  tvHeaderType: string
  listDesignData: any[] = []
  routeFrom: any;
  displayBy = 'ALBUMS';
  sortBy = 'A-Z';
  noOfItem = '25';
  removeCatalogContent: RemoveCatalogContent
  updateData = false
  mobileId: number
  webId: number
  tvId: number
  listDesign: MobileListDesign
  offActive: boolean
  rowActive: boolean
  staticActive: boolean
  gridActive: boolean
  stackActive: boolean
  carouselActive: boolean
  orderObj: any;
  contentData: any[] = [];
  timeZone: any;
  updatedDate: any;
  contentLength: any;
  carousalRotate = true

  // mobile app list component variables
  eventPanel = false
  calenderPanel = false
  headerType: string
  itemLayout: string
  infoMsg = false;
  infoCount = 0
  mainTab = true
  subTab = false
  dataList: any[] = []
  updatedArray: any[] = []
  newDataArray: any[] = []
  sendDataArray: any
  carousalData: any

  showSquare: boolean
  showWide: boolean
  showBanner: boolean
  showShadow: boolean
  marginType: any;
  staticHeaderColor: any
  staticBannerHeaderColor: any
  products: any[] = []
  staticHeaderImgid: any
  staticHeaderImg: any
  staticWideHeaderImg: any
  staticBannerHeaderImg: any
  staticType: any
  marginEdges: any
  carousalType: any
  showBannerCarousal = false
  staticArtworkId: any
  landscapeImageId: any;
  landscapeArtworkId: any
  showImage = true;
  showDate: boolean;
  showTitle: boolean;
  // itemDisplay: any;
  itemTitle: any
  overlayText: boolean;
  theme: any = 'light'
  checked = false
  checkMargins: boolean
  listDesignId: any
  staticImageData: any[] = []
  staticImageName: string
  imagePathWide: any
  withWideArtwork = true;
  imagePath: string
  catalogID: any
  loginUserDetail: any
  orgId: any
  listDataArray: any[] = [];

  currentVedio: Plyr.Source[] = [
    { src: '' }
  ]
  hlsjsDriver1 = new HlsjsPlyrDriver(true)

  hlsjsDriver2 = new HlsjsPlyrDriver(false);

  players: Plyr;

  options: Plyr.Options = {
    // autoplay: true,
    volume: 1,
    muted: false,
    // invertTime: false,
    disableContextMenu: true,
    displayDuration: true,
    settings: [],
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
    captions: {},
    loop: { active: false }
  }
  poster: string
  m3u8 = false
  // videoPath = 'https://d22trojezeokn1.cloudfront.net/1/public/1651689838182_1651689837610_nowcast_Glitch_Promo_%28Final%29.mp4'
  // videoPath: string = 'https://d22trojezeokn1.cloudfront.net/630/public/1669807102630_15minLowTrim.mp4';
     videoPath: string = " https://d22trojezeokn1.cloudfront.net/651/public/2322/m3u8/HD/1670238420101_nowcast_Glitch_Promo__Final_.m3u8"

  videoPathClone = this.videoPath
  image = true
  video = false
  staticModal = false
  mainData: any[] = []
  mappedData: any[] = []
  wideArtworkId: number;
  wideImageId: number;
  showDeleteIcon = false

  drop(event: CdkDragDrop<string[]>, type) {
    moveItemInArray(this.trendingList, event.previousIndex, event.currentIndex);
    if (type === 'content') {
      let id = this.trendingList[event.currentIndex]['id'];
      var tabIndex = event.currentIndex;
      this.trendingList[event.currentIndex].sortOrder = tabIndex
      console.log(id, 'dataid');
    }
  }

  newselectedData: any[] = []
  changePosition(event, item) {
    console.log(event, item);
    this.trendingList.forEach(el => {
      if (el.id === item.id && el.type === item.type) {
        el['isSelected'] = true
      }
    })
    this.newselectedData.push(item)
    if (this.newselectedData.length === 2) {
      let previousIndex;
      let currentIndex;
      this.trendingList.forEach((el, i) => {
        if (el.id === this.newselectedData[0].id && el.type === this.newselectedData[0].type) {
          previousIndex = i
        }
        if (el.id === this.newselectedData[1].id && el.type === this.newselectedData[1].type) {
          currentIndex = i
        }
      })
      // this.drop(event,'content')
      moveItemInArray(this.trendingList, previousIndex, currentIndex)
      setTimeout(() => {
        previousIndex = null
        currentIndex = null
        this.newselectedData = []
        this.trendingList.forEach(el => {
          el['isSelected'] = false
        })
      }, 200);
    }
  }
  constructor(
    public libraryService: LibraryServiceService,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public messageService: MessageService,
    public artWorkService: ArtWorkService,
    public router: Router,
    public authService: AuthenticateService,
    private location: Location
  ) {
    this.editListItem = new EditListItem()
    this.catalogContentDTO = new CatalogContentDTO()
    this.removeCatalogContent = new RemoveCatalogContent()
    this.listDesign = new MobileListDesign()
   }

   ngOnInit(): void {
    this.loginUserDetail = localStorage.getItem('loggedInUser');
    this.orgId = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.organization.id : '';
    this.listItemId = +this.route.snapshot.paramMap.get('id');
    this.timeZone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.timeZone : '';
    this.routeFrom = this.route.snapshot.paramMap['params']['getFrom']
    //  this.sendCatalog()
    this.route.queryParamMap
      .subscribe((params) => {
        this.orderObj = params['params'];
        if (this.orderObj.from === 'list-panel') {
          setTimeout(() => {
            if (this.orderObj.tabType === 'list') {
              let data = this.orderObj.tabType
              this.libraryService.updatePanel({ showPanel: true, clickedFrom: data, arrayMobile: this.combined, itemType: this.orderObj.type, panel: 'list', tabId: this.listItemId })
            }
          }, 500)
        }

      }
      );
    this.getBrandingData(this.orgId)
    console.log(this.orderObj)
    console.log(this.listItemId)
    console.log(this.editListItem.subTitle)
    this.basicOptions = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: '#ccc'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: '#ccc'
          }
        }]
      }
    };
    this.basicData = {
      labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      datasets: [
        {
          data: [0, 20, 40, 50, 40, 50, 20, 40, 40, 30, 40],
          fill: false,
          borderColor: '#a0e13a'
        }
      ]
    };
    if (this.listItemId) {
      this.getListItemData(this.listItemId)
    }
    this.libraryService.mediaSeries$.subscribe((response: any) => {
      this.mediaSerieId = response
    })

    this.libraryService.mediaItem$.subscribe((response: any) => {
      this.mediaItemId = response

    })

    this.libraryService.folderItem$.subscribe((response: any) => {
      this.combined = response;
      this.combined.forEach(data => {
        if (data.squareArtworkId) {
          let id = data.squareArtworkId;
          data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;
        }
        if (data.wideArtworkId) {
          let id = data.wideArtworkId;
          data['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.artworkWideHeight}` + '&width=' + `${appConfig.artworkWideWidth}`;
        }
        if (data.bannerArtworkId) {
          let id = data.bannerArtworkId;
          data['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;
        }
      })

    })


    this.libraryService.sendUpdate$.subscribe((state: any) => {
      this.updateData = state.update
      if (this.updateData) {
        this.getListItemData(this.listItemId)
      }
    })

    this.sharedService.sendStaticPanel$.subscribe((res: any) => {
      this.mainData = res.realData
      this.mappedData = res.mappedData
      console.log(this.videoPath, this.m3u8, `m3u8 2nd`)
      this.mainData.forEach((el) => {
        this.listDesign.mediaItemId = el.id
        if (el?.wideArtwork?.document?.id) {
          var width = 480; var height = 270;
          let id = el.wideArtwork.document.id;
          this.imagePathWide = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          this.wideImageId = el.wideArtworkId;
          this.wideArtworkId = el.wideArtwork.document.id;
        }
        if (el.videoDTO) {
          let size = el.videoDTO?.path?.split(".").length;
          let ext = el.videoDTO?.path?.split(".")[size - 1];
          if (ext === "m3u8") {
            this.m3u8 = true;
            this.currentVedio[0].src = el.videoDTO.path;
            this.hlsjsDriver2.load(this.currentVedio[0].src);
          } else {
            this.m3u8 = false;
            this.videoPath = el.videoDTO.path
          }
        } else if (
          el.videoUrl &&
          el.videoUrl.trim() != ""
        ) {
          var size = el.videoUrl?.split(".").length;
          let ext = el.videoUrl?.split(".")[size - 1];
          if (ext === "m3u8") {
            this.m3u8 = true;
            this.currentVedio[0].src = el.videoUrl;
            this.hlsjsDriver2.load(this.currentVedio[0].src);
          } else {
            this.m3u8 = false;
            this.videoPath = el.videoUrl
          }
        }
      })
      console.log(this.videoPath, this.m3u8, `m3u8 3rd`)
    })

    this.libraryService.sendStaticImage$.subscribe((response: any) => {

      // this.staticImageId = response.document.id
      this.staticImageData = response
      if (this.staticImageData) {
        // if (this.staticImageData['staticImage'].bdImage === true) {
        if (response.artworkType === 'WIDE') {
          this.staticArtworkId = response.id
          this.staticHeaderImgid = response.document.id
          this.staticHeaderColor = response.document.imageColur
          this.withWideArtwork = true
          var width = 480; var height = 270;
          let id = response.document.id
          // this.staticImageName = this.staticImageData['staticImage'].name
          // this.staticImageData['staticImage']['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePathWide = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.staticWideHeaderImg = this.imagePathWide
          // this.staticBannerHeaderImg = ''
          // this.imagePath = ''
        }
        else if (response.artworkType === 'BANNER') {
          this.landscapeArtworkId = response.id
          this.landscapeImageId = response.document.id
          this.staticBannerHeaderColor = response.document.imageColur
          this.withWideArtwork = false
          var width = 300; var height = 100;
          let id = response.document.id
          // this.staticImageName = this.staticImageData['staticImage'].name
          // this.staticImageData['staticImage']['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.imagePath = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          this.staticBannerHeaderImg = this.imagePath
          // this.staticWideHeaderImg = ''
          // this.imagePathWide= ''
        }
      }
    }
    )

  }

  ngOnChanges(): void {
    this.getListItemData(this.listItemId)
  }

  getBrandingData(orgId) {
    this.authService.getBrandingDataWithoutAuth(orgId).subscribe((response: any) => {
      this.theme = response.data.mobileTheme ? response.data.mobileTheme : 'light'

    })
  }

  sendCatalog() {
    this.libraryService.sendCatalogId(this.listItemId)
  }

  openMobilePanel(artworkType) {
    setTimeout(() => {
      // this.libraryService.updatePanel1({showPanel:true})

      this.libraryService.updatePanel1({ showPanel: true, id: this.listItemId, clickedFrom: 'mobileApp' })
      if (this.mobileId) {
        this.libraryService.sendMobile(this.mobileId)
        this.libraryService.sendDesignData(this.listDesign)
        this.libraryService.sendArtworkType(artworkType)
      }
    }, 1000);
  }

  openMobPanel() {

  }

  openTvPanel() {
    this.libraryService.updatePanel3({ showPanel: true })

    if (this.tvId) {
      this.libraryService.sendTv(this.tvId)
    }
  }

  openWebPanel() {
    this.libraryService.updatePanel2({ showPanel: true })

    if (this.webId) {
      this.libraryService.sendWeb(this.webId)
    }

  }

  sendClickedFrom(data) {
    this.libraryService.clickedFrom({ clickedFrom: data })
  }

  openPanel(data) {
    setTimeout(() => {

      if (data === 'list') {
        this.libraryService.updatePanel({ showPanel: true, clickedFrom: data, arrayMobile: this.combined, panel: 'list', tabId: this.listItemId })
      }
      else if (data === 'static') {
        if (this.listDesign.staticType === 'WIDE') {
          this.sharedService.updatePanelState({ showPanel: true, type: 'wide', opacity: true, static: true, clickedFrom: data, artworkId: this.staticArtworkId, id: this.staticHeaderImgid })
        }
        else {
          this.sharedService.updatePanelState({ showPanel: true, type: 'banner', opacity: true, static: true, clickedFrom: data, artworkId: this.landscapeArtworkId, id: this.landscapeImageId })
        }
      }
      else {
        if (this.carousalData.length > 0 && data === 'carousel') {
          this.libraryService.sendBannerCarousel(this.carousalData)
        }
        else if (this.carousalData.length === 0 && data === 'carousel') {
          this.libraryService.sendBannerCarousel(this.carousalData)
        }
      }
    }, 1000);

  }

  updateListItem() {
    this.contentData = [...this.mediaSerieId, ...this.trendingList];
    this.contentData = this.contentData.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }))
    console.log(this.contentData)
    const dataToSend = (JSON.parse(JSON.stringify(this.editListItem)));

    dataToSend.catalogContent = this.contentData
    dataToSend.listDesign = this.listDesign

    if (dataToSend.bannerArtworkId) {
      let banArtwork = this.artworkList['BANNER'].id
      dataToSend.bannerArtworkId = banArtwork
    }
    if (dataToSend.squareArtworkId) {
      let sqArtwork = this.artworkList['SQUARE'].id
      dataToSend.squareArtworkId = sqArtwork
    }
    if (dataToSend.wideArtworkId) {
      let widArtwork = this.artworkList['WIDE'].id
      dataToSend.wideArtworkId = widArtwork
    }

    if ((this.editListItem.squareArtwork === null && this.editListItem.squareArtworkId === null) || (this.editListItem.bannerArtwork === null && this.editListItem.bannerArtworkId === null) || (this.editListItem.wideArtwork === null && this.editListItem.wideArtworkId === null)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select all three types of artworks.' });
      console.log('test validation');

    }
    else {
      this.libraryService.updateListItem(this.listItemId, dataToSend).subscribe((response) => {
        console.log(response)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Folder updated successfully` });
        this.combined = []
        this.mediaSerieId = [];
        this.contentData = []
        this.listDesign
        this.getListItemData(this.listItemId)
        this.createListDesign()
        console.log(this.listDesign);

        // if(this.orderObj.mobile === "true"){
        //   this.router.navigate(['/apps-module/apps/mobileApp/view-tab/' + this.orderObj.redirectId + "/BUILD_YOUR_OWN"])
        // }
        // else if(this.orderObj.library === "true"){
        //   this.router.navigate(['/library-module/library/folder/create-list/' + this.orderObj.redirectId + '/edit'])
        // }else{
        // this.router.navigate(['/library-module/library/folder']);
        // }
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }

  }

  goBack() {
    if (this.orderObj.mobile === "true") {
      this.router.navigate(['/apps-module/apps/mobileApp/view-tab/' + this.orderObj.redirectId + "/BUILD_YOUR_OWN"])
    }
    else if (this.orderObj.library === "true") {
      this.router.navigate(['/library-module/library/folder/create-list/' + this.orderObj.redirectId + '/edit'])
    }
    else if (this.orderObj.musicTab === "true") {
      this.router.navigate(['/apps-module/apps/mobileApp/view-tab/' + this.orderObj.redirectId + "/Music"])
    }
    else if (this.orderObj.tv === "true" || this.orderObj.tvroute === "true") {
      // this.router.navigate(['/apps-module/apps/tvApp'])
      this.location.back()
    }
    else if (this.orderObj.app === "true" && this.orderObj.tab === "mobileApp") {
      this.router.navigate([
        "/apps-module/apps/mobileApp/"
      ]);
    } 
    else {
      this.router.navigate(['/library-module/library/folder']);
    }
  }

  getListItemData(id) {

    this.libraryService.getListItemById(id).subscribe((response: any) => {

      console.log(response)
      console.log(response.data.catalogContents)
      // this.combined = response.data.catalogContents
      this.trendingList = response.data.catalogContents ? response.data.catalogContents : [];
      this.allTrendingList = response.data.catalogContents ? response.data.catalogContents : [];
      this.trendingListLength = response.data.catalogContents ? response.data.catalogContents.length : 0;
      // this.trendingList = this.trendingList.slice(0, 5);
      console.log(this.trendingList);
      this.contentLength = response.data.catalogContents ? response.data.catalogContents.length : 0
      if (this.trendingList) {
        this.trendingList.forEach(data => {
          if (data.squareArtwork) {
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;
            // data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;
          }
          if (data.bannerArtwork) {
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;
            // data['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;
          }
          if (data.wideArtwork) {
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.artworkWideHeight}` + '&width=' + `${appConfig.artworkWideWidth}`;
            // data['newWideimg'] = `${appConfig.imageURL}` + id + '?height=225' + '&width=400';
          }
        })
      }
      if (this.allTrendingList) {
        this.allTrendingList.forEach(data => {
          if (data.squareArtwork) {
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;

          }
          if (data.bannerArtwork) {
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;

          }
          if (data.wideArtwork) {
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.artworkWideHeight}` + '&width=' + `${appConfig.artworkWideWidth}`;

          }
        })
      }

      this.trendingList = this.trendingList.filter((el, i) => {
        if (el.status === "PUBLISHED" || el.status === "SCHEDULED") {
          return el
        }
      })

      if (response.data.updatedDate) {
        if (this.timeZone) {
          var cutoffString = response.data.updatedDate; // in utc
          var utcCutoff = moment.utc(cutoffString, 'YYYYMMDD HH:mm:ss');
          var displayCutoff = utcCutoff.clone().tz(this.timeZone);
          let formatDate = moment(displayCutoff).format('MMM DD YYYY, h:mm a');
          this.updatedDate = formatDate
        }
      }


      this.libraryService.sendCatalogId(response.data.id)

      this.listDesignData = response.data.listDesign
      // if(!response.data.listDesign){
      //   this.offActivate('OFF')
      //   this.gridActivate('GRID')
      // }
      this.hideDeleteIcon()
      if (this.listDesignData) {
        this.listDesignData.forEach((el) => {
          if (el.designType === 'MOBILE_APP') {

            this.mobileHeaderType = el.headerType
            this.headerType = this.mobileHeaderType
            this.mobileItemLayout = el.itemLayout
            this.itemLayout = el.itemLayout
            this.itemImages = el.itemImages
            this.listDesign.shadow = el.shadow
            this.showShadow = el.shadow
            this.checked = el.shadow
            this.listDesign.marginType = el.margins
            this.checkMargins = el.margins
            this.marginType = el.marginType ? el.marginType : '';
            this.staticHeaderColor = el.staticImageColor
            this.staticBannerHeaderColor = el.landscapeImageColor
            this.listDesignId = el.id
            this.carousalData = el.carouselContentsList
            this.listDesign.carouselContentsList = el.carouselContentsList
            this.staticType = el.staticType;
            this.carousalType = el.carouselType;
            this.marginEdges = el.marginEdges;
            this.itemDisplay = el.itemDisplay;
            this.itemTitle = el.itemTitles;

            this.mobileId = el.id

            this.staticArtworkId = el.staticArtworkId
            this.landscapeArtworkId = el.landscapeArtworkId
            this.staticHeaderImgid = el.staticImageId;
            this.landscapeImageId = el.landscapeImageId
            if (this.staticHeaderImgid) {
              // this.staticHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=270&width=480';
              this.staticWideHeaderImg = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=270&width=480';
            }
            if (this.landscapeImageId) {
              // this.staticHeaderImg = `${appConfig.imageURL}` + this.landscapeImageId + '?height=100&width=300';
              this.staticBannerHeaderImg = `${appConfig.imageURL}` + this.landscapeImageId + '?height=150&width=450';
            }

            if (this.staticHeaderImgid) {
              // if (this.staticImageData['staticImage'].bdImage === true) {
              // if(this.staticType === 'WIDE'){
              // this.withWideArtwork = true
              var width = 480; var height = 270;
              let id = this.staticHeaderImgid
              // this.staticImageName = this.staticImageData['staticImage'].name
              // this.staticImageData['staticImage']['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
              this.imagePathWide = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
              // this.imagePath = ''
              // }
              // var width = 150; var height = 150;
              // let id = this.staticImageData['staticImage'].id
              // this.staticImageName = this.staticImageData['staticImage'].name
              // this.staticImageData['staticImage']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
            }
            else {
              this.imagePathWide = ''
              // this.imagePath = ''
            }

            if (this.landscapeImageId) {
              // if(this.staticType === 'BANNER'){
              // this.withWideArtwork = false
              var width = 300; var height = 100;
              let id = this.landscapeImageId
              // this.staticImageName = this.staticImageData['staticImage'].name
              // this.staticImageData['staticImage']['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
              this.imagePath = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
              // this.imagePathWide = ''
              // }
            }
            else {
              // this.imagePathWide = ''
              this.imagePath = ''
            }

            if (this.staticType === 'WIDE') {
              this.withWideArtwork = true
            }
            else {
              this.withWideArtwork = false
            }

            // this.mobileHeaderType = el.headerType
            // this.mobileItemLayout = el.itemLayout

            if (this.mobileHeaderType === 'OFF') {
              this.offActive = true
              this.staticActive = false
              this.carouselActive = false
            }
            if (this.mobileHeaderType === 'STATIC') {
              this.offActive = false
              this.staticActive = true
              this.carouselActive = false
            }
            if (this.mobileHeaderType === 'CAROUSEL') {
              this.offActive = false
              this.staticActive = false
              this.carouselActive = true
            }
            if (this.mobileItemLayout === 'ROWS') {
              this.rowActive = true
              this.gridActive = false
              this.stackActive = false
            }
            if (this.mobileItemLayout === 'GRID') {
              this.gridActive = true
              this.rowActive = false
              this.stackActive = false
            }
            if (this.mobileItemLayout === 'STACKED') {
              this.stackActive = true
              this.rowActive = false
              this.gridActive = false
            }
            this.listDesignId = el.id
            // this.carousalData = el.carouselContentsList
            if (this.carousalType === 'WIDE') {
              this.showBannerCarousal = false
            }
            else if (this.carousalType === 'BANNER') {
              this.showBannerCarousal = true;
            }
            this.products = this.carousalData.map((el) => ({
              id: el,
              wideImgId: el.wideArtwork ? el.wideArtwork.document.id : '',
              wideImg: '',
              wideImgColor: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
              bannerImgId: el.bannerArtwork ? el.bannerArtwork.document.id : '',
              bannerImgColor: el.bannerArtwork ? el.bannerArtwork.document.imageColur : '',
              bannerImg: ''
            }))


            this.products.forEach((data, i) => {
              if (data.bannerImgId) {
                var width = 600; var height = 200;
                let id = data.bannerImgId;
                data.bannerImg = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              }
              if (data.wideImgId) {
                var width = 480; var height = 270;
                let id = data.wideImgId;
                data.wideImg = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
              }
            })
            if (this.products.length == 1) {
              this.carousalRotate = false
            }
            else {
              this.carousalRotate = true
            }

            this.showImage = true;
            this.showDate = false;
            this.showTitle = true;
            if (this.itemDisplay === 'DATE' && this.itemLayout === 'ROWS') {
              this.showDate = true;
              this.showImage = false;
              this.itemImages = "SQUARE"
            }
            else if (this.itemDisplay === 'IMAGE' && this.itemLayout === 'ROWS') {
              this.showImage = true;
              this.showDate = false;
            }
            else if (this.itemDisplay === 'TITLE_ONLY' && this.itemLayout === 'ROWS') {
              this.showDate = false;
              this.showImage = false;
            }
            if (this.itemTitle === "BELOW") {
              this.showTitle = true;
              this.overlayText = false;
            }
            else if (this.itemTitle === "OFF") {
              this.showTitle = false;
              this.overlayText = false;
            }
            else if (this.itemTitle === "OVERLAY") {
              this.showTitle = true;
              this.overlayText = true;
            }

            if (this.itemImages === "SQUARE") {
              this.showSquare = true
              this.showWide = false
              this.showBanner = false
            }
            else if (this.itemImages === "WIDE") {
              this.showSquare = false
              this.showWide = true
              this.showBanner = false
            }
            else if (this.itemImages === "BANNER") {
              this.showBanner = true
              this.showSquare = false
              this.showWide = false
            }
            if (this.mobileHeaderType === 'OFF') {
              this.offActive = true
              this.staticActive = false
              this.carouselActive = false
            }
            if (this.mobileHeaderType === 'STATIC') {
              this.offActive = false
              this.staticActive = true
              this.carouselActive = false
            }
            if (this.mobileHeaderType === 'CAROUSEL') {
              this.offActive = false
              this.staticActive = false
              this.carouselActive = true
            }
            if (this.mobileItemLayout === 'ROWS') {
              this.rowActive = true
              this.gridActive = false
              this.stackActive = false
            }
            if (this.mobileItemLayout === 'GRID') {
              this.gridActive = true
              this.rowActive = false
              this.stackActive = false
            }
            if (this.mobileItemLayout === 'STACKED') {
              this.stackActive = true
              this.rowActive = false
              this.gridActive = false
            }
            this.listDesign = new MobileListDesign(el)
            this.catalogID = el.catalogId

            this.m3u8 = false;
            this.videoPath = this.videoPathClone

            if (this.listDesign.staticType === 'VIDEO') {
              this.listDesign.staticType = 'WIDE'
              this.image = false
              this.video = true
              this.staticArtworkId = null
              if (
                this.listDesign.videoUrl &&
                this.listDesign.videoUrl.trim() != ""
              ) {
                var size = this.listDesign.videoUrl.split(".").length;
                let ext = this.listDesign.videoUrl.split(".")[size - 1];
                if (ext === "m3u8") {
                  this.m3u8 = true;
                  this.currentVedio[0].src = this.listDesign.videoUrl;
                  this.hlsjsDriver2.load(this.currentVedio[0].src);
                } else {
                  this.m3u8 = false;
                  this.videoPath = this.listDesign.videoUrl
                }
              } else {
                this.m3u8 = false;
                this.videoPath = this.videoPathClone
              }
            } else if (this.listDesign.staticType === 'WIDE') {
              this.image = true
              this.video = false
              if (
                this.listDesign.videoUrl &&
                this.listDesign.videoUrl.trim() != ""
              ) {
                var size = this.listDesign.videoUrl.split(".").length;
                let ext = this.listDesign.videoUrl.split(".")[size - 1];
                if (ext === "m3u8") {
                  this.m3u8 = true;
                  this.currentVedio[0].src = this.listDesign.videoUrl;
                  this.hlsjsDriver2.load(this.currentVedio[0].src);
                } else {
                  this.m3u8 = false;
                  this.videoPath = this.listDesign.videoUrl
                }
              } else {
                this.m3u8 = false;
                this.videoPath = this.videoPathClone
              }
            }

            if (el.itemImages) {
              this.itemImages = el.itemImages
            }
            if (el.itemDisplay) {
              this.itemDisplay = el.itemDisplay
            }
            if (el.itemTitles) {
              this.itemTitles = el.itemTitles
            }
            if (el.margins) {
              this.margin = el.margins
            }
            if (el.shadow) {
              this.shadow = el.shadow
            }
            // this.libraryService.sendMobile(data)
          }
          else if (el.designType === "WEB") {
            this.webId = el.id
            if (el.itemLayout === 'ROWS') {
              this.webHeaderType = el.itemLayout

            } else {
              this.webHeaderType = el.itemLayout
            }
            // this.libraryService.sendWeb(data)
          }
          else if (el.designType === "TV") {
            this.tvId = el.id
            if (el.headerType === 'OFF') {
              this.tvHeaderType = el.headerType
            } else {
              this.tvHeaderType = el.headerType
            }
            // this.libraryService.sendTv(data)
          }
          else {
            let data = el.id
          }

        }
        )
      }


      // this.trendingListSpliceData = this.trendingList.splice(0, 4)
      this.editListItem = new EditListItem(response.data);
      if (this.editListItem.squareArtworkId) {
        // if(this.editListItem.squareArtworkId['document'].bdImage === true){
        let id = this.editListItem.squareArtworkId['document'].id;
        this.editListItem.squareArtworkId['document']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;
        // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
        //   this.sharedService.updateLoader(false);
        //   this.editListItem.squareArtworkId['document']['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
        // })
        // }
      }
      if (this.editListItem.bannerArtworkId) {
        // if(this.editListItem.bannerArtworkId['document'].bdImage === true){
        let id = this.editListItem.bannerArtworkId['document'].id;
        this.editListItem.bannerArtworkId['document']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;
        // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
        //   this.sharedService.updateLoader(false);
        //   this.editListItem.bannerArtworkId['document']['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
        // })
        // }
      }
      if (this.editListItem.wideArtworkId) {
        // if(this.editListItem.wideArtworkId['document'].bdImage === true){
        let id = this.editListItem.wideArtworkId['document'].id;
        this.editListItem.wideArtworkId['document']['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.artworkWideHeight}` + '&width=' + `${appConfig.artworkWideWidth}`;
        // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
        //   this.sharedService.updateLoader(false);
        //   this.editListItem.wideArtworkId['document']['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
        // })
        // }
      }
      if (this.editListItem.squareArtworkId) {
        this.artworkList['SQUARE'] = this.editListItem.squareArtworkId
      }
      if (this.editListItem.bannerArtworkId) {
        this.artworkList['BANNER'] = this.editListItem.bannerArtworkId;
      }
      if (this.editListItem.wideArtworkId) {
        this.artworkList['WIDE'] = this.editListItem.wideArtworkId;
      }
      this.listDataArray = []
      this.listDataArray.push(response.data)
    })

  }

  filterSmartList() {
    let id = this.listItemId
    this.libraryService.filterSmartList(id, this.displayBy, this.noOfItem, this.sortBy).subscribe((response: any) => {
      this.trendingList = response.data.catalogContents
      this.trendingList.forEach(data => {
        if (data.squareArtwork) {
          let id = data.squareArtwork.document.id;
          data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;

        }
        if (data.bannerArtwork) {
          let id = data.bannerArtwork.document.id;
          data.bannerArtwork.document['newBannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;

        }
        if (data.wideArtwork) {
          let id = data.wideArtwork.document.id;
          data.wideArtwork.document['newWideimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.wideHeight}` + '&width=' + `${appConfig.wideWidth}`;

        }
      })
      this.trendingList = this.trendingList.filter((el, i) => {
        if (el.status === "PUBLISHED" || el.status === "SCHEDULED") {
          return el
        }
      })
    })
  }


  selectItems(event, item) {
    if (item === 'all') {
      this.selectAll = event.target.checked;
      this.trendingList.forEach((el) => el.itemChecked = event.target.checked);
      this.selectedItems = event.target.checked ? this.trendingList : [];
      console.log(this.selectItems)
    } else {
      this.trendingList.forEach((el) => {
        if (el.id === item.contentId) {
          el.itemChecked = event.target.checked;
        }
      });
      this.selectedItems = this.trendingList.filter((el) => el.itemChecked);
      console.log(this.selectedItems)
      this.deleteData = this.selectedItems.map((el) => ({
        contentId: el.contentId,
        type: el.type
      }))
      console.log(this.deleteData)
      this.selectAll = (this.selectedItems.length === this.trendingList.length);
    }
    if (event.target.checked) {
      this.viewAllItems = true
    }
    else {
      this.viewAllItems = false
    }
  }

  viewAll() {
    this.trendingList = this.allTrendingList
    this.viewAllItems = true
    this.trendingList = this.trendingList.filter((el, i) => {
      if (el.status === "PUBLISHED" || el.status === "SCHEDULED") {
        return el
      }
    })
  }

  removeCatalogItems() {

    this.libraryService.removeCatalogContent(this.deleteData).subscribe((response: any) => {
      this.getListItemData(this.listItemId)
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item removed successfully` });
      this.viewAllItems = false
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  removeCatalogData(item) {

    let newData = [
      {
        contentId: item.contentId,
        type: item.type
      }
    ]

    this.libraryService.removeCatalogContent(newData).subscribe((response: any) => {
      this.getListItemData(this.listItemId)
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item removed successfully` });
      this.viewAllItems = false
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }



  removeUnnecessaryKeys(data) {
    const objKeys = Object.keys(data);
    // for (const key of objKeys) {
    //    if (!data[key]  || key === 'catalogContent' ) {
    //      delete data[key];
    //   }
    // }
    return data;
  }

  getArtwork(data) {
    this.artworkList[`${data.artworkType}`] = data;
    if (this.artworkList) {
      this.editListItem.squareArtworkId = this.artworkList.SQUARE ? this.artworkList.SQUARE.id : null;
      this.editListItem.wideArtworkId = this.artworkList.WIDE ? this.artworkList.WIDE.id : null;
      this.editListItem.bannerArtworkId = this.artworkList.BANNER ? this.artworkList.BANNER.id : null;
    }
  }


  goToView(item) {
    if (item.type === "MEDIASERIES" && item.contentType === "MEDIA_SERIES") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/media-module/media/on-demand/edit-media-series/' + item.id], { queryParams: { library: false, mobile: true, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/media-module/media/on-demand/edit-media-series/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/media-module/media/on-demand/edit-media-series/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      // this.router.navigateByUrl("/media-module/media/on-demand/edit-media-series/" + item.id)
    }
    if (item.type === "MEDIASERIES" && item.contentType === "EBOOK_SERIES") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/media-module/media/ebook/edit-collection/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/media-module/media/ebook/edit-collection/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/media-module/media/ebook/edit-collection/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      // this.router.navigateByUrl("/media-module/media/on-demand/edit-media-series/" + item.id)
    }
    else if (item.type === "MEDIA_ITEM") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/media-module/media/on-demand/edit-media-item/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/media-module/media/on-demand/edit-media-item/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/media-module/media/on-demand/edit-media-item/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
      }
      // this.router.navigateByUrl("/media-module/media/on-demand/edit-media-item/" + item.id)
    }
    else if (item.type === "ALBUM") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/media-module/media/music/createAlbum/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/media-module/media/music/createAlbum/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else if (this.orderObj.musicTab === "true") {
        this.router.navigate(['/media-module/media/music/createAlbum/' + item.id], { queryParams: { library: true, music: true, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else {
        if (this.routeFrom === 'music') {
          this.router.navigate(['/media-module/media/music/createAlbum/' + item.id], { queryParams: { library: true, music: true, 'redirectId': this.listItemId } });
        }
        else {
          this.router.navigate(['/media-module/media/music/createAlbum/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
        }
      }
      // this.router.navigateByUrl("/media-module/media/music/createAlbum/" + item.id)
    }
    else if (item.type === "RSSFEED") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/library-module/library/rss-feeds/create-rss-item/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/library-module/library/rss-feeds/create-rss-item/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/library-module/library/rss-feeds/create-rss-item/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
      }
      // this.router.navigateByUrl("/library-module/library/rss-feeds/create-rss-item/" + item.id)
    }
    else if (item.type === "LINK") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/library-module/library/links/create-link/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/library-module/library/links/create-link/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/library-module/library/links/create-link/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
      }
      // this.router.navigateByUrl("/library-module/library/links/create-link/" + item.id)
    }
    else if (item.type === "LIST") {
      this.router.navigate(['/library-module/library/folder/create-list/' + item.id + "/edit"], { queryParams: { library: true, 'redirectId': this.listItemId } });
      // this.router.navigateByUrl("/library-module/library/folder/create-list/" + item.id + "/contentView")
    }
    else if (item.type === "MUSIC") {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/media-module/media/music/createSong/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/media-module/media/music/createSong/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else if (this.orderObj.musicTab === "true") {
        this.router.navigate(['/media-module/media/music/createSong/' + item.id], { queryParams: { library: true, music: true, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else {
        if (this.routeFrom === 'music') {
          this.router.navigate(['/media-module/media/music/createSong/' + item.id], { queryParams: { library: true, music: true, 'redirectId': this.listItemId } });
        }
        else {
          this.router.navigate(['/media-module/media/music/createSong/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
        }
      }
      // this.router.navigateByUrl("/library-module/library/folder/create-list/" + item.id + "/contentView")
    }
    else if (item.type === 'EVENT') {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/events-module/events/edit-event/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/events-module/events/edit-event/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/events-module/events/edit-event/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
      }
    }
    else if (item.type === 'CALENDAR') {
      if (this.orderObj.mobile === "true") {
        this.router.navigate(['/events-module/events/edit-calender/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, mId: this.orderObj.redirectId } });
      }
      else if (this.orderObj.tvroute === "true") {
        this.router.navigate(['/events-module/events/edit-calender/' + item.id], { queryParams: { library: false, 'redirectId': this.listItemId, tvroute: true } });
      }
      else {
        this.router.navigate(['/events-module/events/edit-calender/' + item.id], { queryParams: { library: true, 'redirectId': this.listItemId } });
      }
    }

  }

  // mobile app list component methods

  staticActivate(STATIC) {
    this.staticActive = true
    this.carouselActive = false
    this.offActive = false
    this.headerType = 'STATIC'
    this.listDesign.headerType = 'STATIC'
    if (this.staticActive && this.stackActive && (this.infoCount === 0)) {
      this.infoMsg = true
      this.infoCount = this.infoCount + 1
    }

  }

  offActivate(OFF) {
    this.offActive = true;
    this.carouselActive = false;
    this.staticActive = false
    this.headerType = 'OFF'
    this.listDesign.headerType = 'OFF'
    this.infoMsg = false
  }

  carouselActivate(CAROUSEL) {
    this.carouselActive = true;
    this.offActive = false;
    this.staticActive = false
    this.headerType = 'CAROUSEL'
    this.listDesign.headerType = 'CAROUSEL'
    this.itemLayout = this.itemLayout ? this.itemLayout : 'ROWS'
    this.listDesign.itemTitles = this.listDesign.itemTitles ? this.listDesign.itemTitles : 'BELOW'
    this.listDesign.itemDisplay = this.listDesign.itemDisplay ? this.listDesign.itemDisplay : 'IMAGE'
    this.listDesign.itemImages = this.listDesign.itemImages ? this.listDesign.itemImages : 'SQUARE'
    this.itemImages = this.listDesign.itemImages
    this.listDesign.carouselContent = this.carousalData
    // this.listDesign.margins = false
    // this.listDesign.shadow = false
    // this.listDesign.marginEdges = null
    // this.listDesign.marginType = null
    // this.showWide = false;
    // this.showSquare = true

    if (this.rowActive && this.carouselActive) {
      this.listDesign.marginEdges = 'SHARP'
    }

  }

  getListDesignChange(data) {


    if (data === 'SQUARE') {
      this.showSquare = true
      this.showWide = false
      this.showBanner = false
      this.itemImages = 'SQUARE'
    }
    else if (data === 'WIDE') {
      this.showSquare = false
      this.showWide = true
      this.showBanner = false
      this.itemImages = 'WIDE'
    }
    else if (data === "BANNER") {
      this.showSquare = false
      this.showWide = false
      this.showBanner = true
      this.itemImages = 'BANNER'
    }
    if (data === true) {
      this.showShadow = true
      this.listDesign.margins = true
      this.listDesign.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THICK'
      this.marginType = 'THICK'
    }
    else if (data === false) {
      this.showShadow = false
      this.listDesign.margins = false
      this.marginType = ''
      this.listDesign.marginType = ''
    }

    if (data === 'VERYTHIN') {
      this.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'VERYTHIN'
    }
    else if (data === 'THIN') {
      this.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THIN'
    }
    else if (data === 'THICK') {
      this.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THICK'
    }

    if (data === 'CURVE') {
      this.marginEdges = this.listDesign.marginEdges ? this.listDesign.marginEdges : 'CURVE'
    }
    else if (data === 'SHARP') {
      this.marginEdges = this.listDesign.marginEdges ? this.listDesign.marginEdges : 'SHARP'
    }
    if (data === 'DATE' && this.itemLayout === 'ROWS') {
      this.showDate = true;
      this.showImage = false;
      this.itemImages = "SQUARE"
    }
    else if (data === 'IMAGE' && this.itemLayout === 'ROWS') {
      this.showImage = true;
      this.showDate = false;
      if (this.showSquare) {
        this.itemImages = "SQUARE"
        this.showSquare = true
        this.showWide = false
      }
      else if (this.showWide) {
        this.itemImages = "WIDE"
        this.showSquare = false
        this.showWide = true
      }
    }
    else if (data === 'TITLE_ONLY' && this.itemLayout === 'ROWS') {
      this.showDate = false;
      this.showImage = false;
    }
    if (data === "BELOW") {
      this.showTitle = true;
      this.overlayText = false;
      this.itemTitle = "BELOW"
    }
    else if (data === "OFF") {
      this.showTitle = false;
      this.overlayText = false;
      this.itemTitle = "OFF"
    }
    else if (data === "OVERLAY") {
      this.showTitle = true;
      this.overlayText = true;
      this.itemTitle = "OVERLAY"
    }
  }

  rowActivate(ROWS) {
    this.rowActive = true;
    this.gridActive = false;
    this.stackActive = false;
    this.itemLayout = 'ROWS'
    this.listDesign.itemLayout = 'ROWS'
    this.listDesign.itemTitles = 'BELOW'
    this.listDesign.itemDisplay = 'IMAGE'
    this.listDesign.itemImages = 'SQUARE'
    this.itemImages = this.listDesign.itemImages
    // this.listDesign.margins = false
    // this.listDesign.shadow = false
    // this.listDesign.shadow = false
    // this.showShadow = false
    // this.checked = false
    this.showDate = false;
    this.showImage = true;
    // this.listDesign.marginEdges = null
    // this.listDesign.marginType = null
    this.showWide = false;
    this.showBanner = false
    this.showSquare = true
    this.showTitle = true
    this.overlayText = false
    if (this.rowActive && this.carouselActive) {
      this.listDesign.marginEdges = 'SHARP'
    }

  }

  gridActivate(GRID) {
    this.hideDeleteIcon()
    this.gridActive = true;
    this.rowActive = false;
    this.stackActive = false;
    this.overlayText = false
    this.itemLayout = 'GRID'
    this.listDesign.itemLayout = 'GRID'
    this.listDesign.itemTitles = this.listDesign.itemTitles ? this.listDesign.itemTitles : 'BELOW'
    if (this.listDesign.itemTitles === 'OVERLAY') {
      this.listDesign.itemTitles = 'BELOW'
      this.itemTitle = 'BELOW'
    }
    this.showTitle = this.listDesign.itemTitles === 'BELOW' ? true : false
    this.listDesign.itemImages = 'SQUARE'
    this.itemImages = this.listDesign.itemImages
    this.listDesign.margins = this.listDesign.margins ? this.listDesign.margins : false
    this.listDesign.shadow = false
    this.showShadow = false
    this.checked = false
    this.showDate = false;
    this.showImage = true;
    this.listDesign.shadow = false
    this.listDesign.marginEdges = this.listDesign.marginEdges === 'SHARP' ? this.marginEdges = 'SHARP' : this.marginEdges = 'CURVE'
    if (this.listDesign.margins) {
      this.listDesign.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THICK'
      this.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THICK'
    }
    this.listDesign.itemDisplay = null
    this.showWide = false;
    this.showBanner = false;
    this.showSquare = true
  }

  stackActivate(STACKED) {
    this.hideDeleteIcon()
    this.stackActive = true;
    this.rowActive = false;
    this.gridActive = false
    this.itemLayout = 'STACKED'
    this.listDesign.itemLayout = 'STACKED'
    this.listDesign.itemTitles = this.listDesign.itemTitles ? this.listDesign.itemTitles : 'BELOW'
    this.showTitle = this.listDesign.itemTitles === 'BELOW' ? true : false
    this.listDesign.itemImages = 'WIDE'
    this.itemImages = this.listDesign.itemImages
    this.showWide = true;
    this.showBanner = false
    this.showSquare = false
    this.listDesign.shadow = false
    this.listDesign.margins = this.listDesign.margins ? this.listDesign.margins : false
    this.showShadow = false
    this.checked = false
    this.showDate = false;
    this.showImage = true;
    this.listDesign.marginEdges = this.listDesign.marginEdges === 'SHARP' ? this.marginEdges = 'SHARP' : this.marginEdges = 'CURVE'
    if (this.listDesign.margins) {
      this.listDesign.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THICK'
      this.marginType = this.listDesign.marginType ? this.listDesign.marginType : 'THICK'
    }
    this.listDesign.itemDisplay = null


    if (this.staticActive && this.stackActive && (this.infoCount === 0)) {
      this.infoMsg = true
      this.infoCount = this.infoCount + 1
    }

  }

  openAddlistPannel() {
    this.mainTab = false;
    this.subTab = true;
  }

  marginSlider(event) {
    if (!event.target.checked) {
      this.listDesign.shadow = false
    }
  }

  back() {
    this.mainTab = true
    this.subTab = false
  }

  openCarouselPanel(mobile) {
    if (this.dataList.length > 0) {
      this.libraryService.sendDataList(this.dataList.length)
      this.libraryService.sendSaveCarouselData(this.dataList)
    }
    else {
      this.libraryService.sendDataList(this.combined.length)
      this.libraryService.sendSaveCarouselData(this.combined)
    }
    this.libraryService.updatePanel({ showPanel: true, opacity: true, clickedFrom: mobile })
    this.libraryService.sendCarouselMobileLengthData(this.mediaSerieId.length)
  }

  deleteItem(item) {

    let data = this.combined[item].id
    let arrData = this.combined[item]
    this.mediaSerieId.forEach((el) => {
      if (el.id === data) {

        var i = this.mediaSerieId.indexOf(el)

        this.mediaSerieId.splice(i, 1)
        // el.itemChecked = false
        let arrData = []
        arrData.push(el)

        this.libraryService.sendRemovedDataFromMobile(arrData)

      }
    })
    let arr = []
    arr.push(arrData)
    this.combined.splice(item, 1)
    this.libraryService.sendRemovedDataFromMobile(arr)
  }

  delete(item, it) {

    this.mediaSerieId.forEach((el, i) => {
      if (el.id === it.id) {
        this.mediaSerieId.splice(i, 1)

      }
    })
    this.dataList.splice(item, 1)
    this.updatedArray = (this.dataList.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }
    )))
  }

  closeInfoMsg() {
    this.infoMsg = false
  }

  createListDesign() {

    const data = (JSON.parse(JSON.stringify(this.listDesign)))
    if (this.eventPanel) {
      data.eventId = this.catalogID
    }
    if (this.calenderPanel) {
      data.calendarId = this.catalogID
    }
    if (this.eventPanel || this.calenderPanel) {
      data.itemTitles = null
    }
    if (!this.eventPanel && !this.calenderPanel) {
      data.catalogId = this.catalogID
    }

    if (this.dataList) {
      this.newDataArray = this.dataList.map((el) => ({
        id: el.id,
        sortOrder: 1,
        type: el.type
      }))
      this.mediaSerieId = [...this.mediaSerieId, ...this.newDataArray]
    }
    this.mediaSerieId.forEach((element) => {
      var medId = element.id
      this.newDataArray.forEach((el) => {
        if (medId !== el.id) {
          this.mediaSerieId = [...this.mediaSerieId, ...this.newDataArray]
          this.updatedArray = [element, el]
        }
      })
    })

    this.mediaSerieId = this.mediaSerieId.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }))

    if (data.itemLayout === "ROWS") {
      data.margins = false
    }

    if (this.carousalData) {
      this.sendDataArray = [...this.carousalData.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    }

    if (!this.listDesign.margins) {
      data.marginEdges = null
      data.marginType = null
    }
    data.headerType = this.headerType
    // if(data.headerType === "STATIC"){
    //   if(this.staticImageData ? this.staticImageData['document'].newWideimg : this.staticImageData ? this.staticImageData['staticImage'].newWideimg : null && data.staticType === 'WIDE'){
    //     data.staticImageId = this.staticImageId
    //     data.staticArtworkId = this.staticArtworkId
    //   }
    //   else if(this.staticImageData ? this.staticImageData['document'].newBannerimg : this.staticImageData ? this.staticImageData['staticImage'].newBannerimg : null && data.staticType === 'BANNER'){
    //     data.staticImageId = this.staticImageId
    //     data.staticArtworkId = this.staticArtworkId
    //   }
    //   else{
    //     data.staticImageId = null
    //     data.staticArtworkId = null
    //   }
    // }

    data.designType = "MOBILE_APP"

    data.itemLayout = this.itemLayout
    data.staticImageId = this.staticHeaderImgid
    data.staticArtworkId = this.staticArtworkId
    data.landscapeArtworkId = this.landscapeArtworkId
    // data.carouselContentIds = this.carousalData
    var carousalDataToSend = []
    if (this.carousalData) {
      carousalDataToSend = this.carousalData.map((el, i) => ({
        id: el.id,
        sortOrder: i,
        type: el.type
      }))
    }
    data.carouselContent = carousalDataToSend
    data.shadow = this.showShadow

    if (this.listDesignId) {
      this.libraryService.updateListDesign(this.listDesignId, data).subscribe((response: any) => {

        // this.router.navigate([`/library-module/library/folder/create-list/`+this.id]);
        this.combined = []
        this.mediaSerieId = []


        this.libraryService.sendUpdateToCreate({ update: true })

        // this.closePanel()

        // this.getListDesign(this.tabId)

        // this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item updated successfully` });
      }, (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      }
      )
    }
    else {
      // this.libraryService.createListDesign(data).subscribe((response) => {

      //   this.listDesignId = response.body.data.id
      //   this.combined = []
      //   this.mediaSerieId = []

      //   this.libraryService.sendUpdateToCreate({ update: true })

      //   // this.closePanel()

      //   // this.getListDesign(this.tabId)

      //   // this.getListItemData(this.id)



      //   // this.messageService.add({ severity: 'success', summary: 'Success', detail: `Item created successfully` });
      // }, (error) => {
      //   // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      // }
      // )
    }
  }


  changeCarouselType(type) {
    if (type === 'WIDE') {
      this.showBannerCarousal = false
    }
    else {
      this.showBannerCarousal = true
    }
  }

  changeStaticType(type) {
    if (type === 'WIDE') {
      this.withWideArtwork = true
      this.imagePathWide = this.imagePathWide ? this.imagePathWide : null
      // this.openPanel('static')
    }
    else {
      this.withWideArtwork = false
      this.imagePath = this.imagePath ? this.imagePath : null
      // this.openPanel('static')
    }
  }

  handleShadow(e) {
    this.listDesign.shadow = e.checked
    this.showShadow = e.checked
  }

  handleMargin(e) {
    this.listDesign.margins = e.checked
    if (!e.checked) {
      this.listDesign.shadow = false
      this.showShadow = false
      this.checked = false
      this.listDesign.marginEdges = 'SHARP'
      this.marginEdges = 'SHARP'
    }
    this.marginType = e.checked === true ? 'THICK' : ''
    this.listDesign.marginType = e.checked === true ? 'THICK' : ''
  }

  deleteCatalogData(item) {
    this.combined.forEach((data, i) => {
      if (data.id === item.id && data.type === item.type) {
        this.combined.splice(i, 1)
      }
    })
    this.mediaSerieId.forEach((data, i) => {
      if (data.id === item.id && data.type === item.type) {
        this.mediaSerieId.splice(i, 1)
      }
    })
  }


  attachNotification() {

    this.listDataArray = this.listDataArray.map((el) => ({
      bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : null,
      squareArtworkColor: el.squareArtwork ? el.squareArtwork.document.imageColur : '#e6e6e6',
      id: el.id,
      squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : null,
      subtitle: el.subTitle,
      title: el.title,
      type: 'LIST',
      wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : null,
    }))

    localStorage.setItem('combined', JSON.stringify(this.listDataArray));

    this.router.navigate(['/apps-module/apps/pushNotification/createNotification/0.1'], { queryParams: { folderItem: true, 'redirectId': this.listItemId } });
    this.libraryService.sendFolderItem(this.listDataArray)


  }


  selectImageOrVideo() {
    if (this.image) {
      this.video = false
      var width = 480; var height = 270;
      if (this.staticHeaderImgid) {
        this.imagePathWide = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=' + height + '&width=' + width;
      }
    } else {
      console.log(this.videoPath, this.m3u8, `m3u8`)
      this.video = true
      if (this.staticHeaderImgid || this.wideArtworkId) {
        var width = 480; var height = 270;
        this.imagePathWide = `${appConfig.imageURL}` + `${this.wideArtworkId ? this.wideArtworkId : this.staticHeaderImgid}` + '?height=' + height + '&width=' + width;
      }
    }
    this.staticModal = false
    // this.video = true
    this.image ? this.openPanel('static') : this.sharedService.openStaticPanelHeader({ panel: true, mediaItemId: this.listDesign.mediaItemId ? this.listDesign.mediaItemId : null })
    this.mainData = []
    this.mappedData = []
  }

  selectImage(data) {
    if (data === 'image') {
      this.image = true
      // var width = 480; var height = 270;
      // this.imagePathWide = `${appConfig.imageURL}` + this.staticHeaderImgid + '?height=' + height + '&width=' + width;
    } else {
      this.image = false
      // this.imagePathWide = `${appConfig.imageURL}` + this.wideArtworkId ? this.wideArtworkId :  this.staticHeaderImgid + '?height=' + height + '&width=' + width;
    }
  }

  openStaticModal() {
    this.image = this.video ? false : true
    this.staticModal = true
  }

  liveVideoPlayed(event: Plyr.PlyrEvent) {
    // // setTimeout(() => {
    //   this.currentVedio[0].src = this.mainData ? this.mainData[0].videoUrl : '' 
    //   this.hlsjsDriver2.load(this.currentVedio[0].src);
    // // }, 1000 )
    // // this.hlsjsDriver2.updateSource();
  }

  completeLiveVideo(event: Plyr.PlyrEvent) {
    // this.hlsjsDriver2.destroy()
    // this.currentVedio[0].src = ''
    // this.hlsjsDriver2.load(this.currentVedio[0].src);
  }

  videoPlayed(event: Plyr.PlyrEvent) {
    console.log('currntTime', this.players.duration);
    // this.isVideoPlayed = this.isVideoPlayed + 1
    // if (this.isVideoPlayed === 1 && this.mediaItemId) {

    //   const data = JSON.parse(JSON.stringify(this.mediaCount))

    //   data.deviceType = 'WEB'
    //   data.mediaId = this.editMedia.videoDTO ? this.editMedia.videoDTO.id : null;
    //   data.mediaItemId = this.mediaItemId
    //   data.mediaType = 'VIDEO'

    // }

  }


  completeVideo(event: Plyr.PlyrEvent) {
    // this.isVideoPlayed = 0
  }

  hideDeleteIcon() {
    this.showDeleteIcon = false
    setTimeout(() => {
      this.showDeleteIcon = true
    }, 500);
  }

}


