<div class="switch-bar" *ngIf="switchView">
    <p>You are switched in as {{userName}} ({{orgId}}). <a (click)="switchBack()">Switch Back</a></p>
</div>
<div class="app-main d-flex w-100" [class.app-switch]="switchView">
    <div class="app-sidebar" [class.show-sidebar]="sidebarState" [ngClass]="[sidebarState ? 'showSidebar' : 'hideSidebar']">
        <div class="slider-container" (click)="sidebarState = !sidebarState" [ngClass]="[sidebarState ? 'showbar' : 'hidebar']">
            <em *ngIf="sidebarState" [appSvgIcon]="'leftCompactArrow'"></em>
            <em *ngIf="!sidebarState" [appSvgIcon]="'rightCompactArrow'"></em>
        </div>
        <app-sidebar (closeSidebarPanel)="getSidebarState($event)" [panelState]="sidebarState"></app-sidebar>
    </div>
    <div class="app-container" [ngClass]="[sidebarState ? 'sideBarShow' : 'sideBarHide']">
        <app-header [panelState]="sidebarState"></app-header>
        <div class="app-content" [ngClass]="[sidebarState ? 'dullScreen' : 'showScreen']" (scroll)="onContentScroll()">
            <div class="app-content-inner"> 
                <div *ngIf="showChatSupport"> 
                    <app-communication></app-communication>
                </div>
                <app-show-per-modal [sendInfoData]="infoData" (closeModal)="closeModal()"></app-show-per-modal>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>