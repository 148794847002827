export const SuperAdminColumns = [
  { field: "name", header: "Organization" },
  { field: "mobile", header: "Mobile" },
  { field: "email", header: "Email" },
  { field: "state", header: "State" },
  { field: "subscription", header: "Subscription" },
  { field: "published", header: "Published" },
  { field: "stealth", header: "Login" },
  { field: "action", header: "Action" },
];

export const PaymentHistoryCols = [
  { field: "invoice", header: "Invoice" },
  { field: "paymentdate", header: "Payment Date" },
  { field: "fromdate", header: "Start Date" },
  { field: "todate", header: "Due Date" },
  { field: "duedate", header: "Access loss" },
  { field: "status", header: "Status" },
  { field: "amount", header: "Amount" },
];

export const TemplateCols = [
  { field: "templateName", header: "Template Name" },
  // { field: 'subject', header: 'Subject' },
  // { field: 'templateType', header: 'Template Type' },
  // { field: 'selected', header: 'Select' },
];
export const TemplateCols1 = [
  { field: "templateName", header: "Template Name" },
  // { field: 'subject', header: 'Subject' },
  // { field: 'preview', header: 'Preview' },
  // { field: 'templateType', header: 'Template Type' },
  // { field: 'selected', header: 'Select' },
];
export const subscribersCols = [
  { field: "id", header: "ID" },
  { field: "subscribers", header: "Customers" },

  { field: "mobile", header: "Mobile" },
  { field: "email", header: "Email" },
];
