import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class LoginAuthGuard implements CanActivate {

    constructor(
        public router: Router
    ) { }

    canActivate(): boolean {
        if (localStorage.getItem('loggedInUser')) {
            this.router.navigate(['dashboard']);
            return false;
        } else {
            return true;
        }
    }

}
