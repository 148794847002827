// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authService: "authentication-service/api/v1/",
  adminService: "admin-service/api/v1/",
  webService: "web-service/api/v1/",
  imageService: "image-service/api/v1",
  // antMediaWebsocket: "wss://www.testing-kanav.tk/LiveApp/websocket",
  // antMediaServerUrl: "https://www.testing-kanav.tk",

  antMediaWebsocket: "wss://media.nowcast.cc:5443/LiveApp/websocket",
  antMediaRecordWebsocket: "wss://media.nowcast.cc:5443/LiveRecApp/websocket",
  antMediaServerUrl: "https://media.nowcast.cc:5443",
  antMediaServerProdUrl: "https://mediaprod1.nowcast.cc:5443",
  chatSupprotWebsocket: "wss://dev.nowcast.cc/ws",
  customDomainURL: 'https://automation.nowcast.cc/',

  basePath: "https://dev.nowcast.cc/",
  baseUrl: "https://dev.nowcast.cc/",

  // basePath: 'http://52.71.217.35:8090/api/v1/',
  // baseUrl: 'http://52.71.217.35'
  // basePath: ' http://61ed-2405-204-a38c-bac-bcb0-2030-5e3c-74b7.ngrok.io/api/v1/',
  // baseUrl: ' http://61ed-2405-204-a38c-bac-bcb0-2030-5e3c-74b7.ngrok.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
