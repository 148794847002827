<div class="panel-overlay" [ngClass]="{'panel':opacity}" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    <!-- <form> -->

    <div class="panel-header">
        <div class="d-flex justify-content-between">
            <!-- <h4>Static Image</h4> -->
            <h4 *ngIf="!brandIcon && !logoIcon && !appIcon && !static && !splashScreen">Appearance</h4>
            <h4 *ngIf="brandIcon && !static">Logo</h4>
            <h4 *ngIf="logoIcon">Appstore Icon</h4>
            <h4 *ngIf="appIcon">Loading Screen</h4>
            <h4 *ngIf="splashScreen">Splash Screen</h4>
            <h4 *ngIf="static && brandIcon">Static Image</h4>
            <em [appSvgIcon]="'back'" (click)="closePanel('close')"></em>
        </div>
        <ul class="custom-tabs mt-3">
            <li [class.active]="activeTab === 'library'" (click)="getImageData('LIBERARY'); activeTab = 'library'">
                <a>Library</a>
            </li>
            <li *ngIf="( !dataFrom)" [class.active]="activeTab === 'stock'"
                (click)="getImageData('STOCK'); activeTab = 'stock'">
                <a>Stock</a>
            </li>
        </ul>
    </div>
    <div class="container text-center mt-10 mb-10">
        <button type="button" class="btn btn-white mr-10" (click)="closePanel('update')"
            [disabled]="!selectedImgExist || disableApplyBtn">Apply</button>
        <button type="button" class="btn btn-white mr-10" (click)="closePanel('close')">Cancel</button>
        <!-- <button type="button" class="btn btn-white" (click)="closePanel('close')"
                *ngIf="activeTab === 'library'">Back</button> -->
        <button type="button" class="btn btn-white" (click)="backPanel('STOCK')" *ngIf="type === 'child'">Back</button>
    </div>
    <div class="panel-content" style="padding-top: 5px; padding-bottom: 70px;">
        <ng-container *ngIf="activeTab === 'library' else stockContent">
            <div class="app-uploader app-uploader-small mb-3" *ngIf="!croppedImage && hideAudioContainer">
                <input type="file" #attachments (change)="fileChangeEvent($event)">
                <div class="upload-content">
                    <em [appSvgIcon]="'upload'"></em>
                    <div *ngIf="artworkType !== 'LOGO' && artworkType !== 'APP_ICON'">
                        <p>Upload Image</p>
                        <span>{{artworkType === 'banner' ? '1920 X 692' : (artworkType === 'EBOOK' ? '396 x 612'
                            :(artworkType === 'wide' ? '1920 X 1080' :'1024 X 1024'))}}
                            20 MB maximum</span>
                        <ul>
                            <li *ngIf="artworkType !== 'APP_ICON'">JPG</li>
                            <li>PNG</li>
                        </ul>
                    </div>
                    <div *ngIf="artworkType=== 'LOGO' || artworkType === 'APP_ICON'">
                        <p>Upload Image</p>
                        <span>
                            20 MB maximum</span>
                        <ul>
                            <!-- <li>JPG</li> -->
                            <li>PNG</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="!hideAudioContainer">

                <!-- <ng-template> -->
                <div class="download-block">
                    <div class="file-info" style="width: 100%;">
                        <div class="detail-wrapper" style="width: 100%;">
                            <div *ngFor="let progressInfo of progressAudioInfos" class="mb-2">
                                <div *ngIf="progressInfo.value > 0">
                                    <p class="ellipsis-text file-name-ellipsis">{{ progressInfo.fileName }}</p>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-info progress-bar-striped"
                                            role="progressbar" attr.aria-valuenow="{{ progressInfo.value }}"
                                            aria-valuemin="0" aria-valuemax="100"
                                            [ngStyle]="{ width: progressInfo.value + '%' }">
                                            {{ progressInfo.value }}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="btn-group align-items-center" dropdown>
                        <span class="pointer toggle-dropdown" dropdownToggle><em
                                [appSvgIcon]="'toggle'"></em></span>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                            <li *ngIf="!m3u8" ><a href="{{editMedia.videoUrl}}" download target="_blank">Download</a></li>
                            <li><a (click)="deleteVideo()">Delete</a></li>
                        </ul>
                    </div> -->
                </div>
                <!-- </ng-template> -->

            </div>
            <!-- <image-cropper *ngIf="artworkType !== 'LOGO'" [imageChangedEvent]="imageChangedEvent" style="max-width: 250px;"
                    [maintainAspectRatio]="false" format="png" (imageCropped)="imageCropped($event)" imageQuality="100"
                    [cropperMinWidth]="cropperWidth" [cropperMaxWidth]="cropperWidth" [cropperMinHeight]="cropperHeight"
                    [cropperMaxHeight]="cropperHeight"  [resizeToWidth]="imgWidth" [resizeToHeight]="imgHeight">
                </image-cropper> -->

            <ngx-photo-editor *ngIf="artworkType !== 'LOGO' && artworkType !== 'APP_ICON'"
                [imageChangedEvent]="imageChangedEvent" [viewMode]="2" format="png"
                (imageCropped)="imageCropped($event)" [aspectRatio]="aspectRatio" imageQuality="100"
                imageSmoothingQuality="high" imageSmoothingEnabled="true" hideModalHeader="true"
                [resizeToWidth]="imgWidth" [resizeToHeight]="imgHeight" [cropBoxResizable]="cropBoxResizable">
            </ngx-photo-editor>

            <ngx-photo-editor *ngIf="artworkType === 'LOGO'" aspectRatio="auto" [viewMode]="1" hideModalHeader="true"
                [imageChangedEvent]="imageChangedEvent" format="png" (imageCropped)="imageCropped($event)">
            </ngx-photo-editor>

            <!-- <ngx-photo-editor *ngIf="artworkType === 'APP_ICON'" [aspectRatio]="1 / 1" [viewMode]="3" hideModalHeader="true"
                [imageChangedEvent]="imageChangedEvent" format="png" (imageCropped)="imageCropped($event)">
            </ngx-photo-editor> -->
            <ngx-photo-editor *ngIf="artworkType === 'APP_ICON'" [imageChangedEvent]="imageChangedEvent" [viewMode]="2"
                format="png" (imageCropped)="imageCropped($event)" [aspectRatio]="1 / 1" imageQuality="100"
                imageSmoothingQuality="high" imageSmoothingEnabled="true" hideModalHeader="true"
                [resizeToWidth]="imgWidth" [resizeToHeight]="imgHeight" [cropBoxResizable]="cropBoxResizable">
            </ngx-photo-editor>

            <!-- <ng-container *ngIf="croppedImage">
                    <div class="mt-2 mb-3">
                        <button type="button" class="btn btn-white mr-10" (click)="cropImage()">
                            <ng-container *ngIf="isSameSize else cropImageText">
                                Upload
                            </ng-container>
                            <ng-template #cropImageText>
                                Crop
                            </ng-template>
                        </button>
                        <button type="button" class="btn btn-white"
                            (click)="croppedImage = ''; imageChangedEvent = null">Cancel</button>
                    </div>
                </ng-container> -->
            <div class="image-list">
                <p *ngIf="!imgLibraryLists.length">No Library Exist</p>

                <ul *ngIf="artworkType !== 'LOGO' && artworkType !== 'APP_ICON'" class="m-0"
                    [ngClass]="{'art-banner':artworkType === 'banner', 'art-wide':artworkType === 'wide','art-EBOOK':artworkType === 'EBOOK', 'art-square':artworkType === 'square'}">
                    <li *ngFor="let art of imgLibraryLists; let i = index" [class.selected-art]="art.selectedImage"
                        [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4 && clickedFrom === 'carousel'}"
                        [ngStyle]="{'background-color': art.document ? art.document.imageColur : '#e6e6e6'}">



                        <div class="select-stock" *ngIf="art.document.newimg">
                            <div class="form-check" (click)="updateSelectedImage(art, i)"
                                (dblclick)="selectAndApply(art, i)">
                                <input *ngIf="clickedFrom !== 'carousel'" class="form-check-input" type="radio"
                                    name="selectedImage" id="selected-image-{{i}}" [checked]="art.selectedImage"
                                    (change)="updateSelectedImage(art, i)">
                                <input *ngIf="clickedFrom === 'carousel'" class="form-check-input"
                                    [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4}"
                                    type="checkbox" name="selectedImage" id="selected-image-{{i}}"
                                    [checked]="art.selectedImage">
                                <label class="form-check-label" for="selected-image-{{i}}">
                                    <ng-container *ngIf="art.selectedImage else selectText">Selected</ng-container>
                                    <ng-template #selectText>
                                        Select
                                    </ng-template>
                                </label>
                            </div>
                            <span>
                                <em [appSvgIcon]="'delete'" [class.disableIcon]="art.isSelected || art.selectedImage"
                                    [tooltip]="(art.isSelected || art.selectedImage) ?   'You can not delete selected artwork' : ''"
                                    container="body" (click)="deleteStock(art)"></em>
                            </span>
                        </div>
                        <label class="form-check-label" for="selected-image-{{i}}" (click)="updateSelectedImage(art, i)"
                            (dblclick)="selectAndApply(art, i)">
                            <!-- <img  alt="" *ngIf="art.document.newimg" [lazyLoad]="art.document.newimg" offset="100" useSrcset="true">
                            <img *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt="" style="width: 174px;"> -->
                            <!-- <img  alt="" *ngIf="!art.document.newimg" [lazyLoad]="art.document.newimg" offset="100" useSrcset="true"> -->
                            <!-- <img  alt="" [defaultImage]="defaultImg" [lazyLoad]="art.document.newimg" offset="0"> -->
                            <img class="defaultImg" *ngIf="art.document.newimg" [src]="art.document.newimg" alt=""
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;">
                            <!-- <img class="defaultImg" *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt=""> -->
                        </label>
                    </li>


                </ul>


                <ul *ngIf="artworkType === 'LOGO' || artworkType === 'APP_ICON'" class="m-0"
                    [ngClass]="{'art-logo':artworkType === 'LOGO'|| artworkType === 'APP_ICON'}">
                    <li *ngFor="let art of imgLibraryLists; let i = index" [class.selected-art]="art.selectedImage"
                        [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4 && clickedFrom === 'carousel'}"
                        [class.logo-active]="artworkType === 'LOGO' || artworkType === 'APP_ICON'">


                        <div class="background-png-div"></div>


                        <div class="select-stock" *ngIf="art.document.newimg">
                            <div class="form-check" (click)="updateSelectedImage(art, i)"
                                (dblclick)="selectAndApply(art, i)">
                                <input *ngIf="clickedFrom !== 'carousel'" class="form-check-input" type="radio"
                                    name="selectedImage" id="selected-image-{{i}}" [checked]="art.selectedImage"
                                    (change)="updateSelectedImage(art, i)">
                                <input *ngIf="clickedFrom === 'carousel'" class="form-check-input"
                                    [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4}"
                                    type="checkbox" name="selectedImage" id="selected-image-{{i}}"
                                    [checked]="art.selectedImage">
                                <label class="form-check-label" for="selected-image-{{i}}">
                                    <ng-container *ngIf="art.selectedImage else selectText">Selected</ng-container>
                                    <ng-template #selectText>
                                        Select
                                    </ng-template>
                                </label>
                            </div>
                            <span>
                                <em [class.disableIcon]="(art.isSelected || art.selectedImage)"
                                    [tooltip]="(art.isSelected || art.selectedImage) ?   'You can not delete selected artwork' : ''"
                                    container="body" [appSvgIcon]="'delete'" (click)="deleteStock(art)"></em>
                            </span>
                        </div>

                        <label class="form-check-label" for="selected-image-{{i}}" (click)="updateSelectedImage(art, i)"
                            (dblclick)="selectAndApply(art, i)">
                            <!-- <img  alt="" *ngIf="art.document.newimg" [lazyLoad]="art.document.newimg" offset="100" useSrcset="true">
                            <img *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt="" style="width: 174px;"> -->
                            <!-- <img  alt="" *ngIf="!art.document.newimg" [lazyLoad]="art.document.newimg" offset="100" useSrcset="true"> -->
                            <!-- <img  alt="" [defaultImage]="defaultImg" [lazyLoad]="art.document.newimg" offset="0"> -->
                            <img class="defaultImg" *ngIf="art.document.newimg" [src]="art.document.newimg" alt=""
                                onload="style.opacity = 1" style="opacity: 0;">
                            <!-- <img class="defaultImg" *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt=""> -->
                        </label>
                    </li>


                </ul>


            </div>

            <!-- <div *ngIf="artworkType ==='LOGO'" class="image-list">
                <p *ngIf="!imgLibraryLists.length">No Library Exist</p>

                <ul class="m-0"
                [ngClass]="{'art-square':artworkType === 'LOGO'}" >
                    <li *ngFor="let art of imgLibraryLists; let i = index"
                        > -->

            <!-- <div class="background-png-div"></div> -->
            <!--
                       <div class="stack-d-image-select__content stack-d-image-select__content--logo u__pattern--checkerboard stack-d-image-select__content--selected" >

                         <div style="padding-top: 100%;border-radius: 3px;" class="kit-image kit-image--default kit-image--depth ember-view" >

                          <img class="kit-image__image" *ngIf="art.document.newimg" [src]="art.document.newimg" alt=""
                          onload="style.opacity = 1" style="opacity: 0;">

                         </div>


                       </div> -->

            <!-- <div class="select-stock" *ngIf="art.document.newimg">
                            <div class="form-check">
                                <input *ngIf="clickedFrom !== 'carousel'" class="form-check-input" type="radio" name="selectedImage"
                                    id="selected-image-{{i}}" [checked]="art.selectedImage"
                                    (change)="updateSelectedImage(art, i)">
                                <input *ngIf="clickedFrom === 'carousel'" class="form-check-input" [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4}" type="checkbox" name="selectedImage"
                                    id="selected-image-{{i}}" [checked]="art.selectedImage"
                                    (change)="updateSelectedImage(art, i)">
                                <label class="form-check-label" for="selected-image-{{i}}">
                                    <ng-container *ngIf="art.selectedImage else selectText">Selected</ng-container>
                                    <ng-template #selectText>
                                        Select
                                    </ng-template>
                                </label>
                            </div>
                            <span>
                                <em [appSvgIcon]="'delete'" (click)="deleteStock(art.id)"></em>
                            </span>
                        </div>
                        <label class="form-check-label" for="selected-image-{{i}}">

                            <img style="z-index: 2;" class="defaultImg" *ngIf="art.document.newimg" [src]="art.document.newimg" alt=""
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;">

                        </label> -->
            <!-- </li>
                </ul>
            </div> -->

            <div *ngIf="!loadMoreForLib" class="app-filters d-flex mb-0 justify-content-center align-items-center">
                <!-- <div class="circular-button">

                </div> -->

                <button *ngIf="imgLibraryLists.length>0" (click)="loadMore()"
                    class="btn btn-white mb-0 load-more-btn">Load More</button>
                <!-- <button (click)="loadMore()" class="btn btn-white mb-0 mr-0">Load More</button> -->

            </div>


        </ng-container>
        <ng-template #stockContent>
            <div class="image-list">
                <p *ngIf="!imgStockLists.length">No Stock Exist</p>
                <ul *ngIf="artworkType !== 'LOGO' && artworkType !== 'APP_ICON'" class="m-0"
                    [ngClass]="{'art-banner':artworkType === 'banner', 'art-wide':artworkType === 'wide', 'art-square':artworkType === 'square','art-EBOOK':artworkType === 'EBOOK'}">
                    <!-- <li *ngFor="let art of imgStockLists; let i = index" [class.selected-art]="art.selectedImage || art.isSelected"
                        [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4 && clickedFrom === 'carousel'}"
                        [ngStyle]="{'background-color': art.document ? art.document.imageColur : '#e6e6e6'}"> -->



                    <li *ngFor="let art of imgStockLists; let i = index" [class.selected-art]="art.selectedImage"
                        [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4 && clickedFrom === 'carousel'}"
                        [ngStyle]="{'background-color': art.document ? art.document.imageColur : '#e6e6e6'}">

                        <div class="select-stock">
                            <div class="form-check" (click)="updateSelectedImage(art, i)"
                                (dblclick)="selectAndApply(art, i)">
                                <input *ngIf="clickedFrom !== 'carousel'" class="form-check-input" type="radio"
                                    name="selectedImage" id="selected-image-{{i}}" [checked]="art.selectedImage"
                                    (change)="updateSelectedImage(art, i)">
                                <input *ngIf="clickedFrom === 'carousel'" class="form-check-input"
                                    [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4}"
                                    type="checkbox" name="selectedImage" id="selected-image-{{i}}"
                                    [checked]="art.selectedImage">
                                <label class="form-check-label" for="selected-image-{{i}}">
                                    <!-- <ng-container *ngIf="art.selectedImage else selectText">Selected</ng-container> -->
                                    <ng-container *ngIf="(art.selectedImage) else selectText">Selected
                                    </ng-container>
                                    <ng-template #selectText>
                                        Select
                                    </ng-template>
                                </label>
                            </div>
                        </div>
                        <label class="form-check-label" for="selected-image-{{i}}" (click)="updateSelectedImage(art, i)"
                            (dblclick)="selectAndApply(art, i)">
                            <!-- <img [lazyLoad]="art.newimg" alt="" offset="100" useSrcset="true" > -->
                            <!-- <img  alt="" *ngIf="art.document.newimg" [lazyLoad]="art.document.newimg" offset="100" useSrcset="true">
                            <img *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt=""> -->
                            <!-- <img *ngIf="art.document.newimg" [src]="art.document.newimg" alt="">
                            <img class="defaultImg" *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt=""> -->
                            <img class="defaultImg" *ngIf="art.document.newimg" [src]="art.document.newimg" alt=""
                                onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;">
                        </label>
                    </li>
                </ul>

                <ul *ngIf="artworkType === 'LOGO' || artworkType === 'APP_ICON'" class="m-0"
                    [ngClass]="{'art-logo':artworkType === 'LOGO' || artworkType === 'APP_ICON'}">
                    <li *ngFor="let art of imgStockLists; let i = index" [class.selected-art]="art.selectedImage"
                        [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4 && clickedFrom === 'carousel'}"
                        class="logo-active">



                        <div class="background-png-div"></div>


                        <div class="select-stock">
                            <div class="form-check" (click)="updateSelectedImage(art, i)"
                                (dblclick)="selectAndApply(art, i)">
                                <input *ngIf="clickedFrom !== 'carousel'" class="form-check-input" type="radio"
                                    name="selectedImage" id="selected-image-{{i}}" [checked]="art.selectedImage"
                                    (change)="updateSelectedImage(art, i)">
                                <input *ngIf="clickedFrom === 'carousel'" class="form-check-input"
                                    [ngClass]="{deactive: !art.selectedImage && selectedArtwork.length>4}"
                                    type="checkbox" name="selectedImage" id="selected-image-{{i}}"
                                    [checked]="art.selectedImage">
                                <label class="form-check-label" for="selected-image-{{i}}">
                                    <ng-container *ngIf="art.selectedImage else selectText">Selected</ng-container>
                                    <ng-template #selectText>
                                        Select
                                    </ng-template>
                                </label>
                            </div>
                        </div>
                        <label class="form-check-label" for="selected-image-{{i}}" (click)="updateSelectedImage(art, i)"
                            (dblclick)="selectAndApply(art, i)">
                            <!-- <img [lazyLoad]="art.newimg" alt="" offset="100" useSrcset="true" > -->
                            <!-- <img  alt="" *ngIf="art.document.newimg" [lazyLoad]="art.document.newimg" offset="100" useSrcset="true">
                            <img *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt=""> -->
                            <!-- <img *ngIf="art.document.newimg" [src]="art.document.newimg" alt="">
                            <img class="defaultImg" *ngIf="!art.document.newimg" src="assets/images/common/square.svg" alt=""> -->
                            <img class="defaultImg" *ngIf="art.document.newimg" [src]="art.document.newimg" alt=""
                                onload="style.opacity = 1" style="opacity: 0;">
                        </label>


                    </li>
                </ul>
            </div>

            <div *ngIf="!loadMoreForStock" class="app-filters d-flex mb-0 justify-content-center align-items-center">
                <div class="circular-button">

                </div>

                <button *ngIf="imgStockLists.length>0" (click)="loadMore()"
                    class="btn btn-white mb-0 mr-0 load-more-btn">Load More</button>
                <!-- <button (click)="loadMore()" class="btn btn-white mb-0 mr-0">Load More</button> -->

            </div>

        </ng-template>

        <!-- <div class="app-filters d-flex mb-0 justify-content-center align-items-center">
            <div class="circular-button">

            </div>

            <button *ngIf="!loadMoreForLib" (click)="loadMore()" class="btn btn-white mb-0 mr-0">Load More</button>
            <button (click)="loadMore()" class="btn btn-white mb-0 mr-0">Load More</button>

        </div> -->

    </div>

    <!-- </form> -->

</div>


<p-dialog *ngIf="deleteStockModal" header="Delete Library" [(visible)]="deleteStockModal" [modal]="true"
    [draggable]="false" [resizable]="false">
    <div>
        Are you sure, you want to delete the appearance? This cannot be undone.
    </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)="deleteStockMod()">Yes, Delete</button>
        <button type="button" class="btn btn-white" (click)="deleteStockModal = false;">No,
            Cancel</button>
    </p-footer>
</p-dialog>