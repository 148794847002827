import { MessageService } from 'primeng/api';
import { ArtWorkService } from './../../../services/artwork/artwork.service';
import { SharedService } from './../../../services/shared/shared.service';
import { Subscription } from 'rxjs';
import { AppAnimation } from './../../../utilities/animations/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { SettingsServiceService } from 'src/app/services/settings/settings-service.service';
import { ThrowStmt } from '@angular/compiler';
import { appConfig } from 'src/app/app.config';
import { timeStamp } from 'console';
import { TouchSequence } from 'selenium-webdriver';
import {CroppedEvent} from 'ngx-photo-editor';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.css'],
  animations: AppAnimation,
})
export class ArtworkComponent implements OnInit, OnDestroy {
  stockType: string
  animation = 'pulse';
  count = 2;
  widthHeightSizeInPixels = 50;
  appIcon = false
  givingIcon = false
  logoIcon = false
  brandIcon = false
  // splashScreen = false
  clickedFrom: string
  opacity: false;
  imgList: any[] = [];
  imgLibraryLists: any[] = [];
  imgStockLists: any[] = [];
  newdata: any[] = [];
  overlay = false;
  sidePanel = false;
  dataFrom = false
  artworkType: string;
  activeTab = 'library';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  selectedImageData: any;
  type: any;
  static = false
  subsciptionData = new Subscription();
  imageSize = {
    banner: { width: 1920, height: 692 },
    wide: { width: 1920, height: 1080 },
    square: { width: 1024, height: 1024 },
    LAUNCH_SCREEN: { width: 1024, height: 1024 },
    APP_ICON: { width: 1024, height: 1024 },
    EBOOK: { width: 396, height: 612 }
  };
  imageName: string;
  cropperWidth: number;
  cropperHeight: number;
  artworkList: any[] = [];
  isSameSize = false;
  organizationId: number;
  selectedImgExist = false;
  selectedArtwork: any = {};
  // selectedArtwork : number
  defaultImg: any;
  totalChecked: any[] = []
  selectedItem: any[] = []
  currentIndex: any
  clicked: string;
  brandingSelectedId: number
  artwork = 'square'
  page = 1;
  size = 10;
  libraryCounter = 1
  stockCounter = 0
  libraryArray: any[] = []
  stockArray: any[] = []
  cloneLibraryArray: any[] = []
  cloneStockArray: any[] = []
  loadMoreForLib = false
  loadMoreForStock = false
  totalElementsInLib: number
  totalElementsInStock: number
  selectedImage: any = {}
  brandingArtworkId: number
  uploadedAtFirstIndex = false
  cloneSelectedImage: any = {}
  imgWidth: number
  imgHeight: number
  filter: any = {}
  selectedData: any;
  storeFilter = ''
  aspectRatio: any
  cropBoxResizable = true
  base64String : any
  imageType : string
  deleteStockModal = false
  stock : any = {}
  showBack = true
  splashScreen = false
  disableApplyBtn = false
  selectedArtworkId : number
  selectedArtworkIdComing : number
  @ViewChild('attachments') attachment: any;
  @Output() updateArtwork = new EventEmitter();
  progressInfos: any[] = [];
  progressAudioInfos: any[] = [];
  progressInfoData: any;
  subscriptonProgressData = new Subscription();
  exType: any;
  hideAudioContainer = true;
  selectedFiles: any
  constructor(
    public sharedService: SharedService,
    public artWorkService: ArtWorkService,
    public messageService: MessageService,
    public libraryService: LibraryServiceService,
    public settingsService: SettingsServiceService
  ) { }

  ngOnInit(): void {
    this.organizationId = JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.id;
    this.getSubscriptionData();
    this.defaultImg = 'assets/images/common/square.svg'
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }

  getSubscriptionData() {
    this.subsciptionData.add(
      this.sharedService.panelState$.subscribe((data: any) => {
        this.sidePanel = data.showPanel;
        this.clicked = data.clicked;
        this.overlay = data.showPanel;
        this.opacity = data.opacity
        this.appIcon = data.appIcon
        this.logoIcon = data.logoIcon
        this.brandIcon = data.brandIcon
        this.splashScreen = data.splashScreen
        // this.givingIcon = data.showPanel
        this.givingIcon = data.givingIcon
        // this.clickedFrom = data.clickedFrom
        this.dataFrom = data.dataFrom
        if (this.brandIcon) {
          this.dataFrom = false
        }
        if (this.givingIcon) {
          this.dataFrom = false
        }
        if (this.logoIcon) {
          this.dataFrom = false
        }
        if (this.appIcon) {
          this.dataFrom = false
        }
        if(this.splashScreen){
          this.dataFrom = false
        }
        this.static = data.static
        this.clickedFrom = data.clickedFrom
        if (data.artworkId) {
          this.brandingArtworkId = data.artworkId
          this.selectedImage = this.brandingArtworkId
          console.log('selected Image' , this.selectedImage);
          if (this.static) {
            this.brandIcon = true
          }

        }
        if (data.id) {
          this.brandingSelectedId = data.id
          this.selectedArtwork = this.brandingSelectedId
          // this.selectedImage = this.brandingSelectedId

        }


        this.activeTab = 'library';
        if (data.type) {
          this.artworkType = data.type;
          if (this.artworkType !== 'LOGO') {
            this.cropperWidth = this.imageSize[data.type].width;
            this.cropperHeight = this.imageSize[data.type].height;
          }

          if (data.artwork) {
            const art = this.artworkType === 'square' ? 'SQUARE': (this.artworkType === 'EBOOK' ? 'EBOOK' : (this.artworkType === 'wide' ? 'WIDE' : 'BANNER'));
            this.selectedArtwork[`${art}`] = data.artwork;
            this.selectedImage = data.artwork['id']
          }
          if (data.appIconDTO){
            this.selectedArtwork = data.appIconDTO
            this.selectedImage = data.appIconDTO['id']
          }
          // this.getImageList('LIBERARY');
          this.getImageList(this.page, this.size, 'LIBERARY');
        } else {
          this.imageChangedEvent = null;
        }
        if (this.selectedImage === data.id || this.brandingSelectedId === data.id) {
          this.disableApplyBtn = true
        } 
        this.selectedArtworkIdComing = data.artworkId
      })
    );
    this.subsciptionData.add(
      this.libraryService.sendBannerCarouselIds$.subscribe((response: any) => {
        if (response) {
          this.selectedItem = response

          

          // this.selectedImage = response
          // this.imgList.forEach((el) => {
          //   response.forEach((data) =>{
          //     if(el.id === data){
          //       el['selectedImage'] = true;
          //     }
          //   })
          // if (el.id === this.selectedArtwork[`${artworkType}`].id) {
          //   el['selectedImage'] = true;
          // }
          // });
        }
      })
    )
  }

  closePanel(type) {
    if (type === 'update' || type === 'close') {
      this.page = 1
      this.libraryCounter = 1
      this.stockCounter = 0
      this.cloneStockArray = []
      this.cloneLibraryArray = []
      this.libraryArray = []
      this.stockArray = []
      this.totalElementsInLib = 0
      this.totalElementsInStock = 0
      this.loadMoreForLib = false
      this.loadMoreForStock = false
      this.selectedImage = {}
      this.brandingArtworkId = null
      this.brandingSelectedId = null
      this.imgList = []
      this.imgStockLists = []
      this.imgLibraryLists = []
      this.uploadedAtFirstIndex = false
      this.cloneSelectedImage = {}
      this.type = ''
      this.filter = {}
      this.storeFilter = ''
    }
    // this.sidePanel = false;
    // this.overlay = false;
    // this.opacity = false
    // this.sharedService.updatePanelState({ showPanel: false });
    this.croppedImage = '';
    this.imgList = [];
    this.imgLibraryLists = [];
    this.imgStockLists = [];
    if (type === 'update') {
      if (!this.static) {
        this.updateArtwork.emit(this.selectedArtwork);
      }
      if (this.clickedFrom === 'static') {
        this.libraryService.sendStaticImageId(this.selectedArtwork)
      }
      if (this.clickedFrom === 'carousel') {
        this.libraryService.sendCaraousalData(this.selectedArtwork)
      }

      if (this.appIcon) {
        this.settingsService.sendAppIconImageId(this.selectedArtwork)
        this.brandingSelectedId = null
      }

      if (this.logoIcon) {
        this.settingsService.sendLogo(this.selectedArtwork)
        this.brandingSelectedId = null
      }

      if (this.brandIcon) {
        this.settingsService.brandLogo(this.selectedArtwork)
        this.brandingSelectedId = null
      }

      if(this.splashScreen){
        this.settingsService.sendSplashScreen(this.selectedArtwork)
        this.brandingSelectedId = null
      }
      if (this.givingIcon) {
        this.settingsService.givingAppearanceArtworkId(this.selectedArtwork)
        // this.brandingSelectedId = null

      }
    }

    if (this.clicked === 'iconLaunchScreen') {
      this.libraryService.updatePanel({ showPanel: true });
    }
    // if(type === 'update' && this.appIcon ){
    //   this.settingsService.sendAppIconImageId(this.selectedArtwork)
    //   this.appIcon = false
    // }
    // this.sidePanel = false;
    // this.overlay = false;
    // this.opacity = false
    // this.appIcon = false
    // this.logoIcon = false
    // this.brandIcon = false
    this.sharedService.updatePanelState({ showPanel: false });
    this.sidePanel = false;
    this.overlay = false;
    this.opacity = false;
    this.appIcon = false;
    this.logoIcon = false;
    this.brandIcon = false;
    this.givingIcon = false
    this.dataFrom = false
    this.static = false
    this.attachment.nativeElement.value = '';
  }


  handleUpload(event:any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64String = reader.result
    };
}

  /**
   * On file select
   * @param event Input Event
   */
  fileChangeEvent(event: any): void {
    // if (!this.brandIcon || this.static) {
      if(this.artworkType !== 'APP_ICON' && this.artworkType !== 'LOGO'){
      if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg') {
        this.imageName = event.target.files[0].name;
        const img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        

        if (event.target.files) {
          // var reader = new FileReader();
          // reader.readAsDataURL(event.target.files[0])
          // reader.onload = (e: any) => {
          //   this.base64String = e.target.result
          // }
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
              // console.log(reader.result);
              this.base64String = reader.result
          };
        }

        img.onload = () => {
          this.isSameSize = (img.width === this.cropperWidth && img.height === this.cropperHeight);
          this.imgWidth = img.width
          this.imgHeight = img.height
          // if(img.width === 1024 && this.imgHeight === 1024){
          //   this.cropImage()
          // }
          // else{

            if (img.width < this.cropperWidth || img.height < this.cropperHeight) {
              if(this.artworkType === 'EBOOK'){
                var alertMsg = `Minimum image size for Ebook should be ${this.imageSize[this.artworkType].width}px * ${this.imageSize[this.artworkType].height}px.`; 
              }
              else{
                var alertMsg = `Minimum image size for Logo should be ${this.imageSize[this.artworkType].width}px * ${this.imageSize[this.artworkType].height}px.`;
              }

              this.attachment.nativeElement.value = '';
              // alert(alertMsg);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
              return;
            } else {
              if(this.artworkType === 'square' || this.artworkType === 'LAUNCH_SCREEN'){
                this.aspectRatio = 1 / 1;
                if (event.target.files[0].size > 21000000) {
                  alertMsg = 'File size must be less than 20mb';
                  this.attachment.nativeElement.value = '';
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
                }
                 else if(this.imgWidth === 1024 && this.imgHeight === 1024){
                  this.cropBoxResizable = false
                  this.crop()
                }
                else{
                  this.imageChangedEvent = event;
                  setTimeout(() => {
                    this.attachment.nativeElement.value = '';
                  }, 1000 )
                }
              }
              else if(this.artworkType === 'wide'){
                this.aspectRatio = 16 / 9;
                if (event.target.files[0].size > 21000000) {
                  alertMsg = 'File size must be less than 20mb';
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
                }
                else if(this.imgWidth === 1920 && this.imgHeight === 1080){
                  this.cropBoxResizable = false
                  this.crop()
                }
                else{
                  this.imageChangedEvent = event;
                  setTimeout(() => {
                    this.attachment.nativeElement.value = '';
                  }, 1000 )
                }
              }
              else if (this.artworkType === 'banner'){
                // this.aspectRatio = 3 / 1;
                this.aspectRatio = 480 / 173;
                if (event.target.files[0].size > 21000000) {
                  alertMsg = 'File size must be less than 20mb';
                  this.attachment.nativeElement.value = '';
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
                }
                else if(this.imgWidth === 1920 && this.imgHeight === 692){
                  this.cropBoxResizable = false
                  this.crop()
                }
                else{
                  this.imageChangedEvent = event;
                  setTimeout(() => {
                    this.attachment.nativeElement.value = '';
                  }, 1000 )
                }
              }
              else if (this.artworkType === 'EBOOK'){
                // this.aspectRatio = 0.65 / 1;
                this.aspectRatio = 11/17
                if(this.imgWidth === 396 && this.imgHeight === 612){
                  this.cropBoxResizable = false
                  this.crop()
                }
                else{
                  this.imageChangedEvent = event;
                  setTimeout(() => {
                    this.attachment.nativeElement.value = '';
                  }, 1000 )
                }
              }
              // this.imageChangedEvent = event;
            }
          // }
          };
        } else {
          this.attachment.nativeElement.value = '';
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Supported image formats are PNG and JPG' });
      }
    }
    else {

      if (this.artworkType === 'LOGO') {
        if (event.target.files[0].type === 'image/png') {
          this.imageName = event.target.files[0].name;
          const img = new Image();
          img.src = window.URL.createObjectURL(event.target.files[0]);
          img.onload = () => {
            this.imgWidth = img.width
            this.imgHeight = img.height
            let alertMsg;
            if (event.target.files[0].size > 21000000) {
              alertMsg = 'File size must be less than 20mb';
              this.attachment.nativeElement.value = '';
              this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
            }
            else if(this.imgHeight > this.imgWidth){
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You cannot upload this image' });
            }
            else{
              this.imageChangedEvent = event;
              setTimeout(() => {
                this.attachment.nativeElement.value = '';
              }, 1000 )
            }
          }
        } else {
          this.attachment.nativeElement.value = '';
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Supported image format is PNG' });
        }
      }
      else if (this.artworkType === 'APP_ICON') {

        if (event.target.files[0].type === 'image/png') {
          this.imageName = event.target.files[0].name;
          const img = new Image();
          img.src = window.URL.createObjectURL(event.target.files[0]);
          img.onload = () => {
            this.isSameSize = (img.width === this.cropperWidth && img.height === this.cropperHeight);
            this.imgWidth = img.width
            this.imgHeight = img.height
            let alertMsg;
            if (event.target.files[0].size > 21000000) {
              alertMsg = 'File size must be less than 20mb';
              this.attachment.nativeElement.value = '';
              this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
            }
            // else if (img.width < this.cropperWidth || img.height < this.cropperHeight || img.width > this.cropperWidth || img.height > this.cropperHeight) {
            //   alertMsg = `Image size for ${this.artworkType} should be ${this.imageSize[this.artworkType].width}px * ${this.imageSize[this.artworkType].height}px.`;
            //   // alert(alertMsg);
            //   this.attachment.nativeElement.value = '';
            //   this.messageService.add({ severity: 'error', summary: 'Error', detail: alertMsg });
            //   return;
            // } 
            else {
              this.imageChangedEvent = event;
              setTimeout(() => {
                this.attachment.nativeElement.value = '';
              }, 1000 )
            }
          };
        } else {
          this.attachment.nativeElement.value = '';
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Supported image format is PNG.' });
        }


      }

    }

  }

  crop(){
   this.croppedImage = this.base64String
   this.cropImage()
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.cropImage()
    this.sharedService.updateLoader(false);
  }

  cropImage() {
    this.sharedService.updateLoader(false);
    var imgData = {}
    // this.artwork = this.artworkType === 'square' ? 'SQUARE' : (this.artworkType === 'wide' ? 'WIDE' : 'BANNER');
    this.artwork = this.artworkType === 'square' ? 'SQUARE' : (this.artworkType === 'EBOOK' ? 'EBOOK': (this.artworkType === 'wide' ? 'WIDE' : (this.artworkType === 'LOGO' ? 'LOGO' : (this.artworkType === 'LAUNCH_SCREEN' ? 'LAUNCH_SCREEN' : (this.artworkType === 'APP_ICON' ? 'APP_ICON' : 'BANNER')))));
    if (this.artwork !== 'LOGO' && this.artwork !== 'APP_ICON') {
      imgData = imgData = {
        name: this.imageName,
        imageString: this.croppedImage.split(';base64,')[1]
      };
      // this.artwork = this.artworkType === 'square' ? 'SQUARE' : (this.artworkType === 'wide' ? 'WIDE' : 'BANNER');
    }
    else if (this.artwork === 'LOGO' || this.artwork === 'APP_ICON') {
      imgData = imgData = {
        name: this.imageName,
        isLogo: true,
        imageString: this.croppedImage.split(';base64,')[1]
      };
      // this.artwork = 'LOGO'
    }

    this.selectFiles(imgData,'audio')
    // const artwork = this.artworkType === 'square' ? 'SQUARE' : (this.artworkType === 'wide' ? 'WIDE' : 'BANNER');

    this.sharedService.updateLoader(false);
    // this.artWorkService.uploadBase64(imgData).subscribe((response: any) => {
    //   this.sharedService.updateLoader(false);
    //   const stockData = {
    //     artworkType: this.artwork,
    //     documentId: response.body.data.id,
    //     parentId: null,
    //     type: 'LIBERARY'
    //   };
    //   this.sharedService.updateLoader(false);
    //   this.uploadStock(stockData, response.body.data);
    // }, (error) => {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    // });
    // this.sharedService.updateLoader(false);
  }

  /**
   * To upload stock
   * @param stockData Stock Data
   * @param baseImage Base64 image Data
   */
  uploadStock(stockData, baseImage) {
    this.sharedService.updateLoader(true);
    // var type = stockData.artworkType;
    this.artWorkService.uploadStock(stockData).subscribe((response: any) => {
      
      // let id = baseImage.id;
      // if(type === "SQUARE"){var width = 200;var height = 200;}
      //     else if(type === "WIDE"){var width = 200; var height = 95;}
      //     else if(type === "BANNER"){var width = 200; var height = 67;}
      //     baseImage['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
      // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
      //   this.sharedService.updateLoader(false);
      //   this.imgLists = response.data;
      //   baseImage['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;
      // })
      // this.imgList.unshift({ id: response.body.data, document: baseImage });
      this.imageChangedEvent = null;
      this.croppedImage = '';
      let message = ''
      if(this.activeTab === "library"){
        message = 'Library'
      }
      else{
        message = 'Stock'
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${message} uploaded successfully` });
      // this.getImageList(stockData.type);
      this.uploadedAtFirstIndex = true

      this.page = 1
      this.libraryCounter = 1
      this.cloneLibraryArray = []
      this.libraryArray = []
      this.totalElementsInLib = 0
      this.loadMoreForLib = false
      this.brandingArtworkId = null
      this.brandingSelectedId = null
      this.imgLibraryLists = []
      this.uploadedAtFirstIndex = false
      this.selectedImage = this.cloneSelectedImage
      this.filter = this.selectedData
      this.getImageList(this.page, this.size, stockData.type);
      this.sharedService.updateLoader(true);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  backPanel(data) {
    this.type = ''
    // this.cloneStockArray = []
    // this.imgStockLists = []
    // this.imgList = []
    // this.stockArray = []
    // this.getImageList(data)
    this.loadMoreForStock = false
    this.activeTab = 'stock'
    // this.getImageList(this.page, this.size, data)

    this.imgList = this.cloneStockArray
    this.imgStockLists = this.cloneStockArray

  }

  getImageData(type) {
    if (type === 'STOCK') {
      this.activeTab = `stock`
      this.type = ''
      this.selectedImgExist = false
      if(this.imgStockLists.length>0){
        this.imgStockLists.forEach(el=>{
          if(el.selectedImage === true){
            // el.selectedImage = false
            this.selectedImgExist = true
          }
        })
      }
    }
    else {
      this.activeTab = `library`
      this.type = ''
      this.selectedImgExist = false
      if(this.imgLibraryLists.length>0){
        this.imgLibraryLists.forEach(el=>{
          // el.selectedImage = false
          if(el.selectedImage === true){
            // el.selectedImage = false
            this.selectedImgExist = true
          }
        })
        // this.selectedImgExist = false
      }
    }
    if (this.activeTab === 'stock' && this.stockCounter === 0) {
      this.page = 1
      this.stockCounter = ++this.stockCounter
    }
    if (this.activeTab === 'library' && (this.imgLibraryLists.length === 0 || this.imgLibraryLists.length)) {
      this.imgList = this.cloneLibraryArray
      this.imgLibraryLists = this.cloneLibraryArray
    }
    if (this.activeTab === 'stock' && (this.imgStockLists.length === 0 || this.imgStockLists.length)) {
      this.imgList = this.cloneStockArray
      this.imgStockLists = this.cloneStockArray
    }
    if (this.libraryArray.length < 1 && this.activeTab === 'library') {
      this.getImageList(this.page, this.size, type);
    }
    if (this.stockArray.length < 1 && this.activeTab === 'stock') {
      this.getImageList(this.page, this.size, type);
    }

  }

  getImageList(page, size, type) {
    this.selectedData = null
    if (this.clickedFrom === 'carousel') {
      if (this.selectedItem.length > 0) {
        this.selectedItem.forEach(el => {
          // var filter = ''
          this.storeFilter += `artworkIdList=${el}&`;

        });
        this.filter = this.storeFilter
        this.selectedData = this.filter
      }
      else {
        this.filter = ''
      }

    } else {
      this.filter = (Object.keys(this.selectedImage).length === 0 && this.selectedImage.constructor === Object) ? '' : `artworkIdList=${this.selectedImage}&`
      this.selectedData = this.filter
    }

    this.artWorkService.getStockListInOrganization(this.filter, this.artworkType, this.organizationId, page, size, type).subscribe((response: any) => {
      this.artworkList = response.data;
      if (type === `LIBERARY` && response.data.content.length > 0) {
        this.libraryArray.push(...response.data.content)
        this.cloneLibraryArray = this.libraryArray
        this.imgList = this.libraryArray
        this.totalElementsInLib = response.data.totalElements

        if (this.libraryArray.length === 0 || this.totalElementsInLib < 10) {
          this.loadMoreForLib = true
        }
        this.uploadedAtFirstIndex = false
      }
      else if (type === `STOCK` && response.data.content.length > 0) {
        this.stockArray.push(...response.data.content)
        this.cloneStockArray = this.stockArray
        this.imgList = this.stockArray
        this.totalElementsInStock = response.data.totalElements
        if (this.stockArray.length === 0 || this.totalElementsInStock < 10) {
          this.loadMoreForStock = true
        }

      }

      this.storeFilter = ''
      this.filter = {}

      const clonedList = JSON.parse(JSON.stringify(this.artworkList));

      // const artworkType = (this.artworkType === 'banner' ? 'BANNER' : (this.artworkType === 'wide' ? 'WIDE' : 'SQUARE'));
      const artworkType = (this.artworkType === 'banner' ? 'BANNER' : (this.artworkType === 'wide' ? 'WIDE': (this.artworkType === 'EBOOK' ? 'EBOOK'  : (this.artworkType === 'LOGO' ? 'LOGO' : (this.artworkType === 'LAUNCH_SCREEN' ? 'LAUNCH_SCREEN' : (this.artworkType === 'APP_ICON' ? 'APP_ICON' : 'SQUARE'))))));
      // this.imgList = clonedList.filter((el) => el.type === type && el.artworkType === artworkType);
      // this.sharedService.updateLoader(false);
      this.imgList.forEach((data, index) => {
        this.sharedService.updateLoader(true);
        if (data.document) {
          // this.sharedService.updateLoader(false);

          if (artworkType !== "LOGO" && artworkType !== "APP_ICON") {
            if (data.artworkType === "SQUARE" || data.artworkType === "LAUNCH_SCREEN") { var width = 200; var height = 200; }
            else if (data.artworkType === "WIDE") { var width = 480; var height = 270; }
            else if (data.artworkType === "BANNER") { var width = 415; var height = 150; }
            else if (data.artworkType === "EBOOK") { var width = 396; var height = 612; }
            let id = data.document.id
            data.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width
          }
          else if (artworkType === 'LOGO' || artworkType === "APP_ICON") {
            let id = data.document.id
            data.document['newimg'] = `${appConfig.imageURL}` + id
          }

        }

        this.sharedService.updateLoader(false);
      })

      if (this.clickedFrom === 'carousel') {
        // if(type === "LIBERARY"){this.imgLibraryLists = this.imgList;}
        // else if(type === "STOCK"){this.imgStockLists = this.imgList;}
        this.imgList.forEach((el) => {
          this.selectedItem.forEach((data) => {


            // if (el.document.id === data) {
            if (el.id === data) {
              el['selectedImage'] = true;
              this.selectedImgExist = true;
            }
          })
        });

        this.selectedArtwork = this.imgList.filter(el => {
          if (el.selectedImage === true) {
            return el
          }
        })


        // this.selectedImgExist = true;
      }
      else {
        this.selectedImgExist = false;
      }
      if (type === "LIBERARY") { this.imgLibraryLists = this.imgList; }
      else if (type === "STOCK") { this.imgStockLists = this.imgList; }
      if (this.clickedFrom !== 'carousel') {

        if (this.selectedArtwork && this.selectedArtwork[`${artworkType}`]) {

          this.imgList.forEach((el) => {

            if (el.id === this.selectedArtwork[`${artworkType}`].id) {
              el['selectedImage'] = true;
              this.selectedImgExist = true;
              this.currentIndex = this.imgList.indexOf(el)
              this.moveArrayItemToNewIndex(this.imgList, this.currentIndex, 0)
            }

            // if(el.isSelected && el.isParent && el.id === this.selectedArtwork[`${artworkType}`].id){
            //   el['selectedImage'] = false;
            // }
          });

          // this.selectedImgExist = true;
        }

        else {
          this.selectedImgExist = false;
        }
      }


      if (this.logoIcon || this.appIcon || this.brandIcon || this.splashScreen || this.givingIcon) {



        if (this.brandingSelectedId && this.imgList.length > 0) {
          this.imgList.forEach((el) => {
            // if (el.document.id === this.brandingSelectedId) {
            //   el['selectedImage'] = true;
            //   this.currentIndex = this.imgList.indexOf(el)
            // }
            if (el.id === this.brandingArtworkId) {
              el['selectedImage'] = true;
              this.selectedImgExist = true;
              this.currentIndex = this.imgList.indexOf(el)
            }
          });

          this.moveArrayItemToNewIndex(this.imgList, this.currentIndex, 0)
          // this.selectedImgExist = true;
        }
        else {
          this.selectedImgExist = false;
        }
      }


    });

  }









  /**
   * Update image list based on Library/Stock
   * @param type Library/Stock
   */
  updateImageList(type) {
    const clonedList = JSON.parse(JSON.stringify(this.artworkList));
    const artworkType = (this.artworkType === 'banner' ? 'BANNER' : (this.artworkType === 'EBOOK' ? 'EBOOK' :  (this.artworkType === 'wide' ? 'WIDE' : (this.artworkType === 'LOGO' ? 'LOGO' : (this.artworkType === 'LAUNCH_SCREEN' ? 'LAUNCH_SCREEN' : 'SQUARE')))));
    this.imgList = clonedList.filter((el) => el.type === type && el.artworkType === artworkType);
  }

  updateSelectedImage(art, index) {
    // if (art.isParent === true) {
    //   this.getChildList(art.id);
    // } else {
    //   if (this.clickedFrom !== 'carousel') {
    //     this.imgList.forEach((el) => el.selectedImage = false);
    //   }
    //   if (art.selectedImage === true) {
    //     this.imgList[index].selectedImage = false
    //   }
    //   else {
    //     this.imgList[index].selectedImage = true;
    //   }
    //   this.selectedImgExist = true;
    //   if (this.clickedFrom === 'carousel') {
    //     this.selectedArtwork = this.imgList.filter(el => {
    //       if (el.selectedImage === true) {
    //         return el
    //       }
    //     })
    //   }
    //   else {
    //     this.selectedArtwork = art;
    //   }
    //   this.totalChecked = this.imgList.filter(el => {
    //     if (el.selectedImage === true) {
    //       return el
    //     }
    //   })
    //   console.log(this.totalChecked)
    // }
    // this.selectedArtworkId = art.id
    if (this.selectedArtworkIdComing !== art.id){
      this.disableApplyBtn = false
    }
    if (this.type === 'child') {

      if (art.isParent === true) {
        // this.imgStockLists.forEach(el=>{
        //   if(el.id === art.id){
        //     el.selectedImage = true;
        //   }
        // })
        if (this.selectedArtworkIdComing !== art.id){
          this.disableApplyBtn = false
        }
        this.getChildList(art.id);
      }
      else {
        if (this.clickedFrom !== 'carousel') {
          this.imgStockLists.forEach((el) => el.selectedImage = false);
        }
        if (art.selectedImage === true) {
          this.imgStockLists[index].selectedImage = false
        }
        else {
          this.imgStockLists[index].selectedImage = true;
        }
        this.selectedImgExist = true;
        if (this.clickedFrom === 'carousel') {
          if(this.type === 'child'){
            this.imgStockLists.forEach(element=>{
              this.imgList.forEach(el => {
                if(element.id === el.id){
                  this.selectedArtwork = this.imgStockLists.filter(el => {
                    if (el.selectedImage === true) {
                      return el
                    }
                  })
                }
              })
            })
          }
          else{

            this.selectedArtwork = this.imgList.filter(el => {
              if (el.selectedImage === true) {
                return el
              }
            })
          }


        }
        else {
          this.selectedArtwork = art;
        }
        this.totalChecked = this.imgList.filter(el => {
          if (el.selectedImage === true) {
            return el
          }
        })
        console.log(this.totalChecked)
      }


    }
    else {


      if (art.isParent === true) {
        // this.imgStockLists.forEach(el=>{
        //   if(el.id === art.id){
        //     el.selectedImage = true;
        //     el.isSelected = true
        //   }
        // })
        this.disableApplyBtn = true
        this.getChildList(art.id);
      } else {
        if (this.clickedFrom !== 'carousel') {
          this.imgList.forEach((el) => el.selectedImage = false);

        }
        if (art.selectedImage === true) {
          this.imgList[index].selectedImage = false
        }
        else {
          this.imgList[index].selectedImage = true;
          if (this.activeTab === 'library') {
            this.cloneStockArray.forEach((el) => {
              el['selectedImage'] = false
            })
          }
          else if (this.activeTab === 'stock') {
            this.cloneLibraryArray.forEach((el) => {
              el['selectedImage'] = false
            })
          }
        }
        this.selectedImgExist = true;
        if (this.clickedFrom === 'carousel') {
          this.selectedArtwork = this.imgList.filter(el => {
            if (el.selectedImage === true) {
              return el
            }
          })
        }
        else {
          this.selectedArtwork = art;
        }
        this.totalChecked = this.imgList.filter(el => {
          if (el.selectedImage === true) {
            return el
          }
        })
      }
    }
  }

  /**
   * Get child stock list
   * @param stockId Stock Id
   */
  getChildList(stockId) {
    this.artWorkService.getChildStock(stockId).subscribe((response) => {
      this.selectedImageData = response.data;
      this.type = 'child'
      this.loadMoreForStock = true
      // if(this.selectedImageData){
      //   if(this.selectedImageData.document.bdImage === true){
      //     if(this.selectedImageData.artworkType === "SQUARE"){var width = 400;var height = 400;}
      //     else if(this.selectedImageData.artworkType === "WIDE"){var width = 500; var height =225;}
      //     else if(this.selectedImageData.artworkType === "BANNER"){var width = 500; var height = 165;}
      //     let id = this.selectedImageData.document.id
      //     this.selectedImageData.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;

      //   }
      // }
      this.imgStockLists = response.data.child;
      if (this.imgStockLists) {
        this.imgStockLists.forEach(data => {
          data.isParent = false
          this.sharedService.updateLoader(true);
          if (data.document) {
            if (data.artworkType === "SQUARE") { var width = 200; var height = 200; }
            else if (data.artworkType === "WIDE") { var width = 480; var height = 270; }
            else if (data.artworkType === "BANNER") { var width = 415; var height = 150; }
            else if (data.artworkType === "EBOOK") { var width = 396; var height = 612; }
            let id = data.document.id;
            data.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
            this.imgStockLists.forEach((el) => {
              if (this.selectedArtworkIdComing) {
                if (el.id === this.selectedArtworkIdComing) {
                  el['selectedImage'] = true;
                }
              }
              if (this.selectedImage) {
                if (el.id === this.selectedImage) {
                  el['selectedImage'] = true;
                }
              }
              if(this.clickedFrom === 'carousel'){
                this.selectedArtwork.forEach(element => {
                  if (el.id === element.id) {
                    el['selectedImage'] = true;
                  }
                });
              }
            });
          }
          this.sharedService.updateLoader(false);

        })
      }
      this.imageChangedEvent = null;
      this.croppedImage = '';
    });
  }
  /**
   * To delete stock
   * @param stockId Stock Id
   */

  

  deleteStockMod() {
    let stock = this.stock
    let stockId = stock.id
    if(!stock.isSelected && !stock.selectedImage){
    this.artWorkService.deleteStock(stockId).subscribe(() => {
      this.deleteStockModal = false
      this.imgList = this.imgList.filter((el) => el.id !== stockId);
      this.imgLibraryLists = this.imgLibraryLists.filter((el) => el.id !== stockId);
      this.cloneLibraryArray = this.cloneLibraryArray.filter((el) => el.id !== stockId);
      this.libraryArray = this.libraryArray.filter((el) => el.id !== stockId);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted successfully' });
      this.stock = {}
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }
  }

  deleteStock(stock){
    if(!stock.isSelected && !stock.selectedImage){
     this.deleteStockModal = true
     this.stock = stock
    }
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  //move index 1(b) to index 2(c)
  // console.log(moveArrayItemToNewIndex(["a","b","c","d"], 1, 2));

  loadMore() {
    if (this.activeTab === 'library') {
      this.libraryCounter = ++this.libraryCounter
      this.page = this.libraryCounter
      //  if(this.page === 2){
      //  this.cloneSelectedImage = this.selectedImage
      //  this.selectedImage = {}
      //  }
      this.getImageList(this.page, this.size, `LIBERARY`)
      if (this.totalElementsInLib < (this.page * 10) + 1) {
        this.loadMoreForLib = true
      }
    }
    else {
      this.stockCounter = ++this.stockCounter
      this.page = this.stockCounter
      // if(this.page === 2){
      //   this.cloneSelectedImage = this.selectedImage
      //   this.selectedImage = {}
      //   }
      this.getImageList(this.page, this.size, `STOCK`)
    }
    if (this.totalElementsInStock < (this.page * 10) + 1) {
      this.loadMoreForStock = true
    }
  }

  selectAndApply(art, index){
    this.updateSelectedImage(art, index)
    this.closePanel('update');
  }

  selectFiles(event, type) {
    // console.log(this.progressInfoData.length,"this.progressInfoData.length");
    if (!this.progressInfoData) {
      // this.progressInfos.push()
      this.exType = type;
      if (type === "audio") {
        this.progressAudioInfos = [];
        this.hideAudioContainer = false;
      }
    }
    this.selectedFiles = event
    this.uploadFiles(type);
  }
  uploadFiles(type) {
    console.log(type);

    if (!this.progressInfoData) {
      if (type === "audio") {
        this.uploadAudio(0, this.selectedFiles);
      }
    } else {
      if (type === "audio") {
        this.uploadAudio(this.progressInfoData.length, this.selectedFiles);
      }
    }
  }

  uploadAudio(idx, file) {
    this.progressAudioInfos[idx] = {
      value: 0,
      fileName: file.name,
      exType: "audio",
    };
    const splittedFileName = file.name.split(".");
    const formData = file;
    // formData.append("file", file);
    this.sharedService.updateLoader(false);
    this.artWorkService.uploadBase64Img(formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressAudioInfos[idx].value = Math.round(
            (100 * event.loaded) / event.total
          );
          if(this.progressAudioInfos[idx].value === 100){
            this.progressAudioInfos[idx].value = 99
          }
          this.sharedService.updateProgressBarData(this.progressAudioInfos);
        } else if (event instanceof HttpResponse) {
          const stockData = {
            artworkType: this.artwork,
            documentId: event.body.data.id,
            parentId: null,
            type: 'LIBERARY'
          };
          this.sharedService.updateLoader(false);
          this.uploadStock(stockData, event.body.data);
          this.hideAudioContainer = true;

          setTimeout(() => {
            this.progressAudioInfos[idx].value = 0;
            this.progressAudioInfos.forEach((element, index) => {
              if (element.value === 0) this.progressAudioInfos.splice(index, 1);
            });
            this.sharedService.updateProgressBarData(this.progressAudioInfos);
            // localStorage.setItem("progress", JSON.stringify(this.progressAudioInfos));
          }, 500);
        }
        this.sharedService.updateLoader(false);
      },
      (error) => {
        this.progressAudioInfos[idx].value = 0;
      }
    );
    this.sharedService.updateLoader(false);
  }

  /**
   * To get subscription data
   */
  getProgressDataData() {
    this.subscriptonProgressData.add(
      this.sharedService.updateProgressBar$.subscribe((data: any) => {
        this.progressInfoData = data;
        if (this.progressInfoData.length) {
          this.progressInfoData.forEach((element) => {
            this.exType = element.exType;
          });
          if (this.exType === "audio") {
            this.progressAudioInfos = this.progressInfoData;
          }
          this.progressAudioInfos.forEach((element, i) => {
            this.hideAudioContainer = false;
          });
        }
      })
    );
  }

}
