import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SharedService {
    updateSideBar = new Subject();
    updateSideBar$ = this.updateSideBar.asObservable();

    updateUserRole = new Subject();
    updateUserRole$ = this.updateUserRole.asObservable();

    updateProgressBar = new Subject();
    updateProgressBar$ = this.updateProgressBar.asObservable();

    updateProgressBarMedia = new Subject();
    updateProgressBarMedia$ = this.updateProgressBarMedia.asObservable();

    updateSideBarControl = new Subject();
    updateSideBarControl$ = this.updateSideBarControl.asObservable();

    updatePopOver = new Subject();
    updatePopOver$ = this.updatePopOver.asObservable();

    panelState = new Subject();
    panelState$ = this.panelState.asObservable();


    loaderState = new Subject();
    loaderState$ = this.loaderState.asObservable();

    loaderState1 = new Subject();
    loaderState1$ = this.loaderState1.asObservable();

    switchUserData = new Subject();
    switchUserData$ = this.switchUserData.asObservable();

    updateGroupData = new Subject();
    updateGroupData$ = this.updateGroupData.asObservable();

    updateGroup = new Subject();
    updateGroup$ = this.updateGroup.asObservable();

    updateVideo = new Subject();
    updateVideo$ = this.updateVideo.asObservable();

    updateAudio = new Subject();
    updateAudio$ = this.updateAudio.asObservable();

    updateError = new Subject();
    updateError$ = this.updateError.asObservable();

    openStaticPanel = new Subject();
    openStaticPanel$ = this.openStaticPanel.asObservable();

    sendStaticPanel = new Subject();
    sendStaticPanel$ = this.sendStaticPanel.asObservable();

    private showPercentModalSource = new Subject<boolean>();
    showPercentModal$ = this.showPercentModalSource.asObservable();
  
    constructor(
        ) { }
        
    openModal(): void {
      this.showPercentModalSource.next(true);
    }
    closeModal(): void {
      this.showPercentModalSource.next(false);
    }
    
    sendStaticPanelHeader(data) {
        this.sendStaticPanel.next(data)
    }

    openStaticPanelHeader(state) {
        this.openStaticPanel.next(state)
    }

    updateErrorMessage(id) {
        this.updateError.next(id)
    }

    updateVideoDto(id) {
        this.updateVideo.next(id)
    }
    updateAudioDto(id) {
        this.updateAudio.next(id)
    }


    updateDetail(id) {
        this.updateGroupData.next(id)
    }

    updateGroupDetail(id) {
        this.updateGroupData.next(id)
    }

    /**
     * To update sidebar state
     * @param state True/False
     */
    updateSidebarState(state) {
        this.updateSideBar.next(state);
    }

    /**
     * To update user role in the app
     * @param role Role
     */
    updateUserRoleData(role) {
        this.updateUserRole.next(role);
    }

    /**
    * To update user data in the app
    * @param data Data
    */
    updateProgressBarData(data) {
        this.updateProgressBar.next(data);
    }

    /**
     * To update user data in the app
     * @param data Data
     */
    updateProgressBarMediaData(data) {
        this.updateProgressBarMedia.next(data);
    }

    /**
     * To update user role in the app
     * @param state Role
     */
    updateSideBarControlData(state) {
        this.updateSideBarControl.next(state);
    }


    /**
     * To update popover state
     * @param state Show/Hide
     */
    updatePopOverState(state) {
        this.updatePopOver.next(state);
    }

    /**
     * To update panel state
     * @param state Panel State
     */
    updatePanelState(state) {
        if (this.panelState.observers[1]) {
            this.panelState.observers.splice(1, 1);
        }
        this.panelState.next(state);
    }

    /**
     * To update loader across the app
     * @param state Show/Hide
     */
    updateLoader(state) {
        this.loaderState.next(state);
    }

    updateLoader1(state) {
        this.loaderState1.next(state);
    }

    isNumberOnly(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    noSpaceAllow(event){
            const keyCode = event.keyCode || event.which;
            if (keyCode === 32) {
                event.preventDefault();
            }
    }
    /**
     * To update switch user data
     * @param data Switch Data
     */
    updateSwitchUserData(data) {
        this.switchUserData.next(data);
    }

    timeConvert(data: any) {
        var seconds: any = data
        var hours = Math.floor(seconds / (60 * 60));
        seconds -= hours * (60 * 60);
        var minutes = Math.floor(seconds / (60));
        seconds -= minutes * (60);
        seconds = seconds / 100
        if (hours) {
            var seconds: any = data
            const result = new Date(seconds * 1000).toISOString().slice(11, 19);

            return result
        }
        else if (minutes) {
            var seconds: any = data
            const minLength = new Date(seconds * 1000).toISOString().slice(14, 15);
            if (minLength == '0') {
                const result = new Date(seconds * 1000).toISOString().slice(11, 19);
                return result
            }
            else {
                const result = new Date(seconds * 1000).toISOString().slice(11, 19);
                return result
            }
        }
        else {
            var seconds: any = data
            const result = new Date(seconds * 1000).toISOString().slice(11, 19);
            return result
        }
    }

}
