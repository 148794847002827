import { appSvgIcons } from './../../app.svg.icons';
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TabSvgIcons } from 'src/app/tab.icons';

@Directive({ selector: '[appSvgIcon]' })

export class SvgIconsDirective implements OnInit {
    icons = appSvgIcons;
    @Input('appSvgIcon') icon: string;
    tabIcons = TabSvgIcons;
    @Input('TabSvgIcons') tabicons: any;

    constructor(
        public el: ElementRef,
        public renderer: Renderer2
    ) { }

    ngOnInit() {
        this.createSVG();
    }

    createSVG(): void {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.icons[this.icon]);
    }

}
