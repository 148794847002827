import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { WebsocketService } from 'src/app/services/communication/communication.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  visible: boolean = false;
  hideDefaultContainer: boolean = true;
  dailogClose: boolean = false;
  message: string;
  togetmessageVal: any = [];
  questionAsk: any;
  assignuserdeails: boolean = false;
  supportName: any;
  userImg: any;
  questionbyUuser: any;
  @ViewChild('defaultChatView') defaultChatView: ElementRef;
  @ViewChild('chatSection') chatSection: ElementRef;
  userName: any;
  typeingAnimation: boolean = false;
  profileImg = 'assets/images/header/avatar.png'
  logoId: any;
  messageCount: number = 0;
  assignuserName: boolean = false;
  assignuserNameheader: boolean = false;
  userToken: any;
  UserId: any;
  defaultChatViews: boolean = true;
  userIsOnhistory: boolean = false;
  showChatHistory: boolean = false;
  scrollCount: number = 0;
  players: Plyr;
  isVideoPlayed = 0;
  poster: string;
  options: Plyr.Options = {
    // autoplay: true,
    volume: 1,
    muted: false,
    // invertTime: false,
    disableContextMenu: true,
    displayDuration: true,
    settings: [],
    controls: ["play-large", "progress", "fullscreen"],
    captions: {},
    loop: { active: false },
  };
  fileId: any;
  file: any;
  filePath: any;
  formData = new FormData();
  enterFirstTime = '';
  isAlreadyClicked = false;
  recentChatData: any;
  constructor(private renderer: Renderer2, private websocketService: WebsocketService, private el: ElementRef, public messageService: MessageService,) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.defaultChatView?.nativeElement) {
        this.hideDefaultContainer = false;
      }
    });


  }
  ngOnInit(): void {
    this.userToken = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).token : '';
    this.userName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.firstName : '';
    this.UserId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.id : '';
    this.logoId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.logoId : null;
    if (this.logoId) {
      this.profileImg = `${appConfig.imageURL}` + this.logoId;
    } else {
      this.profileImg = 'assets/images/header/avatar.png'
    }
    this.websocketService.messageArray.subscribe((response: any) => {
      const resp = JSON.parse(response);
      if (resp.user_id && resp.user_id == this.UserId) {
        return
      }
      else {
        if (resp.id) {
          this.supportName = resp.user_name;
          this.userImg = resp.user_img;

        }
        else {
          if (this.togetmessageVal.length == 1 && this.enterFirstTime === 'entered') {
            let obj1 = {
              forword: 'NOWCAST is forwarding the chat..',
              support: 'Your chat has been transferred to ' + this.supportName,
            }
            this.togetmessageVal.push(obj1);
            this.assignuserNameheader = true;
            this.enterFirstTime = 'done'
          }
          if (this.enterFirstTime === 'entered') {
            let obj1 = {
              forword: 'NOWCAST is forwarding the chat..',
              support: 'Your chat has been transferred to ' + this.supportName,
            }
            this.togetmessageVal.push(obj1);
            this.assignuserNameheader = true;
            this.enterFirstTime = 'done'
          }
          if (resp && (resp.message || resp.file_url)) {
            let obj = {
              qa: false,
              ans: resp.message ? (resp.message).replace(/[()]/g, '') : undefined,
              assignuserdeails: this.supportName,
              fileUrl: resp.file_url ? resp.file_url : undefined,
              contentType: resp.file_url && resp.file_content_type ? this.checkContentType(resp.file_content_type) : undefined,
            }
            this.togetmessageVal.push(obj);
          }
          setTimeout(() => {
            const div = document.getElementById("chatSection");
            div.scrollTo({
              top: div.scrollHeight,
              behavior: 'smooth'
            });
            this.typeingAnimation = false;
          }, 300)
        }
      }
    })

  }

  openChatdailog() {
    this.visible = true;
    this.websocketService.connect(this.userToken);
  }

  sendMessage(msg: any): void {
    setTimeout(() => {
      this.websocketService.sendMessage(msg);
    }, 1000);
    this.questionAsk = '';
  }

  closeChatdailog() {
    this.visible = false;
  }


  onEnterhit(event: any, el) {
    if (!(this.questionbyUuser.trim())) {
      return
    }
    if (this.questionbyUuser) {
      this.questionbyUuser = this.questionAsk
      this.messageCount = this.messageCount + 1;
      this.assignuserName = true;
      let msg = {
        message_type: "text",
        message_content: {
          "message": this.questionAsk,
        }
      }
      this.sendMessage(msg)
      let obj = {
        que: this.questionbyUuser,
        qa: true
      }
      this.togetmessageVal.push(obj);
      this.typeingAnimation = true;
      setTimeout(() => {
        const div = document.getElementById("chatSection");
        div.scrollTo({
          top: div.scrollHeight,
          behavior: 'smooth'
        });
        this.assignuserdeails = this.messageCount === 1 ? true : false;
      }, 300)
      setTimeout(() => {
        this.assignuserdeails = this.messageCount === 1 ? true : false;
      }, 5000)
    }
  }

  defaultQuestion(qes: string) {
    this.assignuserName = true;
    this.assignuserNameheader = true;
    this.isAlreadyClicked = true;
    this.typeingAnimation = true;
    let msg = {
      message_type: "text",
      message_content: {
        "message": qes,
      }
    }
    this.sendMessage(msg);
    let obj = {
      que: qes,
      qa: true
    }
    this.togetmessageVal.push(obj);
  }

  hasLink(gptmsg): boolean {
    const urlRegex = /(http:\/\/|https:\/\/)\S+/gi;
    return urlRegex.test(gptmsg);
  }
  getFormattedString(gptmsg): string {
    if (this.hasLink(gptmsg)) {
      const urlRegex = /(http:\/\/|https:\/\/)\S+/gi;
      let link = gptmsg.match(urlRegex)[0];
      if (link.endsWith(".")) {
        link = link.substring(0, link.length - 1);
      }
      let viewURL = link;
      viewURL = viewURL.replace('http://', '');
      viewURL = viewURL.replace('https://', '');
      viewURL = viewURL.replace('www.', '');
      return gptmsg.replace(link, `<a target="_blank" href="${link}">${viewURL}</a>`);
    } else {
      return gptmsg;
    }
  }

  gethistoryData() {
    this.showChatHistory = true;
    this.userIsOnhistory = true;
    this.recentChatData = this.togetmessageVal;
    this.togetmessageVal = [];
    const byTogel = true;
    this.getChatWindowHistory(this.userToken, this.UserId, byTogel);
  }
  getChatWindowHistory(token: any, userId: any, byTogel: boolean, offset?: any, limt?: any,) {
    let page_param = -1;
    this.websocketService.getChatWindowHistory(token, userId, offset, limt, page_param).subscribe((response) => {
      this.defaultChatViews = false;
      this.assignuserNameheader = true;
      let array = response.results.data;
      if (byTogel) {
        setTimeout(() => {
          const div = document.getElementById("chatSection");
          div.scrollTo({
            top: div.scrollHeight,
            behavior: 'smooth'
          });
        }, 300)
      }
      array.forEach((ele: any) => {
        let obj = {}
        if (ele.is_support_user || ele.user_id != this.UserId) {
          obj = {
            qa: false,
            ans: ele.message ? ele.message : undefined,
            assignuserdeails: ele.user_name,
            fileUrl: ele.file_message ? ele.file_message : undefined,
            contentType: ele.file_message && ele.file_content_type ? this.checkContentType(ele.file_content_type) : undefined,
          }
        }
        else {
          obj = {
            que: ele.message ? ele.message : undefined,
            qa: true,
            fileUrl: ele.file_message ? ele.file_message : undefined,
            contentType: ele.file_message && ele.file_content_type ? this.checkContentType(ele.file_content_type) : undefined,
          }
        }
        if (byTogel) {
          this.togetmessageVal.push(obj);
        }
        else {
          this.togetmessageVal.unshift(obj);
        }
      })
    })

  }

  onScrollUp() {
    if (!this.defaultChatViews) {
      this.scrollCount = this.scrollCount + 1;
      const offset = this.scrollCount * 10;
      const limt = 10;
      const byTogel = false;
      //  this.getChatWindowHistory(this.userToken,this.UserId,byTogel,offset,limt); 
    }
  }

  hidehistoryData() {
    this.defaultChatViews = true;
    this.togetmessageVal = this.recentChatData;
    // this.togetmessageVal = [];
    this.assignuserNameheader = false;
    this.assignuserdeails = true;
  }
  checkContentType(contentType) {
    if (contentType && contentType.includes('image')) {
      return 'image';
    } else if (contentType && contentType.includes('video')) {
      return 'video';
    }
  }

  completeVideo(event: Plyr.PlyrEvent) {
    this.isVideoPlayed = 0;
  }
  stateChange(event: Plyr.PlyrEvent) {
    // console.log('stateChange', event);
  }
  playedFullScreen(event: Plyr.PlyrEvent) {
    // console.log('fullscreen', event);
  }

  videoPlayed(event: Plyr.PlyrEvent) {
    this.isVideoPlayed = this.isVideoPlayed + 1;
  }

  openFullscreen(imgUrl: any) {
    const img = document.querySelector("img");
    const fullScreenImg = document.getElementById("fullscreen-image") as HTMLImageElement;

    if (img && fullScreenImg) {
      fullScreenImg.src = imgUrl;
      fullScreenImg.style.display = "block";

      if (fullScreenImg.requestFullscreen) {
        fullScreenImg.requestFullscreen();
      }
    }
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape" || event.key === "Esc") {
        this.exitFullscreen();
      }
    });

    // Exit full-screen when clicking on the full-screen image
    fullScreenImg.addEventListener("click", () => {
      this.exitFullscreen();
    });
  }

  exitFullscreen() {
    const fullScreenImg = document.getElementById("fullscreen-image");

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    if (fullScreenImg) {
      fullScreenImg.style.display = "none";
    }
  }
  handleClick() {
    document.getElementById('upload-file').click();
  }

  addAttachment(event: any) {
    this.assignuserName = true;
    this.assignuserNameheader = true;
    let fileList: FileList = event.target.files;
    this.file = fileList[0];
    const fileSizeInBytes = this.file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    if (this.file.type != "video/mp4" && this.file.type != "video/quicktime" && this.file.type != "image/png"
      && this.file.type != "image/jpg" && this.file.type != "image/jpeg") {
      this.messageService.add({
        severity: 'error', summary: "Please select the valid format",
      });
      return
    }
    else if ((this.file.type == "video/mp4" || this.file.type == "video/quicktime") && fileSizeInMB > 100) {
      this.messageService.add({
        severity: 'error', summary: "Maximum video size can be 100mb",
      });
      return
    }
    else if ((this.file.type == "image/jpg" || this.file.type == "image/jpeg" || this.file.type == "image/png") && fileSizeInMB > 20) {
      this.messageService.add({
        severity: 'error', summary: "Maximum image size can be 20mb",
      });
      return
    }
    else {
      this.sendfileOnserver()
    }
  }
  sendfileOnserver() {
    this.filePath = this.file.name;
    let data = {
      file: this.file
    }
    Object.keys(data).forEach(key => {
      this.formData.append(key, data[key]);
    })
    this.websocketService.uploadTraningFile(this.formData, this.userToken).subscribe((response: any) => {
      if (response.body && response.body.success && response.body.data) {
        let mage = response.body.data.file
        const fileContentType = response.body.data.file_content_type
        let obj = {
          que: undefined,
          qa: true,
          fileUrl: mage,
          contentType: this.checkContentType(fileContentType)
        }
        this.togetmessageVal.push(obj);
        let msg1 = {
          message_type: "file",
          message_content: {
            "file": mage,
            "file_content_type": fileContentType
          }
        }
        this.websocketService.sendMessage(msg1);
      }
      this.messageCount = this.messageCount + 1;

      // this.assignuserName = true;
      this.typeingAnimation = true;
      setTimeout(() => {
        const div = document.getElementById("chatSection");
        div.scrollTo({
          top: div.scrollHeight,
          behavior: 'smooth'
        });
        this.assignuserdeails = this.messageCount === 1 ? true : false;
      }, 300)
      setTimeout(() => {
        this.assignuserdeails = this.messageCount === 1 ? true : false;
      }, 5000)

    })
  }

  onEnterValue(event) {
    if (this.enterFirstTime === '') {
      this.enterFirstTime = 'entered';
    }

    this.questionbyUuser = event.target.value;
  }
}
