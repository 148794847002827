import { Component, OnInit } from '@angular/core';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { MediaService } from 'src/app/services/media/media.service';
import { AppAnimation } from './../../../../utilities/animations/animations';

@Component({
  selector: 'app-embeds-side-pop-up',
  templateUrl: './embeds-side-pop-up.component.html',
  styleUrls: ['./embeds-side-pop-up.component.css'],
  animations: AppAnimation
})
export class EmbedsSidePopUpComponent implements OnInit {
  medLib = true
  medList = false
  medLibSettings = false
  sidePanel = false;
  overlay = false;
  mainLib = true;
  mainSer = false
  code= '<script id="subsplash-embed-4r5kcvs" type="text/javascript">var target = document.getElementById("subsplash-embed-4r5kcvs");var script = document.createElement("script");script.type = "text/javascript";script.onload = function() {subsplashEmbed("+hjcm/lb/li/+4r5kcvs?embed&branding&1639390709469","https://subsplash.com/","subsplash-embed-4r5kcvs");};script.src = "https://dashboard.static.subsplash.com/production/web-client/external/embed-1.1.0.js";target.parentElement.insertBefore(script, target);</script>'
  constructor(
    public mediaService : MediaService,
    public libraryService : LibraryServiceService
  ) { }

  ngOnInit(): void {
    this.mediaService.embedPanel$.subscribe((response:any) => {
      this.overlay = response
      this.sidePanel = response
    } )
  }

  closePanel(){
    this.overlay = false
    this.sidePanel = false
    this.mediaService.openEmbedPanel(false)
  }

  typeLib(){
    this.mainLib = true
    this.mainSer = false
  }

  typeList(){
    this.mainLib = false
    this.mainSer = true
  }

  showMedLibSettings(){
    this.medLibSettings = true
    this.medLib = false
    console.log(`abc`)
  }

  closeMedLibSettings(){
    this.medLibSettings = false
    this.medLib = true
  }
  
  openPanel(mediaLib) {
    this.mediaService.openEmbedPanel(false)
    this.libraryService.updatePanel({ showPanel: true , clickedFrom : mediaLib})
  }

}
