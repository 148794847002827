import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { ResourceService } from '../../resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class EbookreaderService {

  constructor(public resourceService: ResourceService,
    public http : HttpClient) { }
    panel = new Subject();
    panel$ = this.panel.asObservable();
    sendUpdate = new Subject()
    sendUpdate$ = this.sendUpdate.asObservable()

    sendUpdateToCreate(state){
      this.sendUpdate.next(state)
    }

    updatePanel(state){
      this.panel.next(state)
    }


    sendMediaToNotification = new Subject()
    sendMediaToNotification$ = this.sendMediaToNotification.asObservable()

    sendMediaToPushNotification(data){
      this.sendMediaToNotification.next(data)
    }

    attachNotification = new Subject()
    attachNotification$ = this.attachNotification.asObservable()

    attachMediaNotifiaction(data){
      this.attachNotification.next(data)
    }

    embedPanel = new Subject()
    embedPanel$ = this.embedPanel.asObservable()

    openEmbedPanel(data){
      this.embedPanel.next(data)
    }

    mostRecentMediaEmbed = new Subject()
    mostRecentMediaEmbed$ = this.mostRecentMediaEmbed.asObservable()

    openMostRecentMediaEmbed(data){
      this.mostRecentMediaEmbed.next(data)
    }

    eventEmbed = new Subject()
    eventEmbed$ = this.eventEmbed.asObservable()

    openEventEmbed(data){
      this.eventEmbed.next(data)
    }

    mobileEmbed = new Subject()
    mobileEmbed$ = this.mobileEmbed.asObservable()

    openMobileEmbed(data){
      this.mobileEmbed.next(data)
    }


    getCollectiondata(page, size,search) {
      const filter = search ? `&searchkey=${search}` : ''
      const getCollectiondataUrl = `${appConfig.getcollectionData}/allEbookReaderWithCatalogue?page=${page}${filter}&size=${size}`;
      return this.resourceService.getData(getCollectiondataUrl);
    }

    getEbooklist(page, size, search) {
      const filter = search ? `&searchkey=${search}` : ''
      const getEbooklistUrl = `${appConfig.getEbook}/getAllEbookList?page=${page}&size=${size}${filter}`;
      return this.resourceService.getData(getEbooklistUrl);
    }
    createCollection(data) {
      const createCollectionUrl = `${appConfig.getcollectionData}`;
      return this.resourceService.postData(createCollectionUrl, data);
    }

    createEbook(data) {
      const createEbookUrl = `${appConfig.getEbook}/ebook`;
      return this.resourceService.postData(createEbookUrl, data);
    }


    deleteCollection(filter) {
      const deleteCollectionUrl = `${appConfig.getcollectionData}?${filter}`;
      return this.resourceService.deleteData(deleteCollectionUrl);
    }

    deleteEbookItem(id) {
      const deleteEbookUrl = `${appConfig.getEbook}/delete?ids=${id}`;
      return this.resourceService.deleteData(deleteEbookUrl);
    }

    updateCollection(id, data, type) {
      const updateCollectionUrl = (type === 'PUBLISH') ? `${appConfig.getcollectionData}/publishEbookReader/${id}` :
        `${appConfig.getcollectionData}/ebookReader/${id}`;
      return this.resourceService.putData(updateCollectionUrl, data);
    }


    updateEbook(id, data, type) {
      const updateCollectionUrl = (type === 'PUBLISH') ? `${appConfig.getEbook}/publishEbook/${id}` :
        `${appConfig.getEbook}/updateEbook/${id}`;
      return this.resourceService.putData(updateCollectionUrl, data);
    }




    getEbookreaderById(ebookReaderId) {
      const getEbookreaderUrl = `${appConfig.getcollectionData}/getEbookReader/${ebookReaderId}`;
      return this.resourceService.getData(getEbookreaderUrl);
    }


    getEbookbyId(id) {
      const getEbookbyIdUrl = `${appConfig.getEbook}/${id}`;
      return this.resourceService.getData(getEbookbyIdUrl);
    }




    duplicateEbook(id) {
      const duplicateItemUrl = `${appConfig.getEbook}/duplicateEbook/${id}`;
      return this.resourceService.postData(duplicateItemUrl, {});
    }


    EbookAddtoFolder(catalogId,ebookIds){
      const addToFolderebookUrl = `${appConfig.getEbook}/${catalogId}?${ebookIds}`;
      return this.resourceService.putData(addToFolderebookUrl);
    }

    EbookReaderAddtoFolder(catalogId,ebookReaderId){
      const addToFolderebookUrl = `${appConfig.getcollectionData}/${catalogId}?${ebookReaderId}`;
      return this.resourceService.putData(addToFolderebookUrl);
    }

    changeCollection(filter, data){
      const changeCollectionUrl = `${appConfig.getcollectionData}/ids/changeEbookReader?${filter}`;
      return this.resourceService.putData(changeCollectionUrl, data);
    }

  moveEbook(eBookIds, data){
    const moveebookUrl = `${appConfig.getEbook}/move?eBookIds=${eBookIds}&ebookReaderId=${data.ebookReaderId}`;
    return this.resourceService.putData(moveebookUrl, data);
  }




  getEbookandbook(page, size, search) {
    const filter = search ? `&searchkey=${search}` : ''
    const getEbookandbookUrl = `${appConfig.getEbook}?page=${page}&size=${size}${filter}`;
    return this.resourceService.getData(getEbookandbookUrl);
  }

  deleteEbbook(filter, type) {
    const deleteEbbookmUrl = type === 'ebook' ? `${appConfig.getEbook}/deleteEbookFromEbookReader?${filter}` : `${appConfig.getEbook}/delete?${filter}`;
    return this.resourceService.deleteData(deleteEbbookmUrl);
  }


  deleteMediaDoc(mediaId, docId, type) {
    const itemType = (type === 'video' ? 'videoId' : (type === 'audio' ? 'audioId' : 'documentId'));
    const deleteDocUrl = `${appConfig.getEbook}/${type}/${mediaId}?${itemType}=${docId}`;
    return this.resourceService.deleteData(deleteDocUrl);
  }



  deleteebooktems(filter, type) {
    const deleteMediaItemUrl = type === 'series' ? `${appConfig.getcollectionData}?${filter}` : `${appConfig.getEbook}/delete?${filter}`;
    return this.resourceService.deleteData(deleteMediaItemUrl);
  }

  }


