<div class="panel-overlay" [ngClass]="{'panel':opacity}" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">



    <div class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">
            <h4
                *ngIf="clickedFrom !== 'tvApp' && clickedFrom !== 'notification' && clickedFrom !== 'mediaLib' && clickedFrom !== 'eventEmbed' && clickedFrom !== 'mobileApp' && clickedFrom !== 'mobile' && clickedFrom !== 'podcast' && clickedFrom !== 'ebookItem' && clickedFrom !== 'channelItem' && clickedFrom !== 'videoItem' && clickedFrom !== 'albumItem' && clickedFrom !== 'calendarItem'">
                Add to Folder </h4>
            <h4 *ngIf="clickedFrom === 'mobile'">Add to carousel</h4>
            <h4 *ngIf="clickedFrom === 'tvApp'">Content Source</h4>
            <h4 *ngIf="clickedFrom === 'notification'">Attach to Notification</h4>
            <h4 *ngIf="clickedFrom === 'mediaLib'">Video Series or Folder</h4>
            <h4 *ngIf="clickedFrom === 'eventEmbed'">Events Embed</h4>
            <h4 *ngIf="clickedFrom === 'podcast'">Add to Podcast</h4>
            <h4 *ngIf="clickedFrom === 'ebookItem'">Ebooks</h4>
            <h4 *ngIf="clickedFrom === 'channelItem'">Channels</h4>
            <h4 *ngIf="clickedFrom === 'videoItem'">Recent Videos</h4>
            <h4 *ngIf="clickedFrom === 'albumItem'">Recent Songs</h4>
            <h4 *ngIf="clickedFrom === 'calendarItem'">Recent Events</h4>
            <img class="cross-icon-img" style="cursor: pointer;" (click)="closePanel('cancel');"
                src="./../../../../assets/images/library/close.svg" alt="">
        </div>
    </div>

    <div *ngIf="showOptions" class="app-filters d-flex justify-content-center pt-1">
        <button *ngIf="clickedFrom === 'mobile'" (click)="sendData(selectedItems)"
            class="btn btn-white mr-10">Apply</button>
        <button *ngIf="clickedFrom !== 'mobile'"
            [ngClass]="[selectedMediaSeriesItems.length==0 && selectedStreamItems.length==0 && selectedMediaItems.length==0 && selectedLinkItems.length==0 && selectedFolderItems.length==0 && selectedRssItems.length==0 && selectedEbookItem.length==0 && selectedAlbumItems.length==0 && selectedSongsItems.length==0 && selectedEventItems.length==0 && selectedCalenderItems.length==0  && selectedEbookItems.length==0 && selectedChannelItems.length==0? 'disable' : '']"
            (click)="sendData(selectedItems)" class="btn btn-white mr-10">Apply</button>
        <button (click)="closePanel('cancel')" class="btn btn-white">Cancel</button>
    </div>

    <div *ngIf="showOptions  && clickedFrom !== 'calendarItem' && clickedFrom !== 'albumItem' && clickedFrom !== 'videoItem' && clickedFrom !== 'ebookItem' "
        class="table addtolistTable">
        <form>
            <!-- <div class="top-inputs pb-3"> -->
            <div class="p-3">
                <table
                    *ngIf="routeFrom !== 'music' && clickedFrom !=='podcast' && clickedFrom !=='ebookItem' && clickedFrom !== 'channelItem' && clickedFrom !== 'videoItem' && clickedFrom !== 'albumItem' && clickedFrom !== 'calendarItem' ">

                    <tr *ngIf="tvMed">
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" id="mediaItem"
                                    (change)="getMediaItems(1,10);emptySearch();showAllMediaItem = false"
                                    name="optradio" [checked]="mediaitemChecked">
                                <label for="mediaItem" [ngClass]="{activebtn: mediaItems}" name="optradio">Video</label>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="tvMed && featureControlService.showVOD">
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" id="mediaItem"
                                    (change)="getMediaItems(1,10);emptySearch();showAllMediaItem = false"
                                    name="optradio" [checked]="mediaitemChecked">
                                <label for="mediaItem" [ngClass]="{activebtn: mediaItems}" name="optradio">Video</label>
                            </div>
                        </td>
                    </tr>

                    <!-- handle UI -->

                    <td *ngIf="clickedFrom === 'eventEmbed'">
                        <div class="d-flex align-items-center">
                            <input type="radio" (change)="getCalenderItem(1,10);emptySearch()" name="optradio"
                                id="calender" [checked]="claenderChecked">
                            <label for="calender" [ngClass]="{activebtn: calender}" name="optradio">Calendar</label>
                        </div>
                    </td>
                    <td *ngIf="clickedFrom === 'mediaLib'">
                        <div class="d-flex align-items-center">
                            <input type="radio" (change)="getMediaSeries(1,10);emptySearch();showallMediaSeries = false"
                                id="mediaSeries" name="optradio" [checked]="mediaSeries" checked>
                            <label for="mediaSeries" [ngClass]="{activebtn: mediaSeries}" name="optradio">Video
                                Series</label>
                        </div>
                    </td>
                    <td *ngIf="clickedFrom === 'mediaLib'">
                        <div class="d-flex align-items-center test">
                            <input type="radio" (change)="getListItem(1,10);emptySearch();showallFolder = false"
                                id="folder" name="optradio" [checked]="folderChecked">
                            <label for="folder" [ngClass]="{activebtn: listItems}" name="optradio">Folder</label>
                        </div>
                    </td>
                    <tr
                        *ngIf="clickedFrom !== 'mediaLib' && clickedFrom !== 'ebook' && clickedFrom !== 'eventEmbed' && !tvMed">

                        <td *ngIf="clickedFrom !== 'tvApp' && featureControlService.showVOD">
                            <div class="d-flex align-items-center">
                                <input type="radio"
                                    (change)="getMediaSeries(1,10);emptySearch();showallMediaSeries = false"
                                    id="mediaSeriesId" name="optradio" [checked]="mediaSeriesChecked">
                                <label for="mediaSeriesId" [ngClass]="{activebtn: mediaSeries}" name="optradio">Video
                                    Series</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showFolder && (clickedFrom !== 'tv' && panel !== 'tv-panel')">
                            <div class="d-flex align-items-center">
                                <input type="radio" (change)="getListItem(1,10);emptySearch();showallFolder = false"
                                    id="folder" name="optradio" [checked]="folderChecked">
                                <label for="folder" [ngClass]="{activebtn: listItems}" name="optradio">Folder</label>
                            </div>
                        </td>
                        <td
                            *ngIf="clickedFrom !== 'tvApp' && featureControlService.showEvent && clickedFrom !== 'tv' && panel !== 'tv-panel'">
                            <div class="d-flex align-items-center">
                                <input type="radio" (change)="getAllEventItem(1,10);emptySearch();showAllEvents = false"
                                    name="optradio" id="event" [checked]="EventChecked">
                                <label for="event" [ngClass]="{activebtn: events}" name="optradio">Event</label>
                            </div>
                        </td>
                    </tr>
                    <tr style="width: 360px;display: flex;flex-wrap: wrap;"
                        *ngIf="clickedFrom !== 'tvApp' && clickedFrom !== 'mediaLib' && clickedFrom !== 'ebook' && clickedFrom !== 'eventEmbed' && clickedFrom !== 'tv' && panel !== 'tv-panel' && !tvMed">
                        <td *ngIf="featureControlService.showVOD">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="mediaItem"
                                    (change)="getMediaItems(1,10);emptySearch();showAllMediaItem = false"
                                    name="optradio" [checked]="mediaitemChecked">
                                <label for="mediaItem" [ngClass]="{activebtn: mediaItems}" name="optradio">Video</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showMusic">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="album" (change)="getAlbumList(1 , 5);emptySearch();"
                                    name="optradio" [checked]="albumChecked">
                                <label for="album" [ngClass]="{activebtn: album}" name="optradio">Album</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showMusic">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="song" (change)="getAllSongList(1,10);emptySearch();"
                                    name="optradio" [checked]="songChecked">
                                <label for="song" [ngClass]="{activebtn: song}" name="optradio">Song</label>
                            </div>
                        </td>
                        <!-- <tr
                        *ngIf="clickedFrom !== 'tvApp' && clickedFrom !== 'mediaLib' && clickedFrom !== 'ebook' && clickedFrom !== 'eventEmbed' && !tvMed"> -->
                        <td *ngIf="featureControlService.showEvent">
                            <div class="d-flex align-items-center">
                                <input type="radio" (change)="getCalenderItem(1,10);emptySearch();" name="optradio"
                                    id="calender" [checked]="claenderChecked">
                                <label for="calender" [ngClass]="{activebtn: calender}" name="optradio">Calendar</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showLinks">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="link" [checked]="linkChecked"
                                    (change)="getLinkItems(1,10);emptySearch();showAllLink = false" name="optradio">
                                <label for="link" [ngClass]="{activebtn: linkItem}" name="optradio">Link</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showRss">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="rss" [checked]="rssChecked"
                                    (change)="getRssFeedList(1,10);emptySearch();showAllRssItem = false"
                                    name="optradio">
                                <label for="rss" [ngClass]="{activebtn: rss}" name="optradio">RSS</label>
                            </div>
                        </td>
                        <!-- </tr> -->
                        <!-- <tr
                        *ngIf="clickedFrom !== 'tvApp' && clickedFrom !== 'mediaLib' && clickedFrom !== 'ebook' && clickedFrom !== 'eventEmbed' && !tvMed"> -->

                        <td *ngIf="featureControlService.showBookStore">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="ebook" [checked]="ebookChecked"
                                    (change)="getEbooklist(1,10);emptySearch();showAllEbookItem = false"
                                    name="optradio">
                                <label for="ebook" [ngClass]="{activebtn: ebook}" name="optradio">Library</label>
                            </div>
                        </td>
                        <td *ngIf="showLiveStream && featureControlService.showLive">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="livestream"
                                    (change)="getLiveStreamItems(1,10);emptySearch();showAllLiveStream = false"
                                    name="optradio">
                                <label for="livestream" [ngClass]="{activebtn: livestream}" name="optradio">Live
                                    Stream</label>
                            </div>
                        </td>
                        <!-- </tr> -->
                    </tr>

                    <tr
                        *ngIf="clickedFrom !== 'tvApp' && clickedFrom !== 'tv' && panel !== 'tv-panel' && clickedFrom !== 'mediaLib' && clickedFrom !== 'eventEmbed' && clickedFrom === 'ebook' && !tvMed">
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" id="ebookId" [checked]="ebookChecked"
                                    (change)="getEbooklist(1,10);emptySearch();showAllEbookItem = false"
                                    name="optradio">
                                <label for="ebookId" name="optradio">Ebook Series</label>
                            </div>
                        </td>
                    </tr>

                </table>

                <table *ngIf="routeFrom === 'music'">

                    <tr *ngIf="featureControlService.showMusic">
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" id="album" (change)="getAlbumList(1 , 5);emptySearch();"
                                    name="optradio" [checked]="albumChecked">
                                <label for="album" [ngClass]="{activebtn: album}" name="optradio">Album</label>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center" style="margin-left: 7px !important;">
                                <input type="radio" id="song" (change)="getAllSongList(1,10);emptySearch();"
                                    name="optradio" [checked]="songChecked">
                                <label for="song" [ngClass]="{activebtn: song}" name="optradio">Song</label>
                            </div>
                        </td>
                    </tr>


                </table>
                <table *ngIf="clickedFrom === 'podcast'">
                    <tr>
                        <td *ngIf="featureControlService.showVOD">
                            <div class="d-flex align-items-center">
                                <input type="radio"
                                    (change)="getMediaSeries(1,10);emptySearch();showallMediaSeries = false"
                                    id="mediaSeriesId" name="optradio" [checked]="mediaSeries" checked>
                                <label for="mediaSeriesId" [ngClass]="{activebtn: mediaSeries}" name="optradio">Video
                                    Series</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showFolder">
                            <div class="d-flex align-items-center">
                                <input type="radio" (change)="getListItem(1,10);emptySearch();showallFolder = false"
                                    id="folder" name="optradio" [checked]="folderChecked">
                                <label for="folder" [ngClass]="{activebtn: listItems}" name="optradio">Folder</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showMusic">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="album" (change)="getAlbumList(1 , 5);emptySearch();"
                                    name="optradio" [checked]="albumChecked">
                                <label for="album" [ngClass]="{activebtn: album}" name="optradio">Album</label>
                            </div>
                        </td>

                    </tr>


                </table>

                <table *ngIf="clickedFrom === 'ebookItem'">
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" id="ebookItemId" name="optradio" [checked]="ebookItemChecked"
                                    checked>
                                <label for="ebookItemId" name="optradio">Ebook</label>
                            </div>
                        </td>

                    </tr>


                </table>

                <table *ngIf="clickedFrom === 'channelItem'">
                    <tr>
                        <td *ngIf="featureControlService.showCustomChannel && featureControlService.showVOD">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="customChannelItemId" name="optradio"
                                    [checked]="channelItemChecked" (change)="onItemChange('customChannel')" checked>
                                <label for="customChannelItemId" [ngClass]="{activebtn: channelItemChecked}"
                                    name="optradio">Custom</label>
                            </div>
                        </td>
                        <td *ngIf="featureControlService.showM3uChannel">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="channelItemId" name="optradio" [checked]="m3uChannelItemChecked"
                                    (change)="onItemChange('m3uChannel')" checked>
                                <label for="channelItemId" [ngClass]="{activebtn: m3uChannelItemChecked}"
                                    name="optradio">M3U</label>
                            </div>
                        </td>

                    </tr>


                </table>

                <table *ngIf="clickedFrom === 'videoItem' && featureControlService.showVOD">
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" id="mediaItem"
                                    (change)="getMediaItems(1,10);emptySearch();showAllMediaItem = false"
                                    name="optradio" [checked]="mediaitemChecked">
                                <label for="mediaItem" [ngClass]="{activebtn: mediaItems}" name="optradio">Video</label>
                            </div>
                        </td>

                    </tr>


                </table>

                <table *ngIf="clickedFrom === 'albumItem'">
                    <tr>


                        <td *ngIf="featureControlService.showMusic">
                            <div class="d-flex align-items-center">
                                <input type="radio" id="song" (change)="getAllSongList(1,10);emptySearch();"
                                    name="optradio" [checked]="songChecked">
                                <label for="song" [ngClass]="{activebtn: song}" name="optradio">Song</label>
                            </div>
                        </td>

                    </tr>


                </table>

                <table *ngIf="clickedFrom === 'calendarItem'">
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <input type="radio" (change)="getAllEventItem(1,10);emptySearch();showAllEvents = false"
                                    name="optradio" id="event" [checked]="EventChecked">
                                <label for="event" [ngClass]="{activebtn: events}" name="optradio">Event</label>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </form>

    </div>
    <div>
        <!-- <div>
            <div class="app-block" style="box-shadow: none !important;">
                <p class="info-title">Basic Info</p>
                <form>
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" [(ngModel)]="media.title" name="title" required
                            appWhiteSpaceValidator #title="ngModel">
                        <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                            <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                Title is required</p>
                        </ng-container>
                    </div>
                    <!-- <div class="form-group">
                    <label>Subtitle</label>
                    <input type="text" class="form-control" [(ngModel)]="editMedia.subTitle" name="subTitle">
                </div> -->
        <!-- </form>
            </div>
            <div class="app-filters d-flex justify-content-center my-4">
                <button type="button" class="btn btn-white mr-10">Add to List</button>
            </div>
        </div>  -->

        <!-- <div class="panel-content"> -->

        <!-- <div class="app-select-bar">
            <div class="selected-count">
                <img src="/assets/images/common/close.svg" alt="" class="pointer">
                <span>{{selectedItems.length}} Item Selected</span>
            </div>
            <!-- <div class="app-filters m-0">
                <button type="button"
                    >Delete</button>
            </div> -->
        <!-- </div> -->

        <!-- <div class="app-filters d-flex">
            <div class="app-search app-search-big search-box mr-10">
                <input type="text" placeholder="Search" class="form-control w-100 ">
            </div>
            <button class="btn btn-white">Create Media Series</button>

        </div> -->

        <!-- <div *ngIf="mediaSeries">
            <div *ngIf="clickedFrom !== 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" (keyup)="searchMediaSeries($event)" placeholder="Search" class="form-control w-100 " #searchMediaSer >
                </div>
                <button (click)="createMedSeries()" class="btn btn-white">Create Media Series</button>

            </div>

            <div *ngIf="clickedFrom === 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mx-4 w-100">
                    <input (keyup)="searchMediaSeries($event)" type="text" placeholder="Search" class="form-control w-100 " #searchMediaSer >
                </div>

            </div>
        </div>

        <div *ngIf="mediaItems">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchMediaItem($event)" type="text" placeholder="Search" class="form-control w-100 " #searchMediaItems >
                </div>
                <button (click)="createMedItem()" class="btn btn-white">Create Media Item</button>


            </div>
        </div>

        <div *ngIf="listItems">
            <div *ngIf="clickedFrom !== 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box  mr-10">
                    <input type="text" (keyup)="searchList($event)" placeholder="Search" class="form-control w-100 " #searchInput >
                </div>
                <button (click)="createFolItem()" class="btn btn-white">Create Folder Item</button>

            </div>
            <div *ngIf="clickedFrom === 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mx-4 w-100">
                    <input type="text" (keyup)="searchList($event)" placeholder="Search" class="form-control w-100 " #searchInput >
                </div>

            </div>
        </div>

        <div *ngIf="linkItem">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchLink($event)" type="text" placeholder="Search" class="form-control w-100 " #searchLinks >
                </div>
                <button class="btn btn-white">Create Link Item</button>
            </div>
        </div>

        <div *ngIf="album">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchAlbum($event)" type="text" placeholder="Search" class="form-control w-100 " #searchAlbums >
                </div>
                <button class="btn btn-white">Create Album</button>

            </div>
        </div>

        <div *ngIf="song">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchSong($event)" type="text" placeholder="Search" class="form-control w-100 " #searchSongs >
                </div>
                <button class="btn btn-white">Create Song</button>

            </div>
        </div>

        <div *ngIf="rss">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchRss($event)" type="text" placeholder="Search" class="form-control w-100 " #searchRssFeed >
                </div>
                <button class="btn btn-white">Create RSS</button>

            </div>
        </div>

        <div *ngIf="calender">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchCalender($event)" type="text" placeholder="Search" class="form-control w-100 " #searchCalenders >
                </div>
                <button class="btn btn-white">Create Calender</button>

            </div>
        </div>

        <div *ngIf="events">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input (keyup)="searchEvent($event)" type="text" placeholder="Search" class="form-control w-100 " #searchEvents >
                </div>
                <button class="btn btn-white">Create Event</button>

            </div>
        </div> -->



        <div [hidden]="!mediaSeries" *ngIf="featureControlService.showVOD">
            <div [hidden]="clickedFrom === 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10 srch">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchMediaSer>
                </div>
                <button (click)="createMedSeries('MEDIA_SERIES')" class="btn btn-white btnSeries">Add Video
                    Series</button>

            </div>

            <div *ngIf="clickedFrom === 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mx-4 w-100">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchMediaSer>
                </div>
            </div>
        </div>

        <div [hidden]="!mediaItems">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchMediaItems>
                </div>
                <button (click)="createMedItem('MEDIA_ITEM')" class="btn btn-white btnSeries">Add Video</button>
            </div>
        </div>

        <div [hidden]="!ebookItem">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10 ">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchEbookItem>
                </div>
                <button (click)="createMedItem('EBOOK')" class="btn btn-white btnSeries">Add Ebook</button>
            </div>
        </div>

        <div [hidden]="!channelItem">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10 ">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchChannelItem>
                </div>
                <button (click)="createMedItem('CHANNEL')" class="btn btn-white btnSeries">Add Channel</button>
            </div>
        </div>

        <div [hidden]="!listItems">
            <div [hidden]="clickedFrom === 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box  mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchInput>
                </div>
                <button (click)="createFolItem()" class="btn btn-white btnSeries">Add Folder</button>

            </div>
            <div *ngIf="clickedFrom === 'mediaLib'" class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mx-4 w-100">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchInput>
                </div>

            </div>
        </div>

        <div [hidden]="!linkItem">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchLinks>
                </div>
                <button (click)="createItem('link')" class="btn btn-white btnSeries">Add Link Item</button>
            </div>
        </div>

        <div [hidden]="!album" *ngIf="featureControlService.showMusic">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchAlbums>
                </div>
                <button (click)="createItem('album')" class="btn btn-white btnSeries">Add Album</button>

            </div>
        </div>

        <!-- <div [hidden]="!livestream">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchAlbums>
                </div>
                <button (click)="createItem('album')" class="btn btn-white btnSeries">Create LiveStream</button>

            </div>
        </div> -->

        <div [hidden]="!song">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchSongs>
                </div>
                <button (click)="createItem('song')" class="btn btn-white btnSeries">Add Song</button>

            </div>
        </div>

        <div [hidden]="!rss">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchRssFeed>
                </div>
                <button (click)="createItem('rss')" class="btn btn-white btnSeries">Add RSS</button>

            </div>
        </div>

        <div [hidden]="!ebook">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input style="font-size: 15px" type="text" placeholder="Search" class="form-control w-100 "
                        #searchEbook>
                </div>
                <button style="font-size: 14px" (click)="createMedSeries('EBOOK_SERIES')"
                    class="btn btn-white btnSeries">Add Ebook Libraries</button>

            </div>
        </div>

        <div [hidden]="!calender">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchCalenders>
                </div>
                <button (click)="createItem('calen')" class="btn btn-white btnSeries">Add Calendar</button>

            </div>
        </div>

        <div [hidden]="!events">
            <div class="app-filters d-flex justify-content-center">
                <div class="app-search app-search-big search-box mr-10">
                    <input type="text" placeholder="Search" class="form-control w-100 " #searchEvents>
                </div>
                <button (click)="createItem('event')" class="btn btn-white btnSeries">Add Event</button>

            </div>
        </div>
        <ng-container>


            <div *ngIf="createMedIt">
                <div class="app-block" style="box-shadow: none !important;">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em [appSvgIcon]="'back'" (click)="closeMediaItem(type)"></em>
                    </div>

                    <form #createMediaItemForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="mediaItem.title" name="title" required
                                appWhiteSpaceValidator #title="ngModel">
                            <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container>
                        </div>

                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createMediaItemForm.valid" (click)="createMediaItem(mediaItem)"
                                type="button" class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createMediaSer">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em [appSvgIcon]="'back'" (click)="closeMediaSeries()"></em>
                    </div>

                    <form #createMediaSeriesForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="mediaSeriesTitle"
                                name="mediaSeriesTitle" #mediaSeries="ngModel" appWhiteSpaceValidator required>
                            <ng-container *ngIf="mediaSeries.errors && (mediaSeries.touched || mediaSeries.dirty)">
                                <p class="error"
                                    [hidden]="!mediaSeries.errors.required && !mediaSeries.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container>
                        </div>
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button (click)="createMediaSeries()" [disabled]="!createMediaSeriesForm.valid"
                                type="button" class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createListIt">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em [appSvgIcon]="'back'" (click)="closeFolderItem()"></em>
                    </div>

                    <form #createListItemForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="listItem.title" name="title" required
                                appWhiteSpaceValidator #title="ngModel">
                            <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container>
                        </div>
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createListItemForm.valid" (click)="createListItem(listItem)"
                                type="button" class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>


            <!-- create Link -->

            <div *ngIf="createLinkIt">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em (click)="closeItem('link')" [appSvgIcon]="'back'"></em>
                    </div>

                    <form #createLinkItemForm="ngForm" (ngSubmit)="createLink(createLinkItemForm)">
                        <div class="form-group required">
                            <label>Title</label>
                            <input type="text" required class="form-control" [(ngModel)]="LinkTitle" name="LinkTitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" [(ngModel)]="LinkSubtitle" name="LinkSubtitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group required">
                            <label>Link Type</label>
                            <select name="linkType" class="form-control" (change)="status($event)" required
                                [(ngModel)]="Linktype" name="Linktype">
                                <option value="APP_LINK" selected>In app link</option>
                                <option value="WEBSITE">Website</option>
                                <option value="VIDEO">Video</option>
                                <option value="GIVING">Existing Giving or Payment solution</option>
                                <option value="BIBLE">Bible</option>
                                <option value="NOWCAST_GIVING" *ngIf="showGivingCard && showGiving">In App Giving or
                                    Payment solution (stripe account)
                                </option>
                                <option value="CONTACT_US">Contact us</option>
                                <option value="CONTACT">Phone or Email</option>
                            </select>
                            <!-- <input type="text" class="form-control"> -->
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group required" *ngIf="(urlbox==true)">
                            <label>URL</label>
                            <input type="text" required class="form-control" [(ngModel)]="LinkUrl" name="LinkUrl">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>

                        <div class="form-group required" *ngIf="(cntctBox==true)">
                            <label>Phone or Email</label>
                            <input type="text"
                                pattern="^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{5,15})+$"
                                class="form-control" [(ngModel)]="contact" #contacts="ngModel" name="contacts" required>
                            <ng-container *ngIf="contacts.errors && submitted">
                                <p class="error" [hidden]="!contacts.errors.pattern">Please enter a valid Phone or Email
                                </p>
                                <p class="error" [hidden]="!contacts.errors.required">Phone or Email is required
                                </p>
                            </ng-container>
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                              <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                  Title is required</p>
                          </ng-container> -->
                        </div>

                        <div class="app-filters d-flex justify-content-center my-4">
                            <button type="submit" class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>



            <div *ngIf="createRssIt">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em (click)="closeItem('rss')" [appSvgIcon]="'back'"></em>
                    </div>

                    <form #createRssItemForm="ngForm">
                        <div class="form-group required">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="rssTitle" name="rssTitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" [(ngModel)]="rssSubtitle" name="rssSubtitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group">
                            <label>RSS Feed URL</label>
                            <input type="text" class="form-control" [(ngModel)]="rssUrl" name="rssUrl">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createRssItemForm.valid" (click)="createRss()" type="button"
                                class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createSongIt">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em (click)="closeItem('song')" [appSvgIcon]="'back'"></em>
                    </div>

                    <form #createSongItemForm="ngForm">
                        <div class="form-group required">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="songTitle" name="songTitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" [(ngModel)]="songSubtitle" name="songSubtitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <!-- album name list -->
                        <div class="col-md-12 col-12" *ngIf="clickedFrom !== 'albumItem'">
                            <label for="listAlbumname" class="form-label">Select Album</label>
                            <p-dropdown [options]="listAlbumname" [(ngModel)]="albumItemId" name="albumItemId"
                                class="form-control p-0 drpdwnselect" placeholder="Select Album " [showClear]="true">
                            </p-dropdown>
                        </div>


                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createSongItemForm.valid" (click)="createMusic()" type="button"
                                class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createAlbumIt">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em (click)="closeItem('album')" [appSvgIcon]="'back'"></em>
                    </div>

                    <form #createAlbumItemForm="ngForm">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="albumTitle" name="albumTitle">

                        </div>
                        <!-- <div class="form-group">
                            <label>Sub Title</label>
                            <input type="text" class="form-control">

                        </div> -->
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createAlbumItemForm.valid" type="button" (click)="createAlbum()"
                                class="btn btn-white mr-10">Add </button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createCalenderIt">
                <div class="app-block" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em (click)="closeItem('calen')" [appSvgIcon]="'back'"></em>
                    </div>

                    <form #createCalenItemForm="ngForm">
                        <div class="form-group required">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="calenderTitle" name="calenderTitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="form-group">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" [(ngModel)]="calenderSubtitle"
                                name="calenderSubtitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createCalenItemForm.valid" type="button" class="btn btn-white mr-10"
                                (click)="createCalender()">Add</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createEventIt">
                <div class="app-block overlay-overflow" style="box-shadow: none !important;">

                    <div class="d-flex justify-content-between align-items-center">
                        <p class="info-title">Basic Info</p>
                        <em (click)="closeItem('event')" [appSvgIcon]="'back'"></em>
                    </div>

                    <form #createEventItemForm="ngForm">
                        <div class="form-group required">
                            <label>Title</label>
                            <input type="text" class="form-control" [(ngModel)]="EventTitle" name="eventTitle"
                                #eventTitle="ngModel">

                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->

                            <ng-container *ngIf="(EventTitle === null || EventTitle.trim() === '') && eventSubmitted ">
                                <p class="error">
                                    Title is required</p>
                            </ng-container>

                        </div>
                        <div class="form-group">
                            <label>Subtitle</label>
                            <input type="text" class="form-control" [(ngModel)]="EventSubtitle" name="EventSubtitle">
                            <!-- <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                                <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                    Title is required</p>
                            </ng-container> -->
                        </div>

                        <!-- <div class="col-md-12 col-12"> -->
                        <div class="form-group required">
                            <label for="listCalendername" class="">Select Calendar</label>
                            <!-- <p-dropdown   [options]="listCalendername"  [(ngModel)]="calenderId" name="calenderId"  class="form-control p-0 drpdwnselect"  placeholder="Select Calendar " [showClear]="true"></p-dropdown> -->

                            <div>
                                <span class="app-dropdown w-100" style="margin-bottom: 0;margin-right: 0;">
                                    <select class="form-control mb-0 mr-0" [(ngModel)]="calenderId"
                                        [ngModelOptions]="{standalone: true}"
                                        style="width: 100%;margin-bottom: 0;margin-right: 0;">
                                        <option disabled selected value="null">Select</option>
                                        <option *ngFor="let st of listCalendername" value="{{st.id}}">
                                            {{st.title}}
                                        </option>
                                    </select>
                                </span>

                            </div>

                            <ng-container *ngIf="(calenderId === null || calenderId?.trim() === '') && eventSubmitted">
                                <p class="error">
                                    Calendar is required</p>
                            </ng-container>


                        </div>

                        <p class="info-title mt-2">Date and Time</p>
                        <div class="row">
                            <!-- col-lg-3 col-md-4 col-sm-4 col-6 -->
                            <!-- col-3 -->
                            <div class=" col-md-6 col-sm-4 col-6 mb-10">
                                <div class="form-group">
                                    <label>Start Date</label>
                                    <p-calendar [showIcon]="true" [yearNavigator]="true" yearRange="1900:2999"
                                        [minDate]="date" (onSelect)="startDate(EventstartDate);newStartTime()"
                                        [readonlyInput]="true" [(ngModel)]="EventstartDate" name="EventstartDate">
                                    </p-calendar>
                                </div>
                            </div>
                            <div class=" col-md-6 col-sm-4 col-6 mb-10">

                                <div class="form-group">
                                    <label>End Date</label>
                                    <p-calendar (onSelect)="newEndTime()" [showIcon]="true" [yearNavigator]="true"
                                        yearRange="1900:2999" name="endedDate" [readonlyInput]="true"
                                        [minDate]="EventstartDate" [(ngModel)]="EventendDate" name="EventendDate">
                                    </p-calendar>
                                </div>

                                <!-- <div class="form-group">
                                    <label>Start Time</label>
                                    <p-calendar name="startTime" class="cst-date-picker  cst-time-picker"
                                        [timeOnly]="true" hourFormat="12" placeholder="00:00" [showIcon]="false"  [(ngModel)]="EventstartTime" (ngModelChange)="enterStartTime($event)" name="EventstartTime">
                                    </p-calendar>
                                    <span class="info-validion" *ngIf="!invalidStartTime">Please enter a valid time format eg.(10:01 AM)</span>

                                </div> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class=" col-md-6 col-sm-4 col-6">
                                <!-- <div class="form-group">
                                    <label>End Date</label>
                                    <p-calendar [showIcon]="true" [yearNavigator]="true" yearRange="1900:2999"
                                        name="endedDate" [readonlyInput]="true"  [(ngModel)]="EventendDate" (ngModelChange)="enterEndTime($event)" name="EventendDate" ></p-calendar>
                                </div> -->


                                <div class="form-group">
                                    <label>Start Time</label>

                                    <div class="12hr-example">
                                        <input placeholder="0:00" class="form-control frm-cntrl"
                                            aria-label="12hr format" [format]="12" name="startTime"
                                            [(ngModel)]="startTimeData" [ngxTimepicker]="default" readonly>
                                        <ngx-material-timepicker (timeSet)="setTime($event,'startTime')"
                                            [enableKeyboardInput]="true" #default>
                                        </ngx-material-timepicker>
                                    </div>

                                    <!-- <p-calendar name="startTime" (onSelect)="startTime($event);newStartTime()"
                                        class="cst-date-picker  cst-time-picker" [timeOnly]="true" hourFormat="12"
                                        [minDate]="date" placeholder="00:00" [showIcon]="false"
                                        [(ngModel)]="EventstartTime" (ngModelChange)="enterStartTime($event)"
                                        name="EventstartTime">
                                    </p-calendar> -->
                                    <span class="info-validion" *ngIf="!invalidStartTime">Please enter a valid time
                                        format eg.(10:01 AM)</span>

                                </div>


                            </div>
                            <div class=" col-md-6 col-sm-4 col-6">
                                <div class="form-group">
                                    <label>End Time</label>
                                    <!-- <p-calendar name="endTime" class="cst-date-picker  cst-time-picker"
                                        (ngModelChange)="enterEndTime($event)" [minDate]="EventstartTime"
                                        (onSelect)="endTime($event);newEndTime()" [timeOnly]="true" hourFormat="12"
                                        placeholder="00:00" [showIcon]="false" [(ngModel)]="EventendTime"
                                        name="EventendTime">
                                    </p-calendar> -->

                                    <div class="12hr-example">
                                        <input placeholder="0:00" class="form-control frm-cntrl"
                                            aria-label="12hr format" [format]="12" name="endTime"
                                            [(ngModel)]="endTimeData" [ngxTimepicker]="defaults" readonly>
                                        <ngx-material-timepicker (timeSet)="setTime($event,'endTime')"
                                            [enableKeyboardInput]="true" #defaults>
                                        </ngx-material-timepicker>
                                    </div>


                                    <span class="info-validion" *ngIf="!invalidEndTime">Please enter a valid time format
                                        eg.(10:01 AM)</span>
                                    <!-- <input type="time" step='1' min="00:00:00" max="24:00:00" class="form-control" [(ngModel)]="editEvent.endTime" name="endTime"> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="mt-2" >
                            <label class="switch-input" container="body">
                                <input type="checkbox"  [(ngModel)]="Eventpinned" name="Eventpinned"
                                    >
                                <span class="slider"></span>
                            </label>
                         </div> -->

                        <!-- [disabled]="!createEventItemForm.valid" -->
                        <div class="app-filters d-flex justify-content-center my-4">
                            <button [disabled]="!createEventItemForm.valid" type="button" (click)="createEventItem()"
                                class="btn btn-white mr-10">Add</button>
                        </div>
                    </form>
                </div>
            </div>


            <span class="draft-info"
                *ngIf="!createMediaSer && !createMedIt && !createListIt && !createLinkIt && !createRssIt && !createSongIt && !createAlbumIt && !createCalenderIt && !createEventIt && rss || ebook || livestream || song || album || mediaItems || mediaSeries || linkItem || events || calender">
                It is required that the item's status be changed from Draft to either Publish or Schedule before it can
                be chosen.</span>


            <!-- <div class="app-block" style="box-shadow: none !important;" *ngIf="mediaSeries"> -->

            <!-- <div class="app-select-bar">
                    <div class="selected-count">
                        <img src="/assets/images/common/close.svg" alt="" class="pointer">
                        <span>{{selectedItems.length}} Item Selected</span>
                    </div>
                    <!-- <div class="app-filters m-0">
                        <button type="button"
                            >Delete</button>
                    </div> -->
            <!-- </div> -->


            <!-- <div class="app-filters d-flex">
                    <div class="app-search app-search-big search-box mr-10">
                        <input type="text" placeholder="Search" class="form-control w-100 ">
                    </div>
                    <button class="btn btn-white">Create Media Series</button>

                </div> -->

            <div class="app-block" style="box-shadow: none !important;" *ngIf="mediaSeries">
                <div class="app-block-title">
                    <p class="info-title">Video Series</p>
                </div>

                <ul *ngIf="!showallMediaSeries" class="recent-item-list m-0">
                    <li *ngFor="let series of recentSeriesList; let i = index">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': series.squareArtworkImageColour ? series.squareArtworkImageColour : '#e6e6e6'}">
                                    <img [src]="series.squareArtwork ? series.newimg : ''" onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': series.squareArtwork ? series.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="series.squareArtwork ? series.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': series.wideArtwork ? series.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="series.wideArtwork ? series.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': series.bannerArtwork ? series.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="series.bannerArtwork ? series.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-mediaSeries-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(series,'series')"
                                            (dblclick)="dblClickselectMediaSeries(series)">
                                            {{series.title}}</p>
                                    </label>
                                    <!-- <span *ngIf="series.status === 'DRAFT'" style="color: #cc0000;">Draft</span> -->
                                    <div class="recent-chips" *ngIf="series.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div>
                            <div
                                *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'tvApp'&& clickedFrom !== 'podcast'">
                                <span class="form-check">
                                    <input type="checkbox" name="" id="selected-mediaSeries-{{i}}"
                                        class="form-check-input" (change)="selectMediaSeries($event, series)"
                                        [disabled]="!series.itemChecked && popUp || series.status === 'DRAFT'"
                                        [(ngModel)]="series.itemChecked">
                                </span>
                            </div>
                            <div
                                *ngIf="clickedFrom === 'notification' || clickedFrom === 'tvApp' || clickedFrom === 'podcast'">
                                <input type="radio" name="series" [disabled]="series.status === 'DRAFT'"
                                    id="selected-mediaSeries-{{i}}" value="{{series.id}}"
                                    (change)="selectMediaSeries($event, series)">
                            </div>
                        </div>


                    </li>
                    <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                </ul>
                <ul *ngIf="showallMediaSeries" class="recent-item-list m-0">
                    <li *ngFor="let series of recentSeries; let i = index">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': series.squareArtworkImageColour ? series.squareArtworkImageColour : '#e6e6e6'}">
                                    <img [src]="series.squareArtwork ? series.newimg : '' " onload="style.opacity = 1"
                                        style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': series.squareArtwork ? series.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="series.squareArtwork ? series.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': series.wideArtwork ? series.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="series.wideArtwork ? series.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': series.bannerArtwork ? series.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="series.bannerArtwork ? series.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-showallmediaSeries-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(series,'series')"
                                            (dblclick)="dblClickselectMediaSeries(series)">
                                            {{series.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="series.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div
                            *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'tvApp' && clickedFrom !=='podcast'">
                            <span class="form-check">
                                <input type="checkbox" name="" class="form-check-input"
                                    id="selected-showallmediaSeries-{{i}}" (change)="selectMediaSeries($event, series)"
                                    [disabled]="!series.itemChecked && popUp || series.status === 'DRAFT'"
                                    [(ngModel)]="series.itemChecked">
                            </span>
                        </div>
                        <div
                            *ngIf="clickedFrom === 'notification' || clickedFrom === 'tvApp' || clickedFrom ==='podcast'">
                            <input type="radio" name="series" [disabled]="series.status === 'DRAFT'"
                                id="selected-showallmediaSeries-{{i}}" value="{{series.id}}"
                                (change)="selectMediaSeries($event, series)">
                        </div>


                    </li>
                </ul>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showallMediaSeries">
                    <button class="btn btn-white" *ngIf="totalRecentSeries>4" (click)="showMoreMediaSeries()">Show
                        more</button>
                </div>

            </div>
            <!-- </div> -->


            <!--LiveStream-->
            <div class="app-block" style="box-shadow: none !important;" *ngIf="livestream">
                <div class="app-block-title">
                    <p class="info-title">Live Stream</p>
                </div>

                <ul *ngIf="!showAllLiveStream" class="recent-item-list m-0">
                    <li *ngFor="let stream of recentStreamList; let i = index">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">

                                <span class="user-img"
                                    [ngStyle]="{'background-color': stream.squareArtwork ? stream.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="stream.squareArtwork ? stream.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': stream.wideArtwork ? stream.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="stream.wideArtwork ? stream.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': stream.bannerArtwork ? stream.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="stream.bannerArtwork ? stream.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-liveStream-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(stream, 'liveItem')"
                                            (dblclick)="dblClickselectLiveStram(stream)">
                                            {{stream.title}}</p>
                                    </label>
                                    <div class="recent-chips" *ngIf="stream.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div>
                            <div
                                *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'tvApp'&& clickedFrom !== 'podcast'">
                                <span class="form-check">
                                    <input type="checkbox" name="" id="selected-liveStream-{{i}}"
                                        class="form-check-input" (change)="selectLiveStram($event, stream)"
                                        [disabled]="!stream.itemChecked && popUp || stream.status === 'DRAFT'"
                                        [(ngModel)]="stream.itemChecked">
                                </span>
                            </div>
                            <div
                                *ngIf="clickedFrom === 'notification' || clickedFrom === 'tvApp' || clickedFrom === 'podcast'">
                                <input type="radio" name="stream" [disabled]="stream.status === 'DRAFT'"
                                    id="selected-liveStream-{{i}}" value="{{stream.id}}"
                                    (change)="selectLiveStram($event, stream)">
                            </div>
                        </div>


                    </li>

                </ul>
                <ul *ngIf="showAllLiveStream" class="recent-item-list m-0">
                    <li *ngFor="let stream of recentStream; let i = index">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">

                                <span class="user-img"
                                    [ngStyle]="{'background-color': stream.squareArtwork ? stream.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="stream.squareArtwork ? stream.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': stream.wideArtwork ? stream.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="stream.wideArtwork ? stream.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': stream.bannerArtwork ? stream.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="stream.bannerArtwork ? stream.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-showAllLiveStream-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(stream, 'liveItem')"
                                            (dblclick)="dblClickselectLiveStram(stream)">
                                            {{stream.title}}</p>
                                    </label>
                                    <div class="recent-chips" *ngIf="stream.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div
                            *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'tvApp' && clickedFrom !=='podcast'">
                            <span class="form-check">
                                <input type="checkbox" name="" class="form-check-input"
                                    id="selected-showAllLiveStream-{{i}}" (change)="selectLiveStram($event, stream)"
                                    [disabled]="!stream.itemChecked && popUp || stream.status === 'DRAFT'"
                                    [(ngModel)]="stream.itemChecked">
                            </span>
                        </div>
                        <div
                            *ngIf="clickedFrom === 'notification' || clickedFrom === 'tvApp' || clickedFrom ==='podcast'">
                            <input type="radio" name="item" [disabled]="stream.status === 'DRAFT'"
                                id="selected-showAllLiveStream-{{i}}" value="{{stream.id}}"
                                (change)="selectLiveStram($event, stream)">
                        </div>


                    </li>
                </ul>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showAllLiveStream">
                    <button class="btn btn-white" *ngIf="totalRecords>4" (click)="showMoreLiveStream()">Show
                        more</button>
                </div>

            </div>

            <div *ngIf="listItems">

                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Folder</p>
                    </div>
                    <ul *ngIf="showallFolder" class="recent-item-list m-0">
                        <li *ngFor="let item of trendingList; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': item.wideArtwork ? item.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.wideArtwork ? item.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': item.bannerArtwork ? item.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="item.bannerArtwork ? item.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info ellipsis-text">
                                    <label for="selected-folderItem-{{i}}">

                                        <p (click)="goTo(item, 'folderItem')" class="pointer"
                                            (dblclick)="dblClickselectFolder(item)">
                                            {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <!-- <div class="recent-chips"
                                        *ngIf="item.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>   -->
                                </div>
                            </div>
                            <div
                                *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'tvApp' && clickedFrom !== 'podcast'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-folderItem-{{i}}" (change)="selectFolder($event, item)"
                                        [disabled]="!item.itemChecked && popUp" [(ngModel)]="item.itemChecked">
                                </span>
                            </div>
                            <div
                                *ngIf="clickedFrom === 'notification' || clickedFrom === 'tvApp' || clickedFrom === 'podcast' ">
                                <input type="radio" name="item" id="selected-folderItem-{{i}}" value="{{item.id}}"
                                    (change)="selectFolder($event, item)">
                            </div>

                        </li>
                    </ul>

                    <ul *ngIf="!showallFolder" class="recent-item-list m-0">
                        <li *ngFor="let item of totalList;let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': item.wideArtwork ? item.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="item.wideArtwork ? item.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': item.bannerArtwork ? item.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="item.bannerArtwork ? item.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-showallFolderItem-{{i}}">

                                        <p class="pointer ellipsis-text " (click)="goTo(item, 'folderItem')"
                                            (dblclick)="dblClickselectFolder(item)">
                                            {{item.title}}</p>
                                    </label>
                                    <div class="recent-chips" *ngIf="item.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                            <div
                                *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'tvApp'&& clickedFrom !== 'podcast'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-showallFolderItem-{{i}}" (change)="selectFolder($event, item)"
                                        [disabled]="!item.itemChecked && popUp || item.status === 'DRAFT'"
                                        [(ngModel)]="item.itemChecked">
                                </span>
                            </div>
                            <div
                                *ngIf="clickedFrom === 'notification' || clickedFrom === 'tvApp' ||  clickedFrom === 'podcast'">
                                <input type="radio" name="item" [disabled]="item.status === 'DRAFT'"
                                    id="selected-showallFolderItem-{{i}}" value="{{item.id}}"
                                    (change)="selectFolder($event, item)">
                            </div>

                        </li>

                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->

                    </ul>
                </div>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showallFolder">
                    <button class="btn btn-white" *ngIf="totaltrendingList>4" (click)="showmoreFolder()">Show
                        more</button>
                </div>

            </div>


            <div *ngIf="linkItem">

                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Link Items</p>
                    </div>
                    <ul *ngIf="showAllLink" class="recent-item-list m-0">
                        <li *ngFor="let list of linkList; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-linkItem-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'linkItem')"
                                            (dblclick)="dblClickselectLinks(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name="" id="selected-linkItem-{{i}}"
                                        (change)="selectLinks($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-linkItem-{{i}}" value="{{list.id}}"
                                    (change)="selectLinks($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <ul *ngIf="!showAllLink" class="recent-item-list m-0">
                        <li *ngFor="let list of totalLink;let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-showallLinkItem-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'linkItem')"
                                            (dblclick)="dblClickselectLinks(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-showallLinkItem-{{i}}" (change)="selectLinks($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-showallLinkItem-{{i}}" value="{{list.id}}"
                                    (change)="selectLinks($event, list)">
                            </div>

                        </li>
                    </ul>
                </div>
                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showAllLink">
                    <button class="btn btn-white" *ngIf="totalLinkList>4" (click)="showMoreLink()">Show more</button>
                </div>

            </div>

            <div *ngIf="album ">

                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Album</p>
                    </div>
                    <ul class="recent-item-list m-0">
                        <li *ngFor="let list of musicList;let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-albumList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'albumItem')"
                                            (dblclick)="dblClickselectAlbum(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification' && clickedFrom !== 'podcast'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-albumList-{{i}}" (change)="selectAlbum($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification' || clickedFrom === 'podcast'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-albumList-{{i}}" value="{{list.id}}"
                                    (change)="selectAlbum($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>

                <div *ngIf="!showall" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalmusicList>4"
                        (click)="getAlbumList(1,totalAlbumList);showall = true">Show more</button>
                </div>

            </div>

            <div *ngIf="song">

                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Song</p>
                    </div>
                    <ul *ngIf="showAllSongs" class="recent-item-list m-0">
                        <li *ngFor="let list of songList; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-songList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'songItem')"
                                            (dblclick)="dblClickselectSong(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" [(ngModel)]="list.itemChecked"
                                        name="" id="selected-songList-{{i}}" (change)="selectSong($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-songList-{{i}}" value="{{list.id}}"
                                    (change)="selectSong($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <ul *ngIf="!showAllSongs" class="recent-item-list m-0">
                        <li *ngFor="let list of totalSongList; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-totalSongList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'songItem')"
                                            (dblclick)="dblClickselectSong(list)">
                                            {{list.title}}</p>
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-totalSongList-{{i}}" [(ngModel)]="list.itemChecked"
                                        (change)="selectSong($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-totalSongList-{{i}}" value="{{list.id}}"
                                    (change)="selectSong($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>

                <div *ngIf="!showAllSongs" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalsongList>4" (click)="showmoreSongsList()">Show
                        more</button>
                </div>

            </div>

            <div *ngIf="rss">

                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">RSS</p>
                    </div>
                    <ul *ngIf="showAllRssItem" class="recent-item-list m-0">
                        <li *ngFor="let list of rssList;let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-rssList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'rssItem')"
                                            (dblclick)="dblClickselectRss(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name="" id="selected-rssList-{{i}}"
                                        (change)="selectRss($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-rssList-{{i}}" value="{{list.id}}" (change)="selectRss($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <ul *ngIf="!showAllRssItem" class="recent-item-list m-0">
                        <li *ngFor="let list of totalRss;let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-allRssList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'rssItem')"
                                            (dblclick)="dblClickselectRss(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-allRssList-{{i}}" (change)="selectRss($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-allRssList-{{i}}" value="{{list.id}}"
                                    (change)="selectRss($event, list)">
                            </div>

                        </li>
                    </ul>
                </div>

                <div *ngIf="!showAllRssItem" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalrssList>4" (click)="showmoreRssItem()">Show
                        more</button>
                </div>

            </div>


            <div *ngIf="ebookItem">

                <div class="app-block" style="box-shadow: none !important;">
                    <!-- <div class="app-block-title">
                        <p class="info-title">Recent Ebook</p>
                    </div> -->
                    <ul *ngIf="showAllEbook" class="recent-item-list m-0">
                        <li *ngFor="let list of ebookItemList;let i = index">
                            <div class="d-flex align-items-center">
                                <span class="user-img" style="width: 49px;height:77px"
                                    [ngStyle]="{'background-color': list.ebookArtwork ? list.ebookArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.ebookArtwork ? list.ebookArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <div class="recent-info">
                                    <label for="selected-ebookList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'ebItem')"
                                            (dblclick)="dblClickselectEbookItems(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-ebookList-{{i}}" (change)="selectEbookItems($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-ebookList-{{i}}" value="{{list.id}}"
                                    (change)="selectEbookItems($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <ul *ngIf="!showAllEbook" class="recent-item-list m-0">
                        <li *ngFor="let list of totalEbookItemList;let i = index">
                            <div class="d-flex align-items-center">
                                <span class="user-img" style="width: 49px;height:77px"
                                    [ngStyle]="{'background-color': list.ebookArtwork ? list.ebookArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.ebookArtwork ? list.ebookArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <div class="recent-info">
                                    <label for="selected-allEbookList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'ebItem')"
                                            (dblclick)="dblClickselectEbookItems(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-allEbookList-{{i}}" (change)="selectEbookItems($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-allEbookList-{{i}}" value="{{list.id}}"
                                    (change)="selectEbookItems($event, list)">
                            </div>

                        </li>
                    </ul>
                </div>


                <div *ngIf="!showAllEbook" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalebookItemList>4" (click)="showmoreEbookItem()">Show
                        more</button>
                </div>


            </div>

            <div *ngIf="channelItem">
                <div class="app-block" style="box-shadow: none !important;">
                    <!-- <div class="app-block-title">
                        <p class="info-title">Recent Ebook</p>
                    </div> -->
                    <ul *ngIf="!showAllChannel && channelItemChecked" class="recent-item-list m-0">
                        <li *ngFor="let list of channelItemList;let i = index">
                            <div class="d-flex align-items-center">
                                <!-- <span class="user-img" style="width: 49px;height:77px"
                                    [ngStyle]="{'background-color': list.ebookArtwork ? list.ebookArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.ebookArtwork ? list.ebookArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-channelList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'channelItem')"
                                            (dblclick)="dblClickselectEbookItems(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-channelList-{{i}}" (change)="selectChannelItems($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-channelList-{{i}}" value="{{list.id}}"
                                    (change)="selectChannelItems($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <ul *ngIf="!showAllChannel && m3uChannelItemChecked" class="recent-item-list m-0">
                        <li *ngFor="let list of m3uChannelItemList;let i = index">
                            <div class="d-flex align-items-center">
                                <!-- <span class="user-img" style="width: 49px;height:77px"
                                    [ngStyle]="{'background-color': list.ebookArtwork ? list.ebookArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.ebookArtwork ? list.ebookArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-channelList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'channelItem')"
                                            (dblclick)="dblClickselectEbookItems(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <!-- <span class="form-check"> -->
                                <input type="radio" name="list" value="{{list.id}}" id="selected-channelList-{{i}}"
                                    (change)="selectChannelItems($event, list)"
                                    [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                    [checked]="list.itemChecked">
                                <!-- </span> -->
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-channelList-{{i}}" value="{{list.id}}"
                                    (change)="selectChannelItems($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <ul *ngIf="showAllChannel && channelItemChecked" class="recent-item-list m-0">
                        <li *ngFor="let list of totalChannelItemList;let i = index">
                            <div class="d-flex align-items-center">
                                <!-- <span class="user-img" style="width: 49px;height:77px"
                                    [ngStyle]="{'background-color': list.ebookArtwork ? list.ebookArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.ebookArtwork ? list.ebookArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-allChannelList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'channelItem')"
                                            (dblclick)="dblClickselectChannelItems(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-allChannelList-{{i}}" (change)="selectChannelItems($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-allChannelList-{{i}}" value="{{list.id}}"
                                    (change)="selectChannelItems($event, list)">
                            </div>

                        </li>
                    </ul>

                    <ul *ngIf="showAllChannel && m3uChannelItemChecked" class="recent-item-list m-0">
                        <li *ngFor="let list of totalM3uChannelItemList;let i = index">
                            <div class="d-flex align-items-center">
                                <!-- <span class="user-img" style="width: 49px;height:77px"
                                    [ngStyle]="{'background-color': list.ebookArtwork ? list.ebookArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.ebookArtwork ? list.ebookArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-allChannelList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'channelItem')"
                                            (dblclick)="dblClickselectChannelItems(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <!-- <span class="form-check"> -->
                                <input type="radio" name="" name="list" value="{{list.id}}"
                                    id="selected-allChannelList-{{i}}" (change)="selectChannelItems($event, list)"
                                    [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                    [checked]="list.itemChecked">
                                <!-- </span> -->
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-allChannelList-{{i}}" value="{{list.id}}"
                                    (change)="selectChannelItems($event, list)">
                            </div>

                        </li>
                    </ul>
                </div>
                <div *ngIf="!showAllChannel && channelItemChecked"
                    class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalChannelItemList ? totalChannelItemList.length>4 : []"
                        (click)="showmoreChannelItem()">Show
                        more</button>
                </div>
                <div *ngIf="!showAllChannel && m3uChannelItemChecked"
                    class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white"
                        *ngIf="totalM3uChannelItemList ? totalM3uChannelItemList.length>4 : []"
                        (click)="showmoreChannelItem()">Show
                        more</button>
                </div>


            </div>


            <div *ngIf="ebook">

                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Library</p>
                    </div>
                    <ul *ngIf="showAllEbookItem" class="recent-item-list m-0">
                        <li *ngFor="let list of ebookList;let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-ebookList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'libItem')"
                                            (dblclick)="dblClickselectEbook(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-ebookList-{{i}}" (change)="selectEbook($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-ebookList-{{i}}" value="{{list.id}}"
                                    (change)="selectEbook($event, list)">
                            </div>

                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <ul *ngIf="!showAllEbookItem" class="recent-item-list m-0">
                        <li *ngFor="let list of totalEbook;let i = index"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <div class="d-flex align-items-center">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': list.squareArtwork ? list.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.squareArtwork ? list.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': list.wideArtwork ? list.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="list.wideArtwork ? list.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': list.bannerArtwork ? list.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="list.bannerArtwork ? list.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-allEbookList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(list, 'libItem')"
                                            (dblclick)="dblClickselectEbook(list)">
                                            {{list.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="list.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-allEbookList-{{i}}" (change)="selectEbook($event, list)"
                                        [disabled]="!list.itemChecked && popUp || list.status === 'DRAFT'"
                                        [(ngModel)]="list.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="list" [disabled]="list.status === 'DRAFT'"
                                    id="selected-allEbookList-{{i}}" value="{{list.id}}"
                                    (change)="selectEbook($event, list)">
                            </div>

                        </li>
                    </ul>
                </div>

                <div *ngIf="!showAllEbookItem" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalebookList>4" (click)="showmoreEbook()">Show
                        more</button>
                </div>

            </div>



            <div *ngIf="mediaItems">
                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Video</p>
                    </div>
                    <ul *ngIf="showAllMediaItem" class="recent-item-list m-0">
                        <li *ngFor="let recent of recentMedia ; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-MediaItem-{{i}}">
                                        <p class="pointer ellipsis-text" (click)="goTo(recent, 'vidItem')"
                                            (dblclick)="dblClickselectItems(recent)">
                                            {{recent.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips"
                                        *ngIf="recent.isReadyToPublish === false  && recent.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                    <div class="recent-chips"
                                        *ngIf="recent.isReadyToPublish === true && recent.status === 'DRAFT'">
                                        <span tooltip="Ready to Publish" class="brown-bg">Ready</span>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-MediaItem-{{i}}" (change)="selectItems($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                    id="selected-MediaItem-{{i}}" value="{{recent.id}}"
                                    (change)="selectItems($event, recent)">
                            </div>
                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <ul *ngIf="!showAllMediaItem" class="recent-item-list m-0">
                        <li *ngFor="let recent of totalMediaItem ; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-allMediaItem-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(recent, 'vidItem')"
                                            (dblclick)="dblClickselectItems(recent)">
                                            {{recent.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips"
                                        *ngIf="recent.isReadyToPublish === false  && recent.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                    <div class="recent-chips"
                                        *ngIf="recent.isReadyToPublish === true && recent.status === 'DRAFT'">
                                        <span tooltip="Ready to Publish" class="brown-bg">Ready</span>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-allMediaItem-{{i}}" (change)="selectItems($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                    id="selected-allMediaItem-{{i}}" value="{{recent.id}}"
                                    (change)="selectItems($event, recent)">
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showAllMediaItem" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalrecentMedia>4" (click)="showmoreMediaItem()">Show
                        more</button>
                </div>
            </div>



            <div *ngIf="events">
                <div class="app-block" style="box-shadow: none !important;">
                    <div *ngIf="clickedFrom !== 'calendarItem' " class="app-block-title">
                        <p class="info-title">Events</p>
                    </div>
                    <ul *ngIf="showAllEvents" class="recent-item-list m-0">
                        <li *ngFor="let recent of allEventItem ; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-EventItem-{{i}}">
                                        <p class="pointer ellipsis-text" (click)="goTo(recent, 'eventItem')"
                                            (dblclick)="dblClickselectEvent(recent)">
                                            {{recent.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-EventItem-{{i}}" (change)="selectEvent($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT' || recent.status === null "
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                    id="selected-EventItem-{{i}}" value="{{recent.id}}"
                                    (change)="selectEvent($event, recent)">
                            </div>
                        </li>
                        <li *ngIf="!allEventItem.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span style="padding-left: 10px;">No item Exists</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul *ngIf="!showAllEvents" class="recent-item-list m-0">
                        <li *ngFor="let recent of allEventItemList ; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-allEvents-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(recent, 'eventItem')"
                                            (dblclick)="dblClickselectEvent(recent)">
                                            {{recent.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-allEvents-{{i}}" (change)="selectEvent($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                    id="selected-allEventItems-{{i}}" value="{{recent.id}}"
                                    (change)="selectEvent($event, recent)">
                            </div>
                        </li>
                        <li *ngIf="!allEventItemList.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span style="padding-left: 10px;">No item Exists</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showAllEvents" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalallEventItem>4" (click)="showMoreEventList()">Show
                        more</button>
                </div>
            </div>


            <div *ngIf="calender">
                <div class="app-block" style="box-shadow: none !important;">
                    <div class="app-block-title">
                        <p class="info-title">Calendar</p>
                    </div>
                    <ul *ngIf="showAllCalender" class="recent-item-list m-0">
                        <li *ngFor="let recent of calenderItem ; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-calenderItem-{{i}}">
                                        <p class="pointer ellipsis-text" (click)="goTo(recent, 'calendarItem')"
                                            (dblclick)="dblClickselectCalender(recent)">
                                            {{recent.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-calenderItem-{{i}}" (change)="selectCalender($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                    id="selected-calenderItem-{{i}}" value="{{recent.id}}"
                                    (change)="selectCalender($event, recent)">
                            </div>
                        </li>
                        <!-- <li *ngIf="!recentMedia.length" class="no-media">
                            <div class="d-flex align-items-center">
                                <div class="recent-info">
                                    <span>No Recent Media Exists</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <ul *ngIf="!showAllCalender" class="recent-item-list m-0">
                        <li *ngFor="let recent of calenderItemList ; let i = index">
                            <div class="d-flex align-items-center"
                                [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                                <!-- <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span> -->
                                <span class="user-img"
                                    [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-wide"
                                    [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                    <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <span class="user-img-banner"
                                    [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                    *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                    <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                        onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                                </span>
                                <div class="recent-info">
                                    <label for="selected-calenderItemList-{{i}}">

                                        <p class="pointer ellipsis-text" (click)="goTo(recent, 'calendarItem')"
                                            (dblclick)="dblClickselectCalender(recent)">
                                            {{recent.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="recent.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="clickedFrom !== 'notification'">
                                <span class="form-check">
                                    <input type="checkbox" class="form-check-input" name=""
                                        id="selected-calenderItemList-{{i}}" (change)="selectCalender($event, recent)"
                                        [disabled]="!recent.itemChecked && popUp || recent.status === 'DRAFT'"
                                        [(ngModel)]="recent.itemChecked">
                                </span>
                            </div>
                            <div *ngIf="clickedFrom === 'notification'">
                                <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                    id="selected-calenderItemList-{{i}}" value="{{recent.id}}"
                                    (change)="selectCalender($event, recent)">
                            </div>
                        </li>
                    </ul>
                </div>
                <div *ngIf="!showAllEvents" class="app-filters mt-20 d-flex justify-content-center">
                    <button class="btn btn-white" *ngIf="totalcalenderItem>4" (click)="showMoreCalenderList()">Show
                        more</button>
                </div>
            </div>




            <!-- <div *ngIf="showOptions" class="app-filters d-flex justify-content-center py-4">
                <button (click)="sendData(selectedItems)" class="btn btn-white mr-10">Apply</button>
                <button (click)="closePanel()" class="btn btn-white">Cancel</button>
            </div> -->

        </ng-container>
    </div>
</div>



<!--  stripe key modal  -->

<p-dialog *ngIf="stripeKeyModal" header="Stripe Details" [(visible)]="stripeKeyModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div>
        <!-- Are you sure, you want to delete <ng-container *ngIf="deleteItem">selected Tab
        </ng-container>
        <ng-template #deleteItem>{{selectedTab.title}}</ng-template> ? This cannot be undone. -->
        To add NOWCAST Giving tab, you need to add Stripe details first. Would you like to add stripe details?
    </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)=redirectToStripe()>Yes, Continue</button>
        <button type="button" class="btn btn-white" (click)="cancelStripeModal()">No,
            Cancel</button>
    </p-footer>
</p-dialog>