import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/media/media.service';
import { AppAnimation } from './../../../../utilities/animations/animations';


@Component({
  selector: 'app-most-recent-media-sidepanel',
  templateUrl: './most-recent-media-sidepanel.component.html',
  styleUrls: ['./most-recent-media-sidepanel.component.css'],
  animations : AppAnimation
})
export class MostRecentMediaSidepanelComponent implements OnInit {
   
  overlay = false
  sidePanel = false
  val1 = 600
  val2 = 337
  code = '<div class="sap-embed-player"><iframe src="https://subsplash.com/+hjcm/embed/mi/*recent?audio&video&info&logoWatermark&shareable&embeddable"frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div><style type="text/css">div.sap-embed-player{position:relative;width:100%;height:0;padding-top:56.25%;}div.sap-embed-player>iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style>'
  constructor(
    public mediaService : MediaService
  ) { }

  ngOnInit(): void {
    this.mediaService.mostRecentMediaEmbed$.subscribe((response:any) => {
      this.overlay = response
      this.sidePanel = response
    } )
  }

  closePanel(){
    this.mediaService.openMostRecentMediaEmbed(false)
    this.overlay = false
    this.sidePanel = false
  }

}
