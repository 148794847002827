import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LazyLoadEvent, MessageService } from "primeng/api";
import { SubscriptionServiceService } from "src/app/services/subscription/subscription-service.service";
import { Router } from "@angular/router";
import { appConfig } from "src/app/app.config";
import { subscribersCols } from "./../../../modules/customer/customer";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { CustomerService } from "src/app/services/customer/customer.service";
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: "app-subscription-detail",
  templateUrl: "./subscription-detail.component.html",
  styleUrls: ["./subscription-detail.component.css"],
})
export class SubscriptionDetailComponent implements OnInit {
  ListPlanDetails: Array<any>;
  ListAllAssociatedOrg: Array<any>;
  ListPlanDetails2: Array<any>;
  basicFeature: Array<any>;
  standardFeature: Array<any>;
  premiumFeature: Array<any>;
  isLoading = false;
  isView: boolean;
  planAmountBasic: any;
  planAmountStandard: any;
  planAmountPremium: any;
  deleteModal = false;
  selectedSubscriptionPlan: any;
  selectedSubscriptionName: any;
  allSubscribedCustomers: any[] = [];
  subscriptionPlanName: any;
  first = 0;
  first2 = 0;
  page: number = 1;
  rows: number = 10;
  pageAssociatedOrg: number = 1;
  rowsAssociatedOrg: number = 10;
  totalRecords = 0;
  totalRecordsAssociatedOrg = 0;
  rowCount: number;
  signUpFormUrl: any;
  subscriptionPlanId: number;
  // @ViewChild('dropdown') dropdown: ElementRef;
  subcribedCustomers = false;
  allAssociatedOrganizations: any;
  organizations: any;
  cols = subscribersCols;
  rows2: number;
  searchInputData: any;
  @ViewChild("searchInput") searchInput: ElementRef;
  templatePopup = false;
  subscriptionId: any;
  templateId: any;
  templateView = 'desktop';
  templateContent: string;
  templateName: string;
  templateModal = false;
  temp = true

  constructor(
    public subscrptionService: SubscriptionServiceService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    public router: Router,
    public customerService: CustomerService
  ) { }

  ngOnInit(): void {
    // this.getSubscriptionPlanList()
    this.rowCount = 10;
    this.rows2 = 10;
    this.route.data.subscribe((data) => {
      const response = data;
      this.getSubscriptionPlanList(response);
    });
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          if (this.searchInput.nativeElement.value.includes("+")) {
            this.searchInputData = this.searchInput.nativeElement.value.replace(
              /\+/g,
              "%2B"
            );
          } else {
            this.searchInputData = this.searchInput.nativeElement.value;
          }
          this.page = 1;
          this.first = 1;
          this.first2 = 1;
          this.rows = this.rows ? this.rows : 50;
          this.getSubscriptionPlanListWithoutResolver(this.page, this.rows);
        })
      )
      .subscribe();
  }

  openSubcribedCustomers(row) {
    this.rows2 = 10;
    this.first2 = 1;
    this.subscriptionPlanId = row.subscriptionPlanId;
    this.getAllAssociatedOrg(this.subscriptionPlanId, this.first2, this.rows2);
  }

  getAllAssociatedOrg(id, page, size) {
    this.subscrptionService
      .getAllAssociatedOrganizations(id, page, size)
      .subscribe(
        (response: any) => {
          this.totalRecordsAssociatedOrg = response.data.totalElements;
          console.log("test count", this.totalRecordsAssociatedOrg);
          this.allSubscribedCustomers = response.data.content;
          this.subcribedCustomers = true;
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong",
          });
        }
      );
  }

  loadCustomers(event: LazyLoadEvent) {
    this.first = event.first;
    this.page = event.first / event.rows + 1;
    this.rows = event.rows;
    this.getSubscriptionPlanListWithoutResolver(this.page, this.rows);
  }

  loadAssociatedOrg(event: PageEvent) {
    console.log("testtt---", event);
    this.first2 = event.first + 1;
    console.log("test", this.first2);
    this.page = event.first / event.rows + 1;
    this.rows2 = event.rows;
    this.getAllAssociatedOrg(this.subscriptionPlanId, this.page, this.rows2);
  }

  getSubscriptionPlanList(response) {
    // this.subscrptionService.getSubscriptionPlanbylist().subscribe((response: any) => {
    // this.planAmountBasic = response.data[0].planAmount
    // console.log(response.data[0].planAmount);
    // console.log(this.planAmountBasic);
    this.allAssociatedOrganizations = response.data.content;
    // console.log('test orgs--', this.allAssociatedOrganizations)
    this.allAssociatedOrganizations.forEach((el) => {
      // console.log('el--', el)
      this.organizations = el.associatedOrganizations;
    });
    if (response.data.content.length) {
      this.ListPlanDetails = response.data.content;
    } else {
      this.ListPlanDetails = [];
    }
    this.totalRecords = response.data.totalElements;

    this.ListPlanDetails.forEach((el) => {
      this.subscriptionPlanName = el.subscriptionPlanName;
      // this.subscriptionPlanId = el.subscriptionPlanId

      if (el.subscriptionPlanName === "Standard") {
        el.storageValue = "1TB Cloud Storage (VOD)";
      } else if (el.subscriptionPlanName === "Premium") {
        el.storageValue = "5TB Cloud Storage (VOD)";
      } else {
        el.storageValue = "500GB Cloud Storage (VOD)";
      }
    });
  }

  getSubscriptionPlanListWithoutResolver(page, size) {
    this.subscrptionService
      .getSubscriptionPlanbylist(
        page,
        size,
        this.searchInputData ? this.searchInputData : ""
      )
      .subscribe((response: any) => {
        this.ListPlanDetails = response.data.content;
        this.ListPlanDetails.forEach((el) => {
          this.subscriptionPlanName = el.subscriptionPlanName;
          this.totalRecords = response.data.totalElements;

          if (el.subscriptionPlanName === "Standard") {
            el.storageValue = "1TB Cloud Storage (VOD)";
          } else if (el.subscriptionPlanName === "Premium") {
            el.storageValue = "5TB Cloud Storage (VOD)";
          } else {
            el.storageValue = "500GB Cloud Storage (VOD)";
          }
        });
      });
  }

  gotoView(item) {
    if (
      item.subscriptionPlanName === "Basic" ||
      item.subscriptionPlanName === "Standard" ||
      item.subscriptionPlanName === "Premium"
    ) {
      this.router.navigate([
        "/subscription/edit-subscription/" +
        item.subscriptionPlanId +
        "/" +
        item.subscriptionPlanName,
      ]);
    } else {
      this.router.navigate([
        "/subscription/edit-custom-plan/" +
        item.subscriptionPlanId +
        "/" +
        item.subscriptionPlanName,
      ]);
    }
  }

  openCustomPlan() {
    this.router.navigate(["/subscription/create-custom-plan"]);
  }

  openDeleteModal(row) {
    this.selectedSubscriptionPlan = row;
    this.selectedSubscriptionName = row.subscriptionPlanName;
    this.deleteModal = true;
  }

  deleteSubscription() {
    this.subscrptionService
      .deleteSubscription(this.selectedSubscriptionPlan.subscriptionPlanId)
      .subscribe(
        () => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Subscription deleted successfully",
          });
          this.deleteModal = false;
          this.getSubscriptionPlanListWithoutResolver(this.page, this.rows);
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.error ? error.error.message : "Something went wrong",
          });
        }
      );
  }

  copyUrl(el, id) {
    this.signUpFormUrl = `${appConfig.baseURL}/organizationSignup/${id}`;
    navigator.clipboard.writeText(this.signUpFormUrl);
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Url Copied successfully",
    });
  }

  getUpdateState(val: any) {
    this.templatePopup = false;
    this.getSubscriptionPlanListWithoutResolver(this.page, this.rows);
  }

  getTemplateContent(templateId) {
    this.customerService.getContent(templateId).subscribe((response: any) => {
      // if (this.routeParam.update) {
      //   this.sendMail.templateName = response.data.templateName;
      //   this.sendMail['design'] = response.data.design
      // }
      // this.previewImg = response.data.imageBytes ? "data:image/png;base64," + response.data.imageBytes :  '/assets/images/common/square.svg';
      // this.sendMail.subject = response.data.subject ? response.data.subject : '';
      this.templateModal = true
      this.templateView = 'desktop';
      this.templateContent = response.data.content;
      this.templateName = response.data.templateName;
    });
  }

}
