<div class="chatbot-inegration remove-default " [ngClass]="{'remove-default':!hideDefaultContainer}">
  <div *ngIf="!visible" class="d-flex align-items-center">
    <div #defaultChatView *ngIf="hideDefaultContainer" class="chat-default-container">
      <div class="help-section">
        <div class="d-flex">
          <div class="hi-section">Hi {{userName}}! How can we help you ? <img
              style="margin-left: 30px;border-radius: 100%;" class="img-head"
              src="../../../assets/images/favIcon/Favicon2.png" width="40" height="40"></div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-center"> 
          <span>  How can we help you ?</span> 
        </div> -->
      </div>
      <div class="ChatNow-section">
        <a (click)="openChatdailog()">Chat Now</a>
      </div>
    </div>
    <div class="msg">
      <i class="msg-icon" (click)="openChatdailog()" [appSvgIcon]="'messageIcon'">
      </i>
    </div>
  </div>


  <p-dialog [baseZIndex]="10000" [(visible)]="visible" position="bottom-right" [style]="{ width: '25vw' }">
    <div *ngIf="visible" (click)="closeChatdailog()" style="position: relative;">
      <div class="closs-icon">
        <i class="svg-icon" [appSvgIcon]="'messageClose'">
        </i>
      </div>

    </div>
    <div class="zoho-openchat-container">
      <div class="container-fluid d-flex justify-content-center h-100 pd-left pr-0">
        <div class="card">
          <div *ngIf="!assignuserNameheader" class="header-section d-flex flex-row  p-3 adiv text-white w-100">
            <img class="img-head" src="../../../assets/images/favIcon/Favicon2.png" width="50" height="50">
            <div class="flex-col head-content">
              <div class="head">NOWCAST</div>
              <div class="head-2" style="font-weight:500;font-style: normal;">NOWCAST Support Plus</div>
              <div class="close-icon"></div>
            </div>
            <div style="margin: 0 0 0 auto;padding-left:10px;padding-top: 5px;">
              <div class="pointer dropdown toggle-dropdown">
                <span class="toggle-icon" [appSvgIcon]="'toggleSupport'"></span>
                <div class="dropdown-content">
                  <a *ngIf="defaultChatViews" (click)="gethistoryData()">Show history</a>
                  <a *ngIf="!defaultChatViews" (click)="hidehistoryData()">Hide history</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="assignuserNameheader" class="header-section d-flex flex-row  p-3 adiv text-white">
            <!-- <img class="img-head" src="../../../assets/images/media/AI-suer.png" width="50" height="50"> -->
            <img class="img-head" src="../../../assets/images/favIcon/Favicon2.png" width="50" height="50">

            <div class="flex-col head-content">
              <div class="head">NOWCAST Support Plus</div>
              <div class="head-2"><span style="font-weight:500;font-style: normal;">{{supportName}},</span> Support
                Agent</div>
              <div class="close-icon"></div>
            </div>
            <div style="margin: 0 0 0 auto;padding-top: 5px;">
              <div class="pointer dropdown toggle-dropdown">
                <span class="toggle-icon" [appSvgIcon]="'toggleSupport'"></span>
                <div class="dropdown-content">
                  <a *ngIf="defaultChatViews" (click)="gethistoryData()">Show history</a>
                  <a *ngIf="!defaultChatViews" (click)="hidehistoryData()">Hide history</a>
                </div>
              </div>
            </div>
          </div>
          <div id='chatSection' class="chat-section" appScrollUp (scrolledUp)="onScrollUp()">
            <div *ngIf="defaultChatViews">
              <div class="pd-que-3 question-ask">
                <span>NOWCAST</span>
                <div class="chat question ml-2">Hi!.. Welcome to NOWCAST Support!</div>
              </div>
              <div class="p-3 reply-zone" *ngIf="!isAlreadyClicked">
                <div class="ans-bg-white mr-2" (click)="defaultQuestion('I want to understand a feature.')"
                  style="cursor: pointer;">I want to understand a feature.</div>
              </div>
              <div class="p-3 reply-zone" *ngIf="!isAlreadyClicked">
                <div class="ans-bg-white mr-2 pe-auto"
                  (click)="defaultQuestion('I want to raise a concern or question.')" style="cursor: pointer;">I want to
                  raise a concern or question.</div>
              </div>
            </div>
            <div *ngFor="let ai of togetmessageVal; let i = index">
              <div class="p-3 reply-zone" style="padding-bottom: 8px !important;" *ngIf="ai.qa">
                <div class="ans-bg-white mr-2"> <span *ngIf="ai.que" class="">{{ai.que}}</span>
                  <img *ngIf="ai.contentType == 'image'" [src]="ai.fileUrl" height="100" width="200"
                    (click)="openFullscreen(ai.fileUrl)">
                  <img *ngIf="ai.contentType == 'image'" id="fullscreen-image" src="" alt="Full Screen Image">
                  <div *ngIf="ai.contentType == 'video'" class="preview">
                    <div plyr plyrType="video" style="width: 220px;" plyrTitle="Video 1" [plyrPlaysInline]="true"
                      [plyrSources]=ai.fileUrl [plyrOptions]="options" (plyrInit)="players = $event"
                      (plyrEnded)="completeVideo($event)" (plyrStateChange)="stateChange($event)"
                      (plyrEnterFullScreen)="playedFullScreen($event)" (plyrPlay)="videoPlayed($event)"
                      [plyrPoster]="poster">
                    </div>
                  </div>
                </div>
              </div>
              <div class="assing-user">
                <div>{{ai.forword}}</div>
                <div>{{ai.support}}</div>
              </div>
              <div class="pd-que-3 question-ask" *ngIf="(ai.ans || ai.fileUrl) && !ai.qa">
                <span *ngIf="!ai.assignuserdeails" style="padding-top: 5px;">NOWCAST</span>
                <span *ngIf="ai.assignuserdeails && !defaultChatViews"
                  style="padding-top: 5px;">{{ai.assignuserdeails}}</span>
                <span *ngIf="ai.assignuserdeails && defaultChatViews" style="padding-top: 5px;">{{supportName}}</span>
                <div class="chat question ml-2" *ngIf="ai.ans" [innerHTML]="getFormattedString(ai.ans)"></div>
                <div *ngIf="ai.fileUrl">
                  <!-- <img [src]= "hero.fileUrl" class="image-inreslopnse" height="100" width="200">  -->
                  <!-- <div *ngIf="chat.imgsend"> -->
                  <img *ngIf="ai.contentType == 'image'" [src]="ai.fileUrl" height="100"
                    (click)="openFullscreen(ai.fileUrl)">
                  <img *ngIf="ai.contentType == 'image'" id="fullscreen-image" src="" alt="Full Screen Image">
                  <div *ngIf="ai.contentType == 'video'" class="preview">
                    <div plyr plyrType="video" style="width: 220px;" plyrTitle="Video 1" [plyrPlaysInline]="true"
                      [plyrSources]=ai.fileUrl [plyrOptions]="options" (plyrInit)="players = $event"
                      (plyrEnded)="completeVideo($event)" (plyrStateChange)="stateChange($event)"
                      (plyrEnterFullScreen)="playedFullScreen($event)" (plyrPlay)="videoPlayed($event)"
                      [plyrPoster]="poster">
                    </div>
                    <!-- </div>  -->
                  </div>
                </div>
              </div>
            </div>
            <!-- *ngIf ="typeingAnimation" -->
            <div *ngIf="typeingAnimation" class="typing-animation"> typing
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>

          </div>
          <div class="question-section form-group">
            <div class="tim" style="height: calc(100% - -24px);">
              <!-- 
            <textarea class="text-class" rows="5" placeholder="Type your message and hit Enter" [(ngModel)]="questionAsk" (keyup.enter)="onEnterhit($event,chatSection)"></textarea>    -->
              <input class="text-class" type="text" [(ngModel)]="questionAsk" (keyup)="onEnterValue($event)"
                (keydown.enter)="onEnterhit($event,chatSection)" placeholder="Type your message and Hit Enter" />
              <em (click)="handleClick()" class="attachment" [appSvgIcon]="'attachment'"
                tooltip="Upload image or video"></em>
              <input class="hidden" type="file" id="upload-file" name="upload-file" (change)="addAttachment($event)">
              <!-- [disabled]="disableMesageEvent" (change)="addAttachment($event)" -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

</div>