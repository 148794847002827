export class ListItem {
    title: string;
}

export class EditListItem {
    bannerArtworkId: number;
    catalogContent: any;
    squareArtworkId: number;
    subTitle: string
    title: string;
    wideArtworkId: number;
    bannerArtwork: any;
    squareArtwork: any;
    wideArtwork: any;
    description: any
    constructor(obj?) {
        this.bannerArtworkId = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.catalogContent = obj && obj.catalogContent ? obj.catalogContent : [];
        this.squareArtworkId = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.subTitle = obj && obj.subTitle ? obj.subTitle : '';
        this.title = obj && obj.title ? obj.title : '';
        this.wideArtworkId = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.description = obj && obj.description ? obj.description : '';

    }
}

export class CatalogContentDTO {
    albumId: number;
    calenderId: number;
    catalogId: number;
    childCatalogId: number;
    eventId: number;
    id: number;
    linkId: number;
    mediaItemId: number;
    mediaSeriesId: number;
    musicId: number;
    rssFeedId: number;
    userId: number
    constructor(obj?) {
        this.albumId = obj && obj.albumId ? obj.albumId : null;
        this.calenderId = obj && obj.calenderId ? obj.calenderId : null;
        this.catalogId = obj && obj.catalogId ? obj.catalogId : null;
        this.childCatalogId = obj && obj.childCatalogId ? obj.childCatalogId : null;
        this.eventId = obj && obj.eventId ? obj.eventId : null;
        this.id = obj && obj.id ? obj.id : null;
        this.linkId = obj && obj.linkId ? obj.linkId : null;
        this.mediaItemId = obj && obj.mediaItemId ? obj.mediaItemId : null;
        this.mediaSeriesId = obj && obj.mediaSeriesId ? obj.mediaSeriesId : null;
        this.musicId = obj && obj.musicId ? obj.musicId : null;
        this.rssFeedId = obj && obj.rssFeedId ? obj.rssFeedId : null;
        this.userId = obj && obj.userId ? obj.userId : null;
    }
}

export class GetListInSortedManner {
    pageCount: number;
    pageNo: number;
    sortBy: string
}

export class GetLinkInSortedManner {
    pageCount: number;
    pageNo: number;
    sortBy: string
}

export class LinkItem{
    title: string;
}

export class RssItem{
    title: string;
}

export class CreateLinkItem {
    bannerArtworkId: number;
    contact: any;
    squareArtworkId: number;
    subTitle: string;
    title: string;
    type: string;
    url: string;
    wideArtworkId: number
    bannerArtwork: any;
    squareArtwork: any;
    wideArtwork: any;
    status:string;
    scheduleDate : any
    scheduleTime : any
    publishedDate: any;
    linkAccessType : any
    isOneTimePurchase:boolean;
    subscriptionPlanIds: 	Array<number>;
    isPaid : string;
    price : any;
    
    constructor(obj?) {
        this.bannerArtworkId = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.contact = obj && obj.contact ? obj.contact : null;
        this.squareArtworkId = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.subTitle = obj && obj.subTitle ? obj.subTitle : '';
        this.title = obj && obj.title ? obj.title : '';
        this.type = obj && obj.type ? obj.type : '';
        this.url = obj && obj.url ? obj.url : 'nowcast.cc';
        this.wideArtworkId = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.status = obj && obj.status ? obj.status : '';
        this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
        this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : '';
        this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
        this.linkAccessType = obj && obj.linkAccessType ? obj.linkAccessType : 'FREE';
        this.isOneTimePurchase=obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false
        this.subscriptionPlanIds =  obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
        this.isPaid = obj && obj.isPaid ? obj.isPaid : 'false';
        this.price=obj && obj.price ? obj.price : null;

    }
}

export class RssFeeds {
    bannerArtworkId: number
    layout: string
    squareArtworkId: number;
    subTitle: string
    title: string
    url: string
    wideArtworkId: number
    status:string;
    scheduleDate : any
    scheduleTime : any
    publishedDate: any;
    bannerArtwork: any;
    squareArtwork: any;
    wideArtwork: any;
    rssFeedAccessType : any
    isOneTimePurchase:boolean;
    subscriptionPlanIds: Array<number>;
    isPaid : string;
    price : any;

    constructor(obj?) {
        this.bannerArtworkId = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.layout = obj && obj.layout ? obj.layout : 'READER';
        this.squareArtworkId = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.subTitle = obj && obj.subTitle ? obj.subTitle : '';
        this.title = obj && obj.title ? obj.title : '';
        this.url = obj && obj.url ? obj.url : '';
        this.wideArtworkId = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.status = obj && obj.status ? obj.status : '';
        this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : new Date();
        this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : '';
        this.publishedDate = obj && obj.publishedDate ? obj.publishedDate : null;
        this.bannerArtwork = obj && obj.bannerArtwork ? obj.bannerArtwork : null;
        this.squareArtwork = obj && obj.squareArtwork ? obj.squareArtwork : null;
        this.wideArtwork = obj && obj.wideArtwork ? obj.wideArtwork : null;
        this.rssFeedAccessType = obj && obj.rssFeedAccessType ? obj.rssFeedAccessType : 'FREE';
        this.isOneTimePurchase=obj && obj.isOneTimePurchase ? obj.isOneTimePurchase : false
        this.subscriptionPlanIds =  obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
        this.isPaid = obj && obj.isPaid ? obj.isPaid : 'false';
        this.price = obj && obj.price ? obj.price : null;
    }
}

export class GetRssFeedInSortedManner {
    pageCount: number;
    pageNo: number;
    sortBy: string
}

export class RemoveCatalogContent {
    contentId: number;
    type: string;
    constructor(obj?) {
        this.contentId = obj && obj.contentId ? obj.contentId : null;
        this.type = obj && obj.type ? obj.type : ''
    }
}


export class MobileListDesign {
    carouselContentIds: any;
    carouselContent:any
    carouselContentsList: any
    catalogId: number;
    eventId : number
    calendarId : number
    designType: string;
    headerType: string;
    itemDisplay: string;
    itemImages: string;
    itemLayout: string;
    itemTitles: string;
    margins: boolean;
    shadow: boolean;
    staticImageId: number;
    staticArtworkId : number;
    landscapeArtworkId: number;
    marginEdges : string;
    marginType : string;
    carouselType : string
    staticType: string
    mediaItemId : number;
    videoUrl : string;
    showTransparency: boolean;
    showBannerTransparency: boolean;
    transparencyColor: string;
    transparencyCount: number;
    constructor(obj?) {
        this.carouselContentIds = obj && obj.carouselContentIds ? obj.carouselContentIds : [];
        this.carouselContent = obj && obj.carouselContent ? obj.carouselContent : [];
        this.carouselContentsList = obj && obj.carouselContentsList ? obj.carouselContentsList : [];
        this.catalogId = obj && obj.catalogId ? obj.catalogId : null;
        this.eventId = obj && obj.eventId ? obj.eventId : null;
        this.calendarId = obj && obj.calendarId ? obj.calendarId : null;
        this.designType = obj && obj.designType ? obj.designType : null;
        this.headerType = obj && obj.headerType ? obj.headerType : 'OFF';
        this.itemDisplay = obj && obj.itemDisplay ? obj.itemDisplay : 'IMAGE';
        this.itemImages = obj && obj.itemImages ? obj.itemImages : 'SQUARE';
        this.itemLayout = obj && obj.itemLayout ? obj.itemLayout : 'ROWS';
        this.itemTitles = obj && obj.itemTitles ? obj.itemTitles : 'BELOW';
        this.margins = obj && obj.margins ? obj.margins : false;
        this.showTransparency = obj && obj.showTransparency ? obj.showTransparency : false;
        this.showBannerTransparency = obj && obj.showBannerTransparency ? obj.showBannerTransparency : false;
        this.transparencyColor= obj && obj.transparencyColor ? obj.transparencyColor : '#000000';
        this.transparencyCount= obj && obj.transparencyCount ? obj.transparencyCount : '5';
        this.shadow = obj && obj.shadow ? obj.shadow : false;
        this.staticImageId = obj && obj.staticImageId ? obj.staticImageId : null;
        this.staticArtworkId = obj && obj.staticArtworkId ? obj.staticArtworkId : null;
        this.landscapeArtworkId = obj && obj.landscapeArtworkId ? obj.landscapeArtworkId : null;
        this.marginEdges = obj && obj.marginEdges ? obj.marginEdges : 'SHARP';
        this.marginType = obj && obj.marginType ? obj.marginType : 'VERYTHIN'
        this.carouselType = obj && obj.carouselType ? obj.carouselType : 'WIDE'
        this.staticType = obj && obj.staticType ? obj.staticType : 'WIDE'
        this.mediaItemId = obj && obj.mediaItemId ? obj.mediaItemId : null
        this.videoUrl = obj && obj.videoUrl ? obj.videoUrl : null;
    }
}

export class TvListDesign {
    carouselContent: any;
    catalogId: number;
    designType: string;
    headerType: string;
    itemDisplay: string;
    itemImages: string;
    itemLayout: string;
    itemTitles: string;
    margins: boolean;
    shadow: boolean;
    staticImageId: number;
    marginEdges : string;
    marginType : string;
    carouselType : string
    constructor(obj?) {
        this.carouselContent = obj && obj.carouselContent ? obj.carouselContent : [];
        this.catalogId = obj && obj.catalogId ? obj.catalogId : null;
        this.designType = obj && obj.designType ? obj.designType : null;
        this.headerType = obj && obj.headerType ? obj.headerType : null;
        this.itemDisplay = obj && obj.itemDisplay ? obj.itemDisplay : null;
        this.itemImages = obj && obj.itemImages ? obj.itemImages : null;
        this.itemLayout = obj && obj.itemLayout ? obj.itemLayout : null;
        this.itemTitles = obj && obj.itemTitles ? obj.itemTitles : null;
        this.margins = obj && obj.margins ? obj.margins : null;
        this.shadow = obj && obj.shadow ? obj.shadow : null;
        this.staticImageId = obj && obj.staticImageId ? obj.staticImageId : null;
        this.marginEdges = obj && obj.marginEdges ? obj.marginEdges : 'SHARP';
        this.marginType = obj && obj.marginType ? obj.marginType : 'VERYTHIN'
        this.carouselType = obj && obj.carouselType ? obj.carouselType : null
    }
}

export class WebListDesign {
    carouselContent: any;
    catalogId: number;
    designType: string;
    headerType: string;
    itemDisplay: string;
    itemImages: string;
    itemLayout: string;
    itemTitles: string;
    margins: boolean;
    shadow: boolean;
    staticImageId: number;
    marginEdges : string;
    marginType : string;
    carouselType : string
    constructor(obj?) {
        this.carouselContent = obj && obj.carouselContent ? obj.carouselContent : [];
        this.catalogId = obj && obj.catalogId ? obj.catalogId : null;
        this.designType = obj && obj.designType ? obj.designType : null;
        this.headerType = obj && obj.headerType ? obj.headerType : null;
        this.itemDisplay = obj && obj.itemDisplay ? obj.itemDisplay : null;
        this.itemImages = obj && obj.itemImages ? obj.itemImages : null;
        this.itemLayout = obj && obj.itemLayout ? obj.itemLayout : null;
        this.itemTitles = obj && obj.itemTitles ? obj.itemTitles : null;
        this.margins = obj && obj.margins ? obj.margins : null;
        this.shadow = obj && obj.shadow ? obj.shadow : null;
        this.staticImageId = obj && obj.staticImageId ? obj.staticImageId : null;
        this.marginEdges = obj && obj.marginEdges ? obj.marginEdges : 'SHARP';
        this.marginType = obj && obj.marginType ? obj.marginType : 'VERYTHIN'
        this.carouselType = obj && obj.carouselType ? obj.carouselType : null
    }
}


