import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';
import { Subject } from 'rxjs';
import { serialize } from 'v8';

@Injectable({
  providedIn: 'root'
})

export class EventService {

  panelState = new Subject();
  panelState$ = this.panelState.asObservable();

  panel = new Subject();
  panel$ = this.panel.asObservable();

  calenderpanel = new Subject();
  calenderpanel$ = this.calenderpanel.asObservable();

  constructor(public resourceService: ResourceService) { }

  updatePanelState(state){
    this.panelState.next(state);
  }

  updatePanel(state){
    this.panel.next(state)
  }

  updatecalenderPanel(state){
    this.calenderpanel.next(state);
  }

  getCalenderData(page,size,search) {
    const pages = page ? `?page=${page}` : ''
    const sizes = size ? `&size=${size}` : ''
    const filter = search ? `&searchkey=${search}` : ''
    const getCalenderItemUrl = `${appConfig.calendar}${pages}${filter}${sizes}`;
    return this.resourceService.getData(getCalenderItemUrl);
  }

  getCalenderDataOnPanel(id,page,size,search) {
    const pages = page ? `page=${page}` : ''
    const sizes = size ? `&size=${size}` : ''
    const filter = search ? `&searchkey=${search}` : ''
    const event = id ? `?eventId=${id}&` : `?`
    const getCalenderItemUrl = `${appConfig.calendar}${event}${pages}${filter}${sizes}`;
    return this.resourceService.getData(getCalenderItemUrl);
  }
 
  getAllCalenderData(page,size,search,isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : ''
    const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
    const filter = search ? `&searchkey=${search}` : ''
    const getCalenderItemUrl = `${appConfig.calendar}${selected}${pages}${filter}&size=${size}`;
    return this.resourceService.getData(getCalenderItemUrl);
  }

  getRegisteredUsers(page,size,eventId){
    const getRegisterdUsers = `${appConfig.registerdUsers}/${eventId}/registration/?page=${page}&size=${size}`;
    return this.resourceService.getData(getRegisterdUsers);

  }


  ViewRegisteredUsersDetails(registrationId,eventId){
    const ViewRegisterdUsers = `${appConfig.registerdUsers}/${eventId}/registration/${registrationId}`;
    return this.resourceService.getData(ViewRegisterdUsers);

  }



  createEventItem(data) {
    const createEventItemUrl = `${appConfig.event}`;
    return this.resourceService.postData(createEventItemUrl, data);
  }
// new
  createClaender1(data) {
    const createClndrItemUrl = `${appConfig.calendar}`;
    return this.resourceService.postData(createClndrItemUrl, data);
  }
// end
  createDuplicateEventItem(id) {
    const createEventItemUrl = `${appConfig.event}/duplicate/${id}`;
    return this.resourceService.postData(createEventItemUrl);
  }

  moveEvent(data,eventIds){
    const moveEventItemUrl = `${appConfig.event}/move?calendarId=${data.calendarId}&eventIds=${eventIds}`;
    return this.resourceService.putData(moveEventItemUrl);
  }

  eventAddToFolder(catalogId,eventIds){
    const addToFolderEventItemUrl = `${appConfig.event}/add/${catalogId}?eventIds=${eventIds}`;
    return this.resourceService.putData(addToFolderEventItemUrl);
  }

  calenderAddToFolder(catalogId,calendarIds){
    const addToFolderEventItemUrl = `${appConfig.calendar}/add/${catalogId}?calendarIds=${calendarIds}`;
    return this.resourceService.putData(addToFolderEventItemUrl);
  }


  updateEventItem(id,data,type) {
    // if(type === 'PUBLISH'){
    //   const createEventItemUrl = `${appConfig.event}/publish/${id}`;
    // return this.resourceService.putData(createEventItemUrl, data);
    // }else{
    //   var getEventItemUrl = (type === 'PUBLISH') ? `${appConfig.event}` :
    //   `${appConfig.event}?eventId=${id}`;
    //   return this.resourceService.putData(getEventItemUrl, data);
    // }
    const createEventItemUrl = `${appConfig.event}`;
    return this.resourceService.postData(createEventItemUrl, data);
  }

  // publishEvent(id,data){
  //   const createEventItemUrl = `${appConfig.event}/publish/${id}`;
  //   return this.resourceService.putData(createEventItemUrl, data);
  // }

  getEventById(id) {
    const getEventItemUrl = `${appConfig.event}?eventId=${id}`;
    return this.resourceService.getData(getEventItemUrl);
  }

  getallEventData(page,size,search) {
    const filter = search ? `&searchkey=${search}` : '';
    const getallEventItemUrl = `${appConfig.event}/all?page=${page}${filter}&size=${size}`;
    return this.resourceService.getData(getallEventItemUrl);
  }
  getSelectallEventData(page,size,search,isSelected) {
    const selected = isSelected ? `?isSelected=${isSelected}` : ''
    const pages = (isSelected ? `&page=${page}` : `?page=${page}`)
    const filter = search ? `&searchkey=${search}` : '';
    const getallEventItemUrl = `${appConfig.event}/all${selected}${pages}${filter}&size=${size}`;
    return this.resourceService.getData(getallEventItemUrl);
  }

  createCalender(id,data, type) {
    if(type === 'PUBLISH' && id){
      const publishCalenderUrl = `${appConfig.calendar}/publish/${id}`;
    return this.resourceService.putData(publishCalenderUrl, data);
    }
   else if(type === 'SAVE' && id){
    var createCalenderUrl = (type === 'PUBLISH') ? `${appConfig.calendar}` :
    `${appConfig.calendar}/${id}`;
    return this.resourceService.putData(createCalenderUrl, data);
   }
   else if (type === 'SAVE'){
       var createCalenderUrl = (type === 'PUBLISH') ? `${appConfig.calendar}` :
       `${appConfig.calendar}`;
       return this.resourceService.postData(createCalenderUrl, data);
   }
  }

  getCalenderById(id) {
    const getCalenderItemUrl = `${appConfig.calendar}/${id}`;
    return this.resourceService.getData(getCalenderItemUrl);
  }

  getcalendarEvent(id,startDate,selectBoolean) {
    if(selectBoolean){
      var getcalendarEventUrl = `${appConfig.calendarEvent}/${id}?allEvents=${selectBoolean}`;
    }else{
      var getcalendarEventUrl = `${appConfig.calendarEvent}/${id}?startDate=${startDate}`;
    }
    return this.resourceService.getData(getcalendarEventUrl);
  }

  deleteEventItem(id) {
    const deleteEventUrl = `${appConfig.event}/delete?${id}`;
    return this.resourceService.deleteData(deleteEventUrl);
  }

  deleteCalenderItem(id) {
    const deleteCalenderUrl = `${appConfig.calendar}/delete?${id}`;
    return this.resourceService.deleteData(deleteCalenderUrl);
  }

  updateEvent(id,data,type){
    const updateEventURL = `${appConfig.updateEvent}?eventId=${id}&type=${type}`;
    return this.resourceService.putData(updateEventURL,data)
  }

  updateCalender(id,data, type){
    const publishCalenderUrl = `${appConfig.calendar}/publish/${id}`;
    return this.resourceService.putData(publishCalenderUrl, data);
  }
  
  deleteEvent(id,type){
    const deleteEventUrl = `${appConfig.events}/delete/${id}?type=${type}`;
    return this.resourceService.deleteData(deleteEventUrl)
  }

  deleteEvent1(id,type){
    const deleteEventUrl = `${appConfig.followingevents}/delete/${id}?type=${type}`;
    return this.resourceService.deleteData(deleteEventUrl)
  }


}

