<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">
    <div class="panels-header">
        <div class="d-flex justify-content-between align-items-center p-4">
            <h4>Ebook Libraries</h4>
            <!-- <em [appSvgIcon]="'close'"></em> -->
            <img  class="" style="cursor: pointer;" (click)="closePanel()" src="./../../../../assets/images/library/close.svg" alt="">
        </div>
    </div>

    <div class="app-filters d-flex justify-content-center pt-1">
        <button  class="btn btn-white mr-10" [ngClass]="[!listIdArray.length && !listId ? 'disable': '']" (click)="apply()" >Apply</button>
        <button  class="btn btn-white" (click)="closePanel()" >Cancel</button>
    </div>

    <div class="app-search app-search-big search-box">
        <input type="text" placeholder="Search" class="form-control w-100 searchbar-size" #searchInput >
    </div>

    <!-- <div class="panel-content"> -->

        <ng-container>
            
            <div class="app-block">
                <!-- <div class="app-block-title">
                    <p class="info-title">Recent Folder</p>
                </div> -->
                <ul class="recent-item-list m-0 giveScroll">
                    <div class="media-series-container">
                    <li *ngFor="let item of recentSeries; let i = index" style="align-items: center;">
                        <div class="d-flex align-items-center">
                            <span class="user-img" [ngStyle]="{'background-color': item.squareArtwork ? item.squareArtwork.document.imageColur : '#e6e6e6'}">
                                <img [src]="item.squareArtwork ? item.newimg : ''"
                                    alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-list-{{i}}">

                                    <p (click)="redirectToDetailPage(item)" class="pointer">
                                        {{item.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </label>
                                    <div class="recent-chips" *ngIf="item.status === 'DRAFT'">
                                        <span class="red-bg">Draft</span>
                                    </div>
                            </div>
                        </div>
                        <div>
                            <input type="radio" [disabled]="item.status === 'DRAFT'" name="item" value="{{item.id}}" id="selected-list-{{i}}" (change)="selectItems($event,item)"   >
                        </div>
                        <!-- <div *ngIf="clickFrom === 'subtabs'">
                            <input type="checkbox" name="item" value="{{item.id}}" id="selected-list-{{i}}" (change)="selectItems($event,item)"   >
                        </div> -->
                    </li>
                    <!-- <li *ngIf="!recentMedia.length" class="no-media">
                                <div class="d-flex align-items-center">
                                    <div class="recent-info">
                                        <span>No Recent Media Exists</span>
                                    </div>
                                </div>
                            </li> -->
                            <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showall">
                                <button class="btn btn-white" *ngIf="recentSeries.length>5" (click)= "showmore()">Show more</button>
                            </div>
                            </div>
                </ul>
            </div>
    
          
            <!-- <div class="app-filters d-flex justify-content-center py-4">
                <button  class="btn btn-white mr-10" (click)="apply()" >Apply</button>
                <button  class="btn btn-white" (click)="closePanel()" >Cancel</button>
            </div> -->
        </ng-container>

</div>