import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHorizontalScrollDirective]'
})
export class HorizontalScrollDirectiveDirective {

  constructor(
    private element: ElementRef
  ) { }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    this.element.nativeElement.scrollLeft += event.deltaY;
    this.element.nativeElement.deltaX = 0
  }
  
}
