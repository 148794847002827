import { array, dataLoader } from "@amcharts/amcharts4/core";

export class SocialBasicInfo{
    bannerArtworkId : number;
    password : string;
    socialMediaType : string;
    squareArtworkId : number;
    username : string;
    wideArtworkId : string;

    constructor(obj?){
        this.bannerArtworkId = obj && obj.bannerArtworkId ? obj.bannerArtworkId : null;
        this.password = obj && obj.password ? obj.password : null;
        this.socialMediaType = obj && obj.socialMediaType ? obj.socialMediaType : null;
        this.squareArtworkId = obj && obj.squareArtworkId ? obj.squareArtworkId : null;
        this.username = obj && obj.username ? obj.username : null;
        this.wideArtworkId = obj && obj.wideArtworkId ? obj.wideArtworkId : null
    }
}

export class SocialLeadForm{
    autoSync : boolean;
    formName : string;
    socialMediaType : string;

    constructor(obj?){
        this.autoSync = obj && obj.autoSync ? obj.autoSync : false;
        this.formName = obj && obj.formName ? obj.formName : null;
        this.socialMediaType = obj && obj.socialMediaType ? obj.socialMediaType : null;
    }
}

export class UpdateSocialLeadForm{
    autoSync : boolean;
    socialMediaFormMapping : any;
    status : string;
    updatedDate : any;
    formName : string
    socialMediaType : string;
    socialMediaTokenInfoId : string
    formFieldCount : number
    constructor(obj?){
        this.autoSync = obj && obj.autoSync ? obj.autoSync : null;
        this.socialMediaFormMapping = obj && obj.socialMediaFormMapping ? obj.socialMediaFormMapping : [];
        this.status = obj && obj.status ? obj.status : null ;
        this.updatedDate = obj && obj.updatedDate ? obj.updatedDate : null;
        this.formName = obj && obj.formName ? obj.formName : null;
        this.socialMediaType = obj && obj.socialMediaType ? obj.socialMediaType : 'FACEBOOK';
        this.socialMediaTokenInfoId = obj && obj.socialMediaTokenInfoId ? obj.socialMediaTokenInfoId : null;
        this.formFieldCount = obj && obj.formFieldCount ? obj.formFieldCount : null
    }
}

export class PostInfo{
    message : string;
    socialMediaType : string
    mediaItemId : number
    mediaSeriesId : number
    constructor(obj?){
        this.message = obj && obj.message ? obj.message : null;
        this.socialMediaType = obj && obj.socialMediaType ? obj.socialMediaType : 'FACEBOOK';
        this.mediaItemId = obj && obj.mediaItemId ? obj.mediaItemId : null;
        this.mediaSeriesId = obj && obj.mediaSeriesId ? obj.mediaSeriesId : null;
    }
}

export class Mapping{
    nowcastColumn : string;
    socialMediaColumn : string;
    mappingId : number

    constructor(obj?){
        this.nowcastColumn = obj && obj.nowcastColumn ? obj.nowcastColumn : null;
        this.socialMediaColumn = obj && obj.socialMediaColumn ? obj.socialMediaColumn : null;
        this.mappingId = obj && obj.mappingId ? obj.mappingId : null
    }
}

export class PostOnSocialMedia{
    accessToken : any;
    artworkType : string;
    documentIds : number;
    mediaItemId : number;
    mediaSeriesId : number;
    message : string;
    postType : string;
    socialMediaTypeList : any;
    videoId : number;
    videoThumbnailUrl: any;

    idSocialMediaTypeMap : any;
    isScheduled : boolean;
    postStatus : string;
    scheduleDate : any;
    scheduleTime : any;
    videoTitle : string;
    videoDescription : string;
    // videoUrl : string;
    constructor(obj?){
        this.accessToken = obj && obj.accessToken ? obj.accessToken : [];
        this.artworkType = obj && obj.artworkType ? obj.artworkType : 'SQUARE';
        this.documentIds = obj && obj.documentIds ? obj.documentIds : [];
        this.mediaItemId = obj && obj.mediaItemId ? obj.mediaItemId : null;
        this.mediaSeriesId = obj && obj.mediaSeries ? obj.mediaSeriesId : null;
        this.message = obj && obj.message ? obj.message : null;
        this.postType = obj && obj.postType ? obj.postType : 'IMAGE';
        this.socialMediaTypeList = obj && obj.socialMediaTypeList ? obj.socialMediaTypeList : [];
        this.videoId = obj && obj.videoId ? obj.videoId : null;
        // this.videoUrl = obj && obj.videoUrl ? obj.videoUrl : null;

        this.idSocialMediaTypeMap = obj && obj.idSocialMediaTypeMap ? obj.idSocialMediaTypeMap : null
        this.isScheduled = obj && obj.isScheduled ? obj.isScheduled : false;
        this.postStatus = obj && obj.postStatus ? obj.postStatus : 'POSTED';
        this.scheduleDate = obj && obj.scheduleDate ? obj.scheduleDate : null;
        this.scheduleTime = obj && obj.scheduleTime ? obj.scheduleTime : null;
        this.videoTitle = obj && obj.videoTitle ? obj.videoTitle : null;
        this.videoDescription = obj && obj.videoDescription ? obj.videoDescription : null;
        this.videoThumbnailUrl = obj && obj.videoThumbnailUrl ? obj.videoThumbnailUrl : null;
    }
} 