<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">

    

        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between align-items-center p-4">
                <h4>Most Recent Video Embed</h4>
                <img class="cross-icon-img"  src="./../../../../assets/images/library/close.svg"
                 class="" (click)="closePanel()" style="cursor: pointer;" alt="">
            </div>
        </div>
        <div class="panel-content">
            <p class="info-title">
                Size
            </p>
            <p class="para-text">
                Determines the size of the embed in your website
            </p>

            <div>
                <div class="d-flex align-items-center pt-2  mb-0" >
                    <input type="radio" name="layout" checked id="">
                    <p class="para-text mb-0 px-2">
                        Auto fit
                    </p>
                </div>
                <div class="d-flex align-items-center py-2" >
                    <input type="radio" name="layout" id="">
                    <p class="para-text mb-0 px-2">
                        Fixed
                    </p>
                </div>
            </div>

            <div class="d-flex align-items-center p-2" >
                <div class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="val1" >
                </div>
                <p class="para-text px-2">X</p>
                <div class="form-group">
                    <input type="text" class="form-control" [(ngModel)]="val2" >
                </div>
                <p class="para-text px-1">pixels</p>
            </div>
    
           <p class="info-title">
               Content
           </p>
            
          <label class="mb-2" for="">Embed type</label>
           <div class="d-flex align-items-center mb-1" >
            <input type="checkbox" name="" id="">
            <p class="para-text mb-0 px-2">Video</p>
           </div>
           <div class="d-flex align-items-center mb-3" >
            <input type="checkbox" name="" id="">
            <p class="para-text mb-0 px-2">Audio</p>
           </div>

           <label class="mb-2" for="">Options</label>
           <div class="d-flex align-items-center mb-1" >
            <input type="checkbox" name="" id="">
            <p class="para-text mb-0 px-2">Show basic info</p>
           </div>
           <div class="d-flex align-items-center mb-1" >
            <input type="checkbox" name="" id="">
            <p class="para-text mb-0 px-2">Video watermark</p>
           </div>
           <div class="d-flex align-items-center mb-1" >
            <input type="checkbox" name="" id="">
            <p class="para-text mb-0 px-2">Social sharing</p>
           </div>
           <div class="d-flex align-items-center mb-3" >
            <input type="checkbox" name="" id="">
            <p class="para-text mb-0 px-2">Anyone can embed this</p>
           </div>



           <div>
            <p class="info-title">
                Embed Code
            </p>
            <p class="para-text my-3">
                Copy and paste the embed code below into your website wherever you would like it to be shown.
            </p>

            <a  >
                Learn more about embed codes
            </a>
           
            <div class="form-group my-3">
                
                <span class="app-password app-copy">
                    <input type="text" class="form-control" [(ngModel)]="code" readonly  >
                    <em class="password-icon" [appSvgIcon]="'copy'"  ></em>
                </span>
            </div>

        </div>
    



        </div>

        <!-- <label for="">Layout</label> -->
        



</div>
