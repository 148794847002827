import { animate, state, style, transition, trigger } from '@angular/animations';

export const AppAnimation = [
  trigger('sidePanel', [
    state('true', style({ right: '0px' })),
    state('false', style({ right: '-400px' })),
    transition('0 => 1', animate('0.2s')),
    transition('1 => 0', animate('0.2s')),
  ]),
  trigger('overlay', [
    state('true', style({ opacity: 1, display: 'block' })),
    state('false', style({ opacity: 0, display: 'none' })),
    transition('0 => 1', animate('0.2s')),
    transition('1 => 0', animate('0.5s')),
  ])
];
export const AppAnimation1 = [
  trigger('sidePanel1', [
    state('true', style({ right: '0px' })),
    state('false', style({ right: '-70%' })),
    transition('0 => 1', animate('0.2s')),
    transition('1 => 0', animate('0.2s')),
  ]),
  trigger('overlay1', [
    state('true', style({ opacity: 1, display: 'block' })),
    state('false', style({ opacity: 0, display: 'none' })),
    transition('0 => 1', animate('0.2s')),
    transition('1 => 0', animate('0.5s')),
  ])
];

export const AppAnimation2 = [
  trigger('sidePanel', [
    state('true', style({ right: '27vw',opacity : 1 })),
    state('false', style({ right: '-48px',opacity : 0 })),
    transition('0 => 1', animate('0.2s')),
    transition('1 => 0', animate('0.2s')),
  ]),
  trigger('overlay', [
    state('true', style({ opacity: 1, display: 'block' })),
    state('false', style({ opacity: 0, display: 'none' })),
    transition('0 => 1', animate('0.2s')),
    transition('1 => 0', animate('0.5s')),
  ])
];

export const AppAnimation3 = [
  trigger('slideInOut', [
    transition(':enter', [
      style({opacity: '1'}),
      animate('250ms ease-in', style({opacity: '1'}))
    ]),
    transition(':leave', [
      animate('250ms ease-in', style({opacity: '0'}))
    ])
  ])
];