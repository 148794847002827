<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">


    <div *ngIf="tv">
        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between p-4">
                <h4>Tv List Design</h4>
                <img class="cross-icon-img" (click)="closePanel()" src="./../../../../assets/images/library/close.svg"
                    alt="">
            </div>
        </div>
        <div class="app-filters pt-1 d-flex justify-content-center">
            <button (click)="createListDesign()" class="btn btn-white">Apply</button>
            <button (click)="closePanel()" class="btn btn-white">Cancel</button>
        </div>
        <div class="d-flex img-div justify-content-center align-items-center">
            <img *ngIf="off" src="./../../../../assets/images/library/tv.svg" alt="web-row" class="tv-img">
            <img *ngIf="featured" src="./../../../../assets/images/library/featuredTv.svg" alt="" class="tv-img">
        </div>

        <div class="panel-content">

            <ng-container>

                <div class="py-4">
                    <!-- <h6>Header</h6> -->
                    <p class="info-title">Header</p>
                    <div class="d-flex justify-content-center">

                        <span class="p-4 d-flex flex-column align-items-center justify-content-center web-options"
                            *ngIf="!off" (click)="offOption('OFF')">
                            <img src="./../../../../assets/images/library/on-off-button-light.svg" alt="">
                            <p class="my-2" >Off</p>
                        </span>
                        <span
                            class="p-4  d-flex flex-column align-items-center  justify-content-center web-options active"
                            *ngIf="off">
                            <img src="./../../../../assets/images/library/on-off-button.svg" alt="">
                            <p class="my-2" >Off</p>
                        </span>
                        <span class="p-4 d-flex flex-column align-items-center justify-content-center web-options"
                            *ngIf="!featured" (click)="featuredOption('CAROUSEL')">
                            <img src="./../../../../assets/images/library/featured.svg" alt="">
                            <p class="my-2" >Featured</p>
                        </span>
                        <span
                            class="p-4 d-flex flex-column align-items-center justify-content-center web-options active"
                            *ngIf="featured">
                            <img src="./../../../../assets/images/library/feature.svg" alt="">
                            <p class="my-2" >Featured</p>
                        </span>

                    </div>
                </div>

                <div class=" carousel-box d-flex justify-content-between align-items-center m-2 py-2 px-4"
                    *ngIf="featured" (click)="featureItemTab()">
                    <div class="d-flex align-items-center">
                        <span>
                            <img src="./../../../../assets/images/library/feature.svg" alt="">
                        </span>
                        <span class="px-4 d-flex flex-column">
                            <span>Carousel item</span>
                            <span>5/5 added</span>
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="./../../../../assets/images/library/sideway-icon.svg" alt="">
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="featureItems">
        <div class="panels-header">
            <div class="d-flex align-items-center justify-content-between p-4">
                <h4>Featured Items</h4>
                <img class="cross-icon-img" (click)="tvTab()" src="./../../../../assets/images/library/back-button.svg"
                    alt="">
            </div>
        </div>

        <div class="d-flex my-2 justify-content-center align-items-center">
            <div class="app-filters d-flex align-items-center justify-content-between">
                <button class="btn-white-rounded" (click)="openCarouselPanel()">
                    <em [appSvgIcon]="'plus'"></em> Add item
                </button>
                <span class="span-text px-3">Add up to 5 carousel items</span>
            </div>

        </div>

        <div class="panel-content">
            <ng-container>

                <div class="app-block">
                    <ul class="recent-item-list m-0">
                        <li *ngFor="let item of combined;index as i">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.path : (item.wideArtwork ? item.wideArtwork.document.path : (item.bannerArtwork ? item.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{item.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                            <div>
                                <!-- <input type="checkbox" name="" (change)="selectFolder($event, item)"
                                [(ngModel)]="item.itemChecked" id=""> -->
                                <img  (click)="deleteItem(i)" src="./../../../../assets/images/library/trash.svg" alt="delete-icon">

                            </div>

                        </li>

                        <li *ngFor="let item of carouselList;index as i">
                            <div class="d-flex align-items-center">
                                <span class="user-img">
                                    <img [src]="item.squareArtwork ? item.squareArtwork.document.path : (item.wideArtwork ? item.wideArtwork.document.path : (item.bannerArtwork ? item.bannerArtwork.document.path : 'assets/images/common/no-image.svg'))"
                                        alt="">
                                </span>
                                <div class="recent-info">
                                    <p class="pointer">
                                        {{item.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </div>
                            </div>
                            <div>

                                <img (click)="delete(i)" src="./../../../../assets/images/library/trash.svg"
                                    alt="delete-icon">

                            </div>

                        </li>

                        <!-- <li *ngIf="!combined.length && carouselList.length" class="no-media">
                        <div class="d-flex align-items-center">
                            <div class="recent-info">
                                <span>No Recent Media Exists</span>
                            </div>
                        </div>
                    </li> -->

                    </ul>

                </div>
            </ng-container>
        </div>


    </div>


    <app-featured-pop-up></app-featured-pop-up>
