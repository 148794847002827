import { Component, OnInit } from '@angular/core';
import { SocialMediaServicesService } from 'src/app/services/social/social-media-services.service';
import { Subscription } from 'rxjs';
import { AppAnimation1 } from 'src/app/utilities/animations/animations';
import { UpdateSocialLeadForm } from 'src/app/models/social-media.model'
import { Mapping } from 'src/app/models/social-media.model'
import * as moment from 'moment';
import { MessageService, LazyLoadEvent } from 'primeng/api';


@Component({
  selector: 'app-social-lead-edit',
  templateUrl: './social-lead-edit.component.html',
  styleUrls: ['./social-lead-edit.component.css'],
  animations: AppAnimation1,
})
export class SocialLeadEditComponent implements OnInit {
  mapping = new Mapping()
  mappingArray: any = []
  overlay = false;
  sidePanel = false;
  activeTab = 'details';
  leadFormId: number
  status = false
  leadEmail: string;
  nowcastEmail: string
  leadFullName: string
  nowcastFullName: string
  leadPhone: string
  nowcastPhone: string
  leadCity: string
  nowcastCity: string
  mappingId1 = null
  mappingId2 = null
  mappingId3 = null
  mappingId4 = null
  formId: string
  fieldCount: number

  cols = [
    { field: 'createdTime', header: 'Created Time' },
    { field: 'formName', header: 'Form Name' },
    { field: 'email', header: 'Email' },
    { field: 'fullName', header: 'Full Name ' },
    { field: 'phone', header: 'Mobile' },
    // { field: 'city', header: 'City' },
    { field: 'action', header: 'Action' }
  ];
  pages: any[] = [];
  // pages : any [] = []
  page1: any = []
  page2: any[] = []
  rowCount = 15;
  totalRecords = 0;
  page = 2;
  rows = 50;
  updateSocialLeadForm: UpdateSocialLeadForm
  subsciptionData = new Subscription();
  socialMediaTokenInfoId: number
  updatedDate: string
  timeZone: any;
  loginUserDetail: any;

  constructor(
    public socialService: SocialMediaServicesService,
    public messageService: MessageService
  ) {
    this.updateSocialLeadForm = new UpdateSocialLeadForm()
  }

  ngOnInit(): void {

    this.loginUserDetail = localStorage.getItem('loggedInUser');
    this.timeZone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.timeZone : '';


    this.getPanelState();
    this.rowCount = (window.innerWidth >= 1600) ? 25 : 10;

    // this.mappingArray.push(this.mapping)

  }

  addRow() {
    if (this.updateSocialLeadForm.formFieldCount !== null && this.updateSocialLeadForm.formFieldCount !== 0) {
      if (this.updateSocialLeadForm.formFieldCount > 1) {
        this.mapping = new Mapping()
        this.mappingArray.push(this.mapping)
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `You can add only ${this.updateSocialLeadForm.formFieldCount} fields.` });
      }
    } else {
      this.mapping = new Mapping()
      this.mappingArray.push(this.mapping)
    }
  }

  removeRow(index) {
    this.mappingArray.splice(index,1)
  }

  loadLeads(event: LazyLoadEvent) {
    this.page = (event.first / event.rows) + 1;
    this.rows = event.rows;
    this.getLeadList(this.page, this.rows, this.leadFormId);
  }


  getPanelState() {
    this.subsciptionData.add(
      this.socialService.panel$.subscribe((data: any) => {
        this.sidePanel = data.showPanel;
        this.overlay = data.showPanel;
        if (data.recent) {
          this.leadFormId = data.recent.id
          this.formId = data.recent.formId
          this.socialMediaTokenInfoId = data.recent.socialMediaTokenInfoId
        }
        this.activeTab = 'details'
        this.mappingArray.push(this.mapping)
        if (this.leadFormId) {
          this.getLeadFormById(this.leadFormId)
          // this.getLeadList(this.page, this.rows,this.leadFormId);
        }
      })
    )
  }

  closePanel() {
    this.socialService.updatePanel({ showPanel: false });
    this.sidePanel = false;
    this.overlay = false;
    this.mappingArray = []
  }

  getLeadFormById(id) {
    this.socialService.getLeadFormById(id).subscribe((response: any) => {
      this.updateSocialLeadForm = new UpdateSocialLeadForm(response.data)
      this.updateSocialLeadForm.updatedDate = moment(this.updateSocialLeadForm.updatedDate).format('LLL')
      if (this.updateSocialLeadForm.status === 'ACTIVE') {
        this.status = true
      }
      else {
        this.status = false
      }

      if (response.data.socialMediaFormMapping) {

        // this.mapping = new Mapping(response.data.socialMediaFormMapping)
        this.mappingArray = response.data.socialMediaFormMapping

        // console.log(this.mappingArray, `abc`)
        // response.data.socialMediaFormMapping.forEach((el, i) => {

        //   let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        //   var phoneno = /^\d{10}$/;

        // if (el.socialMediaColumn) {

        // if (el.socialMediaColumn === 'Email') {
        //   this.leadEmail = el.socialMediaColumn
        //   this.nowcastEmail = el.nowcastColumn
        //   this.mappingId1 = el.mappingId
        // }

        // if (i === 1) {
        //   this.leadPhone = el.socialMediaColumn
        //   this.nowcastPhone = el.nowcastColumn
        //   this.mappingId2 = el.mappingId
        // }

        // if (i === 2) {
        //   this.leadCity = el.socialMediaColumn
        //   this.nowcastCity = el.nowcastColumn
        //   this.mappingId3 = el.mappingId
        // }

        // if (i === 3) {
        //   this.leadFullName = el.socialMediaColumn
        //   this.nowcastFullName = el.nowcastColumn
        //   this.mappingId4 = el.mappingId
        // }

        // }

        // })
      }
    })
  }

  updateLeadForm() {

    const data = JSON.parse(JSON.stringify(this.updateSocialLeadForm))
    data.updatedDate = null
    if (this.status) {
      data.status = 'ACTIVE'
    }
    else {
      data.status = 'INACTIVE'
    }
    data.socialMediaTokenInfoId = this.socialMediaTokenInfoId
    // data.socialMediaFormMapping = [
    //   {
    //     nowcastColumn: this.nowcastEmail,
    //     socialMediaColumn: this.leadEmail,
    //     mappingId: this.mappingId1
    //   },
    //   {
    //     nowcastColumn: this.nowcastFullName,
    //     socialMediaColumn: this.leadFullName,
    //     mappingId: this.mappingId2
    //   },
    //   {
    //     nowcastColumn: this.nowcastPhone,
    //     socialMediaColumn: this.leadPhone,
    //     mappingId: this.mappingId3
    //   },
    //   {
    //     nowcastColumn: this.nowcastCity,
    //     socialMediaColumn: this.leadCity,
    //     mappingId: this.mappingId4
    //   },
    // ]
      const isNullOrEmpty= data.socialMediaFormMapping.some(ele=>(ele.nowcastColumn===null || ele.nowcastColumn==="" || ele.socialMediaColumn===null || ele.socialMediaColumn==="" ))
      if(isNullOrEmpty){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Fields should not be empty.' });
        return
      }
      data.socialMediaFormMapping = this.mappingArray.map((el) => ({
        nowcastColumn: el.nowcastColumn,
        socialMediaColumn: el.socialMediaColumn,
        mappingId: el.mappingId
      }))

      this.socialService.updateLeadForm(this.leadFormId, data).subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Lead Form updated successfully` });
        this.closePanel()
        this.socialService.updateSocialLeadTable({ updateTable: true })
        this.mappingArray = []
      },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
    }

  getLeadList(page, size, id) {
    this.socialService.getLeadList(page, size, id).subscribe((response: any) => {
      if (response.data.content.length>0) {
        
        
        this.cols = response.data.content[0].headerName.map((el) => ({
          field : el,
          header : el.split("_").join(" ")
        }) )

        let data = { field: 'action', header: 'Action' }

        this.cols.push(data)

        // this.cols = this.cols.filter(x => x.field !== 'Created Time');

        this.pages = response.data.content

        this.pages.forEach((el) => {
          if(el["Created Time"]){
            el["Created Time"] = moment(el["Created Time"]?.split("+")[0]).format('LLL')
          }
        } )

        // this.pages = response.data.content.map((el) => ({
        //   autoSync: el.autoSync,
        //   addedAsContact: el.addedAsContact,
        //   leadId: el.leadId,
        //   createdTime: moment(el.createdDate).format('LLL'),
        //   formName: el.formName,
        //   email: (el.columnName1 === ('email')) ? el.value1 : ((el.columnName2 === ('email')) ? el.value2 : ((el.columnName3 === ('email')) ? el.value3 : ((el.columnName4 === ('email')) ? el.value4 : ''))),
        //   fullName: (el.columnName1 === ('full_name')) ? el.value1 : ((el.columnName2 === ('full_name')) ? el.value2 : ((el.columnName3 === ('full_name')) ? el.value3 : ((el.columnName4 === ('full_name')) ? el.value4 : ''))),
        //   phone: (el.columnName1 === ('phone_number')) ? el.value1 : ((el.columnName2 === ('phone_number')) ? el.value2 : ((el.columnName3 === ('phone_number')) ? el.value3 : ((el.columnName4 === ('phone_number')) ? el.value4 : ''))),
        //   city: (el.columnName1 === ('City')) ? el.value1 : ((el.columnName2 === ('City')) ? el.value2 : ((el.columnName3 === ('City')) ? el.value3 : ((el.columnName4 === ('City')) ? el.value4 : '')))
        //   // email: (el.columnName1.toUpperCase() === ('EMAIL')) ? el.value1 : ((el.columnName2.toUpperCase() === ('Email')) ? el.value2 : ((el.columnName3.toUpperCase() === ('Email')) ? el.value3 : ((el.columnName4.toUpperCase() === ('EMAIL')) ? el.value4 : ''))),
        //   // fullName: (el.columnName1.toUpperCase() === ('NAME') || el.columnName1.toUpperCase() === ('FIRST_NAME') || el.columnName1.toUpperCase() === ('FULL_NAME') ) ? el.value1 : ((el.columnName2.toUpperCase() === ('NAME') || el.columnName1.toUpperCase() === ('FIRST_NAME') || el.columnName1.toUpperCase() === ('FULL_NAME')) ? el.value2 : ((el.columnName3.toUpperCase() === ('NAME') || el.columnName1.toUpperCase() === ('FIRST_NAME') || el.columnName1.toUpperCase() === ('FULL_NAME')) ? el.value3 : ((el.columnName4.toUpperCase() === ('NAME') || el.columnName1.toUpperCase() === ('FIRST_NAME') || el.columnName1.toUpperCase() === ('FULL_NAME')) ? el.value4 : ''))),
        //   // phone: (el.columnName1.toUpperCase() === ('PHONE') || ('MOBILE') || ('PHONE_NUMBER') || ('MOBILE_NUMBER') ) ? el.value1 : ((el.columnName2.toUpperCase() === ('PHONE') || ('MOBILE') || ('PHONE_NUMBER') || ('MOBILE_NUMBER')) ? el.value2 : ((el.columnName3.toUpperCase() === ('PHONE') || ('MOBILE') || ('PHONE_NUMBER') || ('MOBILE_NUMBER')) ? el.value3 : ((el.columnName4.toUpperCase() === ('PHONE') || ('MOBILE') || ('PHONE_NUMBER') || ('MOBILE_NUMBER')) ? el.value4 : ''))),
        //   // city: (el.columnName1.toUpperCase() === ('CITY') || ('STATE') ) ? el.value1 : ((el.columnName2.toUpperCase() === ('CITY')|| ('STATE')) ? el.value2 : ((el.columnName3.toUpperCase() === ('CITY')|| ('STATE')) ? el.value3 : ((el.columnName4.toUpperCase() === ('CITY')|| ('STATE')) ? el.value4 : '')))
        // }))

      }else{
        this.pages = []
      }
      if (response.data.content.length > 0) {
        if (response.data.content[0].updatedDate) {
          var cutoffString = response.data.content[0].updatedDate // in utc
          var utcCutoff = moment.utc(cutoffString, 'YYYYMMDD HH:mm:ss');
          var displayCutoff = utcCutoff.clone().tz(this.timeZone);
          let formatDate = moment(displayCutoff).format('LT');

          this.updatedDate = 'Last updated at ' + formatDate
        }
        else {
          this.updatedDate = ''
        }
      } else {
        this.updatedDate = ''
      }

      this.totalRecords = response.data.totalElements

    })
  }

  updateLeadsData() {
    this.socialService.getLeadsUpdatedData(this.formId).subscribe((res: any) => {
      this.getLeadList(this.page, this.rows, this.leadFormId);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Lead Form refreshed successfully` });
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
  }

  addLeadAsContact(rowData) {
    this.socialService.postLeadAsContact(rowData.leadId).subscribe((res: any) => {
      this.getLeadList(this.page, this.rows, this.leadFormId);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Lead added as contact successfully` });
    },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
  }
  addNewRow(){
    // con
    // this.mappingArray.push()
  }

}
