import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { EventService } from 'src/app/services/events/event-services';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { AppAnimation } from 'src/app/utilities/animations/animations';

@Component({
  selector: 'app-calendar-side-panel',
  templateUrl: './calendar-side-panel.component.html',
  styleUrls: ['./calendar-side-panel.component.css'],
  animations: AppAnimation,
})
export class CalendarSidePanelComponent implements OnInit {
  overlay = false;
  sidePanel = false;
  subsciptionData = new Subscription();
  calenderItem: any[] = []
  totalCalender: any
  totalMediaSeriesRecords: any
  dataId: any;
  showall = false;
  listIdArray: any[] = [];
  clickFrom: any
  listId: any
  tabId: any
  @ViewChild('searchInput') searchInput: ElementRef
  constructor(
    private eventService: EventService,
    public libraryService: LibraryServiceService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getSubscriptionData()
  }


  getSubscriptionData() {
    this.subsciptionData.add(
      this.libraryService.calendarPanelState$.subscribe(
        (state: any) => {
          this.sidePanel = state.showPanel;
          this.overlay = state.showPanel
          this.dataId = state.Id
          this.clickFrom = state.clickFrom
          this.listIdArray = []
          this.listId = 0
          this.getCalenderItem(1, 5, '')
        }
      )
    )
  }

  goto(data) {
    if (data?.status && data?.status === 'DRAFT') {
      this.router.navigate([`/events-module/events/edit-calender/${data.id}`])
    }
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }

  ngAfterViewInit() {

    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      if (this.searchInput.nativeElement.value.length > 2) {
        this.getCalenderItem(1, 10, this.searchInput.nativeElement.value);
      }
      else if (this.searchInput.nativeElement.value.length === 0) {
        this.getCalenderItem(1, 10, this.searchInput.nativeElement.value);
        setTimeout(() => {
          this.showall = false
        }, 1000);
      }
    })).subscribe();
  }

  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.showall = false;
    this.libraryService.updateCalendarPanelState({ showPanel: false })
  }

  showmore() {
    this.showall = true
    this.getCalenderItem(1, this.totalCalender, '');
  }

  getCalenderItem(page, size, search) {
    // var search = ''
    this.eventService.getCalenderData(page, size, search).subscribe((response: any) => {
      this.calenderItem = response.data.content;
      this.totalCalender = response.data.totalElements
      this.totalMediaSeriesRecords = response.data.totalElements
      if (response.data.totalElements < 5) {
        this.showall = true
      }
      this.calenderItem.forEach(data => {
        if (data.squareArtwork) {
          // if(data.squareArtwork.document.bdImage === true){
          if (data.squareArtwork.artworkType === "SQUARE") { var width = 270; var height = 270; }
          let id = data.squareArtwork.document.id;
          data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
          //   this.sharedService.updateLoader(false);
          //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

          // })
          // }
        }
        else if (data.bannerArtwork) {
          // if(data.bannerArtwork.document.bdImage === true){
          if (data.bannerArtwork.artworkType === "BANNER") { var width = 300; var height = 100; }
          let id = data.bannerArtwork.document.id;
          data.bannerArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
          //   this.sharedService.updateLoader(false);
          //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

          // })
          // }
        }
        else if (data.wideArtwork) {
          // if(data.wideArtwork.document.bdImage === true){
          if (data.wideArtwork.artworkType === "WIDE") { var width = 270; var height = 130; }
          let id = data.wideArtwork.document.id;
          data.wideArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
          //   this.sharedService.updateLoader(false);
          //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

          // })
          // }
        }
      })
    });
  }

  apply() {
    this.changeFolder()
  }
  changeFolder() {
    if (this.dataId) {
      this.tabId = this.dataId
      this.libraryService.changetabFolderdata(this.listId, this.tabId, 'calendar').subscribe((response: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update successfully.' });
        this.libraryService.sendUpdateToCreate({ update: true })
        this.closePanel()
      }
        , (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });

    }
  }

  selectItems(event, item) {
    if (this.clickFrom === 'subtabs') {
      this.listIdArray.push(item.id)
    } else {
      this.listId = event.target.value
    }
    console.log(this.listId)
  }

  redirectToDetailPage(data) {
    if (data.status === 'DRAFT')
      this.router.navigate(['/events-module/events/edit-calender/' + data.id], { queryParams: { calenderSidePanel: true } })


    // localStorage.setItem('activeTabId', JSON.stringify(data.id));

  }

}

