<div class="wrapper" [ngStyle]="{'background-color':(brandColor)? brandColor: '#010433' }">
    <div style="  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);">
        <div class="logo" style="cursor: pointer;">
            <img [src]="logoSrcFound ? logoId : defaultSrc" alt="logo">
        </div>
        <div class="container">
            <div *ngIf="isVerify">
                <h1 style="color: #fff">Congratulations</h1>
                <p style="color: #fff">Your email has been verified
                </p>
            </div>
            <!-- *ngIf="showmsg" -->
            <!-- You have successfully verify your email -->
            <p *ngIf="!isVerify" style="color: #fff">Your email has already been verified</p>
            <!-- *ngIf="!showmsg" -->
            <div class="detail">
                <h3>Experience Nowcast Apps Network across all platforms</h3>
                <p>Nowcast Apps is accessible on over 1000 devices including iOS devices, Android devices, MACs, PCs, &
                    streaming media boxes such as Roku.</p>
            </div>
            <!-- <div>
                <p>
                    <span class="login">To continue, Please login to {{orgName}} website or mobile app.</span>

                </p>
            </div> -->
                <div class="thanks-wrapper">
                    <h3 *ngIf="created === 'org'" class="login" routerLink="/login">
                        Login to continue.
                    </h3>
                    <p *ngIf="created === 'user'" class="user-login">To continue, Please login to {{orgName}}
                        <span class="pointer underline" (click)="openLink()">website</span> or mobile app.</p>
                </div>
        </div>
    </div>

</div>