<div class="panel-overlay" [ngClass]="{'panel':opacity}" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">
    <div class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">
            <h4 *ngIf="clickedFrom === 'channelItem'">Recent Videos</h4>
            <img class="cross-icon-img" style="cursor: pointer;" (click)="closePanel('cancel');"
                src="./../../../../assets/images/library/close.svg" alt="">
        </div>
    </div>
    <div *ngIf="showOptions" class="app-filters d-flex justify-content-center pt-1">
        <button *ngIf="clickedFrom === 'mobile'" (click)="sendData(selectedItems)"
            class="btn btn-white mr-10">Apply</button>
        <button *ngIf="clickedFrom !== 'mobile'"
            [ngClass]="[selectedMediaItems.length==0? 'disable' : '']"
            (click)="sendData(selectedItems)" class="btn btn-white mr-10">Apply</button>
        <button (click)="closePanel('cancel')" class="btn btn-white">Cancel</button>
    </div>
    <div [hidden]="!mediaItems">
        <div class="app-filters d-flex justify-content-center">
            <div class="app-search app-search-big search-box mr-10">
                <input type="text" placeholder="Search" class="form-control w-100 " #searchMediaItems>
            </div>
            <button (click)="createMedItem('CHANNEL')" class="btn btn-white btnSeries">Add Video</button>


        </div>
    </div>

    <ng-container>
        <div *ngIf="createMedIt">
            <div class="app-block" style="box-shadow: none !important;">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="info-title">Basic Info</p>
                    <em [appSvgIcon]="'back'" (click)="closeMediaItem(type)"></em>
                </div>

                <form #createMediaItemForm="ngForm">
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" [(ngModel)]="mediaItem.title" name="title" required
                            appWhiteSpaceValidator #title="ngModel">
                        <ng-container *ngIf="title.errors && (title.touched || title.dirty)">
                            <p class="error" [hidden]="!title.errors.required && !title.errors.whiteSpaceValidator">
                                Title is required</p>
                        </ng-container>
                    </div>

                    <div class="app-filters d-flex justify-content-center my-4">
                        <button [disabled]="!createMediaItemForm.valid" (click)="createMediaItem(mediaItem)"
                            type="button" class="btn btn-white mr-10">Add</button>
                    </div>
                </form>
            </div>
        </div>
        <span class="draft-info"
        *ngIf="!createMedIt &&  mediaItems">
        It is required that the item's status be changed from Draft to either Publish or Schedule before it can
        be chosen.</span>

        <div *ngIf="mediaItems">
            <div class="app-block" style="box-shadow: none !important;">
                <div class="app-block-title">
                    <p class="info-title">Video</p>
                </div>
                <ul *ngIf="showAllMediaItem" class="recent-item-list m-0">
                    <li *ngFor="let recent of recentMedia ; let i = index">
                        <div class="d-flex align-items-center"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <!-- <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span> -->
                            <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-wide"
                                [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-banner"
                                [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-MediaItem-{{i}}">
                                    <p class="pointer ellipsis-text" (click)="goTo(recent, 'vidItem')"
                                        (dblclick)="dblClickselectItems(recent)">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </label>
                                <div class="recent-chips"
                                    *ngIf="recent.isReadyToPublish === false  && recent.status === 'DRAFT'">
                                    <span class="red-bg">Draft</span>
                                </div>
                                <div class="recent-chips"
                                    *ngIf="recent.isReadyToPublish === true && recent.status === 'DRAFT'">
                                    <span tooltip="Ready to Publish" class="brown-bg">Ready</span>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="clickedFrom !== 'notification'">
                            <span class="form-check">
                                <input type="checkbox" class="form-check-input" name=""
                                    id="selected-MediaItem-{{i}}" (change)="selectItems($event, recent)"
                                    [disabled]="!recent.itemChecked || recent.status === 'DRAFT'"
                                    [(ngModel)]="recent.itemChecked">
                            </span>
                        </div>
                        <div *ngIf="clickedFrom === 'notification'">
                            <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                id="selected-MediaItem-{{i}}" value="{{recent.id}}"
                                (change)="selectItems($event, recent)">
                        </div>
                    </li>
                    <!-- <li *ngIf="!recentMedia.length" class="no-media">
                        <div class="d-flex align-items-center">
                            <div class="recent-info">
                                <span>No Recent Media Exists</span>
                            </div>
                        </div>
                    </li> -->
                </ul>
                <ul *ngIf="!showAllMediaItem" class="recent-item-list m-0">
                    <li *ngFor="let recent of totalMediaItem ; let i = index">
                        <div class="d-flex align-items-center"
                            [ngClass]="[carouselArtworkType === 'WIDE' ? 'carouselWide' : '' || carouselArtworkType === 'BANNER' ? 'carouselBanner' : '']">
                            <!-- <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg : ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span> -->
                            <span class="user-img"
                                [ngStyle]="{'background-color': recent.squareArtwork ? recent.squareArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.squareArtwork ? recent.squareArtwork.document.newimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-wide"
                                [ngStyle]="{'background-color': recent.wideArtwork ? recent.wideArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType === 'WIDE' && carouselArtworkType !== 'BANNER'">
                                <img [src]="recent.wideArtwork ? recent.wideArtwork.document.newwideimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <span class="user-img-banner"
                                [ngStyle]="{'background-color': recent.bannerArtwork ? recent.bannerArtwork.document.imageColur : '#e6e6e6'}"
                                *ngIf="carouselArtworkType !== 'WIDE' && carouselArtworkType === 'BANNER'">
                                <img [src]="recent.bannerArtwork ? recent.bannerArtwork.document.newbannerimg :  ''"
                                    onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;" alt="">
                            </span>
                            <div class="recent-info">
                                <label for="selected-allMediaItem-{{i}}">

                                    <p class="pointer ellipsis-text" (click)="goTo(recent, 'vidItem')"
                                        (dblclick)="dblClickselectItems(recent)">
                                        {{recent.title}}</p>
                                    <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                </label>
                                <div class="recent-chips"
                                    *ngIf="recent.isReadyToPublish === false  && recent.status === 'DRAFT'">
                                    <span class="red-bg">Draft</span>
                                </div>
                                <div class="recent-chips"
                                    *ngIf="recent.isReadyToPublish === true && recent.status === 'DRAFT'">
                                    <span tooltip="Ready to Publish" class="brown-bg">Ready</span>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="clickedFrom !== 'notification'">
                            <span class="form-check">
                                <input type="checkbox" class="form-check-input" name=""
                                    id="selected-allMediaItem-{{i}}" (change)="selectItems($event, recent)"
                                    [disabled]="!recent.itemChecked || recent.status === 'DRAFT'"
                                    [(ngModel)]="recent.itemChecked">
                            </span>
                        </div>
                        <div *ngIf="clickedFrom === 'notification'">
                            <input type="radio" name="recent" [disabled]="recent.status === 'DRAFT'"
                                id="selected-allMediaItem-{{i}}" value="{{recent.id}}"
                                (change)="selectItems($event, recent)">
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="!showAllMediaItem" class="app-filters mt-20 d-flex justify-content-center">
                <button class="btn btn-white" *ngIf="totalrecentMedia>4" (click)="showmoreMediaItem()">Show
                    more</button>
            </div>
        </div>



    </ng-container>
</div>
