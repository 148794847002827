import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { AuthenticateService } from 'src/app/services/authenticateService/authenticate.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {
  code: any;
  token: any;
  id:any
  showbuttons= true;
  logoImage : any;
  logoSrcFound:boolean;
  logoId: any;
  brandColor:any;
  orgName:any;
  defaultSrc = " "
  showmsg= true;
  isVerify : boolean = true
  created: string;
  subDomain: string
  constructor(   public router: Router,
    public route : ActivatedRoute,
    public authenticateService:AuthenticateService,
    public messageService: MessageService,

    ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      // this.logoId = params['logoId'];
      this.token = params['token'];
      this.created = params['created'] ? params['created'] : 'user';

    // this.logoId = `${appConfig.imageURL}` + this.logoId
    //   if(this.logoId){
    //     this.logoSrcFound=true;
    //   }
    //   else{
    //     this.logoSrcFound=false;
    //   }
      // this.orgName = params.orgName; 
      // this.brandColor = params['brandColor'];

    });


   this.showmsg = this.code==='null' ? false : true
   console.log(
    this.showmsg );
    this.getBranding()
    this.sendVerification(this.code,this.token);


  }

  sendVerification(code,token){
    this.authenticateService.verifyEmail(code,token).subscribe((res: any) => {
      this.showbuttons = false;
    }, (err: any) => {
      console.log(err);
      console.log(err.error.errors[0].fieldError);
      console.log(err.message);
      // this.messageService.add({ severity: 'error', summary: 'Error', detail:err.message });

      if(err.error.errors[0].fieldError === 'Token is expired'){
        this.isVerify = false
        console.log(this.isVerify);
        // console.log(err.errors[0].fieldError);        
        
      }
    
      else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail:err.message });
      }
    } 
  
    )
  }

  getBranding(){
    this.authenticateService.getBrandingColor(this.token).subscribe((res: any) => {
      this.brandColor =res.data.brandingColor
      this.subDomain = res.data.subDomain
      this.logoId = res.data.logoId

      this.orgName = res.data.orgName
      console.log(this.brandColor);
      this.logoId = `${appConfig.imageURL}` + this.logoId

      if(res.data.logoId){
        this.logoSrcFound=true;
      }
      else{
        this.logoSrcFound=false;
      }
      console.log(this.brandColor);
    }, (err: any) => {
      console.log(err);
      
      this.messageService.add({ severity: 'error', summary: 'Error', detail:err.message });
   
    }  
    )
  }

  openLink() {
    var subDomainUrl = this.subDomain
    if (subDomainUrl.includes("https://")) {
      window.open(subDomainUrl, '_blank')
    }
    else {
      window.open("https://" + subDomainUrl, '_blank');
    }
  }

}
