import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { Channel } from 'src/app/models/channel.model';
import { MediaItem } from 'src/app/models/media.model';
import { AppsService } from 'src/app/services/apps/apps-service';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { EbookreaderService } from 'src/app/services/collection/ebook/ebookreader.service';
import { EventService } from 'src/app/services/events/event-services';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { MediaService } from 'src/app/services/media/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-add-channel-panel',
  templateUrl: './add-channel-panel.component.html',
  styleUrls: ['./add-channel-panel.component.css']
})
export class AddChannelPanelComponent implements OnInit {

  opacity = false;
  overlay = false;
  sidePanel = false;
  clickedFrom: string
  totalMediaItem: any[]=[]
  recentMedia: any[]=[]
  newMediaItemData: any[] = []
  newChannelData : any[] = []
  selectedMediaItems: any[] = []
  selectedItems: any[] = [];
  arrayMobile: any[]=[]
  combined1: any[]=[]
  combined: any[]=[]
  totalMediaItems = 0;
  totalrecentMedia = 0
  tabId: any;
  panel: string
  type: string
  tabType: string
  showAllMediaItem = false
  mediaItems = false;
  selectAll = false;
  showOptions = true
  mediaitemChecked = false
  createMedIt = false
  showall = false;
  mediaItem: Channel;
  subsciptionData = new Subscription();
  subsciptionData2 = new Subscription()
  @ViewChild('searchMediaItems') searchMediaItems: ElementRef;
  timeZone: any;
  profitable: any;
  routeFrom: any;
  tvMed = false
  carouselArtworkType: any
  constructor(
    public libraryService: LibraryServiceService,
    public mediaService: MediaService,
    public ebookService: EbookreaderService,
    public messageService: MessageService,
    public artWorkService: ArtWorkService,
    public sharedService: SharedService,
    public route: ActivatedRoute,
    public router: Router,
    public eventService: EventService,
    public appsService: AppsService,
  ) {this.mediaItem = new Channel(); }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
    if (this.subsciptionData2) {
      this.subsciptionData2.unsubscribe();
    }
  }

  ngAfterViewInit() {
    fromEvent(this.searchMediaItems.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      this.getMediaItems(1, 10);
      this.showAllMediaItem = false
    })).subscribe();
  }

  // getSubscriptionData() {

  //   this.timeZone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.timeZone : '';
  //   this.profitable = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.profit : '';
  //   this.emptySearch()
    
  //   this.subsciptionData.add(
  //     this.libraryService.panel$.subscribe(
  //       (state: any) => {
  //         this.overlay = state.showPanel
  //         this.sidePanel = state.showPanel;
  //         if (state.tvMed) {
  //           this.tvMed = state.tvMed
  //         }
  //         this.panel = state?.panel ? state?.panel : null;
  //         this.tabId = state?.tabId ? state?.tabId : null;
  //         // this.opacity = state.opacity
  //         this.clickedFrom = state.clickedFrom
  //         if (this.route.snapshot.paramMap['params']['getFrom']) {
  //           this.routeFrom = this.route.snapshot.paramMap['params']['getFrom']
  //         }
  //         if (this.tvMed) {
  //           this.getMediaItems(1, 10)
  //         }
  //         if (this.clickedFrom === 'videoItem') {
  //           this.mediaitemChecked = true
  //           this.mediaItems = true
  //           this.showAllMediaItem = false
  //           this.showOptions = true
  //           if (!state.selectData) {
  //             this.selectedMediaItems = []
  //           }
  //           this.getMediaItems(1, 10)
  //         } 
  //         // else if(this.folderChecked){
  //         //   this.showallFolder = false
  //         //   this.listItems = true
  //         //   this.createListIt = false
  //         //   this.showOptions = true
  //         //   this.getListItem(1,5)
  //         // }
  //         else {
  //           if (this.clickedFrom === 'ebook') {
  //             // this.getEbooklist(1, 10)
  //           }
  //           else {
  //             if (this.clickedFrom === 'mobile') {
  //               this.getMediaItems(1, 1000)
  //             }
  //             else {
  //               if (state.itemType === 'vidItem') {
  //                 let element: HTMLElement = document.getElementById('mediaItem') as HTMLElement;
  //                 element.click();
  //               }
  //             }
  //           }
  //         }
  //       })
  //   );

  //   this.subsciptionData.add(
  //     this.libraryService.sendartworktype$.subscribe((response: any) => {
  //       this.carouselArtworkType = response?.carouselArtworkType ? response?.carouselArtworkType : null
  //       // //  this.clickedFrom = response.clickedFrom
  //       //  

  //     }),
  //   )

  //   this.subsciptionData.add(
  //     this.libraryService.sendDataListToAddList$.subscribe((response: any) => {
  //       this.responseData = response
  //       if (this.combined.length === 0) {
  //         this.selectedMediaSeriesItems = []
  //         this.selectedStreamItems = [];
  //         this.selectedMediaItems = []
  //         this.selectedLinkItems = []
  //         this.selectedFolderItems = []
  //         this.selectedRssItems = []
  //         this.selectedEbookItem = []
  //         this.selectedAlbumItems = []
  //         this.selectedSongsItems = []
  //         this.selectedEventItems = []
  //         this.selectedCalenderItems = []
  //         this.medSeLen = 0
  //         this.selMedSeLen = 0
  //         this.selLiveStreamLen = 0
  //         this.medItLen = 0
  //         this.selMedItLen = 0
  //         this.folItLen = 0
  //         this.selFolItLen = 0
  //         this.linItLen = 0
  //         this.selLinItLen = 0
  //         this.rssItLen = 0
  //         this.ebookLen = 0
  //         this.selRssItLen = 0
  //         this.selEbookLen = 0
  //         this.songItLen = 0
  //         this.selSongLen = 0
  //         this.eventItLen = 0
  //         this.selEventLen = 0
  //         this.calenderItLen = 0
  //         this.selCalenderItLen = 0
  //         this.AlbItLen = 0
  //         this.selAlbItLen = 0
  //       }
  //     })
  //   );

  //   this.subsciptionData.add(
  //     this.libraryService.sendSaveCarousel$.subscribe((response: any) => {
  //       this.arrayMobile = response
  //       if (this.arrayMobile.length === 5) {
  //         this.popUp = true
  //       }
  //       else if (this.arrayMobile.length === 0) {
  //         this.selectedMediaSeriesItems = []
  //         this.selectedStreamItems = [];
  //         this.selectedMediaItems = []
  //         this.selectedLinkItems = []
  //         this.selectedFolderItems = []
  //         this.selectedRssItems = []
  //         this.selectedEbookItem = []
  //         this.selectedAlbumItems = []
  //         this.selectedSongsItems = []
  //         this.selectedEventItems = []
  //         this.selectedCalenderItems = []
  //         this.selectedEbookItems = []
  //         this.medSeLen = 0
  //         this.selMedSeLen = 0
  //         this.selLiveStreamLen = 0
  //         this.medItLen = 0
  //         this.selMedItLen = 0
  //         this.folItLen = 0
  //         this.selFolItLen = 0
  //         this.linItLen = 0
  //         this.selLinItLen = 0
  //         this.rssItLen = 0
  //         this.ebookLen = 0
  //         this.selRssItLen = 0
  //         this.selEbookLen = 0
  //         this.songItLen = 0
  //         this.selSongLen = 0
  //         this.eventItLen = 0
  //         this.selEventLen = 0
  //         this.calenderItLen = 0
  //         this.selCalenderItLen = 0
  //         this.AlbItLen = 0
  //         this.selAlbItLen = 0
  //         this.popUp = false
  //       }
  //       else {
  //         this.popUp = false
  //       }
  //       if (this.arrayMobile.length > 0) {
  //         this.medSeLen = 0
  //         this.selMedSeLen = 0
  //         this.selLiveStreamLen = 0
  //         this.medItLen = 0
  //         this.selMedItLen = 0
  //         this.folItLen = 0
  //         this.selFolItLen = 0
  //         this.linItLen = 0
  //         this.selLinItLen = 0
  //         this.rssItLen = 0
  //         this.ebookLen = 0
  //         this.selRssItLen = 0
  //         this.selEbookLen = 0
  //         this.songItLen = 0
  //         this.selSongLen = 0
  //         this.eventItLen = 0
  //         this.selEventLen = 0
  //         this.calenderItLen = 0
  //         this.selCalenderItLen = 0
  //         this.AlbItLen = 0
  //         this.selAlbItLen = 0
  //         this.arrayMobile.forEach((el) => {
  //           if (el.type === 'MEDIASERIES') {
  //             this.medSeLen = this.medSeLen + 1
  //             this.selMedSeLen = this.medSeLen
  //           }
  //           else if (el.type === 'MEDIA_ITEM') {
  //             this.medItLen = this.medItLen + 1
  //             this.selMedItLen = this.medItLen
  //           }
  //           else if (el.type === 'LIST') {
  //             this.folItLen = this.folItLen + 1
  //             this.selFolItLen = this.folItLen
  //           }
  //           else if (el.type === 'LINK') {
  //             this.linItLen = this.linItLen + 1
  //             this.selLinItLen = this.linItLen

  //           }
  //           else if (el.type === 'RSSFEED') {
  //             this.rssItLen = this.rssItLen + 1
  //             this.selRssItLen = this.rssItLen
  //           }
  //           else if (el.type === 'EBOOK_READER') {
  //             this.ebookLen = this.ebookLen + 1
  //             this.selEbookLen = this.ebookLen
  //           }
  //           else if (el.type === 'SONG') {
  //             this.songItLen = this.songItLen + 1
  //             this.selSongLen = this.songItLen
  //           }
  //           else if (el.type === 'EVENT') {
  //             this.eventItLen = this.eventItLen + 1
  //             this.selEventLen = this.eventItLen
  //           }
  //           else if (el.type === 'CALENDAR') {
  //             this.calenderItLen = this.calenderItLen + 1
  //             this.selCalenderItLen = this.calenderItLen
  //           }
  //           else {
  //             this.AlbItLen = this.AlbItLen + 1
  //             this.selAlbItLen = this.AlbItLen
  //           }
  //         })
  //       }
  //       // this.getMediaSeries(1, 10)
  //     })
  //   )

  //   // this.getMediaSeries(1, 10)
  //   // this.getLinkItems(1,5)

  //   this.subsciptionData.add(
  //     this.libraryService.sendCarouselMobileLength$.subscribe((response: any) => {
  //       this.carouselMaxLength = response
  //     })
  //   )


  //   this.totalCheckedLength = (this.selMedSeLen + this.selLiveStreamLen + this.selMedItLen + this.selFolItLen + this.selLinItLen + this.selAlbItLen + this.selRssItLen + this.selEbookLen + this.selSongLen)
    
  //   this.subsciptionData.add(
  //     this.libraryService.sendRemovedData$.subscribe((response: any) => {
  //       this.removedDataArray = response

  //       this.removedDataArray
        
        
  //       this.removedDataArray.forEach((el) => {
  //         if (el.type === 'MEDIASERIES') {
  //           this.selectedMediaSeriesItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedMediaSeriesItems.indexOf(element)
  //               this.selectedMediaSeriesItems.splice(i, 1)
  //               this.selMedSeLen = this.selectedMediaSeriesItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }
  //         else if (el.type === 'MEDIA_ITEM') {
  //           this.selectedMediaItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedMediaItems.indexOf(element)
  //               this.selectedMediaItems.splice(i, 1)
  //               this.selMedItLen = this.selectedMediaItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }
  //         else if (el.type === 'LIST') {
  //           this.selectedFolderItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedFolderItems.indexOf(element)
  //               this.selectedFolderItems.splice(i, 1)
  //               this.selFolItLen = this.selectedFolderItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }
  //         else if (el.type === 'LINK') {
  //           this.selectedLinkItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedLinkItems.indexOf(element)
  //               this.selectedLinkItems.splice(i, 1)
  //               this.selLinItLen = this.selectedLinkItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }
  //         else if (el.type === 'RSSFEED') {
  //           this.selectedRssItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedRssItems.indexOf(element)
  //               this.selectedRssItems.splice(i, 1)
  //               this.selRssItLen = this.selectedRssItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }

  //         else if (el.type === 'EBOOK_READER') {
  //           this.selectedEbookItem.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedEbookItem.indexOf(element)
  //               this.selectedEbookItem.splice(i, 1)
  //               this.selEbookLen = this.selectedEbookItem.length
  //               this.newFunction()
  //             }
  //           })
  //         }

  //         else if (el.type === 'EBOOK') {
  //           this.selectedEbookItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedEbookItems.indexOf(element)
  //               this.selectedEbookItems.splice(i, 1)
  //               // this.selEbookLen = this.selectedEbookItem.length
  //               // this.newFunction()
  //             }
  //           })
  //         }

  //         else if (el.type === 'MUSIC') {
  //           this.selectedSongsItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedSongsItems.indexOf(element)
  //               this.selectedSongsItems.splice(i, 1)
  //               this.selSongLen = this.selectedSongsItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }

  //         else if (el.type === 'EVENT') {
  //           this.selectedEventItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedEventItems.indexOf(element)
  //               this.selectedEventItems.splice(i, 1)
  //               this.selEventLen = this.selectedEventItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }

  //         else if (el.type === 'CALENDER') {
  //           this.selectedCalenderItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedCalenderItems.indexOf(element)
  //               this.selectedCalenderItems.splice(i, 1)
  //               this.selCalenderItLen = this.selectedCalenderItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }

  //         else {
  //           this.selectedAlbumItems.forEach((element) => {
  //             if (el.id === element.id) {
  //               element.itemChecked = false
  //               let i = this.selectedAlbumItems.indexOf(element)
  //               this.selectedAlbumItems.splice(i, 1)
  //               this.selAlbItLen = this.selectedAlbumItems.length
  //               this.newFunction()
  //             }
  //           })
  //         }
  //       })
  //     })

  //   );

  // }
  
  closePanel(type) {
    this.panel = null
    this.tabType = null
    this.tabId = null
    this.sidePanel = false;
    this.showall = false;
    this.overlay = false;
    this.libraryService.updatePanel({ showPanel: false, opacity: false })
    
    this.mediaItems = true
    this.createMedIt = false
    this.showOptions = true
    this.mediaitemChecked = false
    
    this.combined = []
    this.combined1 = []
    if (type === 'cancel') {
      this.newChannelData = [];
    }
  }

  sendData(newData) {
    if (this.clickedFrom === 'mobile') {
      this.combined = [...this.newMediaItemData, ...this.arrayMobile]
    }
    else {
      this.combined = [...this.newMediaItemData]
    }
    this.combined.map((el, i) => ({
      id: el.id,
      sortOrder: i,
      type: el.type
    }))
    if (this.clickedFrom === 'mobile') {
      this.combined1 = [...this.newChannelData, ...this.arrayMobile]
    } else {
      this.combined1 = [...this.newChannelData]
    }
    this.sendMediaSeriesId(this.combined)
    this.sendFolderId(this.combined1)
    this.libraryService.updatePanel({ showPanel: false })

    // this.rss = false
    // this.song = false
    // this.album = false
    // this.mediaItems = false
    // this.mediaSeries = true
    // this.listItems = false
    // this.linkItem = false
    this.closePanel('apply');
  }

  sendFolderId(folderId) {
    // this.combined1 = [...this.newData1, ...this.newData2, ...this.newData3, ...this.newData4, ...this.newData5, ...this.newData6]
    if (this.clickedFrom === 'list' || this.clickedFrom === 'buildOwnView' || this.clickedFrom === 'ebook' || this.clickedFrom === 'notification' || this.clickedFrom === 'tvApp' || this.clickedFrom === 'podcast' || this.clickedFrom === 'ebookItem' || this.clickedFrom === 'videoItem' || this.clickedFrom === 'albumItem' || this.clickedFrom === 'calendarItem') {
      this.libraryService.sendFolderItem(this.combined1)
    } else {
      this.libraryService.sendMobileDat2(this.combined1)
    }
  }

  sendMediaSeriesId(newData) {
    if (this.clickedFrom === 'list' || this.clickedFrom === 'buildOwnView' || this.clickedFrom === 'ebook' || this.clickedFrom === 'notification' || this.clickedFrom === 'tvApp' || this.clickedFrom === 'podcast' || this.clickedFrom === 'ebookItem' || this.clickedFrom === 'videoItem' || this.clickedFrom === 'albumItem' || this.clickedFrom === 'calendarItem') {
      this.libraryService.sendMediaSeries(newData)
    }
    else {
      this.libraryService.sendMobileDat1(newData)
    }
  }

  createMedItem(type) {
    this.type = type
    this.mediaItems = false
    this.mediaitemChecked = true
    this.createMedIt = true
    this.showOptions = false
    this.mediaItem = new Channel()

  }

  closeMediaItem(type) {
    this.type = type
    this.mediaItems = true
    this.createMedIt = false
    this.showOptions = true
  }

  createMediaItem(mediaItem) {
    // mediaItem.itemType = 'CHANNEL'
    mediaItem.mediaSeriesId = this.tabId
    mediaItem.itemType = this.type
    this.mediaService.createMediaItem( mediaItem).subscribe((response: any) => {
      if (this.type === 'CHANNEL') {
        this.getMediaItems(1, 10);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Channel created Successfully.' });
      }

    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
    if(this.clickedFrom === 'ebookItem' || this.clickedFrom === 'videoItem'){
      this.libraryService.sendUpdateToCreate(true)
    }
      this.mediaItems = true
      this.mediaitemChecked = true

    // this.mediaItems = true;
    this.createMedIt = false;
    this.showOptions = true
  }

  dblClickselectItems(item){
    if(item.status !== 'DRAFT'){
      this.selectItems('dbl',item);
      this.saveData();
    }
  }
  
  saveData() {
    this.sendData(this.selectedItems)
  }

  goTo(data, type) {
    if (data?.status && data?.status === 'DRAFT') {
      if (this.panel === 'add-series-panel') {
        let panelType = 'add-series-panel'
        this.router.navigate([`/media-module/media/on-demand/edit-media-item/${data.id}`], { queryParams: { type: type, from: panelType, serId: this.tabId } });
      }
    }
  }

  selectItems(event, item) {
    if (item === 'all') {
      if(event === 'dbl'){this.selectAll = true}
      else{this.selectAll = event.target.checked;}
      this.recentMedia.forEach((el) => {
        if(event === 'dbl'){el.itemChecked = true}
        else{el.itemChecked = event.target.checked}
      });
      if(event === 'dbl'){this.selectedMediaItems = true ? this.recentMedia : [];}
      else{this.selectedMediaItems = event.target.checked ? this.recentMedia : [];}

    } else {
      this.recentMedia.forEach((el) => {
        if (el.id === item.id) {
          if(event === 'dbl'){el.itemChecked = true;}
            else{el.itemChecked = event.target.checked;}
          if (el.itemChecked === false) {
            this.arrayMobile.forEach((data, i) => {
              if (data.id === item.id) {
                this.arrayMobile.splice(i, 1)
              }
            })
          }
        }
      });
        this.selectedMediaItems = this.recentMedia.filter((el) => el.itemChecked);
      this.selectAll = (this.selectedMediaItems.length === this.recentMedia.length);
      this.newChannelData = this.selectedMediaItems;
      
      this.newChannelData = this.newChannelData.map((el) => ({
        bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : '',
        id: el.id,
        itemChecked: el.itemChecked,
        squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : '',
        subtitle: el.subTitle,
        title: el.title,
        wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : '',
        type: 'CHANNEL'
      }))
      this.newMediaItemData = this.selectedMediaItems.map((el) => ({
        id: el.id,
        sortOrder: 2,
        type: 'CHANNEL',
      }))

    }

    if(this.clickedFrom === 'mobile'){
      if(this.arrayMobile.length>0){
        this.arrayMobile.forEach((el,i)=>{
          if(el.id === item.id){
            this.arrayMobile.splice(i,1)
          }
        })
      }
    }
    // if(this.carouselMaxLength === 0 ){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 1){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 2){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 3){
    //   this.popUp = false
    // }
    // else if(this.carouselMaxLength === 4){
    //   this.popUp = false
    // }
    // else{
    //   this.popUp = true
    // }

  }

  showmoreMediaItem() {
    this.showAllMediaItem = true
    this.getMediaItems(1, this.totalMediaItems)
  }

  getMediaItems(page, size) {
    this.mediaItems = true;
    this.newChannelData = []
    this.newMediaItemData = []

    this.mediaService.getMediaItems(page, size, this.searchMediaItems ? this.searchMediaItems.nativeElement.value : '', 'CHANNEL', '').subscribe((response: any) => {
      this.recentMedia = response.data.content;
      this.totalrecentMedia = response.data.totalElements
      // this.recentMedia = this.recentMedia.filter((el,i)=>{
      //   if(el.status === "PUBLISHED" || el.status === "SCHEDULED"){
      //           return el
      //         }
      // })
      if (this.arrayMobile) {
        let count = 0
        this.arrayMobile.forEach((data) => {
          this.recentMedia.forEach(el => {
            if (data.id === el.id && data.title === el.title) {
              el['itemChecked'] = true
              count = count + 1
              this.newChannelData.push(data);
            }
          });
        })
        // this.selectedMediaItems = []
        this.selectedMediaItems.forEach((data => {
          this.recentMedia.forEach((el) => {
            if (data.id === el.id && data.title === el.title) {
              el['itemChecked'] = true
            }
          })
        }))
      }

      this.newMediaItemData = this.selectedMediaItems.map((el) => ({
        id: el.id,
        sortOrder: 2,
        type: 'CHANNEL',
      }))


      this.totalMediaItems = response.data.totalElements;
      // if (response.data.totalElements < 5) {
      //   this.showAllMediaItem = true
      // }else{
      //   this.showAllMediaItem = false
      // }

      this.totalMediaItem = this.recentMedia.slice(0, 4)
      this.recentMedia.forEach(data => {
        if (data.squareArtwork) {
          // if (data.squareArtwork.document.bdImage === true) {
          if (data.squareArtwork.artworkType === "SQUARE") {
            let id = data.squareArtwork.document.id;
            data.squareArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.squareHeight}` + '&width=' + `${appConfig.squareWidth}`;
          }
          // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
          //   this.sharedService.updateLoader(false);
          //   data.squareArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

          // })
          // }
        }
        if (data.bannerArtwork) {
          // if (data.bannerArtwork.document.bdImage === true) {
          if (data.bannerArtwork.artworkType === "BANNER") {
            let id = data.bannerArtwork.document.id;
            data.bannerArtwork.document['newbannerimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.bannerHeight}` + '&width=' + `${appConfig.bannerWidth}`;
          }
          // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
          //   this.sharedService.updateLoader(false);
          //   data.bannerArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

          // })
          // }
        }
        if (data.wideArtwork) {
          // if (data.wideArtwork.document.bdImage === true) {
          if (data.wideArtwork.artworkType === "WIDE") {
            let id = data.wideArtwork.document.id;
            data.wideArtwork.document['newwideimg'] = `${appConfig.imageURL}` + id + '?height=' + `${appConfig.wideHeight}` + '&width=' + `${appConfig.wideWidth}`;
          }
          // this.artWorkService.getImages(id,width,height).subscribe((response: any) => {
          //   this.sharedService.updateLoader(false);
          //   data.wideArtwork.document['newimg'] = 'data:image/jpeg;base64,' + response.data.picByte;

          // })
          // }
        }
      })
    });
  }
}
