<div class="panel-overlay" [@overlay]="overlay"></div>
<div class="slide-panel" [@sidePanel]="sidePanel">
    <div class="panels-header">
        <div class="d-flex align-items-center justify-content-between p-4">
            <h4>Calendars</h4>
            <img (click)="closePanel()" src="./../../../../assets/images/library/close.svg" alt=""
                style="cursor: pointer;">
        </div>
    </div>

    <div class="app-block">
        <!-- <div class="app-block-title">
            <p class="info-title">Recent Media Series</p>
        </div> -->
        <div class="app-filters d-flex justify-content-center">
            <button (click)="changeCalender()" class="btn btn-white mr-10">Move</button>
            <button class="btn btn-white" (click)="cancel()">Cancel</button>
        </div>
        <!-- <div class=" d-flex justify-content-center pt-2">
            
        </div> -->
        <div class="app-search app-search-big search-box" style="width: 356px;">
            <input type="text" placeholder="Search" class="form-control w-100 " #searchInput>
        </div>
        <ul class="recent-item-list m-0">
            <div class="media-series-container">
                <li *ngFor="let series of calenderItem">
                    <label for={{series.id}} style="width: 100%;">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <!-- style="background-color: #e6e6e6;" -->
                                    <span class="user-img"
                                        [ngStyle]="{'background-color': series.squareArtwork ? series.squareArtwork.document.imageColur : '#e6e6e6'}">
                                        <!-- <img [src]="series.squareArtwork ? series.squareArtwork.document.newimg : (series.wideArtwork ? series.wideArtwork.document.newimg : (series.bannerArtwork ? series.bannerArtwork.document.newimg : 'assets/images/dashboard/bg.jpg')) "
                                        alt="" onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0;"> -->

                                        <img [src]="series.squareArtwork ? series.squareArtwork.document.newimg : ''"
                                            alt="" onload="style.opacity = 1"
                                            style="background-color: #e6e6e6;opacity: 0;">

                                    </span>
                                    <div class="recent-info">
                                        <p class="pointer" (change)="selectedCalender(series)">
                                            {{series.title}}</p>
                                        <!-- <span>Click here to watch 24/7 Livestream</span> -->
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <input type="radio" name="medSer" id={{series.id}}
                                    [checked]="radCheck && series.itemChecked" (change)="selectedCalender(series)">
                            </div>
                        </div>
                    </label>

                </li>

                <li *ngIf="!calenderItem.length" class="no-media">
                    <div class="d-flex align-items-center">
                        <div class="recent-info">
                            <span>No item exists</span>
                        </div>
                    </div>
                </li>

                <div class="app-filters mt-20 d-flex justify-content-center" *ngIf="!showall">
                    <button *ngIf="calenderItem.length>4" class="btn btn-white" (click)="showmore()">Show more</button>
                </div>
            </div>
        </ul>
    </div>



</div>