import * as moment from "moment";

export class LoginModel {
  username: string;
  password: string;
}

export class SignUpModel {
  name: string;
  subscriptionPlanId: string;
  basicInfo = new BasicInfo();
  mailingAddress = new MailingAddress();
  socialMediaDTO = new SocialMedia();
  sourceType: any;
  isTermAndCondition:boolean
  constructor(obj?) {
    this.subscriptionPlanId = '';
    this.isTermAndCondition = obj ? obj.isTermAndCondition : false;

  }
}

export class BasicInfo {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

export class MailingAddress {
  addressLine1: string;
  apartment: string;
  city: string;
  postalCode: string;
  state: string;
  constructor() {
    this.state = null;
  }
}

export class SocialMedia {
  fb: string;
  insta: string;
  tiktok: string;
  twitter: string;
}

export class ResetPassword {
  password: string;
  confirmPassword: string;
  token: string;
}


export class SignUpOrgModel {
  email: string
  firstName: string
  lastName: string
  mobileNumber: string
  organizationName: string
  password: string
  subscriptionPlanId: number
  userName: string;
  profit: boolean
  isTermAndCondition : boolean
  constructor(obj?) {
    this.profit = obj ? obj.profit : false;
    this.isTermAndCondition = obj ? obj.isTermAndCondition : false;

    // this.subscriptionPlanId = '';
  }
}

const newDate = new Date();
const sendDate = moment(newDate).format('YYYY-MM-DD')
export const emptyPlayListData = [
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "03:00:00", groupTitle: "No Data Available", playlist: '',startTime: "00:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "06:00:00", groupTitle: "No Data Available", playlist: '',startTime: "03:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "09:00:00", groupTitle: "No Data Available", playlist: '',startTime: "06:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "12:00:00", groupTitle: "No Data Available", playlist: '',startTime: "09:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "15:00:00", groupTitle: "No Data Available", playlist: '',startTime: "12:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "18:00:00", groupTitle: "No Data Available", playlist: '',startTime: "15:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "21:00:00", groupTitle: "No Data Available", playlist: '',startTime: "18:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
  {channelName: "No Data Available", channelUri: 'null', duration: null, date: sendDate, endTime: "23:59:59", groupTitle: "No Data Available", playlist: '',startTime: "21:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Data Available"},
]

export class UpgradeStoragePlanModel {
  email: string
  firstName: string = '100 GB'
  lastName: string
  mobileNumber: string
  organizationName: string='Basic'
  password: string
  subscriptionPlanId: number
  userName: string;
  profit: boolean
  isTermAndCondition : boolean
  constructor(obj?) {
    this.profit = obj ? obj.profit : false;
    this.isTermAndCondition = obj ? obj.isTermAndCondition : false;

    // this.subscriptionPlanId = '';
  }
}