<div class="main">
    <div class="sub-main" [ngStyle]="{height: !showCard ? '100vh': '100%'}">
        <div class="logo-container">
            <img src="assets/images/web/orgNowcast.png" alt="logo">
        </div>

        <div class="content-wrap">
            <div class="content">
                <form #signUpForm="ngForm" >
                    <!-- <div class="logo-container" *ngIf="signUpLogo">
                        <img [src]="signUpLogo" alt="logo">
                    </div> -->
                    <div class="app-content-inner">
                        <div class="">
                            <div class="row">
                                <p class="info-title">Basic Information</p>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Plan Name</label>
                                        <input type="text" class="form-control disable" [(ngModel)]="subscriptionData.subscriptionPlanName" disabled
                                            name="organizationName" required #organizationName="ngModel"
                                            appWhiteSpaceValidator>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label> {{ dataStorageType === 'STORAGE' ? 'Current Storage usage' : 'Current VOD Usage' }} </label>
                                        <input type="text" class="form-control disable" [(ngModel)]="subscriptionData.currentPlan.usage" disabled
                                            name="firstName" required #firstName="ngModel" appWhiteSpaceValidator>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>{{ dataStorageType === 'STORAGE' ? 'Storage usage plans' : 'VOD usage plans' }} </label>
                                        <span class="app-dropdown d-block m-0">
                                            <select name="organizationType" [ngClass]="{disable: showCard}" (change)="onChange($event)"
                                                [(ngModel)]="selectedPlanUsage" class="form-control">
                                                <option value="select" selected>Select</option>
                                                <option [value]="el.id" *ngFor="let el of subscriptionData.dataUsagePricingInfoDTOS">
                                                    {{el.dataUsageName}}
                                                </option>
                                            </select>
                                        </span>
                                        <!-- <p-dropdown [options]="profitOption" style="padding: 0 !important;" [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.profit" name="organizationType" (onChange)="selectOrgType($event)" class="form-control" optionLabel="name"></p-dropdown> -->
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <!-- <label class="label">Organization type</label>
                                    <select name="organizationType">

                                    </select> -->
                                    <div class="form-group required">
                                        <label>Plan Price</label>
                                        <input type="text" class="form-control disable" [(ngModel)]="selectedPlanPrice" disabled 
                                        name="priceField" required #priceField="ngModel">
                                        
                                        <!-- <p-dropdown [options]="profitOption" style="padding: 0 !important;" [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.profit" name="organizationType" (onChange)="selectOrgType($event)" class="form-control" optionLabel="name"></p-dropdown> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container text-center mt-20" *ngIf="!showCard">
                            <!-- [disabled]="!signUpForm.valid  || !signUp.isTermAndCondition " -->
                            <button type="submit" class="btn btn-blue mr-10" (click)="signUpUser()"
                                >Continue</button>
                            <button class="btn btn-white mr-10 cancel-btn">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>


        <div class="app-content-inner" *ngIf="showCard">

            <div class="cardDetail mt-20">
                <div class="">
                    <p class="info-title">Make Payment</p>

                    <div class=" d-flex align-items-center justify-content-center cards failed-wrapper mt-20">
                        <div class=" justify-content-between">
                            <div class="existing_card">
                                <div class="main-card">
                                    <h3 class="cardNumber">XXXX XXXX XXXX <span
                                            style="font-size: 18px;">{{cardLast4Digit}}</span></h3>
                                    <h3 class="cardMonth">{{cardexpMonth}}/{{cardexpYear}}</h3>
                                    <h3 class="cardName">{{cardPersonName}}</h3>
                                    <div class="cardBrand">
                                        <img *ngIf="cardBrand === 'MasterCard'"
                                            src="../../../assets/images/cards/card/mastercard_m.svg" alt="">
                                        <img *ngIf="cardBrand === 'Visa'" src="../../../assets/images/cards/card/visa.svg"
                                            alt="">
                                        <img *ngIf="cardBrand === 'UnionPay'"
                                            src="../../../assets/images/cards/card/union pay.svg" alt="">
                                        <img *ngIf="cardBrand === 'JCB'" src="../../../assets/images/cards/card/JCB.svg"
                                            alt="">
                                        <img *ngIf="cardBrand === 'American Express'"
                                            src="../../../assets/images/cards/card/American_express.svg" alt="">
                                        <img *ngIf="cardBrand === 'Discover'"
                                            src="../../../assets/images/cards/card/mastercard_m.svg" alt="">
                                        <img *ngIf="cardBrand === 'Diners Club'"
                                            src="../../../assets/images/cards/card/mastercard_m.svg" alt="">
                                    </div>
                                </div>
            
                            </div>
                            <div class="d-flex flex-colun justify-content-between mt-3 button-wrapper">
                                <button class="btn btn-blue mt-2" (click)="processNow()">Process Now</button>
                                <button class="btn btn-blue mt-2 ms-2" (click)="anotherCard = true">Replace Card</button>
                                <button (click)="showCard = false" class="btn btn-blue mt-2 ms-2" style="width:100px;">Cancel</button>
                            </div>
                        </div>
                    </div>


                    <div class="form-group mt-4 login-stripe" *ngIf="anotherCard">
                        <label for="">Card details </label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <input type="text" maxlength="50" class="form-control card-form"
                                        placeholder="Name on Card" [(ngModel)]="cardHolderName" name="holdername"
                                        #holdername="ngModel" required>
                                    <ng-container *ngIf="holdername.errors && (submitted)">
                                        <p class="error" [hidden]="!holdername.errors.required">
                                            Name is required</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="Card holder email"
                                        [(ngModel)]="cardHolderEmail" name="email" #email="ngModel" required
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                    <ng-container *ngIf="email.errors && (submitted)">
                                        <p class="error" [hidden]="!email.errors.pattern && !email.errors.required">
                                            Please enter a valid email</p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div>
                            <!-- <ngx-stripe-card [options]="cardOptions"
                                [elementsOptions]="elementsOptions"></ngx-stripe-card> -->
                                <form class="card-form-NMI">
                                    <div class="creditCardNumber">
                                        <i *ngIf="!cardFound" class="fas fa-credit-card" style='font-size: 24px;margin-right: 7px;'></i>
                                        <img *ngIf="cardFound" [src]="cardSource" style="height: 24px; width: auto; display: inline-block;" alt="cards">
                                        <div id="ccnumber"></div>
                                    </div>
                                    <div id="ccexp"></div>
                                    <div id="cvv"></div>
                                </form>
                                <div *ngIf="submitted && (!isCCNumberValid || !isCCExpValid || !isCVVValid)" style="margin: auto;">
                                    <p class="error">
                                        Invalid card details</p>
                                </div>
                            <ng-container *ngIf="cardError.error">
                                <p class="error">
                                    {{cardError.message}}</p>
                            </ng-container>
                        </div>

                        <div class="row mt-10">
                            <div class="col-md-12">
                                <div class="form-group required">
                                    <input type="text" maxlength="255" class="form-control card-form"
                                        placeholder="Address Line 1" [(ngModel)]="cardHolderAddressLine1"
                                        name="addressline1" #addressline1="ngModel" required>
                                    <ng-container *ngIf="addressline1.errors && (submitted)">
                                        <p class="error" [hidden]="!addressline1.errors.required">
                                            Address is required</p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group required">
                                    <input type="text" maxlength="255" class="form-control card-form"
                                        placeholder="Address Line 2" [(ngModel)]="cardHolderAddressLine2"
                                        name="addressline2" #addressline2="ngModel" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ng-container *ngIf="countryList.length;else countryInput">
                                        <span class="app-dropdown d-block m-0 input-width">
                                            <select class="form-control card-form" placeholder="Select Country"
                                                [(ngModel)]="cardHolderCountry" name="country" required
                                                #country="ngModel" (change)="updateStateList($event)">
                                                <option selected disabled value="">Select Country</option>
                                                <option *ngFor="let ct of countryList" value="{{ct.code2}}">
                                                    {{ct.name}}
                                                </option>
                                            </select>
                                            <ng-container *ngIf="country.errors && (submitted)">
                                                <p class="error" [hidden]="!country.errors.required">
                                                    Country is required</p>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                    <ng-template #countryInput>
                                        <input type="text" class="form-control card-form" placeholder="Country"
                                            [(ngModel)]="cardHolderCountry" maxlength="25" readonly
                                            [ngModelOptions]="{standalone: true}" name="country" #country="ngModel"
                                            required>
                                        <ng-container *ngIf="country.errors && (submitted)">
                                            <p class="error" [hidden]="!country.errors.required">
                                                Country is required</p>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ng-container *ngIf="stateList.length;else stateInput">
                                        <span class="app-dropdown d-block m-0 input-width">
                                            <select class="form-control card-form" placeholder="Select State"
                                                [(ngModel)]="cardHolderState" name="state" required #state="ngModel"
                                                (change)="updateCityList($event)">
                                                <option selected disabled value="">Select State</option>
                                                <option *ngFor="let ct of stateList" value="{{ct.name}}">
                                                    {{ct.name}}
                                                </option>
                                            </select>
                                            <ng-container *ngIf="state.errors && (submitted)">
                                                <p class="error" [hidden]="!state.errors.required">
                                                    State is required</p>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                    <ng-template #stateInput>
                                        <input type="text" class="form-control card-form" placeholder="State"
                                            [(ngModel)]="cardHolderState" maxlength="25"
                                            [ngModelOptions]="{standalone: true}" name="state" #state="ngModel"
                                            required>
                                        <ng-container *ngIf="state.errors && (submitted)">
                                            <p class="error" [hidden]="!state.errors.required">
                                                State is required</p>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ng-container *ngIf="cityList.length;else cityInput">
                                        <span class="app-dropdown d-block m-0 input-width">
                                            <select class="form-control card-form" placeholder="Select City"
                                                [(ngModel)]="cardHolderCity" name="state" required #city="ngModel">
                                                <option selected disabled value="">Select City</option>
                                                <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                                    {{ct.cityName}}
                                                </option>
                                            </select>
                                            <ng-container *ngIf="city.errors && (submitted)">
                                                <p class="error" [hidden]="!city.errors.required">
                                                    City is required</p>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                    <ng-template #cityInput>
                                        <input type="text" class="form-control card-form" placeholder="City"
                                            maxlength="25" [(ngModel)]="cardHolderCity" pattern="^[a-zA-Z ]*$"
                                            appWhiteSpaceValidator [ngModelOptions]="{standalone: true}" name="city"
                                            #city="ngModel" required>
                                        <ng-container *ngIf="city.errors && (submitted)">
                                            <p class="error" [hidden]="!city.errors.pattern">Please enter a valid city
                                            </p>
                                            <p class="error" [hidden]="!city.errors.required">
                                                City is required</p>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="Zipcode" maxlength="12" minlength="3"
                                        (keypress)="isNumberOnly($event)" [(ngModel)]="cardHolderZipcode" name="zipcode"
                                        #zipcode="ngModel" required>
                                    <ng-container *ngIf="zipcode.errors && (submitted)">
                                        <p class="error" [hidden]="!zipcode.errors.required">
                                            Zip Code is required</p>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="((cardHolderZipcode.toString().trim() !== '' && (cardHolderZipcode.toString().length < 5
                                            || cardHolderZipcode.toString().length > 9 ))) && submitted ">
                                                <p class="error">
                                                    Zip Code should be between 5 to 9 digits</p>
                                            </ng-container> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container text-center mt-20" *ngIf="anotherCard">
                <button type="submit" class="btn btn-blue mr-10" [disabled]="this.disableBtn"
                    (click)="createToken()">Pay</button>
                <button type="submit" (click)="anotherCard = false;disableFields=true"
                    class="btn btn-blue mr-10">Cancel</button>
            </div>
        </div>

    </div>
</div>

<p-dialog *ngIf="paymentModal" header="Payment" [(visible)]="paymentModal" [modal]="true" [draggable]="false"
    [resizable]="false">
    <div>
        Are you sure, you want to continue ?
    </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)="makePaymentNow()">Yes</button>
        <button type="button" class="btn btn-white" (click)="paymentModal = false">No</button>
    </p-footer>
</p-dialog>