import { Component, OnInit,Output,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppsService } from 'src/app/services/apps/apps-service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { MediaService } from 'src/app/services/media/media.service';
@Component({
  selector: 'app-app-downloads',
  templateUrl: './app-downloads.component.html',
  styleUrls: ['./app-downloads.component.css']
})
export class AppDownloadsComponent implements OnInit {
  orgId: number;
  pages: any[] = [];
  first = 0
  page: number = 1;
  rows: number = 10;
  totalRecords = 0;
  clickFrom: any;
  rowCount: number;
  startDate: any;
  endDate: any;
  @Input() start: string;
  @Input() end: string;


  constructor(
    public router: Router,
    public appsService: AppsService,
    private route: ActivatedRoute,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
   
    this.startDate = this.mediaService.getStartDate();
    this.endDate = this.mediaService.getEndDate();
    console.log("recieve start date===", this.startDate)
    console.log("recieve end date===", this.endDate)
    this.orgId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.organization.id : '';
    this.rowCount = (window.innerWidth >= 1600) ? 50 : 10;

    this.route.queryParams.subscribe(params => {
      const clickFrom = params['clickFrom'];
      this.clickFrom = clickFrom

      if (clickFrom === 'APP_DOWNLOAD') {
        this.getAppDownloadsData(this.page,this.rows,clickFrom,this.endDate,this.startDate)
      } else if (clickFrom === 'APP_LAUNCHES') {
        this.getAppLaunchesData(this.page, this.rows, clickFrom,this.endDate,this.endDate)
      }
    });
  }

  loadCustomers(event: LazyLoadEvent) {
    this.first = event.first
    this.page = (event.first / event.rows) + 1;
    this.rows = event.rows;
    this.getAppDownloadsData(this.page, this.rows, this.clickFrom, this.endDate, this.startDate);
  }


  getAppDownloadsData(page,size,clickFrom,endDate,startDate){
  this.appsService.getPushNotificationDetailsList(this.page, this.rows, this.orgId, clickFrom,'',this.endDate,this.startDate).subscribe((response: any) => {
    console.log('App Downloads Data----', response)
    this.totalRecords = response.data.totalElements;

    this.pages = response.data.content.map((el) => ({
      deviceId: el.deviceDto.deviceId,
      deviceModel: el.deviceDto.deviceModel,
      deviceOS: el.deviceDto.deviceOS === 'ANDROID' ? el.deviceDto.deviceOS = 'Android' : el.deviceDto.deviceOS ,
      deviceType: el.deviceDto.deviceType === 'MOBILE_IOS' ? el.deviceDto.deviceType = 'iOS MOBILE'
        : el.deviceDto.deviceType === 'MOBILE_ANDROID' ? el.deviceDto.deviceType = 'ANDROID MOBILE'
          : el.deviceDto.deviceType === 'TV_ANDROID' ? el.deviceDto.deviceType = 'ANDROID TV'
            : el.deviceDto.deviceType === 'TV_AMAZON' ? el.deviceDto.deviceType = 'AMAZON TV'
              : el.deviceDto.deviceType === 'TV_GOOGLE' ? el.deviceDto.deviceType = 'GOOGLE TV'
                : el.deviceDto.deviceType === 'TV_IOS' ? el.deviceDto.deviceType = 'iOS TV'     
            : el.deviceDto.deviceType,
      appVersion: el.deviceDto.appVersion,
      downloadDate: el.downloadDate
    }));

  })

  }

  getAppLaunchesData(page, size, clickFrom,endDate,startDate) {
    this.appsService.getPushNotificationDetailsList(this.page, this.rows, this.orgId, clickFrom,'',this.endDate,this.startDate).subscribe((response: any) => {
      console.log('App Downloads Data----', response)
      this.totalRecords = response.data.totalElements;
      this.pages = response.data.content.map((el) => ({
        deviceId: el.deviceDto.deviceId,
        deviceModel: el.deviceDto.deviceModel,
        deviceOS: el.deviceDto.deviceOS,
        deviceType: el.deviceDto.deviceType === 'MOBILE_IOS' ? el.deviceDto.deviceType = 'iOS MOBILE'
          : el.deviceDto.deviceType === 'MOBILE_ANDROID' ? el.deviceDto.deviceType = 'ANDROID MOBILE'
            : el.deviceDto.deviceType === 'TV_ANDROID' ? el.deviceDto.deviceType = 'ANDROID TV'
              : el.deviceDto.deviceType,
        appVersion: el.deviceDto.appVersion,
        downloadDate: el.downloadDate



      }));

    })

  }


  goBack() {
    this.router.navigate(['/apps-module/apps/analytics']);
  }

}
