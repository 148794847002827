import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-show-per-modal',
  templateUrl: './show-per-modal.component.html',
  styleUrls: ['./show-per-modal.component.css']
})
export class ShowPerModalComponent implements OnInit {
  // @Input() showPercentModalSource: boolean = false;
  @Input() sendInfoData: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  showStorageModal = false
  showVodModal = false
  percentageStorage: any;
  percentageVodData: any;
  showLiveStreamModal: boolean = false;
  percentageLiveUsageData: any;
  isLiveStreamingSectionShow: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    let loginFeatureList = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails?.featureList : [];
    if(loginFeatureList && loginFeatureList.length > 0) {
       loginFeatureList.forEach(feature => {
        if(feature.name === 'Live Streaming'){
          this.isLiveStreamingSectionShow = feature.isView;
        }
      })
    }
    if(changes){
      if(this.sendInfoData?.isPaymentDone){
        if(this.sendInfoData.data.isStorageAboveFifty){
          this.showStorageModal = true
          this.percentageStorage = this.sendInfoData.data.percentageStorage
        }
        if(this.sendInfoData.data.isVodDataAboveFifty){
          this.showVodModal = true
          this.percentageVodData = this.sendInfoData.data.percentageVodData
        }
        if(this.sendInfoData.data.isLiveStreamAboveFifty && this.isLiveStreamingSectionShow){
          this.showLiveStreamModal = true
          this.percentageLiveUsageData = this.sendInfoData.data.percentageLiveStream
        }

      }
    }
  }
}
