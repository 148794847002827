<div class="app-block app-block-form1 video-item">
    <div class="app-block-title" style="position: relative;">
        <p class="header-title" style="position: absolute;left: 2%;width: 171px">App Download Date & Time</p>
        <p class="header-title" style="position: absolute;left: 24%;">Device Id</p>
        <p class="header-title" style="position: absolute;left: 43%;">Device Model</p>
        <p class="header-title" style="position: absolute;left: 66%;">Device OS</p>
        <p class="header-title" style="position: absolute;left: 85%;">App version</p>
    </div>
    <ul class="recent-item-list m-0">
        <li *ngFor="let recent of pages" style="position: relative;">
            <div class="d-flex align-items-center route-to-width ">
                <div class="p-2" style="position: absolute;left: 1.5%;">
                    <p class=" ellipsis-text-content m-0" >
                        {{recent.downloadDate | date : 'M-d-YYYY h:mm a' : '--'}}</p>
                </div>
                <div class="p-2 series-position" style="position: absolute;left: 23.5%;">
                    <p class="pointer title-container ellipsis-text-content m-0" 
                    title="{{recent.deviceId}}">{{recent.deviceId}}</p>
                </div>

                <div class="p-2 plan-position" style="position: absolute;left: 43%;">

                    <p class=" ellipsis-text-content title-container m-0">{{recent.deviceModel}}</p>

                </div>
                <div class="p-2 plan-position" style="position: absolute;left: 66%;">
                    <p class=" ellipsis-text-content title-container m-0">{{recent.deviceOS}}</p>
                </div>

                <div class="p-2" style="position: absolute;left: 87.5%;">
                    <p class=" title-container m-0">{{recent.appVersion}}</p>
                </div>
            </div>
        </li>
        <li *ngIf="!pages.length" class="no-media">
            <div class="d-flex align-items-center">
                <div class="recent-info">
                    <span>No data found.</span>
                </div>
            </div>
        </li>
    </ul>

    <div class="d-flex justify-content-between align-items-center paginator-top w-100">

        <p-paginator class="custom_paginator" [rows]="rowCount" [showCurrentPageReport]="true" [first]="first"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            [rowsPerPageOptions]="[10,20,25,50, 100]" (onPageChange)="loadCustomers($event)"
            [totalRecords]="totalRecords" pageLinkSize="3"></p-paginator>

    </div>


</div>
<div class="container text-center mt-20">
    <!-- <button type="submit" class="btn btn-white mr-10">Save</button> -->
    <button type="button" class="btn btn-white" (click)="goBack()">Back</button>
</div>
