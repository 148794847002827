import { Component, OnInit } from '@angular/core';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { MediaService } from 'src/app/services/media/media.service';
import { AppAnimation } from './../../../../utilities/animations/animations';


@Component({
  selector: 'app-events-embed-side-panel',
  templateUrl: './events-embed-side-panel.component.html',
  styleUrls: ['./events-embed-side-panel.component.css'],
  animations : AppAnimation
})
export class EventsEmbedSidePanelComponent implements OnInit {
  sidePanel = false
  overlay = false
  code= '<script id="subsplash-embed-4r5kcvs" type="text/javascript">var target = document.getElementById("subsplash-embed-4r5kcvs");var script = document.createElement("script");script.type = "text/javascript";script.onload = function() {subsplashEmbed("+hjcm/lb/li/+4r5kcvs?embed&branding&1639390709469","https://subsplash.com/","subsplash-embed-4r5kcvs");};script.src = "https://dashboard.static.subsplash.com/production/web-client/external/embed-1.1.0.js";target.parentElement.insertBefore(script, target);</script>'
  constructor(
    public mediaService : MediaService,
    public libraryService : LibraryServiceService
  ) { }

  ngOnInit(): void {
    this.mediaService.eventEmbed$.subscribe((response:any) => {
      this.overlay = response
      this.sidePanel = response
    } )
  }
  
  closePanel(){
    this.mediaService.openEventEmbed(false)
    this.overlay = false
    this.sidePanel = false
  }

  openPanel(eventEmbed) {
    this.mediaService.openEventEmbed(false)
    this.libraryService.updatePanel({ showPanel: true , clickedFrom : eventEmbed})
  }

}
