<div class="main-div">
    <div class="sub-main">
        <div class="nowcast-container">
            <div class="nowcast-container-wrapp">
                <div class="logo" style="cursor: pointer;"  >
                    <img src="/assets/images/authenticate/logo.png" alt="logo">
                </div>
                <div class="login-form app-block-small-form">
                    <form #forgotForm="ngForm">
                        <div class="login-email">
                            <label>Email</label>
                            <input type="text" class="form-control" [(ngModel)]="username" name="email" #email="ngModel"
                                required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                            <ng-container *ngIf="email.errors && (email.touched || email.dirty)">
                                <p class="error" [hidden]="!email.errors.pattern && !email.errors.required">
                                    Please enter a valid email
                                </p>
                            </ng-container>
                        </div>
                        <div class="button-div">
                            <a class="but-anc pb-1" routerLink="/login">Back to login</a>
                            <button type="button" class="submit-btn" (click)="forgetPassword()" [disabled]="!forgotForm.valid">Request</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>